import {
    AppBar,
    Tabs,
    Tab,
    Toolbar,
    Typography,
    Grid,
    createTheme,
    ThemeProvider,
    makeStyles,
    Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './css/TopHeader.css';

import {  AFFILIATE_CODE_CHANGED} from '../../../reducers/TYPES';

import SearchButton from './SearchButton';
import { createPath } from '../utils';
import BackButton from './BackButton';
import DineSelectHeader from './DineSelectHeader';
import { useTranslation } from 'react-i18next';
import { loadCampaigns } from '../../../actions/orderActions';
import { persistor } from '../../../reduxStore';

const useStyles = makeStyles({
    indicator: {
        backgroundColor: '#103C54',
        height: '4px',
    },
});

const tabThemeOverride = createTheme({
    overrides: {
        MuiTab: {
            wrapper: {
                color: '#103C54',
                fontWeight: '510',
            },
        },
    },
});

export default (props) => {
    const classes = useStyles();
    const restaurant = useSelector((state) => state.main.restaurant_info);
    const isGhostKitchen =
        typeof restaurant.name === 'string'
            ? restaurant.name.includes('Ghost')
            : false;
    let backButton = props.backButton;
    let mainOrderPage = props.orderPage;
    let searchField = props.searchField;
    const affiliateCode = useSelector((state) => state.order.affiliate_code);

    const { endpoint } = useParams();
    const tabs = {
        0: createPath('/v2/:/order', [endpoint]),
        1: createPath('/v2/:/order/featured', [endpoint]),
    };
    const location = useLocation();
    const path = location.pathname;
    const [tab, setTab] = useState(path);
    const history = useHistory();
    const campaigns = useSelector((state) => state.order.menu.campaigns);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadCampaigns());
    }, [dispatch]);

    const renderFeatured =
        (Array.isArray(campaigns) && campaigns.length) > 0 ? (
            <Tab
                style={{
                    textTransform: 'none',
                }}
                value={tabs[1]}
                label={t('common.featured')}
            />
        ) : null;
    // const isTakeoutOrder = useSelector(state => state.order.payment_type) === PaymentType.PAY_IMMEDIATE;

    const changeTab = (newRoute) => {
        setTab(newRoute);
        history.push(newRoute);
    };

    const handleRefresh = () => {
        console.log({ affiliateCode });
        const href = window.location.href.replace('/order', `/${affiliateCode}`);

        try {
            persistor.purge();
            return window.location.href = href;
        } catch (e) {
            console.log(e)
        } finally {
            if (affiliateCode) {
                dispatch({
                    type: AFFILIATE_CODE_CHANGED,
                    payload: affiliateCode,
                });
            }
        }
    }

    const mainOrderPageHeader = (
        <Typography variant="h5">{t('common.order')}</Typography>
    );

    const [showAlert, setShowAlert] = useState(true);

    return (
        <AppBar
            position="sticky"
            style={{
                background: 'white',
                color: 'black',
                boxShadow: '0px 0px 8px rgba(0,0,0, 0.2)',
            }}
        >
            <Toolbar style={{ flexDirection: 'column' }}>
                {mainOrderPage && showAlert ? (
                    <Grid
                        container
                        justifyContent="space-between"
                        style={{ flexWrap: 'nowrap', width: '100%' }}
                    >
                        <Alert
                            severity="info"
                            onClose={() => setShowAlert(false)}
                            style={{ width: '100%' }}
                        >
                            {t('order.alert')}{' '}
                            <span
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }}
                                onClick={handleRefresh}
                            >
                                {t('order.refresh')}
                            </span>
                        </Alert>
                    </Grid>
                ) : null}

                <Grid
                    container
                    justifyContent="space-between"
                    style={{ flexWrap: 'nowrap', width: '100%' }}
                >
                    <Grid
                        item
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        {backButton ? (
                            <>
                                <BackButton previousPath={props.previousPath} />
                                <div
                                    className="topHeaderTitle"
                                    data-cy="topHeaderTitle"
                                >
                                    {props.title}
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {searchField ? (
                            <>
                                <BackButton previousPath={props.previousPath} />
                                <Typography
                                    style={{ marginLeft: '30px' }}
                                    variant="h6"
                                    data-cy="searchField"
                                >
                                    {t('common.searchField')}
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}

                        {mainOrderPage ? <>{mainOrderPageHeader}</> : <></>}
                    </Grid>

                    <Grid item>
                        <SearchButton />
                    </Grid>
                </Grid>
            </Toolbar>
            {mainOrderPage ? (
                <>
                    <ThemeProvider theme={tabThemeOverride}>
                        <Tabs
                            value={tab}
                            onChange={(evt, newRoute) => changeTab(newRoute)}
                            classes={{
                                indicator: classes.indicator,
                            }}
                        >
                            <Tab
                                style={{
                                    textTransform: 'none',
                                }}
                                label={
                                    isGhostKitchen
                                        ? t('common.brands')
                                        : 'Menus'
                                }
                                value={tabs[0]}
                            />
                            {renderFeatured}
                        </Tabs>
                    </ThemeProvider>
                </>
            ) : (
                <></>
            )}
            <DineSelectHeader />
        </AppBar>
    );
};
