import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.open('/documents/Privacy.html', '_self');
    }, [])
    return (
        <>Redirecting...</>
    );
};
export default PrivacyPolicy;
