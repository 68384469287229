import { BASE_URL } from '../../endpoints';
import './css/FourOFour.css';
import React from 'react';

export default (props) => {
    return (
        <div className="fof-container">
            <div className="fof-number">404</div>
            <div className="fof-text">
                Sorry, this restaurant does not exist.
            </div>
            <a href="/">
                <img
                    alt=""
                    src={`${BASE_URL}/assets/landing/mentumblack.png`}
                    className="fof-logo"
                ></img>
            </a>
        </div>
    );
};
