import React from 'react';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import * as OrderActions from '../../../actions/orderActions';
import Keyboard from 'react-simple-keyboard';
import { ModifierQuantity } from '../../v2/Components/ItemComponents';
import { cloudfrontBaseUrl } from '../../../endpoints';

class KioskItemDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modifier_error_message: '',
            show_more_modifiers_chevron: false,
            show_special_instructions_modal: false,
            spcial_instructions: '',
        };
    }

    modifierSelected(modifier, is_multiple_selection_allowed, max_qty) {
        this.setState({
            modifier_error_message: '',
        });
        // ensure max modifier has not been reached
        const modifiers = this.props.state.order.selected_item_modifiers.filter(
            (m) => m.modifier_group_id === modifier.modifier_group_id
        );
        const toggleOff =
            modifiers.filter((m) => m.modifier_id === modifier.modifier_id)
                .length > 0;

        if (modifiers.length < max_qty || toggleOff) {
            this.props.menuItemModifierSelected(
                modifier,
                is_multiple_selection_allowed
            );
        }
    }

    isModifierChecked(modifier) {
        const modifiers_enabled =
            this.props.state.order.selected_item_modifiers;
        return (
            modifiers_enabled.filter(
                (m) => modifier.modifier_id === m.modifier_id
            ).length >= 1
        );
    }

    addToOrder() {
        if (this.checkModifiersAreSatisfied()) {
            this.props.addToOrder(this.props.history, false);
            // hide add item
            this.closeItem();
        }
    }

    closeItem() {
        this.props.history.push('/kiosk');
    }

    checkModifiersAreSatisfied() {
        // validate that all required modifiers are selected
        const item = this.props.state.order.selected_item;
        const modifierGroups = item.item_modifier_groups;
        const modifiers_enabled =
            this.props.state.order.selected_item_modifiers;

        if (Array.isArray(modifierGroups)) {
            for (let i = 0; i < modifierGroups.length; i++) {
                const modifierGroup = modifierGroups[i];
                if (modifierGroup.min_qty === 0) {
                    continue;
                }

                let uniqueModifiers = [];
                let numberOfModifierSelected = 0;
                modifiers_enabled.forEach((selectedModifier) => {
                    if (
                        selectedModifier.modifier_group_id ===
                        modifierGroup.modifier_group.modifier_group_id
                    ) {
                        numberOfModifierSelected++;
                        if (!uniqueModifiers.includes(selectedModifier)) {
                            uniqueModifiers.push(selectedModifier);
                        }
                    }
                });

                var modifierSatisfied = false;
                if (
                    numberOfModifierSelected >= modifierGroup.min_qty &&
                    numberOfModifierSelected <= modifierGroup.max_qty
                ) {
                    modifierSatisfied = true;
                }

                // if modifier is not satisfied, user cannot add to cart, show toast
                if (!modifierSatisfied) {
                    // notify customer
                    this.setState({
                        modifier_error_message: `Error selecting: ${modifierGroup.modifier_group.name}`,
                    });
                    scroller.scrollTo(
                        modifierGroup.modifier_group.modifier_group_id,
                        {
                            containerId: 'item-inner-container',
                            smooth: true,
                            duration: 1000,
                        }
                    );
                    return false;
                }
            }
        }

        return true;
    }

    generateRequirementText(min, max) {
        const requirementsString = (min, max) => {
            if (min === max) {
                return 'exactly ' + min;
            } else if (min === 0) {
                if (max === 1000) {
                    return 'any';
                }
                return 'at most ' + max;
            } else if (max === 1000) {
                return 'at least ' + min;
            } else {
                return 'between ' + min + ' and  ' + max;
            }
        };

        if (min === 0 && max === 1000) {
            return '';
        }

        return `(Choose ${requirementsString(min, max)})`;
    }

    render() {
        const item = this.props.state.order.selected_item;

        if (Object.keys(item).length === 0) {
            return <></>;
        }

        const specialInstructionsModal = () => {
            return (
                <div className="kiosk-discount-modal">
                    <div className="kiosk-discount-modal-window">
                        <div className="kiosk-discount-modal-header">
                            <div className="kiosk-discount-modal-title">
                                Special Instructions
                            </div>
                        </div>
                        <div className="kiosk-discount-modal-body">
                            <div className="kiosk-discount-modal-alert">
                                Please enter any special instructions using the
                                keyboard.
                            </div>
                            <input
                                readOnly
                                className="kiosk-discount-modal-text-field"
                                value={this.state.special_instructions}
                            />
                            <div className="kiosk-discount-modal-button-container">
                                <div
                                    className="kiosk-discount-modal-button modal-button-red"
                                    onClick={() => {
                                        this.setState({
                                            show_special_instructions_modal: false,
                                            special_instructions:
                                                this.props.state.order
                                                    .selected_item_special_instructions,
                                        });
                                    }}
                                >
                                    Cancel
                                </div>
                                <div
                                    className="kiosk-discount-modal-button modal-button-blue"
                                    onClick={() => {
                                        this.props.menuItemSpecialInstructionsChanged(
                                            this.state.special_instructions
                                        );
                                        this.setState({
                                            show_special_instructions_modal: false,
                                        });
                                    }}
                                >
                                    Done
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kiosk-discount-modal-keyboard-background"></div>
                    <div className="kiosk-discount-modal-keyboard">
                        <Keyboard
                            onChange={(val) => {
                                this.setState({ special_instructions: val });
                            }}
                        />
                    </div>
                </div>
            );
        };

        const renderSpecialInstructions = () => {
            return (
                <div style={{ marginTop: 50 }}>
                    {this.state.show_special_instructions_modal
                        ? specialInstructionsModal()
                        : null}
                    <div className="to-page-item-one-modifier">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                minWidth: '400px',
                            }}
                        >
                            <div className="to-page-item-one-modifier-index">
                                {item.item_modifier_groups.length + 1}
                            </div>
                            <div className="to-page-item-one-modifier-name">
                                {'Special Instructions'}
                            </div>
                        </div>
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <div
                                className={`to-page-item-modifier`}
                                onClick={() => {
                                    this.setState({
                                        show_special_instructions_modal: true,
                                    });
                                }}
                            >
                                <div className="modifier-label">
                                    Enter Special Instructions
                                </div>
                            </div>
                            <div
                                className="modifier-label"
                                style={{ marginLeft: 16, marginTop: 8 }}
                            >{`Special Instructions: ${this.props.state.order.selected_item_special_instructions}`}</div>
                        </div>
                        <div className="to-page-item-one-modifier-group-modifier-container"></div>
                    </div>
                </div>
            );
        };

        const renderModifierGroups = () => {
            const modifiers = this.props.state.order.menu.modifiers;

            const modifierGroups = item.item_modifier_groups;
            if (!Array.isArray(modifierGroups)) {
                return;
            }

            return (
                <div id="itemModifierGroupContainer">
                    {modifierGroups.map((modGroupObject, index) => {
                        const { min_qty, max_qty } = modGroupObject;
                        let is_multiple_selection_allowed = false;
                        if (max_qty > 1) is_multiple_selection_allowed = true;
                        const modGroup = modGroupObject.modifier_group;
                        let group_modifiers = modifiers.filter((mod) => {
                            return (
                                mod.modifier_group_id ===
                                modGroup.modifier_group_id
                            );
                        });
                        const modifiers_in_stock = group_modifiers.filter(
                            (mod) => mod.is_in_stock
                        );
                        const modifiers_oos = group_modifiers.filter(
                            (mod) => !mod.is_in_stock
                        );
                        group_modifiers = [
                            ...modifiers_in_stock,
                            ...modifiers_oos,
                        ];

                        let modifier_text = this.generateRequirementText(
                            min_qty,
                            max_qty
                        );
                        let render;

                        return (
                            <div
                                className="to-page-item-one-modifier"
                                key={modGroup.modifier_group_id}
                                id={modGroup.modifier_group_id}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minWidth: '400px',
                                    }}
                                >
                                    <div className="to-page-item-one-modifier-index">
                                        {index + 1}
                                    </div>
                                    <div className="to-page-item-one-modifier-name">
                                        {modGroup.name}
                                        <div className="to-page-item-one-modifier-required">
                                            {modifier_text}
                                        </div>
                                    </div>
                                </div>

                                <div className="to-page-item-one-modifier-group-modifier-container">
                                    {group_modifiers.map((modifier, index) => {
                                        const modifier_is_checked =
                                            this.isModifierChecked(modifier)
                                                ? 'to-p-i-m-checked'
                                                : '';

                                        if (is_multiple_selection_allowed) {
                                            if (modifier.is_in_stock) {
                                                render = (
                                                    <div
                                                        className={`to-page-item-modifier ${modifier_is_checked}`}
                                                        key={
                                                            modifier.modifier_id
                                                        }
                                                        onClick={(e) => {
                                                            this.modifierSelected(
                                                                modifier,
                                                                true,
                                                                max_qty
                                                            );
                                                        }}
                                                    >
                                                        <div className="modifier-label">
                                                            {modifier.name}
                                                            {modifier.price
                                                                ? ' +$' +
                                                                  modifier.price
                                                                : ''}
                                                        </div>
                                                        {this.isModifierChecked(
                                                            modifier
                                                        ) ? (
                                                            <ModifierQuantity
                                                                style={{
                                                                    paddingLeft:
                                                                        '15px',
                                                                }}
                                                                isKiosk={true}
                                                                initialValue={1}
                                                                setSelectedModifiers={(
                                                                    mods
                                                                ) => {
                                                                    this.props.addModifiersToItem(
                                                                        mods
                                                                    );
                                                                }}
                                                                selectedModifiers={
                                                                    this.props
                                                                        .state
                                                                        .order
                                                                        .selected_item_modifiers
                                                                }
                                                                modifier={
                                                                    modifier
                                                                }
                                                                maxSelections={
                                                                    max_qty
                                                                }
                                                                orderSelected={[]}
                                                            />
                                                        ) : null}
                                                    </div>
                                                );
                                            } else {
                                                render = (
                                                    <div
                                                        className={`to-page-item-modifier ${modifier_is_checked}`}
                                                        key={
                                                            modifier.modifier_id
                                                        }
                                                        style={{
                                                            opacity: '0.5',
                                                        }}
                                                    >
                                                        <div className="modifier-label">
                                                            {modifier.name}
                                                            {modifier.price
                                                                ? ' +$' +
                                                                  modifier.price
                                                                : ''}
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                    marginLeft:
                                                                        '10px',
                                                                }}
                                                            >
                                                                SOLD OUT
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        } else {
                                            if (modifier.is_in_stock) {
                                                render = (
                                                    <div
                                                        className={`to-page-item-modifier ${modifier_is_checked}`}
                                                        key={
                                                            modifier.modifier_id
                                                        }
                                                        onClick={(e) => {
                                                            this.modifierSelected(
                                                                modifier,
                                                                false,
                                                                max_qty
                                                            );
                                                        }}
                                                    >
                                                        <div className="modifier-label">
                                                            {modifier.name}
                                                            {modifier.price
                                                                ? ' +$' +
                                                                  modifier.price
                                                                : ''}
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                render = (
                                                    <div
                                                        className={`to-page-item-modifier ${modifier_is_checked}`}
                                                        key={
                                                            modifier.modifier_id
                                                        }
                                                        style={{
                                                            opacity: '0.5',
                                                        }}
                                                    >
                                                        <div className="modifier-label">
                                                            {modifier.name}
                                                            {modifier.price
                                                                ? ' +$' +
                                                                  modifier.price
                                                                : ''}
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                    marginLeft:
                                                                        '10px',
                                                                }}
                                                            >
                                                                SOLD OUT
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                        return render;
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        };

        const renderBottom = () => {
            let selectedItemQuantity =
                this.props.state.order.selected_item_quantity;
            const increaseQuantity = () => this.props.increaseItemQuantity();
            const decreaseQuantity = () => this.props.decreaseItemQuantity();

            return (
                <div className="to-page-item-bottom-container animate-page-fade-in">
                    <div
                        className="to-page-item-modifier-error"
                        style={{
                            position: 'fixed',
                            bottom: '700px',
                            width: '100%',
                            textAlign: 'center',
                            backgroundColor: '#fff',
                        }}
                    >
                        {this.state.modifier_error_message}
                    </div>

                    <div className="to-page-item-bottom-quantity-container">
                        Quantity
                        <div className="to-quantity">
                            <div
                                className="to-quantity-minus"
                                onClick={(e) => decreaseQuantity()}
                            >
                                -
                            </div>
                            <div className="to-quantity-value">
                                {selectedItemQuantity}
                            </div>
                            <div
                                className="to-quantity-plus"
                                onClick={(e) => increaseQuantity()}
                            >
                                +
                            </div>
                        </div>
                    </div>

                    <div className="to-item-save">
                        <div
                            className="to-item-save-button"
                            onTouchEnd={(e) => {
                                setTimeout((e) => {
                                    this.props.history.push('/kiosk');
                                }, 1);
                            }}
                            onMouseUp={(e) => {
                                setTimeout((e) => {
                                    this.props.history.push('/kiosk');
                                }, 1);
                            }}
                        >
                            Back
                        </div>
                        <div
                            className="to-item-save-button"
                            onTouchEnd={(e) => {
                                this.addingToCart = true;
                                setTimeout((e) => {
                                    this.addToOrder();
                                }, 1);
                            }}
                            onMouseUp={(e) => {
                                if (this.addingToCart) {
                                    return;
                                }
                                setTimeout((e) => {
                                    this.addToOrder();
                                }, 1);
                            }}
                        >
                            Add to cart
                        </div>
                    </div>
                </div>
            );
        };

        const restaurant_id =
            this.props.state.main.restaurant_info.restaurant_id;
        const price = item.price ? `$${parseFloat(item.price).toFixed(2)}` : '';
        const imgUrl = `${cloudfrontBaseUrl}${restaurant_id}/items/${item.item_id}.jpeg`;

        return (
            <div
                className="to-page-container to-page-container-item animate-page-fade-in"
                ref={(r) => {
                    this.modifierContainerRef = r;
                }}
            >
                <div
                    className="to-page-inner-container"
                    id="item-inner-container"
                >
                    <div
                        className="to-page-item-header to-page-item-header-fixed"
                        id="item-top"
                    >
                        <div
                            className="to-page-item-image"
                            style={{
                                backgroundImage: `url('${imgUrl}'), url('')`,
                            }}
                        ></div>
                        <div className="to-page-item-name">
                            {item.name} {price}
                            <div className="to-page-item-description">
                                {item.description}
                            </div>
                        </div>
                    </div>
                    <div className="to-page-item-header-fixed-spacing"></div>

                    <div className="to-page-item-modifiers">
                        <div>{renderModifierGroups()}</div>
                        <div>{renderSpecialInstructions()}</div>
                    </div>

                    <div className="to-page-item-bottom-container-spacing"></div>
                    {renderBottom()}
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    menuItemModifierSelected: OrderActions.menuItemModifierSelected,
    menuItemSpecialInstructionsChanged:
        OrderActions.menuItemSpecialInstructionsChanged,
    addToOrder: OrderActions.addToOrder,
    addModifiersToItem: OrderActions.addModifiersToItem,
    increaseItemQuantity: OrderActions.increaseItemQuantity,
    decreaseItemQuantity: OrderActions.decreaseItemQuantity,
})(KioskItemDetail);
