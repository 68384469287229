import React, { useState } from 'react';
import Spacing, { SpacingAmount } from './Spacing';

export interface Tab {
    label: string;
    render: () => JSX.Element;
}

interface TabbedProps {
    tabs: Tab[];
}
export default (props: TabbedProps) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const renderLabels = props.tabs.map((tab, tabIndex) => {
        const extraClasses =
            tabIndex === selectedTab ? 'v3TabLabelSelected' : '';
        return (
            <div
                onClick={() => setSelectedTab(tabIndex)}
                className={`v3TabLabel ${extraClasses}`}
            >
                {tab.label}
            </div>
        );
    });

    if (props.tabs.length === 0) {
        return <></>;
    }

    return (
        <div className="v3TabbedContainer">
            <div className="v3TabLabelContainer">{renderLabels}</div>
            <Spacing amount={SpacingAmount.Spacing60} />
            {props.tabs.map((tab, tabIndex) => {
                const className =
                    tabIndex === selectedTab ? '' : 'v3ProductHidden';
                return <div className={className}>{tab.render()}</div>;
            })}
            <Spacing amount={SpacingAmount.Spacing60} />
        </div>
    );
};
