import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import '../../css/ItemScreen.css';
import '../../css/ModifierSelect.css';
import { Container } from '@material-ui/core';
import {
    isSelectedModifier,
    getInstruction,
    sendUserBack,
    findModifierGroup,
    getModifiers,
    getModifierMultiplier,
} from '../utils';
import * as OrderActions from '../../../actions/orderActions';
import {
    SelectPageHeader,
    ModifierSelectIcon,
    SelectPageSubHeader,
    ModifierQuantity,
} from '../Components/ItemComponents';
import MiniCart from '../Components/MiniCart';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { previousPath } = props;
    const thisOrder = useSelector((state) => state.order);
    const history = useHistory();
    const urlParams = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const curModGroup = history.location.state
        ? history.location.state.modifier_group
        : findModifierGroup(thisOrder.menu, urlParams);
    let curGroupModifiers = getModifiers(
        thisOrder.menu,
        curModGroup.modifier_group.modifier_group_id
    );
    let instruction = getInstruction(curModGroup.min_qty, curModGroup.max_qty);
    let selectedModifiersBefore = history.location.state
        ? history.location.state.selected_modifiers
            ? history.location.state.selected_modifiers
            : []
        : [];
    const [selectedModifiers, setSelectedModifiers] = useState(
        selectedModifiersBefore
    );
    const maxSelections = curModGroup.max_qty;

    if (
        !previousPath ||
        (previousPath && !previousPath.pathname.includes('/i/'))
    ) {
        // history.push(createPath('/v2/:/m/:/c/:/i/:', [urlParams.endpoint, urlParams.menu_id, urlParams.category_id, urlParams.item_id]))
    }

    return (
        <Container
            fixed
            style={{
                padding: 0,
                margin: 0,
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
            }}
        >
            <div
                class="content"
                style={{ display: 'flex', flexDirection: 'column', flex: '1' }}
            >
                <SelectPageHeader
                    text={t('common.select')}
                    condition={selectedModifiers.length >= curModGroup.min_qty}
                    previousPath={previousPath}
                    urlParams={urlParams}
                    history={history}
                    clickedSaveCallback={() => {
                        let newArr = [];
                        thisOrder.selected_item_modifiers.forEach((selMod) => {
                            if (
                                selMod.modifier_group_id !==
                                curModGroup.modifier_group.modifier_group_id
                            ) {
                                newArr.push(selMod);
                            }
                        });
                        newArr = newArr.concat(selectedModifiers);
                        dispatch(OrderActions.addModifiersToItem(newArr));
                        sendUserBack(history, previousPath, urlParams);
                    }}
                />
                <SelectPageSubHeader sub_header={instruction} />
                {curGroupModifiers.map((mod) => {
                    const modPrice = mod.price
                        ? `$${parseFloat(mod.price).toFixed(2)}`
                        : '';
                    const inStock = mod.is_in_stock;
                    return (
                        <div
                            className="ModifierRow"
                            data-cy="modifierRow"
                            onClick={() => {
                                if (!inStock) {
                                    return;
                                }
                                if (
                                    isSelectedModifier(selectedModifiers, mod)
                                ) {
                                    setSelectedModifiers(
                                        selectedModifiers.filter(
                                            (selMod) =>
                                                selMod.modifier_id !==
                                                mod.modifier_id
                                        )
                                    );
                                } else {
                                    let newSelectedModifiers =
                                        selectedModifiers;
                                    if (
                                        selectedModifiers.length >=
                                        curModGroup.max_qty
                                    ) {
                                        newSelectedModifiers =
                                            newSelectedModifiers.slice(
                                                0,
                                                curModGroup.max_qty - 1
                                            );
                                    }
                                    selectedModifiersBefore =
                                        newSelectedModifiers.length
                                            ? newSelectedModifiers.concat([mod])
                                            : [mod];
                                    setSelectedModifiers(
                                        selectedModifiersBefore
                                    );
                                }
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <div className="CenterVertically">
                                    <div className="SelectButtonContainer">
                                        <ModifierSelectIcon
                                            isSelected={isSelectedModifier(
                                                selectedModifiers,
                                                mod
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="CenterVertically">
                                    <div className="ModifierRowText">
                                        {mod.name} {modPrice}{' '}
                                        {!inStock
                                            ? t('itemDetail.soldOut')
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            {maxSelections > 1 &&
                            isSelectedModifier(selectedModifiers, mod) ? (
                                <div className="CenterVertically">
                                    <ModifierQuantity
                                        isKiosk={false}
                                        maxSelections={maxSelections}
                                        selectedModifiers={selectedModifiers}
                                        setSelectedModifiers={
                                            setSelectedModifiers
                                        }
                                        initialValue={
                                            getModifierMultiplier(
                                                selectedModifiers,
                                                mod
                                            ) || 1
                                        }
                                        modifier={mod}
                                    />
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
            <MiniCart />
        </Container>
    );
};
