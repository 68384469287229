import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { checkIntervalAvailability, isTakeout } from '../../../utils';

import './css/MenuHours.css';
import { useTranslation } from 'react-i18next';
import { i18n } from '../../../locales';

export default (props) => {
    const schedules = useSelector((state) => state.order.menu.schedule);
    const order_time = useSelector((state) => state.order.order_time);
    const [showMenuHours, setShowMenuHours] = useState(false);
    const selectedMenu = props.selectedMenu || {};
    const { t } = useTranslation();

    const getMenuSchedule = () => {
        if (!schedules) {
            return [];
        }
        // TODO: OPTIMIZE
        const schedule = schedules.filter((s) => {
            if (s.menus) {
                return (
                    s.menus.filter((m) => {
                        return m.menu_id === selectedMenu.menu_id;
                    }).length > 0
                );
            } else {
                return false;
            }
        });
        return schedule;
    };

    const schedule = getMenuSchedule();

    const checkIfMenuIsActive = () => {
        if (schedule.length > 0) {
            // check current time is within schedule
            const current_time = order_time ? new Date(order_time) : new Date();
            let is_available = false;

            schedule.forEach((schedule) => {
                // todo: optimize
                (schedule.intervals || []).forEach((i) => {
                    // check day of week
                    if (i.day_of_week - 1 === current_time.getDay()) {
                        is_available |= checkIntervalAvailability(
                            i,
                            current_time
                        );
                    }
                });
            });

            if (!is_available) {
                const message = isTakeout()
                    ? t('toast.closedMealPeriod')
                    : t('toast.menuNotServed', {
                          selectedMenuName: selectedMenu.name,
                      });
                toast.info(message, {
                    toastId: 'mealPeriodClosed',
                });
            }
        }
    };

    useEffect(() => {
        checkIfMenuIsActive();
        return () => {
            toast.dismiss('mealPeriodClosed');
        };
    }, []);

    const scheduleFound = schedule.length > 0;

    const renderMenuModal = () => {
        if (scheduleFound) {
            // combine schedules
            let combined_schedule = {};
            schedule.forEach((one_schedule) => {
                if (!one_schedule.intervals) {
                    return;
                }
                one_schedule.intervals.forEach((interval) => {
                    const day_of_week = interval.day_of_week;
                    if (!(day_of_week in combined_schedule)) {
                        combined_schedule[day_of_week] = [interval];
                    } else {
                        combined_schedule[day_of_week].push(interval);
                    }
                });
            });

            return (
                <div className="menuHoursContainer">
                    <div className="menuHours">
                        <div className="menuHoursFloatRight">
                            <div className="menuHoursTitle">
                                {selectedMenu.name} {t('menuHours.hours')}
                            </div>
                            <CloseIcon
                                color="action"
                                onClick={() => {
                                    setShowMenuHours(false);
                                }}
                            />
                        </div>

                        <div className="menuHoursHours">
                            {Object.keys(combined_schedule).map(
                                (day_of_week) => {
                                    const intervals =
                                        combined_schedule[day_of_week];
                                    return intervals.map(
                                        (i, interval_index) => {
                                            const start_time = new Date(
                                                i.start_time
                                            );
                                            const end_time = new Date(
                                                i.end_time
                                            );
                                            const start_time_str =
                                                start_time.toLocaleString(
                                                    i18n.language,
                                                    {
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true,
                                                        timeZone: 'UTC',
                                                    }
                                                );
                                            const end_time_str =
                                                end_time.toLocaleString(
                                                    i18n.language,
                                                    {
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true,
                                                        timeZone: 'UTC',
                                                    }
                                                );
                                            const day_of_week = i.day_of_week;
                                            const day_of_week_map = {
                                                1: t('menuHours.daysOfWeek.1'),
                                                2: t('menuHours.daysOfWeek.2'),
                                                3: t('menuHours.daysOfWeek.3'),
                                                4: t('menuHours.daysOfWeek.4'),
                                                5: t('menuHours.daysOfWeek.5'),
                                                6: t('menuHours.daysOfWeek.6'),
                                                7: t('menuHours.daysOfWeek.7'),
                                            };
                                            const today_day_of_week =
                                                new Date().getDay() + 1;
                                            return (
                                                <div
                                                    className={`menuHourRow ${
                                                        today_day_of_week ===
                                                        i.day_of_week
                                                            ? 'menuHourRow-bold'
                                                            : ''
                                                    }`}
                                                >
                                                    <div className="menuHourDayOfWeek">
                                                        {interval_index === 0
                                                            ? day_of_week_map[
                                                                  day_of_week
                                                              ]
                                                            : ''}
                                                    </div>
                                                    <div className="menuHourInterval">
                                                        {start_time_str}{' '}
                                                        {t('common.to')}{' '}
                                                        {end_time_str}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="menuHoursContainer">
                    <div className="menuHours">
                        <div className="menuHoursTitle">
                            {t('menuHours.noMenuHoursFound')}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                onClick={(e) => setShowMenuHours(false)}
                                className="categoryHeaderMenuHours"
                            >
                                {t('oneMenu.close')}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            {showMenuHours ? renderMenuModal() : <></>}
            {scheduleFound ? (
                <div className="menuHoursRow">
                    <div
                        className="menuHoursButton"
                        onClick={() => setShowMenuHours(true)}
                    >
                        {t('menuHours.menuHours')}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
