import React, { useState, useEffect, useMemo } from 'react';
import './GhostKitchenLocationPicker.css';
import Axios from 'axios';
import { getDistance } from 'geolib';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { checkIntervalAvailability } from '../../../utils';

import GKLocationPickerDesktop from './GKLocationPickerDesktop';
import GKLocationPickerMobile from './GKLocationPickerMobile';
import { LocationPickerAction } from './constants';
import { BASE_URL } from '../../../endpoints';

// Given a restaurant, calculate and format its schedule into opening and closing times that the user can read
const calculateOpeningHours = (restaurantSchedule) => {
    let schedule = [];
    restaurantSchedule.forEach(
        (s) => (schedule = schedule.concat(s.intervals))
    );
    let startTimes = new Array(7);
    let endTimes = new Array(7);
    schedule.forEach(function (time) {
        let day = time.day_of_week;
        let startDate = time.start_time;
        let endDate = time.end_time;
        if (
            typeof startTimes[day - 1] === 'undefined' ||
            new Date(startTimes[day - 1]).getHours() <
                new Date(startDate).getHours()
        )
            startTimes[day - 1] = startDate;
        if (
            typeof endTimes[day - 1] === 'undefined' ||
            new Date(endDate).getHours() >
                new Date(endTimes[day - 1]).getHours()
        )
            endTimes[day - 1] = endDate;
    });

    let now = new Date();
    let dayOfWeek = now.getDay();
    let preOrder = true;

    //Calculate and display time intervals
    let openingToday;
    if (startTimes[dayOfWeek] != null && endTimes[dayOfWeek] != null) {
        //Calculate time of day relative to start of day for opening, closing, and current times
        //Checks if the store is open currently right now
        let startDate = new Date(startTimes[dayOfWeek]);
        let startDateMidnight = new Date(startDate);
        startDateMidnight.setHours(0, 0, 0);
        let openingTime = startDate.getTime() - startDateMidnight.getTime();

        let endDate = new Date(endTimes[dayOfWeek]);
        let endDateMidnight = new Date(endDate);
        endDateMidnight.setHours(0, 0, 0);
        let closingTime = endDate.getTime() - endDateMidnight.getTime();

        let currentDateMidnight = new Date(now);
        currentDateMidnight.setHours(0, 0, 0);
        let currentTime = now.getTime() - currentDateMidnight.getTime();

        //If the store is open right now, order instead of pre-order
        if (
            checkIntervalAvailability(
                {
                    start_time: startTimes[dayOfWeek],
                    end_time: endTimes[dayOfWeek],
                },
                now
            )
        ) {
            preOrder = false;
        }

        //Calculate and display opening times
        if (Math.abs(closingTime - openingTime) <= 300000) {
            //If the opening and closing times are within 5 minutes of each other, the store is likely open for 24 hours
            openingToday = 'Open today 24 hours';
            preOrder = false;
        } else {
            let start = startDate.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZone: 'UTC',
            });
            let end = endDate.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZone: 'UTC',
            });
            openingToday = 'Open today from ' + start + ' to ' + end;
        }
    } else {
        openingToday = schedule?.length ? 'Closed today' : 'Coming soon';
        preOrder = schedule?.length;
    }

    //Calculate and display opening times for all days
    let openingIntervals = Array(7);
    let isComingSoon = true;
    for (let i = 0; i < 7; i++) {
        if (startTimes[i] != null && endTimes[i] != null) {
            isComingSoon = false;

            let startDate = new Date(startTimes[i]);
            let startDateMidnight = new Date(startDate);
            startDateMidnight.setHours(0, 0, 0);
            let openingTime = startDate.getTime() - startDateMidnight.getTime();

            let endDate = new Date(endTimes[i]);
            let endDateMidnight = new Date(endDate);
            endDateMidnight.setHours(0, 0, 0);
            let closingTime = endDate.getTime() - endDateMidnight.getTime();

            if (
                Math.abs(closingTime - openingTime) <= 300000 ||
                Math.abs(closingTime - openingTime) >= 85800000
            ) {
                openingIntervals[i] = '24 hours';
            } else {
                let start = startDate.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                    timeZone: 'UTC',
                });
                let end = endDate.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                    timeZone: 'UTC',
                });
                openingIntervals[i] = start + ' to ' + end;
            }
        } else {
            openingIntervals[i] = schedule?.length ? 'Closed' : 'Coming Soon';
        }
    }

    return {
        openingIntervals,
        openingToday,
        preOrder,
        isComingSoon,
    };
};

const sortRestaurantsByDistance = (restaurants, position, radius, miles) => {
    return restaurants.sort((a, b) => {
        const distanceA = getDistance(position, {
            longitude: a.longitude,
            latitude: a.latitude,
        });
        const distanceB = getDistance(position, {
            longitude: b.longitude,
            latitude: b.latitude,
        });
        if (distanceA < distanceB) {
            return -1;
        }
        if (distanceA === distanceB) {
            return 0;
        }
        return 1;
    });
};

const buildRestaurantDistances = (restaurants, position, miles, radius) => {
    return restaurants.reduce((acc, restaurant) => {
        const distance = getDistance(position, {
            longitude: restaurant.longitude,
            latitude: restaurant.latitude,
        });

        if (miles) {
            distance /= 1.60934;
        }

        acc[restaurant.restaurant_id] = {
            distance,
            inRange: distance / 1000 < radius,
        };

        return acc;
    }, {});
};

const GKLocationPickerWeb = () => {
    const [restaurants, setRestaurants] = useState([]);
    const [miles, setMiles] = useState(false);
    const [address] = React.useState('');
    const [isDelivery, setIsDelivery] = useState(false);
    const [radius, setRadius] = useState(50);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [scheduleByRestaurantId, setScheduleByRestaurantId] = useState({});
    const [displayRestaurants, setDisplayRestaurants] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const [restaurantsDistances, setRestaurantsDistances] = useState({});

    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    const loadData = async () => {
        try {
            setIsLoading(true);

            // Fetch a list of restaurants
            const restaurantsResponse = await Axios.get(
                `${BASE_URL}/api/restaurants/list`
            );

            // Filter out restaurants that are not ghost kitchens or are demos
            let filteredData = restaurantsResponse.data.restaurants.filter(
                ({ franchise_id }) => !!franchise_id
            );

            // For each filtered restaurant, fetch its schedule
            const promises = filteredData.map(async ({ restaurant_id }) => {
                const schedulesResponse = await Axios.get(
                    `${BASE_URL}/api/menu/${restaurant_id}/schedules`
                );
                return {
                    restaurant_id,
                    schedule: schedulesResponse.data.schedules,
                };
            });

            // Wait for all schedules to be fetched
            const restaurantSchedules = await Promise.all(promises);

            // Set the schedule by restaurant id for easy lookup
            const schedules = restaurantSchedules.reduce(
                (acc, { restaurant_id, schedule }) => {
                    acc[restaurant_id] = {
                        schedule,
                        ...calculateOpeningHours(schedule),
                    };
                    return acc;
                },
                {}
            );

            // filter  filterData by schedule that is not empty
            filteredData = filteredData.filter(
                ({ restaurant_id }) =>
                    schedules[restaurant_id].schedule.length > 0
            );

            setRestaurants(filteredData);
            setScheduleByRestaurantId(schedules);

            try {
                const ipLookupResponse = await Axios.get(
                    'https://extreme-ip-lookup.com/json/'
                );
                setMiles(ipLookupResponse.data.country === 'United States');
            } catch {
                const locale = navigator.language || 'en-US'; // Default to 'en-US'
                setMiles(['en-US', 'en-LR', 'en-MM', 'my-MM'].includes(locale));
            }

            if (filteredData.length > 0) {
                setSelectedRestaurant(filteredData[0]);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    // Get restaurants from API
    useEffect(() => {
        loadData();
        window.addEventListener('resize', () =>
            setIsMobile(window.innerWidth < 600)
        );
    }, []);

    /**
     * @param {import('./GKLocationPickerDesktop').RequestData} action
     */
    const onRequestCallback = (action) => {
        switch (action.type) {
            case LocationPickerAction.SELECT_RESTAURANT:
                setSelectedRestaurant(action.restaurant);
                break;
            case LocationPickerAction.SELECT_DELIVERY:
                setIsDelivery(true);
                break;
            case LocationPickerAction.SELECT_PICKUP:
                setIsDelivery(false);
                getCurrentLocation();
                break;
            case LocationPickerAction.GET_CURRENT_LOCATION:
                getCurrentLocation();
                break;
            case LocationPickerAction.SELECT_RADIUS:
                setRadius(action.radius);
                break;
            case LocationPickerAction.SET_ADDRESS:
                findLocation(action.address);
                break;
        }
    };

    useEffect(() => {
        let filtered = restaurants.filter((r) => {
            return isDelivery
                ? r.delivery_type !== 'DELIVERY_OPTION_NO_DELIVERY'
                : true;
        });

        if (userLocation) {
            filtered = sortRestaurantsByDistance(
                filtered,
                userLocation,
                radius,
                miles
            );
            const distances = buildRestaurantDistances(
                filtered,
                userLocation,
                miles,
                radius
            );

            setRestaurantsDistances(distances);

            filtered = filtered.filter(
                (r) => distances[r.restaurant_id].inRange
            );

            // Choose the closest open location
            for (let i = 0; i < filtered.length; i++) {
                const restaurant = filtered[i];
                const hours = scheduleByRestaurantId[restaurant.restaurant_id];
                if (
                    hours &&
                    hours.openingToday !== 'Closed today' &&
                    distances[restaurant.restaurant_id].inRange
                ) {
                    setSelectedRestaurant(restaurant);
                    break;
                }
            }
        }

        setDisplayRestaurants(filtered);
    }, [restaurants, isDelivery, userLocation, radius, scheduleByRestaurantId]);

    // Get current user coordinates and find the restaurant nearest to them
    // If its mobile view, do not show store is closed
    const getCurrentLocation = () => {
        setIsLoading(true);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserLocation({
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude,
                });

                checkRestaurantsIsOpen();
                setIsLoading(false);
            },
            (error) => {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        alert('User denied the request to use location.');
                        break;
                    case error.POSITION_UNAVAILABLE:
                        alert('Location information is unavailable.');
                        break;
                    case error.TIMEOUT:
                        alert('The request to get user location timed out.');
                        break;
                    case error.UNKNOWN_ERROR:
                        alert('An unknown error occurred.');
                        break;
                }
                setIsLoading(false);
            }
        );
    };

    // Given an address, find its coordinates and the nearest restaurant to the address
    const findLocation = (address) => {
        setIsLoading(true);
        if (typeof address === 'object') {
            if (address && address.value && address.value.place_id) {
                geocodeByPlaceId(address.value.place_id).then((results) => {
                    if (
                        results[0] &&
                        results[0].geometry &&
                        results[0].geometry.location
                    ) {
                        setIsLoading(false);
                    }
                    // Choose the closest open location
                    for (let i = 0; i < restaurants.length; i++) {
                        const restaurant = restaurants[i];
                        const hours =
                            scheduleByRestaurantId[restaurant.restaurant_id];
                        if (
                            hours.openingToday !== 'Closed today' &&
                            restaurant.inRange
                        ) {
                            setSelectedRestaurant(restaurant);
                            break;
                        }
                    }
                    setIsLoading(false);
                });
            }
        }
    };

    //when click useLocation button, only show map view of restaurant opens today
    const checkRestaurantsIsOpen = () => {
        // if (isMobile() && showMobileMap) {
        // setFilteredRestaurants(
        //     filteredRestaurants.filter((entry) => {
        //         const { restaurant } = entry;
        //         const retObj = calculateOpeningHours(restaurant);
        //         const { openingToday } = retObj;
        //         return (
        //             openingToday !== 'Coming soon' &&
        //             openingToday !== 'Closed Today'
        //         );
        //     })
        // );
        // }
    };

    // Distance measurement unit
    const dUnit = miles ? 'mi' : 'km';

    const isDeliveryEnabled = useMemo(
        () =>
            displayRestaurants.some(
                (el) => el.delivery_type !== 'DELIVERY_OPTION_NO_DELIVERY'
            ),
        [restaurants]
    );

    if (isMobile) {
        return (
            <GKLocationPickerMobile
                isLoading={isLoading}
                isDelivery={isDelivery}
                restaurants={displayRestaurants}
                selectedRestaurant={selectedRestaurant}
                isDeliveryEnabled={isDeliveryEnabled}
                onRequestCallback={onRequestCallback}
                scheduleByRestaurantId={scheduleByRestaurantId}
                restaurantsDistances={restaurantsDistances}
                findLocation={findLocation}
                address={address}
                dUnit={dUnit}
            />
        );
    } else {
        return (
            <GKLocationPickerDesktop
                isLoading={isLoading}
                isDelivery={isDelivery}
                restaurants={displayRestaurants}
                selectedRestaurant={selectedRestaurant}
                isDeliveryEnabled={isDeliveryEnabled}
                onRequestCallback={onRequestCallback}
                address={address}
                dUnit={dUnit}
                scheduleByRestaurantId={scheduleByRestaurantId}
                restaurantsDistances={restaurantsDistances}
                radius={radius}
            />
        );
    }
};
export default GKLocationPickerWeb;
