import React from 'react';
import { Link } from 'react-router-dom';

const ProductsDropdown = (
    <div>
        <div className="v3HeaderDropdownSpace"></div>
        <div className="v3HeaderDropdownContent v3HeaderDropdownBg">
            <div className="v3HeaderDropdownRow">
                <Link to="/product/kiosk" style={{ color: 'inherit' }}>
                    <div className="v3HeaderProductContent">
                        <div className="v3HeaderProductSplitViewRight">
                            <img
                                className="v3HeaderProductIcon"
                                src="/assets/landing/v3/products/dropdown-kiosk.svg"
                            />
                        </div>
                        <div className="v3HeaderProductSplitViewSide">
                            <div className="v3HeaderDropdownProductTag">
                                Self-serve Kiosk
                            </div>
                            <div className="v3HeaderDropdownProductDscr">
                                Improve order accuracy & give patrons control of
                                their dining experience.{' '}
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/product/qr" style={{ color: 'inherit' }}>
                    <div className="v3HeaderProductContent">
                        <div className="v3HeaderProductSplitViewRight">
                            <img
                                className="v3HeaderProductIcon"
                                src="/assets/landing/v3/products/dropdown-qr.svg"
                                style={{ width: 43 }}
                            />
                        </div>
                        <div className="v3HeaderProductSplitViewSide">
                            <div className="v3HeaderDropdownProductTag">
                                Mobile Ordering
                            </div>
                            <div className="v3HeaderDropdownProductDscr">
                                View menus on your mobile phone and automate the
                                ordering process.
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            <div className="v3HeaderDropdownRow">
                <Link to="/product/dinein" style={{ color: 'inherit' }}>
                    <div className="v3HeaderProductContent">
                        <div className="v3HeaderProductSplitViewRight">
                            <img
                                className="v3HeaderProductIcon"
                                src="/assets/landing/v3/products/dropdown-mobile.svg"
                                style={{ width: '26px' }}
                            />
                        </div>
                        <div className="v3HeaderProductSplitViewSide">
                            <div className="v3HeaderDropdownProductTag">
                                Dine-in Management
                            </div>
                            <div className="v3HeaderDropdownProductDscr">
                                Enable order status notifications and payment.
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/product/delivery" style={{ color: 'inherit' }}>
                    <div className="v3HeaderProductContent">
                        <div className="v3HeaderProductSplitViewRight">
                            <img
                                className="v3HeaderProductIcon"
                                src="/assets/landing/v3/products/dropdown-delivery.svg"
                            />
                        </div>
                        <div className="v3HeaderProductSplitViewSide">
                            <div className="v3HeaderDropdownProductTag">
                                Delivery Integrations
                            </div>
                            <div className="v3HeaderDropdownProductDscr">
                                All your delivery platforms in one place.
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    </div>
);

export default ProductsDropdown;
