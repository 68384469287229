import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import * as OrderActions from '../../actions/orderActions';
import FinalizeOrderButton from './components/FinalizeOrderButton';

import { withTheme } from '../../themes/theming';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { i18n } from '../../locales';
import { withTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../endpoints';

class Category extends React.Component {
    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        const order = this.props.state.order.temporary_order;
        const item = order[order.length - 1];
        if (item) {
            toast.update('success', {
                render: (
                    <div className="toastSuccessContainer">
                        <img
                            alt="Checkmark"
                            className="toastCheckmark"
                            src="/assets/icons/checkmark-white.png"
                        ></img>
                        <div>
                            {i18n.t('toast.itemAddedToCart', {
                                itemMultiplier: item.multiplier,
                                itemName: item.name,
                            })}
                        </div>
                    </div>
                ),
            });
        }
    }

    itemSelected(item) {
        this.props.menuItemSelected(item, this.props.history);
    }

    renderItems(category) {
        const theme = this.props.theme;
        if (!category.items) {
            return;
        }

        const renderItemThumbnail = (item) => {
            const restaurant_id =
                this.props.state.main.restaurant_info.restaurant_id;
            let s3ImageUrl = `${cloudfrontBaseUrl}${restaurant_id}/items/${item.item_id}.jpeg`;

            return (
                <div style={{ margin: '5px', marginRight: '10px' }}>
                    <div
                        className="itemFlexImage"
                        style={{ backgroundImage: `url(${s3ImageUrl})` }}
                    >
                        <img
                            alt=""
                            className="itemFlexImage"
                            src={s3ImageUrl}
                            style={{ visibility: 'hidden' }}
                        ></img>
                    </div>
                </div>
            );
        };

        return category.items.map((itemData) => {
            const item = itemData.item;
            if (!item) {
                return <></>;
            }
            // chop the description
            let description = item.description;
            if (description) {
                if (description.length > 70) {
                    description = `${item.description.substring(0, 70)}...`;
                }
            }

            if (!item.price) {
                item.price = 0;
            }

            return (
                <div
                    className="itemFlexContainer"
                    key={item.item_id}
                    onClick={(e) => this.itemSelected(item)}
                >
                    <div className="itemContainer">
                        <div className="itemDetailsColumn">
                            <div className="itemHeader">
                                <div
                                    className="itemName"
                                    style={theme.menuItemLabel}
                                >
                                    {item.name}{' '}
                                    {!item.is_in_stock
                                        ? this.props.t('itemDetail.soldOut')
                                        : ''}
                                </div>
                            </div>
                            <div
                                className="itemDescription"
                                style={theme.itemDescription}
                            >
                                {description}
                            </div>
                            <div className="itemPrice" style={theme.itemPrice}>
                                {item.price > 0 ? item.price.toFixed(2) : ''}
                            </div>
                        </div>

                        <div className="itemRight">
                            {renderItemThumbnail(item)}
                            {/* <img alt="Arrow" src="/assets/thin-right-arrow.png" className="rightArrow"></img> */}
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderSpinner(show) {
        return show ? (
            <div className="cssload-container">
                <div className="cssload-whirlpool"></div>
            </div>
        ) : (
            ''
        );
    }

    render() {
        const category = this.props.state.order.selected_category;
        const theme = this.props.theme;

        const menu = this.props.state.order.selected_menu;

        return (
            <div>
                <Header
                    back="/oneMenu"
                    showGuestName
                    history={this.props.history}
                    backButtonText={menu.name}
                />

                <div className="categoryHeader" style={theme.menuCategoryTitle}>
                    {category.name}
                </div>

                {this.renderSpinner(
                    typeof category.items === 'object' &&
                        Object.keys(category.items).length === 0
                )}

                <div className="items" style={{ paddingBottom: '100px' }}>
                    {this.renderItems(category)}
                </div>

                <FinalizeOrderButton history={this.props.history} />
            </div>
        );
    }
}

// MSTP: Map State To Props
const MSTP = (state) => {
    return { state };
};

export default withTheme(
    connect(MSTP, {
        menuItemSelected: OrderActions.menuItemSelected,
        loadMenu: OrderActions.loadMenu,
    })(withTranslation()(Category))
);
