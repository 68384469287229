import { store } from './reduxStore';
import { toast } from 'react-toastify';
import { DineInOrTakeoutSetting, PaymentType } from './reducers/TYPES';
import stripeTerminal from './StripeTerminal';
import localforage from 'localforage';
import { isInteger } from 'lodash';
import { i18n } from './locales';
import { cloudfrontBaseUrl } from './endpoints';

export const modifierFrequency = (modifiers, element) => {
    let frequency = 0;
    modifiers.forEach((elem) => {
        if (elem.modifier_id === element.modifier_id) frequency++;
    });
    return frequency;
};

export const uniqueModifiersHasByID = (array, element) => {
    let flag = false;
    array.forEach((elem) => {
        if (elem.modifier_id === element.modifier_id) flag = true;
    });
    return flag;
};

export const isDineInPaymentsEnabled = () => {
    const restaurant = store.getState().main.restaurant_info;
    const has_stripe_acct = restaurant.stripe_acct;
    const has_nuvei_acct = restaurant.nuvei_acct;
    const has_nmi_key = restaurant.nmi_public_key;
    const has_bambora_acct = restaurant.bambora_mid;
    const dineInPaymentsEnabled = [
        'RESTAURANT_SETTING_DINE_IN_ONLY',
        'RESTAURANT_SETTING_TAKE_OUT_AND_DINE_IN',
    ].includes(restaurant.stripe_payments);

    return (
        dineInPaymentsEnabled &&
        (has_stripe_acct || has_nuvei_acct || has_nmi_key || has_bambora_acct)
    );
};

export const isRestaurantClosed = () => {
    return store.getState().main.restaurant_info.state !== 'ACTIVE';
};

export const isPaymentsEnabled = () => {
    const restaurant = store.getState().main.restaurant_info;
    const has_stripe_acct = restaurant.stripe_acct;
    const has_nuvei_acct = restaurant.nuvei_acct;
    const has_nmi_key = restaurant.nmi_public_key;
    const has_bambora_acct = restaurant.bambora_mid;
    const paymentsEnabledInSettings =
        restaurant.stripe_payments !== 'RESTAURANT_SETTING_NONE';

    return (
        (has_stripe_acct ||
            has_nuvei_acct ||
            has_nmi_key ||
            has_bambora_acct) &&
        paymentsEnabledInSettings
    );
};

export const isFrontOfHouse = () => {
    return store.getState().order.affiliate_code === `$frontOfHouse`;
};

export const isPayAtCounterOnly = () => {
    const has_stripe_acct = store.getState().main.restaurant_info.stripe_acct;
    const has_nuvei_acct = store.getState().main.restaurant_info.nuvei_acct;
    const has_bambora_acct = store.getState().main.restaurant_info.bambora_mid;
    const is_pay_at_counter_enabled = !['RESTAURANT_SETTING_NONE'].includes(
        store.getState().main.restaurant_info.pay_at_counter
    );
    return (
        !(has_stripe_acct || has_nuvei_acct || has_bambora_acct) &&
        is_pay_at_counter_enabled
    );
};

export const checkPaymentTerminalConnected = (state) => {
    const isPayAtCounterEnabled = [
        'RESTAURANT_SETTING_TAKE_OUT_ONLY',
        'RESTAURANT_SETTING_TAKE_OUT_AND_DINE_IN',
    ].includes(state.main.restaurant_info.pay_at_counter);

    let isNuveiConnected = false;
    if (
        Array.isArray(state.kiosk.nuvei_payment_terminals) &&
        state.kiosk.nuvei_selected_terminal
    ) {
        let matchingTerminals = state.kiosk.nuvei_payment_terminals.filter(
            (t) =>
                t.terminal_id ===
                state.kiosk.nuvei_selected_terminal.terminal_id
        );
        isNuveiConnected =
            matchingTerminals.length === 1 &&
            matchingTerminals[0].is_online === true;
    }
    const isReaderConnected =
        stripeTerminal.isReaderConnected() || isNuveiConnected;
    const paymentMethodExists = isPayAtCounterEnabled || isReaderConnected;

    return paymentMethodExists;
};

export const preloadImages = (imageSrcArray) => {
    // check if the image is already preloaded
    imageSrcArray.forEach((imgSrc) => {
        localforage.getItem(imgSrc).then((datePreloaded) => {
            if (!datePreloaded || !isInteger(datePreloaded)) {
                // preload image
                const img = new Image();
                img.src = imgSrc;
                localforage.setItem(imgSrc, new Date().getTime());
            } else {
                // image exists -- check if it needs to be reloaded (every day)
                const now = new Date().getTime();
                if (now - datePreloaded > 1000 * 60 * 60 * 12) {
                    const img = new Image();
                    img.src = imgSrc;
                    localforage.setItem(imgSrc, now);
                }
            }
        });
    });
};

// preload maintenace screen page
preloadImages(['/assets/icons/barricade.svg']);

export const convertTempItemToItemInstance = (temp_item, order) => {
    const itemInstance = temp_item.map((item) => {
        const item_copy = { ...item, price: Math.round(item.price * 100) };
        return {
            item_instance_id: item.item_instance_id,
            order_id: order.order_id,
            guest_id: order.guest_id,
            item: {
                ...item_copy,
            },
            modifiers: (item.modifiers || []).map((m) => {
                return { ...m, price: Math.round(m.price * 100) };
            }),
            multiplier: item.multiplier,
            special_instructions: item.special_instructions,
            campaign_instance_id: item.campaign_instance_id,
            is_reward_item: item.is_reward_item,
            analytics_menu_id: item.analytics_menu_id,
        };
    });

    return itemInstance;
};

export const getImageUrl = (type, id) => {
    let restaurantID = store.getState().main.restaurant_info.restaurant_id;
    switch (type) {
        case 'menu':
            return `${cloudfrontBaseUrl}${restaurantID}/menus/${id}.jpeg`;
        case 'items':
            return `${cloudfrontBaseUrl}${restaurantID}/items/${id}.jpeg`;
        case 'categories':
            return `${cloudfrontBaseUrl}${restaurantID}/categories/${id}.jpeg`;
        default:
            return;
    }
};

export const isEthos = () => {
    return (
        store.getState().main.restaurant_info.restaurant_id ===
        '2993daf5-c798-11ea-b545-02685a4295ea'
    );
};

export const isNam = () => {
    return (
        store.getState().main.restaurant_info.restaurant_id ===
        '6c01c773-dce2-11ea-b14e-02685a4295ea'
    );
};

export const isGhostKitchens = () => {
    return store.getState().main.restaurant_info.name.includes('Ghost Kitchen');
};

export const isAbsinthe = () => {
    return (
        store.getState().main.restaurant_info.restaurant_id ===
        '93764d2a-ed89-11ea-abad-02685a4295ea'
    );
};

export const isTakeout = () => {
    return store.getState().order.order_type === DineInOrTakeoutSetting.TAKEOUT;
};

export const isV2 = () => {
    return store.getState().main.version === 2;
};

export const isHotel = () => {
    const restaurant_id = store.getState().main.restaurant_info.restaurant_id;
    const hotels = ['65fcf46b-c0bb-11ea-abee-02685a4295ea'];
    return hotels.includes(restaurant_id);
};

export const isDisableSelectGuest = () => {
    return ['2993daf5-c798-11ea-b545-02685a4295ea'].includes(
        store.getState().main.restaurant_info.restaurant_id
    );
};

export const isTakeoutOrder = () => {
    return store.getState().order.order_type === DineInOrTakeoutSetting.TAKEOUT;
};

export const isDineinOrder = () => {
    return store.getState().order.order_type === DineInOrTakeoutSetting.DINE_IN;
};

export const isDevEnvironment = () => {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
};

export const checkIntervalAvailability = (i, current_time) => {
    const start_time = new Date(i.start_time);
    const end_time = new Date(i.end_time);

    const start_time_in_minutes =
        start_time.getUTCHours() * 60 + start_time.getUTCMinutes();
    const end_time_in_minutes =
        end_time.getUTCHours() * 60 + end_time.getUTCMinutes();
    const today_in_minutes =
        current_time.getHours() * 60 + current_time.getMinutes();
    return start_time_in_minutes <= end_time_in_minutes
        ? start_time_in_minutes <= today_in_minutes &&
              today_in_minutes <= end_time_in_minutes
        : start_time_in_minutes <= today_in_minutes ||
              today_in_minutes <= end_time_in_minutes;
};

export const checkItemsAreAvailableAtSpecifiedTime = () => {
    const today = new Date();
    const schedules = store.getState().order.menu.schedule;
    const temp_order = store.getState().order.temporary_order;

    let can_purchase_items = {};
    let can_continue = true;

    temp_order.forEach((item) => {
        const item_menu_id = item.menu_id;
        // find schedules where this menu appears
        const item_schedules = schedules.filter((s) => {
            if (s.menus) {
                return (
                    s.menus.filter((m) => {
                        return m.menu_id === item_menu_id;
                    }).length > 0
                );
            }

            return false;
        });

        // check if the time is valid for this item
        let can_purchase_item = false;

        if (item_schedules.length === 0) {
            // no schedule was created for this menu
            // assume it's available all the time
            can_purchase_item = true;
        }

        let item_intervals = [];
        item_schedules.forEach((s) => {
            // check if user's day of week is in the interval
            const day_of_week = today.getDay();
            // check if day of week exists
            const intervals = s.intervals.filter((i) => {
                return i.day_of_week - 1 === day_of_week; // -1 since intervals start from 1...7 in DB (not 0...6)
            });

            if (intervals.length > 0) {
                const interval = intervals[0];
                item_intervals.push(interval);
                can_purchase_item |= checkIntervalAvailability(interval, today);
            }
        });

        can_purchase_items[item.name] = can_purchase_item;
        if (!can_purchase_item) {
            can_continue = false;

            if (item_intervals.length > 0) {
                const start_time = new Date(item_intervals[0].start_time);
                const end_time = new Date(item_intervals[0].end_time);
                const start_time_str = start_time.toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                    timeZone: 'UTC',
                });
                const end_time_str = end_time.toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                    timeZone: 'UTC',
                });

                toast.info(
                    i18n.t('toast.itemOrderTimeRestriction1', {
                        itemName: item.name,
                        startTime: start_time_str,
                        endTime: end_time_str,
                    })
                );
            } else {
                toast.info(
                    i18n.t('toast.itemOrderTimeRestriction2', {
                        itemName: item.name,
                    })
                );
            }
        }
    });

    return can_continue;
};

export function combineItemsById(items, idKey) {
    const combinedItems = {};

    items.forEach((item) => {
        const id = item[idKey];
        if (combinedItems[id]) {
            combinedItems[id].count += 1;
        } else {
            combinedItems[id] = { ...item, count: 1 };
        }
    });

    return Object.values(combinedItems);
}
