import React from 'react';
import { connect } from 'react-redux';
import './css/DineSelect.css';
import { toast } from 'react-toastify';
import * as TableActions from '../actions/tableActions';
import Axios from 'axios';
import { APIv1Endpoint } from '../endpoints';
import { withTheme } from '../themes/theming';
import { i18n } from '../locales';
import { withTranslation } from 'react-i18next';
class DineSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_dine_in_selected: false,
            is_takeout_selected: false,
            is_loading: false,
        };
    }

    submitCoasterCode() {
        const local_id = this.props.state.table.local_id;
        const { restaurant_id } = this.props.state.main.restaurant_info;
        Axios.post(`${APIv1Endpoint}table/local`, {
            restaurant_id,
            local_id,
        })
            .then((res) => {
                const { table } = res.data;
                this.props.dineInTableFound(table, this.props.history);
            })
            .catch((err) => {
                toast.error(i18n.t('toast.tableNotFound1'));
                console.error(err.response);
            });
    }

    selectDineIn() {
        this.setState({
            is_dine_in_selected: true,
            is_takeout_selected: false,
        });
    }
    selectTakeaway() {
        this.setState({
            is_dine_in_selected: false,
            is_takeout_selected: true,
            is_loading: true,
        });
        this.props.takeAwaySelected(this.props.history);
    }

    renderSpinner(show) {
        return show ? (
            <div className="cssload-container">
                <div className="cssload-whirlpool"></div>
            </div>
        ) : (
            ''
        );
    }

    render() {
        const theme = this.props.theme;
        const local_id = this.props.state.table.local_id;

        // restaurant info
        const restaurant = this.props.state.main.restaurant_info;
        if (!restaurant) {
            return;
        }

        const renderCodeInput = (
            <div className="codeInputContainer">
                <input
                    type="text"
                    value={local_id}
                    onChange={(e) => {
                        const value = e.target.value.toUpperCase();
                        this.props.updateLocalId(value);
                    }}
                    placeholder={this.props.t('common.enterTable')}
                    className="codeInputTextField"
                ></input>
                <div
                    className="codeInputSubmitButton"
                    onClick={(e) => this.submitCoasterCode()}
                >
                    <img
                        alt=""
                        src="/assets/icons/arrow-right-white.png"
                        className="codeInputSubmitButtonArrow"
                    ></img>
                </div>
            </div>
        );

        return (
            <div className="dineSelectContainer">
                <div>
                    <img
                        alt=""
                        src={restaurant.logo}
                        className="dineSelectLogo"
                        style={theme.customDineSelect}
                    ></img>

                    <div className="dineSelectOptionsContainer">
                        {restaurant.order_mode !==
                        'RESTAURANT_SETTING_DINE_IN_ONLY' ? (
                            this.state.is_takeout_selected ? (
                                <div
                                    className="dineSelectOption selectedBack animateSlow"
                                    onClick={(e) => this.selectTakeaway()}
                                >
                                    <div className="dineSelectInnerContainer selectedInner animateSlow">
                                        <img
                                            alt=""
                                            src="/assets/icons/take-away-white.png"
                                            className="dineSelectIcon"
                                        ></img>
                                    </div>
                                    <div className="dineSelectLabel selectedLabel">
                                        {this.props.t('common.takeAway')}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="dineSelectOption"
                                    onClick={(e) => this.selectTakeaway()}
                                >
                                    <div className="dineSelectInnerContainer">
                                        <img
                                            alt=""
                                            src="/assets/icons/take-away.png"
                                            className="dineSelectIcon"
                                        ></img>
                                    </div>
                                    <div className="dineSelectLabel">
                                        {this.props.t('common.takeAway')}
                                    </div>
                                </div>
                            )
                        ) : (
                            ''
                        )}

                        {this.state.is_dine_in_selected ? (
                            <div
                                className="dineSelectOption selectedBack"
                                onClick={(e) => this.selectDineIn()}
                            >
                                <div className="dineSelectInnerContainer selectedInner">
                                    <img
                                        alt=""
                                        src="/assets/icons/dine-in-white.png"
                                        className="dineSelectIcon"
                                    ></img>
                                </div>
                                <div className="dineSelectLabel selectedLabel">
                                    {this.props.t('common.dineInOption')}
                                </div>
                            </div>
                        ) : (
                            <div
                                className="dineSelectOption"
                                onClick={(e) => this.selectDineIn()}
                            >
                                <div className="dineSelectInnerContainer">
                                    <img
                                        alt=""
                                        src="/assets/icons/dine-in.png"
                                        className="dineSelectIcon"
                                    ></img>
                                </div>
                                <div className="dineSelectLabel">
                                    {this.props.t('common.dineInOption')}
                                </div>
                            </div>
                        )}
                    </div>

                    {this.renderSpinner(this.state.is_loading)}

                    {this.state.is_dine_in_selected ? renderCodeInput : ''}
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });
const actions = {
    takeAwaySelected: TableActions.takeAwaySelected,
    dineInTableFound: TableActions.dineInTableFound,
    updateLocalId: TableActions.updateLocalId,
};

export default withTranslation()(withTheme(connect(mstp, actions)(DineSelect)));
