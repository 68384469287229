import React, { Component } from 'react';
import {
    AiFillCalendar,
    AiFillCreditCard,
    AiTwotoneSecurityScan,
} from 'react-icons/ai';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as OrderActions from '../../../actions/orderActions';
import Header from '../../../components/Header';
import ApplePayButton from './ApplePayButton';
import './css/PayBambora.css';
import './css/PayNMI.css';

class PayBambora extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        const customCheckout = window.customcheckout();
        this.state = {
            isSubmitting: false,
            customCheckout,
            token: null,
        };
    }

    componentDidMount() {
        var styles = {
            complete: { color: '#85e085' },
            error: { color: 'red' },
        };
        const cardNumber = this.state.customCheckout.create('card-number', {
            placeholder: '0000 0000 0000 0000',
            style: styles,
            brands: ['visa', 'mastercard', 'amex', 'discover'], // TODO: What brands do we have
        });
        cardNumber.mount('#card-number');
        const cardExpiry = this.state.customCheckout.create('expiry', {
            placeholder: 'MM/YY',
            style: styles,
        });
        cardExpiry.mount('#card-expiry');
        const cardCVV = this.state.customCheckout.create('cvv', {
            placeholder: '000',
            style: styles,
        });
        cardCVV.mount('#card-cvv');
    }

    handleSubmit(e) {
        this.setState({ isSubmitting: true });
        this.state.customCheckout.createToken((result) => {
            if (this.state.token) return;
            if (result.error) {
                console.log(result.error.message);
                toast.error(result.error.message);
            } else {
                this.setState({ token: result.token });
                this.props.processBamboraToken(
                    this.props.history,
                    result.token,
                    'PAYMENT_METHOD_BAMBORA'
                );
            }
        });
    }

    render() {
        return (
            <div>
                <Header back=":goBack" history={this.props.history} />
                <div className="column-container center payLayout">
                    <div
                        style={{
                            textAlign: 'center',
                            borderRadius: '5px',
                            padding: '1rem',
                            marginBottom: '10px',
                            marginTop: '5%',
                            backgroundColor: '#e6e6e6',
                            color: '#333',
                            width: '66vw',
                        }}
                    >
                        <img
                            style={{ width: '20px', padding: '10px' }}
                            src="/assets/icons/lock-black.png"
                            alt=""
                        />
                        <div style={{ padding: '5px' }}>
                            Your payment is secured by Bambora.
                        </div>
                    </div>
                    <div className="paymentText">
                        You Pay <br />
                        {this.props.state.order.payment_totals
                            .total_with_tip_and_tax
                            ? `$${this.props.state.order.payment_totals.total_with_tip_and_tax.toFixed(
                                  2
                              )}`
                            : 0}
                        {}
                    </div>
                    <hr />
                    <div
                        style={{
                            padding: '1rem',
                            alignItems: 'center',
                            width: '66vw',
                        }}
                    >
                        <div className="column-container">
                            <div className="column-container">
                                <div className="row-container center">
                                    <AiFillCreditCard
                                        style={{ fontSize: '2rem' }}
                                    />
                                    <div
                                        style={{
                                            width: '100%',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div id="card-number" />
                                    </div>
                                </div>
                                <div
                                    className="row-container center"
                                    style={{ flexWrap: 'wrap' }}
                                >
                                    <div className="input-container">
                                        <AiFillCalendar className="iconStyle" />
                                        <div id="card-expiry"></div>
                                    </div>
                                    <div
                                        className="input-container"
                                        id="last-row-media"
                                    >
                                        <AiTwotoneSecurityScan className="iconStyle" />
                                        <div id="card-cvv" />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: 'relative',
                                    alignSelf: 'center',
                                }}
                            >
                                <button
                                    style={{
                                        textTransform: 'none',
                                        backgroundColor: '#20B04B',
                                        color: 'white',
                                        fontWeight: '700',
                                        padding: '0.6rem 1rem 0.6rem 1rem',
                                        borderRadius: '5px',
                                        borderWidth: 0,
                                        width: '66vw',
                                        marginBottom: '0.5rem',
                                        opacity: this.state.isSubmitting
                                            ? 0.6
                                            : 1,
                                    }}
                                    type="button"
                                    disabled={this.state.isSubmitting}
                                    onClick={this.handleSubmit}
                                    id="pay-button"
                                >
                                    Pay
                                </button>
                            </div>
                            <hr />
                            <ApplePayButton history={this.props.history} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ state });

const mapDispatchToProps = {
    processBamboraToken: OrderActions.processBamboraToken,
};
export default connect(mapStateToProps, mapDispatchToProps)(PayBambora);
