import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const HamburgerIcon = styled.button<{ open: boolean }>`
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 33px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;

    &:focus {
        outline: none;
    }

    line {
        width: 31px;
        height: 3px;
        background: ${({ open }) => (open ? '#232323' : '#232323')};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) =>
                open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`;

interface BurgerProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Burger = ({ open, setOpen }: BurgerProps) => {
    return (
        <HamburgerIcon open={open} onClick={() => setOpen(!open)}>
            <line />
            <line />
            <line />
        </HamburgerIcon>
    );
};

interface MenuProps {
    menuContent: JSX.Element;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default (props: MenuProps) => {
    const { open, setOpen } = props;
    const [clickedOutside, setClickedOutside] = useState(false);
    const node = useRef<HTMLDivElement>(null);
    const handleClickInside = () => setClickedOutside(false);

    return (
        <div ref={node} onClick={handleClickInside}>
            <Burger open={open} setOpen={setOpen} />

            {open && !clickedOutside && (
                <div className="v3HamburgerContainer v3HamDropdownBg">
                    <div className="v3HamburgerContent">
                        {props.menuContent}
                    </div>
                </div>
            )}
        </div>
    );
};
