import React, { useMemo, useRef, useState } from 'react';
import './GhostKitchenLocationPicker.css';
import {
    FaLocationArrow,
    FaCaretUp,
    FaCaretDown,
    FaCrosshairs,
} from 'react-icons/fa';
import { HiShoppingCart } from 'react-icons/hi';
import { ImPhone } from 'react-icons/im';

import GKAnnotationDetails from './GKAnnotationDetails';
import GKGoogleMap, { buildRestaurantMarker } from './GKGoogleMap';
import GKGooglePlacesAutoComplete from './GKGooglePlacesAutoComplete';
import { LocationPickerAction } from './constants';
import { useClickAway } from '../../../hooks/use-click-away';
import { BASE_URL } from '../../../endpoints';

const RadiusDropdown = ({ radius, dUnit, onSelect }) => {
    const [isDropdownHidden, setIsDropdownHidden] = useState(true);
    const containerRef = useRef(null);

    const handleClickAway = () => {
        setIsDropdownHidden(true);
    };

    useClickAway(containerRef, handleClickAway);

    return (
        <>
            <div
                ref={containerRef}
                style={{
                    width: '100px',
                }}
                className="radiusList"
            >
                <div
                    className="searchRadiusButton2"
                    onClick={() => setIsDropdownHidden(!isDropdownHidden)}
                    style={{
                        borderColor: isDropdownHidden ? '#eaeaea' : ' #3AB649',
                    }}
                >
                    {radius} {dUnit}
                    <FaCaretDown size="0.8em" style={{ padding: '0 2px' }} />
                </div>

                {!isDropdownHidden && (
                    <div
                        className="radiusListOrder"
                        style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            width: '100px',
                            border: '1px solid #ccc',
                            backgroundColor: '#fff',
                            zIndex: 1,
                        }}
                    >
                        {[10, 20, 50, 100].map((el) => {
                            return (
                                <div
                                    key={`radius-${el}`}
                                    style={{ cursor: 'pointer' }}
                                    className="el"
                                    onClick={() => {
                                        onSelect(el);
                                        setIsDropdownHidden(true);
                                    }}
                                >
                                    {el} {dUnit}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};

/**
 * @typedef {Object} Restaurant
 * @property {string} name
 * @property {string} address
 * @property {string} phone_number
 * @property {string} endpoint
 * @property {number} restaurant_id
 * @property {number} distance
 * @property {number} latitude
 * @property {number} longitude
 * @returns
 */

/**
 * Represents a single restaurant item in a list.
 *
 * @function
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.restaurant - The restaurant object.
 * @param {string} props.dUnit - The unit of distance.
 * @param {boolean} props.isComingSoon - Indicates if the restaurant is coming soon.
 * @param {boolean} props.displayHours - Indicates if the opening hours should be displayed.
 * @param {Array} props.openingIntervals - The opening intervals of the restaurant.
 * @param {string} props.openingToday - The opening hours of the restaurant for the current day.
 * @param {boolean} props.preOrder - Indicates if pre-ordering is available.
 * @param {boolean} props.isSelected - Indicates if the restaurant is currently selected.
 * @param {onRequestCallback} props.onRequestCallback - The callback function to be called when the restaurant is clicked.
 * @returns {React.Component} The RestaurantListItem component.
 *
 */

/**
 * @typedef {function(RequestData)} onRequestCallback
 * @callback onRequestCallback
 * @param {RequestData} fps Fps info object
 */

/**
 * @typedef {Object} RequestData
 * @property {LocationPickerAction} type The type of the request
 * @property {Restaurant} restaurant The restaurant object
 * @property {number} radius The radius
 *
 *
 * @property {number} jitter The absolute difference since the last calculated fps
 * @property {number} elapsed Milliseconds ellapsed since the last computation
 * @property {number} frames Number of frames since the last computation
 * @property {number} trigger Next computation will happen at this amount of frames
 */

/**
 * @typedef {Object} Restaurant
 * @property {string} name
 * @property {string} address
 * @property {string} phone_number
 * @property {string} endpoint
 * @property {number} restaurant_id
 */

/**
 * @typedef {Object} RestaurantListItemProps
 * @property {Restaurant} restaurant
 * @property {string} dUnit
 * @property {boolean} isComingSoon
 * @property {boolean} displayHours
 * @property {Array} openingIntervals
 * @property {string} openingToday
 * @property {boolean} preOrder
 * @property {boolean} isSelected
 * @property {object} distance
 * @property {onRequestCallback} onRequestCallback
 */

/**
 * @function
 * @param {RestaurantListItemProps} props
 * @returns {React.Component} The RestaurantListItem component.
 *
 */
const RestaurantListItem = ({
    restaurant,
    dUnit,
    isComingSoon,
    openingIntervals,
    openingToday,
    preOrder,
    isSelected,
    distance,
    onRequestCallback,
}) => {
    const [isDisplayingHours, setIsDisplayHours] = useState(false);
    return (
        <div
            key={restaurant.endpoint}
            className="restaurantItem"
            onClick={() =>
                onRequestCallback({
                    type: 'SELECT_RESTAURANT',
                    restaurant,
                })
            }
            style={{
                border: isSelected ? '1px solid #3AB649' : 'none',
            }}
        >
            <h1 style={{ display: 'inline' }}>{restaurant.name}</h1>
            {distance ? (
                <span className="restaurantDistanceText">
                    {(distance.distance / 1000).toFixed(2)} {dUnit} away
                </span>
            ) : null}
            <div>
                <section className="restautantsAddressText">
                    {restaurant.address}
                </section>
            </div>

            {restaurant.phone_number && !isComingSoon && (
                <section
                    style={{
                        paddingBottom: '10px',
                    }}
                >
                    <span style={{ fontWeight: 500 }}>{'Phone: '}</span>
                    {restaurant.phone_number}
                </section>
            )}

            {isComingSoon && (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <div className="commingSoonText">{'Coming Soon!'}</div>
                </div>
            )}

            {isDisplayingHours && !isComingSoon ? (
                <div className="openHoursContainer">
                    <section>
                        <section
                            onClick={() => {
                                setIsDisplayHours(!isDisplayingHours);
                                onRequestCallback({
                                    type: LocationPickerAction.SELECT_RESTAURANT,
                                    restaurant,
                                });
                            }}
                            style={{
                                fontWeight: 500,
                                color: 'black',
                            }}
                        >
                            {openingToday} <FaCaretUp />
                        </section>
                        <GKAnnotationDetails
                            openingIntervals={openingIntervals}
                        />
                    </section>
                </div>
            ) : !isComingSoon ? (
                <div className="openHoursContainer">
                    <section
                        onClick={() => {
                            setIsDisplayHours(!isDisplayingHours);
                            onRequestCallback({
                                type: LocationPickerAction.SELECT_RESTAURANT,
                                restaurant,
                            });
                        }}
                        style={{
                            fontWeight: 500,
                            color: 'black',
                        }}
                    >
                        {openingToday} <FaCaretDown />
                    </section>
                </div>
            ) : null}
            {!isComingSoon && (
                <>
                    <br />
                    <div className="restaurantLocationActionContainer">
                        <div
                            className="restaurantLocationActionButton"
                            onClick={() =>
                                window.open(
                                    `tel:${restaurant.phone_number}`,
                                    '_blank'
                                )
                            }
                        >
                            <ImPhone
                                size="0.8em"
                                style={{
                                    marginRight: '6px',
                                }}
                            />
                            {'Call'}
                        </div>
                        <div
                            className="restaurantLocationActionButton"
                            onClick={() =>
                                window.open(
                                    `http://maps.apple.com/?q=${restaurant.address}`,
                                    '_blank'
                                )
                            }
                        >
                            <FaLocationArrow
                                size="0.8em"
                                style={{
                                    marginRight: '6px',
                                }}
                            />{' '}
                            Directions
                        </div>
                        <div
                            className="restaurantLocationOrderButton"
                            onClick={() =>
                                window.open(
                                    `${BASE_URL}/${restaurant.endpoint}`,
                                    '_blank'
                                )
                            }
                        >
                            <HiShoppingCart
                                size="0.8em"
                                style={{
                                    marginRight: '6px',
                                }}
                            />
                            {preOrder ? (
                                <span>{'Preorder'}</span>
                            ) : (
                                <span>{'Order'}</span>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

/**
 * @typedef {Object} GKLocationPickerDesktopProps
 * @property {Array} restaurants
 * @property {boolean} isLoading
 * @property {Restaurant} selectedRestaurant
 * @property {Object} scheduleByRestaurantId
 * @property {number} radius
 * @property {onRequestCallback} onRequestCallback
 * @property {function} onSelectRadius
 * @property {string} address
 * @property {function} findLocation
 * @property {boolean} isMobile
 * @property {boolean} isDelivery
 * @property {object} restaurantsDistances
 * @property {string} dUnit
 * @property {boolean} isDeliveryEnabled
 * @returns {React.Component} The GKLocationPickerDesktop component.
 *
 */

/**
 * @function
 * @param {GKLocationPickerDesktopProps} props
 * @returns {React.Component} The GKLocationPickerDesktop component.
 *
 **/

const GKLocationPickerDesktop = ({
    restaurants,
    isLoading,
    selectedRestaurant,
    scheduleByRestaurantId,
    radius,
    onRequestCallback,
    address,
    findLocation,
    isDelivery,
    restaurantsDistances,
    isDeliveryEnabled,
    dUnit,
}) => {
    const [isDeliveryDropdownVisible, setIsDeliveryDropdownVisible] =
        useState(false);

    const dropdownRef = useRef(null);

    useClickAway(dropdownRef, () => {
        setIsDeliveryDropdownVisible(false);
    });
    const selectedCoordinates = selectedRestaurant
        ? {
              lat: selectedRestaurant.latitude,
              lng: selectedRestaurant.longitude,
          }
        : null;

    const mapMarkers = useMemo(() => {
        if (
            restaurants.length === 0 ||
            Object.keys(scheduleByRestaurantId).length === 0
        ) {
            return [];
        }
        return restaurants.map(
            (el) => ({
                position: {
                    lat: el.latitude,
                    lng: el.longitude,
                },
                title: el.name,
                content: buildRestaurantMarker(
                    el,
                    scheduleByRestaurantId[el.restaurant_id]
                ),
                onClick: () =>
                    onRequestCallback({
                        type: 'SELECT_RESTAURANT',
                        restaurant: el,
                    }),
            }),
            [restaurants, scheduleByRestaurantId]
        );
    }, [restaurants, scheduleByRestaurantId]);

    let deliveryOptions = [
        {
            name: 'Pick Up',
            enabled: true,
            action: () =>
                onRequestCallback({ type: LocationPickerAction.SELECT_PICKUP }),
        },
        {
            name: 'Delivery',
            enabled: isDeliveryEnabled,
            action: () =>
                onRequestCallback({
                    type: LocationPickerAction.SELECT_DELIVERY,
                }),
        },
    ];

    const selectedDeliveryOption = isDelivery
        ? deliveryOptions[1]
        : deliveryOptions[0];

    const renderDeliveryOptions = isDelivery
        ? [deliveryOptions[1], deliveryOptions[0]]
        : [deliveryOptions[0], deliveryOptions[1]];

    return (
        <div className="restaurantLocationPickerContainer">
            <div className="restaurantList">
                <img
                    alt="Ghost Kitchens Logo"
                    src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                />
                <div className="restaurantSearchbarContainer">
                    <div ref={dropdownRef} className="radiusList deliveryType">
                        <div
                            className="searchRadiusButton2"
                            onClick={() =>
                                setIsDeliveryDropdownVisible(
                                    !isDeliveryDropdownVisible
                                )
                            }
                            style={{
                                borderColor: !isDeliveryDropdownVisible
                                    ? '#eaeaea'
                                    : ' #3AB649',
                            }}
                        >
                            {selectedDeliveryOption.name}
                            <FaCaretDown
                                size="0.8em"
                                style={{ padding: '0 2px' }}
                            />
                        </div>

                        {isDeliveryDropdownVisible && (
                            <div className="deliveryList">
                                {renderDeliveryOptions.map((option, index) => (
                                    <div
                                        key={`option-${option.name}`}
                                        // className={
                                        //     index === 0
                                        //         ? 'restaurantDeliveryButton2'
                                        //         : 'restaurantDeliveryButton3'
                                        // }
                                        className="el"
                                        style={{
                                            color: option.enabled
                                                ? undefined
                                                : 'rgba(85, 85, 85, 0.2)',
                                        }}
                                        onClick={() => {
                                            if (option.enabled) option.action();
                                            setIsDeliveryDropdownVisible(false);
                                        }}
                                    >
                                        {option.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {/* <div
                        className="restaurantSearchbarButton"
                        ref={dropdownRef}
                    >
                        {isDeliveryDropdownVisible ? (
                            renderDeliveryOptions
                                .filter((item) => item.enabled)
                                .map((option, index) => (
                                    <div
                                        key={`option-${option.name}`}
                                        className={
                                            index === 0
                                                ? 'restaurantDeliveryButton2'
                                                : 'restaurantDeliveryButton3'
                                        }
                                        style={{
                                            color: option.enabled
                                                ? undefined
                                                : 'rgba(85, 85, 85, 0.2)',
                                        }}
                                        onClick={() => {
                                            if (option.enabled) option.action();
                                            setIsDeliveryDropdownVisible(false);
                                        }}
                                    >
                                        {option.name}
                                        {index === 0 && (
                                            <FaCaretDown
                                                size="0.8em"
                                                style={{ padding: '0 2px' }}
                                            />
                                        )}
                                    </div>
                                ))
                        ) : (
                            <div
                                className="restaurantDropdownButtonClosed"
                                onClick={() =>
                                    setIsDeliveryDropdownVisible(true)
                                }
                            >
                                {selectedDeliveryOption.name}
                                <FaCaretDown
                                    size="0.8em"
                                    style={{ padding: '0 2px' }}
                                />
                            </div>
                        )}
                    </div> */}
                    <GKGooglePlacesAutoComplete
                        isMobile={false}
                        address={address}
                        onChange={(address) => {
                            onRequestCallback({
                                type: LocationPickerAction.SET_ADDRESS,
                                address,
                            });
                        }}
                    />
                </div>
                <div
                    className="useLocation"
                    onClick={() => {
                        onRequestCallback({
                            type: LocationPickerAction.GET_CURRENT_LOCATION,
                        });
                    }}
                >
                    <FaCrosshairs
                        size="0.8em"
                        style={{ marginTop: '5px', marginRight: '10px' }}
                    />
                    {'Use my location'}
                </div>
                <div className="restaurantSearchbarContainer">
                    <div>
                        <div className="restaurantDropdownButtonClosed">
                            {'Distance'}
                        </div>
                    </div>
                    <RadiusDropdown
                        radius={radius}
                        dUnit={dUnit}
                        onSelect={(r) => {
                            onRequestCallback({
                                type: LocationPickerAction.SELECT_RADIUS,
                                radius: r,
                            });
                        }}
                    />
                </div>
                {!isLoading && restaurants.length === 0 && (
                    <div className="noResultsContainer">
                        <div className="noResultsTag">
                            Sorry, no restaurant found
                        </div>
                        <div className="noResultsText">
                            Try different location or checkout different
                            distance please.
                        </div>
                    </div>
                )}
                {isLoading ? (
                    <div style={{ margin: 10 }}>Loading...</div>
                ) : (
                    <></>
                )}
                {!isLoading &&
                    restaurants.map((restaurant) => {
                        const isSelected =
                            selectedRestaurant.restaurant_id ===
                            restaurant.restaurant_id;
                        const {
                            isComingSoon,
                            openingIntervals,
                            openingToday,
                            preOrder,
                        } = scheduleByRestaurantId[restaurant.restaurant_id];
                        const distance =
                            restaurantsDistances[restaurant.restaurant_id];
                        return (
                            <RestaurantListItem
                                key={`rest-${restaurant.restaurant_id}`}
                                {...{
                                    dUnit,
                                    restaurant,
                                    isSelected,
                                    isComingSoon,
                                    openingIntervals,
                                    openingToday,
                                    preOrder,
                                    onRequestCallback,
                                    distance,
                                }}
                            />
                        );
                    })}
            </div>
            <div className="mapContainer">
                <GKGoogleMap
                    selectedCoordinates={selectedCoordinates}
                    markers={mapMarkers}
                />
            </div>
        </div>
    );
};

export default GKLocationPickerDesktop;
