import React, { useEffect } from 'react';
import '../../Menu/css/main.css';
import { Button, IconButton, makeStyles, Modal } from '@material-ui/core';
import { CheckCircleOutline, Close } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import amplitude from 'amplitude-js';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as TableActions from '../../../actions/tableActions';
import * as Types from '../../../reducers/TYPES';
import { store } from '../../../reduxStore';
import Axios from 'axios';
import { APIv1Endpoint } from '../../../endpoints';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    complete: {
        flex: '1',
        color: '#202020',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Poppins',
    },
    modal: {
        position: 'absolute',
        bottom: '0',
        backgroundColor: '#F5F5F7',
        width: '100%',
        height: '30%',
        fontFamily: 'Poppins',
        overflow: 'hidden',
        borderRadius: '1rem 1rem 0rem 0rem',
    },
}));

export default (props) => {
    const history = useHistory();
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const endpoint = params.endpoint;
    const affiliate = useSelector((state) => state.order.affiliate_code);
    const table_id = useSelector((state) => state.main.table_id);
    const guest_id = useSelector(
        (state) => state.table.selected_guest.guest_id
    );
    const { t } = useTranslation();

    const cart_items = useSelector((state) => state.order.temporary_order);
    const cartEmpty = !Array.isArray(cart_items) || cart_items.length === 0;

    const [trackingUrl, setTrackingUrl] = React.useState('');
    const [openCancel, setOpenCancel] = React.useState(false);
    const [cancelled, setCancelled] = React.useState(false);

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    useEffect(() => {
        setTimeout(getTrackingUrl(0), 1000);
    }, []);

    useEffect(() => {
        amplitude.getInstance().logEvent('Order Complete');
        dispatch({
            type: Types.DISCOUNT_CODE_FIELD_CHANGED,
            payload: '',
        });
        return () => {
            reset();
        };
    }, [dispatch, endpoint, history, affiliate]);

    const handleUnload = (e) => {
        reset();
    };

    const handleTrackingUrl = (val) => {
        setTrackingUrl(val);
    };

    const handleCancelOpen = () => {
        setOpenCancel(true);
    };

    const handleCancelClose = () => {
        setOpenCancel(false);
    };

    const handleCancelOrder = () => {
        Axios.post(`${APIv1Endpoint}delivery/cancel`, {
            guest_id: guest_id,
        }).then(
            (response) => {
                handleCancelClose();
                setCancelled(true);
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const getTrackingUrl = (callCount) => {
        const endpoint = `${APIv1Endpoint}table/${table_id.join('')}/guests`;
        Axios.get(endpoint).then(
            (response) => {
                for (let i = 0; i < response.data.guests.length; i++) {
                    if (guest_id === response.data.guests[i].guest_id) {
                        handleTrackingUrl(response.data.guests[i].tracking_url);
                        if (
                            !response.data.guests[i].tracking_url &&
                            callCount < 300
                        ) {
                            setTimeout(getTrackingUrl(++callCount), 1000);
                        }
                    }
                }
            },
            (error) => {
                console.error(error);
                setTimeout(getTrackingUrl(++callCount), 1000);
            }
        );
        /* eslint-disable */
    };

    const reset = () => {
        dispatch(
            TableActions.loadStandingTable(endpoint, history, affiliate, false)
        );
        dispatch({
            type: Types.CLEAR_TEMPORARY_ORDERS,
        });
        dispatch(TableActions.setVersion(2));
    };

    const addLiveTracker = () => {
        const deliveryOptionUpdated =
            store.getState().order.delivery_option_updated;
        if (cancelled) {
            return <div>{t('orderComplete.orderCancelled')}</div>;
        } else if (!deliveryOptionUpdated && trackingUrl) {
            return (
                <div>
                    <div className="checkCircleOutline">
                        <CheckCircleOutline
                            style={{
                                width: '1.5rem',
                                height: '1.5rem',
                                fill: '#202020',
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>
                                <div style={{ paddingLeft: '1rem' }}>
                                    {' '}
                                    {t('orderComplete.trackDelivery')}{' '}
                                </div>
                            </span>
                            <a href={trackingUrl}>
                                <IconButton>
                                    <DirectionsCarIcon fontSize="default" />
                                </IconButton>
                            </a>
                        </div>
                    </div>
                    {renderCancelModal()}
                    <Button
                        variant="contained"
                        onClick={handleCancelOpen}
                        style={{
                            width: '100vw',
                            backgroundColor: '#20B04B',
                            textTransform: 'none',
                            color: 'white',
                            borderRadius: 0,
                            padding: '1rem',
                            fontWeight: '700',
                        }}
                    >
                        {t('orderComplete.cancelOrder')}
                    </Button>
                    <div
                        style={{
                            width: '100vw',
                            height: 'env(safe-area-inset-bottom)',
                            backgroundColor: '#ECECEC',
                            zIndex: 1,
                        }}
                    ></div>
                </div>
            );
        } else if (!deliveryOptionUpdated && !trackingUrl) {
            return (
                <div
                    className="checkCircleOutline"
                    style={{ padding: '0rem 1rem 2rem 1.15rem' }}
                >
                    <CircularProgress size="28px" />
                    <div style={{ paddingLeft: '1rem' }}>
                        {t('orderComplete.placingDelivery')}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="checkCircleOutline">
                    <CheckCircleOutline
                        style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            fill: '#202020',
                        }}
                    />
                    <div style={{ paddingLeft: '1rem' }}>
                        {t('orderComplete.receiveTextNotification')}
                    </div>
                </div>
            );
        }
    };

    const renderCancelModal = () => {
        return (
            <Modal open={openCancel} onClose={handleCancelClose}>
                <div className={classes.modal}>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '1rem 0rem 1rem 0rem',
                            }}
                        >
                            <IconButton onClick={handleCancelClose}>
                                <Close />
                            </IconButton>
                            <div
                                style={{
                                    fontWeight: '700',
                                    fontSize: '1.25rem',
                                    flex: '1',
                                }}
                            >
                                {t('orderComplete.confirmCancelOrder')}
                            </div>
                        </div>
                        <button
                            style={{ marginLeft: '10px' }}
                            className="inputSubmit"
                            onClick={handleCancelOrder}
                            value="Yes"
                        >
                            {t('common.yes')}
                        </button>
                        <button
                            style={{ marginLeft: '10px' }}
                            className="inputSubmit"
                            onClick={handleCancelClose}
                            value="No"
                        >
                            {t('common.no')}
                        </button>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '1rem',
                            }}
                        ></div>
                    </div>
                </div>
            </Modal>
        );
    };

    const handleStartNewOrder = () => {
        history.replace(`/v2/${endpoint}/order`);
    };

    if (cartEmpty) {
        history.replace(`/v2/${endpoint}/order`);
    }

    return (
        <div className={classes.complete}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '2rem 1rem 2rem 1rem',
                }}
            >
                <div
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: '700',
                        flex: '1',
                        paddingRight: '1rem',
                    }}
                >
                    {t('orderComplete.orderPlaced')}
                </div>
                <CheckCircleOutline
                    style={{ width: '3rem', height: '3rem', fill: '#52CD19' }}
                />
            </div>
            <div
                style={{
                    padding: '0rem 1rem 0rem 1rem',
                    fontSize: '1.5rem',
                }}
            >
                {t('orderComplete.yourOrderNumber')}
            </div>
            <div
                style={{
                    padding: '0rem 1rem 0rem 1rem',
                    fontSize: '4rem',
                    fontWeight: '700',
                    color: '#52CD19',
                    flex: '1',
                }}
                data-cy="orderNumber"
            >
                {table_id}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '0rem 1rem 1rem 1rem',
                }}
            >
                <CheckCircleOutline
                    style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        fill: '#202020',
                    }}
                />
                <div style={{ paddingLeft: '1rem' }}>
                    {t('orderComplete.receiptTexted')}
                </div>
            </div>
            {addLiveTracker()}
            <Button
                variant="contained"
                data-cy="start-new-order-button"
                onClick={handleStartNewOrder}
                style={{
                    width: '100vw',
                    backgroundColor: '#ECECEC',
                    textTransform: 'none',
                    color: '#444444',
                    borderRadius: 0,
                    padding: '1rem',
                    fontWeight: '700',
                }}
            >
                {t('orderComplete.startNewOrder')}
            </Button>
            <div
                style={{
                    width: '100vw',
                    height: 'calc(env(safe-area-inset-bottom) + 29px)',
                    backgroundColor: '#ECECEC',
                    zIndex: 1,
                }}
            ></div>
        </div>
    );
};
