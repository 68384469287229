import React from 'react';
import { connect } from 'react-redux';
import * as OrderActions from '../../actions/orderActions';
import { PaymentType } from '../../reducers/TYPES';

import { withTheme } from '../../themes/theming';
import './css/PaySummary.css';
import { withRouter } from 'react-router';

import { HiPlus } from 'react-icons/hi';
import { isAbsinthe, isFrontOfHouse, isTakeout } from '../../utils';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return { state };
};

class DiscountCodeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDiscountCode: false,
        };
    }

    validateCode() {
        this.props.validateCode(this.props.history);
        this.setState({ showDiscountCode: false });
    }

    render() {
        const discountCode = this.props.state.order.discount_code_field;

        const renderDiscountCode = (
            <div className="discountCodeContainer">
                <div className="discountCodePrompt">
                    <div className="discountCodePromptHeader">
                        {this.props.t('cart.discountCode')}
                    </div>
                    <div className="discountCodeLabel">
                        {this.props.t('order.enterDiscountCode')}
                    </div>
                    <input
                        className="discountCodeInput"
                        value={discountCode}
                        onChange={(e) => {
                            this.props.updateDiscountCodeField(
                                e.target.value.toUpperCase()
                            );
                        }}
                    ></input>
                    <div className="discountCodeError"></div>
                    <div
                        className="twoFA-button"
                        style={{ margin: '0' }}
                        onClick={(e) => {
                            this.validateCode();
                        }}
                    >
                        {this.props.t('common.submit')}
                    </div>
                    <div
                        onClick={(e) => {
                            this.setState({ showDiscountCode: false });
                        }}
                        className="twoFA-button twoFA-button-cancel"
                        style={{ margin: '0', marginTop: '10px' }}
                    >
                        {this.props.t('common.cancel')}
                    </div>
                </div>
            </div>
        );

        return (
            <>
                <div
                    className="enterDiscountCodeButton"
                    onClick={(e) => {
                        this.setState({
                            showDiscountCode: !this.state.showDiscountCode,
                        });
                    }}
                >
                    <HiPlus style={{ marginRight: '10px' }} />
                    {this.props.t('cart.enterDiscountCode')}
                </div>

                {this.state.showDiscountCode ? renderDiscountCode : ''}
            </>
        );
    }
}

export const DiscountCode = withRouter(
    withTranslation()(
        connect(mapStateToProps, {
            updateDiscountCodeField: OrderActions.updateDiscountCodeField,
            calculateTotals: OrderActions.calculateTotals,
            updateGuestWithCampaignCode:
                OrderActions.updateGuestWithCampaignCode,
            validateCode: OrderActions.validateCode,
        })(DiscountCodeComponent)
    )
);
class PaySummary extends React.Component {
    componentDidMount() {
        // if disable tip, then set tip to 0%
        if (isAbsinthe() || isFrontOfHouse()) {
            this.props.changeTip(0);
        }

        const { isItemInstance, order } = this.props;
        this.props.calculateTotals(order, isItemInstance);
    }

    renderTotals(paymentType) {
        const {
            tax_amount,
            // total_with_tax,
            tip_amount,
            total_with_tip_and_tax,
            net_total,
            discount,
            tax_rate,
        } = this.props.state.order.payment_totals;

        let percentageLabel = String((tax_rate * 100).toFixed(0)) + '%';
        const context_FA = this.props.context === 'finalizeOrder';

        let render;

        if (
            (paymentType === PaymentType.PAY_IMMEDIATE) |
            (paymentType === PaymentType.PAY_LATER)
        ) {
            render = (
                <div className="orderTotalContainer">
                    <div className="orderTotalDivider"></div>

                    {context_FA ? <DiscountCode></DiscountCode> : ''}

                    {discount ? (
                        <div className="orderTotalRow">
                            <div className="orderTotalLabel">
                                {/* <GoInfo style={{marginLeft: '5px', color: "#469F5E"}} size="1.3em"/> */}
                                {this.props.t('common.discount')}
                            </div>
                            <div className="orderTotalPrice">
                                -{discount.toFixed(2)}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                        <div className="orderTotalRow">
                            <div className="orderTotalLabel">
                                {this.props.t('cart.subtotal')}&nbsp;
                            </div>
                            <div className="orderTotalPrice">
                                {(net_total + (discount || 0)).toFixed(2)}
                            </div>
                        </div>

                        <div className="orderTotalRow">
                            <div className="orderTotalLabel">
                                {this.props.t('cart.taxes')}&nbsp;(
                                {percentageLabel})&nbsp;
                            </div>
                            <div className="orderTotalPrice">
                                {tax_amount.toFixed(2)}
                            </div>
                        </div>

                        {(isFrontOfHouse && tip_amount > 0) ? (
                            <div className="orderTotalRow">
                                <div className="orderTotalLabel">
                                    {this.props.t('cart.tip')}&nbsp;
                                </div>
                                <div className="orderTotalPrice">
                                    {tip_amount.toFixed(2)}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        {(discount || 0) > 0 ? (
                            <div className="orderTotalRow">
                                <div className="orderTotalLabel">
                                    {' '}
                                    {this.props.t('cart.youSaved')}
                                </div>
                                <div className="orderTotalPrice">
                                    {discount.toFixed(2)}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>

                    <div className="orderTotalRow">
                        <div className="orderTotalLabel youPay">
                            {this.props.t('cart.total')}
                        </div>
                        <div className="orderTotalPrice youPay">
                            {total_with_tip_and_tax.toFixed(2)}
                        </div>
                    </div>

                    <div className="orderTotalDivider"></div>

                    {isTakeout() ? <></> : this.renderTipButtons(net_total)}
                </div>
            );
        }

        return render;
    }

    changeTip(tip) {
        this.props.changeTip(tip);

        // recalculate totals
        const { isItemInstance, order } = this.props;
        this.props.calculateTotals(order, isItemInstance);
    }

    renderTipButtons(netTotal) {
        const theme = this.props.theme;
        const tip = this.props.state.main.payment_tip;
        const tip_options = (
            this.props.state.main.restaurant_info.tip_options || []
        ).sort();

        return (
            <div className="tipContainer">
                <div className="tipTitle" style={theme.tipLabel}>
                    {this.props.t('cart.tip')} %
                </div>
                <div className="tipButtonContainer">
                    {tip_options.map((option) => {
                        option /= 100;
                        const label = `${parseFloat(option * 100)}%`;
                        const selected = option === tip;
                        return (
                            <div
                                key={'tip_' + option}
                                onClick={(e) => this.changeTip(option)}
                                className={
                                    selected ? 'tipButtonActive' : 'tipButton'
                                }
                                style={
                                    selected
                                        ? theme.tipButtonActive
                                        : theme.tipButton
                                }
                            >
                                {label}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    render() {
        const paymentType = this.props.state.order.payment_type;
        return <div>{this.renderTotals(paymentType)}</div>;
    }
}

export default withTheme(
    withTranslation()(
        connect(mapStateToProps, {
            changeTip: OrderActions.changeTip,
            calculateTotals: OrderActions.calculateTotals,
        })(PaySummary)
    )
);
