import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons//ArrowDropUp';
import '../../css/ItemScreen.css';
import '../../css/ModifierSelect.css';
import {
    findItem,
    closeItem,
    getModifierGroups,
    initializeModifiersMap,
    findMenu,
    isSelectedModifier,
    getModifiers,
    getModifierMultiplier,
    getInstruction,
} from '../utils';
import * as OrderActions from '../../../actions/orderActions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import LoadingScreen from '../LoadingScreen';
import {
    shouldDisplayFab,
    checkPreviousPath,
    getImage,
    resetItemState,
    sendUserBack,
    setSelectedItemEditMode,
    getModifierGroupTag,
} from '../utils';
import {
    FloatingActionButton,
    ItemQuantity,
} from '../Components/ItemComponents';
import MiniCart from '../Components/MiniCart';
import amplitude from 'amplitude-js';
import TextField from '@material-ui/core/TextField';
import {
    ModifierSelectIcon,
    ModifierQuantity,
} from '../Components/ItemComponents';
import { useTranslation } from 'react-i18next';

export default (props) => {
    //setup
    const history = useHistory();
    const thisOrder = useSelector((state) => state.order);
    const urlParams = useParams();
    const menuID = urlParams.menu_id;
    const dispatch = useDispatch();
    const curItem = findItem(thisOrder.menu, urlParams);
    const { previousPath } = props;
    const setupReduxState = useCallback(() => {
        amplitude.getInstance().logEvent('Item Selected', curItem?.item);
        if (thisOrder.is_edit_item && history.location.state) {
            setSelectedItemEditMode(history.location.state.cart_item, dispatch);
            history.replace({ state: undefined });
        } else if (
            !previousPath ||
            !checkPreviousPath(previousPath.pathname, urlParams)
        ) {
            resetItemState(dispatch, true);
        }
    }, [
        thisOrder.is_edit_item,
        history,
        previousPath,
        dispatch,
        urlParams,
        curItem,
    ]);

    useEffect(setupReduxState, []);

    useEffect(() => {
        dispatch(OrderActions.loadMenu());
    }, []);

    let itemImage;
    let modifierGroups = [];
    let modifiersMap = new Map();

    if (curItem) {
        itemImage = getImage(curItem.item.restaurant_id, curItem.item.item_id);
        modifierGroups = getModifierGroups(curItem);
        initializeModifiersMap(
            modifiersMap,
            modifierGroups,
            thisOrder.selected_item_modifiers
        );
    }

    const initialInstructions = thisOrder.selected_item_special_instructions;

    const [showInstruction, setShowInstruction] = useState(false);
    const [showQuantity, setShowQuantity] = useState(false);
    const cart_items = useSelector((state) => state.order.temporary_order);

    const { innerWidth: width } = window;
    const price = curItem
        ? curItem.item.price
            ? curItem.item.price.toFixed(2)
            : '0.00'
        : '';

    let displayPrice = Number(price);
    thisOrder.selected_item_modifiers.forEach(
        (mod) => (displayPrice += Number(mod.price))
    );
    displayPrice *= thisOrder.selected_item_quantity;

    const { t } = useTranslation();
    const renderImage = () => {
        return (
            <div>
                <div
                    className="ItemPhotoContainer"
                    style={{
                        backgroundImage: `linear-gradient(330deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35)), linear-gradient(200deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35))`,
                    }}
                >
                    <div
                        className="CloseItemButton"
                        onClick={() => {
                            sendUserBack(history, previousPath, urlParams);
                            resetItemState(dispatch, true);
                        }}
                    >
                        <CloseIcon
                            data-cy="itemClose"
                            style={{ fontSize: 24 }}
                        />
                    </div>
                    <div className="ItemHeader">{curItem.item.name}</div>
                </div>
                <LazyLoadImage
                    effect="opacity"
                    className="ItemImage"
                    src={itemImage}
                    width={width}
                    style={{ height: '40vh' }}
                />
            </div>
        );
    };

    const allClosed = Array(modifierGroups.length).fill(false, 0);
    const initialShowMods = modifierGroups.map((modGroup) => {
        return modGroup.item_modifier_group.min_qty > 0;
    });
    const [showMod, setShowMod] = useState(allClosed);
    useEffect(() => setShowMod([...initialShowMods]), []);

    return curItem ? (
        <Container
            data-cy="topContainer"
            style={{
                padding: 0,
                margin: 0,
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
            }}
        >
            <div
                className="content"
                style={{ display: 'flex', flexDirection: 'column', flex: '1' }}
            >
                {renderImage()}
                <div className="ItemDescriptionRow">
                    <div className="ItemDescription">${price}</div>
                    <div className="ItemDescription">
                        {curItem.item.description}
                    </div>
                </div>
                {modifierGroups.map((rootModifierGroup, index) => {
                    let modifierGroup = rootModifierGroup.item_modifier_group;
                    return (
                        <div className="SettingGroup" key={index}>
                            <div
                                className="SettingHeader"
                                onClick={() => {
                                    let newShowMod = showMod;
                                    newShowMod[index] = !showMod[index];
                                    setShowMod([...newShowMod]);
                                }}
                            >
                                <div>
                                    <div className="SelectRowHeader">
                                        {modifierGroup.modifier_group.name}
                                    </div>
                                    <div className="SelectRowStatus">
                                        {getInstruction(
                                            modifierGroup.min_qty,
                                            modifierGroup.max_qty
                                        )}
                                    </div>
                                </div>
                                {showMod[index] ? (
                                    <ArrowDropUpIcon />
                                ) : (
                                    <ArrowDropDownIcon />
                                )}
                            </div>
                            {showMod[index] ? (
                                <ModifierItem
                                    modifierGroup={modifierGroup}
                                    thisOrder={thisOrder}
                                    modifiersMap={modifiersMap}
                                    dispatch={dispatch}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                })}
                <div className="SettingGroup">
                    <div
                        onClick={(e) => setShowInstruction(!showInstruction)}
                        className="SettingHeader"
                    >
                        <div>
                            <div className="SelectRowHeader">
                                {t('order.specialInstructions')}
                            </div>
                            <div className="SelectRowStatus">
                                {t('common.optional')}
                            </div>
                        </div>
                        {showInstruction ? (
                            <ArrowDropUpIcon />
                        ) : (
                            <ArrowDropDownIcon />
                        )}
                    </div>
                    {showInstruction ? (
                        <div className="InstructionContainer">
                            <input
                                data-cy="specialInstructionsField"
                                value={initialInstructions}
                                onChange={(e) => {
                                    dispatch(
                                        OrderActions.menuItemSpecialInstructionsChanged(
                                            e.target.value
                                        )
                                    );
                                }}
                                id="outlined-basic"
                                variant="outlined"
                                className="InstructionInput"
                                placeholder={t('item.addNote')}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="SettingGroup">
                    <div
                        onClick={(e) => setShowQuantity(!showQuantity)}
                        className="SettingHeader"
                    >
                        <div>
                            <div className="SelectRowHeader">
                                {t('item.selectQuantity')}
                            </div>
                            <div className="SelectRowStatus">
                                {t('common.required')}
                            </div>
                        </div>
                        {showQuantity ? (
                            <ArrowDropUpIcon />
                        ) : (
                            <ArrowDropDownIcon />
                        )}
                    </div>
                    {showQuantity ? (
                        <div
                            className="content"
                            style={{
                                paddingTop: '15px',
                                paddingBottom: '50px',
                            }}
                        >
                            <ItemQuantity
                                order={thisOrder}
                                setStateQuantity={(newQuant) => {
                                    dispatch(
                                        OrderActions.setItemQuantity(newQuant)
                                    );
                                }}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div style={{ paddingBottom: '100px' }}></div>
                <FloatingActionButton
                    price={displayPrice.toFixed(2)}
                    itemOutOfStock={!curItem.item.is_in_stock}
                    data-cy="floatingActionButton"
                    shouldRender={shouldDisplayFab(
                        modifierGroups,
                        modifiersMap
                    )}
                    editMode={thisOrder.is_edit_item}
                    clickCallback={() => {
                        amplitude.getInstance().logEvent('Add to Cart', {
                            multiplier: curItem?.multiplier,
                            ...curItem?.item,
                        });
                        const finalSelectedModifiers =
                            thisOrder.selected_item_modifiers;
                        dispatch(
                            OrderActions.menuSelected(
                                findMenu(thisOrder.menu.menus, menuID),
                                null,
                                false,
                                false
                            )
                        );
                        if (thisOrder.is_edit_item) {
                            let itemToDelete = thisOrder.selected_item;
                            dispatch(
                                OrderActions.deleteItem(itemToDelete, false)
                            );
                            dispatch(
                                OrderActions.setSelectedItem(curItem.item)
                            );
                            dispatch(
                                OrderActions.menuItemSpecialInstructionsChanged(
                                    initialInstructions
                                )
                            );
                            dispatch(
                                OrderActions.addModifiersToItem(
                                    finalSelectedModifiers
                                )
                            );
                            dispatch(OrderActions.addToOrder(history, false));
                            sendUserBack(history, previousPath, urlParams);
                            resetItemState(dispatch, true);
                        } else {
                            dispatch(
                                OrderActions.setSelectedItem(curItem.item)
                            );
                            dispatch(
                                OrderActions.menuItemSpecialInstructionsChanged(
                                    initialInstructions
                                )
                            );
                            dispatch(
                                OrderActions.addModifiersToItem(
                                    finalSelectedModifiers
                                )
                            );
                            dispatch(OrderActions.addToOrder(history, false));
                            closeItem(thisOrder, history, urlParams);
                            resetItemState(dispatch, false);
                        }
                    }}
                />
            </div>
            {!thisOrder.is_edit_item ? <MiniCart /> : null}
        </Container>
    ) : (
        <LoadingScreen />
    );
};

const ModifierItem = ({ modifierGroup, thisOrder, modifiersMap, dispatch }) => {
    let curGroupModifiers = getModifiers(
        thisOrder.menu,
        modifierGroup.modifier_group.modifier_group_id
    );
    const maxSelections = modifierGroup.modifier_group.max_selections;
    const maxSelectionAllowed = Math.min(maxSelections, modifierGroup.max_qty);
    let selmod = modifiersMap.has(
        modifierGroup.modifier_group.modifier_group_id
    )
        ? modifiersMap.get(modifierGroup.modifier_group.modifier_group_id)
        : null;
    let selectedModifiersBefore = selmod ? selmod : [];
    const [selectedModifiers, setSelectedModifiers] = useState(
        selectedModifiersBefore
    );
    const { t } = useTranslation();

    const updateModifiers = (mods, selected) => {
        setSelectedModifiers(mods);
        dispatch(OrderActions.addModifiersToItem(selected.concat(mods)));
    };

    return (
        <div className="ModifierDetails">
            {curGroupModifiers.map((mod) => {
                const modPrice = mod.price
                    ? `(+$${parseFloat(mod.price).toFixed(2)})`
                    : '';
                const inStock = mod.is_in_stock;
                const opacity = inStock ? 1 : 0.3;
                return (
                    <div
                        className="ModifierRow"
                        key={mod.modifier_id}
                        data-cy="modifierRow"
                        onClick={() => {
                            if (!inStock) {
                                return;
                            }
                            let newArr = [];
                            thisOrder.selected_item_modifiers.forEach(
                                (selMod) => {
                                    if (
                                        selMod.modifier_group_id !==
                                        modifierGroup.modifier_group
                                            .modifier_group_id
                                    ) {
                                        newArr.push(selMod);
                                    }
                                }
                            );
                            if (isSelectedModifier(selectedModifiers, mod)) {
                                const filtered = selectedModifiers.filter(
                                    (selMod) =>
                                        selMod.modifier_id !== mod.modifier_id
                                );
                                setSelectedModifiers(filtered);
                                dispatch(
                                    OrderActions.addModifiersToItem(
                                        newArr.concat(filtered)
                                    )
                                );
                            } else {
                                let newSelectedModifiers = selectedModifiers;
                                if (
                                    selectedModifiers.length >=
                                    modifierGroup.max_qty
                                ) {
                                    newSelectedModifiers =
                                        newSelectedModifiers.slice(
                                            0,
                                            modifierGroup.max_qty - 1
                                        );
                                }
                                selectedModifiersBefore =
                                    newSelectedModifiers.length
                                        ? newSelectedModifiers.concat([mod])
                                        : [mod];
                                setSelectedModifiers(selectedModifiersBefore);
                                dispatch(
                                    OrderActions.addModifiersToItem(
                                        newArr.concat(selectedModifiersBefore)
                                    )
                                );
                            }
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="CenterVertically">
                                <div className="SelectButtonContainer">
                                    <ModifierSelectIcon
                                        isSelected={isSelectedModifier(
                                            selectedModifiers,
                                            mod
                                        )}
                                        max_qty={modifierGroup.max_qty}
                                        opacity={opacity}
                                    />
                                </div>
                            </div>
                            <div className="CenterVertically">
                                <div
                                    className="ModifierRowText"
                                    style={{ opacity }}
                                >
                                    {mod.name} {modPrice}
                                    <span className="SoldOut">
                                        {!inStock
                                            ? t('itemDetail.soldOut')
                                            : ''}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {maxSelections > 1 &&
                        isSelectedModifier(selectedModifiers, mod) ? (
                            <div className="CenterVertically">
                                <ModifierQuantity
                                    orderSelected={
                                        thisOrder.selected_item_modifiers
                                    }
                                    isKiosk={false}
                                    maxSelections={maxSelectionAllowed}
                                    selectedModifiers={selectedModifiers}
                                    setSelectedModifiers={updateModifiers}
                                    initialValue={
                                        getModifierMultiplier(
                                            selectedModifiers,
                                            mod
                                        ) || 1
                                    }
                                    modifier={mod}
                                />
                            </div>
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};
