import { combineReducers } from 'redux';

import MainReducer from './mainReducer';
import tableReducer from './TableReducer';
import orderReducer from './orderReducer';
import kioskReducer from './kioskReducer';

const rootReducer = combineReducers({
    main: MainReducer,
    table: tableReducer,
    order: orderReducer,
    kiosk: kioskReducer,
});

export default rootReducer;
