import React from 'react';
import { connect } from 'react-redux';
import { withTheme } from '../../themes/theming';
import { withTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../endpoints';
class AddedToOrder extends React.Component {
    goHome() {
        this.props.history.push('/menu/category');
    }

    render() {
        const restaurant_id =
            this.props.state.main.restaurant_info.restaurant_id;
        const logo = `${cloudfrontBaseUrl}${restaurant_id}/misc/logo.png`;

        const orders = this.props.state.order.temporary_order;
        const latestOrder = orders[orders.length - 1];

        return (
            <div>
                <div
                    className="completeContainer"
                    onClick={(e) => {
                        this.goHome();
                    }}
                >
                    <img
                        src={logo}
                        className="completeLogo"
                        alt="Restaurant Logo"
                    />

                    <div className="completeThankYouContainer">
                        <div className="completeThankYouText">
                            {this.props.t('order.added')}&nbsp;
                            {latestOrder.name}
                        </div>
                    </div>

                    <div className="addedToOrderDismissPrompt">
                        {this.props.t('order.tapToDismiss')}
                    </div>
                </div>
            </div>
        );
    }
}

const MSTP = (state) => {
    return { state };
};

export default withTranslation()(withTheme(connect(MSTP)(AddedToOrder)));
