import Axios from 'axios';
import { cloneDeep } from 'lodash';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import debounce from 'lodash/debounce';
import stripeTerminal from '../StripeTerminal';
import {
    APIv1AnalyticsEndpoint,
    APIv1Endpoint,
    cloudfrontBaseUrl,
} from '../endpoints';
import { GAEvent } from '../googleAnalytics';
import { i18n } from '../locales';
import * as Types from '../reducers/TYPES';
import { store } from '../reduxStore';
import {
    convertTempItemToItemInstance,
    isDevEnvironment,
    isDineinOrder,
    isFrontOfHouse,
    isHotel,
    preloadImages,
} from '../utils';
import * as TableActions from './tableActions';
import { loadOrders } from './tableActions';
import { areModifiersEqual, isEqualSpecialInstructions } from './util';

const convert = require('xml-js');

const removeSimilarItems = (items_array, parameter) => {
    let item_seen = {};
    let unique_items_array = [];

    if (Array.isArray(items_array)) {
        items_array.forEach((item) => {
            const value = item[parameter];
            if (value in item_seen) {
                return;
            } else {
                item_seen[value] = 0;
                unique_items_array.push(item);
            }
        });

        return unique_items_array;
    }
};

export const v2ShowTapToCheckout = () => (dispatch, getState) => {
    if (getState().order.tutorial_1_shown) {
        return;
    }
    dispatch({
        type: Types.TAP_TO_CHECKOUT_NOTIF_SHOWN,
    });
    dispatch({
        type: Types.TAP_TO_CHECKOUT_NOTIF_DISPLAY,
        payload: true,
    });
};

export const v2HideTapToCheckout = () => (dispatch, getState) => {
    dispatch({
        type: Types.TAP_TO_CHECKOUT_NOTIF_DISPLAY,
        payload: false,
    });
};

export const v2ShowReadyToPay = (show) => (dispatch, getState) => {
    dispatch({
        type: Types.READY_TO_PAY_CHECKOUT_DISPLAY,
        payload: show,
    });
};

const formatMenus = (item_ids, current, menus) => {
    let formatted = current;
    item_ids.forEach((item_id) => {
        menus.forEach((menu) => {
            if (menu.categories) {
                menu.categories.forEach((category) => {
                    if (category.items && category.items) {
                        const matchedItems = category.items.filter((i) => {
                            if (i.item) {
                                return i.item.item_id === item_id;
                            }
                            return false;
                        });
                        if (matchedItems.length === 1) {
                            let item_obj = {
                                ...matchedItems[0].item,
                            };
                            item_obj['category'] = category; // might be rekt (circular reference)
                            item_obj['category_id'] =
                                category.category.category_id;
                            formatted.push(item_obj);
                        }
                    }
                    return null;
                });
            }
        });
    });

    // remove items with similar names (i.e. Cinnabon that appears in two menus)
    formatted = removeSimilarItems(formatted, 'name');
    // remove items from similar category
    formatted = removeSimilarItems(formatted, 'category_id');

    return formatted;
};

const loadPopularItems = (
    current_recommendations,
    restaurant_id,
    menus,
    seen,
    dispatch
) => {
    const now = new Date();
    const end_date_range = now.toISOString();
    now.setMonth(now.getMonth() - 1);
    const start_date_range = now.toISOString();

    const topTenRequest = {
        query: {
            measures: ['ItemInstances.count'],
            filters: [
                {
                    member: 'Guests.createTime',
                    operator: 'inDateRange',
                    values: [start_date_range, end_date_range], // start and end time
                },
                {
                    member: 'Restaurants.restaurantId',
                    operator: 'equals',
                    values: [restaurant_id], // restaurant id
                },
                {
                    member: 'Items.isActive',
                    operator: 'equals',
                    values: ['true'],
                },
                {
                    member: 'Items.isInStock',
                    operator: 'equals',
                    values: ['true'],
                },
            ],
            order: {
                'ItemInstances.count': 'desc',
            },
            dimensions: ['Items.itemId'],
            limit: 10,
        },
    };

    Axios.post(`${APIv1AnalyticsEndpoint}cubejs-api/v1/load/`, topTenRequest)
        .then((resp) => {
            if (resp.data.data) {
                // get item details from item id
                const rawItemsArray = resp.data.data;
                const item_ids = rawItemsArray.map((i) => i['Items.itemId']);
                let recommendations = [];
                item_ids.forEach((id) => {
                    if (!(id in seen)) {
                        recommendations.push(id);
                    }
                    seen[id] = true;
                });
                const formattedItemsArray = formatMenus(
                    recommendations,
                    current_recommendations,
                    menus
                );
                dispatch({
                    type: Types.TOP_TEN_LOADED,
                    payload: formattedItemsArray,
                });
            }
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: Types.TOP_TEN_LOADED,
                payload: current_recommendations,
            });
        });
};

export const loadRecommendations = () => (dispatch, getState) => {
    const menus = getState().order.menu.menus;
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    const cart_item_ids = getState().order.temporary_order.map(
        (o) => o.item_id
    );
    const request = {
        query: {
            measures: ['Recommendations.score'],
            filters: [
                {
                    member: 'Recommendations.restaurantId',
                    operator: 'equals',
                    values: [restaurant_id], // restaurant id
                },
                {
                    member: 'Recommendations.itemId',
                    operator: 'contains',
                    values: cart_item_ids,
                },
                {
                    member: 'Items.isActive',
                    operator: 'equals',
                    values: ['true'],
                },
                {
                    member: 'Items.isInStock',
                    operator: 'equals',
                    values: ['true'],
                },
            ],
            order: {
                'Recommendations.score': 'desc',
            },
            dimensions: ['Recommendations.recommendationItemId'],
            limit: 10,
        },
    };

    Axios.post(`${APIv1AnalyticsEndpoint}cubejs-api/v1/load/`, request)
        .then((res) => {
            console.log(res);
            const item_ids = res.data.data.map(
                (i) => i['Recommendations.recommendationItemId']
            );
            // Filtering out duplicate ids or ids already in cart.
            let seen = {};
            let recommendations = [];
            item_ids.forEach((id) => {
                if (!(id in seen) && !cart_item_ids.includes(id)) {
                    recommendations.push(id);
                }
                seen[id] = true;
            });

            let formattedItemsArray = formatMenus(recommendations, [], menus);

            if (formattedItemsArray.length <= 10) {
                // Add some recommendations using top items from restaurant
                loadPopularItems(
                    formattedItemsArray,
                    restaurant_id,
                    menus,
                    seen,
                    dispatch
                );
            } else {
                dispatch({
                    type: Types.TOP_TEN_LOADED,
                    payload: formattedItemsArray,
                });
            }
        })
        .catch((err) => {
            console.error(err);
        });
};

export const loadNuveiTerminals = () => (dispatch, getState) => {
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    Axios.get(`${APIv1Endpoint}terminal/${restaurant_id}/list`)
        .then((terminalResponse) => {
            let terminals = terminalResponse.data.terminals;

            //  filter out stripe terminals
            terminals = terminals.filter((t) => !t.terminal_id.includes('tmr'));

            // check terminal status
            Axios.post(`${APIv1Endpoint}nuveiTerminalStatus`, {
                terminal_ids: terminals.map((t) => t.terminal_id),
                restaurant_id,
            }).then((terminalStatusResponse) => {
                if (terminalStatusResponse.status !== 200) {
                    console.error(terminalStatusResponse);
                    return;
                }

                if (
                    Object.keys(terminalStatusResponse.data.statuses).length ===
                    terminals.length
                ) {
                    let sel_terminal = getState().kiosk.nuvei_selected_terminal;

                    terminals.forEach((terminal) => {
                        terminal.is_online =
                            terminalStatusResponse.data.statuses[
                                terminal.terminal_id
                            ];
                    });

                    // update the status of the currently selected terminal
                    if (
                        sel_terminal &&
                        sel_terminal.terminal_id in
                            terminalStatusResponse.data.statuses
                    ) {
                        sel_terminal.is_online =
                            terminalStatusResponse.data.statuses[
                                sel_terminal.terminal_id
                            ];
                        dispatch({
                            type: Types.NUVEI_PAYMENT_TERMINAL_SELECTED,
                            payload: sel_terminal,
                        });
                    }

                    // compare terminals - reduce number of renders
                    const terminals_state =
                        getState().kiosk.nuvei_payment_terminals;
                    if (
                        JSON.stringify(terminals_state) !==
                        JSON.stringify(terminals)
                    ) {
                        dispatch({
                            type: Types.NUVEI_PAYMENT_TERMINALS_LOADED,
                            payload: terminals,
                        });
                    }
                }
            });
        })
        .catch((err) => {
            console.error(err);
            dispatch({
                type: Types.NUVEI_ERROR_CHANGED,
                payload: err.response,
            });
        });
};

export const selectNuveiTerminal = (terminal) => (dispatch, getState) => {
    dispatch({
        type: Types.NUVEI_PAYMENT_TERMINAL_SELECTED,
        payload: terminal,
    });

    if (terminal !== {}) {
        dispatch({
            type: Types.TERMINAL_SET_CONNECTED,
            payload: true,
        });
        dispatch({
            type: Types.TERMINAL_IS_CONNECTING,
            payload: false,
        });
        dispatch({
            type: Types.TERMINAL_VENDOR_CHANGED,
            payload: Types.TerminalVendors.NUVEI,
        });
    } else {
        dispatch({
            type: Types.TERMINAL_SET_CONNECTED,
            payload: false,
        });
        dispatch({
            type: Types.TERMINAL_IS_CONNECTING,
            payload: false,
        });
        dispatch({
            type: Types.TERMINAL_VENDOR_CHANGED,
            payload: Types.TerminalVendors.NONE,
        });
    }
};

export const orderDateTypeChanged = (orderDateType) => (dispatch) => {
    dispatch({
        type: Types.ORDER_DATE_TYPE_CHANGED,
        payload: orderDateType,
    });
};

export const orderTimeChanged = (orderTime) => (dispatch) => {
    dispatch({
        type: Types.ORDER_TIME_CHANGED,
        payload: orderTime,
    });
};

export const twoFACodeRequested = () => (dispatch, getState) => {
    const now = new Date().getTime();
    dispatch({
        type: Types.TWOFA_CODE_TIME_REQUESTED,
        payload: now,
    });
};

const convertTempOrderToUnnestedItemInstance = (order, guest_id) => {
    let unnestedItemInstances = [];
    order.forEach((item) => {
        // TODO: is this merge conflict resolution done right
        const {
            item_id,
            multiplier,
            modifiers,
            special_instructions,
            item_instance_id,
            order_id,
            campaign_instance_id,
            is_reward_item,
            analytics_menu_id,
        } = item;

        let modifier_ids = [];
        modifiers.forEach((m) => {
            modifier_ids.push(m.modifier_id);
        });

        const uii = {
            item_instance_id,
            order_id,
            guest_id,
            item_id,
            multiplier,
            modifier_ids,
            special_instructions,
            campaign_instance_id,
            is_reward_item,
            analytics_menu_id,
        };

        unnestedItemInstances.push(uii);
    });
    return unnestedItemInstances;
};

export const payAtCounter =
    (history, redirect = true, clear_orders = true) =>
    (dispatch, getState) => {
        dispatch({
            type: Types.PAY_AT_COUNTER_LOADING,
            payload: true,
        });

        const order = getState().order.temporary_order;
        const guest_id = getState().table.selected_guest.guest_id;
        const restaurant_id = getState().main.restaurant_info.restaurant_id;
        const endpoint = getState().main.restaurant_info.endpoint;
        const campaign_instances = getState().order.campaign_instances;
        const v2 = getState().main.version === 2;

        GAEvent('User', 'guest selected pay at counter', guest_id);

        const unnestedItemInstances = convertTempOrderToUnnestedItemInstance(
            order,
            guest_id
        );

        const request = {
            item_instances: unnestedItemInstances,
            campaigns: campaign_instances,
            restaurant_id,
            guest_id,
        };

        Axios.post(`${APIv1Endpoint}orders/addToOrder`, request)
            .then((res) => {
                if (clear_orders) {
                    if (!v2) {
                        dispatch({
                            type: Types.CLEAR_TEMPORARY_ORDERS,
                        });
                    }
                    dispatch({
                        type: Types.CLEAR_CAMPAIGN_INSTANCES,
                    });
                }

                // check if guest has a valid phone number
                const pn = getState().table.selected_guest.phone_number;
                if (typeof pn === 'string' && isValidPhoneNumber(pn)) {
                    dispatch(TableActions.submitPhoneNumber());
                }

                // Show loading
                if (redirect) {
                    if (!v2) {
                        toast.success(i18n.t('toast.orderPlaced'));
                    }
                    history.push(
                        v2 ? `/v2/${endpoint}/orderComplete` : '/orderComplete'
                    );
                }

                dispatch({
                    type: Types.PAY_AT_COUNTER_LOADING,
                    payload: false,
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.error(err.response);
                    if (
                        err.response.status === 503 &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        toast.error(i18n.t('toast.errorNotification3'), {
                            toastId: 'payAtCounterError',
                        });
                    } else {
                        toast.error(i18n.t('toast.errorNotification3'), {
                            toastId: 'payAtCounterError',
                        });
                    }
                } else if (err.request) {
                    console.error(err.request);
                    toast.error(i18n.t('toast.errorNotification3'), {
                        toastId: 'payAtCounterError',
                    });
                } else {
                    console.error(err);
                    toast.error(i18n.t('toast.errorNotification3'), {
                        toastId: 'payAtCounterError',
                    });
                }
                if (redirect) {
                    history.push(v2 ? `/v2/${endpoint}/cart` : '/logoview');
                }
                dispatch({
                    type: Types.PAY_AT_COUNTER_LOADING,
                    payload: false,
                });
            });
    };

export const paymentComplete = (intent, history) => (dispatch, getState) => {
    const endpoint = getState().main.restaurant_info.endpoint;
    const v2 = getState().main.version === 2;

    const guest_id = getState().table.selected_guest.guest_id;
    GAEvent('User', 'Payment complete for guest', guest_id);

    dispatch({
        type: Types.PAYMENT_INTENT_COMPLETED,
        payload: intent,
    });
    if (!v2) {
        dispatch({
            type: Types.CLEAR_TEMPORARY_ORDERS,
        });
    }
    dispatch({
        type: Types.CLEAR_CAMPAIGN_INSTANCES,
    });

    const isDineIn = isDineinOrder();

    if (isDineIn) {
        history.replace(v2 ? `/v2/${endpoint}/cart` : '/orders');
        toast.success(i18n.t('toast.paymentReceived'));
        dispatch(TableActions.submitPhoneNumber());
        dispatch(loadOrders());
        TableActions.resetState();
    } else {
        // reload orders
        dispatch(loadOrders());
        history.replace(
            v2 ? `/v2/${endpoint}/orderComplete` : '/orderComplete'
        );
        // send sms notification
        dispatch(TableActions.submitPhoneNumber());
        TableActions.resetState();
    }
};

export const getPaymentIntent_PayLater = (history) => (dispatch, getState) => {
    // reset payment intent
    dispatch({
        type: Types.PAYMENT_INTENT_RECEIVED,
        payload: {},
    });

    const guest_id = getState().table.selected_guest.guest_id;
    const restaurant_id = getState().main.restaurant_info.restaurant_id;

    // PAYMENT SETTINGS
    // check restaurant has payment accounts setup
    const has_stripe_acct = getState().main.restaurant_info.stripe_acct;
    const has_nuvei_acct = getState().main.restaurant_info.nuvei_acct;
    const nmi_public_key = getState().main.restaurant_info.nmi_public_key;

    if (!(has_stripe_acct || has_nuvei_acct || nmi_public_key)) {
        toast.error(i18n.t('toast.paymentSetupError'), {});
    }

    const tipType = getState().order.payment_tip_type;
    const tip = Math.round(getState().order.payment_tip * 100);
    const tipPercentage = tipType === 'percentage' ? tip : undefined;
    const tipFixed = tipType === 'fixed' ? tip : undefined;

    // TODO: Support NMI
    const request = {
        guest_id,
        restaurant_id,
        tip_percentage: tipPercentage,
        tip_fixed: tipFixed,
        delivery_tip: 0,
    };

    const reqEndpoint = `${APIv1Endpoint}orders/pay`;
    const endpoint = getState().main.restaurant_info.endpoint;

    Axios.post(reqEndpoint, request)
        .then((res) => {
            const payment_service = res.data.payment_service;

            switch (payment_service) {
                case 'PAYMENT_SERVICE_NUVEI':
                    dispatch({
                        type: Types.PAYMENT_PROCESSOR_CHANGED,
                        payload: Types.PaymentProcessor.NUVEI,
                    });
                    dispatch({
                        type: Types.NUVEI_PAYMENT_REQUEST_RECEIVED,
                        payload: JSON.parse(res.data.response),
                    });

                    const paymentRequestObject = JSON.parse(res.data.response);

                    let searchParams = {
                        AMOUNT: parseFloat(paymentRequestObject.Amount).toFixed(
                            2
                        ),
                        CURRENCY: paymentRequestObject.Currency.toUpperCase(),
                        ORDERID: paymentRequestObject.OrderID,
                        TERMINALID: paymentRequestObject.TerminalID,
                        DATETIME: paymentRequestObject.DateTime,
                        HASH: paymentRequestObject.Hash,
                        INIFRAME: 'N',
                    };

                    const params = new URLSearchParams(searchParams);

                    let nuvei_hostname = `payments.nuvei.com`;
                    if (isDevEnvironment()) {
                        nuvei_hostname = `testpayments.nuvei.com`;
                    }

                    const url = `https://${nuvei_hostname}/merchant/paymentpage?${params.toString()}`;
                    window.open(url, '_self');
                    break;
                case 'PAYMENT_SERVICE_STRIPE':
                    dispatch({
                        type: Types.PAYMENT_PROCESSOR_CHANGED,
                        payload: Types.PaymentProcessor.STRIPE,
                    });
                    dispatch({
                        type: Types.PAYMENT_INTENT_RECEIVED,
                        payload: JSON.parse(res.data.response),
                    });
                    history.push(`/v2/${endpoint}/payStripe`);
                    break;
                default:
                    // item was free, navigate user to payment complete page
                    history.push(`/v2/${endpoint}/orderComplete`);
                    break;
            }
        })
        .catch((err) => console.error(err.response));
};

export const setTwoFACompleted = (value) => (dispatch, getState) => {
    dispatch({
        type: Types.TWOFA_COMPLETION_CHANGED,
        payload: value,
    });
};

export const updateGuestWithCampaignCode =
    (code, campaign_metadata) => (dispatch, getState) => {
        Axios.post(`${APIv1Endpoint}guest/update`, {
            guest: {
                ...getState().table.selected_guest,
                campaign_code: code,
            },
        })
            .then((res) => {
                if (
                    campaign_metadata &&
                    Object.keys(campaign_metadata).length > 0
                ) {
                    dispatch({
                        type: Types.CAMPAIGN_CODE_METADATA_UPDATED,
                        payload: campaign_metadata,
                    });
                } else {
                    dispatch({
                        type: Types.CAMPAIGN_CODE_METADATA_UPDATED,
                        payload: {},
                    });
                }
                // load same guest from remote
                const table_id = getState().table.selected_guest.table_id;
                Axios.get(`${APIv1Endpoint}table/${table_id}/guests`)
                    .then((r) => {
                        if (r.data.guests) {
                            // find guest
                            const guests = r.data.guests;
                            const guest = guests.filter((g) => {
                                return (
                                    g.guest_id ===
                                    getState().table.selected_guest.guest_id
                                );
                            })[0];
                            dispatch({
                                type: Types.GUEST_SELECTED,
                                payload: {
                                    selected_guest: guest,
                                },
                            });
                        }
                    })
                    .catch((err) => {
                        console.error(err.response);
                        toast.error('Could not load guest data');
                    });
            })
            .catch((err) => {
                console.error('Could not update campaign code');
                console.error(err.response);
                toast.error('Could not update guest');
            });
    };

export const checkTwoFA = (history) => (dispatch, getState) => {
    // check if two fa is complete
    const twoFAComplete = getState().order.twofa_completed;
    if (twoFAComplete) {
        // check if hotel
        if (isHotel()) {
            history.push('/hotel/customerform');
            return;
        }

        // process order
        dispatch(getPaymentIntent_PayBefore(history, isFrontOfHouse()));
    } else {
        // do 2fa
        history.push('/order/2fa');
    }
};

export const resetPaymentErrors = () => (dispatch, getState) => {
    dispatch({
        type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
        payload: { error: '', error_details: '' },
    });
};

export const processNMIToken = (history, token) => (dispatch, getState) => {
    console.log('processNMIToken fns: ', history, token);
    const endpoint = getState().main.restaurant_info.endpoint;
    const v2 = getState().main.version === 2;

    let order = getState().order.temporary_order;
    const tipType = getState().order.payment_tip_type;
    const tip = Math.round(getState().order.payment_tip * 100);
    const tipPercentage = tipType === 'percentage' ? tip : undefined;
    const tipFixed = tipType === 'fixed' ? tip : undefined;
    let delivery_tip = getState().order.payment_totals.delivery_tip_amount;
    const { campaign_instances } = getState().order;

    // reset payment intent
    dispatch({
        type: Types.PAYMENT_INTENT_RECEIVED,
        payload: {},
    });

    const guest_id = getState().table.selected_guest.guest_id;
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    // convert to integer

    //
    const unnestedItemInstance = order.map((item) => {
        // get modifier ids
        let modifier_ids = [];
        if (item.modifiers) {
            item.modifiers.forEach((modifiers) => {
                modifier_ids.push(modifiers.modifier_id);
            });
        }

        return {
            guest_id,
            item_id: item.item_id,
            modifier_ids,
            multiplier: item.multiplier,
            special_instructions: item.special_instructions,
            campaign_instance_id: item.campaign_instance_id,
            analytics_menu_id: item.analytics_menu_id,
        };
    });

    // get payment intent
    const url = `${APIv1Endpoint}orders/orderAndPay`;
    const req = {
        guest_id,
        restaurant_id,
        tip_percentage: tipPercentage,
        tip_fixed: tipFixed,
        delivery_tip: delivery_tip.toFixed(0),
        items: unnestedItemInstance,
        campaigns: campaign_instances,
        token: token.token,
    };

    Axios.post(url, req).then(
        (orderAndPayResponse) => {
            if (orderAndPayResponse.status === 200) {
                dispatch(paymentComplete({}, history));
            } else {
                toast.error(i18n.t('toast.errorNotification3'));
                history.replace(v2 ? `/v2/${endpoint}/cart` : '/logoview');
            }
        },
        (err) => {
            if (err.response) {
                if (
                    err.response.status === 503 &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    toast.error(i18n.t('toast.errorNotification3'), {
                        toastId: 'error',
                    });
                } else {
                    toast.error(i18n.t('toast.errorNotification3'), {
                        toastId: 'error',
                    });
                }
                console.error(err.response);
            } else {
                toast.error(i18n.t('toast.errorNotification3'), {
                    toastId: 'error',
                });
                console.error(err);
            }
            history.replace(v2 ? `/v2/${endpoint}/cart` : '/logoview');
        }
    );
};

export const getPaymentIntent_PayBefore =
    (history, is_kiosk = false) =>
    (dispatch, getState) => {
        const endpoint = getState().main.restaurant_info.endpoint;
        const v2 = getState().main.version === 2;

        const connected_stripe_reader =
            stripeTerminal.getConnectedReader() || {};
        const connected_nuvei_reader = getState().kiosk.nuvei_selected_terminal;
        const isReaderConnected =
            connected_stripe_reader.serial_number ||
            connected_nuvei_reader.terminal_id;

        if (is_kiosk || isFrontOfHouse()) {
            dispatch({
                type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                payload: Types.TerminalPaymentStatus.READY,
            });
            if (isFrontOfHouse()) {
                history.push('/order/frontOfHouseReader');
            }
        }

        let order = getState().order.temporary_order;
        const tipType = getState().order.payment_tip_type;
        const tip = Math.round(getState().order.payment_tip * 100);
        const tipPercentage = tipType === 'percentage' ? tip : undefined;
        const tipFixed = tipType === 'fixed' ? tip : undefined;
        let delivery_tip = getState().order.payment_totals.delivery_tip_amount;
        const order_total =
            getState().order.payment_totals.total_with_tip_and_tax;
        const { campaign_instances } = getState().order;

        GAEvent('User', 'Clicked pay now', JSON.stringify(order));

        // reset payment intent
        dispatch({
            type: Types.PAYMENT_INTENT_RECEIVED,
            payload: {},
        });

        const guest_id = getState().table.selected_guest.guest_id;
        const restaurant_id = getState().main.restaurant_info.restaurant_id;

        const unnestedItemInstance = order.map((item) => {
            // get modifier ids
            let modifier_ids = [];
            if (item.modifiers) {
                item.modifiers.forEach((modifiers) => {
                    modifier_ids.push(modifiers.modifier_id);
                });
            }

            return {
                guest_id,
                item_id: item.item_id,
                modifier_ids,
                multiplier: item.multiplier,
                special_instructions: item.special_instructions,
                campaign_instance_id: item.campaign_instance_id,
                analytics_menu_id: item.analytics_menu_id,
            };
        });

        // handle terminal vendor type
        let terminal_id = '';
        if (getState().kiosk.terminal_vendor === Types.TerminalVendors.STRIPE) {
            if (getState().kiosk.selected_reader) {
                terminal_id = getState().kiosk.selected_reader.id;
            }
        } else if (
            getState().kiosk.terminal_vendor === Types.TerminalVendors.NUVEI
        ) {
            terminal_id = getState().kiosk.nuvei_selected_terminal.terminal_id;
        }

        // get payment intent
        const url = `${APIv1Endpoint}orders/orderAndPay`;
        const req = {
            guest_id,
            restaurant_id,
            tip_percentage: tipPercentage,
            tip_fixed: tipFixed,
            delivery_tip: delivery_tip.toFixed(0),
            items: unnestedItemInstance,
            campaigns: campaign_instances,
            terminal_id,
        };

        // check restaurant has paymnet accounts setup
        const has_stripe_acct = getState().main.restaurant_info.stripe_acct;
        const has_nuvei_acct = getState().main.restaurant_info.nuvei_acct;
        const nmi_public_key = getState().main.restaurant_info.nmi_public_key;
        const bambora_mid = getState().main.restaurant_info.bambora_mid;
        const is_pay_at_counter_enabled = !['RESTAURANT_SETTING_NONE'].includes(
            getState().main.restaurant_info.pay_at_counter
        );

        if (
            !(
                has_stripe_acct ||
                has_nuvei_acct ||
                nmi_public_key ||
                is_pay_at_counter_enabled ||
                bambora_mid
            ) &&
            !is_kiosk &&
            order_total > 0
        ) {
            toast.error(i18n.t('toast.paymentSetupError'), {});
        }

        if (
            !(has_stripe_acct || has_nuvei_acct) &&
            is_pay_at_counter_enabled &&
            is_kiosk
        ) {
            // Pay at Counter
            dispatch(payAtCounter(history));
            return;
        }
        if (
            getState().order.payment_processor ===
            Types.PaymentProcessor.BAMBORA
        ) {
            history.push(
                v2
                    ? `/v2/${endpoint}/payBambora/before`
                    : '/order/payBambora/before'
            );
            return;
        }
        if (
            getState().order.payment_processor === Types.PaymentProcessor.NMI &&
            !isFrontOfHouse()
        ) {
            history.push(
                v2 ? `/v2/${endpoint}/payNMI/before` : '/order/payNMI/before'
            );
            return;
        }

        const CancelToken = Axios.CancelToken;
        const nuvei_payment_request = CancelToken.source();
        dispatch({
            type: Types.NUVEI_CANCELLATION_TOKEN_RECEIVED,
            payload: nuvei_payment_request,
        });

        Axios.post(url, req, { cancelToken: nuvei_payment_request.token })
            .then((res) => {
                if (
                    (res.status === 200 && res.data.response) ||
                    res.data.payment_service === 'PAYMENT_SERVICE_NONE'
                ) {
                    // Move them to the pay screen

                    // depending on kiosk or tablet interaction,
                    // route user to kiosk pay or on-screen payment

                    const deviceType = getState().order.device_type;
                    if (
                        deviceType === Types.DeviceTypes.KIOSK ||
                        isFrontOfHouse()
                    ) {
                        const payment_service = res.data.payment_service;
                        switch (payment_service) {
                            case 'PAYMENT_SERVICE_STRIPE':
                                // Use stripe terminal to create payment
                                if (isReaderConnected) {
                                    stripeTerminal.processPaymentIntent(
                                        JSON.parse(res.data.response)
                                    );
                                    dispatch({
                                        type: Types.PAYMENT_INTENT_RECEIVED,
                                        payload: JSON.parse(res.data.response),
                                    });
                                }
                                break;
                            case 'PAYMENT_SERVICE_NUVEI':
                                // handle nuvei payment confirmation
                                dispatch({
                                    type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                                    payload:
                                        Types.TerminalPaymentStatus.COMPLETE,
                                });
                                break;
                            default:
                                // order amount was 0, so navigate user to payment complete page
                                dispatch({
                                    type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                                    payload:
                                        Types.TerminalPaymentStatus.COMPLETE,
                                });
                        }
                    } else {
                        // customer app
                        const payment_service = res.data.payment_service;
                        switch (payment_service) {
                            case 'PAYMENT_SERVICE_NMI':
                                dispatch({
                                    type: Types.PAYMENT_PROCESSOR_CHANGED,
                                    payload: Types.PaymentProcessor.NMI,
                                });
                                dispatch({
                                    type: Types.PAYMENT_INTENT_RECEIVED,
                                    payload: JSON.parse(res.data.response),
                                });
                                history.push(
                                    v2
                                        ? `/v2/${endpoint}/payNMI/before`
                                        : '/order/pay'
                                );
                                break;
                            case 'PAYMENT_SERVICE_STRIPE':
                                dispatch({
                                    type: Types.PAYMENT_PROCESSOR_CHANGED,
                                    payload: Types.PaymentProcessor.STRIPE,
                                });
                                dispatch({
                                    type: Types.PAYMENT_INTENT_RECEIVED,
                                    payload: JSON.parse(res.data.response),
                                });
                                history.push(
                                    v2
                                        ? `/v2/${endpoint}/payStripe`
                                        : '/order/pay'
                                );
                                break;
                            case 'PAYMENT_SERVICE_NUVEI':
                                dispatch({
                                    type: Types.PAYMENT_PROCESSOR_CHANGED,
                                    payload: Types.PaymentProcessor.NUVEI,
                                });
                                dispatch({
                                    type: Types.NUVEI_PAYMENT_REQUEST_RECEIVED,
                                    payload: JSON.parse(res.data.response),
                                });

                                const paymentRequestObject = JSON.parse(
                                    res.data.response
                                );
                                let searchParams = {
                                    AMOUNT: parseFloat(
                                        paymentRequestObject.Amount
                                    ).toFixed(2),
                                    CURRENCY:
                                        paymentRequestObject.Currency.toUpperCase(),
                                    ORDERID: paymentRequestObject.OrderID,
                                    TERMINALID: paymentRequestObject.TerminalID,
                                    DATETIME: paymentRequestObject.DateTime,
                                    HASH: paymentRequestObject.Hash,
                                    // RECEIPTPAGEURL: `https://${hostname}/order/nuvei_confirmation`,
                                    INIFRAME: 'N',
                                };
                                const params = new URLSearchParams(
                                    searchParams
                                );

                                let nuvei_hostname = `payments.nuvei.com`;
                                if (isDevEnvironment()) {
                                    nuvei_hostname = `testpayments.nuvei.com`;
                                }

                                const url = `https://${nuvei_hostname}/merchant/paymentpage?${params.toString()}`;
                                window.open(url, '_self');
                                break;
                            default:
                                // item was free, navigate user to payment complete page
                                history.push(
                                    v2
                                        ? `/v2/${endpoint}/orderComplete`
                                        : '/orderComplete'
                                );
                                dispatch(TableActions.submitPhoneNumber());
                                break;
                        }
                    }
                } else {
                    if (is_kiosk) {
                        dispatch({
                            type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
                            payload: {
                                error: 'Sorry, please try another payment method.',
                            },
                        });
                        dispatch({
                            type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                            payload: Types.TerminalPaymentStatus.FAILED,
                        });
                    }
                    if (!is_kiosk) {
                        toast.error(
                            toast.error(i18n.t('toast.paymentProcessingError'))
                        );
                        history.push(v2 ? `/v2/${endpoint}/cart` : '/logoview');
                    }
                }
            })
            .catch((err) => {
                if (is_kiosk && isReaderConnected) {
                    // check if error was thrown by cancellation token
                    if (Axios.isCancel(err)) {
                        dispatch({
                            type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
                            payload: { err, error_details: '' },
                        });
                        dispatch({
                            type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                            payload: Types.TerminalPaymentStatus.FAILED,
                        });
                        return;
                    }

                    let error = 'Sorry please try another payment method.';
                    let error_details = err.response.data.message;

                    try {
                        if (
                            getState().kiosk.terminal_vendor ===
                            Types.TerminalVendors.NUVEI
                        ) {
                            const xmlValue = convert.xml2js(error_details, {
                                compact: true,
                            }).xmp;
                            error =
                                (
                                    xmlValue?.response?.RespMSG ||
                                    xmlValue?.response?.Message
                                )?._text || error;
                            error_details = '';
                        }
                    } catch (e) {
                        console.error(e);
                    }

                    dispatch({
                        type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
                        payload: { error, error_details },
                    });
                    dispatch({
                        type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                        payload: Types.TerminalPaymentStatus.FAILED,
                    });
                }

                if (!is_kiosk) {
                    if (err.response) {
                        if (
                            err.response.status === 503 &&
                            err.response.data &&
                            err.response.data.message
                        ) {
                            toast.error(
                                toast.error(i18n.t('toast.errorNotification3')),
                                { toastId: 'error' }
                            );
                        } else {
                            toast.error(
                                toast.error(i18n.t('toast.errorNotification3')),
                                { toastId: 'error' }
                            );
                        }
                        console.error(err.response);
                    } else {
                        toast.error(
                            toast.error(i18n.t('toast.errorNotification3')),
                            { toastId: 'error' }
                        );
                        console.error(err);
                    }
                    history.push(v2 ? `/v2/${endpoint}/cart` : '/logoview');
                }
            });
    };

const roundToNearestCent = (amount) => {
    return Math.round(parseFloat(amount) * 100) / 100;
};

export const updateDiscountCodeField = (value) => (dispatch, getState) => {
    dispatch({
        type: Types.DISCOUNT_CODE_FIELD_CHANGED,
        payload: value,
    });
};

const debouncedCalculateTotals = debounce(
    (order, isItemInstance, dispatch, getState) => {
        // Check that the guest has been initialized
        if (!getState().table.selected_guest) {
            store.dispatch({ type: Types.PAYMENT_LOADING, payload: false });
            return;
        }

        dispatch({
            type: Types.CART_UPDATING,
        });

        if (!Array.isArray(order)) {
            dispatch({
                type: Types.CART_UPDATED,
            });
            store.dispatch({ type: Types.PAYMENT_LOADING, payload: false });
            return;
        }

        const taxRate = getState().main.restaurant_info.tax_percentage / 100;
        const tipType = getState().order.payment_tip_type;
        const tip = getState().order.payment_tip;
        const delivery_tip = getState().order.delivery_tip_percentage;

        if (order.length === 0) {
            dispatch({
                type: Types.PAYMENT_TOTAL_UPDATED,
                payload: {
                    tax_amount: 0,
                    total_with_tax: 0,
                    tip_amount: 0,
                    delivery_tip_amount: 0,
                    total_with_tip_and_tax: 0,
                    net_total: 0,
                    discount: 0,
                    tax_rate: taxRate,
                },
            });
            store.dispatch({ type: Types.PAYMENT_LOADING, payload: false });
            dispatch({ type: Types.CART_UPDATED });
            return;
        }

        // calculate totals
        let sub_total = 0;

        // Item instances have different object structures than regular items
        if (isItemInstance) {
            order.forEach((item) => {
                // calculate unpaid items
                if (item.has_paid !== 'PAYMENT_PAID_ONLINE') {
                    // add cost of item

                    if (item.item.price) {
                        sub_total += item.item.price * item.multiplier;
                    }

                    // calculate modifiers
                    if (Array.isArray(item.modifiers)) {
                        item.modifiers.forEach((modifier) => {
                            if (modifier.price) {
                                sub_total += modifier.price * item.multiplier;
                            }
                        });
                    }
                }
            });
        } else {
            order.forEach((item) => {
                if (item.price) {
                    sub_total += item.price * item.multiplier;
                }

                // calculate modifiers
                if (Array.isArray(item.modifiers)) {
                    item.modifiers.forEach((modifier) => {
                        if (modifier.price) {
                            sub_total += modifier.price * item.multiplier;
                        }
                    });
                }
            });
        }

        // handle discounts
        let discount = 0;
        if (
            getState().main.restaurant_info.restaurant_id ===
            '2993daf5-c798-11ea-b545-02685a4295ea'
        ) {
            discount = roundToNearestCent(sub_total * 0.2);
        }

        // GET DISCOUNTS FROM CAMPAIGN -------------------------------

        // calculate discounts

        const guest_id = getState().table.selected_guest.guest_id;
        let campaigns_active = [];

        let tip_amount,
            total_with_tax,
            total_with_tip_and_tax,
            tax_amount,
            delivery_fee,
            delivery_tip_amount;
        const restaurant_id = getState().main.restaurant_info.restaurant_id;
        if (isItemInstance) {
            campaigns_active = getState().table.guest_orders.campaigns || [];

            // calculate dollar discount
            campaigns_active.forEach((campaign) => {
                discount += campaign.discount || 0;
            });

            // convert to dollar
            discount /= 100;

            // TODO: CALCULATE TIP AFTER OR BEFORE TAX
            tip_amount =
                tipType === 'percentage'
                    ? roundToNearestCent(sub_total * tip)
                    : tip;

            delivery_fee = 0;
            if (!getState().order.delivery_option_updated) {
                delivery_fee = getState().order.delivery_fee || 0;
            }
            sub_total += delivery_fee;

            // calculate tax
            let endpoint = `${APIv1Endpoint}order/calculateTax`;
            const reqBody = {
                order: {
                    restaurant_id,
                    delivery_fee: delivery_fee * 100,
                    items: order,
                    delivery_platform: 'MENTUM',
                },
            };
            Axios.post(endpoint, reqBody)
                .then((res) => {
                    tax_amount = res.data.sales_tax / 100;
                    delivery_tip_amount = roundToNearestCent(
                        sub_total * delivery_tip
                    );

                    total_with_tax = sub_total + tax_amount;
                    total_with_tip_and_tax =
                        total_with_tax + tip_amount + delivery_tip_amount;
                    delivery_tip_amount *= 100;

                    dispatch({
                        type: Types.PAYMENT_TOTAL_UPDATED,
                        payload: {
                            tax_amount,
                            total_with_tax,
                            tip_amount,
                            delivery_tip_amount,
                            total_with_tip_and_tax,
                            net_total: sub_total,
                            discount,
                            tax_rate: taxRate,
                        },
                    });
                    dispatch({
                        type: Types.CART_UPDATED,
                    });
                    store.dispatch({
                        type: Types.PAYMENT_LOADING,
                        payload: false,
                    });
                })
                .catch((err) => {
                    console.error(err.response);
                    dispatch({
                        type: Types.CART_UPDATED,
                    });
                    store.dispatch({
                        type: Types.PAYMENT_LOADING,
                        payload: false,
                    });
                });
        } else {
            GAEvent('User', 'Checking discount code', guest_id);
            order = [...order];
            const restaurant_id = getState().main.restaurant_info.restaurant_id;
            //convert subtotal from dollars to cents
            const amount = Math.round(sub_total * 100);
            const itemInstance = convertTempItemToItemInstance(order, {
                order_id: '',
                guest_id,
            });
            const { campaign_instances } = getState().order;
            const request = {
                guest: {
                    guest_id,
                    restaurant_id,
                    order: {
                        amount,
                        items: itemInstance,
                        campaigns: campaign_instances,
                    },
                    campaign_code: getState().order.discount_code_field,
                },
            };
            const endpoint = `${APIv1Endpoint}campaign/getForGuest`;
            Axios.post(endpoint, request)
                .then((res) => {
                    let campaigns = res.data.campaigns || [];

                    //sort the campaigns by letter
                    campaigns.sort((campaign_a, campaign_b) => {
                        if (
                            campaign_a.campaign.name < campaign_b.campaign.name
                        ) {
                            return -1;
                        } else if (
                            campaign_a.campaign.name > campaign_b.campaign.name
                        ) {
                            return 1;
                        }
                        return 0;
                    });
                    dispatch({
                        type: Types.CAMPAIGN_INSTANCE_RECEIVED,
                        payload: campaigns,
                    });

                    campaigns_active = res.data.campaigns || [];
                    // calculate dollar discount
                    campaigns_active.forEach((campaign) => {
                        discount += campaign.discount || 0;
                    });

                    // convert to cents
                    discount /= 100;

                    sub_total -= discount;
                    if (sub_total < 0) {
                        sub_total = 0;
                    }

                    delivery_fee = 0;
                    if (!getState().order.delivery_option_updated) {
                        delivery_fee = getState().order.delivery_fee;
                    }

                    // TODO: CALCULATE TIP AFTER OR BEFORE TAX
                    tip_amount =
                        tipType === 'percentage'
                            ? roundToNearestCent(sub_total * tip)
                            : tip;

                    // set tip amount to 0 for frontCounter order
                    if (isFrontOfHouse()) {
                        tip_amount = 0;
                    }

                    sub_total += delivery_fee;

                    delivery_tip_amount = roundToNearestCent(
                        sub_total * delivery_tip
                    );

                    // calculate tax
                    let endpoint = `${APIv1Endpoint}order/calculateTax`;
                    const reqBody = {
                        order: {
                            restaurant_id,
                            items: itemInstance,
                            campaigns: campaigns_active,
                            delivery_fee: delivery_fee * 100,
                            delivery_platform: 'MENTUM',
                        },
                    };
                    Axios.post(endpoint, reqBody)
                        .then((res) => {
                            tax_amount = res.data.sales_tax / 100;
                            total_with_tax = sub_total + tax_amount;
                            total_with_tip_and_tax =
                                total_with_tax +
                                tip_amount +
                                delivery_tip_amount;
                            delivery_tip_amount *= 100;

                            dispatch({
                                type: Types.PAYMENT_TOTAL_UPDATED,
                                payload: {
                                    tax_amount,
                                    total_with_tax,
                                    tip_amount,
                                    delivery_tip_amount,
                                    total_with_tip_and_tax,
                                    net_total: sub_total,
                                    discount,
                                    tax_rate: taxRate,
                                },
                            });
                            dispatch({
                                type: Types.CART_UPDATED,
                            });
                            store.dispatch({
                                type: Types.PAYMENT_LOADING,
                                payload: false,
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                })
                .catch((err) => {
                    console.error(err.response);
                    dispatch({
                        type: Types.CART_UPDATED,
                    });
                    store.dispatch({
                        type: Types.PAYMENT_LOADING,
                        payload: false,
                    });
                });
        }
    },
    300
);

export const calculateTotals =
    (order, isItemInstance) => (dispatch, getState) => {
        debouncedCalculateTotals(order, isItemInstance, dispatch, getState);
    };

export const changeTip = (tip, type) => (dispatch, getState) => {
    GAEvent('User', 'Changed tip', tip.toString());

    dispatch({
        type: Types.TIP_CHANGED,
        payload: {
            type,
            value: tip,
        },
    });

    const orderId = getState().table?.all_orders?.[0]?.order_id;

    if (orderId) {
        dispatch(updateTip(tip));
    }

    const order = getState().order.temporary_order;
    if (Array.isArray(order) && order.length > 0) {
        dispatch(calculateTotals(order, false));
    }
};

export const updateTip = (newTip) => (dispatch, getState) => {
    const orderId = getState().table.guest_orders.order_id;
    const tipType = getState().order.payment_tip_type;
    const oldTip = getState().order.payment_tip;
    const tip = Math.round(newTip * 100);

    Axios.patch(`${APIv1Endpoint}orders/${orderId}/tip`, {
        tip_percentage: tipType === 'percentage' ? tip : undefined,
        tip_fixed: tipType === 'fixed' ? tip : undefined,
    }).catch((err) => {
        dispatch({
            type: Types.TIP_CHANGED,
            payload: { value: oldTip },
        });

        const order = getState().order.temporary_order;
        if (Array.isArray(order) && order.length > 0) {
            dispatch(calculateTotals(order, false));
        }
    });
};

export const changeDeliveryTipPercentage = (tip) => (dispatch, getState) => {
    GAEvent('User', 'Changed delivery tip percentage', tip.toString());

    dispatch({
        type: Types.DELIVERY_TIP_PERCENTAGE_CHANGED,
        payload: tip,
    });

    const order = getState().order.temporary_order;
    if (Array.isArray(order) && order.length > 0) {
        dispatch(calculateTotals(order, false));
    }
};

export const increaseItemQuantity = () => (dispatch, getState) => {
    let quantity = getState().order.selected_item_quantity;
    quantity++;
    dispatch({
        type: Types.ITEM_QUANTITY_CHANGED,
        payload: quantity,
    });
};

export const disableItemEditMode = () => (dispatch) => {
    dispatch({
        type: Types.IS_EDIT_ITEM_CHANGED,
        payload: false,
    });
};

export const setItemQuantity = (quantity) => (dispatch, getState) => {
    dispatch({
        type: Types.ITEM_QUANTITY_CHANGED,
        payload: quantity,
    });
};

export const decreaseItemQuantity = () => (dispatch, getState) => {
    let quantity = getState().order.selected_item_quantity;
    if (quantity === 1) {
        return;
    }
    quantity--;
    dispatch({
        type: Types.ITEM_QUANTITY_CHANGED,
        payload: quantity,
    });
};

export const callWaiter = (inquiry, history) => (dispatch, getState) => {
    GAEvent('User', 'Call waiter', inquiry);

    const guest_name = getState().table.selected_guest.name;
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    const table_id_arr = getState().main.table_id;
    let table_id = '';
    table_id_arr.forEach((digit) => {
        table_id += String(digit);
    });

    if (history) {
        history.push('/callWaiter/complete');
    }

    const req = {
        restaurant_id,
        table_id,
        guest_name,
        request: inquiry,
    };

    Axios.post(`${APIv1Endpoint}table/callWaiter`, req)
        .then((res) => {
            toast.success(i18n.t('toast.requestSent'));
        })
        .catch((err) => {
            toast.error(i18n.t('toast.errorNotification3'));
        });
};

export const setDineInOrTakeout =
    (dineInOrTakeoutSetting) => (dispatch, getState) => {
        dispatch({
            type: Types.DINE_IN_OR_TAKE_OUT_CHANGED,
            payload: dineInOrTakeoutSetting,
        });
    };

export const sendToKitchen =
    (history, pathToRedirect, redirect = true, sendText = false) =>
    (dispatch, getState) => {
        const order = getState().order.temporary_order;
        const guest_id = getState().table.selected_guest.guest_id;
        const restaurant_id = getState().main.restaurant_info.restaurant_id;
        const campaign_instances = getState().order.campaign_instances;

        const itemInstance = convertTempOrderToUnnestedItemInstance(
            order,
            guest_id
        );

        const request = {
            item_instances: itemInstance,
            campaigns: campaign_instances,
            guest_id,
            restaurant_id,
        };

        Axios.post(`${APIv1Endpoint}orders/addToOrder`, request)
            .then((res) => {
                // check if guest has a phone number
                const pn = getState().table.selected_guest.phone_number;

                if (typeof pn === 'string' && isValidPhoneNumber(pn)) {
                    // phone number is valid
                    // submit (Toast is handled by submitPhoneNumber func)
                    dispatch(TableActions.submitPhoneNumber(sendText));
                } else {
                    // Phone number is invalid
                    toast.success(i18n.t('toast.orderPlaced'), {
                        toastId: 'orderPlacedToast',
                    });
                }

                // clear temporary orders
                dispatch({
                    type: Types.CLEAR_TEMPORARY_ORDERS,
                });
                dispatch({
                    type: Types.CLEAR_CAMPAIGN_INSTANCES,
                });

                // Show toast
                if (redirect) {
                    history.push(pathToRedirect);
                }

                // load orders
                dispatch(loadOrders());
            })
            .catch((err) => {
                console.error(err.response);
            });
    };

export const addToOrder =
    (history, redirect = true) =>
    (dispatch, getState) => {
        if (!getState().main.is_active && !isFrontOfHouse()) {
            console.log('addToOrder - notTakingOrders', {
                isActive: getState().main.is_active,
                isFrontOfHouse: isFrontOfHouse(),
            });
            toast.dismiss('closedToast');
            toast.error(i18n.t('toast.notTakingOrders'), {
                toastId: 'closedToast',
            });
            return;
        }

        const temporary_order = getState().order.temporary_order;
        let item = { ...getState().order.selected_item };

        const modifiers = getState().order.selected_item_modifiers;
        const selected_item_special_instructions =
            getState().order.selected_item_special_instructions;

        const newItem = {
            ...item,
            modifiers,
        };

        const indexOfItemInTemporaryOrder = temporary_order.findIndex(
            (orderItem) => {
                const modifiersMatch = areModifiersEqual(orderItem, newItem);

                const instructionsMatch = isEqualSpecialInstructions(
                    orderItem,
                    selected_item_special_instructions
                );

                if (
                    (modifiersMatch && instructionsMatch) ||
                    (modifiersMatch &&
                        !selected_item_special_instructions &&
                        !orderItem.special_instructions)
                ) {
                    return true;
                }

                if (
                    !modifiers.length &&
                    !orderItem.modifiers.length &&
                    instructionsMatch &&
                    orderItem.item_id === newItem.item_id
                ) {
                    return true;
                }

                return false;
            }
        );

        // Item is already in temporary orders,
        if (indexOfItemInTemporaryOrder !== -1) {
            const existingItem = temporary_order[indexOfItemInTemporaryOrder];

            dispatch(
                finalizeOrderQuantityUpdated(
                    existingItem.multiplier +
                        getState().order.selected_item_quantity,
                    indexOfItemInTemporaryOrder
                )
            );
            dispatch(calculateTotals(temporary_order, false));
            return;
        }

        // remove category object from item
        if (item.category) {
            delete item.category;
        }

        // set special instructions to item
        item['special_instructions'] = selected_item_special_instructions;

        item['analytics_menu_id'] = getState().order.selected_menu.menu_id;

        if (!item.is_in_stock) {
            toast.error(i18n.t('toast.itemSoldOut'));
            return;
        }

        GAEvent('User', 'Item added to order', item.item_id);
        modifiers.forEach((modifier) => {
            GAEvent('User', 'Added modifier to cart', modifier.modifier_id);
        });

        item['modifiers'] = [...modifiers];
        item.multiplier = getState().order.selected_item_quantity;

        // ensure price exists
        if (!item.price) {
            item.price = 0;
        }

        // get total price with modifiers
        let priceOfModifiers = 0;
        item['modifiers'].forEach((modifier) => {
            if (modifier.price) {
                priceOfModifiers += parseFloat(modifier.price);
            }
        });
        const priceWithModifiers =
            parseFloat(item.price) + parseFloat(priceOfModifiers);
        item['price_with_modifiers'] = priceWithModifiers;

        console.log('item,', item);
        temporary_order.push({ ...item });

        dispatch({
            type: Types.ITEM_ADDED_TO_ORDER,
            payload: temporary_order,
        });

        dispatch(calculateTotals(temporary_order, false));

        if (temporary_order.length === 1) {
            dispatch(v2ShowTapToCheckout());
        }

        if (redirect) {
            toast.success('', { toastId: 'success' });
            history.push({
                pathname: '/menu/category',
                state: { added_to_cart: true },
            });
        }
    };

export const finalizeOrderQuantityUpdated =
    (quantity, item_index) => (dispatch, getState) => {
        if (quantity <= 0) {
            return;
        }
        let temp_order = getState().order.temporary_order;
        temp_order[item_index].multiplier = quantity;

        dispatch(calculateTotals(temp_order, false));

        dispatch({
            type: Types.FINALIZE_ITEM_QUANTITY_UPDATED,
            payload: temp_order,
        });
    };

export const menuItemSpecialInstructionsChanged =
    (text) => (dispatch, getState) => {
        dispatch({
            type: Types.SELECTED_ITEM_SPECIAL_INSTRUCTIONS_CHANGED,
            payload: text,
        });
    };

export const orderInstructionsChanged = (text) => (dispatch) => {
    dispatch({
        type: Types.ORDER_SPECIAL_INSTRUCTIONS_CHANGED,
        payload: text,
    });
};

export const itemModifiersUpdated = (modifiers) => (dispatch) => {
    dispatch({
        type: Types.ITEM_MODIFIERS_UPDATED,
        payload: modifiers,
    });
};

export const menuItemModifierSelected =
    (modifier, is_multiple_selection_allowed) => (dispatch, getState) => {
        let modifiers = getState().order.selected_item_modifiers;

        // if multiple selection is not allowed, we must remove
        // any modifiers with the same modifier_group_id

        let new_modifiers;
        if (!is_multiple_selection_allowed) {
            const modifier_in_selected =
                modifiers.filter((m) => {
                    return m.modifier_id === modifier.modifier_id;
                }).length > 0;

            // remove any instances of a modifier from this modifier group (toggle off existing modifier)
            new_modifiers = modifiers.filter((m, index) => {
                return m.modifier_group_id !== modifier.modifier_group_id;
            });

            // add the selected modifier if it wasn't previously in the list of selected modifiers
            if (!modifier_in_selected) {
                new_modifiers.push(modifier);
            }
        } else {
            // toggle or add modifier to the list
            new_modifiers = modifiers.filter((m, index) => {
                return m.modifier_id !== modifier.modifier_id;
            });

            // compare new modifiers to modifiers. If they're the same, then the modifier was never added
            if (new_modifiers.length === modifiers.length) {
                // add modifier
                new_modifiers.push(modifier);
            }
            // If they're different, then the modifier has been removed (toggled off)
        }

        dispatch({
            type: Types.ITEM_MODIFIERS_UPDATED,
            payload: new_modifiers,
        });
    };

export const addModifiersToItem = (modifiers) => (dispatch, getState) => {
    dispatch({
        type: Types.ITEM_MODIFIERS_UPDATED,
        payload: modifiers,
    });
};

export const deleteItemStep1 = (item) => (dispatch, getState) => {
    dispatch({
        type: Types.DELETE_ITEM_STEP_1,
        payload: item,
    });
};
export const clearItemToDelete = () => (dispatch, getState) => {
    dispatch({
        type: Types.DELETE_ITEM_STEP_1,
        payload: {},
    });
};
export const deleteItem =
    (item, notification = true) =>
    (dispatch, getState) => {
        GAEvent('User', 'Item deleted from cart', item.item_id);

        let order = getState().order.temporary_order;
        const new_order = order.filter((i) => {
            return item !== i;
        });
        dispatch({
            type: Types.ITEM_ADDED_TO_ORDER,
            payload: new_order,
        });
        if (notification) {
            if (item.name) {
                toast.info(
                    i18n.t('toast.itemRemovedFromBag', { itemName: item.name }),
                    {}
                );
            }
            if (item.campaign_name) {
                toast.info(
                    i18n.t('toast.itemRemovedFromBag', {
                        itemName: item.campaign_name,
                    }),
                    {}
                );
            }
        }

        dispatch(calculateTotals(new_order, false));
    };
// export const increaseItemQuantity = () => (dispatch, getState) => {
//     let quantity = getState().order.selected_item_quantity;
//     quantity++;
//     dispatch({
//         type: Types.ITEM_QUANTITY_CHANGED,
//         payload: quantity,
//     })
// }
export const setPageBeforeItem = (location) => (dispatch) => {
    dispatch({
        type: Types.PAGE_BEFORE_ITEM_CHANGED,
        payload: location,
    });
};

export const saveEditedItem =
    (history, changesMade) => (dispatch, getState) => {
        let item = getState().order.selected_item;
        const modifiers = getState().order.selected_item_modifiers;
        let temporary_order = getState().order.temporary_order;
        const selected_item_special_instructions =
            getState().order.selected_item_special_instructions;

        // set special instructions to item
        item['special_instructions'] = selected_item_special_instructions;

        if (!item.is_in_stock) {
            toast.error(i18n.t('toast.itemSoldOut'));
            return;
        }

        GAEvent('User', 'Item added to order', item.item_id);
        modifiers.forEach((modifier) => {
            GAEvent('User', 'Added modifier to cart', modifier.modifier_id);
        });

        item['modifiers'] = [...modifiers];
        item.multiplier = getState().order.selected_item_quantity;

        // ensure price exists
        if (!item.price) {
            item.price = 0;
        }

        // get total price with modifiers
        let priceOfModifiers = 0;
        item['modifiers'].forEach((modifier) => {
            if (modifier.price) {
                priceOfModifiers += parseFloat(modifier.price);
            }
        });
        const priceWithModifiers =
            parseFloat(item.price) + parseFloat(priceOfModifiers);
        item['price_with_modifiers'] = priceWithModifiers;

        temporary_order[item.item_index] = { ...item }; // update the item

        dispatch({
            type: Types.ITEM_ADDED_TO_ORDER,
            payload: temporary_order,
        });
        dispatch({
            type: Types.ITEM_EDIT_MODE_CHANGED,
            payload: false,
        });

        dispatch(calculateTotals(temporary_order, false));

        changesMade && toast.success(`${item.name} has been saved!`);

        history.push({ pathname: '/order' });
    };

export const setSelectedItem = (item) => (dispatch, getState) => {
    dispatch({
        type: Types.SET_SELECTED_ITEM,
        payload: item,
    });
};

export const editItem = (item, history) => (dispatch, getState) => {
    dispatch({
        type: Types.ITEM_EDIT_MODE_CHANGED,
        payload: true,
    });
    dispatch(menuItemSelected(item, history, true, true));
};

export const menuItemSelected =
    (item, history, redirect = true, edit = false) =>
    (dispatch, getState) => {
        if (!item) {
            dispatch({
                type: Types.MENU_ITEM_SELECTED,
                payload: {},
            });
            return;
        }

        GAEvent('User', 'Menu item selected', item.item_id);

        // find modifier groups and modifiers for this item
        const modifiers = getState().order.menu.modifiers;

        if (Array.isArray(item.modifier_groups)) {
            item.modifier_groups.forEach((modGroup) => {
                modGroup['modifiers'] = [];
                modifiers.forEach((modifier) => {
                    if (
                        modifier.modifier_group_id ===
                        modGroup.modifier_group_id
                    ) {
                        modGroup['modifiers'].push(modifier);
                    }
                });
            });
        }

        dispatch({
            type: Types.MENU_ITEM_SELECTED,
            payload: { ...item },
        });

        // dispatch existing item's options
        if (edit) {
            dispatch({
                type: Types.ITEM_MODIFIERS_UPDATED,
                payload: [...item.modifiers],
            });
            dispatch({
                type: Types.ITEM_QUANTITY_CHANGED,
                payload: item.multiplier,
            });
            dispatch({
                type: Types.SELECTED_ITEM_SPECIAL_INSTRUCTIONS_CHANGED,
                payload: item.special_instructions || '',
            });
        } else {
            dispatch({
                type: Types.ITEM_QUANTITY_CHANGED,
                payload: 1,
            });
        }

        if (redirect) {
            history.push('/menu/item');
        }
    };

export const updateOrder = () => (dispatch, getState) => {
    if (Array.isArray(getState().table.all_orders)) {
        const order = getState().table.all_orders[0];
        const special_instructions = getState().order.order_instructions;
        order.special_instructions = special_instructions;
        Axios.post(`${APIv1Endpoint}orders/update`, { order })
            .then((res) => {})
            .catch((err) => {
                dispatch({
                    type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
                    payload: 'Failed to update special instructions',
                });
            });
    } else {
        console.error('Could not update order!!!!!');
    }
};

export const loadSchedules = () => async (dispatch, getState) => {
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    Axios.get(`${APIv1Endpoint}menu/${restaurant_id}/schedules`)
        .then((res) => {
            const schedules = res.data.schedules;

            // get menus for every schedule
            if (schedules) {
                schedules.forEach((schedule, index) => {
                    Axios.get(
                        `${APIv1Endpoint}menu/schedule/${schedule.schedule_id}`
                    )
                        .then((res) => {
                            const menus = res.data.menus;
                            if (menus) {
                                schedule.menus = menus;
                            }
                            if (index === schedules.length - 1) {
                                dispatch({
                                    type: Types.MENU_SCHEDULES_LOADED,
                                    payload: schedules,
                                });
                            }
                        })
                        .catch((err) => {
                            console.error(err.response);
                        });
                });
            }
        })
        .catch((err) => {
            console.error(err.response);
        });
};

const _convertFullMenuToCents = (menu) => {
    let categories = menu.categories;
    // convert all items to cents
    if (!categories) {
        return menu;
    }
    categories.forEach((category) => {
        if (category.items && Array.isArray(category.items)) {
            category.items.forEach((item) => {
                if (item.item.price) {
                    item.item.price /= 100;
                }
                if (
                    item.item_modifier_groups &&
                    Array.isArray(item.item_modifier_groups)
                ) {
                    // iterate through each modifier group
                    item.item_modifier_groups.forEach((modifierGroup) => {
                        if (modifierGroup.modifiers) {
                            // iterate through each modifier in this group
                            modifierGroup.modifiers.forEach((modifier) => {
                                if (modifier.price) {
                                    // convert to cents
                                    modifier.price /= 100;
                                }
                            });
                        }
                    });
                }
            });
        }
    });
};

const _preloadMenuImages = (menus, restaurant_id) => {
    let imageSrcArray = [];

    menus.forEach((menu) => {
        let categories = menu.categories;
        if (categories) {
            categories.forEach((category) => {
                let categoryImage = `${cloudfrontBaseUrl}${restaurant_id}/categories/${category.category.category_id}.jpeg`;
                imageSrcArray.push(categoryImage);

                let items = category.items;
                if (items) {
                    items.forEach((item) => {
                        let itemImage = `${cloudfrontBaseUrl}${restaurant_id}/items/${item.item.item_id}.jpeg`;
                        imageSrcArray.push(itemImage);
                    });
                }
            });
        }
    });

    // preload images after 3 seconds
    setTimeout(() => {
        preloadImages(imageSrcArray);
    }, 3000);
};

export const loadInScheduleMenus = () => async (dispatch, getState) => {
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    Axios.get(`${APIv1Endpoint}menu/${restaurant_id}/menus?by_schedule=true`)
        .then((res) => {
            const menus = res?.data?.menus;

            // get menus for every schedule
            if (menus.length === 0) {
                dispatch({
                    type: Types.RESTAURANT_IN_SCHEDULE_MENUS_LOADED,
                    payload: [],
                });
                return;
            }

            dispatch({
                type: Types.RESTAURANT_IN_SCHEDULE_MENUS_LOADED,
                payload: menus,
            });
        })
        .catch((err) => {
            console.error(err.response);
        });
};

export const loadMenu = () => (dispatch, getState) => {
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    const is_tablet = getState().table.is_tablet;

    GAEvent('User', 'Restaruant menu loaded', restaurant_id);

    dispatch({
        type: Types.LOADING_MENUS_STATUS_UPDATED,
        payload: true,
    });
    Axios.get(`${APIv1Endpoint}menu/${restaurant_id}/menus`)
        .then((menuResponse) => {
            let menus = [...menuResponse.data.menus];
            if (menus) {
                // for each menu, get the categories
                let loadedCategories = 0;
                menus.forEach((menu, menuIndex) => {
                    // load full menu
                    const fullMenuUrl = `${APIv1Endpoint}menu/${menu.menu_id}/menu`;
                    Axios.get(fullMenuUrl).then((menuDataResponse) => {
                        const menuData = menuDataResponse.data.menu;

                        // append a copy of the menu to each category
                        if (menuData && menuData.menu) {
                            let categories = menuData.categories;
                            if (categories) {
                                categories.forEach((c) => {
                                    c.menu = { ...menuData.menu };
                                    // iterate through each category and set the menu_id for each item
                                    if (c.items) {
                                        c.items.forEach((i) => {
                                            if (i.item) {
                                                i.item.menu_id =
                                                    menuData.menu.menu_id;
                                                i.item.category_id =
                                                    c.category.category_id;
                                            }
                                        });
                                    }
                                });
                            }
                            _convertFullMenuToCents(menuData);
                            loadedCategories++;
                            menu['categories'] = [...(categories || [])];
                        }

                        if (loadedCategories === menus.length) {
                            //only dispatch once all categories are loaded
                            dispatch({
                                type: Types.RESTAURANT_MENUS_LOADED,
                                payload: menus,
                            });
                            dispatch(translateMenus());
                            dispatch({
                                type: Types.LOADING_MENUS_STATUS_UPDATED,
                                payload: false,
                            });

                            // preload images if this is a kiosk
                            if (
                                getState().order.device_type ===
                                Types.DeviceTypes.KIOSK
                            ) {
                                _preloadMenuImages(menus, restaurant_id);
                            }
                            if (is_tablet) {
                                dispatch(menuSelected(menus[0], null, false));
                            }
                        }
                    });
                });
            }
        })
        .catch((err) => console.error(err.response));

    dispatch(loadInScheduleMenus());
    dispatch(loadModifiersAndModGroups());
    dispatch(loadSchedules());
};

export const translateMenus = () => (dispatch, getState) => {
    const restaurant = getState().main.restaurant_info;
    const selectedLanguage = getState().main.language?.value || 'en';
    const menuTranslations = getState().main.menuTranslations;
    const translatedMenus = [...getState().order.menu.menus];

    let language = selectedLanguage;

    if (!restaurant.active_languages?.includes(selectedLanguage)) {
        language = restaurant.language;
    }

    translatedMenus.forEach((menu) => {
        const menuId = menu.menu_id;

        menu.name =
            menuTranslations?.[menuId]?.translations[language]?.name ||
            menu.name;
        menu.description =
            menuTranslations?.[menuId]?.translations[language]?.description ||
            menu.description;

        menu.categories.forEach((category) => {
            if (!category.category) {
                return;
            }

            const categoryId = category.category.category_id;

            category.category.name =
                menuTranslations?.[categoryId]?.translations[language]?.name ||
                category.category.name;
            category.category.description =
                menuTranslations?.[categoryId]?.translations[language]
                    ?.description || category.category.description;

            category.items.forEach((item) => {
                if (!item.item) {
                    return;
                }

                const itemId = item.item.item_id;

                item.item.name =
                    menuTranslations?.[itemId]?.translations[language]?.name ||
                    item.item.name;
                item.item.description =
                    menuTranslations?.[itemId]?.translations[language]
                        ?.description || item.item.description;

                item.item_modifier_groups.forEach((modifierGroup) => {
                    if (!modifierGroup.item_modifier_group.modifier_group) {
                        return;
                    }

                    const modifierGroupId =
                        modifierGroup.item_modifier_group.modifier_group
                            .modifier_group_id;

                    modifierGroup.item_modifier_group.modifier_group.name =
                        menuTranslations?.[modifierGroupId]?.translations[
                            language
                        ]?.name ||
                        modifierGroup.item_modifier_group.modifier_group.name;
                    modifierGroup.item_modifier_group.modifier_group.description =
                        menuTranslations?.[modifierGroupId]?.translations[
                            language
                        ]?.description ||
                        modifierGroup.item_modifier_group.modifier_group
                            .description;

                    modifierGroup.modifiers.forEach((modifier) => {
                        modifier.name =
                            menuTranslations?.[modifier.modifier_id]
                                ?.translations[language]?.name || modifier.name;
                        modifier.description =
                            menuTranslations?.[modifier.modifier_id]
                                ?.translations[language]?.description ||
                            modifier.description;
                    });
                });
            });
        });
    });

    dispatch({
        type: Types.RESTAURANT_MENUS_LOADED,
        payload: translatedMenus,
    });
};

export const clearAllOrders = () => (dispatch, getState) => {
    dispatch({
        type: Types.CLEAR_TEMPORARY_ORDERS,
    });
    dispatch({
        type: Types.CLEAR_CAMPAIGN_INSTANCES,
    });
};

export const menuSelected =
    (menu, history, redirect = true, kiosk = false) =>
    (dispatch, getState) => {
        if (!menu) {
            dispatch({
                type: Types.MENU_SELECTED,
                payload: {},
            });
            return;
        }
        GAEvent('User', 'Menu selected', menu.menu_id);
        dispatch({
            type: Types.MENU_SELECTED,
            payload: menu,
        });
        dispatch({
            type: Types.MENU_CATEGORY_SELECTED,
            payload: {},
        });
        if (
            kiosk &&
            Array.isArray(menu.categories) &&
            menu.categories.length > 0
        ) {
            dispatch({
                type: Types.MENU_CATEGORY_SELECTED,
                payload: menu.categories[0],
            });
        }
        if (redirect) {
            history.push('/oneMenu');
        }
    };

export const kioskCategorySelected = (category) => (dispatch, getState) => {
    dispatch({
        type: Types.MENU_CATEGORY_SELECTED,
        payload: category,
    });

    // const catToItemsMap = getState().order.menu.all_categories_to_items_map;
    // const category_copy = {...category};

    // // use category that's already been loaded
    // if (category_copy.category_id in catToItemsMap) {
    //     category_copy.items = catToItemsMap[category_copy.category_id]
    //     dispatch({
    //         type: Types.MENU_CATEGORY_SELECTED,
    //         payload: category_copy
    //     })
    // } else {
    //     // if category is not loaded, fallback to loading category manually
    //     dispatch(menuCategorySelected(category, null, false));
    // }
};

export const menuCategorySelected =
    (category, history, redirect = true) =>
    (dispatch, getState) => {
        GAEvent('User', 'Menu category selected', category.category_id);

        // PUSH category with no items
        if (redirect) {
            history.push('/menu/category');
        }

        dispatch({
            type: Types.MENU_CATEGORY_SELECTED,
            payload: category,
        });

        // the use case for this is when we want to clear the category / items to display an animation
        if (!category.category_id) {
            return;
        }

        // do not load category items again if exists
        if (category.category && category.items) {
            return;
        }
    };

export const loadModifiersAndModGroups = () => (dispatch, getState) => {
    const restaurant = getState().main.restaurant_info;
    const restaurant_id = restaurant.restaurant_id;
    const menuTranslations = getState().main.menuTranslations;
    const selectedLanguage = getState().main.language?.value || 'en';

    let language = selectedLanguage;

    if (!restaurant.active_languages?.includes(selectedLanguage)) {
        language = restaurant.language;
    }

    const e1 = `${APIv1Endpoint}menu/${restaurant_id}/modifiers`;
    const e2 = `${APIv1Endpoint}menu/${restaurant_id}/modifierGroups`;

    Axios.get(e1)
        .then((res) => {
            if (res.data.modifiers) {
                res.data.modifiers.forEach((modifier) => {
                    modifier.name =
                        menuTranslations[modifier.modifier_id]?.translations[
                            language
                        ]?.name || modifier.name;
                    modifier.description =
                        menuTranslations[modifier.modifier_id]?.translations[
                            language
                        ]?.description || modifier.description;

                    if (modifier.price) {
                        modifier.price = (modifier.price / 100).toFixed(2);
                    }
                });

                // sort alphabetically
                res.data.modifiers.sort((a, b) => {
                    return a.name > b.name ? 1 : -1;
                });

                dispatch({
                    type: Types.MENU_MODIFIERS_LOADED,
                    payload: res.data.modifiers,
                });
            } else {
                console.error('Restuarant has no modifiers!');
            }
        })
        .catch((err) => console.error(err.response));

    Axios.get(e2)
        .then((res) => {
            if (res.data.modifier_groups) {
                dispatch({
                    type: Types.MENU_MODIFIER_GROUPS_LOADED,
                    payload: res.data.modifier_groups,
                });

                res.data.modifier_groups.sort((a, b) => {
                    return a.name > b.name ? 1 : -1;
                });
            } else {
                console.error('Restuarant has no modifier groups!');
            }
        })
        .catch((err) => console.error(err.response));
};

export const loadItems = () => (dispatch, getState) => {
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    const endpoint = `${APIv1Endpoint}menu/restaurant/${restaurant_id}/items`;
    Axios.get(endpoint, { restaurant_id })
        .then((res) => {
            const items = res.data.items;
            if (Object.keys(res.data).length === 0) {
                console.error('Menu has no items!');
            } else {
                dispatch({
                    type: Types.MENU_ITEMS_LOADED,
                    payload: items,
                });
            }
        })
        .catch((err) => console.error(err.response));
};

// INFO: new api
export const loadGuestOrders = (guest_id) => (dispatch, getState) => {
    GAEvent('User', 'Receipt loaded', guest_id);

    let endpoint = `${APIv1Endpoint}guests/${guest_id}`;
    Axios.get(endpoint)
        .then((res) => {
            const raw_guest = JSON.parse(JSON.stringify(res.data.guest));

            if (res.data.guest.order) {
                const guest_orders = res.data.guest.order;
                if (!Array.isArray(guest_orders.items)) {
                    guest_orders.items = [];
                }
                guest_orders.items.forEach((itemInstance) => {
                    const item = itemInstance.item;
                    // set price to dollars
                    if (item.price) {
                        item.price /= 100;
                    }
                    // set modifier price to dollars
                    if (itemInstance.modifiers) {
                        itemInstance.modifiers.forEach((modifier) => {
                            if (modifier.price) {
                                modifier.price /= 100;
                            }
                        });
                    }
                });
                guest_orders.amount /= 100;

                dispatch({
                    type: Types.LOAD_GUEST_ORDERS,
                    payload: {
                        guest: res.data.guest,
                        raw_guest,
                    },
                });

                endpoint = `${APIv1Endpoint}restaurant/${res.data.guest.order.restaurant_id}/details`;
                Axios.get(endpoint)
                    .then((res) => {
                        if (res.data.restaurant) {
                            dispatch({
                                type: Types.RECEIPT_RESTAURANT_UPDATED,
                                payload: res.data.restaurant,
                            });
                        }
                    })
                    .catch((err) => console.error(err.response));
            }
        })
        .catch((err) => console.error(err.response));
};

export const loadReceipt = (guest_id) => (dispatch, getState) => {
    GAEvent('User', 'Receipt loaded', guest_id);

    let endpoint = `${APIv1Endpoint}orders/${guest_id}/getGuestOrder`;
    Axios.get(endpoint)
        .then((res) => {
            if (res.data.order) {
                const guest_orders = res.data.order;
                if (!Array.isArray(guest_orders.items)) {
                    guest_orders.items = [];
                }
                guest_orders.items.forEach((itemInstance) => {
                    const item = itemInstance.item;
                    // set price to dollars
                    if (item.price) {
                        item.price /= 100;
                    }
                    // set modifier price to dollars
                    if (itemInstance.modifiers) {
                        itemInstance.modifiers.forEach((modifier) => {
                            if (modifier.price) {
                                modifier.price /= 100;
                            }
                        });
                    }
                });
                guest_orders.amount /= 100;

                dispatch({
                    type: Types.RECEIPT_ORDER_UPDATED,
                    payload: res.data.order,
                });

                endpoint = `${APIv1Endpoint}restaurant/${res.data.order.restaurant_id}/details`;
                Axios.get(endpoint)
                    .then((res) => {
                        if (res.data.restaurant) {
                            dispatch({
                                type: Types.RECEIPT_RESTAURANT_UPDATED,
                                payload: res.data.restaurant,
                            });
                        }
                    })
                    .catch((err) => console.error(err.response));
            }
        })
        .catch((err) => console.error(err.response));
};

export const updateGuestName = (guestInfo) => (dispatch, getState) => {
    console.log(getState());
    Axios.post(`${APIv1Endpoint}guest/update`, {
        guest: {
            // ...getState().table.selected_guest,
            ...guestInfo,
        },
    })
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.error('Could not update campaign code');
            console.error(err.response);
            toast.error('Could not update guest');
        });
};

export const getRestaurantRating = (guest_id) => (dispatch, getState) => {
    const endpoint = `${APIv1Endpoint}guests/${guest_id}/review`;
    const order = getState().order.receipt_order;
    Axios.get(endpoint)
        .then((res) => {
            dispatch({
                type: Types.RESTAURANT_RATING_UPDATED,
                payload: res?.data?.review?.rating || 0,
            });
        })
        .catch((err) => {
            console.error(err.response);
            dispatch({
                type: Types.RESTAURANT_RATING_UPDATED,
                payload: 0,
            });
        });
};

// INFO: new implementation
export const updateRestaurantRatingNew =
    (rating, item_id) => (dispatch, getState) => {
        const endpoint = `${APIv1Endpoint}orders/add/review`;
        const order = getState().order.receipt_guest.order;
        Axios.post(endpoint, {
            review: {
                order_id: order.order_id,
                guest_id: order.guest_id,
                restaurant_id: order.restaurant_id,
                rating: rating,
                item_id: item_id || '',
            },
        }).catch((err) => console.error(err.response));
        dispatch({
            type: Types.RESTAURANT_RATING_UPDATED,
            payload: rating,
        });
    };

export const updateRestaurantRating =
    (rating, item_id) => (dispatch, getState) => {
        const endpoint = `${APIv1Endpoint}orders/add/review`;
        const order = getState().order.receipt_order;
        Axios.post(endpoint, {
            review: {
                order_id: order.order_id,
                guest_id: order.guest_id,
                restaurant_id: order.restaurant_id,
                rating: rating,
                item_id: item_id || '',
            },
        }).catch((err) => console.error(err.response));
        dispatch({
            type: Types.RESTAURANT_RATING_UPDATED,
            payload: rating,
        });
    };

export const loadCampaigns = () => (dispatch, getState) => {
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    if (!restaurant_id) return;

    const endpoint = `${APIv1Endpoint}campaign/list/public/${restaurant_id}`;
    const ListCampaignsRequest = { restaurant_id };
    Axios.get(endpoint, ListCampaignsRequest)
        .then((res) => {
            let { campaigns } = res.data;
            if (!campaigns) {
                return;
            }

            //take the discount object out for easy access later
            campaigns.forEach((campaign) => {
                const { campaign_type } = campaign;
                switch (campaign_type) {
                    case 'CAMPAIGN_SPEND_AND_GET':
                        campaign.discount =
                            campaign.spend_and_get_campaign.discount;
                        return;
                    case 'CAMPAIGN_PURCHASE_AND_GET':
                        campaign.discount =
                            campaign.purchase_and_get_campaign.discount;
                        return;
                    case 'CAMPAIGN_DISCOUNTED_ENTITY':
                        campaign.discount =
                            campaign.discounted_entity_campaign.discount;
                        return;
                    default:
                        return;
                }
            });

            //sort in alphabetical order
            campaigns.sort((campaign_a, campaign_b) => {
                if (campaign_a.name < campaign_b.name) {
                    return -1;
                } else if (campaign_a.name > campaign_b.name) {
                    return 1;
                }
                return 0;
            });

            dispatch({
                type: Types.RESTAURANT_CAMPAIGNS_LOADED,
                payload: campaigns,
            });

            //filter out featured campaigns
            const featuredCampaigns = campaigns.filter((campaign) => {
                return campaign.is_featured;
            });
            dispatch({
                type: Types.RESTAURANT_FEATURED_CAMPAIGNS_LOADED,
                payload: featuredCampaigns,
            });
        })
        .catch((err) => {
            console.error(err);
        });
};

export const campaignSelected =
    (campaign, history, redirect = true) =>
    (dispatch, getState) => {
        if (!campaign) {
            dispatch({
                type: Types.CAMPAIGN_SELECTED,
                payload: {},
            });
            return;
        }
        GAEvent('User', 'Campaign selected', campaign.campaign_id);
        dispatch({
            type: Types.CAMPAIGN_SELECTED,
            payload: campaign,
        });
        if (redirect) {
            history.push('/oneCampaign');
        }
    };

export const campaignItemSelected = (campaignItems) => (dispatch, getState) => {
    dispatch({
        type: Types.CAMPAIGN_ITEM_SELECTED,
        payload: [...campaignItems],
    });
};

export const addCampaignToOrder =
    (history, redirect = true) =>
    (dispatch, getState) => {
        if (!getState().main.is_active) {
            console.log('addCampaignToOrder - notTakingOrders', {
                isActive: getState().main.is_active,
            });
            toast.dismiss('closedToast');
            toast.error(i18n.t('toast.notTakingOrders'), {
                toastId: 'closedToast',
            });
            return;
        }
        const {
            temporary_order,
            selected_campaign,
            selected_campaign_items,
            campaign_instances_in_cart,
            campaign_instances,
        } = getState().order;
        const restaurant_id = getState().main.restaurant_info.restaurant_id;
        const { discount_type } = getState().order.selected_campaign.discount;
        const specialPrice =
            discount_type === 'DISCOUNT_SPECIAL_PRICE' ? true : false;
        const freeItems = discount_type === 'DISCOUNT_ITEM' ? true : false;
        let total_price = 0;
        GAEvent('User', 'Campaign added to order', selected_campaign);

        selected_campaign_items.forEach((item, index) => {
            // ensure price exists
            if (!item.price) {
                item.price = 0;
            }

            item.multiplier = 1;
            item.is_reward_item = false;
            //mark the items if they are reward items
            if (
                freeItems &&
                selected_campaign.campaign_type === 'CAMPAIGN_PURCHASE_AND_GET'
            ) {
                const { count } =
                    getState().order.selected_campaign
                        .purchase_and_get_campaign;
                if (index + 1 > count) {
                    item.is_reward_item = true;
                }
            }

            if (
                freeItems &&
                selected_campaign.campaign_type === 'CAMPAIGN_SPEND_AND_GET'
            ) {
                item.is_reward_item = true;
            }

            // get total price with modifiers
            let priceOfModifiers = 0;
            item.modifiers.forEach((modifier) => {
                if (modifier.price) {
                    priceOfModifiers += parseFloat(modifier.price);
                }
            });
            const priceWithModifiers =
                parseFloat(item.price) / 100 + parseFloat(priceOfModifiers);

            if (!specialPrice && !item.is_reward_item) {
                total_price += parseFloat(priceWithModifiers);
            } else {
                total_price += parseFloat(priceOfModifiers);
            }

            item.price_with_modifiers = priceWithModifiers;
        });

        if (specialPrice) {
            total_price +=
                getState().order.selected_campaign.discount.price / 100;
        }

        let campaign_instance_id = '';

        //if the campaign items are added through campaign code, take the campaign_instance_id and apply it to the items
        if (selected_campaign.campaign_instance_id) {
            campaign_instance_id = selected_campaign.campaign_instance_id;
            //else, if the campaign items are added as a PAG bundle, generate a random campaign_instance_id and apply it to the items
        } else {
            campaign_instance_id = v4();
        }

        //add campaign tags to label campaign combo items
        selected_campaign_items.forEach((item) => {
            item.campaign_instance_id = campaign_instance_id;
            temporary_order.push({
                ...item,
                price: item.price / 100,
                price_with_modifiers: item.price_with_modifiers,
            });
        });

        campaign_instances_in_cart.push({
            campaign: selected_campaign,
            campaign_instance_id,
            items: [...selected_campaign_items],
            total_price,
        });

        campaign_instances.push({
            campaign_instance_id,
            restaurant_id,
            campaign: selected_campaign,
        });

        dispatch({
            type: Types.CAMPAIGN_ADDED_TO_ORDER,
            payload: {
                items: temporary_order,
                campaign_instances_in_cart,
                campaign_instances,
            },
        });

        dispatch(calculateTotals(temporary_order, false));

        if (redirect) {
            toast.success(i18n.t('toast.promotionAddedToCart'));
            history.goBack();
        }
    };

export const editCampaign = (campaign, history) => (dispatch, getState) => {
    dispatch({
        type: Types.ITEM_EDIT_MODE_CHANGED,
        payload: true,
    });
    dispatch(campaignSelected(campaign, history, true));
};

export const saveEditedCampaign = (history) => (dispatch, getState) => {
    const {
        temporary_order,
        selected_campaign,
        selected_campaign_items,
        campaign_instances_in_cart,
        campaign_instances,
    } = getState().order;
    const { discount_type } = getState().order.selected_campaign.discount;
    const specialPrice =
        discount_type === 'DISCOUNT_SPECIAL_PRICE' ? true : false;
    const freeItems = discount_type === 'DISCOUNT_ITEM' ? true : false;
    let total_price = 0;
    GAEvent('User', 'Campaign added to order', selected_campaign);

    selected_campaign_items.forEach((item, index) => {
        // ensure price exists
        if (!item.price) {
            item.price = 0;
        }

        item.multiplier = 1;
        item.is_reward_item = false;

        //mark the items if they are reward items
        if (
            freeItems &&
            selected_campaign.campaign_type === 'CAMPAIGN_PURCHASE_AND_GET'
        ) {
            const { count } =
                getState().order.selected_campaign.purchase_and_get_campaign;
            if (index + 1 > count) {
                item.is_reward_item = true;
            }
        }
        if (
            freeItems &&
            selected_campaign.campaign_type === 'CAMPAIGN_SPEND_AND_GET'
        ) {
            item.is_reward_item = true;
        }

        // get total price with modifiers
        let priceOfModifiers = 0;
        item.modifiers.forEach((modifier) => {
            if (modifier.price) {
                priceOfModifiers += parseFloat(modifier.price);
            }
        });
        const priceWithModifiers =
            parseFloat(item.price) / 100 + parseFloat(priceOfModifiers);

        if (!specialPrice && !item.is_reward_item) {
            total_price += parseFloat(priceWithModifiers);
        } else {
            total_price += parseFloat(priceOfModifiers);
        }

        item.price_with_modifiers = priceWithModifiers;
    });

    if (specialPrice) {
        total_price += getState().order.selected_campaign.discount.price / 100;
    }

    campaign_instances_in_cart[selected_campaign.campaign_index] = {
        campaign: selected_campaign,
        campaign_instance_id: selected_campaign.campaign_instance_id,
        items: [...selected_campaign_items],
        total_price,
    };

    //add campaign tags to label campaign combo items
    selected_campaign_items.forEach((item) => {
        item.campaign_instance_id = selected_campaign.campaign_instance_id;
    });

    const selected_campaign_items_copy = cloneDeep(selected_campaign_items);
    selected_campaign_items_copy.forEach((item) => {
        item.price = item.price / 100;
    });

    //update those items in order
    const startingIndex = temporary_order.findIndex(
        ({ campaign_instance_id }) =>
            campaign_instance_id === selected_campaign.campaign_instance_id
    );
    const itemsToBeChanged = temporary_order.filter((orderItem) => {
        return orderItem;
    });
    temporary_order.splice(
        startingIndex,
        itemsToBeChanged.length,
        ...selected_campaign_items_copy
    );

    dispatch({
        type: Types.CAMPAIGN_ADDED_TO_ORDER,
        payload: {
            items: temporary_order,
            campaign_instances_in_cart,
            campaign_instances,
        },
    });

    dispatch({
        type: Types.ITEM_EDIT_MODE_CHANGED,
        payload: false,
    });

    dispatch(calculateTotals(temporary_order, false));

    toast.success(
        i18n.t('toast.campaignSaved', { campaignName: selected_campaign.name })
    );
    history.push({ pathname: '/order' });
};

export const deleteCampaignInOrder =
    (campaign_instance, notification = true) =>
    (dispatch, getState) => {
        if (!campaign_instance) {
            console.error('no campaign instance found');
            toast.error(i18n.t('toast.deleteError'));
            return;
        }
        let {
            campaign_instances_in_cart,
            campaign_instances,
            temporary_order,
        } = getState().order;

        //filter out items with same campaign_instance_id in order
        temporary_order = temporary_order.filter(
            ({ campaign_instance_id }) =>
                campaign_instance_id !== campaign_instance.campaign_instance_id
        );

        //filter out campaign instances with same campaign_instance_id in order
        campaign_instances_in_cart = campaign_instances_in_cart.filter(
            ({ campaign_instance_id }) =>
                campaign_instance_id !== campaign_instance.campaign_instance_id
        );

        //filter out campaign instances with same campaign_instance_id in order
        campaign_instances = campaign_instances.filter(
            ({ campaign_instance_id }) =>
                campaign_instance_id !== campaign_instance.campaign_instance_id
        );

        dispatch({
            type: Types.CAMPAIGN_INSTANCE_DELETED,
            payload: {
                temporary_order,
                campaign_instances_in_cart,
                campaign_instances,
            },
        });

        dispatch(calculateTotals(temporary_order, false));
        if (notification) {
            toast.info(
                i18n.t('toast.campaignRemoved', {
                    campaignName: campaign_instance.campaign.name,
                })
            );
        }
    };

export const rewardItemAdded =
    (history, redirect = true) =>
    (dispatch, getState) => {
        let {
            selected_campaign,
            selected_campaign_items,
            temporary_order,
            campaign_instances_in_cart,
        } = getState().order;
        let total_price = 0;
        selected_campaign_items.forEach((item) => {
            // ensure price exists
            if (!item.price) {
                item.price = 0;
            }

            item.multiplier = 1;
            item.is_reward_item = true;
            item.campaign_instance_id = selected_campaign.campaign_instance_id;

            // get total price with modifiers
            let priceOfModifiers = 0;
            item.modifiers.forEach((modifier) => {
                if (modifier.price) {
                    priceOfModifiers += parseFloat(modifier.price);
                }
            });

            const priceWithModifiers =
                parseFloat(item.price) / 100 + parseFloat(priceOfModifiers);
            item.price_with_modifiers = priceWithModifiers;
            temporary_order.push({
                ...item,
                price: item.price / 100,
                price_with_modifiers: item.price_with_modifiers,
            });

            total_price += priceOfModifiers;
        });

        campaign_instances_in_cart.push({
            campaign: selected_campaign,
            campaign_instance_id: selected_campaign.campaign_instance_id,
            items: [...selected_campaign_items],
            total_price,
        });

        // campaign_instances.push({
        //     campaign_instance_id: selected_campaign.campaign_instance_id,
        //     restaurant_id,
        //     campaign: selected_campaign,
        // })

        dispatch({
            type: Types.REWARD_ITEMS_ADDED_TO_ORDER,
            payload: {
                items: temporary_order,
                campaign_instances_in_cart,
            },
        });

        dispatch(calculateTotals(temporary_order, false));
        if (redirect) {
            toast.success(i18n.t('toast.rewardItemsAdded'));
            history.push('/order');
        }
    };

export const validateCode =
    (history, callback = () => {}) =>
    (dispatch, getState) => {
        const guest_id = getState().table.selected_guest.guest_id;
        let order = getState().order.temporary_order;
        let { campaign_instances, campaign_instances_in_cart } =
            getState().order;
        const restaurant_id = getState().main.restaurant_info.restaurant_id;
        const amount = Math.round(
            getState().order.payment_totals.net_total * 100
        );
        const itemInstance = convertTempItemToItemInstance(order, {
            order_id: '',
            guest_id,
        });
        const discount_code = getState().order.discount_code_field;

        const previousCampaignCode =
            getState().table.selected_guest.campaign_code;
        //if previous campaign exists, remove those campaign items

        const request = {
            guest: {
                guest_id,
                restaurant_id,
                order: {
                    amount,
                    items: itemInstance,
                    campaigns: campaign_instances,
                },
                campaign_code: discount_code,
            },
        };

        Axios.post(`${APIv1Endpoint}campaign/getForGuest`, request)
            .then((res) => {
                let campaigns = res.data.campaigns;
                let campaign_code_returned = false;
                let appliedCampaignInstance;
                let appliedCampaign;
                (campaigns || []).forEach((campaignInstance) => {
                    if (
                        campaignInstance.campaign.campaign_code ===
                        discount_code
                    ) {
                        campaign_code_returned = true;
                        appliedCampaignInstance = campaignInstance;
                        appliedCampaign = campaignInstance.campaign;
                        appliedCampaign.campaign_instance_id =
                            campaignInstance.campaign_instance_id;
                    }
                });

                if ((campaigns || []).length > 0 && campaign_code_returned) {
                    //sort the campaigns by letter
                    campaigns.sort((campaign_a, campaign_b) => {
                        if (
                            campaign_a.campaign.name < campaign_b.campaign.name
                        ) {
                            return -1;
                        } else if (
                            campaign_a.campaign.name > campaign_b.campaign.name
                        ) {
                            return 1;
                        }
                        return 0;
                    });
                    // update guest with code

                    if (previousCampaignCode) {
                        const previousCampaignInstance =
                            campaign_instances.find(
                                ({ campaign }) =>
                                    campaign.campaign_code ===
                                    previousCampaignCode
                            );
                        dispatch(
                            deleteCampaignInOrder(
                                previousCampaignInstance,
                                false
                            )
                        );
                    }

                    dispatch(updateGuestWithCampaignCode(discount_code));
                    if (appliedCampaign) {
                        if (
                            appliedCampaign.campaign_type ===
                            'CAMPAIGN_PURCHASE_AND_GET'
                        ) {
                            appliedCampaign.discount =
                                appliedCampaign.purchase_and_get_campaign.discount;
                            appliedCampaign.campaign_instance_id =
                                appliedCampaignInstance.campaign_instance_id;
                            dispatch(
                                campaignSelected(appliedCampaign, history)
                            );
                        } else {
                            // recalculate order totals
                            dispatch(calculateTotals(order, false));
                        }
                    }
                    toast.success(
                        i18n.t('toast.discountCodeApplied', {
                            discountCode: discount_code,
                        })
                    );
                    callback(true);
                } else {
                    dispatch(calculateTotals(order, false));
                    toast.error(i18n.t('toast.invalidDiscountCode'));
                    callback(false);
                }
            })
            .catch((err) => {
                toast.error(i18n.t('toast.invalidDiscountCode'));
                console.error(err.message);
                callback(false);
            });
    };

export const clearCampaignsInOrder = () => (dispatch, getState) => {};

export const processBamboraToken =
    (history, token, paymentMethod) => (dispatch, getState) => {
        const endpoint = getState().main.restaurant_info.endpoint;
        const v2 = getState().main.version === 2;

        let order = getState().order.temporary_order;
        const tipType = getState().order.payment_tip_type;
        const tip = Math.round(getState().order.payment_tip * 100);
        const tipPercentage = tipType === 'percentage' ? tip : undefined;
        const tipFixed = tipType === 'fixed' ? tip : undefined;
        let delivery_tip = getState().order.payment_totals.delivery_tip_amount;
        const { campaign_instances } = getState().order;

        // reset payment intent
        dispatch({
            type: Types.PAYMENT_INTENT_RECEIVED,
            payload: {},
        });

        const guest_id = getState().table.selected_guest.guest_id;
        const restaurant_id = getState().main.restaurant_info.restaurant_id;

        const unnestedItemInstance = order.map((item) => {
            // get modifier ids
            let modifier_ids = [];
            if (item.modifiers) {
                item.modifiers.forEach((modifiers) => {
                    modifier_ids.push(modifiers.modifier_id);
                });
            }

            return {
                guest_id,
                item_id: item.item_id,
                modifier_ids,
                multiplier: item.multiplier,
                special_instructions: item.special_instructions,
                campaign_instance_id: item.campaign_instance_id,
                analytics_menu_id: item.analytics_menu_id,
            };
        });

        // get payment intent
        const url = `${APIv1Endpoint}orders/orderAndPay`;
        const req = {
            guest_id,
            restaurant_id,
            tip_percentage: tipPercentage,
            tip_fixed: tipFixed,
            delivery_tip: delivery_tip.toFixed(0),
            items: unnestedItemInstance,
            campaigns: campaign_instances,
            token: token,
            payment_method: paymentMethod,
        };
        Axios.post(url, req).then(
            (orderAndPayResponse) => {
                if (orderAndPayResponse.status === 200) {
                    dispatch(paymentComplete({}, history));
                } else {
                    toast.error(i18n.t('toast.paymentProcessingError'));
                    history.push(v2 ? `/v2/${endpoint}/cart` : '/logoview');
                }
            },
            (err) => {
                if (err.response) {
                    if (
                        err.response.status === 503 &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        toast.error(i18n.t('toast.errorNotification3'), {
                            toastId: 'error',
                        });
                    } else {
                        toast.error(i18n.t('toast.errorNotification3'), {
                            toastId: 'error',
                        });
                    }
                    console.error(err.response);
                } else {
                    toast.error(i18n.t('toast.errorNotification3'), {
                        toastId: 'error',
                    });
                    console.error(err);
                }
                history.push(v2 ? `/v2/${endpoint}/cart` : '/logoview');
            }
        );
    };
