import React, { Component } from 'react';
import './css/ErrorBoundary.css';
import { captureException } from '@sentry/react';
import { withTranslation } from 'react-i18next';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorType: null,
            message: null,
        };
    }

    static getDerivedStateFromError(err) {
        if (err.name)
            return {
                hasError: true,
                errorType: err.name,
                message: err.message,
            };
    }

    componentDidCatch(err) {
        console.error(err);
        captureException(err);
    }

    renderErrorRefreshPage = () => {
        return (
            <div className="ErrorBoundaryContainer">
                <h1 className="ErrorOops">
                    {' '}
                    {this.props.t('errorBoundary.clickToRefresh')}
                </h1>
                <div
                    className="RefreshButton"
                    onClick={() => window.location.reload(false)}
                >
                    {this.props.t('errorBoundary.clickToRefresh')}
                </div>
                <p className="ErrorMessage">{this.state.message}</p>
            </div>
        );
    };

    render() {
        let errorPageToDisplay = (
            <div> {this.props.t('errorBoundary.oopsSomethingWentWrong')}</div>
        );
        if (this.state.hasError) {
            switch (this.state.errorType) {
                default:
                    errorPageToDisplay = this.renderErrorRefreshPage();
                    break;
            }
            return (
                <div className="ErrorBoundaryPage"> {errorPageToDisplay} </div>
            );
        }

        return this.props.children;
    }
}

const ErrorBoundaryWithTranslation = withTranslation()(ErrorBoundary);

export default ErrorBoundaryWithTranslation;
