import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import { APIv1Endpoint } from '../../endpoints';
import './css/PayNuvei.css';
import * as TableActions from '../../actions/tableActions';
import amplitude from 'amplitude-js';
const queryString = require('query-string');

class NuveiConfirmation extends React.Component {
    verifyNuveiPayment(params) {
        const request = {
            terminal_id: params.TERMINALID,
            order_id: params.ORDERID,
            datetime: params.DATETIME,
            approval_code: params.APPROVALCODE,
            response_code: params.RESPONSECODE,
            response_text: params.RESPONSETEXT,
            hash: params.HASH,
            total: Math.round(params.AMOUNT * 100),
            unique_ref: params.UNIQUEREF,
        };

        Axios.post(`${APIv1Endpoint}verifyNuveiPayment`, request)
            .then((verificationResponse) => {
                if (verificationResponse.data.success) {
                    amplitude
                        .getInstance()
                        .logEvent('Nuvei Payment Verification Succeeded');
                    this.props.submitPhoneNumber();
                    // order is complete
                    this.props.history.push('/orderComplete');
                } else {
                    amplitude
                        .getInstance()
                        .logEvent(
                            'Nuvei Payment Verification Failed',
                            verificationResponse
                        );
                    this.setState({
                        verificationError: true,
                        verificationErrorDetails: {
                            message:
                                'Sorry, there was an issue with your order. You will be refunded.',
                        },
                    });
                    console.error(params);
                }
            })
            .catch((err) => {
                amplitude
                    .getInstance()
                    .logEvent('Nuvei Payment Verification Errored', err);
                if (err.response) {
                    this.setState({
                        verificationError: true,
                        verificationErrorDetails: {
                            message: `Sorry, there was an error validating your payment. Did you accidentally press the Back button?`,
                        },
                    });
                    console.error(err.response);
                } else {
                    console.error(err);
                }
            });

        amplitude.getInstance().logEvent('Verifying Nuvei Payment');
    }

    constructor(props) {
        super(props);
        this.state = {
            verificationError: false,
            verificationErrorDetails: {},
            v2: props.state.main.version === 2,
        };
    }

    componentDidMount() {
        if (this.state.v2) {
            return;
        }
        let params = queryString.parse(this.props.location.search);
        // verify nuvei payment
        this.verifyNuveiPayment(params);
    }

    render() {
        const endpoint = this.props.state.main.restaurant_info.endpoint;
        if (this.state.v2) {
            this.props.history.replace(
                `/v2/${endpoint}/nuveiConfirmation${this.props.location.search}`
            );
        }

        if (this.state.verificationError) {
            return (
                <div className="nuveiPaymentComplete">
                    <div className="nuveiPaymentCompleteText">
                        {this.state.verificationErrorDetails.message}
                    </div>
                </div>
            );
        }

        return (
            <div>
                <Header></Header>
                <div className="nuveiPaymentComplete">
                    <div className="lds-ripple">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="nuveiPaymentCompleteText">
                        Thank you! Please wait...
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => ({ state }), {
    submitPhoneNumber: TableActions.submitPhoneNumber,
})(NuveiConfirmation);
