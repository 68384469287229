import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import TwoFA from '../../components/TwoFA';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import './css/TakeoutTwoFA.css';
import Axios from 'axios';
import { APIv1Endpoint } from '../../endpoints';
import { toast } from 'react-toastify';

import * as OrderActions from '../../actions/orderActions';
import * as TableActions from '../../actions/tableActions';
import { isFrontOfHouse, isHotel } from '../../utils';
import { i18n } from '../../locales';

const TwoFAPageEnum = {
    PhoneNumberInput: 0,
    CodeInput: 1,
    NameInput: 2,
};

class TakeoutTwoFA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: TwoFAPageEnum.NameInput,
            isUpdatingPhoneNumber: false,
            phone: '',
            name: '',
        };
    }

    componentDidMount() {
        const guest = this.props.state.table.selected_guest;
        if (!guest) {
            const endpoint =
                this.props.state.main.restaurant_info.restaurant_id;
            if (endpoint) {
                this.props.history.push(`/${endpoint}`);
            } else {
                this.props.history.push('/');
            }
        }
        if (guest.phone_number) {
            this.setState({
                phone: guest.phone_number,
            });
        }
        if (guest.name !== guest.table_id) {
            this.setState({
                name: guest.name,
                page: TwoFAPageEnum.PhoneNumberInput,
            });
        }
    }

    navigateToPage(page) {
        this.setState({ page });
    }

    verifyPhoneNumber() {
        const phoneNumber = this.state.phone;
        const customerName = this.state.name;
        const isValid = isValidPhoneNumber(phoneNumber);

        this.setState({
            isUpdatingPhoneNumber: true,
        });

        // send phone number

        if (!isValid) {
            toast.error(i18n.t('toast.invalidPhoneNumber2'));
            this.setState({
                isUpdatingPhoneNumber: false,
            });
            return;
        }

        // update guest phone number
        const guest = this.props.state.table.selected_guest;

        const updated_guest = {
            ...guest,
            phone_number: phoneNumber,
            name: customerName,
        };

        Axios.post(`${APIv1Endpoint}guest/update`, {
            guest: updated_guest,
        })
            .then((res) => {
                this.props.loadGuestFromDB();

                // skip 2fa
                this.onSuccess();

                // 2FA HERE
                // this.setState({
                //     isUpdatingPhoneNumber: false,
                //     page: TwoFAPageEnum.CodeInput,
                // });
            })
            .catch((err) => {
                this.setState({ isUpdatingPhoneNumber: false });
                console.error(err);
                toast.error(i18n.t('toast.addPhoneNumberError'));
            });
    }

    onSuccess() {
        // toast.success("Your phone number is verified!");
        this.props.setTwoFACompleted(true);

        if (isHotel()) {
            this.props.history.push('/hotel/customerform');
        } else {
            this.props.getPaymentIntent_PayBefore(
                this.props.history,
                isFrontOfHouse()
            );
        }
    }

    onFailure() {}

    renderNameInput() {
        const skippable = isFrontOfHouse();
        const guest = this.props.state.table.selected_guest;

        return (
            <>
                <Header back="/order" history={this.props.history} />

                <div className="verifyPhoneNumContainer">
                    <div className="verifyPhoneNumberLabel">
                        {isFrontOfHouse()
                            ? 'Enter Customer Name'
                            : 'Enter your name'}
                    </div>
                    <input
                        placeholder="Name"
                        value={this.state.name}
                        onChange={(e) =>
                            this.setState({ name: e.target.value })
                        }
                        style={{
                            width: 'calc(100% - 30px)',
                            backgroundColor: '#F7F7F7',
                            padding: '15px',
                            border: '0',
                            borderBottom: '5px solid #DADADA',
                            borderRadius: '5px',
                            fontSize: '22px',
                            marginTop: '5px',
                        }}
                    />

                    <div
                        className="twoFA-button"
                        style={{ marginLeft: '0', marginRight: '0' }}
                        onClick={(e) =>
                            this.navigateToPage(TwoFAPageEnum.PhoneNumberInput)
                        }
                    >
                        Next
                    </div>

                    {skippable ? (
                        <div
                            className="twoFA-button"
                            style={{
                                marginLeft: '0',
                                marginRight: '0',
                                backgroundColor: '#555',
                            }}
                            onClick={(e) => {
                                this.navigateToPage(
                                    TwoFAPageEnum.PhoneNumberInput
                                );
                                this.setState({ name: guest.table_id });
                            }}
                        >
                            Skip
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }

    renderStep1() {
        const default_country =
            this.props.state.main.restaurant_info.restaurant_id ===
            '2993daf5-c798-11ea-b545-02685a4295ea'
                ? 'US'
                : 'CA';
        const skippable = isFrontOfHouse();

        return (
            <>
                <Header back="/order" history={this.props.history} />

                <div className="verifyPhoneNumContainer">
                    <div className="verifyPhoneNumberLabel">
                        Enter phone number
                    </div>
                    <PhoneInput
                        placeholder="(123) 456-7890"
                        defaultCountry={default_country}
                        value={this.state.phone}
                        onChange={(e) => this.setState({ phone: e })}
                        style={{
                            width: 'calc(100% - 30px)',
                            backgroundColor: '#F7F7F7',
                            borderBottom: '5px solid #DADADA',
                        }}
                    />

                    {this.state.isUpdatingPhoneNumber ? (
                        <>
                            <div
                                className="twoFA-button"
                                style={{ marginLeft: '0', marginRight: '0' }}
                            >
                                <div className="twofa-loader">Loading...</div>
                            </div>
                        </>
                    ) : (
                        <div
                            className="twoFA-button"
                            style={{ marginLeft: '0', marginRight: '0' }}
                            onClick={(e) => this.verifyPhoneNumber()}
                        >
                            Next
                        </div>
                    )}

                    {skippable ? (
                        <div
                            className="twoFA-button"
                            style={{
                                marginLeft: '0',
                                marginRight: '0',
                                backgroundColor: '#555',
                            }}
                            onClick={(e) => this.onSuccess()}
                        >
                            Skip
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }

    backToStep1() {
        this.setState({
            page: TwoFAPageEnum.PhoneNumberInput,
        });
    }

    renderStep2() {
        const guest = this.props.state.table.selected_guest;
        const { guest_id } = guest;

        return (
            <>
                <img
                    alt="Back"
                    onClick={(e) => this.backToStep1()}
                    src="/assets/thin-back-btn.png"
                    className="thinBackButton"
                    style={{
                        padding: '20px',
                        marginTop: '10px',
                    }}
                />

                <div className="verifyPhoneNumContainer">
                    <div className="verifyPhoneNumberLabel">Got it!</div>
                    <div className="verifyPhoneNumberLabel">
                        We've sent{' '}
                        <span className="phoneNumberBlue">
                            {this.state.phone}
                        </span>{' '}
                        a 6-digit code. Please enter it below.
                    </div>
                    <TwoFA
                        onSuccess={(e) => this.onSuccess()}
                        onFailure={(e) => this.onFailure()}
                        guestId={guest_id}
                    ></TwoFA>
                </div>
            </>
        );
    }

    render() {
        return (
            <div className="twoFAContainer">
                <div className="twoFAFixedWidth">
                    {this.state.page === TwoFAPageEnum.PhoneNumberInput
                        ? this.renderStep1()
                        : ''}
                    {this.state.page === TwoFAPageEnum.NameInput
                        ? this.renderNameInput()
                        : ''}
                    {this.state.page === TwoFAPageEnum.CodeInput
                        ? this.renderStep2()
                        : ''}
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    getPaymentIntent_PayBefore: OrderActions.getPaymentIntent_PayBefore,
    setTwoFACompleted: OrderActions.setTwoFACompleted,
    loadGuestFromDB: TableActions.loadGuestFromDB,
})(TakeoutTwoFA);
