import React from 'react';
import './css/CampaignBlock.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../endpoints';

const CampaignBlock = (props) => {
    let name = props.campaign.name;
    if (name.length > 30) {
        name = `${props.campaign.name.substring(0, 70)}...`;
    }

    let description = props.campaign?.description || '';
    if (description.length > 70) {
        description = `${props.campaign.description.substring(0, 70)}...`;
    }

    let itemId = '';
    switch (props.campaign.campaign_type) {
        case 'CAMPAIGN_SPEND_AND_GET':
            if (
                props.campaign.spend_and_get_campaign.discount.discount_type ===
                'DISCOUNT_ITEM'
            ) {
                itemId =
                    props.campaign.spend_and_get_campaign.discount.items[0]
                        .items[0].item_id;
            }
            break;
        case 'CAMPAIGN_PURCHASE_AND_GET':
            itemId =
                props.campaign.purchase_and_get_campaign.item_groups[0]
                    .item_ids[0];
            break;
        case 'CAMPAIGN_DISCOUNTED_ENTITY':
            itemId =
                props.campaign.discounted_entity_campaign.discounted_entity
                    .item_ids[0];
            break;
        default:
            return;
    }
    const restaurant_id = props.state.main.restaurant_info.restaurant_id;
    const itemImageUrl = `${cloudfrontBaseUrl}${restaurant_id}/items/${itemId}.jpeg`;

    return (
        <div className="campaignBlockContainer" onClick={props.onClick}>
            <div className="campaignBlockText">
                <p className="campaignBlockPromotionLabel">
                    {props.t('cart.promotion')}
                </p>
                <p className="campaignBlockTitle">{name}</p>
                <p className="campaignBlockDescription">{description}</p>
            </div>
            <div
                className="campaignBlockImage"
                style={{ backgroundImage: `url(${itemImageUrl})` }}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({ state });
const ConnectedCampaignBlock = connect(mapStateToProps)(CampaignBlock);

const TranslatedCampaignBlock = withTranslation()(ConnectedCampaignBlock);

export default TranslatedCampaignBlock;
