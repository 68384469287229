import React from 'react';

import Header from '../components/Header';
import { connect } from 'react-redux';

import * as TableCodeActions from '../actions/tableCodeActions';

// Css
import './css/Home.css';

// Components

class Home extends React.Component {
    componentDidMount() {
        // Auto select the first input code on load
        //this.tableCodeInput1.focus();
        this.props.resetReduxState();

        // test stripe
    }

    /* Moves cursor to previous field when backspace is pressed
    Params:
      data (obj): onKeyDown event
      index (int): Current field index
    Returns:
      None
  */
    handleTableCodeBackspace(data, index) {
        // Get the table code at that index
        const tableCodeAtIndex = this.props.state.table_id[index];

        const isBackspacePressed = (data.keyCode === 8) | (data.keyCode === 46);
        const isTableCodeAtIndexEmpty = tableCodeAtIndex === '';

        // check for backspace and that tablecode is empty
        if (isBackspacePressed && isTableCodeAtIndexEmpty) {
            // Move the focused cursor to the left inbot box
            switch (index) {
                case 3:
                    this.tableCodeInput2.focus();
                    break;
                case 2:
                    this.tableCodeInput1.focus();
                    break;
                case 1:
                    this.tableCodeInput0.focus();
                    break;
                default:
                    break;
            }
        }
    }

    /* Gets input from a tableCodeInput field and updates the state
    Params:
      event (obj): input onChange data
      index (int): Index of the input code
    Returns:
      None
  */
    tableCodeInput(event, index) {
        // Get value that was entered
        const value = event.target.value;

        // Update table code using action
        this.props.enterTableCode(value, index);

        // Focus on next field
        if (value !== '') {
            //Auto focus to the next input field
            switch (index) {
                case 0:
                    this.tableCodeInput1.focus();
                    break;
                case 1:
                    this.tableCodeInput2.focus();
                    break;
                case 2:
                    this.tableCodeInput3.focus();
                    break;
                default:
                    break;
            }
        }
    }

    getTableCodeValue(index) {
        return this.props.state.table_id[index];
    }

    /* GO button for "Enter Table Code" pressed
     */
    goButtonPressed() {
        // History allows action to redirect the user
        const history = this.props.history;
        // Call submit function in the action creator
        this.props.tableCodeSubmit(history);
    }

    /* Renders a red error message */
    renderErrorMessage() {
        return (
            <div className="tableCodeErrorMessage">
                {this.props.state.table_id_error}
            </div>
        );
    }

    render() {
        return (
            <div>
                <Header history={this.props.history} showMenu />

                <div className="tableCodeContainer">
                    <div className="tableCodePromptText">Enter Table Code</div>
                    <div className="tableCodeInputContainer">
                        <input
                            className="tableCodeInput"
                            maxLength="1"
                            value={this.getTableCodeValue(0)}
                            ref={(ref) => (this.tableCodeInput0 = ref)}
                            onChange={(data) => this.tableCodeInput(data, 0)}
                            onKeyDown={(data) =>
                                this.handleTableCodeBackspace(data, 0)
                            }
                        />
                        <input
                            className="tableCodeInput"
                            maxLength="1"
                            value={this.getTableCodeValue(1)}
                            ref={(ref) => (this.tableCodeInput1 = ref)}
                            onChange={(data) => this.tableCodeInput(data, 1)}
                            onKeyDown={(data) =>
                                this.handleTableCodeBackspace(data, 1)
                            }
                        />
                        <input
                            className="tableCodeInput"
                            maxLength="1"
                            value={this.getTableCodeValue(2)}
                            ref={(ref) => (this.tableCodeInput2 = ref)}
                            onChange={(data) => this.tableCodeInput(data, 2)}
                            onKeyDown={(data) =>
                                this.handleTableCodeBackspace(data, 2)
                            }
                        />
                        <input
                            className="tableCodeInput"
                            maxLength="1"
                            value={this.getTableCodeValue(3)}
                            ref={(ref) => (this.tableCodeInput3 = ref)}
                            onChange={(data) => this.tableCodeInput(data, 3)}
                            onKeyDown={(data) =>
                                this.handleTableCodeBackspace(data, 3)
                            }
                        />

                        <img
                            alt="Submit"
                            onMouseDown={() => {
                                this.goButtonPressed();
                            }}
                            className="tableCodeSubmitButton"
                            src="/assets/tableCodeSubmitBtn.svg"
                        ></img>
                    </div>
                    {this.renderErrorMessage()}
                </div>

                <div className="welcomeSplash">
                    <div className="welcomeTitle">
                        Ditch the menu. Order with your phone.
                    </div>
                </div>
            </div>
        );
    }
}

//<div className="welcomeDetail">Enter your table code to get started.</div>

//<div className="splashActionButton">How does it work?</div>

// TODO: Only map state vars we need
const mapStateToProps = (state) => {
    return { state: state.main };
};

// Connect redux and enable actions
export default connect(mapStateToProps, {
    enterTableCode: TableCodeActions.enterTableCode,
    tableCodeSubmit: TableCodeActions.tableCodeSubmit,
    resetReduxState: TableCodeActions.resetReduxState,
})(Home);
