import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

// Components
import RestaurantMenus from './Menu/RestaurantMenus';
import Category from './Menu/Category';
import MenuItemDetail from './Menu/ItemDetail';
import AddedToOrder from './Menu/AddedToOrder';

import './Menu/css/spinners.css';

// Action Creators
import * as OrderActions from '../actions/orderActions';

class OrderingMenu extends React.Component {
    componentDidMount() {}

    render() {
        const { path } = this.props.match;
        return (
            <div>
                <Route
                    exact
                    path={`${path}`}
                    component={RestaurantMenus}
                ></Route>
                <Route path={`${path}/category`} component={Category} />
                <Route path={`${path}/item`} component={MenuItemDetail} />
                <Route path={`${path}/addedToOrder`} component={AddedToOrder} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { table: state.table, main: state.main };
};

const mapActionsToProps = {
    menuCategorySelected: OrderActions.menuCategorySelected,
};

export default connect(mapStateToProps, mapActionsToProps)(OrderingMenu);
