import * as Types from './TYPES';

const INITIAL_STATE = {
    // Entire menu
    menu: {
        is_loading: false,
        menus: [],
        inScheduleMenus: [],
        categories: [],
        items: [],

        modifier_groups: [],
        modifiers: [],
        schedule: [],

        campaigns: [],
        featured_campaigns: [],
        all_categories_to_items_map: {},

        top_10: [],
        temporary_order: [],
    },

    affiliate_code: '',

    demo: 123,

    // device type
    device_type: Types.DeviceTypes.CONSUMER_DEVICE,

    // Selected objects
    selected_category: {},
    selected_menu: {},
    selected_item: {},
    selected_item_quantity: 1,
    selected_item_modifiers: [], // currently editing item
    selected_item_special_instructions: '',
    page_before_item: {},
    selected_campaign: {},
    selected_campaign_items: [],

    is_edit_item: false,

    // delete item
    delete_item: {},

    // Orders
    temporary_order: [],
    live_order: [],
    order_instructions: '',
    campaign_instances: [],
    campaign_instances_in_cart: [],
    cart_updating: false,

    // user select order time/date
    order_date_type: Types.OrderDateType.NOW,
    order_time: null,
    // order_time: new Date(),

    //
    display_tutorial_1: false,
    display_tutorial_2: false,
    tutorial_1_shown: false,

    // Payment
    payment_processor: Types.PaymentProcessor.NONE,
    payment_type: Types.PaymentType.DISABLED,
    delivery_tip_options: [0, 0.1, 0.15, 0.2, 0.25],
    payment_tip_type: '',
    payment_tip: 0,

    //
    pay_at_counter_loading: false,
    campaign_modal_is_open: false,

    // Takeout
    twofa_completed: false,
    twofa_code_requested: 0,

    // Totals
    payment_name: 'Mentum',
    payment_totals: {
        tax_amount: 0,
        total_with_tax: 0,
        tip_amount: 0,
        delivery_tip_amount: 0,
        total_with_tip_and_tax: 0,
        net_total: 0,
        discount: 0,
    },

    // Discounts
    campaign_code_metadata: {},
    discount_code_field: '',

    payment_intent: null,
    payment_intent_completed: null,
    // stripe payment data

    // nuvei payment data
    nuvei_payment_request: {},

    // Receipt
    receipt_order: {},
    receipt_restaurant: {},
    receipt_guest: {},
    receipt_raw_guest: {},
    restaurant_rating: -1,

    //Delivery
    delivery_option_updated: true,
    payment_loading: false,
    dropoff_instructions: '',
    dropoff_submit: false,
    dropoff_eta: null,
    delivery_tip_percentage: 0,
    delivery_fee: 0,
    // Default to take-out
    order_type: Types.DineInOrTakeoutSetting.TAKEOUT,
};

const orderReducer = (state = INITIAL_STATE, action) => {
    // Copy state for modification
    let copy = { ...state };

    switch (action.type) {
        case Types.DINE_IN_OR_TAKE_OUT_CHANGED:
            copy.order_type = action.payload;
            return copy;
        case Types.READY_TO_PAY_CHECKOUT_DISPLAY:
            copy.display_tutorial_2 = action.payload;
            return copy;
        case Types.TAP_TO_CHECKOUT_NOTIF_DISPLAY:
            copy.display_tutorial_1 = action.payload;
            return copy;
        case Types.TAP_TO_CHECKOUT_NOTIF_SHOWN:
            copy.tutorial_1_shown = true;
            return copy;
        case Types.TOP_TEN_LOADED:
            copy.menu.top_10 = action.payload;
            return copy;
        case Types.NUVEI_PAYMENT_REQUEST_RECEIVED:
            copy.nuvei_payment_request = action.payload;
            return copy;
        case Types.PAYMENT_PROCESSOR_CHANGED:
            copy.payment_processor = action.payload;
            return copy;
        case Types.CAMPAIGN_CODE_METADATA_UPDATED:
            copy.campaign_code_metadata = action.payload;
            return copy;
        case Types.AFFILIATE_CODE_CHANGED:
            copy.affiliate_code = action.payload;
            return copy;
        case Types.DISCOUNT_CODE_FIELD_CHANGED:
            copy.discount_code_field = action.payload;
            return copy;
        case Types.PAY_AT_COUNTER_LOADING:
            copy.pay_at_counter_loading = action.payload;
            return copy;
        case Types.ITEM_EDIT_MODE_CHANGED:
            copy.is_edit_item = action.payload;
            return copy;
        case Types.CATEGORIES_TO_ITEMS_MAP_UPDATED:
            copy.menu.all_categories_to_items_map = action.payload;
            return copy;
        case Types.DEVICE_TYPE_CHANGED:
            copy.device_type = action.payload;
            return copy;
        case Types.FINALIZE_ITEM_QUANTITY_UPDATED:
            copy.menu.temporary_order = action.payload;
            return copy;
        case Types.MENU_SCHEDULES_LOADED:
            copy.menu.schedule = action.payload;
            return copy;
        case Types.ORDER_TIME_CHANGED:
            copy.order_time = action.payload;
            return copy;
        case Types.ORDER_DATE_TYPE_CHANGED:
            copy.order_date_type = action.payload;
            return copy;
        case Types.PAGE_BEFORE_ITEM_CHANGED:
            copy.page_before_item = action.payload;
            return copy;
        case Types.ORDER_SPECIAL_INSTRUCTIONS_CHANGED:
            copy.order_instructions = action.payload;
            return copy;
        case Types.SELECTED_ITEM_SPECIAL_INSTRUCTIONS_CHANGED:
            copy.selected_item_special_instructions = action.payload;
            return copy;
        case Types.TWOFA_CODE_TIME_REQUESTED:
            copy.twofa_code_requested = action.payload;
            return copy;
        case Types.TWOFA_COMPLETION_CHANGED:
            copy.twofa_completed = true;
            return copy;
        case Types.PAYMENT_INTENT_COMPLETED:
            copy.payment_intent_completed = action.payload;
            return copy;
        case Types.PAYMENT_TOTAL_UPDATED:
            copy.payment_totals = action.payload;
            return copy;
        case Types.PAYMENT_INTENT_RECEIVED:
            copy.payment_intent = action.payload;
            return copy;
        case Types.TIP_CHANGED:
            copy.payment_tip = action.payload.value;

            if (action.payload.type) {
                copy.payment_tip_type = action.payload.type;
            }

            return copy;
        case Types.DELIVERY_TIP_PERCENTAGE_CHANGED:
            copy.delivery_tip_percentage = action.payload;
            return copy;
        case Types.CART_UPDATING:
            copy.cart_updating = true;
            return copy;
        case Types.CART_UPDATED:
            copy.cart_updating = false;
            return copy;

        case Types.PAYMENT_TYPE_CHANGED:
            copy.payment_type = action.payload;
            return copy;

        case Types.ITEM_QUANTITY_CHANGED:
            copy.selected_item_quantity = action.payload;
            return copy;
        case Types.DELETE_ITEM_STEP_1:
            copy.delete_item = action.payload;
            return copy;
        case Types.CLEAR_ORDER_TIME:
            copy.order_time = null;
            return copy;
        case Types.CLEAR_TEMPORARY_ORDERS:
            copy.temporary_order = [];
            copy.menu.temporary_order = [];
            // clear the order time also
            copy.order_time = null;
            return copy;
        case Types.ITEM_ADDED_TO_ORDER:
            copy.temporary_order = action.payload;
            return copy;
        case Types.IS_EDIT_ITEM_CHANGED:
            copy.is_edit_item = action.payload;
            return copy;
        case Types.ITEM_MODIFIERS_UPDATED:
            copy.selected_item_modifiers = action.payload;
            return copy;
        case Types.RESTAURANT_MENUS_LOADED:
            copy.menu.menus = action.payload;
            return copy;
        case Types.LOADING_MENUS_STATUS_UPDATED:
            copy.menu.is_loading = action.payload;
            return copy;
        case Types.RESTAURANT_IN_SCHEDULE_MENUS_LOADED:
            copy.menu.inScheduleMenus = action.payload;
            return copy;
        case Types.RESET_STATE:
            // return INITIAL_STATE;
            return state;
        case Types.MENU_MODIFIERS_LOADED:
            copy.menu.modifiers = action.payload;
            return copy;
        case Types.MENU_MODIFIER_GROUPS_LOADED:
            copy.menu.modifier_groups = action.payload;
            return copy;
        case Types.SET_SELECTED_ITEM:
            copy.selected_item = action.payload;
            return copy;
        case Types.MENU_ITEM_SELECTED:
            copy.selected_item = action.payload;
            copy.selected_item_modifiers = [];
            copy.selected_item_special_instructions = '';
            return copy;
        case Types.MENU_SELECTED:
            copy.selected_menu = action.payload;
            return copy;
        case Types.MENU_CATEGORY_SELECTED:
            copy.selected_category = action.payload;
            return copy;
        case Types.MENU_ITEMS_LOADED:
            copy.menu.items = action.payload;
            return copy;
        case Types.MENU_CATEGORIES_LOADED:
            copy.menu.categories = action.payload;
            return copy;
        case Types.CAMPAIGN_INSTANCE_RECEIVED:
            copy.campaign_instances = action.payload;
            return copy;

        //CAMPAIGNS
        case Types.RESTAURANT_CAMPAIGNS_LOADED:
            copy.menu.campaigns = action.payload;
            return copy;
        case Types.RESTAURANT_FEATURED_CAMPAIGNS_LOADED:
            copy.menu.featured_campaigns = action.payload;
            return copy;
        case Types.CAMPAIGN_SELECTED:
            copy.selected_campaign = action.payload;
            return copy;
        case Types.CAMPAIGN_ITEM_SELECTED:
            copy.selected_campaign_items = action.payload;
            return copy;
        case Types.CAMPAIGN_ADDED_TO_ORDER:
            copy.temporary_order = action.payload.items;
            copy.campaign_instances_in_cart =
                action.payload.campaign_instances_in_cart;
            copy.campaign_instances = action.payload.campaign_instances;
            return copy;
        case Types.CAMPAIGN_INSTANCE_DELETED:
            copy.temporary_order = action.payload.temporary_order;
            copy.campaign_instances_in_cart =
                action.payload.campaign_instances_in_cart;
            copy.campaign_instances = action.payload.campaign_instances;
            return copy;
        case Types.CLEAR_CAMPAIGN_INSTANCES:
            copy.campaign_instances_in_cart = [];
            copy.campaign_instances = [];
            return copy;
        case Types.REWARD_ITEMS_ADDED_TO_ORDER:
            copy.temporary_order = action.payload.items;
            copy.campaign_instances_in_cart =
                action.payload.campaign_instances_in_cart;
            return copy;

        case Types.RECEIPT_ORDER_UPDATED:
            copy.receipt_order = action.payload;
            return copy;
        case Types.LOAD_GUEST_ORDERS:
            copy.receipt_guest = action.payload.guest;
            copy.receipt_raw_guest = action.payload.raw_guest;
            return copy;
        case Types.RECEIPT_RESTAURANT_UPDATED:
            copy.receipt_restaurant = action.payload;
            return copy;
        case Types.RESTAURANT_RATING_UPDATED:
            copy.restaurant_rating = action.payload;
            return copy;
        case Types.DELIVERY_OPTION_UPDATED:
            copy.delivery_option_updated = action.payload;
            return copy;
        case Types.DELIVERY_FEE:
            copy.delivery_fee = action.payload;
            return copy;
        case Types.PAYMENT_LOADING:
            copy.payment_loading = action.payload;
            return copy;
        case Types.DROPOFF_INSTRUCTIONS:
            copy.dropoff_instructions = action.payload;
            return copy;
        case Types.DROPOFF_SUBMIT:
            copy.dropoff_submit = action.payload;
            return copy;
        case Types.DROPOFF_ETA:
            copy.dropoff_eta = action.payload;
            return copy;
        case Types.RESTAURANT_INFO_UPDATED:
            const restaurant = action.payload;

            if (!copy.payment_tip_type) {
                copy.payment_tip_type =
                    restaurant.tip_type === 'TIP_TYPE_PERCENTAGE'
                        ? 'percentage'
                        : 'fixed';
            }

            if (copy.payment_tip) {
                if (
                    !restaurant.tip_enabled ||
                    !restaurant.tip_platform.includes('TIP_PLATFORM_WEB')
                ) {
                    copy.payment_tip = 0;
                    return copy;
                }
            }

            const defaultOptionIndex = restaurant.default_tip_option || 0;
            const defaultOption =
                action.payload.tip_options[defaultOptionIndex];

            copy.payment_tip = defaultOption / 100;

            return copy;
        default:
            return state;
    }
};

export default orderReducer;
