import React from 'react';

export default () => {
    const reviews = [
        {
            rating: 5,
            text: '“Mentum is the best technology provider in terms of experience, support and ease of setup for our 50+ QSR locations.',
            user_name: 'George Kottas',
            user_position: 'CEO @ Ghost Kitchen Brands',
            image: '/assets/landing/v3/reviews/george.png',
        },
        {
            rating: 5,
            text: '“Adding Mentum’s QR ordering to our dine-in has significantly improved speed of operations and experience for our guests.',
            user_name: 'Zoran Kocovski',
            user_position: 'Principal @ Sassafraz Restaurant',
            image: '/assets/landing/v3/reviews/zoran.png',
        },
        {
            rating: 5,
            text: '“My team and I researched dozens of competitors and Mentum was the most advanced and flexible product available.',
            user_name: 'Umesh Srivastava',
            user_position: 'GM @ Doubletree by Hilton',
            image: '/assets/landing/v3/reviews/umesh.png',
        },
    ];

    return (
        <div className="v3ReviewManyContainer">
            {reviews.map((review) => {
                return (
                    <div className="v3ReviewContainer">
                        <div className="v3RatingStars">
                            <img
                                src="/assets/landing/v3/star.png"
                                className="v3ReviewStar"
                            />
                            <img
                                src="/assets/landing/v3/star.png"
                                className="v3ReviewStar"
                            />
                            <img
                                src="/assets/landing/v3/star.png"
                                className="v3ReviewStar"
                            />
                            <img
                                src="/assets/landing/v3/star.png"
                                className="v3ReviewStar"
                            />
                            <img
                                src="/assets/landing/v3/star.png"
                                className="v3ReviewStar"
                            />
                        </div>

                        <div className="v3ReviewText">{review.text}</div>

                        <div className="v3ReviewPerson">
                            <img
                                src={review.image}
                                className="v3ReviewPersonImage"
                            />
                            <div className="v3ReviewPersonDescription">
                                <div className="v3ReviewName">
                                    {review.user_name}
                                </div>
                                <div className="v3ReviewPosition">
                                    {review.user_position}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
