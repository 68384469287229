import * as OrderActions from '../../actions/orderActions';
import { cloudfrontBaseUrl } from '../../endpoints';
import { i18n } from '../../locales';

export const createPath = (path, variables) => {
    let newPath = path;
    variables.forEach((value) => {
        if (!newPath.includes(':')) {
            throw new Error('createPath has too many variables!');
        }
        newPath = newPath.replace(':', value);
    });

    if (newPath.includes(':')) {
        throw new Error('createPath has is missing variables!');
    }
    return newPath;
};

export const findMenu = (menus, menuID) => {
    for (const menu of menus) {
        if (menu.menu_id === menuID) {
            return menu;
        }
    }
};

export const findCategory = (categories, categoryID) => {
    for (const category of categories) {
        if (category.category.category_id === categoryID) {
            return category;
        }
    }
};

export function isSelectedModifier(selectedModifiers, modifier) {
    let flag = false;
    selectedModifiers.forEach((selMod) => {
        if (selMod && selMod.modifier_id === modifier.modifier_id) {
            flag = true;
        }
    });
    return flag;
}

export function getInstruction(minqty, maxqty) {
    let instruction;
    if (minqty === maxqty) {
        instruction = `${i18n.t('common.select')} ${minqty}`;
    } else if (minqty === 0 && maxqty === 1000) {
        instruction = i18n.t('common.selectAny');
    } else if (minqty !== 0 && maxqty === 1000) {
        instruction = `${i18n.t('common.selectAtLeast')} ${minqty}`;
    } else if (minqty === 0 && maxqty < 1000) {
        instruction = `${i18n.t('common.selectUpTo')} ${maxqty}`;
    } else {
        instruction = `Select between ${minqty} and ${maxqty}`;
        instruction = i18n.t('common.selectBetween', {
            min: minqty,
            max: maxqty,
        });
    }
    return instruction;
}

export function sendUserBack(history, previousPath, urlParams) {
    if (previousPath && previousPath.pathname.substr(0, 3) === '/v2') {
        history.goBack();
    } else {
        let path = createPath(`/v2/${urlParams.endpoint}/order`, []);
        let location = {
            pathname: path,
        };
        //history.pop();
        history.replace(location);
    }
}

export function resetItemState(dispatch, isEditMode) {
    dispatch(OrderActions.addModifiersToItem([]));
    dispatch(OrderActions.setItemQuantity(1));
    dispatch(OrderActions.menuItemSpecialInstructionsChanged(''));
    dispatch(OrderActions.setSelectedItem(null));
    if (isEditMode) dispatch(OrderActions.disableItemEditMode());
}

export function shouldDisplayFab(modifierGroups, modifiersMap) {
    let flag = true;
    modifierGroups.forEach((modGroup) => {
        if (modGroup.item_modifier_group.min_qty > 0) {
            const modGroupId =
                modGroup.item_modifier_group.modifier_group.modifier_group_id;
            const currentSelected = modifiersMap.get(modGroupId)?.length || 0;
            if (
                !modifiersMap.has(modGroupId) ||
                currentSelected < modGroup.item_modifier_group.min_qty ||
                currentSelected > modGroup.item_modifier_group.max_qty
            ) {
                flag = false;
            }
        }
    });
    return flag;
}

export function checkPreviousPath(prevPath, urlParams) {
    let modifierSelect = `/v2/${urlParams.endpoint}/m/${urlParams.menu_id}/c/${urlParams.category_id}/i/${urlParams.item_id}/mg`;
    let specialInstructions = `/v2/${urlParams.endpoint}/m/${urlParams.menu_id}/c/${urlParams.category_id}/i/${urlParams.item_id}/si`;
    let curItem = `/v2/${urlParams.endpoint}/m/${urlParams.menu_id}/c/${urlParams.category_id}/i/${urlParams.item_id}`;
    if (
        prevPath.includes(modifierSelect) ||
        prevPath.includes(specialInstructions) ||
        prevPath.includes(curItem)
    ) {
        return true;
    } else {
        return false;
    }
}

export function getImage(restaurantId, itemId) {
    const urlCandidate = `${cloudfrontBaseUrl}${restaurantId}/items/${itemId}.jpeg`;
    return urlCandidate;
}

export function findModifierGroup(menu, keys) {
    let modifierGroup;
    menu.menus.forEach((menu) => {
        if (menu.menu_id === keys.menu_id) {
            menu.categories.forEach((category) => {
                if (category.category.category_id === keys.category_id) {
                    category.items.forEach((item) => {
                        if (item.item.item_id === keys.item_id) {
                            item.item_modifier_groups.forEach((modGroup) => {
                                if (
                                    modGroup.item_modifier_group.modifier_group
                                        .modifier_group_id ===
                                    keys.modifier_group_id
                                ) {
                                    modifierGroup =
                                        modGroup.item_modifier_group;
                                }
                            });
                        }
                    });
                }
            });
        }
    });
    return modifierGroup;
}

export function getModifiers(menu, modifier_group_id) {
    let modifiers = [];
    menu.modifiers.forEach((menu_modifier) => {
        if (menu_modifier.modifier_group_id === modifier_group_id) {
            modifiers.push(menu_modifier);
        }
    });
    modifiers = modifiers.sort((a, b) => (a.rank < b.rank ? 1 : -1));
    return modifiers;
}

export function findItem(menu, keys) {
    let returnItem;
    menu.menus.forEach((menu) => {
        if (menu.menu_id === keys.menu_id) {
            menu.categories.forEach((category) => {
                if (category.category.category_id === keys.category_id) {
                    category.items.forEach((item) => {
                        if (item.item.item_id === keys.item_id) {
                            returnItem = item;
                        }
                    });
                }
            });
        }
    });
    return returnItem;
}

export function parseUrl(url) {
    let slashCounter = 0;
    let retUrl = '';
    for (let i = 0; i < url.length; i++) {
        if (url[i] === '/') {
            slashCounter++;
        }
        if (slashCounter === 3) {
            retUrl = url.substr(i);
            break;
        }
    }
    return retUrl;
}

export function closeItem(thisOrder, history, urlParams) {
    if (thisOrder.page_before_item) {
        if (thisOrder.page_before_item.search) {
            history.goBack();
        } else {
            history.goBack();
        }
        let path = thisOrder.page_before_item.search
            ? thisOrder.page_before_item.pathname +
              thisOrder.page_before_item.search
            : thisOrder.page_before_item.pathname;
        let location = {
            pathname: path,
        };
        history.replace(location);
    } else {
        let path = createPath(`/v2/${urlParams.endpoint}/order`, []);
        let location = {
            pathname: path,
        };
        history.replace(location);
    }
}

export function getModifierGroups(item) {
    let requiredGroups = [];
    let optionalGroups = [];

    //get all modifier groups that have modifiers
    item.item_modifier_groups.forEach((modGroup) => {
        if (modGroup.modifiers && modGroup.modifiers.length) {
            if (modGroup.item_modifier_group.min_qty === 0) {
                optionalGroups.push(modGroup);
            } else {
                requiredGroups.push(modGroup);
            }
        }
    });

    requiredGroups.sort((a, b) =>
        a.item_modifier_group.rank > b.item_modifier_group.rank ? 1 : -1
    );
    optionalGroups.sort((a, b) =>
        a.item_modifier_group.rank > b.item_modifier_group.rank ? 1 : -1
    );
    return requiredGroups.concat(optionalGroups);
}

export function initializeModifiersMap(
    modifiersMap,
    modifierGroups,
    selectedModifiers
) {
    modifierGroups.forEach((modGroup) => {
        let modifierGroupID =
            modGroup.item_modifier_group.modifier_group.modifier_group_id;
        let selectedModifiersInThisGroup = [];
        selectedModifiers.forEach((selectedModifier) => {
            if (
                selectedModifier &&
                selectedModifier.modifier_group_id === modifierGroupID
            ) {
                selectedModifiersInThisGroup.push(selectedModifier);
            }
        });
        if (selectedModifiersInThisGroup.length) {
            modifiersMap.set(modifierGroupID, selectedModifiersInThisGroup);
        }
    });
}

export function setSelectedItemEditMode(selectedItem, dispatch) {
    dispatch(
        OrderActions.menuItemSpecialInstructionsChanged(
            selectedItem.special_instructions
        )
    );
    dispatch(OrderActions.addModifiersToItem(selectedItem.modifiers));
    dispatch(OrderActions.setItemQuantity(selectedItem.multiplier));
    dispatch(OrderActions.setSelectedItem(selectedItem));
}

export function getModifierGroupTag(modifiersMap, modifierGroup) {
    let status = '';
    if (modifiersMap.has(modifierGroup.modifier_group.modifier_group_id)) {
        let selectedMods = modifiersMap.get(
            modifierGroup.modifier_group.modifier_group_id
        );
        selectedMods.forEach((selMod, ind) => {
            ind === 0
                ? (status += `${selMod.name}`)
                : (status += `, ${selMod.name}`);
        });
    } else {
        if (modifierGroup.min_qty === 0) {
            status = i18n.t('common.optional');
        } else {
            status = i18n.t('common.required');
        }
    }
    return status;
}

export function getModifierMultiplier(selectedModifiers, modifier) {
    let num = 0;
    selectedModifiers.forEach((selectedModifier) => {
        if (selectedModifier.modifier_id === modifier.modifier_id) {
            num++;
        }
    });
    return num;
}

export function numUniqueModifiersSelected(selectedModifiers) {
    let foundModifiers = [];
    selectedModifiers.forEach((selectedModifier) => {
        if (!foundModifiers.includes(selectedModifier)) {
            foundModifiers.push(selectedModifier);
        }
    });
    return foundModifiers.length;
}
