import React from 'react';
import { connect } from 'react-redux';
import * as Types from '../../../reducers/TYPES';
import stripeTerminal from '../../../StripeTerminal';
class PaymentReader extends React.Component {
    render() {
        // check for terminal payment status
        const terminal_payment_status =
            this.props.state.kiosk.terminal_payment_status;

        if (terminal_payment_status === Types.TerminalPaymentStatus.COMPLETE) {
            this.props.history.push('/kiosk/paymentComplete');
        }

        if (terminal_payment_status === Types.TerminalPaymentStatus.FAILED) {
            this.props.history.push('/kiosk/cart');
        }

        const cancelPayment = () => {
            const terminalVendor = this.props.state.kiosk.terminal_vendor;
            switch (terminalVendor) {
                case Types.TerminalVendors.STRIPE:
                    stripeTerminal.cancelPayment();
                    break;
                case Types.TerminalVendors.NUVEI:
                    // get cancel token
                    const source =
                        this.props.state.kiosk.nuvei_cancellation_token;
                    if (source) {
                        source.cancel('Cancelled by user');
                    }
                    break;
                default:
                    break;
            }

            this.props.history.push('/kiosk/cart');
        };

        const renderCancelButton = () => {
            if (
                this.props.state.kiosk.terminal_vendor ===
                Types.TerminalVendors.NUVEI
            ) {
                return '';
            }

            return (
                <div>
                    <div
                        className="to-page-payment-back-button"
                        onTouchEnd={() => cancelPayment()}
                        onMouseUp={() => cancelPayment()}
                    >
                        Cancel
                    </div>
                </div>
            );
        };

        return (
            <div className="to-page-container to-page-container-dark">
                <div className="to-page-inner-container to-page-inner-container-dark">
                    <img
                        alt="Ghost Kitchens Logo"
                        src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                        className="to-page-review-logo"
                    ></img>
                    <div className="to-page-review-heading">
                        Please tap or insert your card.
                    </div>
                </div>

                {renderCancelButton()}
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {})(PaymentReader);
