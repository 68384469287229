import React, { Fragment } from 'react';
import { Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MiniCart from '../Components/MiniCart';
import MenuCategory from '../Components/MenuCategory';
import { findMenu } from '../utils';
import LoadingScreen from '../LoadingScreen';
import TopHeader from '../Components/TopHeader';
import DineInFab from '../Components/DineInFab';
import MenuHours from '../Components/MenuHours';

export default () => {
    const menus = useSelector((state) => state.order.menu.menus);
    const params = useParams();
    const curMenu = findMenu(menus, params.menu_id);
    const description = curMenu && curMenu.description;

    if (!curMenu || !curMenu.categories) {
        return <LoadingScreen />;
    }
    return (
        <>
            <TopHeader title={curMenu.name} backButton />
            <MenuHours selectedMenu={curMenu} />
            {description ? (
                <div className="singleCategoryMenuDescription">
                    {description}
                </div>
            ) : (
                <></>
            )}
            <div id="content" style={{ flex: '1' }}>
                {curMenu.categories.map((category) => {
                    return (
                        <Fragment key={category.category.category_id}>
                            <MenuCategory menu={curMenu} category={category} />
                            <Divider />
                        </Fragment>
                    );
                })}
            </div>
            <DineInFab />
            <MiniCart />
        </>
    );
};
