import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createPath, findCategory, findMenu } from '../utils';
import RoundComponent from '../Components/RoundComponent';
import MiniCart from '../Components/MiniCart';
import TopHeader from '../Components/TopHeader';
import LoadingScreen from '../LoadingScreen';
import './css/SingleCategoryPage.css';
import DineInFab from '../Components/DineInFab';
import { isTakeout } from '../../../utils';
import { toast } from 'react-toastify';
import MenuHours from '../Components/MenuHours';
import { cloudfrontBaseUrl } from '../../../endpoints';

export default (props) => {
    const params = useParams();
    const history = useHistory();
    let categoryID = params.category_id;
    let menuID = params.menu_id;
    let restaurantID = useSelector(
        (state) => state.main.restaurant_info.restaurant_id
    );
    let menus = useSelector((state) => state.order.menu.menus);
    let curMenu = findMenu(menus, menuID);

    if (!curMenu || !curMenu.categories) {
        return <LoadingScreen />;
    }

    let curCategory = findCategory(curMenu.categories, categoryID);

    const { endpoint } = params;

    return (
        <>
            <TopHeader
                previousPath={props.previousPath}
                title={curCategory.category.name}
                backButton
            />
            <div style={{ flex: '1' }}>
                <div className="singleCategoryContainer">
                    {curCategory.items.map((item, ind) => {
                        let itemID = item.item.item_id;
                        let imageHref = `${cloudfrontBaseUrl}${restaurantID}/items/${itemID}.jpeg`;
                        let itemName = item.item.name;
                        return (
                            <div data-cy="item" key={itemID}>
                                <RoundComponent
                                    onPress={() => {
                                        history.push(
                                            createPath('/v2/:/m/:/c/:/i/:', [
                                                endpoint,
                                                curMenu.menu_id,
                                                curCategory.category
                                                    .category_id,
                                                itemID,
                                            ])
                                        );
                                    }}
                                    image={imageHref}
                                    label={itemName}
                                    medium
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            <DineInFab />
            <MiniCart />
        </>
    );
};
