import { AppBar, TextField, Toolbar, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import Fuse from 'fuse.js';
import RoundComponent from './Components/RoundComponent';
import { createPath } from './utils';
import MiniCart from './Components/MiniCart';
import './css/SearchPage.css';
import LoadingScreen from './LoadingScreen';
import { FiSearch } from 'react-icons/fi';
import { useEffect } from 'react';
import BackButton from './Components/BackButton';
import { useTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../endpoints';

let masterArray, fuse;

export default (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const menus = useSelector((state) => state.order.menu.menus);
    const params = useParams();
    let queries = new URLSearchParams(document.location.search.substring(1));
    let searchQuery = queries.get('q');
    let endpoint = params.endpoint;
    let restaurantID = useSelector(
        (state) => state.main.restaurant_info.restaurant_id
    );
    const { t } = useTranslation();
    const history = useHistory();

    if (!menus) {
        return <LoadingScreen />;
    }

    console.log({ searchResults });

    // one time initialization of master array and Fuse.js search obj
    if (!masterArray || masterArray.length === 0) {
        initSearch(menus);
    }

    const runSearch = (query) => {
        setSearchResults(
            search(query).sort((a, b) => (a.item.type === 'menu' ? -1 : 1))
        );
    };

    // check if we already have a q param
    if (searchQuery && searchQuery !== searchTerm) {
        setSearchTerm(searchQuery);
        runSearch(searchQuery);
    }

    useEffect(() => {
        runSearch(searchQuery || '');
        /* eslint-disable */
    }, []);

    return (
        <>
            <AppBar
                position="sticky"
                style={{ background: 'white', color: 'black' }}
            >
                <Toolbar>
                    <div className="toolBarContainer">
                        <div className="iconContainer">
                            <BackButton previousPath={props.previousPath} />
                        </div>
                        <div className="toolBarSearchTextFieldContainer">
                            <TextField
                                autoFocus
                                defaultValue={searchQuery}
                                onChange={(e) => {
                                    let newSearchTerm = e.target.value;
                                    history.replace({
                                        search: '?q=' + newSearchTerm,
                                    });
                                    setSearchTerm(newSearchTerm);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        runSearch(searchTerm);
                                    }
                                }}
                                style={{ width: '100%', marginLeft: '10px' }}
                                placeholder=""
                                data-cy="searchField"
                            />
                        </div>

                        <div className="iconContainer">
                            <IconButton
                                onClick={() => {
                                    runSearch(searchTerm);
                                }}
                                data-cy="searchButton"
                            >
                                <FiSearch
                                    color="#121212"
                                    style={{ marginLeft: '5px' }}
                                />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>

            <div style={{ flex: '1' }}>
                {searchQuery && !searchResults.length && (
                    <p
                        style={{
                            textAlign: 'center',
                            width: '100%',
                            padding: '10px 0 ',
                        }}
                    >
                        {t('searchPage.noSearchResults', {
                            query: searchQuery,
                        })}
                    </p>
                )}
                <div className="searchResultContainer">
                    {searchResults.map((result) => {
                        let label, image, id, pathResult;

                        if (result.item.type === 'item') {
                            label = result.item.itemName;
                            id = result.item.itemID;
                            pathResult = createPath('/v2/:/m/:/c/:/i/:', [
                                endpoint,
                                result.item.menuID,
                                result.item.categoryID,
                                id,
                            ]);
                            image = `${cloudfrontBaseUrl}${restaurantID}/items/${id}.jpeg`;
                        } else {
                            label = result.item.menuName;
                            id = result.item.menuID;
                            pathResult = createPath('/v2/:/m/:', [
                                endpoint,
                                id,
                            ]);
                            image = `${cloudfrontBaseUrl}${restaurantID}/menus/${id}.jpeg`;
                        }

                        return (
                            <RoundComponent
                                image={image}
                                label={label}
                                medium
                                key={id}
                                onPress={() => {
                                    history.push(pathResult);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <MiniCart />
        </>
    );
};

function search(searchTerm) {
    return fuse.search(searchTerm);
}

function initSearch(menus) {
    masterArray = constructMasterArray(menus);
    fuse = new Fuse(masterArray, {
        threshold: 0.3,
        keys: ['keywords'],
        findAllMatches: true,
    });
}

function constructMasterArray(menus) {
    // we want to be able to search for menus and items that fuzzy match
    // as such, we'll have 1 array that contains every menu and every item, so kind of a flattened structure
    // pushedIds keep track of ids that are already in the masterArray, so we dont end up displaying duplicate items
    let result = [],
        pushedIds = [];

    // add all menus
    menus.forEach((menu) => {
        // check menu has categories and is active
        const validMenu = menu.categories && menu.is_active;
        if (validMenu) {
            result.push({
                type: 'menu',
                menuName: menu.name,
                keywords: `${menu.name}`,
                menuID: menu.menu_id,
            });
            pushedIds.push(menu.menu_id);

            menu.categories.forEach((category) => {
                category.items.forEach((item) => {
                    let name = item.item.name,
                        id = item.item.item_id;
                    const keywords = `${menu.name} ${name} ${id} ${
                        item.description || ''
                    }`;

                    let push = {
                        type: 'item',
                        keywords,
                        itemName: name,
                        itemID: id,
                        categoryID: category.category.category_id,
                        menuID: menu.menu_id,
                    };
                    if (!pushedIds.includes(id)) {
                        result.push(push);
                        pushedIds.push(id);
                    }
                });
            });
        }
    });

    return result;
}
