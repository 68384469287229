const theme = {
    paymentCompleteThankYou: {
        fontFamily: 'Cormorant Garamond',
        fontWeight: '700',
    },
    guestListLogoContiner: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '10px',
        marginBottom: '30px',
    },

    guestListImage: '/assets/photos/ajisaibanner.jpg',

    guestListImageStyle: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        display: 'none',
    },

    guestListSubtitleContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '30px',
        marginRight: '30px',

        textAlign: 'center',

        fontFamily: 'Cormorant Garamond',
        fontStyle: 'italic',
        fontSize: '18px',
        fontWeight: '600',
        color: '#606060',

        marginBottom: '30px',
    },

    guestListGreeting: {
        display: 'none',
    },

    buttonFont: {
        fontFamily: 'Cormorant Garamond',
        fontWeight: '600',
        fontSize: '22px',
        color: '#373737',
    },

    pageTitleFont: {
        color: '#373737',
        fontWeight: '700',
    },
    pageDescriptionFont: {
        color: '#373737',
        fontStyle: 'normal',
    },

    addGuestTextBox: {
        color: '#373737',
        fontFamily: 'Roboto',
        fontWeight: '400',
    },

    // FLOATING BUTTON

    floatingButton: {
        backgroundColor: '#121212',
        fontWeight: '600',
    },
    floatingButtonLabel: {
        fontFamily: 'Cormorant Garamond',
        fontWeight: '700',
        fontSize: '20px',
    },

    // HEADER
    headerGuestNameLabel: {
        color: '#373737',
        fontFamily: 'Cormorant Garamond',
    },
    headerBackButtonText: {
        fontFamily: 'Cormorant Garamond',
        fontWeight: '600',
        color: '#333',
    },
    headerGuestIcon: {},

    // GUEST HOME OPTIONAL LOGO
    optionalHomeHeaderUrl: '/assets/photos/roosterstore.jpg',
    optionalHomeHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    optionalHomeHeaderImg: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
    },
    optionalHomeWelcomeContainer: {
        marginTop: '20px',
        marginBottom: '30px',
    },

    // Homepage Greeting
    welcomeGreeting: {
        fontFamily: 'Cormorant Garamond',
        fontStyle: 'normal',
        fontWeight: '600',
        color: '#373737',
    },
    welcomeName: {
        fontFamily: 'Cormorant Garamond',
        fontWeight: '600',
        color: '#373737',
    },

    // OPTIONAL GUEST HOME FOOTER
    optionalHomeFooter: {
        paddingTop: '50px',
        paddingBottom: '20px',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        color: '#B09F9F',
        fontFamily: 'Cormorant Garamond',
        fontSize: '16px',
        fontStyle: 'italic',
    },
    optionalHomeFooterText: 'hello@roostercoffeehouse.com',

    itemMultiplierContainer: {
        borderColor: '#121212',
    },
    itemMultiplierLeft: {
        borderRightColor: '#121212',
        color: '#121212',
    },
    itemMultiplierRight: {
        borderLeftColor: '#121212',
        color: '#121212',
    },
    itemMultiplierText: {
        color: '#121212',
        fontFamily: 'Cormorant Garamond',
    },
    applePayContainer: {
        position: 'absolute',
        bottom: '20px',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    tipLabel: {
        color: '#333',
    },
    tipButton: {
        borderColor: '#121212',
        color: '#121212',
    },
    tipButtonActive: {
        backgroundColor: '#121212',
        color: '#fff',
    },

    // Generic titles and descriptions
    orderTitle: {
        color: '#333',
        fontFamily: 'Cormorant Garamond',
        fontWeight: '600',
        fontSize: '36px',
    },
    orderDescription: {
        fontFamily: 'Cormorant Garamond',
        color: '#333',
    },

    restaurantMenuTitle: {
        fontFamily: 'Cormorant Garamond',
        color: '#333',
        fontWeight: '600',
    },
    restaurantCategoryLabel: {
        fontFamily: 'Cormorant Garamond',
        color: '#373737',
        fontSize: '24px',
    },

    menuCategoryTitle: {
        fontFamily: 'Cormorant Garamond',
        color: '#373737',
        fontWeight: '600',
    },

    menuItemLabel: {
        fontFamily: 'Cormorant Garamond',
        color: '#373737',
        fontWeight: '600',
    },
    itemPrice: {
        fontFamily: 'Roboto',
        color: '#373737',
        fontWeight: '500',
    },

    itemDetailTitle: {
        fontFamily: 'Cormorant Garamond',
        fontWeight: '600',
        color: '#373737',
        fontSize: '28px',
    },
    itemDetailPrice: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        color: '#373737',
    },
    itemDetailDescription: {
        fontFamily: 'Cormorant Garamond',
        fontSize: '22px',
        color: '#373737',
    },
    finalizeAddItemsButton: {
        borderColor: '#121212',
        color: '#121212',
        fontFamily: 'Cormorant Garamond',
    },
    finalizeItemLabel: {
        fontFamily: 'Cormorant Garamond',
        color: '#272727',
        fontSize: '20px',
    },
    modifierGroupName: {
        fontFamily: 'Cormorant Garamond',
        fontSize: '20px',
        fontWeight: '600',
        color: '#272727',
    },
    itemModifierLabel: {
        fontFamily: 'Cormorant Garamond',
        fontSize: '20px',
        fontWeight: '600',
        color: '#272727',
    },
    finalizeNoOrdersPrompt: {
        fontFamily: 'Cormorant Garamond',
    },
};

export default theme;
