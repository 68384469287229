import React from 'react';
import { connect } from 'react-redux';
import './css/Upsell.css';
import * as OrderActions from '../../../actions/orderActions';
import { getImageUrl } from '../../../utils';
import { cloudfrontBaseUrl } from '../../../endpoints';
class Upsell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carousel_index: 0,
            rendered_groups: [],
        };
    }

    renderItemGroups() {
        let { items } = this.props;

        // filter out items that are in the cart already
        const cart = this.props.state.order.temporary_order;
        const cart_item_ids = cart.map((i) => i.item_id);

        // remove any items that are already in cart
        let filtered_items = items.filter(
            (i) => !cart_item_ids.includes(i.item_id)
        );

        const renderDisplayItem = (item) => {
            const brand_logo_url = getImageUrl('menu', item.menu_id);
            const restaurant_id =
                this.props.state.main.restaurant_info.restaurant_id;
            const item_img_url = `${cloudfrontBaseUrl}${restaurant_id}/items/${item.item_id}.jpeg`;

            return (
                <div
                    className="kiosk-upsell-individual-item"
                    key={item.item_id}
                >
                    <div className="kiosk-upsell-ii-inner-container">
                        <img
                            alt=""
                            className="kiosk-upsell-ii-brand-logo"
                            src={brand_logo_url}
                        ></img>
                        <img
                            alt=""
                            className="kiosk-upsell-ii-image"
                            src={item_img_url}
                            onClick={() => this.selectItem(item)}
                        ></img>
                        <div
                            className="kiosk-upsell-ii-name"
                            onClick={() => this.selectItem(item)}
                        >
                            {item.name}
                        </div>
                        <div
                            className="kiosk-upsell-ii-price"
                            onClick={() => this.selectItem(item)}
                        >
                            {item.price}
                        </div>
                        <div
                            className="kiosk-upsell-ii-button"
                            onClick={() => this.selectItem(item)}
                        >
                            Choose
                        </div>
                        <div
                            className="kiosk-upsell-ii-button kiosk-upsell-ii-button-dark"
                            onClick={() => this.selectMenu(item)}
                        >
                            Browse
                        </div>
                    </div>
                </div>
            );
        };

        const group_size = 3;
        const whole_groups = Math.floor(filtered_items.length / group_size);
        const remainder = filtered_items.length % group_size;
        const total_slides = whole_groups + (remainder > 0) ? 1 : 0;

        const renderItemGroups = () => {
            let renderedGroups = [];
            for (
                let group_index = 0;
                group_index < whole_groups;
                group_index++
            ) {
                const current_group = filtered_items.slice(
                    group_index * group_size,
                    (group_index + 1) * group_size
                );
                renderedGroups.push(
                    <div className="kiosk-upsell-items">
                        {current_group.map((item) => renderDisplayItem(item))}
                    </div>
                );
            }
            for (
                let remainder_index = 0;
                remainder_index < remainder;
                remainder_index++
            ) {
                const group = filtered_items.slice(
                    whole_groups * group_size,
                    filtered_items.length
                );
                renderedGroups.push(
                    <div className="kiosk-upsell-items">
                        {group.map((item) => renderDisplayItem(item))}
                    </div>
                );
            }
            return renderedGroups;
        };

        this.setState({
            rendered_groups: renderItemGroups(),
            total_slides,
        });
    }

    componentDidMount() {
        // items: Array of menu items to display to the user
        const { items } = this.props;
        if (!items) {
            this.props.history.push('/kiosk/item');
        }

        // render groups
        this.renderItemGroups();
    }

    selectItem(item) {
        // Select item menu
        const menus = this.props.state.order.menu.menus;
        const menu = menus.filter((m) => m.menu_id === item.menu_id)[0];
        if (!menu) {
            this.props.onNoThanks();
            return;
        }
        this.props.menuSelected(menu, null, false);

        // select item category
        if (Array.isArray(menu.categories) && menu.categories.length > 0) {
            const categories = menu.categories;
            let category_index = 0;
            categories.forEach((cat, cat_index) => {
                if (cat.category.category_id === item.category_id) {
                    category_index = cat_index;
                }
            });

            this.props.kioskCategorySelected(menu.categories[category_index]);
        }

        // Select item and navigate to item page
        this.props.menuItemSelected(item, this.props.history, false);
        this.props.history.push('/kiosk/item');
    }

    selectMenu(item) {
        const menu_id = item.menu_id;

        // look for menu object
        const menus = this.props.state.order.menu.menus;
        const menu = menus.filter((m) => m.menu_id === menu_id)[0];
        if (!menu) {
            this.props.onNoThanks();
            return;
        }

        this.props.menuSelected(menu, null, false);
        if (Array.isArray(menu.categories) && menu.categories.length > 0) {
            const categories = menu.categories;
            let category_index = 0;
            categories.forEach((cat, cat_index) => {
                if (cat.category.category_id === item.category_id) {
                    category_index = cat_index;
                }
            });
            this.props.kioskCategorySelected(menu.categories[category_index]);

            this.props.history.push('/kiosk');
            this.props.onClose();

            const { scrollToMenu } = this.props;
            if (scrollToMenu) {
                setTimeout(() => {
                    if (typeof scrollToMenu !== 'undefined') {
                        scrollToMenu();
                    }
                }, 100);
            }
        }
    }

    nextSlide() {
        const total_slides = this.state.rendered_groups.length;

        // check if on last slide
        if (this.state.carousel_index === total_slides - 1) {
            this.setState({
                carousel_index: 0,
            });
        } else {
            this.setState({
                carousel_index: this.state.carousel_index + 1,
            });
        }
    }

    prevSlide() {
        const total_slides = this.state.rendered_groups.length;

        // check if on first slide
        if (this.state.carousel_index === 0) {
            this.setState({
                carousel_index: total_slides - 1,
            });
        } else {
            this.setState({
                carousel_index: this.state.carousel_index - 1,
            });
        }
    }

    render() {
        const { onNoThanks } = this.props;

        const renderNoThanks = (
            <div
                className="kiosk-upsell-no-thanks-container"
                onClick={() => onNoThanks()}
            >
                <span className="kiosk-upsell-no-thanks">
                    No, thanks! Checkout
                </span>
            </div>
        );

        const groups = this.state.rendered_groups;
        const items_to_display = groups[this.state.carousel_index];

        const show_left_chevron = this.state.carousel_index !== 0;
        const show_right_chevron =
            this.state.carousel_index < groups.length - 1;

        const itemCarousel = (
            <div className="kiosk-upsell-carousel">
                {show_left_chevron ? (
                    <div
                        onClick={() => this.prevSlide()}
                        className="kiosk-upsell-chevron"
                    >
                        <img
                            alt=""
                            src="/assets/icons/upsell-chevron.svg"
                        ></img>
                    </div>
                ) : (
                    <div className="kiosk-upsell-chevron upsell-chevron-grayed">
                        <img
                            alt=""
                            src="/assets/icons/upsell-chevron.svg"
                        ></img>
                    </div>
                )}

                {items_to_display}

                {show_right_chevron ? (
                    <div
                        onClick={() => this.nextSlide()}
                        className="kiosk-upsell-chevron upsell-chevron-flipped"
                    >
                        <img
                            alt=""
                            src="/assets/icons/upsell-chevron.svg"
                        ></img>
                    </div>
                ) : (
                    <div className="kiosk-upsell-chevron upsell-chevron-flipped upsell-chevron-grayed">
                        <img
                            alt=""
                            src="/assets/icons/upsell-chevron.svg"
                        ></img>
                    </div>
                )}
            </div>
        );

        return (
            <>
                <div className="kiosk-upsell-container">
                    <div className="kiosk-upsell-modal">
                        <div className="kiosk-upsell-inner-container">
                            <div className="kiosk-upsell-modal-header">
                                Try these with your meal!
                            </div>
                            {itemCarousel}
                            {renderNoThanks}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    menuItemSelected: OrderActions.menuItemSelected,
    menuSelected: OrderActions.menuSelected,
    kioskCategorySelected: OrderActions.kioskCategorySelected,
})(Upsell);
