const hostname = window && window.location && window.location.hostname;

function setBackendConfig(hostname) {
    let backendHost, analyticsEndpoint, env, cloudfrontBaseUrl;
    let baseURL = 'http://127.0.0.1:4003';
    const devCloudfrontBaseUrl =
        'https://mentum-restaurants-dev.s3.amazonaws.com/';
    const prodCloudfrontBaseUrl = 'https://d2616l3y4p7rgg.cloudfront.net/';

    switch (true) {
        case hostname.includes('dev.mentumqr.com'):
            backendHost = 'https://dev.mentumqr.com/api/';
            analyticsEndpoint = 'https://dev.mentumqr.com/analytics/api/';
            baseURL = 'https://dev.mentumqr.com';
            env = 'development';
            cloudfrontBaseUrl = devCloudfrontBaseUrl;
            break;
        case hostname.includes('localhost'):
            backendHost = 'http://localhost:8081/';
            analyticsEndpoint = 'http://localhost:8081/api/';
            baseURL = 'http://localhost:8081';
            env = 'development';
            cloudfrontBaseUrl = devCloudfrontBaseUrl;
            break;
        case hostname.includes('mentumqr.com'):
            backendHost = 'https://mentumqr.com/api/';
            analyticsEndpoint = 'https://mentumqr.com/analytics/api/';
            baseURL = 'https://mentumqr.com';
            env = 'production';
            cloudfrontBaseUrl = prodCloudfrontBaseUrl;
            break;
        case hostname.includes('30036'):
            // TESTING
            backendHost = `http://localhost:30036/api/`;
            analyticsEndpoint = `http://localhost:30036/analytics/api/`;
            env = 'ci';
            break;
        case hostname.includes('cidemo.co'):
            backendHost = `https://${hostname}/api/`;
            analyticsEndpoint = `https://${hostname}/analytics/api/`;
            baseURL = 'https://dev.mentumqr.com';
            env = 'ci';
            break;
        case hostname.includes('staging'):
            backendHost = `https://${hostname}/api/`;
            analyticsEndpoint = `https://${hostname}/analytics/api/`;
            baseURL = 'https://dev.mentumqr.com';
            env = 'staging';
            cloudfrontBaseUrl = devCloudfrontBaseUrl;
            break;
        default:
            backendHost = 'https://dev.mentumqr.com/api/';
            analyticsEndpoint = 'https://dev.mentumqr.com/analytics/api/';
            env = 'development';
            cloudfrontBaseUrl = devCloudfrontBaseUrl;
    }

    return { backendHost, analyticsEndpoint, env, baseURL, cloudfrontBaseUrl };
}

const config = setBackendConfig(hostname);
const baseUrl = process.env.REACT_APP_BASE_URL || config.baseURL;

export const APIv1Endpoint = config.backendHost;
export const APIv1AnalyticsEndpoint = config.analyticsEndpoint;
export const ENV = config.env;
export const BASE_URL = baseUrl;
export const cloudfrontBaseUrl = config.cloudfrontBaseUrl;

console.log('environment', ENV);
