import { createTheming } from '@callstack/react-theme-provider';

// Themes
import Sassafraz from './sassafraz';
import Ajisai from './ajisai';
import Default from './default';
import GhostKitchens from './ghostkitchens';
import RoosterCoffee from './roostercoffee';
import LaGrotta from './lagrotta';
import Utsav from './utsav';
import Kamasutra from './kamasutra';
import Hilton from './hilton';
import Ethos from './ethos';
const Themes = {
    Sassafraz,
    Ajisai,
    GhostKitchens,
    Default,
    RoosterCoffee,
    LaGrotta,
    Utsav,
    Kamasutra,
    Hilton,
    Ethos,
};

// create theme object
let theme = createTheming(Themes.Default);
const { ThemeProvider, withTheme, useTheme } = theme;

const getTheme = (restaurant) => {
    const { restaurant_id, name } = restaurant;

    // Return ghost kitchen theme for any ghost kitchens
    if (name.search('Ghost Kitchen') !== -1) {
        return Themes.GhostKitchens;
    }

    // maps restaurant id to theme
    let themeMap = {
        'c1216aa5-8fc7-11ea-b79c-0aea01d90f1c': Themes.Sassafraz,
        'f716200c-9625-11ea-820f-7c67a2b110fc': Themes.Ajisai,
        '56ff23fd-a475-11ea-b0d7-7c67a2b110fc': Themes.RoosterCoffee,
        '148f29f6-a945-11ea-8a6a-069f49d95199': Themes.LaGrotta,
        '21677f98-b57b-11ea-acd6-069f49d95199': Themes.Sassafraz,
        '72d47f78-b5d4-11ea-acd6-069f49d95199': Themes.Utsav,
        'df8e2518-b650-11ea-ac00-069f49d95199': Themes.Kamasutra,
        '54e3ed8a-baea-11ea-b85e-02685a4295ea': Themes.Hilton,
        '2993daf5-c798-11ea-b545-02685a4295ea': Themes.Ethos,
    };

    return themeMap[restaurant_id];
};

export { ThemeProvider, useTheme, withTheme, Themes, getTheme };
