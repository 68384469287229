import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from './endpoints';

export default function HelmetMetaData(props) {
    let location = useLocation();
    let currentUrl = `${BASE_URL}` + location.pathname;
    let quote = props.quote !== undefined ? props.quote : '';
    let title = props.title !== undefined ? props.title : 'Mentum QR';
    let image =
        props.image !== undefined
            ? props.image
            : 'https://mentumqr.s3.amazonaws.com/misc/MentumIconNoRadius.png';
    let description =
        props.description !== undefined
            ? props.description
            : 'Mentum amalgamates several delivery platform orders, analytics, and menu editing to a single dashboard and provides restaurants their own white label zero commission digital ordering solutions through QR codes and Kiosks.';
    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
            />
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="csrf_token" content="" />
            <meta property="type" content="website" />
            <meta property="url" content={currentUrl} />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-TileImage"
                content="/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff" />
            <meta name="_token" content="" />
            <meta name="robots" content="noodp" />
            <meta property="title" content={title} />
            <meta property="quote" content={quote} />
            <meta name="description" content={description} />
            <meta property="image" content={image} />
            <meta property="og:locale" content="en_CA" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={quote} />
            <meta property="og:image" content={image} />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:site_name" content="Mentum QR" />
            <meta property="og:description" content={description} />
            <meta
                name="keywords"
                content="qr code, digital menu, restaurant safety, contactless dining, mentum, mentumqr, mentum restaurants, mentum restaurant, safe, covid, restaurants"
            ></meta>
        </Helmet>
    );
}
