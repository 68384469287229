import React from 'react';
import { connect } from 'react-redux';

// action creators
import * as OrderActions from '../actions/orderActions';

// Route css
import './css/GuestHome.css';

// Components
import Header from '../components/Header';
import Button from '../components/Button';

// theme
import { withTheme } from '../themes/theming';
import { isNam } from '../utils';
import { withTranslation } from 'react-i18next';

const INQUIRIES = {
    call: 'Call waiter to table',
    glass_water: 'Glass of water',
    water: 'Refil water',
    help: 'Help with app',
    get_bill: 'Asking for bill',
};

class CallWaiter extends React.Component {
    callWaiter(inquiry) {
        this.props.callWaiter(inquiry, this.props.history);
    }

    render() {
        const theme = this.props.theme;
        const payBillEnabled = this.props.main.restaurant_info.pay_bill_enabled;

        return (
            <div className="Container">
                <Header
                    history={this.props.history}
                    back=":goBack"
                    showGuestName
                />

                <div className="yourOrderContainer">
                    <div className="yourOrderTitle" style={theme.orderTitle}>
                        {this.props.t('waiter.callWaiter')}
                    </div>
                    <div
                        className="yourOrderDescription"
                        style={theme.orderDescription}
                    >
                        {this.props.t('waiter.useQuickActions')}
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div className="buttonGroupContainer">
                        <Button
                            text={this.props.t('waiter.callWaiter')}
                            action={() => this.callWaiter(INQUIRIES['call'])}
                            showArrow
                        />
                        <Button
                            text={this.props.t('waiter.askForGlassOfWater')}
                            action={() =>
                                this.callWaiter(INQUIRIES['glass_water'])
                            }
                            showArrow
                        />
                        <Button
                            text={this.props.t('waiter.helpWithApp')}
                            action={() => this.callWaiter(INQUIRIES['help'])}
                            showArrow
                        />
                        {!isNam() && payBillEnabled ? (
                            <Button
                                text={this.props.t('waiter.askForBill')}
                                action={() =>
                                    this.callWaiter(INQUIRIES['get_bill'])
                                }
                                showArrow
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                {/* <FinalizeOrderButton history={this.props.history}/> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        table: state.table,
        main: state.main,
    };
};

const actions = {
    callWaiter: OrderActions.callWaiter,
};

// Connect the component to Redux and wrap it with withTranslation and withTheme
export default withTranslation()(
    withTheme(connect(mapStateToProps, actions)(CallWaiter))
);
