import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Header from '../../components/Header';
import * as OrderActions from '../../actions/orderActions';
import { withTheme } from '../../themes/theming';
import { isEthos } from '../../utils';
import { i18n } from '../../locales';
import { withTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../endpoints';

const MAX_MODIFIERS = 1000;
class MenuItemDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modifierIdToQuantity: {},
            modifiers: [],
            changesMade: false,
        };
        this.modifierGroupIdToMaxQuantity = {};
    }

    getModifierQuantity(modifierId) {
        const modifiers = this.props.state.order.selected_item_modifiers;
        const quantity = modifiers.filter(
            (modifier) => modifier.modifier_id === modifierId
        ).length;
        return quantity >= 1 ? quantity : 1;
    }

    componentDidMount() {
        const modifiers = this.props.state.order.selected_item_modifiers;
        const modifierGroups =
            this.props.state.order.selected_item.item_modifier_groups;
        // Filter for unique modifiers
        this.setState({
            modifiers: [
                ...new Map(
                    modifiers.map((modifier) => [
                        modifier.modifier_id,
                        modifier,
                    ])
                ).values(),
            ],
        });
        // Initialize modifierIdToQuantity
        modifiers.forEach((modifier) => {
            const modifierId = modifier.modifier_id;
            this.setState((prev) => ({
                modifierIdToQuantity: {
                    ...prev.modifierIdToQuantity,
                    [modifierId]: this.getModifierQuantity(modifierId),
                },
            }));
        });
        if (Array.isArray(modifierGroups)) {
            modifierGroups.forEach((modGroupObject) => {
                const modGroup = modGroupObject.modifier_group;
                const modifierGroupId = modGroup.modifier_group_id;
                this.modifierGroupIdToMaxQuantity[modifierGroupId] =
                    modGroup.max_selections;
            });
        }
    }

    removeModifierOfSameGroup(modifier, modifiers) {
        modifiers.forEach((m, i) => {
            if (m.modifier_group_id === modifier.modifier_group_id) {
                modifiers.splice(i, 1);
                this.setState((prev) => ({
                    modifierIdToQuantity: {
                        ...prev.modifierIdToQuantity,
                        [m.modifier_id]: 0,
                    },
                }));
            }
        });
    }

    modifierSelected(
        modifier,
        is_multiple_selection_allowed,
        modifier_group_object
    ) {
        let modifiers = this.state.modifiers;
        const modifier_id = modifier.modifier_id;

        if (this.isModifierChecked(modifier)) {
            // Simply uncheck the modifier
            modifiers = modifiers.filter(
                (m) => m.modifier_id !== modifier.modifier_id
            );
            this.setState({
                modifierIdToQuantity: {
                    ...this.state.modifierIdToQuantity,
                    [modifier_id]: 0,
                },
            });
        } else {
            // check that the number of modifiers selected does not exceed max_qty
            if (
                this.getNumModifersSelectedFromModifier(modifier) >=
                    modifier_group_object.max_qty &&
                is_multiple_selection_allowed
            ) {
                return;
            }

            if (!is_multiple_selection_allowed) {
                // Uncheck all modifiers in the modifier group
                this.removeModifierOfSameGroup(modifier, modifiers);
            }

            // Check the modifier and set the quantity
            modifiers.push(modifier);
            this.setState((prev) => ({
                modifierIdToQuantity: {
                    ...prev.modifierIdToQuantity,
                    [modifier.modifier_id]: 1,
                },
            }));
        }
        this.setState({
            modifiers: modifiers,
            changesMade: true,
        });
    }

    isModifierChecked(modifier) {
        return (
            this.state.modifiers.filter(
                (m) => modifier.modifier_id === m.modifier_id
            ).length > 0
        );
    }

    generateRequirementText(min, max) {
        if (min === max) {
            return `${i18n.t('itemDetail.exactly')} ${min}`;
        } else if (min === 0 && max === MAX_MODIFIERS) {
            return i18n.t('itemDetail.any');
        } else if (min === 0) {
            return `${i18n.t('itemDetail.atMost')} ${max}`;
        } else if (max === MAX_MODIFIERS) {
            return `${i18n.t('itemDetail.atLeast')} ${min}`;
        } else {
            return i18n.t('itemDetail.between', { min, max });
        }
    }

    renderModifier(
        modifier,
        modifier_name,
        is_multiple_selection_allowed,
        modifier_group,
        modifier_group_object
    ) {
        const theme = this.props.theme;
        const modifierId = modifier.modifier_id;
        const isChecked = this.isModifierChecked(modifier);
        const multiplier = this.state.modifierIdToQuantity[modifierId];
        const showModifier =
            modifier_group_object.max_qty !== 1 &&
            modifier_group.max_selections !== 1;

        return (
            <>
                <div
                    className={`itemModifierRadioRow ${
                        isChecked ? 'itemModifierRadioSelected' : ''
                    }`}
                    key={modifier.modifier_id}
                    onClick={() =>
                        this.modifierSelected(
                            modifier,
                            is_multiple_selection_allowed,
                            modifier_group_object
                        )
                    }
                >
                    <label className="itemModifierLabel">
                        {modifier_name}
                        <div className="itemModifierDescriptionLabel">
                            {modifier.description}{' '}
                            {modifier.price ? ' +$' + modifier.price : ''}
                        </div>
                    </label>
                </div>
                {isChecked && showModifier ? (
                    <div className="itemMultiplier">
                        <div
                            className="itemMultiplierContainer"
                            style={{
                                ...theme.itemMultiplierContainer,
                                margin: '10px 0',
                            }}
                        >
                            <div
                                className="itemMultiplerMinus noselect"
                                style={theme.itemMultiplierLeft}
                                onClick={() =>
                                    this.decreaseModifierQuantity(modifierId)
                                }
                            >
                                -
                            </div>
                            <div
                                className="itemMultiplierAmount"
                                style={theme.itemMultiplierText}
                            >
                                {multiplier}
                            </div>
                            <div
                                className="itemMultiplierPlus noselect"
                                style={theme.itemMultiplierRight}
                                onClick={() =>
                                    this.increaseModifierQuantity(
                                        modifier,
                                        modifier_group_object
                                    )
                                }
                            >
                                +
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </>
        );
    }

    renderModifierGroups(item) {
        const modifiers = this.props.state.order.menu.modifiers;

        const modifierGroups = item.item_modifier_groups;
        if (!Array.isArray(modifierGroups)) {
            return;
        }

        // sort based on modifier group rank
        modifierGroups.sort((g1, g2) => {
            if (g1.rank < g2.rank) {
                return -1;
            }
            if (g1.rank > g2.rank) {
                return 1;
            }
            return 0;
        });

        return (
            <div
                className="itemModifierGroupContainer"
                id="itemModifierGroupContainer"
            >
                {modifierGroups.map((modGroupObject) => {
                    var is_multiple_selection_allowed = false;
                    if (modGroupObject.max_qty > 1)
                        is_multiple_selection_allowed = true;

                    const modGroup = modGroupObject.modifier_group;
                    const group_modifiers = modifiers.filter((mod) => {
                        return (
                            mod.modifier_group_id === modGroup.modifier_group_id
                        );
                    });
                    var requirement =
                        ` (${this.props.t('itemDetail.choose')} ` +
                        this.generateRequirementText(
                            modGroupObject.min_qty,
                            modGroupObject.max_qty
                        ) +
                        ')';
                    let modifier_group_name = modGroup.name;
                    const dot_index = modifier_group_name.indexOf('.');
                    if (dot_index !== -1) {
                        // check if index before dot is a number
                        if (
                            !isNaN(
                                parseInt(
                                    modifier_group_name.slice(
                                        dot_index - 1,
                                        dot_index
                                    )
                                )
                            ) &&
                            modifier_group_name.slice(
                                dot_index + 1,
                                dot_index + 2
                            ) === ' '
                        ) {
                            modifier_group_name = modifier_group_name.slice(
                                dot_index + 2,
                                modifier_group_name.length
                            );
                        }
                    }

                    return (
                        <div
                            className="itemModifierGroup"
                            key={modGroup.modifier_group_id}
                            id={modGroup.modifier_group_id}
                        >
                            <div className="itemModifierGroupName">
                                {modifier_group_name}
                                <span className="itemModifierDescriptionLabel">
                                    {requirement}
                                </span>
                            </div>
                            {group_modifiers.map((modifier) => {
                                let modifier_name = modifier.name;
                                const mod_dot_index =
                                    modifier_name.indexOf('.');
                                if (mod_dot_index !== -1) {
                                    if (
                                        !isNaN(
                                            parseInt(
                                                modifier_name.slice(
                                                    mod_dot_index - 1,
                                                    mod_dot_index
                                                )
                                            )
                                        ) &&
                                        modifier_name.slice(
                                            mod_dot_index + 1,
                                            mod_dot_index + 2
                                        ) === ' '
                                    ) {
                                        modifier_name = modifier_name.slice(
                                            mod_dot_index + 2,
                                            modifier_name.length
                                        );
                                    }
                                }

                                if (!modifier.is_in_stock) {
                                    return (
                                        <div
                                            className={`itemModifierRadioRow`}
                                            key={modifier.modifier_id}
                                            style={{ opacity: '0.5' }}
                                        >
                                            <label className="itemModifierLabel">
                                                {modifier_name}
                                                <div className="itemModifierDescriptionLabel">
                                                    {modifier.description}{' '}
                                                    {modifier.price
                                                        ? ' +$' + modifier.price
                                                        : ''}
                                                </div>
                                                <div
                                                    className="itemModifierDescriptionLabel"
                                                    style={{ color: 'red' }}
                                                >
                                                    {this.props.t(
                                                        'itemDetail.outOfStock'
                                                    )}
                                                </div>
                                            </label>
                                        </div>
                                    );
                                } else {
                                    return this.renderModifier(
                                        modifier,
                                        modifier_name,
                                        is_multiple_selection_allowed,
                                        modGroup,
                                        modGroupObject
                                    );
                                }
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }

    checkModifiersAreSatisfied() {
        // validate that all required modifiers are selected
        const modifierGroups =
            this.props.state.order.selected_item.item_modifier_groups;
        const modifiers = this.state.modifiers;
        let satisfied = true;

        if (Array.isArray(modifierGroups)) {
            modifierGroups.forEach((modifierGroup) => {
                const modifierGroupId =
                    modifierGroup.modifier_group.modifier_group_id;
                const quantity = modifiers.filter(
                    (modifier) => modifier.modifier_group_id === modifierGroupId
                ).length;
                if (
                    quantity < modifierGroup.min_qty ||
                    quantity > modifierGroup.max_qty
                ) {
                    const requirementText = this.generateRequirementText(
                        modifierGroup.min_qty,
                        modifierGroup.max_qty
                    );
                    toast.error(
                        i18n.t('toast.modifierRequirement', {
                            requirementText,
                            modifierName: modifierGroup.modifier_group.name,
                        }),
                        { toastId: modifierGroupId }
                    );
                    satisfied = false;
                }
            });
        }

        return satisfied;
    }

    getNumModifiersSelectedFromGroup(modifier_group_object) {
        const all_modifiers = this.props.state.order.menu.modifiers;
        const modifierGroupid =
            modifier_group_object.modifier_group.modifier_group_id;
        const modifiersInGroup = all_modifiers.filter(
            (m) => m.modifier_group_id === modifierGroupid
        );
        let qty = 0;
        modifiersInGroup.forEach((modifier) => {
            qty += this.state.modifierIdToQuantity[modifier.modifier_id] || 0;
        });
        return qty;
    }

    getNumModifersSelectedFromModifier(modifier) {
        const all_modifiers = this.props.state.order.menu.modifiers;
        const modifierGroupid = modifier.modifier_group_id;
        const modifiersInGroup = all_modifiers.filter(
            (m) => m.modifier_group_id === modifierGroupid
        );

        let qty = 0;

        modifiersInGroup.forEach((modifier) => {
            qty += this.state.modifierIdToQuantity[modifier.modifier_id] || 0;
        });

        return qty;
    }

    addToOrder() {
        const is_edit_item = this.props.state.order.is_edit_item;

        if (this.checkModifiersAreSatisfied()) {
            // build modifiers with multiplier
            const modifiers = [];
            this.state.modifiers.forEach((modifier) => {
                const quantity =
                    this.state.modifierIdToQuantity[modifier.modifier_id];
                modifiers.push(...new Array(quantity).fill(modifier));
            });
            this.props.itemModifiersUpdated(modifiers);

            if (is_edit_item) {
                // save edited item
                this.props.saveEditedItem(
                    this.props.history,
                    this.state.changesMade
                );
            } else {
                this.props.addToOrder(this.props.history);
            }
            this.setState({ changesMade: false });
        }
    }

    increaseModifierQuantity(modifier, modifier_group_object) {
        const modifierId = modifier.modifier_id;

        const quantity = this.getNumModifiersSelectedFromGroup(
            modifier_group_object
        );
        const maxQuantity = modifier_group_object.max_qty;

        if (quantity === maxQuantity) {
            return;
        }
        this.setState((prev) => ({
            modifierIdToQuantity: {
                ...prev.modifierIdToQuantity,
                [modifierId]: prev.modifierIdToQuantity[modifierId] + 1,
            },
        }));
    }

    decreaseModifierQuantity(modifierId) {
        const quantity = this.state.modifierIdToQuantity[modifierId];
        if (quantity === 1) {
            // deselect item
            const new_modifiers = this.state.modifiers.filter(
                (modifier) => modifier.modifier_id !== modifierId
            );
            this.setState({ modifiers: new_modifiers });
        }
        this.setState((prev) => ({
            modifierIdToQuantity: {
                ...prev.modifierIdToQuantity,
                [modifierId]: prev.modifierIdToQuantity[modifierId] - 1,
            },
        }));
    }

    increaseQuantity() {
        this.setState({ changesMade: true });

        this.props.increaseItemQuantity();
    }

    decreaseQuantity() {
        this.setState({ changesMade: true });

        this.props.decreaseItemQuantity();
    }

    getImage(item_id) {
        const restaurant_id =
            this.props.state.main.restaurant_info.restaurant_id;
        return `${cloudfrontBaseUrl}${restaurant_id}/items/${item_id}.jpeg`;
    }

    renderSpecialRequests() {
        const theme = this.props.theme;

        if (isEthos()) {
            return;
        }

        return (
            <>
                <div className="itemSpecialInstructionsContainer">
                    <div
                        className="itemSpecialInstructionsLabel"
                        style={theme.modifierGroupName}
                    >
                        {this.props.t('common.specialRequests')}
                    </div>
                    <input
                        className="itemSpecialInstructionsTextField"
                        value={
                            this.props.state.order
                                .selected_item_special_instructions
                        }
                        onChange={(e) => {
                            this.setState({ changesMade: true });
                            this.props.menuItemSpecialInstructionsChanged(
                                e.target.value
                            );
                        }}
                    />
                </div>
            </>
        );
    }

    render() {
        const theme = this.props.theme;
        let item = this.props.state.order.selected_item;
        let category = this.props.state.order.selected_category;
        let selectedItemQuantity =
            this.props.state.order.selected_item_quantity;
        let image = this.getImage(item.item_id);
        const is_edit_item = this.props.state.order.is_edit_item;

        return (
            <div className="itemDetailMobileBottomPaddingFix">
                {!is_edit_item ? (
                    <Header
                        back="/menu/category"
                        showGuestName
                        history={this.props.history}
                        backButtonText={category.name}
                    />
                ) : (
                    ''
                )}

                <div className="itemDetailsDesktopContainer">
                    <div
                        className="itemPhoto"
                        style={{ backgroundImage: `url(${image})` }}
                    >
                        <img
                            alt=""
                            src={image}
                            className="itemPhoto"
                            style={{ visibility: 'hidden' }}
                        ></img>
                    </div>

                    <div className="itemDetailOverflow">
                        <div className="itemDetailsContainer">
                            <div className="headerRow">
                                <div
                                    className="itemDetailName"
                                    style={theme.itemDetailTitle}
                                >
                                    {item.name}{' '}
                                    {!item.is_in_stock
                                        ? this.props.t('itemDetail.soldOut')
                                        : ''}
                                </div>
                                <div
                                    className="itemDetailPrice"
                                    style={theme.itemDetailPrice}
                                >
                                    {(item.price || 0).toFixed(2)}
                                </div>
                            </div>
                            <div
                                className="itemDetailDescription"
                                style={theme.itemDetailDescription}
                            >
                                {item.description}
                            </div>
                        </div>

                        {this.renderModifierGroups(item)}

                        {this.renderSpecialRequests()}

                        <div className="itemMultiplier">
                            <div
                                className="itemMultiplierContainer"
                                style={theme.itemMultiplierContainer}
                            >
                                <div
                                    className="itemMultiplerMinus noselect"
                                    style={theme.itemMultiplierLeft}
                                    onClick={() => this.decreaseQuantity()}
                                >
                                    -
                                </div>
                                <div
                                    className="itemMultiplierAmount"
                                    style={theme.itemMultiplierText}
                                >
                                    {selectedItemQuantity}
                                </div>
                                <div
                                    className="itemMultiplierPlus noselect"
                                    style={theme.itemMultiplierRight}
                                    onClick={() => this.increaseQuantity()}
                                >
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="floatingButton"
                    style={{
                        ...theme.floatingButton,
                        justifyContent: 'center',
                    }}
                    onClick={() => this.addToOrder()}
                >
                    <div
                        className="floatingButtonLabel"
                        style={{
                            ...theme.floatingButtonLabel,
                            marginLeft: '0',
                        }}
                    >
                        {is_edit_item
                            ? this.props.t('cart.save')
                            : this.props.t('itemDetail.addToOrder')}
                    </div>
                </div>
                <div
                    className="floatingButtonSafeArea"
                    style={theme.floatingButton}
                ></div>
            </div>
        );
    }
}

export default withTranslation()(
    withTheme(
        connect((state) => ({ state }), {
            itemModifiersUpdated: OrderActions.itemModifiersUpdated,
            menuItemSpecialInstructionsChanged:
                OrderActions.menuItemSpecialInstructionsChanged,
            addToOrder: OrderActions.addToOrder,
            increaseItemQuantity: OrderActions.increaseItemQuantity,
            decreaseItemQuantity: OrderActions.decreaseItemQuantity,
            saveEditedItem: OrderActions.saveEditedItem,
        })(MenuItemDetail)
    )
);
