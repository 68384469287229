import React from 'react';
import { connect } from 'react-redux';

// Action creators
import * as TableCodeActions from '../actions/tableCodeActions';
import * as TableActions from '../actions/tableActions';
import * as OrderActions from '../actions/orderActions';

// Components
import Header from '../components/Header';
import Button from '../components/Button';

// css
import './css/GuestList.css';
import './css/Default.css';

// Theming
import { withTheme } from '../themes/theming';
import { DineInOrTakeoutSetting } from '../reducers/TYPES';

class GuestList extends React.Component {
    componentDidMount() {
        // this.props.resetReduxState();
        // Update the table code state using the code provided in URL

        // Get table code from URL /table/:tableCode
        const {
            match: { params },
        } = this.props;
        const tableCode = params.tableCode;
        const history = this.props.history; // history from react-router-dom

        // set table to pay later
        this.props.setDineInPaymentFlow();

        // Update table code if a direct link was loaded
        this.props.directLinkLoaded(tableCode);

        // Validate table code
        this.props.checkTableCode(history);

        // Load guest list
        this.props.loadGuestList();
    }

    /* Add guest button pressed */
    addGuestButtonPressed() {
        // this.props.history.push('/addguest');
        this.props.addGuestToDineinTable(this.props.history);
    }

    guestSelected(guest) {
        this.props.setDineInOrTakeout(DineInOrTakeoutSetting.DINE_IN);
        this.props.selectGuest(guest, this.props.history);
    }

    /* Render the guest buttons */
    renderGuests(guests) {
        // We want to create a button for each guest
        return guests.map((guest) => (
            <Button
                solid
                text={guest.name}
                width={3}
                arrow
                key={guest.guest_id}
                action={() => this.guestSelected(guest)}
                blue
            />
        ));
    }

    render() {
        const guests = this.props.tableState.guests; // get guest list
        const theme = this.props.theme;
        const restaurant = this.props.mainState.restaurant_info.restaurant_id;
        const restaurant_logo = this.props.mainState.restaurant_info.logo;

        if (!restaurant) {
            return (
                <div className="cssload-container">
                    <div className="cssload-whirlpool"></div>
                </div>
            );
        }

        return (
            <div>
                <Header history={this.props.history} />
                <div className="Container" style={{ paddingBottom: '100px' }}>
                    {/* <div style={{ display: "none", ...theme.guestListLogoContiner }}>

            <img alt="" src={theme.guestListImage} style={theme.guestListImageStyle}></img>

          </div> */}
                    <img
                        alt=""
                        src={restaurant_logo}
                        style={{
                            margin: '40px',
                            width: 'calc(100% - 80px)',
                            marginTop: '60px',
                        }}
                    ></img>

                    <div style={theme.guestListSubtitleContainer}>
                        {theme.description}
                    </div>

                    <div className="guestListContainer">
                        {/* <div className="greeting" style={theme.guestListGreeting}>a sense of place & someplace else</div> */}
                        <div className="buttonGroupContainer">
                            {this.renderGuests(guests)}
                        </div>

                        <div className="seperator"></div>

                        <div className="buttonGroupContainer">
                            <Button
                                text="New order"
                                width={3}
                                blue
                                plus
                                action={() => this.addGuestButtonPressed()}
                            />
                        </div>

                        {/* Loading Animation */}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mainState: state.main,
        tableState: state.table,
    };
};
const actionCreators = {
    directLinkLoaded: TableCodeActions.directLinkLoaded,
    checkTableCode: TableCodeActions.checkTableCode,

    loadGuestList: TableActions.loadGuestList,
    selectGuest: TableActions.selectGuest,
    setDineInPaymentFlow: TableActions.setDineInPaymentFlow,

    resetReduxState: TableCodeActions.resetReduxState,
    loadMenu: OrderActions.loadMenu,

    addGuestToDineinTable: TableActions.addGuestToDineinTable,
    setDineInOrTakeout: OrderActions.setDineInOrTakeout,
};

export default withTheme(connect(mapStateToProps, actionCreators)(GuestList));
