import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './css/TipModal.css';
import * as OrderActions from '../../../actions/orderActions';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { onComplete, onClose } = props;
    const { t } = useTranslation();
    if (!onComplete) {
        throw new Error('onComplete missing from tip modal');
    }

    const total_before_tax = useSelector(
        (state) => state.order.payment_totals.net_total
    );
    const dispatch = useDispatch();

    const getTipAmount = (tip) => {
        return `$${(total_before_tax * tip).toFixed(2)}`;
    };

    const setTipAmount = (amount) => {
        dispatch(OrderActions.changeTip(amount));
        onComplete();
    };

    const tipToShow = [0.05, 0.1, 0.2];

    return (
        <div className="tipModal">
            <div className="tipModalContainer">
                <div className="tipModalContainerCancelBtn" onClick={onClose}>
                    <img
                        alt="Cancel"
                        src="/assets/icons/cancel-btn.svg"
                        style={{ width: '30px' }}
                    ></img>
                </div>
                <div className="tipModalContainerHeader">{t('cart.tip')}</div>
                <div className="tipModalContainerDescription">
                    {t('tipModal.addTipQuestion')}
                </div>
                <div className="tipModalOptionsContainer">
                    <div
                        className="tipModalOption"
                        onClick={() => setTipAmount(0)}
                    >
                        <div className="tipModalOption-percentage">0%</div>
                        <div className="tipModalOption-amount">
                            {t('tipModal.noThanks')}
                        </div>
                    </div>

                    {tipToShow.map((tip) => {
                        return (
                            <div
                                className="tipModalOption"
                                onClick={() => setTipAmount(tip)}
                            >
                                <div className="tipModalOption-percentage">
                                    {(tip * 100).toFixed(0)}%
                                </div>
                                <div className="tipModalOption-amount">
                                    {getTipAmount(tip)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
