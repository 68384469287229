import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import * as OrderActions from '../actions/orderActions';

import './css/TwoFA.css';
import { APIv1Endpoint } from '../endpoints';
import { toast } from 'react-toastify';
import { i18n } from '../locales';

class TwoFA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            success: false,
            failure: false,
        };
    }

    generate(resend = false) {
        // check if 60 seconds has passed until next code generation
        let code_last_recieved = this.props.state.order.twofa_code_requested;
        if (!code_last_recieved) {
            code_last_recieved = 0;
        }

        const now = new Date().getTime();
        let diff = now - code_last_recieved;

        if (Math.floor(diff / 1000) > 60) {
            const { guestId } = this.props;
            const endpoint = `${APIv1Endpoint}guest/${guestId}/generate2FA`;
            this.props.twoFACodeRequested();
            Axios.get(endpoint)
                .catch((err) => {
                    console.error(err.response);
                })
                .then((res) => {
                    if (resend) {
                        toast.success(i18n.t('toast.sentAnotherCode'));
                    }
                });
        } else {
            toast('', {
                toastId: 'codeRequestBlocked',
            });
            toast.update('codeRequestBlocked', {
                render: (
                    <div>

                        You must wait {60 - Math.floor(diff / 1000)} more
                        seconds before sending another code.
                    </div>
                ),
                type: 'error',
            });
        }
    }

    verify(code, on_success, on_failure) {
        const endpoint = `${APIv1Endpoint}guest/verify2FA`;
        const { guestId } = this.props;

        Axios.post(endpoint, { guestId, code })
            .then((res) => {
                const { success } = res.data;
                if (success) {
                    on_success();
                } else {
                    on_failure();
                }
            })
            .catch((err) => {
                console.error(err.response);
                on_failure();
            });
    }

    onSuccess() {
        this.setState({ success: true, failure: false });
        this.props.onSuccess();
    }

    onFailure() {
        this.setState({ success: false, failure: true });
        this.props.onFailure();
    }

    componentDidMount() {
        this.generate();
    }

    render() {
        const num_chars = 6;
        let border_cls = this.state.success
            ? 'SuccessBorder'
            : this.state.failure
            ? 'FailureBorder'
            : '';

        return (
            <div className="TwoFAContainer">
                {/* <div className="TwoFALabelContainer">
                    A message with a verification code has been sent to your device. Enter the code to continue.
                </div> */}
                <div className={`AuthCodeContainer ${border_cls}`}>
                    <input
                        type="text"
                        className="TwoFAInputField"
                        autoComplete="one-time-code"
                        value={this.state.code}
                        placeholder="000000"
                        maxLength={6}
                        onChange={(e) => {
                            const value = e.target.value;
                            this.setState({ code: value });
                            if (value.length === num_chars) {
                                this.verify(
                                    value,
                                    () => {
                                        this.onSuccess();
                                    },
                                    () => {
                                        this.onFailure();
                                    }
                                );
                            } else {
                                this.setState({
                                    success: false,
                                    failure: false,
                                });
                            }
                        }}
                    />

                    {/* <AuthCode
                        characters={num_chars}
                        onChange={(value) => {
                            this.setState({ code: value });
                            if (value.length === num_chars) {
                                this.verify(value, () => { this.onSuccess() }, () => { this.onFailure() });
                            } else {
                                this.setState({ success: false, failure: false });
                            }
                        }}
                        containerStyle={{
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        inputStyle={{
                            width: '1.5ch',
                            padding: '12px',
                            borderRadius: '5px',
                            fontFamily: "Roboto Mono",
                            fontSize: '24px',
                            textAlign: 'center',
                            marginRight: '5px',
                            border: '0',
                            borderBottom: '6px solid rgb(218, 218, 218)',
                            backgroundColor: "rgb(247, 247, 247)",
                            textTransform: 'uppercase'
                        }}
                        value={this.state.code}
                    /> */}
                </div>
                <div
                    className="TwoFAResendContainer"
                    onClick={(e) => {
                        // toast.success("We've sent you another code.");
                        this.generate(true);
                    }}
                >
                    Resend Link
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });
export default connect(mstp, {
    twoFACodeRequested: OrderActions.twoFACodeRequested,
})(TwoFA);
