import React, { useState, useEffect, useRef } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import ReactDOMServer from 'react-dom/server';
import { ImPhone } from 'react-icons/im';
import { HiShoppingCart } from 'react-icons/hi';
import { FaLocationArrow } from 'react-icons/fa';
import GKAnnotationDetails from './GKAnnotationDetails';
import { BASE_URL } from '../../../endpoints';
/**
 * @function
 * @param {import('./GKLocationPickerDesktop').Restaurant} restaurant
 * @param {Object} hours
 * @param
 *
 */
export const buildRestaurantMarker = (restaurant, hours) => {
    const content = document.createElement('div');
    const { openingIntervals, preOrder, isComingSoon } = hours;

    content.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <div>
            <div className="title">
                <p>{restaurant.name}</p>
            </div>
            <div className="restaurantItem restaurantItemAnnotation details">
                <h1>{restaurant.name}</h1>
                <div>
                    <section className="restautantsAddressText">
                        {restaurant.address}
                    </section>
                </div>
                {restaurant.phone_number ? (
                    <section style={{ paddingBottom: '10px' }}>
                        <span style={{ fontWeight: 500 }}>Phone:</span>{' '}
                        {restaurant.phone_number}
                    </section>
                ) : null}

                {!isComingSoon ? (
                    <div className="openHoursSection">
                        <GKAnnotationDetails
                            openingIntervals={openingIntervals}
                        />
                    </div>
                ) : null}
                <br />
                {!isComingSoon ? (
                    <>
                        <div className="restaurantLocationActionContainer">
                            <a
                                className="restaurantLocationActionButton"
                                href={`tel:${restaurant.phone_number}`}
                                target="_blank"
                            >
                                <ImPhone
                                    size="0.8em"
                                    style={{ marginRight: '5px' }}
                                />
                                {'Call'}
                            </a>
                            <a
                                className="restaurantLocationActionButton"
                                href={`http://maps.apple.com/?q=${restaurant.address}`}
                                target="_blank"
                            >
                                <FaLocationArrow
                                    size="0.8em"
                                    style={{ marginRight: '5px' }}
                                />
                                {'Directions'}
                            </a>
                        </div>
                        <a
                            className="restaurantLocationOrderButton2"
                            href={`${BASE_URL}/${restaurant.endpoint}`}
                            target="_blank"
                        >
                            <HiShoppingCart
                                size="0.8em"
                                style={{ marginRight: '5px' }}
                            />
                            {preOrder ? (
                                <span>{'Preorder'}</span>
                            ) : (
                                <span>{'Order'}</span>
                            )}
                        </a>
                    </>
                ) : (
                    <div
                        className="restaurantLocationOrderButton2"
                        style={{
                            backgroundColor: 'rgb(249, 249, 249)',
                            color: 'rgb(102, 102, 102)',
                        }}
                    >
                        <HiShoppingCart
                            size="0.8em"
                            style={{ marginRight: '5px' }}
                        />
                        {'Coming Soon'}
                    </div>
                )}
            </div>
        </div>
    );

    return content;
};

const GKGoogleMap = ({ selectedCoordinates, markers }) => {
    const token = 'AIzaSyBXP2M5if4sWkuZH92dUm3B3urlzZohKsY';
    const ref = useRef();
    const [map, setMap] = useState(null);
    const zoom = 17;

    const render = (status) => {
        if (status === Status.LOADING) return <h3>{status} ..</h3>;
        if (status === Status.FAILURE) return <h3>{status} ...</h3>;
        return null;
    };

    useEffect(() => {
        (async () => {
            if (!window.google || !ref.current || map) return;
            const { Map } = await window.google.maps.importLibrary('maps');
            const mapObj = new Map(ref.current, {
                zoom,
                mapId: 'ghost-kitchens-restaurants-map',
            });
            setMap(mapObj);
        })();
    });

    useEffect(() => {
        if (!map || !selectedCoordinates) return;
        map.panTo(selectedCoordinates);
        map.setZoom(zoom);
    }, [selectedCoordinates]);

    useEffect(() => {
        const placeMarkers = async () => {
            if (!window.google || !map) return;
            const { AdvancedMarkerElement } =
                await window.google.maps.importLibrary('marker');
            markers.forEach((marker) => {
                // const markerTag = document.createElement('div');

                marker.content.classList.add('markerView');
                // markerTag.textContent = marker.title;

                const element = new AdvancedMarkerElement({
                    map,
                    content: marker.content,
                    position: marker.position,
                    title: marker.title,
                });

                element.addListener('click', () => {
                    if (element.content.classList.contains('highlight')) {
                        element.content.classList.remove('highlight');
                        element.zIndex = null;
                    } else {
                        element.content.classList.add('highlight');
                        element.zIndex = 1;
                    }
                    marker.onClick();
                });
            });
        };
        placeMarkers();
    }, [markers]);

    return (
        <Wrapper apiKey={token} render={render}>
            <div ref={ref} id="map" style={{ width: '100%', height: '100%' }} />
        </Wrapper>
    );
};

export default GKGoogleMap;
