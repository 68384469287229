import { Fab, IconButton, InputLabel, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import amplitude from 'amplitude-js';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as OrderActions from '../../../actions/orderActions';
import * as TableActions from '../../../actions/tableActions';
import {
    isDineinOrder,
    isFrontOfHouse,
    isGhostKitchens,
    isHotel,
    isPaymentsEnabled,
    isTakeout,
    isTakeoutOrder,
} from '../../../utils';

const isPayAtCounterEnabled = (restaurant) => {
    const payAtCounterSetting = restaurant.pay_at_counter || '';
    if (
        isTakeoutOrder() &&
        ['RESTAURANT_SETTING_DINE_IN_ONLY', 'RESTAURANT_SETTING_NONE'].includes(
            payAtCounterSetting
        )
    ) {
        return false;
    }
    if (
        isDineinOrder() &&
        [
            'RESTAURANT_SETTING_TAKE_OUT_ONLY',
            'RESTAURANT_SETTING_NONE',
        ].includes(payAtCounterSetting)
    ) {
        return false;
    }
    return true;
};

const useStyles = makeStyles((theme) => ({
    phone: {
        flex: '1',
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'column',
    },
    fab: {
        position: 'fixed',
        bottom: 'calc(60px + env(safe-area-inset-bottom))',
        right: '1rem',
    },
    textField: {
        fontSize: '2rem',
        fontWeight: '700',
        color: '#202020',
    },
}));

export default (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const endpoint = params.endpoint;
    const guest = useSelector((state) => state.table.selected_guest);
    const restaurant = useSelector((state) => state.main.restaurant_info);
    const showPayAtCounter =
        isPayAtCounterEnabled(restaurant) || isFrontOfHouse();
    const currency =
        useSelector((state) => state.main.restaurant_info.currency) || '';
    const [phoneNumber, setPhoneNumber] = React.useState(guest.phone_number);

    const guestName = guest.name;
    const guestTableName = guest.table_id;

    const [name, setName] = React.useState(
        guestName !== guestTableName ? guestName : ''
    );
    const [loading, setLoading] = React.useState(false);

    const paymentsEnabled = isPaymentsEnabled();
    const { total_with_tip_and_tax } = useSelector(
        (state) => state.order.payment_totals
    );

    const { t } = useTranslation();

    useEffect(() => {
        if (!(typeof phoneNumber === 'string')) {
            setPhoneNumber('');
        }
    }, [phoneNumber]);

    const handleSaveName = async () => {
        if (!name || name.length < 1 || name === guest.name) {
            return;
        }

        await handleName(name);
    };

    const handleName = () => {
        try {
            dispatch(TableActions.submitGuestName(name));
        } catch (error) {
            console.log({ error });
        }
    };

    const verifyPhoneNumber = () => {
        // if (!isValidPhoneNumber(phoneNumber) && !isFrontOfHouse()) {
        //     toast.error(t('toast.invalidPhoneNumber1'));
        //     return;
        // } else
        if (isValidPhoneNumber(phoneNumber)) {
            amplitude.getInstance().logEvent('Phone Number Button');
            dispatch(TableActions.updatePhoneNumber(phoneNumber, true));
            dispatch(OrderActions.setTwoFACompleted(true));
        }
        setLoading(true);

        handleSaveName();

        if (isHotel()) {
            history.push('/hotel/customerform');
        } else {
            if (isDineinOrder()) {
                dispatch(OrderActions.getPaymentIntent_PayLater(history));
            } else {
                dispatch(OrderActions.getPaymentIntent_PayBefore(history));
            }
        }
    };

    const payAtCounter = () => {
        // if (!isValidPhoneNumber(phoneNumber) && !isFrontOfHouse()) {
        //     toast.error(t('toast.invalidPhoneNumber1'));
        //     return;
        // } else
        if (isValidPhoneNumber(phoneNumber)) {
            dispatch(TableActions.updatePhoneNumber(phoneNumber, false));
        }

        handleSaveName();

        setLoading(true);
        dispatch(OrderActions.payAtCounter(history, true, true));
    };

    const renderPayAtCounter = () => {
        if (!showPayAtCounter) {
            return <></>;
        }
        if (loading) {
            return <></>;
        }
        if (isDineinOrder()) {
            return <></>;
        }
        if (isGhostKitchens() && !isFrontOfHouse()) {
            return <></>;
        }

        return (
            <Fab
                className={classes.fab}
                variant="extended"
                style={{
                    textTransform: 'none',
                    backgroundColor: '#20B04B',
                    color: 'white',
                    fontWeight: '700',
                    padding: '2rem',
                    borderRadius: '10000px',
                    marginRight: paymentsEnabled ? '130px' : '0px',
                }}
                onClick={payAtCounter}
                data-cy="pnPayAtCounterButton"
            >
                {loading ? t('order.loading') : t('order.payAtCounterOption')}
            </Fab>
        );
    };

    const renderPayButton = () => {
        if (!paymentsEnabled && total_with_tip_and_tax > 0) {
            return <></>;
        }

        if (loading) {
            return <></>;
        }
        return (
            <Fab
                className={classes.fab}
                variant="extended"
                style={{
                    textTransform: 'none',
                    backgroundColor: '#20B04B',
                    color: 'white',
                    fontWeight: '700',
                    padding: '2rem',
                    borderRadius: '10000px',
                }}
                onClick={verifyPhoneNumber}
                data-cy="phoneNumberNextButton"
            >
                {loading ? t('order.loading') : t('tableSelect.next')}
            </Fab>
        );
    };

    const cart_items = useSelector((state) => state.order.temporary_order);
    const cartEmpty = !Array.isArray(cart_items) || cart_items.length === 0;
    if (cartEmpty && isTakeout()) {
        history.replace(`/v2/${endpoint}/order`);
    }

    let prompt = t('phoneNumber.receiptNotification');
    if (isDineinOrder) {
        prompt = t('phoneNumber.receiptTextMessage');
    }

    return (
        <div className={classes.phone}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '1rem 0rem 1rem 0rem',
                }}
            >
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <ArrowBack style={{ fill: '#222222' }} />
                </IconButton>
            </div>
            <div
                style={{
                    padding: '0rem 1rem 2rem 1rem',
                    fontWeight: '700',
                    fontSize: '1.5rem',
                    color: '#202020',
                }}
            >
                {prompt}
            </div>
            <div className="w-full px-4 my-4">
                <InputLabel
                    style={{
                        fontWeight: '700',
                        color: '#717171',
                        fontSize: '0.75rem',
                    }}
                >
                    {`${t('phoneNumber.name')} ${
                        isFrontOfHouse() ? `(${t('phoneNumber.optional')})` : ''
                    }`}
                </InputLabel>
                <div className="w-full mt-2">
                    <input
                        className="bg-white h-[50px] text-center appearance-none rounded w-full py-2 px-4 text-black"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </div>

            <div
                style={{
                    padding: '0rem 1rem 0rem 1rem',
                }}
            >
                <InputLabel
                    style={{
                        fontWeight: '700',
                        color: '#717171',
                        fontSize: '0.75rem',
                    }}
                >
                    {`${t('phoneNumber.phoneNumber')} ${
                        isFrontOfHouse() ? `(${t('phoneNumber.optional')})` : ''
                    }`}
                </InputLabel>
                <PhoneInput
                    placeholder="(123) 456-7890"
                    defaultCountry={
                        currency.toUpperCase() === 'USD' ? 'US' : 'CA'
                    }
                    maxLength={14}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    data-cy="phoneNumberInput"
                />
            </div>

            {renderPayAtCounter()}
            {!isFrontOfHouse() && renderPayButton()}
        </div>
    );
};
