import React from 'react';
import { connect } from 'react-redux';
import * as OrderActions from '../../../actions/orderActions';
import {
    checkPaymentTerminalConnected,
    isGhostKitchens,
    modifierFrequency,
    uniqueModifiersHasByID,
} from '../../../utils';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import TipModal from '../components/TipModal';
import { withTranslation } from 'react-i18next';

class OrderSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_discount_modal: false,
            show_special_instructions_modal: false,
            show_tip_modal: false,
            special_instructions: null,
        };
    }

    componentDidMount() {
        this.setState({
            special_instructions: this.props.state.order.order_instructions,
        });
    }

    validateCode() {
        this.props.validateCode(this.props.history);
        this.setState({
            show_discount_modal: false,
        });
    }

    hasAppliedCouponCode() {
        const campaign_instances = this.props.state.order.campaign_instances;
        if (Array.isArray(campaign_instances)) {
            // check that campaign instance has applied code
            const discount_code = this.props.state.order.discount_code_field;

            let discount_code_applied = false;

            campaign_instances.forEach((i) => {
                if (i.campaign.campaign_code === discount_code) {
                    discount_code_applied = true;
                }
            });

            if (!discount_code_applied) {
                return false;
            } else {
                return {
                    campaign_instances: campaign_instances.map(
                        (i) => i.campaign
                    ),
                    amount: this.props.state.order.payment_totals.discount,
                };
            }
        } else {
            return false;
        }
    }

    render() {
        let order = this.props.state.order.temporary_order;

        if (order.length === 0) {
            this.props.history.push('/kioskhome');
        }

        const renderModifiers = (modifiers) => {
            if (!Array.isArray(modifiers)) {
                return;
            }
            let uniqueModifiers = [];
            modifiers.forEach((modifier) => {
                if (!uniqueModifiersHasByID(uniqueModifiers, modifier)) {
                    uniqueModifiers.push(modifier);
                }
            });

            return uniqueModifiers.map((modifier) => {
                const frequency = modifierFrequency(modifiers, modifier);
                return (
                    <div
                        className="finalizeItemModifier to-page-review-modifier"
                        key={modifier.modifier_id}
                    >
                        {modifier.name}
                        {frequency > 1 ? ` (x${frequency})` : null}
                    </div>
                );
            });
        };

        const discount = this.hasAppliedCouponCode();

        const renderSpecialInstructions = () => {
            const specialInstructionsModal = () => {
                return (
                    <div className="kiosk-discount-modal">
                        <div className="kiosk-discount-modal-window">
                            <div className="kiosk-discount-modal-header">
                                <div className="kiosk-discount-modal-title">
                                    {this.props.t('order.specialInstructions')}
                                    Special Instructions
                                </div>
                            </div>

                            <div className="kiosk-discount-modal-body">
                                <div className="kiosk-discount-modal-alert">
                                    {this.props.t(
                                        'orderSummary.discountCodeKeyboardPrompt'
                                    )}
                                </div>
                                <input
                                    readOnly
                                    className="kiosk-discount-modal-text-field"
                                    value={
                                        this.props.state.order
                                            .order_instructions
                                    }
                                />
                                <div className="kiosk-discount-modal-button-container">
                                    <div
                                        className="kiosk-discount-modal-button modal-button-red"
                                        onClick={() => {
                                            this.props.updateSpecialInstructions(
                                                this.state.special_instructions
                                            );
                                            this.setState({
                                                show_special_instructions_modal: false,
                                            });
                                        }}
                                    >
                                        {this.props.t('common.cancel')}
                                    </div>
                                    <div
                                        className="kiosk-discount-modal-button modal-button-blue"
                                        onClick={() => {
                                            this.setState({
                                                show_special_instructions_modal: false,
                                                special_instructions:
                                                    this.props.state.order
                                                        .order_instructions,
                                            });
                                            this.props.updateOrder();
                                        }}
                                    >
                                        {this.props.t('common.done')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="kiosk-discount-modal-keyboard-background"></div>
                        <div className="kiosk-discount-modal-keyboard">
                            <Keyboard
                                onChange={(val) => {
                                    this.props.updateSpecialInstructions(val);
                                }}
                            />
                        </div>
                    </div>
                );
            };
            return (
                <div>
                    {this.state.show_special_instructions_modal
                        ? specialInstructionsModal()
                        : ''}
                    <div
                        className="to-page-review-items"
                        style={{ paddingBottom: '30px', marginBottom: '40px' }}
                    >
                        <div>
                            <div className="kiosk-discount-row">
                                <div className="to-page-review-item-name">
                                    {this.props.t('order.specialInstructions')}
                                </div>
                                <div
                                    className="to-page-review-item-button"
                                    onClick={() => {
                                        this.setState({
                                            show_special_instructions_modal:
                                                !this.state
                                                    .show_special_instructions_modal,
                                        });
                                        this.props.updateSpecialInstructions(
                                            ''
                                        );
                                    }}
                                >
                                    {this.props.state.order
                                        .order_instructions !== ''
                                        ? this.props.t('cart.edit')
                                        : this.props.t(
                                              'orderSummary.addSpecialInstructions'
                                          )}
                                </div>
                            </div>
                            <div className="">
                                {this.props.state.order.order_instructions !==
                                ''
                                    ? this.props.t(
                                          'orderSummary.orderInstructionsMessage',
                                          {
                                              instructions:
                                                  this.props.state.order
                                                      .order_instructions,
                                          }
                                      )
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        const doneTip = () => {
            this.props.history.push('/kiosk/paymentSelect');
            this.props.resetPaymentErrors();
        };

        const renderTipModal = () => {
            if (this.state.show_tip_modal) {
                return (
                    <TipModal
                        onComplete={doneTip}
                        onClose={() => {
                            this.setState({ show_tip_modal: false });
                        }}
                    />
                );
            }
            return <></>;
        };

        const renderDiscount = () => {
            const discountModal = () => {
                return (
                    <div className="kiosk-discount-modal">
                        <div className="kiosk-discount-modal-window">
                            <div className="kiosk-discount-modal-header">
                                <div className="kiosk-discount-modal-title">
                                    {this.props.t('cart.discountCode')}
                                </div>
                            </div>

                            <div className="kiosk-discount-modal-body">
                                <div className="kiosk-discount-modal-alert">
                                    {this.props.t(
                                        'orderSummary.discountCodeKeyboardPrompt'
                                    )}
                                </div>
                                <input
                                    type="password"
                                    readOnly
                                    className="kiosk-discount-modal-text-field"
                                    value={
                                        this.props.state.order
                                            .discount_code_field
                                    }
                                ></input>
                                <div className="kiosk-discount-modal-button-container">
                                    <div
                                        className="kiosk-discount-modal-button modal-button-red"
                                        onClick={() => {
                                            this.setState({
                                                show_discount_modal: false,
                                            });
                                        }}
                                    >
                                        {this.props.t('common.cancel')}
                                    </div>
                                    <div
                                        className="kiosk-discount-modal-button modal-button-blue"
                                        onClick={() => {
                                            this.validateCode();
                                        }}
                                    >
                                        {this.props.t('common.done')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="kiosk-discount-modal-keyboard-background"></div>
                        <div className="kiosk-discount-modal-keyboard">
                            <Keyboard
                                onChange={(val) => {
                                    this.props.updateDiscountCodeField(
                                        val.toUpperCase()
                                    );
                                }}
                            />
                        </div>
                    </div>
                );
            };

            let campaign_names = discount
                ? discount.campaign_instances.map((c) => c.name).join(',')
                : '';
            return (
                <>
                    {this.state.show_discount_modal ? discountModal() : <></>}
                    <div
                        className="to-page-review-items"
                        style={{ marginBottom: '15px' }}
                    >
                        <div className="kiosk-discount-row">
                            <div className="to-page-review-item-name">
                                {this.props.t('common.discount')}
                                {discount
                                    ? `: -$${discount.amount.toFixed(
                                          2
                                      )} (${campaign_names})`
                                    : ''}
                            </div>

                            <div
                                className="to-page-review-item-button"
                                onClick={() => {
                                    this.props.updateDiscountCodeField('');
                                    this.setState({
                                        show_discount_modal:
                                            !this.state.show_discount_modal,
                                    });
                                }}
                            >
                                {this.props.t('orderSummary.enterCode')}
                            </div>
                        </div>
                    </div>
                </>
            );
        };

        const renderOrder = () => {
            if (!Array.isArray(order)) {
                return;
            }

            const orders = order.map((item, item_index) => {
                let price = item.price_with_modifiers * item.multiplier;
                return (
                    <div
                        className="to-page-review-items"
                        style={{ marginBottom: '0px', marginTop: '15px' }}
                    >
                        <div
                            className="finalizeItemContainer to-page-review-item-container"
                            key={item.item_id}
                        >
                            <div className="finalizeItemRow">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="to-page-review-item-name">
                                        {item.multiplier} X {item.name}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="to-page-review-item-name">
                                        ${price.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginLeft: '40px' }}>
                                {item.special_instructions ? (
                                    <div className="finalizeItemModifier">
                                        {this.props.t('cart.orderNotes', {
                                            instructions:
                                                item.special_instructions,
                                        })}
                                    </div>
                                ) : (
                                    ''
                                )}
                                {renderModifiers(item.modifiers)}
                            </div>

                            <div className="to-page-review-item-button-container">
                                <div
                                    className="to-page-review-item-button"
                                    onClick={(e) => {
                                        this.props.deleteItem(item);
                                    }}
                                >
                                    {this.props.t('common.remove')}
                                </div>

                                <div
                                    className="to-quantity"
                                    style={{
                                        marginTop: '0px',
                                    }}
                                >
                                    <div
                                        className="to-quantity-minus"
                                        onClick={(e) => {
                                            this.props.finalizeOrderQuantityUpdated(
                                                item.multiplier - 1,
                                                item_index
                                            );
                                        }}
                                    >
                                        -
                                    </div>
                                    <div className="to-quantity-value to-page-review-quantity">
                                        {item.multiplier}
                                    </div>
                                    <div
                                        className="to-quantity-plus"
                                        onClick={(e) => {
                                            this.props.finalizeOrderQuantityUpdated(
                                                item.multiplier + 1,
                                                item_index
                                            );
                                        }}
                                    >
                                        +
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

            return (
                <>
                    {renderDiscount()}
                    {renderSpecialInstructions()}
                    {renderTipModal()}
                    {orders}
                </>
            );
        };

        const renderHalfMinicart = () => {
            const payment_totals = this.props.state.order.payment_totals;

            const tax = payment_totals.tax_amount
                ? payment_totals.tax_amount.toFixed(2)
                : '0.00';
            const cart_total = payment_totals.total_with_tax
                ? payment_totals.total_with_tax.toFixed(2)
                : '0.00';

            return (
                <div className="to-minicart-container">
                    <div
                        className="to-minicart-inner-container"
                        style={{ color: '#fff', flexDirection: 'column' }}
                    >
                        <div
                            className="to-minicart-my-order-summary"
                            style={{
                                marginTop: '0px',
                                minWidth: '100%',
                                textAlign: 'center',
                            }}
                        >
                            {this.props.t('common.tax')}: ${tax} | Total: $
                            {cart_total}
                        </div>

                        <div className="to-order-save">
                            <div
                                className="to-item-save-button to-order-save-button"
                                onTouchEnd={(e) => {
                                    setTimeout((e) => {
                                        this.props.history.push('/kiosk');
                                        this.props.resetPaymentErrors();
                                    }, 1);
                                }}
                                onMouseUp={() => {
                                    setTimeout((e) => {
                                        this.props.history.push('/kiosk');
                                        this.props.resetPaymentErrors();
                                    }, 1);
                                }}
                            >
                                {this.props.t('common.back')}
                            </div>
                            <div
                                className="to-item-save-button to-order-save-button"
                                onClick={() => {
                                    if (isGhostKitchens()) {
                                        doneTip();
                                    } else {
                                        this.setState({
                                            show_tip_modal: true,
                                        });
                                    }
                                }}
                            >
                                {this.props.t('cart.pay')}
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        const reader_error = this.props.state.kiosk.payment_error;

        return (
            <div className="to-page-container to-page-container-dark">
                <div className="to-page-inner-container to-page-inner-container-dark">
                    <img
                        alt="GK Logo"
                        src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                        className="to-page-review-logo"
                    ></img>
                    <div className="to-page-review-heading">
                        {this.props.t('orderSummary.reviewMyOrder')}
                    </div>

                    {reader_error ? (
                        <>
                            <div
                                style={{
                                    color: 'red',
                                    backgroundColor: 'white',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    fontSize: '30px',
                                    margin: '20px',
                                }}
                            >
                                {reader_error.error}
                            </div>
                            <div
                                style={{
                                    color: 'red',
                                    backgroundColor: 'white',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    fontSize: '15px',
                                    margin: '20px',
                                }}
                            >
                                {reader_error.error_details}
                            </div>
                        </>
                    ) : (
                        ''
                    )}

                    {checkPaymentTerminalConnected(this.props.state)
                        ? renderOrder()
                        : this.props.t(
                              'orderSummary.noPaymentTerminalConnected'
                          )}
                    <div style={{ padding: '30px' }}></div>
                    {renderHalfMinicart()}
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    finalizeOrderQuantityUpdated: OrderActions.finalizeOrderQuantityUpdated,
    resetPaymentErrors: OrderActions.resetPaymentErrors,
    deleteItem: OrderActions.deleteItem,
    updateDiscountCodeField: OrderActions.updateDiscountCodeField,
    validateCode: OrderActions.validateCode,
    updateOrder: OrderActions.updateOrder,
    updateSpecialInstructions: OrderActions.orderInstructionsChanged,
})(withTranslation()(OrderSummary));
