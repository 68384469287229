import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import RoundComponent from '../Components/RoundComponent';
import { createPath } from '../utils';
import './css/MenusPage.css';
import TopHeader from '../Components/TopHeader';
import { Container, IconButton, makeStyles } from '@material-ui/core';
import LoadingScreen from '../LoadingScreen';
import MiniCart from '../Components/MiniCart';
import { sortInScheduleMenus, splitBrands } from '../../Tablet/logos';
import PhoneIcon from '@material-ui/icons/Phone';
import { isValidPhoneNumber } from 'react-phone-number-input';
import amplitude from 'amplitude-js';
import DineInFab from '../Components/DineInFab';
import HealthAndSafetyForm from '../../../components/HealthAndSafetyForm';
import {
    isDineinOrder,
    isRestaurantClosed,
    isTakeoutOrder,
} from '../../../utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../../endpoints';

export default () => {
    const menus = useSelector((state) => state.order.menu.menus);
    const inScheduleMenus = useSelector(
        (state) => state.order.menu.inScheduleMenus
    );
    const showHealthAndSafety = useSelector(
        (state) => state.main.show_health_and_safety
    );
    const menuIsLoading = useSelector((state) => state.order.menu.is_loading);
    const { t } = useTranslation();
    const params = useParams();
    const endpointFromUrl = params.endpoint;
    const endpointFromCache = useSelector(
        (state) => state.main.restaurant_info.endpoint
    );
    const history = useHistory();
    const loadingMenus = !Array.isArray(menus);
    const restaurant = useSelector((state) => state.main.restaurant_info);
    let strippedPhoneNumber = restaurant.phone_number
        ?.replace(/-/g, '')
        ?.replace(/ /g, '');
    const isGhostKitchen = restaurant.name.includes('Ghost');

    const groupActiveAndInActiveMenus = sortInScheduleMenus(
        menus,
        inScheduleMenus
    );

    const [switchedStore] = useState(
        endpointFromCache && endpointFromCache !== endpointFromUrl
    );

    //  ! hide for temp, causes conflict
    // useEffect(() => {
    //     if (restaurant.state && isRestaurantClosed()) {
    //         toast.error(
    //             t('toast.closedRestaurant', {
    //                 restaurantName: restaurant.name,
    //             }),
    //             {
    //                 autoClose: false,
    //             }
    //         );
    //     }
    //     return () => {
    //         toast.dismiss();
    //     };
    // }, [restaurant]);

    const isDineIn = isDineinOrder();
    const isTakeOut = isTakeoutOrder();

    const isSwitchingStore = switchedStore && menuIsLoading;

    return (
        <>
            <TopHeader orderPage />

            {showHealthAndSafety ? <HealthAndSafetyForm /> : <></>}

            {loadingMenus || isSwitchingStore ? (
                <LoadingScreen />
            ) : (
                <Container maxWidth="xl" style={{ flex: '1' }}>
                    {isGhostKitchen ? (
                        <GKLogoAndPhone
                            strippedPhoneNumber={strippedPhoneNumber}
                        />
                    ) : (
                        <></>
                    )}
                    <div className="menuContainer">
                        {groupActiveAndInActiveMenus.map((menu) => {
                            const { menu_status } = menu;
                            let restaurantID = menu.restaurant_id;
                            const isMenuHidden = menu_status === 'HIDDEN';
                            let menuID = menu.menu_id;
                            const isComingSoon =
                                !menu.is_active && !isMenuHidden;
                            const isInSchedule = menu.in_schedule;
                            if (
                                isMenuHidden ||
                                (menu_status === 'TAKE_OUT_ONLY' && isDineIn) ||
                                (menu_status === 'DINE_IN_ONLY' && isTakeOut)
                            )
                                return null;
                            return (
                                <div data-cy="menu" key={menuID}>
                                    <RoundComponent
                                        onPress={() => {
                                            if (!isInSchedule) return;

                                            amplitude
                                                .getInstance()
                                                .logEvent(
                                                    'Menu Selected',
                                                    menu
                                                );
                                            if (!isComingSoon && isInSchedule) {
                                                history.push(
                                                    createPath('/v2/:/m/:/', [
                                                        endpointFromUrl,
                                                        menuID,
                                                    ])
                                                );
                                            }
                                        }}
                                        isComingSoon={isComingSoon}
                                        isInSchedule={isInSchedule}
                                        autoMarginRight
                                        image={`${cloudfrontBaseUrl}${restaurantID}/menus/${menuID}.jpeg`}
                                        label={menu.name}
                                        key={menuID}
                                        medium
                                    />
                                </div>
                            );
                        })}

                        <SassafrazWineList
                            show={
                                restaurant.restaurant_id ===
                                'c1216aa5-8fc7-11ea-b79c-0aea01d90f1c'
                            }
                        />
                    </div>
                </Container>
            )}
            <DineInFab />
            <MiniCart />
        </>
    );
};

const SassafrazWineList = (props) => {
    const link =
        'https://mentumqr.s3.amazonaws.com/misc/WINE_LIST_OCT_2021.pdf?v=1629480239202';
    if (!props.show) {
        return <></>;
    }
    return (
        <div data-cy="menu" key="sassafraz_menu">
            <RoundComponent
                onPress={() => {
                    amplitude
                        .getInstance()
                        .logEvent('Menu Selected', 'sassafraz-wine-list');
                    window.open(link, '_self');
                }}
                isComingSoon={false}
                autoMarginRight
                image={`https://14t7vz2358e44amevl4f4yrm-wpengine.netdna-ssl.com/wp-content/uploads/2018/11/SON_3442-2000x800.jpg`}
                label="Wine List"
                key="sassafraz_wine_list"
                medium
            />
        </div>
    );
};
const useStyles = makeStyles({
    root: {
        marginLeft: '15px',
        color: 'black',
        backgroundColor: '#dedede',
        height: '30px',
        width: '30px',
        '&:hover': {
            backgroundColor: '#d6d6d6',
        },
        '@media (min-width: 1024px)': {
            height: '50px',
            width: '50px',
        },
    },
    icon: {
        height: '20px',
        '@media (min-width: 1024px)': {
            height: '30px',
            width: '30px',
        },
    },
});

function GKLogoAndPhone(props) {
    const classes = useStyles();
    return (
        <div className="logoAndPhoneCallContainer">
            <img
                className="v2HeaderGKLogo"
                src="https://mentumqr.s3.amazonaws.com/gk-stuff/gk-logo-horizontal-light.svg"
                alt="Ghost Kitchens logo"
            />
            {isValidPhoneNumber(props.strippedPhoneNumber) ? (
                <IconButton
                    className={classes.root}
                    aria-label="call restaurant"
                >
                    <PhoneIcon
                        className={classes.icon}
                        onClick={() => {
                            window.open('tel:' + props.strippedPhoneNumber);
                        }}
                    />
                </IconButton>
            ) : (
                <></>
            )}
        </div>
    );
}
