import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import './css/main.css';

// actions
import * as OrderActions from '../../actions/orderActions';
import * as TableActions from '../../actions/tableActions';
import { withTheme } from '../../themes/theming';

// types
import { PaymentType } from '../../reducers/TYPES';
import PaySummary from '../Payment/PaySummary';
import { toast } from 'react-toastify';

import { OrderDateType } from '../../reducers/TYPES';
import {
    checkItemsAreAvailableAtSpecifiedTime,
    isAbsinthe,
    isFrontOfHouse,
    isPayAtCounterOnly,
} from '../../utils';
import { BiTimeFive } from 'react-icons/bi';
import { HiPlus } from 'react-icons/hi';
import { cloneDeep } from 'lodash';
import ReactModal from 'react-modal';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../locales';

class MenuItemDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_submitting: false,
            showDiscountCode: false,
            showRewardReminder: false,
            forceSubmit: false,
        };
    }

    validateCode() {
        this.props.validateCode(this.props.history);
        this.setState({ showDiscountCode: false });
    }

    componentDidMount() {
        this.props.clearItemToDelete();
        this.removeUneligibleItems();
    }

    componentDidUpdate() {
        this.removeUneligibleItems();
    }

    componentWillUnmount() {
        // toast.dismiss();
    }

    renderLoadingScreen = () => {
        if (!this.props.state.order.cart_updating) {
            return;
        }

        return (
            <div className="finalizeOrderLoading">
                <div className="twofa-loader twofa-loader__black" />
            </div>
        );
    };

    renderModifiers(modifiers) {
        if (!Array.isArray(modifiers)) {
            return;
        }
        return modifiers.map((modifier) => {
            const modifierPrice = modifier.price ? `$${modifier.price}` : '';

            return (
                <div
                    className="finalizeItemModifier"
                    key={modifier.modifier_id}
                >
                    {modifier.name} {modifierPrice}
                </div>
            );
        });
    }

    deleteCampaign(campaign_instance) {
        this.props.deleteCampaignInOrder(campaign_instance);
    }

    editItem(item, item_index) {
        let item_copy = { ...item };
        // append item index to copy
        item_copy.item_index = item_index;
        this.props.editItem(item_copy, this.props.history);
    }

    removeUneligibleItems = () => {
        const {
            campaign_instances,
            campaign_instances_in_cart,
            temporary_order,
        } = this.props.state.order;

        temporary_order.forEach((item) => {
            if (!item.campaign_instance_id) {
                return;
            }

            const campaign = campaign_instances.find(
                (instance) =>
                    instance.campaign_instance_id === item.campaign_instance_id
            );
            const campaignInCart = campaign_instances_in_cart.find(
                (instance) =>
                    instance.campaign_instance_id === item.campaign_instance_id
            );

            if (!campaign) {
                this.props.deleteItem(item, false);
                if (campaignInCart) {
                    this.props.deleteCampaignInOrder(campaignInCart);
                }
            }
        });
    };

    selectReward = (campaign_instance) => {
        const selectedCampaign = campaign_instance.campaign;
        if (!selectedCampaign) {
            console.error('cannot find the campaign instance');
            toast.error(i18n.t('toast.unavailableCampaign'));
        }
        const copy_selectedCampaign = { ...selectedCampaign };

        switch (campaign_instance.campaign.campaign_type) {
            case 'CAMPAIGN_SPEND_AND_GET':
                copy_selectedCampaign.discount =
                    copy_selectedCampaign.spend_and_get_campaign.discount;
                break;
            case 'CAMPAIGN_PURCHASE_AND_GET':
                copy_selectedCampaign.discount =
                    copy_selectedCampaign.purchase_and_get_campaign.discount;
                break;
            default:
                return;
        }

        copy_selectedCampaign.campaign_instance_id =
            campaign_instance.campaign_instance_id;
        copy_selectedCampaign.select_reward_item = true;
        copy_selectedCampaign.goBack = true;
        this.props.campaignSelected(copy_selectedCampaign, this.props.history);
    };

    editCampaign = (campaign_instance, campaign_index) => {
        const selectedCampaignInstance =
            this.props.state.order.campaign_instances_in_cart.find(
                ({ campaign_instance_id }) =>
                    campaign_instance_id ===
                    campaign_instance.campaign_instance_id
            );
        if (!selectedCampaignInstance) {
            toast.error(i18n.t('toast.editItemError'));
            return;
        }
        //append campaign index and instance to copy
        const selectedCampaign = selectedCampaignInstance.campaign;
        const selectedCampaign_copy = cloneDeep(selectedCampaign);
        selectedCampaign_copy.campaign_index = campaign_index;
        selectedCampaign_copy.goBack = true;
        selectedCampaign_copy.campaign_instance_id =
            campaign_instance.campaign_instance_id;
        this.props.editCampaign(selectedCampaign_copy, this.props.history);
    };

    clearDeleteItem() {
        this.props.clearItemToDelete();
    }

    renderOrder(order) {
        const { theme } = this.props;

        if (!Array.isArray(order)) {
            return;
        }
        return order.map((item, item_index) => {
            if (!item.campaign_instance_id) {
                let price = item.price_with_modifiers * item.multiplier;
                return (
                    <div className="finalizeItemContainer" key={item.item_id}>
                        <div className="finalizeItemRow">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className="finalizeItemLabel"
                                    style={theme.finalizeItemLabel}
                                >
                                    ({item.multiplier}) {item.name}
                                </div>
                                {/* <img
                                onClick={e => this.props.deleteItem(item)}
                                className="finalizeItemDelete" src="/assets/delete-item.png"></img> */}
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className="finalizeItemPrice"
                                    style={theme.finalizeItemPrice}
                                >
                                    {price.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div>
                            {item.special_instructions ? (
                                <div className="finalizeItemModifier">
                                    {this.props.t('order.orderNotes', {
                                        instructions: item.special_instructions,
                                    })}
                                </div>
                            ) : (
                                ''
                            )}
                            {this.renderModifiers(item.modifiers)}
                        </div>

                        <div className="finalizeItemOptions">
                            <div style={{ display: 'flex' }}>
                                <div
                                    className="finalizeItemOptions-delete"
                                    onClick={(e) => {
                                        this.props.deleteItem(item);
                                    }}
                                >
                                    {this.props.t('common.remove')}
                                </div>
                                <div
                                    className="finalizeItemOptions-delete"
                                    style={{ marginLeft: '10px' }}
                                    onClick={(e) => {
                                        this.editItem(item, item_index);
                                    }}
                                >
                                    {this.props.t('cart.edit')}
                                </div>
                            </div>

                            <div className="finalizeItemOptions-quantity">
                                <div
                                    className="finalizeItemOptions-quantity-minus"
                                    onClick={(e) => {
                                        this.props.finalizeOrderQuantityUpdated(
                                            item.multiplier - 1,
                                            item_index
                                        );
                                    }}
                                >
                                    -
                                </div>
                                <div className="finalizeItemOptions-quantity-value">
                                    {item.multiplier}
                                </div>
                                <div
                                    className="finalizeItemOptions-quantity-plus"
                                    onClick={(e) => {
                                        this.props.finalizeOrderQuantityUpdated(
                                            item.multiplier + 1,
                                            item_index
                                        );
                                    }}
                                >
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    }

    renderSAGCampaigns = () => {
        const { theme } = this.props;
        const { campaign_instances_in_cart } = this.props.state.order;
        if (!Array.isArray(campaign_instances_in_cart)) {
            return;
        }
        return campaign_instances_in_cart.map(
            (campaign_instance, campaign_index) => {
                if (
                    campaign_instance.campaign.campaign_type !==
                    'CAMPAIGN_SPEND_AND_GET'
                ) {
                    return null;
                }

                if (
                    campaign_instance.campaign.spend_and_get_campaign.discount
                        .discount_type !== 'DISCOUNT_ITEM'
                ) {
                    return null;
                }

                let price = 'FREE';
                if (campaign_instance.total_price > 0) {
                    price = campaign_instance.total_price.toFixed(2);
                }

                if (campaign_instance.campaign_instance_id) {
                    let items = null;
                    if (campaign_instance.items) {
                        items = campaign_instance.items.map((item, index) => {
                            return (
                                <div
                                    key={`${campaign_instance.campaign_instance_id}_item_${index}`}
                                >
                                    {item.name}
                                </div>
                            );
                        });
                    }
                    return (
                        <div
                            className="finalizeItemContainer"
                            key={campaign_instance.campaign_instance_id}
                        >
                            <div className="finalizeItemRow">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        className="finalizeItemLabel"
                                        style={theme.finalizeItemLabel}
                                    >
                                        {this.props.t('order.rewardCount', {
                                            campaignname:
                                                campaign_instance.campaign.name,
                                        })}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        className="finalizeItemPrice"
                                        style={theme.finalizeItemPrice}
                                    >
                                        {price}
                                    </div>
                                </div>
                            </div>
                            <div className="purchaseAndGetCampaignItems">
                                {items}
                            </div>

                            <div className="finalizeItemOptions">
                                <div style={{ display: 'flex' }}>
                                    <div
                                        className="finalizeItemOptions-delete"
                                        onClick={(e) => {
                                            this.deleteCampaign(
                                                campaign_instance
                                            );
                                        }}
                                    >
                                        {this.props.t('common.remove')}
                                        Remove
                                    </div>
                                    <div
                                        className="finalizeItemOptions-delete"
                                        style={{ marginLeft: '10px' }}
                                        onClick={(e) => {
                                            this.editCampaign(
                                                campaign_instance,
                                                campaign_index
                                            );
                                        }}
                                    >
                                        {this.props.t('common.change')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }

                return null;
            }
        );
    };

    renderPAGCampaigns = () => {
        const { theme } = this.props;
        const { campaign_instances_in_cart } = this.props.state.order;
        if (!Array.isArray(campaign_instances_in_cart)) {
            return <></>;
        }
        return campaign_instances_in_cart.map(
            (campaign_instance, campaign_index) => {
                if (
                    campaign_instance.campaign.campaign_type !==
                    'CAMPAIGN_PURCHASE_AND_GET'
                ) {
                    return <></>;
                }

                if (campaign_instance.campaign_instance_id) {
                    let items = null;
                    if (campaign_instance.items) {
                        items = campaign_instance.items.map((item, index) => {
                            return (
                                <div
                                    key={`${campaign_instance.campaign_instance_id}_item_${index}`}
                                >
                                    {item.name}
                                </div>
                            );
                        });
                    }
                    return (
                        <div
                            className="finalizeItemContainer"
                            key={campaign_instance.campaign_instance_id}
                        >
                            <div className="finalizeItemRow">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        className="finalizeItemLabel"
                                        style={theme.finalizeItemLabel}
                                    >
                                        {this.props.t('order.rewardCount', {
                                            campaignname:
                                                campaign_instance.campaign.name,
                                        })}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        className="finalizeItemPrice"
                                        style={theme.finalizeItemPrice}
                                    >
                                        {campaign_instance.total_price.toFixed(
                                            2
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="purchaseAndGetCampaignItems">
                                {items}
                            </div>

                            <div className="finalizeItemOptions">
                                <div style={{ display: 'flex' }}>
                                    <div
                                        className="finalizeItemOptions-delete"
                                        onClick={(e) => {
                                            this.deleteCampaign(
                                                campaign_instance
                                            );
                                        }}
                                    >
                                        {this.props.t('common.remove')}
                                    </div>
                                    <div
                                        className="finalizeItemOptions-delete"
                                        style={{ marginLeft: '10px' }}
                                        onClick={(e) => {
                                            this.editCampaign(
                                                campaign_instance,
                                                campaign_index
                                            );
                                        }}
                                    >
                                        {this.props.t('common.change')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }

                return <></>;
            }
        );
    };

    renderRewardItemPrompt = () => {
        const { campaign_instances, temporary_order } = this.props.state.order;
        return (
            <div className="rewardItemPromptContainer">
                {campaign_instances.map((campaign_instance) => {
                    const { campaign } = campaign_instance;
                    if (campaign.campaign_type !== 'CAMPAIGN_SPEND_AND_GET') {
                        return <></>;
                    }

                    if (
                        campaign.spend_and_get_campaign.discount
                            .discount_type !== 'DISCOUNT_ITEM'
                    ) {
                        return <></>;
                    }

                    //check if all reward items are chosen
                    let itemChosen = 0;
                    temporary_order.forEach((item) => {
                        if (
                            item.campaign_instance_id ===
                            campaign_instance.campaign_instance_id
                        ) {
                            itemChosen += 1;
                        }
                    });

                    if (itemChosen === 0) {
                        return (
                            <div
                                className="rewardItemPrompt"
                                key={campaign_instance.campaign_instance_id}
                                onClick={(e) =>
                                    this.selectReward(campaign_instance)
                                }
                            >
                                {this.props.t('order.selectReward', {
                                    campaignName: campaign.name,
                                })}
                            </div>
                        );
                    }

                    return <></>;
                })}
            </div>
        );
    };

    editRewardItem = (item) => {
        if (!item.campaign_instance_id) {
            return;
        }

        const { campaign_instances_in_cart } = this.props.state.order;
        let campaignIndex;
        const campaignInstanceToEdit = campaign_instances_in_cart.find(
            (instance, index) => {
                if (
                    instance.campaign_instance_id === item.campaign_instance_id
                ) {
                    campaignIndex = index;
                    return true;
                }
                return false;
            }
        );

        if (!campaignInstanceToEdit || campaignIndex == null) {
            return;
        }

        this.editCampaign(campaignInstanceToEdit, campaignIndex);
    };

    renderDiscounts = () => {
        const { campaign_instances } = this.props.state.order;
        const { theme } = this.props;
        return campaign_instances.map((campaign_instance) => {
            const { campaign } = campaign_instance;
            let discount;

            switch (campaign.campaign_type) {
                case 'CAMPAIGN_SPEND_AND_GET':
                    discount = campaign.spend_and_get_campaign.discount;
                    break;
                case 'CAMPAIGN_PURCHASE_AND_GET':
                    discount = campaign.purchase_and_get_campaign.discount;
                    break;
                case 'CAMPAIGN_DISCOUNTED_ENTITY':
                    discount = campaign.discounted_entity_campaign.discount;
                    break;
                default:
                    break;
            }

            if (!discount || (campaign_instance.discount || 0) <= 0) {
                return null;
            }

            switch (discount.discount_type) {
                case 'DISCOUNT_PERCENT':
                    return (
                        <div
                            className="finalizeItemContainer"
                            key={campaign_instance.campaign_instance_id}
                        >
                            <div className="finalizeItemRow">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        className="finalizeItemLabel"
                                        style={theme.finalizeItemLabel}
                                    >
                                        {this.props.t('order.discount', {
                                            campaignName:
                                                campaign_instance.campaign.name,
                                        })}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        className="finalizeItemPrice"
                                        style={theme.finalizeItemPrice}
                                    >{`- ${(
                                        campaign_instance.discount / 100
                                    ).toFixed(2)}`}</div>
                                </div>
                            </div>
                        </div>
                    );
                case 'DISCOUNT_CENT':
                    return (
                        <div
                            className="finalizeItemContainer"
                            key={campaign_instance.campaign_instance_id}
                        >
                            <div className="finalizeItemRow">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        className="finalizeItemLabel"
                                        style={theme.finalizeItemLabel}
                                    >
                                        {' '}
                                        {this.props.t('order.discount', {
                                            campaignName:
                                                campaign_instance.campaign.name,
                                        })}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        className="finalizeItemPrice"
                                        style={theme.finalizeItemPrice}
                                    >{`- ${(
                                        campaign_instance.discount / 100
                                    ).toFixed(2)}`}</div>
                                </div>
                            </div>
                        </div>
                    );
                case 'DISCOUNT_ITEM':
                    return null;
                case 'DISCOUNT_SPECIAL_PRICE':
                    return null;
                default:
                    return null;
            }
        });
    };

    goBack() {
        this.props.history.goBack();
    }
    orderMore() {
        this.props.history.push('/logoview');
    }

    payNow() {
        const rewardsClaimed = this.checkAllRewardsHaveClaimed();
        const forceSubmit = this.state.forceSubmit;

        if (!rewardsClaimed && !forceSubmit) {
            return;
        }

        // check if is_tablet
        const is_tablet = this.props.state.table.is_tablet;

        // check if items are available

        const can_continue = checkItemsAreAvailableAtSpecifiedTime();
        if (!can_continue && !isFrontOfHouse()) {
            return;
        }

        if (!this.props.state.main.is_active && !isFrontOfHouse()) {
            toast.dismiss('closedToast');
            toast.info(i18n.t('toast.closedMealPeriod2'), {
                toastId: 'closedToast',
            });
            return;
        }

        this.setState({
            is_submitting: true,
        });

        if (is_tablet) {
            this.props.getPaymentIntent_PayBefore(this.props.history);
            return;
        }

        if (isPayAtCounterOnly()) {
            this.props.sendToKitchen(
                this.props.history,
                '/guesthome',
                true,
                isAbsinthe()
            );
            return;
        }

        // Check payment type for path to continue on
        const paymentType = this.props.state.order.payment_type;
        switch (paymentType) {
            case PaymentType.PAY_LATER:
            case PaymentType.PAY_COUNTER_ONLY:
            case PaymentType.DISABLED:
                // for any of the above, send to kitchen
                this.props.sendToKitchen(this.props.history, '/guesthome');
                break;
            case PaymentType.PAY_IMMEDIATE:
                // 2FA path
                this.props.checkTwoFA(this.props.history);
                break;
            default:
                console.error('Invalid payment type!');
                break;
        }
    }

    renderActionButtons(order) {
        const paymentType = this.props.state.order.payment_type;

        const theme = this.props.theme;
        const hasOrders = order.length > 0;

        let render;

        let payNowLabel = this.props.state.table.is_tablet
            ? this.props.t('order.orderNowOption')
            : this.props.t('order.payNowOption');
        if (isPayAtCounterOnly()) {
            payNowLabel = this.props.t('order.payAtCounterOption');
        }

        const { total_with_tip_and_tax } =
            this.props.state.order.payment_totals;

        if (paymentType === PaymentType.PAY_IMMEDIATE) {
            render = (
                <div>
                    {hasOrders ? (
                        this.state.is_submitting ? (
                            <>
                                <div
                                    className="floatingButtonSafeArea"
                                    style={{
                                        ...theme.floatingButton,
                                        justifyContent: 'flex-start',
                                    }}
                                ></div>

                                <div
                                    className="floatingButton floatButtonHalfLeft"
                                    style={{
                                        ...theme.floatingButton,
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <img
                                        alt="Arrow"
                                        src="/assets/thin-right-arrow-white.png"
                                        className="floatButtonRightArrow"
                                        style={{
                                            transform: 'rotate(180deg)',
                                            width: 'auto',
                                        }}
                                    />
                                    <div
                                        className="floatingButtonLabel"
                                        style={theme.floatingButtonLabel}
                                    >
                                        {this.props.t('common.menu')}
                                    </div>
                                </div>
                                <div
                                    className="floatingButton"
                                    style={theme.floatingButton}
                                >
                                    <div
                                        className="floatingButtonLabel"
                                        style={theme.floatingButtonLabel}
                                    ></div>
                                    <div className="floatingButtonRight">
                                        <div className="twofa-loader">
                                            {this.props.t('order.loading')}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <div
                                        className="floatingButtonSafeArea"
                                        style={theme.floatingButton}
                                    ></div>

                                    <div
                                        className="floatingButton floatButtonHalfLeft"
                                        style={{
                                            ...theme.floatingButton,
                                            justifyContent: 'flex-start',
                                        }}
                                        onClick={(e) => {
                                            this.props.history.push(
                                                '/logoview'
                                            );
                                        }}
                                    >
                                        <img
                                            alt="Arrow"
                                            src="/assets/thin-right-arrow-white.png"
                                            className="floatButtonRightArrow"
                                            style={{
                                                transform: 'rotate(180deg)',
                                                width: 'auto',
                                            }}
                                        />
                                        <div
                                            className="floatingButtonLabel"
                                            style={theme.floatingButtonLabel}
                                        >
                                            {this.props.t('common.menu')}
                                        </div>
                                    </div>

                                    <div
                                        className="floatingButton floatingButtonHalfRight"
                                        onClick={(e) => this.payNow()}
                                        style={theme.floatingButton}
                                    >
                                        <div
                                            className="floatingButtonLabel"
                                            style={theme.floatingButtonLabel}
                                        >
                                            {payNowLabel}
                                        </div>
                                        <div className="floatingButtonRight">
                                            {total_with_tip_and_tax.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    ) : (
                        <div></div>
                    )}
                </div>
            );
        } else {
            render = (
                <div>
                    {hasOrders ? (
                        this.state.is_submitting ? (
                            <>
                                <div
                                    className="floatingButton floatButtonHalfLeft"
                                    style={{
                                        ...theme.floatingButton,
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <img
                                        alt="Arrow"
                                        src="/assets/thin-right-arrow-white.png"
                                        className="floatButtonRightArrow"
                                        style={{
                                            transform: 'rotate(180deg)',
                                            width: 'auto',
                                        }}
                                    />
                                    <div
                                        className="floatingButtonLabel"
                                        style={theme.floatingButtonLabel}
                                    >
                                        {this.props.t('common.menu')}
                                    </div>
                                </div>
                                <div
                                    className="floatingButton floatingButtonHalfRight"
                                    style={theme.floatingButton}
                                >
                                    <div
                                        className="floatingButtonLabel"
                                        style={theme.floatingButtonLabel}
                                    >
                                        {this.props.t('order.orderNowOption')}
                                    </div>
                                    <div className="floatingButtonRight">
                                        <div className="twofa-loader">
                                            {this.props.t('order.loading')}
                                        </div>
                                    </div>
                                    <div
                                        className="floatingButtonSafeArea"
                                        style={theme.floatingButton}
                                    ></div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className="floatingButton floatButtonHalfLeft"
                                    style={{
                                        ...theme.floatingButton,
                                        justifyContent: 'flex-start',
                                    }}
                                    onClick={(e) => {
                                        this.props.history.push('/logoview');
                                    }}
                                >
                                    <img
                                        alt="Arrow"
                                        src="/assets/thin-right-arrow-white.png"
                                        className="floatButtonRightArrow"
                                        style={{
                                            transform: 'rotate(180deg)',
                                            width: 'auto',
                                        }}
                                    />
                                    <div
                                        className="floatingButtonLabel"
                                        style={theme.floatingButtonLabel}
                                    >
                                        {this.props.t('common.menu')}
                                    </div>
                                </div>
                                <div
                                    className="floatingButton floatingButtonHalfRight"
                                    onClick={(e) => this.payNow()}
                                    style={theme.floatingButton}
                                >
                                    <div
                                        className="floatingButtonLabel"
                                        style={theme.floatingButtonLabel}
                                    >
                                        {this.props.t('order.orderNowOption')}
                                    </div>
                                    <div className="floatingButtonRight">
                                        <img
                                            alt="Arrow"
                                            src="/assets/thin-right-arrow-white.png"
                                            className="floatButtonRightArrow"
                                        />
                                    </div>
                                    <div
                                        className="floatingButtonSafeArea"
                                        style={theme.floatingButton}
                                    ></div>
                                </div>
                            </>
                        )
                    ) : (
                        ''
                    )}
                </div>
            );
        }

        return render;
    }

    isMenuOnlyMode() {
        return this.props.state.main.menu_only_mode;
    }

    hasPreviousCode() {
        let hasCode = false;
        if (this.props.state.table.guest_orders.campaigns) {
            this.props.state.table.guest_orders.campaigns.forEach(
                (campaignInstance) => {
                    if (campaignInstance.campaign.campaign_code.length > 0) {
                        hasCode = true;
                    }
                }
            );
        }

        return hasCode;
    }

    editTakeoutTime() {
        this.props.history.push('/order/setPickupTime');
    }

    renderOrderSpecialInstructions() {
        return (
            <div
                className="itemSpecialInstructionsContainer"
                style={{
                    margin: '0',
                    width: '100%',
                    marginTop: '40px',
                    marginBottom: '40px',
                }}
            >
                <div className="itemSpecialInstructionsLabel">
                    {this.props.t('order.specialInstructions')}
                </div>
                <input
                    className="itemSpecialInstructionsTextField"
                    value={this.props.state.order.order_instructions}
                    onChange={(e) =>
                        this.props.orderInstructionsChanged(e.target.value)
                    }
                />
            </div>
        );
    }

    checkAllRewardsHaveClaimed() {
        const { temporary_order, campaign_instances } = this.props.state.order;
        const SAGCampaignInstance = campaign_instances.filter(
            (campaignInstance) =>
                campaignInstance.campaign.campaign_type ===
                'CAMPAIGN_SPEND_AND_GET'
        );
        const SAGICampaignInstance = SAGCampaignInstance.filter(
            (campaignInstance) =>
                campaignInstance.campaign.spend_and_get_campaign.discount
                    .discount_type === 'DISCOUNT_ITEM'
        );

        const claimed = SAGICampaignInstance.every((campaignInstance) => {
            return temporary_order.find(
                (item) =>
                    item.campaign_instance_id ===
                    campaignInstance.campaign_instance_id
            );
        });

        this.setState({
            ...this.state,
            showRewardReminder: !claimed,
        });

        return claimed;
    }

    renderRewardReminder() {
        if (!this.state.showRewardReminder) {
            return;
        }

        const cancel = () => {
            this.setState({
                ...this.state,
                showRewardReminder: false,
            });
        };

        const submit = async () => {
            await this.setState({
                ...this.state,
                showRewardReminder: false,
                forceSubmit: true,
            });
            this.payNow();
        };

        return (
            <ReactModal
                isOpen={this.state.showRewardReminder}
                ariaHideApp={false}
                className="rewardItemReminderModal"
                overlayClassName="rewardItemReminderOverlay"
            >
                <p className="rewardReminderMessage">
                    {this.props.t('order.rewardItemsNotSelected')}
                </p>
                <div className="rewardReminderButtonContainer">
                    <button
                        className="rewardReminderButton rewardReminderButton--cancel"
                        onClick={() => cancel()}
                    >
                        {this.props.t('common.cancel')}
                    </button>
                    <button
                        className="rewardReminderButton rewardReminderButton--proceed"
                        onClick={() => submit()}
                    >
                        {this.props.t('common.submit')}
                    </button>
                </div>
            </ReactModal>
        );
    }

    renderNuveiWarning() {
        if (this.props.state.order.payment_processor !== 'NUVEI') {
            return;
        }

        return (
            <div className="finalizeNuveiWarning">
                {this.props.t('order.amexNotSupportedNotice')}
            </div>
        );
    }

    render() {
        // this.removeUneligibleItems();
        let order = this.props.state.order.temporary_order;
        const theme = this.props.theme;
        const paymentType = this.props.state.order.payment_type;
        const is_tablet = this.props.state.table.is_tablet;

        const is_takeout = paymentType === PaymentType.PAY_IMMEDIATE;
        const is_menu_only = this.isMenuOnlyMode();
        const hasOrders = order.length > 0;

        const discountCode = this.props.state.order.discount_code_field;

        const renderDiscountCode = (
            <div className="discountCodeContainer">
                <div className="discountCodePrompt">
                    <div className="discountCodePromptHeader">
                        {this.props.t('cart.discountCode')}
                    </div>
                    <div className="discountCodeLabel">
                        {this.props.t('order.enterDiscountCode')}
                    </div>
                    <input
                        className="discountCodeInput"
                        value={discountCode}
                        onChange={(e) => {
                            this.props.updateDiscountCodeField(
                                e.target.value.toUpperCase()
                            );
                        }}
                    ></input>
                    <div className="discountCodeError"></div>
                    <div
                        className="twoFA-button"
                        style={{ margin: '0' }}
                        onClick={(e) => {
                            this.validateCode();
                        }}
                    >
                        {this.props.t('common.submit')}
                    </div>
                    <div
                        onClick={(e) => {
                            this.setState({ showDiscountCode: false });
                        }}
                        className="twoFA-button twoFA-button-cancel"
                        style={{ margin: '0', marginTop: '10px' }}
                    >
                        {this.props.t('common.cancel')}
                    </div>
                </div>
            </div>
        );

        // render order time
        let orderTimeText = '';
        if (this.props.state.order.order_date_type === OrderDateType.NOW) {
            orderTimeText = 'Pickup Now';
        } else if (
            this.props.state.order.order_date_type === OrderDateType.LATER
        ) {
            const order_time = this.props.state.order.order_time;
            const parsedDate = new Date(order_time);

            orderTimeText = `${parsedDate.toLocaleString('en-US', {
                weekday: 'short',
                // month: 'short',
                // day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })}`;
        }

        return (
            <div>
                {this.renderLoadingScreen()}
                <Header back="/logoview" history={this.props.history} />

                <div className="finalizeOrderBackground">
                    <div className="finalizeOrderContainer">
                        <div
                            className="finalizeOrderTitle"
                            style={theme.orderTitle}
                        >
                            <img
                                alt="Bag"
                                className="finalizeOrderShoppingIcon"
                                src="/assets/bag-black.png"
                            ></img>
                            {this.props.t('order.checkout')}
                        </div>
                        {this.renderNuveiWarning()}
                        {this.renderRewardReminder()}
                        {this.renderRewardItemPrompt()}
                        {this.renderOrder(order)}
                        {this.renderPAGCampaigns()}
                        {this.renderSAGCampaigns()}
                        {this.renderDiscounts()}

                        {/* {hasOrders ? this.renderOrderSpecialInstructions() : ''} */}
                    </div>

                    {hasOrders && is_takeout && !is_menu_only && !is_tablet ? (
                        <div className="editTakeoutButtonContainer">
                            <div className="editTakoutLabel">
                                {this.props.t('order.orderForWhen')}
                            </div>
                            <div>
                                <div
                                    className="twoFA-button"
                                    onClick={(e) => this.editTakeoutTime()}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <BiTimeFive
                                            size="1.3em"
                                            style={{ marginRight: '10px' }}
                                        />
                                        {orderTimeText}
                                    </div>
                                    <div>
                                        <img
                                            alt="Arrow"
                                            className="rightArrow noselect"
                                            src="/assets/thin-right-arrow-white.png"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {!hasOrders ? (
                        <div className="finalizeNoOrdersContainer">
                            <div
                                className="finalizeNoOrdersPrompt"
                                style={theme.finalizeNoOrdersPrompt}
                            >
                                {this.props.t('cart.emptyCartMessage')}!
                            </div>
                            <div
                                className="finalizeAddItemsButton"
                                style={theme.finalizeAddItemsButton}
                                onClick={(e) => this.orderMore()}
                            >
                                {this.props.t('common.browseMenuOption')}
                            </div>
                        </div>
                    ) : is_menu_only ? (
                        <div className="finalizeOrderMessage">
                            {this.props.t('cart.contactStaffMember')}
                        </div>
                    ) : (
                        <></>
                    )}
                    {!this.isMenuOnlyMode() ? (
                        <div className="finalizeOrderPaySummaryContainer">
                            {paymentType === PaymentType.PAY_IMMEDIATE &&
                            hasOrders ? (
                                <PaySummary
                                    order={order}
                                    context="finalizeOrder"
                                ></PaySummary>
                            ) : (
                                ''
                            )}
                            {/* {this.renderActionButtons(order, paymentType)} */}
                            {/* <VerticalSpace size={1}></VerticalSpace> */}
                            {/* <Button text="Add additional notes" showArrow></Button> */}
                        </div>
                    ) : (
                        <></>
                    )}
                    {!this.isMenuOnlyMode() && !this.hasPreviousCode() ? (
                        <div className="finalizeOrderPaySummaryContainer">
                            {paymentType !== PaymentType.PAY_IMMEDIATE &&
                            hasOrders ? (
                                <>
                                    <div
                                        className="enterDiscountCodeButton"
                                        onClick={(e) => {
                                            this.setState({
                                                showDiscountCode:
                                                    !this.state
                                                        .showDiscountCode,
                                            });
                                        }}
                                    >
                                        <HiPlus
                                            style={{ marginRight: '10px' }}
                                        />
                                        {this.props.t('cart.enterDiscountCode')}
                                    </div>

                                    {this.state.showDiscountCode
                                        ? renderDiscountCode
                                        : ''}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div style={{ paddingBottom: '150px' }}></div>

                {!is_menu_only ? <>{this.renderActionButtons(order)}</> : ''}
            </div>
        );
    }
}

const MSTP = (state) => {
    return { state };
};

export default withTheme(
    connect(MSTP, {
        sendToKitchen: OrderActions.sendToKitchen,
        orderInstructionsChanged: OrderActions.orderInstructionsChanged,

        deleteItemStep1: OrderActions.deleteItemStep1,
        deleteItem: OrderActions.deleteItem,
        clearItemToDelete: OrderActions.clearItemToDelete,

        getPaymentIntent_PayBefore: OrderActions.getPaymentIntent_PayBefore,
        calculateTotals: OrderActions.calculateTotals,

        updateTheme: TableActions.updateTheme,

        payAtCounter: OrderActions.payAtCounter,

        checkTwoFA: OrderActions.checkTwoFA,

        finalizeOrderQuantityUpdated: OrderActions.finalizeOrderQuantityUpdated,

        editItem: OrderActions.editItem,

        campaignSelected: OrderActions.campaignSelected,
        editCampaign: OrderActions.editCampaign,
        deleteCampaignInOrder: OrderActions.deleteCampaignInOrder,
        validateCode: OrderActions.validateCode,
        updateDiscountCodeField: OrderActions.updateDiscountCodeField,
    })(withTranslation()(MenuItemDetail))
);
