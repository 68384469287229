import React, { useEffect } from 'react';
import { BASE_URL } from './endpoints';
import { persistor } from './reduxStore';
import { useDispatch, useSelector } from 'react-redux';
import { loadStandingTable, setVersion } from './actions/tableActions';
import { useHistory, useParams } from 'react-router-dom';
import { CLEAR_TEMPORARY_ORDERS } from './reducers/TYPES';

export const IFrameMessage = () => {
    const dispatch = useDispatch();
    const params = useParams<{ endpoint: string }>();
    const endpoint = params.endpoint;
    const history = useHistory();
    const affiliate = useSelector((state: any) => state.order.affiliate_code);

    useEffect(() => {
        window.top?.postMessage('connected', BASE_URL);
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    });

    const handleBeforeUnload = () => {
        window.top?.postMessage('disconnected', BASE_URL);
    };

    const handleMessage = async (event: MessageEvent) => {
        if (event.data === 'startReset') {
            dispatch(loadStandingTable(endpoint, history, affiliate, false));
            dispatch({
                type: CLEAR_TEMPORARY_ORDERS,
            });
            dispatch(setVersion(2));

            setTimeout(() => {
                window.top?.postMessage('resetFinished', BASE_URL);
            }, 500);
        }
    };

    return null;
};
