import React from 'react';
import { connect } from 'react-redux';
import './css/PayNuvei.css';
class PayNuvei extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iframe_url: '',
            times_refreshed: 0,
        };
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state) {
            const iframe_url = this.props.location.state.iframe_url;
            this.setState({
                iframe_url,
            });
        }
    }

    handleIframeEvent(evt) {
        try {
            // check that we're back on the same domain
            if (
                this.iframe.contentWindow.location.href.includes(
                    window && window.location && window.location.hostname
                )
            ) {
                // wait for payment to be verified
                if (
                    !this.iframe.contentWindow.location.href.includes(
                        'nuvei_confirmation'
                    )
                ) {
                    this.props.history.push('/orderComplete');
                }
            }
        } catch (err) {
            // if the site is cross-origin, we can assume it's still on nuvei
            console.log(err);
        }
    }

    render() {
        const { innerWidth, innerHeight } = window;
        return (
            <div>
                <iframe
                    title="Nuvei HPP"
                    src={this.state.iframe_url}
                    allowpaymentrequest="true"
                    seamless={true}
                    onLoad={(e) => this.handleIframeEvent(e)}
                    ref={(i) => {
                        this.iframe = i;
                        if (i) {
                            i.addEventListener('*', this.handleIframeEvent);
                        }
                    }}
                    width={innerWidth}
                    height={innerHeight}
                />
            </div>
        );
    }
}

export default connect((state) => ({ state }), {})(PayNuvei);
