import React from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Fab from '@material-ui/core/Fab';
import '../../css/ItemScreen.css';
import '../../css/ModifierSelect.css';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import 'react-lazy-load-image-component/src/effects/blur.css';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { getModifierMultiplier, sendUserBack } from '../utils';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useTranslation } from 'react-i18next';

export const FloatingActionButton = (props) => {
    const { clickCallback, shouldRender, editMode, itemOutOfStock, price } =
        props;
    const { t } = useTranslation();
    return itemOutOfStock ? (
        <div className="FabContainer" data-cy="floatingActionButton">
            <Fab
                variant="extended"
                size="large"
                color="disabled"
                aria-label="add"
            >
                <div className="FabText">{t('cart.soldOut')}</div>
            </Fab>
        </div>
    ) : shouldRender ? (
        <div className="FabContainer" data-cy="floatingActionButton">
            <Fab
                variant="extended"
                size="large"
                aria-label="add"
                onClick={clickCallback}
                style={{ backgroundColor: '#1C4155' }}
            >
                <div className="FabText">
                    {editMode ? t('cart.save') : t('cart.addToCart')}&nbsp; ($
                    {price})
                </div>
            </Fab>
        </div>
    ) : (
        <div className="FabContainer" data-cy="floatingActionButton">
            <Fab
                variant="extended"
                size="large"
                color="disabled"
                aria-label="add"
                style={{ backgroundColor: '#1C4155' }}
            >
                <div className="FabTextDisabled">
                    {editMode ? t('cart.save') : t('cart.addToCart')}&nbsp; ($
                    {price})
                </div>
            </Fab>
        </div>
    );
};

export const ItemSelectionRow = (props) => {
    const { clickCallback, header, tag } = props;

    return (
        <div
            className="ItemSubSelectionRow"
            onClick={clickCallback}
            data-cy="itemSubSelectionRow"
        >
            <div className="JustifiedRowEnds">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="SelectRowHeader">{header}</div>
                    <div className="SelectRowStatus">{tag}</div>
                </div>
                <div className="CenterVertically">
                    <ArrowRightIcon style={{ fontSize: 32 }} />
                </div>
            </div>
        </div>
    );
};

export const ModifierQuantity = (props) => {
    const {
        selectedModifiers,
        modifier,
        setSelectedModifiers,
        maxSelections,
        isKiosk,
        orderSelected,
    } = props;
    const quantity = getModifierMultiplier(selectedModifiers, modifier);
    const iconFontSize = isKiosk ? 36 : 24;

    // Ensure that the sum of all modifiers and quantities
    const modifiers_enabled = selectedModifiers;
    const num_selected_modifiers_in_group = modifiers_enabled.filter(
        (m) => m.modifier_group_id === modifier.modifier_group_id
    ).length;
    const selected_modifier_quantity = modifiers_enabled.filter(
        (m) => m.modifier_id === modifier.modifier_id
    ).length;

    const availableMaxSelections =
        maxSelections -
        num_selected_modifiers_in_group +
        selected_modifier_quantity;

    let previouslySelected = [];
    (orderSelected || []).forEach((selMod) => {
        if (selMod.modifier_group_id !== modifier.modifier_group_id) {
            previouslySelected.push(selMod);
        }
    });
    return (
        <div className={`ModifierQuantityContainer${isKiosk ? 'Kiosk' : ''}`}>
            <div className="CenterVertically">
                <RemoveCircleIcon
                    data-cy="removeQuantityButton"
                    color={isKiosk ? '' : 'disabled'}
                    style={{ fontSize: iconFontSize, cursor: 'pointer' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        let newSelectedModifiers = selectedModifiers.filter(
                            (selMod) =>
                                selMod.modifier_id !== modifier.modifier_id
                        );
                        for (let i = 0; i < quantity - 1; i++) {
                            newSelectedModifiers.push(modifier);
                        }
                        setSelectedModifiers(
                            newSelectedModifiers,
                            previouslySelected
                        );
                    }}
                />
            </div>
            <div className="CenterVertically">
                <div
                    className={`ModifierQuantityText${isKiosk ? 'Kiosk' : ''}`}
                >
                    {quantity}
                </div>
            </div>
            <div className="CenterVertically">
                <AddCircleIcon
                    color={isKiosk ? '' : 'disabled'}
                    style={{ fontSize: iconFontSize, cursor: 'pointer' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (quantity < availableMaxSelections) {
                            let newSelectedModifiers = selectedModifiers.filter(
                                (selMod) =>
                                    selMod.modifier_id !== modifier.modifier_id
                            );
                            for (let i = 0; i < quantity + 1; i++) {
                                newSelectedModifiers.push(modifier);
                            }
                            setSelectedModifiers(
                                newSelectedModifiers,
                                previouslySelected
                            );
                        }
                    }}
                    data-cy="addQuantityButton"
                />
            </div>
        </div>
    );
};

export const ItemQuantity = (props) => {
    const { order, setStateQuantity } = props;
    let quantity = order.selected_item_quantity;
    //const [quantity, setQuantity] = useState(initialVal);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            <div className="CenterVertically">
                <RemoveCircleIcon
                    data-cy="removeQuantityButton"
                    color="disabled"
                    style={{ fontSize: 48, cursor: 'pointer' }}
                    onClick={() => {
                        if (quantity > 1) {
                            setStateQuantity(quantity - 1);
                            quantity -= 1;
                        }
                    }}
                />
            </div>
            <div className="CenterVertically">
                <div className="ItemQuantityText" data-cy="itemQuantity">
                    {quantity}
                </div>
            </div>
            <div className="CenterVertically">
                <AddCircleIcon
                    color="disabled"
                    style={{ fontSize: 48, cursor: 'pointer' }}
                    onClick={() => {
                        setStateQuantity(quantity + 1);
                        quantity += 1;
                    }}
                    data-cy="addQuantityButton"
                />
            </div>
        </div>
    );
};

export const SelectPageHeader = (props) => {
    const {
        condition,
        text,
        clickedSaveCallback,
        history,
        previousPath,
        urlParams,
    } = props;
    return (
        <div className="HeaderContainer">
            <div className="CenterVertically">
                <ArrowLeftIcon
                    data-cy="selectHeaderBack"
                    style={{ fontSize: 32, color: 'white' }}
                    onClick={() => {
                        sendUserBack(history, previousPath, urlParams);
                    }}
                />
            </div>
            <div className="CenterVertically">
                <div className="HeaderText">{text}</div>
            </div>
            <div className="CenterVertically" data-cy="modifierSaveButton">
                {condition ? (
                    <div
                        className="HeaderConfirmTextWhite"
                        onClick={clickedSaveCallback}
                    >
                        {'Done'}
                    </div>
                ) : (
                    <div className="HeaderConfirmTextDisabled">{'Done'}</div>
                )}
            </div>
        </div>
    );
};

export const ModifierSelectIcon = (props) => {
    const { isSelected, max_qty, inStock } = props;
    const opacity = inStock ? 1 : 0.3;
    if (max_qty !== 1) {
        return isSelected ? (
            <CheckBoxIcon style={{ fontSize: 25 }} />
        ) : (
            <CheckBoxOutlineBlankIcon style={{ fontSize: 25, opacity }} />
        );
    }
    return isSelected ? (
        <RadioButtonCheckedIcon style={{ fontSize: 25 }} />
    ) : (
        <RadioButtonUncheckedIcon style={{ fontSize: 25, opacity }} />
    );
};

export const SelectPageSubHeader = (props) => {
    const { sub_header } = props;
    return <div className="SubHeaderContainerText">{sub_header}</div>;
};
