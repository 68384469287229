import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Spacing, StoreHighlight, SpacingAmount } from '.';

export default () => {
    return (
        <div className="v3HeroContainer v3SectionContainer">
            <div className="v3HeroSplit">
                <div className="v3HeroTextSide">
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="v3HeroText">
                        The Most Seamless Ghost Kitchen POS System
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="v3HeroDescription">
                    Experience an all-in-one ecosystem to run your cloud kitchen with Mentum - sell multiple brands on all the delivery platforms, custom kiosks, and your website from one system.
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Link to="/contact">
                        <Button text="Get started with us" showChevron />
                    </Link>
                </div>
                <div className="v3HeroSplitSide">
                    <div className='v3StoreHighlightSpacing'/>
                    <StoreHighlight />
                </div>
            </div>
            <Spacing amount={SpacingAmount.Spacing60} />
            <div className="v3ContentContainer">
                <div className="v3CenteredTagline">PARTNERS</div>
                <div className="v3CenteredHeader">Brands we power</div>
                <div className="v3BrandsList">
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo1.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo2.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo3.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo4.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo5.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo6.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo7.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo8.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo9.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo10.png"
                    />
                </div>
            </div>
            <Spacing amount={SpacingAmount.Spacing38} />
        </div>
    );
};
