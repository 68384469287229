import { useEffect } from 'react';
import { ENV } from '../endpoints';

export const useDisableConsole = () => {
    useEffect(() => {
        if (ENV === 'production') {
            console.log = function () {};
            console.error = function () {};
            console.warn = function () {};
            console.info = function () {};
            console.debug = function () {};
        }
    }, []);
};
