import React, { Component } from 'react';
import { connect } from 'react-redux';
import './css/OneCampaign.css';
import Header from '../../components/Header';
import * as OrderActions from '../../actions/orderActions';
import FinalizeOrderButton from '../Menu/components/FinalizeOrderButton';
import { withTheme } from '../../themes/theming';
import { toast } from 'react-toastify';
import CampaignItemDetail from './CampaignItemDetail';
import ReactModal from 'react-modal';
import { i18n } from '../../locales';
import { withTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../endpoints';

export class OneCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //constants
            selectedCampaignType: '',
            selectedCampaignDiscountType: '',
            campaignBody: {},
            itemCount: '',
            rewardCount: '',
            totalCount: '',

            //variables
            itemSelectionCompleted: false,
            itemClicked: {},
            itemGroupClicked: {
                itemGroup: [],
                itemGroupIndex: '',
            },
            itemGroupModalIsOpen: false,
            itemModalIsOpen: false,
        };
    }

    componentDidMount() {
        let selectedCampaign = this.props.state.order.selected_campaign;
        const selectedCampaignType = selectedCampaign.campaign_type;

        switch (selectedCampaignType) {
            case 'CAMPAIGN_PURCHASE_AND_GET':
                selectedCampaign = selectedCampaign.purchase_and_get_campaign;
                break;
            case 'CAMPAIGN_SPEND_AND_GET':
                selectedCampaign = selectedCampaign.spend_and_get_campaign;
                break;
            case 'CAMPAIGN_DISCOUNTED_ENTITY':
                selectedCampaign = selectedCampaign.discounted_entity_campaign;
                break;
            default:
                return;
        }

        const itemCount = selectedCampaign.count || 0;
        const rewardCount = selectedCampaign.discount.count || 0;
        const totalCount = itemCount + rewardCount;

        this.setState({
            ...this.state,
            campaignBody: selectedCampaign,
            itemCount,
            rewardCount,
            totalCount,
        });
        this.props.campaignItemSelected(new Array(totalCount));
    }

    renderCampaign() {
        const selectedCampaign = this.props.state.order.selected_campaign;

        //if the campaign is for SAG reward item selection:
        if (selectedCampaign.select_reward_item) {
            let addToCartButton;
            if (this.state.itemSelectionCompleted) {
                //for edit campaign
                if (selectedCampaign.campaign_index != null) {
                    addToCartButton = (
                        <button
                            className="campaignButton"
                            onClick={(e) => {
                                this.campaignEditSaved();
                            }}
                        >
                            {this.props.t('oneCampaign.saveCampaign')}
                        </button>
                    );
                } else {
                    addToCartButton = (
                        <button
                            className="campaignButton"
                            onClick={(e) => {
                                this.rewardItemAdded();
                            }}
                        >
                            {this.props.t('oneCampaign.addToCart')}
                        </button>
                    );
                }
            } else {
                addToCartButton = (
                    <button
                        className="campaignButton campaignButtonDisabled"
                        disabled
                    >
                        {this.props.t('oneCampaign.pleaseSelectItems')}
                    </button>
                );
            }
            return (
                <div className="oneCampaignInteractiveComponents">
                    {this.renderRewardItems()}
                    <div className="campaignButtonContainer">
                        {addToCartButton}
                    </div>
                </div>
            );
        }

        //if the campaign is for SAG reward item selection:
        const selectedCampaignType = selectedCampaign.campaign_type;
        switch (selectedCampaignType) {
            case 'CAMPAIGN_SPEND_AND_GET':
                return this.renderSpendAndGetCampaign();
            case 'CAMPAIGN_PURCHASE_AND_GET':
                return this.renderPurchaseAndGetCampaign();
            case 'CAMPAIGN_DISCOUNTED_ENTITY':
                return this.renderDiscountCampaign();
            default:
                toast.error(i18n.t('toast.promotionUnavailable'));
                return;
        }
    }

    renderSpendAndGetCampaign() {
        const campaign = this.props.state.order.selected_campaign;
        const { discount_type } = campaign.discount;
        let message;
        if (
            discount_type === 'DISCOUNT_ITEM' ||
            discount_type === 'DISCOUNT_SPECIAL_PRICE'
        ) {
            message = <p>{this.props.t('oneCampaign.autoAddedPromotion')}</p>;
        } else if (
            discount_type === 'DISCOUNT_CENTS' ||
            discount_type === 'DISCOUNT_PERCENT'
        ) {
            message = <p> {this.props.t('oneCampaign.autoAppliedDiscount')}</p>;
        }
        return (
            <div className="campaignButtonContainer">
                {message}
                <button
                    className="campaignButton"
                    onClick={() => {
                        this.props.history.push('/logoview');
                    }}
                >
                    {this.props.t('oneCampaign.exploreMenus')}
                </button>
            </div>
        );
    }

    renderDiscountCampaign = () => {
        return (
            <div className="campaignButtonContainer">
                <p> {this.props.t('oneCampaign.autoAppliedDiscount')}</p>
                <button
                    className="campaignButton"
                    onClick={() => {
                        this.props.history.push('/logoview');
                    }}
                >
                    {this.props.t('oneCampaign.exploreMenus')}
                </button>
            </div>
        );
    };

    renderPurchaseAndGetCampaign() {
        const campaign = this.props.state.order.selected_campaign;
        const { item_groups, category_ids, menu_ids } =
            campaign.purchase_and_get_campaign;
        const { discount_type } = campaign.purchase_and_get_campaign.discount;

        if (!(item_groups || category_ids || menu_ids)) {
            toast.error(i18n.t('toast.promotionItemsLoadError'));
            return;
        }

        let campaignItemGroups = [];
        //if the users are choosing from items, find the full item objects
        if (Array.isArray(item_groups)) {
            const allItems = this.props.state.order.menu.items;
            item_groups.forEach((itemGroup, index) => {
                if (Array.isArray(itemGroup.item_ids)) {
                    campaignItemGroups[index] = itemGroup.item_ids.map(
                        (itemId) => {
                            //
                            const campaignItem = allItems.find(
                                ({ item_id }) => item_id === itemId
                            );
                            if (!campaignItem) {
                                return null;
                            }
                            return campaignItem;
                        }
                    );
                }
            });
        } else {
            toast.error(i18n.t('toast.unavailableCampaign'));
        }

        let message = null;
        if (
            discount_type === 'DISCOUNT_PERCENT' ||
            discount_type === 'DISCOUNT_CENT'
        ) {
            message = <p> {this.props.t('oneCampaign.autoAppliedDiscount')}</p>;
        }

        let addToCartButton;
        if (this.state.itemSelectionCompleted) {
            //for edit campaign
            if (campaign.campaign_index != null) {
                addToCartButton = (
                    <button
                        className="campaignButton"
                        onClick={(e) => {
                            this.campaignEditSaved();
                        }}
                    >
                        {this.props.t('oneCampaign.saveCampaign')}
                    </button>
                );
            } else {
                addToCartButton = (
                    <button
                        className="campaignButton"
                        onClick={(e) => {
                            this.addToCartPressed();
                        }}
                    >
                        {this.props.t('oneCampaign.addToCart')}
                    </button>
                );
            }
        } else {
            addToCartButton = (
                <button
                    className="campaignButton campaignButtonDisabled"
                    disabled
                >
                    {this.props.t('oneCampaign.pleaseSelectItems')}
                </button>
            );
        }

        return (
            <div className="oneCampaignInteractiveComponents">
                {this.renderCampaignItemGroups(campaignItemGroups)}
                {this.renderRewardItems()}
                <div className="campaignButtonContainer">
                    {message}
                    {addToCartButton}
                </div>
            </div>
        );
    }

    closeItemGroupModal = () => {
        this.setState({
            ...this.state,
            itemGroupModalIsOpen: false,
        });
    };

    closeItemModal = () => {
        this.setState({
            ...this.state,
            itemModalIsOpen: false,
        });
    };

    closeAllModals = () => {
        this.setState({
            ...this.state,
            itemGroupModalIsOpen: false,
            itemModalIsOpen: false,
        });
    };

    renderItemGroupModal = () => {
        const { itemGroupClicked, itemGroupModalIsOpen } = this.state;

        if (!itemGroupClicked || !itemGroupModalIsOpen) {
            return;
        }

        const { itemGroup, itemGroupIndex } = itemGroupClicked;

        return (
            <ReactModal
                isOpen={itemGroupModalIsOpen}
                ariaHideApp={false}
                className="campaignItemGroupModal"
                overlayClassName="none"
                shouldCloseOnOverlayClick={true}
            >
                <div
                    className="campaignModalCloseButton"
                    onClick={() => this.closeItemGroupModal()}
                >
                    &times;
                </div>
                <div className="campaignModalDescription">
                    <h1> {this.props.t('oneCampaign.selectItem')}</h1>
                    <p>{this.props.t('oneCampaign.selectOneItem')}</p>
                </div>
                {this.renderCampaignItems(itemGroup, itemGroupIndex, true)}
            </ReactModal>
        );
    };

    renderCampaignItemGroups = (campaignItemGroups, reward = false) => {
        // const selectedCampaign = this.props.state.order.selected_campaign;
        const message = reward
            ? this.props.t('oneCampaign.chooseRewardItems')
            : this.props.t('oneCampaign.chooseItems');
        return (
            <div className="chooseItemContainer">
                <p className="chooseItemMessage">{message}</p>
                {campaignItemGroups.map((campaignItemGroup, index) => {
                    let itemGroupIndex = index;
                    let message = '';

                    if (reward) {
                        message = `${this.props.t(
                            'oneCampaign.selectFreeItem'
                        )} ${index + 1}`;
                        itemGroupIndex += this.state.itemCount;
                    } else {
                        message = `${this.props.t('oneCampaign.selectItem')} ${
                            index + 1
                        }`;
                    }

                    if (
                        this.props.state.order.selected_campaign_items[
                            itemGroupIndex
                        ]
                    ) {
                        message =
                            this.props.state.order.selected_campaign_items[
                                itemGroupIndex
                            ].name;
                    }

                    return (
                        <div
                            className="itemSelectionBlock"
                            key={`campaignItemGroupBlock_${index}`}
                            onClick={() =>
                                this.campaignItemGroupClicked(
                                    campaignItemGroup,
                                    itemGroupIndex
                                )
                            }
                        >
                            <div className="itemSelectionTitle">{message}</div>
                            <div className="itemSelectionIconContainer">
                                <img
                                    alt=""
                                    src="/assets/icons/edit 1.png"
                                    className="itemSelectionIcon"
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderCampaignItems = (campaignItemGroup, itemGroupNumber, showPrice) => {
        const theme = this.props.theme;

        const renderItemThumbnail = (item) => {
            const restaurant_id =
                this.props.state.main.restaurant_info.restaurant_id;
            let s3ImageUrl = `${cloudfrontBaseUrl}${restaurant_id}/items/${item.item_id}.jpeg`;

            return (
                <div
                    className="campaignItemFlexImage"
                    style={{ backgroundImage: `url(${s3ImageUrl})` }}
                >
                    <img
                        alt=""
                        className="campaignItemFlexImage"
                        src={s3ImageUrl}
                        style={{ visibility: 'hidden' }}
                    ></img>
                </div>
            );
        };

        return campaignItemGroup.map((campaignItem) => {
            // Check if campaignItem is defined before accessing its properties
            if (!campaignItem) {
                return null; // or handle accordingly
            }

            // chop the description
            let description = campaignItem.description || '';
            if (description && description.length > 70) {
                description = `${campaignItem.description.substring(0, 70)}...`;
            }

            // set price to 0 if price is missing
            if (!campaignItem.price) {
                campaignItem.price = 0;
            }

            const priceTag = showPrice ? (
                <div className="campaignItemPrice" style={theme.itemPrice}>
                    {(campaignItem.price / 100).toFixed(2)}
                </div>
            ) : (
                ''
            );

            const item = (
                <div
                    className="campaignItemContainer"
                    key={campaignItem.item_id}
                    onClick={(e) => {
                        this.campaignItemClicked(campaignItem, itemGroupNumber);
                    }}
                >
                    <div className="campaignItemDetailsColumn">
                        <div className="campaignItemHeader">
                            <div
                                className="campaignItemName"
                                style={theme.menuItemLabel}
                            >
                                {campaignItem.name}{' '}
                                {!campaignItem.is_in_stock ? '(Sold Out)' : ''}
                            </div>
                        </div>
                        <div
                            className="campaignItemDescription"
                            style={theme.itemDescription}
                        >
                            {description}
                        </div>
                        {priceTag}
                    </div>
                    <div className="campaignItemRight">
                        {renderItemThumbnail(campaignItem)}
                    </div>
                </div>
            );

            return item;
        });
    };

    campaignItemGroupClicked = (campaignItemGroup, index) => {
        this.setState({
            ...this.state,
            itemGroupClicked: {
                itemGroup: campaignItemGroup,
                itemGroupIndex: index,
            },
            itemGroupModalIsOpen: true,
        });
    };

    campaignItemClicked = (campaignItem, itemGroupNumber) => {
        this.props.menuItemSelected(campaignItem, this.props.history, false);
        this.setState({
            ...this.state,
            itemClicked: campaignItem,
            itemModalIsOpen: true,
        });
    };

    renderCampaignItemModal = () => {
        const { selected_campaign } = this.props.state.order;
        const { itemModalIsOpen } = this.state;
        const { itemGroupIndex } = this.state.itemGroupClicked;
        const { discount_type } = selected_campaign.discount;
        const showPrice =
            discount_type === 'DISCOUNT_SPECIAL_PRICE' ? false : true;

        return (
            <ReactModal
                isOpen={itemModalIsOpen}
                ariaHideApp={false}
                className="campaignItemModalContainer"
                overlayClassName="campaignItemModalOverlay"
                shouldCloseOnOverlayClick={true}
            >
                <img
                    className="campaignModalGoBackButton"
                    alt="Back"
                    src="/assets/thin-back-btn.png"
                    onClick={() => this.closeItemModal()}
                />
                <CampaignItemDetail
                    itemGroupNumber={itemGroupIndex}
                    showPrice={showPrice}
                    campaignItemSelectedHandler={() =>
                        this.campaignItemSelectedHandler()
                    }
                    itemSelectionCheck={() => this.itemSelectionCheck()}
                    closeItemModal={() => this.closeItemModal()}
                    closeItemGroupModal={() => this.closeItemGroupModal()}
                    closeAllModal={() => this.closeAllModals()}
                />
            </ReactModal>
        );
    };

    itemSelectionCheck = () => {
        const { selected_campaign_items } = this.props.state.order;
        const { totalCount } = this.state;

        let itemSelectionCompleted = true;
        let index;
        for (index = 0; index < totalCount; index++) {
            if (!selected_campaign_items[index]) {
                itemSelectionCompleted = false;
            }
        }

        this.setState({
            ...this.state,
            itemSelectionCompleted,
        });
    };

    renderRewardItems = () => {
        const { selected_campaign } = this.props.state.order;
        let campaign;
        switch (selected_campaign.campaign_type) {
            case 'CAMPAIGN_PURCHASE_AND_GET':
                campaign = selected_campaign.purchase_and_get_campaign;
                break;
            case 'CAMPAIGN_SPEND_AND_GET':
                campaign = selected_campaign.spend_and_get_campaign;
                break;
            default:
                return;
        }
        const discountType = campaign.discount.discount_type;
        const count = campaign.discount.count;
        const itemGroups = campaign.discount.items;

        if (discountType === 'DISCOUNT_ITEM' && itemGroups) {
            const rewardItemGroups = new Array(count);
            const allItems = this.props.state.order.menu.items;
            itemGroups.forEach((itemGroup, index) => {
                if (Array.isArray(itemGroup.items)) {
                    rewardItemGroups[index] = itemGroup.items.map((item) => {
                        //find the item object detail
                        const campaignItem = allItems.find(
                            ({ item_id }) => item_id === item.item_id
                        );
                        return campaignItem;
                    });
                }
            });
            return this.renderCampaignItemGroups(rewardItemGroups, true);
        }
    };

    campaignItemSelectedHandler = () => {
        const { selected_campaign_items } = this.props.state.order;

        //check if add to cart button should be enabled
        const { totalCount } = this.state;
        let itemSelectionCompleted = true;
        let index;
        for (index = 0; index < totalCount; index++) {
            if (!selected_campaign_items[index]) {
                itemSelectionCompleted = false;
            }
        }

        //close all modals
        this.setState({
            ...this.state,
            itemSelectionCompleted,
            itemGroupModalIsOpen: false,
            itemModalIsOpen: false,
        });
    };

    addToCartPressed = () => {
        this.props.addCampaignToOrder(this.props.history);
        this.props.campaignItemSelected([]);
    };

    rewardItemAdded = () => {
        this.props.rewardItemAdded(this.props.history);
        this.props.campaignItemSelected([]);
    };

    campaignEditSaved = () => {
        this.props.saveEditedCampaign(this.props.history);
        this.props.campaignItemSelected([]);
    };

    render() {
        const { restaurant_id } = this.props.state.main.restaurant_info;
        const is_tablet = this.props.state.table.is_tablet;
        let headerBackLink = is_tablet ? '' : '/campaigns';
        const logoViewHeaderUrl = `${cloudfrontBaseUrl}${restaurant_id}/misc/landing-page.jpeg`;
        const selectedCampaign = this.props.state.order.selected_campaign;
        const description = selectedCampaign?.description || '';

        if (selectedCampaign.goBack) {
            headerBackLink = ':goBack';
        }

        return (
            <div>
                <Header
                    back={headerBackLink}
                    history={this.props.history}
                    showGuestName
                />
                <div className="oneCampaignPage">
                    <div
                        className="logoViewHeaderImage"
                        style={{ backgroundImage: `url(${logoViewHeaderUrl})` }}
                    />
                    <img
                        alt=""
                        src={logoViewHeaderUrl}
                        className="logoViewHeaderExpansion"
                    />
                    <div className="oneCampaignContainer">
                        <div className="campaignInfo">
                            <h1 className="campaignTitle">
                                {selectedCampaign.name}
                            </h1>
                            <p className="campaignDescription">{description}</p>
                        </div>
                        {this.renderCampaign()}
                        {this.renderItemGroupModal()}
                        {this.renderCampaignItemModal()}
                    </div>
                </div>
                <FinalizeOrderButton history={this.props.history} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ state });

export default withTranslation()(
    withTheme(
        connect(mapStateToProps, {
            menuItemSelected: OrderActions.menuItemSelected,
            campaignItemSelected: OrderActions.campaignItemSelected,
            addCampaignToOrder: OrderActions.addCampaignToOrder,
            saveEditedCampaign: OrderActions.saveEditedCampaign,
            rewardItemAdded: OrderActions.rewardItemAdded,
        })(OneCampaign)
    )
);
