import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    InputLabel,
    Select,
    MenuItem,
    Badge,
    Tooltip,
    makeStyles,
    withStyles,
    ClickAwayListener,
} from '@material-ui/core';
import amplitude from 'amplitude-js';
import { FaShoppingCart } from 'react-icons/fa';

import './css/MiniCart.css';
import { createPath } from '../utils';
import * as OrderActions from '../../../actions/orderActions';
import { PaymentType } from '../../../reducers/TYPES';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutline, CheckOutlined } from '@material-ui/icons';

const AddToOrderNotif = () => {
    const dispatch = useDispatch();
    return (
        <div
            className="mini-cart-add-to-order-notif"
            onClick={(e) => {
                dispatch(OrderActions.v2HideTapToCheckout());
                e.stopPropagation();
            }}
            data-cy="addToCartNotif"
        >
            <img
                className="mini-cart-ato-prompt"
                src="/assets/icons/tapToCheckout.svg"
                alt="Tap to checkout icon"
            />
        </div>
    );
};

const ReadyToPayNotif = (props) => {
    const dispatch = useDispatch();
    return (
        <div
            className="mini-cart-add-to-order-notif mini-cart-ready-to-pay"
            onClick={(e) => {
                dispatch(OrderActions.v2ShowReadyToPay(false));
                e.stopPropagation();
            }}
            data-cy="addToCartNotif"
        >
            <img
                className="mini-cart-rtp-prompt"
                src="/assets/icons/ready-to-pay.png"
                alt="Ready to pay tutorial"
            />
        </div>
    );
};

const HtmlTooltip = withStyles((theme) => ({
    arrow: {
        '&::before': {
            color: 'white',
        },
    },
    tooltip: {
        backgroundColor: 'white',
        color: '#000',
        borderRadius: '8px',
        padding: '15px 10px',
    },
}))(Tooltip);

export default () => {
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const endpoint = params.endpoint;
    const address = useSelector((state) => state.main.restaurant_info.address);
    const name = useSelector((state) => state.main.restaurant_info.name);
    const { t } = useTranslation();
    const order = useSelector((state) => state.order.temporary_order);

    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
    const guest_existing_order = useSelector(
        (state) => state.table.guest_orders.items
    );
    const isDineIn =
        useSelector((state) => state.order.payment_type) !==
        PaymentType.PAY_IMMEDIATE;

    let num_orders = order.length;
    if (isDineIn && guest_existing_order) {
        num_orders += guest_existing_order.length;
    }

    const showAddToOrderNotif = useSelector(
        (state) => state.order.display_tutorial_1
    );
    const showReadyToPayNotif = useSelector(
        (state) => state.order.display_tutorial_2
    );

    return (
        <>
            <div className="mini-cart-fade"></div>
            <div
                className="mini-cart"
                onClick={() => {
                    amplitude.getInstance().logEvent('Checkout Cart');
                    history.push(createPath('/v2/:/cart', [endpoint]));
                    dispatch(OrderActions.v2HideTapToCheckout());
                }}
                data-cy="minicart"
            >
                {/* {showAddToOrderNotif ? <AddToOrderNotif /> : null} */}
                {showReadyToPayNotif ? <ReadyToPayNotif /> : null}
                {/* <AddToOrderNotif /> */}
                <div className="mini-cart-inner-container">
                    <div className="mini-cart-form">
                        <InputLabel
                            style={{
                                color: 'rgba(255, 255, 255, 0.8)',
                                fontSize: '0.75rem',
                                textTransform: 'uppercase',
                            }}
                        >
                            {t('common.order')}
                        </InputLabel>
                        <Select
                            style={{
                                color: 'white',
                                fontWeight: '700',
                                fontSize: '13px',
                                borderBottom: '1px solid rgba(0, 0, 0, 0)',
                            }}
                            value="0"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <MenuItem style={{ fontWeight: '700' }} value="0">
                                {address
                                    ? address
                                    : name
                                    ? name
                                    : t('order.loading')}
                            </MenuItem>
                        </Select>
                    </div>
                    <HtmlTooltip
                        color="white"
                        placement="top-end"
                        arrow
                        title={<TooltipContent />}
                        open={showAddToOrderNotif}
                        onClose={(e) => {
                            console.log('runing');
                            e.stopPropagation();
                            e.preventDefault();
                            dispatch(OrderActions.v2HideTapToCheckout());
                        }}
                    >
                        <div
                            className="mini-cart-icon-wrapper"
                            data-cy="minicart-button"
                        >
                            <Badge
                                badgeContent={num_orders}
                                color="secondary"
                                style={{ color: 'white' }}
                            >
                                <ClickAwayListener
                                    onClickAway={(e) => {
                                        e.stopPropagation();

                                        dispatch(
                                            OrderActions.v2HideTapToCheckout()
                                        );
                                    }}
                                >
                                    <FaShoppingCart />
                                </ClickAwayListener>
                            </Badge>
                        </div>
                    </HtmlTooltip>
                </div>
            </div>
        </>
    );
};

function TooltipContent() {
    const { t } = useTranslation();
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <CheckCircleOutline
                color="primary"
                style={{ fill: 'green' }}
                fontSize="medium"
            />
            <p
                style={{
                    fontSize: '16px',
                    marginLeft: '10px',
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    color: '#008000',
                }}
            >
                {t('common.addedToCart')}
            </p>
        </div>
    );
}
