import React from 'react';
import Spacing, { SpacingAmount } from './Spacing';
import '../Pages/LandingV3.css';

export default () => {
    return (
        <div>
            <Spacing amount={SpacingAmount.Spacing38} />
            <div className="v3IntegrationsContainer">
                <img
                    className="v3IntegrationPartner"
                    src="/assets/landing/v3/integrations/doordash.png"
                />
                <img
                    className="v3IntegrationPartner"
                    src="/assets/landing/v3/integrations/grubhub.png"
                />
                <img
                    className="v3IntegrationPartner"
                    src="/assets/landing/v3/integrations/postmates.png"
                />
                <img
                    className="v3IntegrationPartner"
                    src="/assets/landing/v3/integrations/skip.png"
                />
                <img
                    className="v3IntegrationPartner"
                    src="/assets/landing/v3/integrations/uber.png"
                />
                <img
                    className="v3IntegrationPartner"
                    src="/assets/landing/v3/integrations/more.png"
                />
            </div>
        </div>
    );
};
