import default_theme from './default';
import { cloudfrontBaseUrl } from '../endpoints';

const theme = {
    ...default_theme,

    // GUEST HOME OPTIONAL LOGO
    optionalHomeHeaderUrl: `${cloudfrontBaseUrl}}2993daf5-c798-11ea-b545-02685a4295ea/misc/Ethos_GreekBistro-19-of-44-scaled.jpg`,
    optionalHomeHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    optionalHomeHeaderImg: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: '50% 50%',
    },

    customDineSelect: {
        height: '80px',
        paddingBottom: '0',
        marginBottom: '30px',
    },

    floatingButton: {
        backgroundColor: '#60673D',
    },

    finalizeAddItemsButton: {
        border: '2px solid #60673D',
        color: '#60673D',
    },

    // MUILTIPLIER
    itemMultiplierContainer: {
        borderColor: '#60673D',
    },
    itemMultiplierLeft: {
        borderRightColor: '#60673D',
        color: '#60673D',
    },
    itemMultiplierRight: {
        borderLeftColor: '#60673D',
        color: '#60673D',
    },
    itemMultiplierText: {
        color: '#60673D',
    },
};

export default theme;
