import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeTip, updateTip } from '../../../../actions/orderActions';
import { OtherTipOption } from './OtherTipOption';
import { TipType, OptionChangeHandler } from './types';

export const tipBtnClass =
    'w-auto !px-3 !py-2 rounded justify-center items-center text-center flex text-sm font-medium leading-3';

const TipOption = () => {
    const dispatch = useDispatch();
    const restaurantInfo = useSelector(
        (state: any) => state.main.restaurant_info
    );
    const orderId = useSelector(
        (state: any) => state.table.guest_orders.order_id
    );
    const tipType = useSelector((state: any) =>
        state.main.restaurant_info.tip_type === 'TIP_TYPE_PERCENTAGE'
            ? 'percentage'
            : 'fixed'
    );
    const selectedTipType = useSelector(
        (state: any) => state.order.payment_tip_type
    );
    const selectedOption = useSelector((state: any) => {
        const tip = state.order.payment_tip;

        if (selectedTipType === 'fixed') {
            return tip;
        }

        return tip * 100;
    });
    const {
        total_with_tax,
        net_total,
        discount,
        tip_amount,
        total_with_tip_and_tax,
    } = useSelector((state: any) => state.order.payment_totals);

    const { tip_enabled, tip_options, tip_platform, tip_type } = restaurantInfo;

    const tipEnabled = tip_enabled ?? true;
    // const tipOptions: number[] = tip_options ?? [];
    const tipOptions = useMemo<number[]>(() => {
        if (tip_type === 'TIP_TYPE_FIXED') {
            return tip_options.map((option: number) => option / 100);
        }

        return tip_options;
    }, [tip_options]);
    // const tipPlatformEnabled = tip_platform?.length === 0 || tip_platform?.includes('TIP_PLATFORM_WEB');
    // const [tipType, setTipType] = useState(
    //     ['TIP_TYPE_NONE', 'TIP_TYPE_PERCENTAGE']?.includes(tip_type) ? 'percent' : 'fixed'
    // );

    // const [newTipType, setNewTipType] = useState(tipType)
    // const [selectedOption, setSelectedOption] = useState<number>(0);

    useEffect(() => {
        if (orderId) {
            dispatch(updateTip(selectedOption / 100));
        }
    }, [orderId]);

    const handleOptionChange: OptionChangeHandler = (option, type) => {
        let tipAmount = Number(option);

        if (option <= 0) {
            dispatch(changeTip(tipAmount, type));
            return;
        }

        if (type === 'percentage') {
            tipAmount /= 100;
        }

        dispatch(changeTip(tipAmount, type));
    };

    const isOtherOptionActive =
        (selectedOption && !tipOptions?.includes(Number(selectedOption))) ||
        tipType !== selectedTipType;

    return (
        <div className="flex items-center !gap-3">
            {/* TODO: add tipEnabled && */}
            {tipOptions?.map((option, index) => (
                <button
                    key={index}
                    onClick={() => handleOptionChange(option, tipType)}
                    className={clsx(
                        tipBtnClass,
                        option === selectedOption && tipType === selectedTipType
                            ? 'bg-[#0D785D] text-white'
                            : 'bg-[#F0F0F2] text-black'
                    )}
                >
                    {`${tipType !== 'percentage' ? '$' : ''}${option}${
                        tipType === 'percentage' ? '%' : ''
                    }`}
                </button>
            ))}

            <OtherTipOption
                isActive={isOtherOptionActive}
                onOptionChange={handleOptionChange}
            />
        </div>
    );
};

export { TipOption };
