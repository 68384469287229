import React from 'react';

export enum SpacingAmount {
    Spacing38 = 'spacing38',
    Spacing60 = 'spacing60',
    Spacing128 = 'spacing128',
}

interface SpacingProps {
    amount: SpacingAmount;
}

export default (props: SpacingProps) => {
    return <div className={props.amount}></div>;
};
