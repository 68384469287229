import React from 'react';
import { connect } from 'react-redux';
import stripeTerminal from '../../../StripeTerminal';
import { isDevEnvironment } from '../../../utils';
import * as OrderActions from '../../../actions/orderActions';

const KIOSK_PASSWORD = '2020';

class SelectReaderTerminal extends React.Component {
    componentDidMount() {
        this.props.loadNuveiTerminals();
    }

    constructor(props) {
        super(props);

        this.state = {
            show_password_input: !isDevEnvironment(),
            password: '',
            password_error: '',
        };
    }

    checkPassword() {
        if (this.state.password === KIOSK_PASSWORD) {
            this.setState({
                show_password_input: false,
            });
        } else {
            this.setState({
                password_error: 'Incorrect',
            });
        }
    }

    renderPassword() {
        return (
            <div className="passwordContainer">
                <div>
                    <button
                        className="backToOrderingButton"
                        onClick={(e) => {
                            this.props.history.goBack();
                        }}
                    >
                        Back to Ordering
                    </button>
                </div>

                <div className="passwordLabel">Please enter password</div>
                <div>
                    <input
                        type="password"
                        className="passwordInput"
                        onChange={(e) => {
                            this.setState({ password: e.target.value });
                        }}
                        value={this.state.password}
                    ></input>
                </div>

                <div>{this.state.password_error}</div>

                <div>
                    <button
                        className="passwordSubmitButton"
                        onClick={(e) => {
                            this.checkPassword();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.show_password_input) {
            return this.renderPassword();
        }

        const is_reader_connecting = this.props.state.kiosk.is_connecting;
        const connection_error =
            Object.keys(this.props.state.kiosk.connection_error).length > 0
                ? this.props.state.kiosk.connection_error
                : '';
        const availableReaders = stripeTerminal.listAvailableReaders();
        const availableNuveiReaders =
            this.props.state.kiosk.nuvei_payment_terminals;

        const connected_reader = stripeTerminal.getConnectedReader() || {};

        return (
            <div className="kioskTerminalSetup">
                <div className="kioskTerminalSetupInnerContainer">
                    <h1>Terminal Reader Registration</h1>
                    {availableReaders.map((reader, reader_index) => {
                        const isTerminalConnected =
                            connected_reader.serial_number ===
                                reader.serial_number &&
                            stripeTerminal.isReaderConnected();
                        const connectionLabel =
                            is_reader_connecting &&
                            is_reader_connecting === reader.serial_number
                                ? 'Connecting...'
                                : 'Connect';

                        return (
                            <div
                                className="kioskTerminalReaderRow"
                                key={`reader-${reader_index}`}
                            >
                                <div>
                                    <h3>Reader #{reader_index + 1}</h3>
                                </div>
                                <div>{reader.label}</div>
                                <div>
                                    Device: {reader.device_type}
                                    <br></br>
                                    ID: {reader.id}
                                    <br></br>
                                    Location: {reader.location}
                                    <br></br>
                                    SN#: {reader.serial_number}
                                    <br></br>
                                    IP Address: {reader.ip_address}
                                    <br></br>
                                    "Object": {reader.object}
                                </div>
                                <div>{reader.status}</div>
                                <div
                                    className="kiskoTerminalConnectButton"
                                    style={{
                                        backgroundColor: isTerminalConnected
                                            ? 'rgb(0, 114, 19)'
                                            : '#222',
                                    }}
                                    onClick={(e) => {
                                        console.log(reader);
                                        if (!isTerminalConnected) {
                                            stripeTerminal.connectToReader(
                                                reader
                                            );
                                        }
                                    }}
                                >
                                    {isTerminalConnected
                                        ? 'Connected!'
                                        : connectionLabel}
                                </div>
                            </div>
                        );
                    })}

                    {availableNuveiReaders.map((reader, reader_index) => {
                        let isTerminalSelected = false;
                        if (this.props.state.kiosk.nuvei_selected_terminal) {
                            isTerminalSelected =
                                reader.terminal_id ===
                                this.props.state.kiosk.nuvei_selected_terminal
                                    .terminal_id;
                        }
                        const connectionLabel =
                            is_reader_connecting &&
                            is_reader_connecting === reader.serial_number
                                ? 'Connecting...'
                                : 'Connect';
                        const isOffline = !reader.is_online;
                        return (
                            <div
                                className="kioskTerminalReaderRow"
                                key={`reader-nuvei-${reader_index}`}
                            >
                                <div>
                                    <h3>Nuvei: {reader.kiosk_id}</h3>
                                </div>
                                <div>{reader.label}</div>
                                <div>Terminal Id: {reader.terminal_id}</div>
                                <div>
                                    Online: {reader.is_online ? 'Yes' : 'No'}
                                </div>
                                <div
                                    className="kiskoTerminalConnectButton"
                                    style={{
                                        backgroundColor: isTerminalSelected
                                            ? 'rgb(0, 114, 19)'
                                            : '#222',
                                    }}
                                    onClick={(e) => {
                                        // select nuvei terminal, disconect from stripe terminal
                                        this.props.selectNuveiTerminal(reader);
                                        stripeTerminal.disconnectFromReader();
                                    }}
                                >
                                    {isTerminalSelected
                                        ? `Connected${
                                              isOffline ? ' (Offline)' : ''
                                          }`
                                        : connectionLabel}
                                </div>
                            </div>
                        );
                    })}

                    <div
                        className="kiskoTerminalConnectButton"
                        onClick={(e) => {
                            stripeTerminal.disconnectFromReader();
                            this.props.selectNuveiTerminal({});
                        }}
                        style={{
                            marginTop: '30px',
                            backgroundColor: '#222',
                        }}
                    >
                        Disconnect
                    </div>

                    <div
                        className="kiskoTerminalConnectButton"
                        onClick={(e) => {
                            stripeTerminal.loadReaders();
                            this.props.loadNuveiTerminals();
                        }}
                        style={{
                            marginTop: '30px',
                            backgroundColor: 'rgba(0,0, 155 ,0.8)',
                        }}
                    >
                        Refresh Readers
                    </div>

                    <div
                        className="kiskoTerminalConnectButton"
                        onClick={(e) => {
                            this.props.history.push('/kiosk/brands');
                        }}
                        style={{
                            marginTop: '30px',
                        }}
                    >
                        Close
                    </div>
                    {connection_error ? (
                        <div
                            style={{
                                margin: '20px',
                                fontSize: '40px',
                                color: 'red',
                            }}
                        >
                            {connection_error.message}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    loadNuveiTerminals: OrderActions.loadNuveiTerminals,
    selectNuveiTerminal: OrderActions.selectNuveiTerminal,
})(SelectReaderTerminal);
