import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FiSearch } from 'react-icons/all';
import React from 'react';
import { createPath } from '../utils';

export default () => {
    const history = useHistory();
    let endpoint = useParams().endpoint;

    return (
        <IconButton
            edge="end"
            onClick={() => {
                history.push(createPath('/v2/:/search', [endpoint]));
            }}
            data-cy="searchButton"
        >
            <FiSearch color="#121212" />
        </IconButton>
    );
};
