import React from 'react';
import {
    Header,
    Footer,
    Hero,
    Spacing,
    SpacingAmount,
    Tabbed,
    Tab,
    Integrations,
    Testimonials,
    Button,
} from '../components';
import './LandingV3.css';
import '../fonts.css';
import { Link } from 'react-router-dom';

const tabs: Tab[] = [
    {
        label: 'Self-serve Kiosks',
        render: () => (
            <div className="v3ProductSplitView">
                <div className="v3ProductSplitViewSide v3ProductSplitViewRight">
                    <img
                        src="/assets/landing/v3/kiosk-product.png"
                        className="v3LandingProductImage"
                        style={{ width: 192 }}
                    />
                </div>
                <div className="v3ProductSplitViewSide">
                    <div className="v3LandingProductTagline">KIOSK</div>
                    <div className="v3LandingProductName mobileWidth">
                        Higher revenue, lower costs
                    </div>
                    <div className="v3Landingv3LandingProductDescription">
                        With our highest quality customer support and most
                        advanced ordering systems, your modern QSR brand demands
                        a modern QSR partner.
                    </div>
                    <Link to="/product/kiosk">
                        <div className="v3LandingProductLink">
                            Learn more{' '}
                            <img
                                src="/assets/landing/v3/link-chevron.svg"
                                className="v3LandingProductChevron"
                            />
                        </div>
                    </Link>
                </div>
            </div>
        ),
    },
    {
        label: 'Mobile Ordering',
        render: () => (
            <div className="v3ProductSplitView">
                <div className="v3ProductSplitViewSide v3ProductSplitViewRight">
                    <img
                        src="/assets/landing/v3/qr-product.png"
                        className="v3LandingProductImage"
                    />
                </div>
                <div className="v3ProductSplitViewSide">
                    <div className="v3LandingProductTagline">
                        MOBILE ORDERING
                    </div>
                    <div className="v3LandingProductName">
                        The future of ordering
                    </div>
                    <div className="v3Landingv3LandingProductDescription">
                        The industry's most seamless digital ordering tool. -in,
                        pick-up and delivery, for your QSR, Hotel, Bar, Club, or
                        Food Truck.
                    </div>
                    <Link to="/product/qr">
                        <div className="v3LandingProductLink">
                            Learn more{' '}
                            <img
                                src="/assets/landing/v3/link-chevron.svg"
                                className="v3LandingProductChevron"
                            />
                        </div>
                    </Link>
                </div>
            </div>
        ),
    },
    {
        label: 'Delivery Aggregation',
        render: () => (
            <div className="v3ProductSplitView">
                <div className="v3ProductSplitViewSide v3ProductSplitViewRight">
                    <img
                        src="/assets/landing/v3/delivery-product.png"
                        className="v3LandingProductImage"
                        style={{ width: 235 }}
                    />
                </div>
                <div className="v3ProductSplitViewSide">
                    <div className="v3LandingProductTagline">
                        DELIVERY AGGREGATION
                    </div>
                    <div className="v3LandingProductName">
                        All Your Delivery Platforms In One Place
                    </div>
                    <div className="v3Landingv3LandingProductDescription">
                        Instantly edit menus on all your delivery platforms,
                        change stock status, and get your analytics all from a
                        single dashboard or integrate directly into your POS.
                    </div>
                    <Link to="/product/delivery">
                        <div className="v3LandingProductLink">
                            Learn more{' '}
                            <img
                                src="/assets/landing/v3/link-chevron.svg"
                                className="v3LandingProductChevron"
                            />
                        </div>
                    </Link>
                </div>
            </div>
        ),
    },
    {
        label: 'Data-driven Insights',
        render: () => (
            <div className="v3ProductSplitView">
                <div className="v3ProductSplitViewSide v3ProductSplitViewRight">
                    <img
                        src="/assets/landing/v3/dashboard-product.png"
                        className="v3LandingProductImage"
                        style={{ width: 235 }}
                    />
                </div>
                <div className="v3ProductSplitViewSide">
                    <div className="v3LandingProductTagline">
                        DATA-DRIVEN INSIGHTS
                    </div>
                    <div className="v3LandingProductName">
                        Future-proof your store
                    </div>
                    <div className="v3Landingv3LandingProductDescription">
                        The most customer and waiter centric table and hotel
                        ordering system in the industry. Order, call for the
                        waiter, and securely pay digitally.
                    </div>
                    <Link to="/product/dinein">
                        <div className="v3LandingProductLink">
                            Learn more{' '}
                            <img
                                src="/assets/landing/v3/link-chevron.svg"
                                className="v3LandingProductChevron"
                            />
                        </div>
                    </Link>
                </div>
            </div>
        ),
    },
];

export default () => {
    return (
        <div>
            <Header />
            <Hero />

            <div className="v3SectionContainer">
                <div className=" v3HomepageBackground">
                    <div className="v3ContentContainer">
                        <Spacing amount={SpacingAmount.Spacing60} />
                        <div className="v3CenteredHeader">
                            Restaurant and Retail
                        </div>
                        <Spacing amount={SpacingAmount.Spacing38} />
                        <div className="v3CenteredDescription">
                            See how we can help you achieve greater restaurant
                            efficiency, customer satisfaction, and capture more
                            loyal customers with our suite of products and
                            digital solutions.
                        </div>
                        <Spacing amount={SpacingAmount.Spacing38} />
                        <Tabbed tabs={tabs} />
                        <Spacing amount={SpacingAmount.Spacing128} />
                        <div className="v3CenteredHeader">
                            POS Delivery Integrations
                        </div>
                        <Spacing amount={SpacingAmount.Spacing38} />
                        <div className="v3CenteredDescription">
                            All your ordering and technology working together
                            seamlessly. We integrate with dozens of POS systems
                            for seamless onboarding and the biggest delivery
                            platforms so all your orders are in one place.
                        </div>
                        <Integrations />
                        <Spacing amount={SpacingAmount.Spacing128} />

                        <div className="v3CenteredHeader">
                            Customer Testimonials
                        </div>
                        <Spacing amount={SpacingAmount.Spacing38} />
                        <Testimonials />
                        <Spacing amount={SpacingAmount.Spacing128} />
                        <Spacing amount={SpacingAmount.Spacing60} />
                    </div>
                    <div className="v3ReadyToGetStartedContainer ReadySectionBackground">
                        <Spacing amount={SpacingAmount.Spacing38} />
                        <div className="v3ReadyHeader">
                            Ready to get started?
                        </div>
                        <Spacing amount={SpacingAmount.Spacing38} />

                        <div className="v3CenteredDescription">
                            Get in touch and set up a free demo of how our
                            products can future proof your operations.
                        </div>
                        <Spacing amount={SpacingAmount.Spacing38} />
                        <Link to="/contact">
                            <Button text="Book a Demo" />
                        </Link>
                        <Spacing amount={SpacingAmount.Spacing128} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
