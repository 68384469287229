import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Button from '../../components/Button';

import * as OrderActions from '../../actions/orderActions';

const INQUIRIES = {
    call: 'Call waiter to table',
    refill_drinks: 'Refill beverages',
    water: 'Refil water',
    room_service: 'Call room service',
    towels: 'Order towels',
};

class Concierge extends React.Component {
    callWaiter(inquiry) {
        this.props.callWaiter(inquiry, this.props.history);
    }

    render() {
        return (
            <div>
                <Header
                    back=":goBack"
                    history={this.props.history}
                    showGuestName
                />

                <Button
                    text="Call room service"
                    action={() => this.callWaiter(INQUIRIES['room_service'])}
                    showArrow
                />
                <Button
                    text="Order towels"
                    action={() => this.callWaiter(INQUIRIES['towels'])}
                    showArrow
                />
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    callWaiter: OrderActions.callWaiter,
})(Concierge);
