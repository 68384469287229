import { Box } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createPath } from '../utils';
import './css/CampaignBlock.css';
import { useTranslation } from 'react-i18next';
import { FaImage } from 'react-icons/fa';

export default (props) => {
    let campaigns = useSelector((state) => state.order.menu.campaigns);

    return (
        <div className="v2CampaignBlockContainer" style={{ margin: '80px 0' }}>
            {campaigns.map((campaign, c_index) => (
                <CampaignCard {...campaign} key={c_index} />
            ))}
        </div>
    );
};

function CampaignCard(props) {
    const { restaurant_id, campaign_id, name } = props;
    const n = new Date().getTime();
    const params = useParams();
    const history = useHistory();
    const { t } = useTranslation();
    const [isImageError, setIsImageError] = React.useState(false);

    const navToCampaign = (campaign) => {
        const path = createPath('/v2/:/campaign/:', [
            params.endpoint,
            campaign.campaign_id,
        ]);
        history.push(path);
    };

    const imageUrl = `https://mentum-restaurants.s3.amazonaws.com/${restaurant_id}/campaigns/${campaign_id}.jpeg?ver=${n}`;

    const handleImageError = (event) => {
        setIsImageError(true);
        // event.target.src =
        //     'https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image-768x518.jpg';
    };

    return (
        <Box m={1}>
            <div
                className="v2CampaignBlock"
                onClick={() => navToCampaign(props)}
            >
                <div className="v2CampaignBlockInnerContainer">
                    <div
                        style={{
                            width: '100%',
                            height: 'calc(100% - 54px)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {isImageError ? (
                            <FaImage size={50} />
                        ) : (
                            <img
                                src={imageUrl}
                                onError={handleImageError}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                    </div>
                    <div>
                        <h4 className="v2CampaignBlockLabel">
                            {t('common.promo')}
                        </h4>
                        <p className="v2CampaignBlockName">{name}</p>
                    </div>
                </div>
            </div>
        </Box>
    );
}
