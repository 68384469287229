import React from 'react';
import { connect } from 'react-redux';
import * as OrderActions from '../../actions/orderActions';
import Upsell from './components/Upsell';
import { withTranslation } from 'react-i18next';

class Minicart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_upsell_modal_visible: false,
            is_upsell_modal_seen: false,
        };
    }

    componentDidMount() {
        this.props.loadRecommendations();
    }

    endOrder() {
        const endpoint = this.props.state.main.restaurant_info.endpoint;
        this.props.clearAllOrders();
        this.props.history.push(`/${endpoint}.kiosk`);
    }

    closeUpsellModal() {
        this.setState({
            is_upsell_modal_visible: false,
        });
        this.props.resetPaymentErrors();
        this.props.history.push('/kiosk/cart');
    }

    renderMiniCart() {
        const cart = this.props.state.order.temporary_order;

        let num_of_items = 0;
        if (Array.isArray(cart)) {
            cart.forEach((item) => {
                num_of_items += item.multiplier;
            });
        }

        const payment_totals = this.props.state.order.payment_totals;

        const tax = payment_totals.tax_amount
            ? payment_totals.tax_amount.toFixed(2)
            : 0;
        const cart_total = payment_totals.total_with_tax
            ? payment_totals.total_with_tax.toFixed(2)
            : 0;

        const cart_summary = (
            <>
                Tax: ${tax} | Total: ${cart_total} | Items: {num_of_items}
            </>
        );

        // functions
        const showCart = () => {
            this.setState({
                is_upsell_modal_visible: true,
                is_upsell_modal_seen: true,
            });
        };

        const endOrderPressed = () => {
            setTimeout((e) => {
                this.endOrder();
            }, 280);
        };

        const top_ten_items = this.props.state.order.menu.top_10;

        return (
            <>
                {this.state.is_upsell_modal_visible ? (
                    <Upsell
                        scrollToMenu={() => this.props.scrollToMenu()}
                        onClose={() =>
                            this.setState({ is_upsell_modal_visible: false })
                        }
                        items={top_ten_items}
                        history={this.props.history}
                        onNoThanks={() => this.closeUpsellModal()}
                    ></Upsell>
                ) : (
                    <></>
                )}

                <div className="to-minicart-container" style={{ zIndex: '1' }}>
                    <div className="to-minicart-inner-container">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div
                                className="to-v2-button v2-button-cancel"
                                onMouseUp={() => endOrderPressed()}
                                onTouchEnd={() => endOrderPressed()}
                            >
                                <img
                                    alt=""
                                    className="to-v2-button-icon-sm"
                                    src="https://mentumqr.s3.amazonaws.com/gk-stuff/cancel.png"
                                ></img>
                                {this.props.t('common.cancel')}
                            </div>

                            <div
                                style={{
                                    marginLeft: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    padding: '20px',
                                }}
                            >
                                <div className="to-minicart-my-order">
                                    {this.props.t('minicart.myOrder')}
                                </div>
                                <div className="to-minicart-my-order-summary">
                                    {num_of_items > 0
                                        ? cart_summary
                                        : this.props.t(
                                              'minicart.orderWillAppearHere'
                                          )}
                                </div>
                            </div>
                        </div>

                        {num_of_items > 0 ? (
                            <div
                                className="to-v2-button"
                                onTouchEnd={() => showCart()}
                                onMouseUp={() => showCart()}
                            >
                                <img
                                    alt=""
                                    className="to-v2-button-icons"
                                    src="https://mentumqr.s3.amazonaws.com/gk-stuff/Group+(1).png"
                                ></img>
                                {this.props.t('minicart.tapToCheckout')}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </>
        );
    }

    render() {
        return this.renderMiniCart();
    }
}

const mstp = (state) => ({ state });

export default withTranslation()(
    connect(mstp, {
        menuCategorySelected: OrderActions.menuCategorySelected,
        menuSelected: OrderActions.menuSelected,
        menuItemSelected: OrderActions.menuItemSelected,
        kioskCategorySelected: OrderActions.kioskCategorySelected,

        clearAllOrders: OrderActions.clearAllOrders,

        resetPaymentErrors: OrderActions.resetPaymentErrors,

        loadRecommendations: OrderActions.loadRecommendations,
    })(Minicart)
);
