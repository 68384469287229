import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Footer, Button, Integrations, Spacing } from '../components';
import { SpacingAmount } from '../components/Spacing';
import './LandingV3.css';
import './ProductPage.css';

export default () => {
    return (
        <div>
            <Header />
            <div className="pdSectionContainer ">
                <div className="ProductBackground">
                    <div className="pdHeroSplit">
                        <div className="pdHeroSplitSide">
                            <Spacing amount={SpacingAmount.Spacing60} />
                            <div className="pdHeroTagline">
                                DELIVERY AGGREGATION
                            </div>
                            <div className="pdHeroText">
                                All Your Delivery Platforms In One Place
                            </div>
                            <div className="pdHeroDescr">
                                Instantly edit menus on all your delivery
                                platforms, change stock status, and get your
                                analytics all from a single dashboard or
                                integrate directly into your POS.
                            </div>
                            <Button
                                href="/contact"
                                text="Get started with us"
                                showChevron
                            />
                        </div>
                        <div className="pdHeroSplitSide">
                            <Spacing amount={SpacingAmount.Spacing60} />
                            <img
                                src="/assets/landing/v3/productPages/pd-delivery.png"
                                className="pdHeroImg"
                            />
                        </div>
                    </div>
                    <Spacing amount={SpacingAmount.Spacing128} />
                    <div className="v3CenteredHeader">
                        Delivery Integrations
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="v3CenteredDescription">
                        As a design-first partner we provide the most intuitive
                        and seamless platform and onboarding. Directly integrate
                        all order channels with your POS or operate Mentum
                        standalone.
                    </div>
                    <Integrations />
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="ProductSplitViewRWM">
                        <div className="ProductSplitViewSide ProductSplitViewRight">
                            <img
                                src="/assets/landing/v3/productPages/delivery-one.png"
                                className="ProductImage"
                            />
                        </div>
                        <div className="ProductSplitViewSide">
                            <div className="v3LandingProductName">
                                View all deliveries in one dashboard
                            </div>
                            <div className="v3LandingProductDescription">
                                {' '}
                                Reduce valuable time spent by staff collecting
                                and inputting orders from multiple delivery
                                platforms by having everything automated for you
                                and managed from a central location.
                            </div>
                            <Link to="/contact">
                                <div className="ProductLink">
                                    Book a Demo
                                    <img
                                        src="/assets/landing/v3/link-chevron.svg"
                                        className="v3LandingProductChevron"
                                    />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="ProductSplitView">
                        <div className="ProductSplitViewSide">
                            <div className="v3LandingProductName">
                                Integrate or go POS-less
                            </div>
                            <div className="v3LandingProductDescription">
                                {' '}
                                We integrate directly with the biggest POS
                                companies, but you can also go POS-less and
                                operate your business without clunky and
                                expensive hardware with our cloud platform as a
                                standalone.
                            </div>
                            <Link to="/contact">
                                <div className="ProductLink">
                                    Book a Demo
                                    <img
                                        src="/assets/landing/v3/link-chevron.svg"
                                        className="v3LandingProductChevron"
                                    />
                                </div>
                            </Link>
                        </div>
                        <div className="ProductSplitViewSide ProductSplitViewRight">
                            <img
                                src="/assets/landing/v3/productPages/delivery-posless.png"
                                className="ProductImage"
                            />
                        </div>
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="ProductSplitViewRWM">
                        <div className="ProductSplitViewSide ProductSplitViewRight">
                            <img
                                src="/assets/landing/v3/productPages/delivery-reduce.png"
                                className="ProductImage"
                            />
                        </div>
                        <div className="ProductSplitViewSide">
                            <div className="v3LandingProductName">
                                Reduce errors and complaints
                            </div>
                            <div className="v3LandingProductDescription">
                                {' '}
                                15% of orders have errors when manually inputted
                                into a POS by staff. Reduce costly mistakes and
                                customer complaints with efficient operations.
                            </div>
                            <Link to="/contact">
                                <div className="ProductLink">
                                    Book a Demo
                                    <img
                                        src="/assets/landing/v3/link-chevron.svg"
                                        className="v3LandingProductChevron"
                                    />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="v3ContentContainer">
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="v3CenteredHeader">POS Integrations</div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="v3CenteredDescription">
                        Handle higher volumes of orders with more efficient
                        labor management in your operation to push a higher
                        overall quality of service.
                    </div>
                    <Integrations />
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="v3CenteredHeader">
                        Check out our other products
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="CheckoutOthersContainer">
                        <div className="CheckoutContent">
                            <Link to="/product/qr" style={{ color: 'inherit' }}>
                                <img
                                    src="/assets/landing/v3/productPages/checkout-qr.png"
                                    className="CheckoutImg"
                                />
                                <div className="CheckoutTag">
                                    QR Dine-in & Pay
                                </div>
                                <div className="CheckoutDescrp">
                                    Future proof your store with the industries
                                    most seamless digital ordering tool for your
                                    QSR, Hotel, Bar, Club, or Food Truck with
                                    take away ordering.
                                </div>
                            </Link>
                        </div>
                        <div className="CheckoutContent">
                            <Link
                                to="/product/kiosk"
                                style={{ color: 'inherit' }}
                            >
                                <img
                                    src="/assets/landing/v3/productPages/checkout-kiosk.png"
                                    className="CheckoutImg"
                                />
                                <div className="CheckoutTag">
                                    Expressive Kiosk
                                </div>
                                <div className="CheckoutDescrp">
                                    Save on labour costs and customer wait times
                                    by giving patrons control of their own
                                    dining experience.
                                </div>
                            </Link>
                        </div>
                        <div className="CheckoutContent">
                            <Link
                                to="/product/dinein"
                                style={{ color: 'inherit' }}
                            >
                                <img
                                    src="/assets/landing/v3/productPages/checkout-dinein.png"
                                    className="CheckoutImg"
                                />
                                <div className="CheckoutTag">
                                    Dine-in Ordering
                                </div>
                                <div className="CheckoutDescrp">
                                    Instantly edit menus on all your delivery
                                    platforms, change stock status, and get your
                                    analytics all from a single dashboard or
                                    integrate directly into your POS.
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <Spacing amount={SpacingAmount.Spacing60} />

                <div className="v3ReadyToGetStartedContainer ReadySectionBackground">
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="v3ReadyHeader">Ready to get started?</div>
                    <Spacing amount={SpacingAmount.Spacing38} />

                    <div className="v3CenteredDescription">
                        Get in touch and set up a free demo of how our products
                        can future proof your operations.
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />

                    <Link to="/contact">
                        <Button text="Book a Demo" />
                    </Link>
                    <Spacing amount={SpacingAmount.Spacing128} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                </div>
                <Footer />
            </div>
        </div>
    );
};
