import { IconButton } from '@material-ui/core';
import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router-dom';
import { sendUserBack } from '../utils';

export default (props) => {
    const history = useHistory();
    const params = useParams();

    return (
        <IconButton
            edge="end"
            onClick={() => {
                sendUserBack(history, props.previousPath, params);
            }}
            data-cy="backButton"
        >
            <BiArrowBack color="#222" />
        </IconButton>
    );
};
