import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createPath } from '../utils';
import './css/TableSelect.css';
import * as TableActions from '../../../actions/tableActions';
import axios from 'axios';
import { APIv1Endpoint } from '../../../endpoints';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const TableSelect = (props) => {
    const {
        match: { params },
    } = props;
    const { endpoint } = params;

    const [tableNum, setTableNum] = useState('');
    const { t } = useTranslation();

    const submitTableNumber = () => {
        const { restaurant_id } = props.state.main.restaurant_info;
        axios
            .post(`${APIv1Endpoint}table/local`, {
                restaurant_id,
                local_id: tableNum,
            })
            .then((res) => {
                const { table } = res.data;
                props.dineInTableFound(table, props.history, false);
                props.history.push(
                    createPath('/v2/:/table/:/guests', [endpoint, tableNum])
                );
            })
            .catch((err) => {
                toast.error(t('toast.tableNotFound1'));
                console.error(err.response);
            });
    };

    const renderNextButton = () => {
        if (!tableNum) {
            return (
                <div className="tableSelectNextButton tableSelectNextButtonDisabled">
                    {t('tableSelect.next')}
                </div>
            );
        } else {
            return (
                <div
                    className="tableSelectNextButton"
                    onClick={submitTableNumber}
                >
                    {t('tableSelect.next')}
                </div>
            );
        }
    };

    return (
        <>
            <div className="tableSelectContainer">
                <div className="tableSelectWelcomeHeader">
                    {t('orderTypePage.welcomeMessage')}
                </div>
                <div className="tableSelectWelcomeDescription">
                    {t('tableSelect.enterTableNumber')}
                </div>

                <div className="tableSelectInputContainer">
                    <div className="tableSelectInputHeader">
                        {' '}
                        {t('tableSelect.table')}
                    </div>
                    <div className="TableSelectInputFieldContainer">
                        <input
                            type="text"
                            className="tableSelectInputField"
                            value={tableNum}
                            autoFocus
                            onChange={(e) =>
                                setTableNum(e.target.value.toUpperCase())
                            }
                        />
                    </div>
                </div>

                <div style={{ marginTop: 40 }}>{renderNextButton()}</div>
            </div>
        </>
    );
};

const mstp = (state) => ({ state });

export default connect(mstp, {
    dineInTableFound: TableActions.dineInTableFound,
})(TableSelect);
