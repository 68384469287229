import * as TYPES from './TYPES';

const INITIAL_STATE = {
    restaurant_endpoint: '',
    restaurant_affiliate: '',

    add_guest_name: '',
    err_guest_name: '',
    guests: [],
    is_loading_guests: false,
    is_adding_guest_to_table: false,
    restaurant_id: '',
    local_id: '',

    selected_guest: {}, // guest object

    table: {}, // table object (optional)

    all_orders: [],
    guest_orders: {},
    is_loading_orders: false,

    is_tablet: false,
    tablet_endpoint: '',

    addButtonDisabled: false,
    quote_time: new Date(),
};

const guestReducer = (state = INITIAL_STATE, action) => {
    // Copy state for modification
    let copy = { ...state };

    switch (action.type) {
        case TYPES.ADDING_GUEST_TO_TABLE:
            copy.is_adding_guest_to_table = action.payload;
            return copy;
        case TYPES.LOADING_TABLE_GUESTS:
            copy.is_loading_guests = action.payload;
            return copy;
        case TYPES.LOADING_GUEST_ORDERS:
            copy.is_loading_orders = action.payload;
            return copy;
        case TYPES.RESTAURANT_ENDPOINT_RECEIVED:
            copy.restaurant_endpoint = action.payload;
            return copy;
        case TYPES.RESTAURANT_AFFILIATE_RECEIVED:
            copy.restaurant_affiliate = action.payload;
            return copy;
        case TYPES.LOCAL_ID_CHANGED:
            copy.local_id = action.payload;
            return copy;
        case TYPES.DINE_IN_TABLE_FOUND:
            copy.table = action.payload;
            return copy;
        case TYPES.SET_TABLET_ENDPOINT:
            copy.tablet_endpoint = action.payload;
            return copy;
        case TYPES.SET_IS_TABLET:
            copy.is_tablet = action.payload;
            return copy;
        case TYPES.ADD_GUEST_BUTTON_STATE_CHANGED:
            copy.addButtonDisabled = action.payload;
            return copy;
        case TYPES.ORDERS_UPDATED:
            copy.all_orders = action.payload.all_orders;
            copy.guest_orders = action.payload.guest_orders;
            return copy;
        case TYPES.RESET_STATE:
            return INITIAL_STATE;
        case TYPES.GUEST_SELECTED:
            copy.selected_guest = action.payload.selected_guest;
            return copy;
        case TYPES.GUEST_NAME_IN_USE:
            copy.err_guest_name = action.payload.errorMessage;
            return copy;
        case TYPES.GUEST_LIST_UPDATED:
            copy.guests = action.payload.guests;
            copy.restaurant_id = action.payload.restaurant_id;
            copy.err_guest_name = ''; // reset error msg
            return copy;
        case TYPES.ADD_GUEST_NAME_UPDATED:
            copy.add_guest_name = action.payload.name; // update name
            copy.selected_guest.name = action.payload.name;
            return copy;
        case TYPES.PHONE_NUMBER_UPDATED:
            copy.selected_guest.phone_number = action.payload;
            return copy;
        case TYPES.QUOTE_ID:
            copy.selected_guest.quote_id = action.payload;
            copy.quote_time = new Date();
            return copy;
        case TYPES.ADDRESS:
            copy.selected_guest.address = action.payload;
            return copy;
        default:
            return state;
    }
};

export default guestReducer;
