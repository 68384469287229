import React from 'react';
import { connect } from 'react-redux';
import * as OrderActions from '../../actions/orderActions';
import * as TableActions from '../../actions/tableActions';

import './css/KioskHome.css';
import './css/TabletHome.css';
// import stripeTerminal from '../../StripeTerminal';
import Axios from 'axios';
import localforage from 'localforage';
import ReactPlayer from 'react-player';
import { isDevEnvironment } from '../../utils';

const PlayerState = {
    Playing: 0,
    Ended: 1,
};

class KioskHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            video_index: 0,
            player_state: PlayerState.Ended,
        };
    }

    startNewOrder() {
        this.props.resetState();

        this.props.history.push('/kiosk/brands');
    }

    downloadVideos() {
        const videos = this.state.videos;
        const dl_progress = {};

        if (Array.isArray(videos)) {
            // get localforage keys
            localforage.keys().then((keys) => {
                videos.forEach((video_url, video_index) => {
                    if (keys.includes(video_url)) {
                        // video has already been downloaded
                        console.log(`${video_url} already loaded`);

                        if (video_index === videos.length - 1) {
                            this.setState({
                                videos: videos,
                            });
                            this.playVideo();
                        }
                    } else {
                        /// else, download the video as a blob
                        Axios({
                            url: video_url,
                            onDownloadProgress: (progress) => {
                                dl_progress[video_url] =
                                    progress.loaded / progress.total;
                                this.setState({
                                    download_progress: dl_progress,
                                });
                            },
                            responseType: 'blob',
                        }).then((res) => {
                            // add video to local forage
                            const blob = res.data;
                            localforage.setItem(video_url, blob);

                            if (video_index === videos.length - 1) {
                                this.setState({
                                    videos: videos,
                                });
                                this.playVideo();
                            }
                        });
                    }
                });
            });
        }

        this.playVideo();
    }

    playVideo() {
        if (this.state.player_state === PlayerState.Playing) {
            return;
        }
        if (this.state.videos.length === 0) {
            console.error('Play video called when no video was available.');
            return;
        }
        if (this.state.video_index > this.state.videos.length - 1) {
            // reset
            this.setState({
                video_index: 0,
            });
            this.playVideo();
            return;
        }

        const video_url = this.state.videos[this.state.video_index];
        localforage.getItem(video_url).then((blob) => {
            if (!blob || !(blob instanceof Blob)) {
                this.setState({
                    video_index: 0,
                });
                this.playVideo();
                return;
            }
            this.setState({
                current_video: window.URL.createObjectURL(blob),
                player_state: PlayerState.Playing,
            });
        });
    }

    loadVideos() {
        // Never cache this file
        const date = new Date().getTime();
        Axios.get(
            `https://mentumqr.s3.amazonaws.com/kiosk/kiosk-videos.json?v=${date}`
        ).then(({ data }) => {
            // check if restaurant is in restaurant specific
            const endpoint = this.props.state.main.restaurant_info.endpoint;
            let videos = data.videos;
            if (
                data.restaurant_specific &&
                endpoint in data.restaurant_specific
            ) {
                videos = data.restaurant_specific[endpoint];
            }

            localforage.setItem('kiosk-videos', videos);
            this.setState({
                videos,
            });
            this.downloadVideos();
        });
    }

    playOffline() {
        localforage.getItem('kiosk-videos').then((videos) => {
            if (!videos) {
                return;
            }
            // find all downloaded videos
            localforage.keys().then((keys) => {
                let offline_videos = [];
                keys.forEach((video_url) => {
                    if (videos.includes(video_url)) {
                        offline_videos.push(video_url);
                    }
                });
                if (offline_videos.length > 0) {
                    this.setState({
                        videos: offline_videos,
                        video_index: 0,
                    });
                    this.playVideo();
                }
            });
        });
    }

    componentDidMount() {
        this.playOffline();
        this.props.clearAllOrders();
        this.loadVideos();

        if (!isDevEnvironment()) {
            document.documentElement.webkitRequestFullScreen();
        }

        window.oncontextmenu = function (event) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        };
    }

    render() {
        const { innerWidth, innerHeight } = window;
        const current_video =
            this.state.player_state === PlayerState.Playing
                ? this.state.videos[this.state.video_index]
                : '';

        return (
            <div>
                <div className="tabletHomeContainer">
                    <div
                        className="hiddenClickableDiv"
                        onClick={() => this.startNewOrder()}
                    ></div>

                    <ReactPlayer
                        url={current_video}
                        muted
                        playing
                        controls={false}
                        width={innerWidth}
                        height={innerHeight}
                        onEnded={() => {
                            this.setState({
                                player_state: PlayerState.Ended,
                            });
                            let next_video = this.state.video_index + 1;
                            if (next_video === this.state.videos.length) {
                                next_video = 0;
                            }
                            this.setState({
                                video_index: next_video,
                            });
                            this.playVideo();
                        }}
                        ref={(ref) => (this.player = ref)}
                    />
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    clearAllOrders: OrderActions.clearAllOrders,
    resetState: TableActions.resetState,
})(KioskHome);
