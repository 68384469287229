// sentry
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import amplitude from 'amplitude-js';
import Axios from 'axios';
import axiosRetry from 'axios-retry';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { Provider } from 'react-redux';
import {
    Route,
    BrowserRouter as Router,
    Switch,
    useLocation,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './components/ErrorBoundary.js';
import { BASE_URL, ENV } from './endpoints.js';
import HelmetMetaData from './HelmetMetaData.js';
// Router
import { persistor, store } from './reduxStore.js';
import './routes/css/index.css';
import Home from './routes/Home.js';
import {
    ContactUs,
    ContactUsSubmit,
    LandingV3,
    ProductDelivery,
    ProductDinein,
    ProductKiosk,
    ProductQr,
} from './routes/Landing/Pages/index.ts';
import CurbsidePickup from './routes/Order/CurbsidePickup.js';
import GhostKitchenLocationPicker from './routes/Other/LocationPicker/GhostKitchenLocationPicker.jsx';
import NuveiConfirmation from './routes/Payment/NuveiConfirmation.js';
import Receipt from './routes/Receipt.js';
import NewReceipt from './routes/PaymentReceipt';
import RestaurantRoutes from './routes/RestaurantRoutes.js';
import ScrollToTop from './ScrollToTop.js';
// v3Landing
import { LocaleProvider, useLocale } from './locales/index.tsx';
import * as serviceWorker from './serviceWorker.js';
import { useDisableConsole } from './hooks/use-disable-console';

if (ENV === 'production') {
    amplitude.getInstance().init('39b78c5321d3ad64f8752ce761ee0a08');
    Sentry.init({
        dsn: 'https://e9bb6bfdc4c646a19448c36a66675a20@o451714.ingest.sentry.io/6081709',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.2,
        environment: 'prod',
        release: '',
    });
} else {
    amplitude.getInstance().init('91140374fb1a04695c788a9d7617484c');
}

axiosRetry(Axios, {
    retryDelay: axiosRetry.exponentialDelay,
    retries: 5,
});

// lodash
const _ = require('lodash');

// TODO: Make proper desktop ui lul
const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
};

// Google Analytics
ReactGA.initialize('UA-168085132-1', {
    debug: false,
});

const reload = () => window.location.reload();

// add logging to every error message in axios
Axios.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error(error);

        // don't send restaurant 404's to sentry
        if (error.config.url.includes(`${BASE_URL}/api/restaurant/`)) {
            return Promise.reject(error);
        }

        const errorMessage =
            error.response && error.response.statusText
                ? `${error.response.statusText} ${
                      error.response.data.message
                          ? error.response.data.message
                          : ''
                  }`
                : 'Axios response error';

        Sentry.captureException(new Error(errorMessage), {
            contexts: {
                error: {
                    ...error,
                    error_raw: JSON.stringify(error),
                },
                response: {
                    ...error.response,
                    response_raw: JSON.stringify(_.cloneDeep(error.response)),
                },
            },
        });
        return Promise.reject(error);
    }
);

function Wrapper(props) {
    useEffect(() => {
        if (ENV !== 'production') return;
        hotjar.initialize('3840087', '6');
    }, []);

    return <>{props.children}</>;
}

function LangLoader() {
    const location = useLocation();
    const { locale, setLocale, supportedLocales } = useLocale();

    const searchParams = new URLSearchParams(location.search);
    const lang = searchParams.get('lang');
    const newLocale = supportedLocales.find((el) => el.value === lang);

    console.log(
        'here > TODO: fix lang',
        { newLocale, locale },
        newLocale?.value && newLocale?.value !== locale?.value
    );
    // useEffect(() => {
    //     const searchParams = new URLSearchParams(location.search);
    //     const lang = searchParams.get('lang');
    //
    //     if (lang) {
    //         const newLocale = supportedLocales.find((el) => el.value === lang);
    //         setLocale(newLocale);
    //     }
    // }, [location.search]);

    return <></>;
}

const Root = () => {
    useDisableConsole();

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Wrapper>
                        <div style={centerStyle}>
                            {/* <div style={{maxWidth: "450px", width: "100%"}}> */}
                            <div style={{ width: '100%' }}>
                                <Router>
                                    <LocaleProvider>
                                        <LangLoader />
                                        <ScrollToTop />
                                        <HelmetMetaData />
                                        <Route
                                            exact
                                            path="/.well-known/apple-developer-merchantid-domain-association"
                                            onEnter={reload}
                                        />
                                        <Switch>
                                            <Route
                                                exact
                                                path="/"
                                                component={LandingV3}
                                            />
                                            {/* <Route exact path="/ourstory" component={OurStory}></Route> */}
                                            <Route
                                                exact
                                                path="/contact"
                                                component={ContactUs}
                                            />
                                            <Route
                                                exact
                                                path="/contact/complete"
                                                component={ContactUsSubmit}
                                            />
                                            <Route
                                                exact
                                                path="/product/kiosk"
                                                component={ProductKiosk}
                                            />
                                            <Route
                                                exact
                                                path="/product/qr"
                                                component={ProductQr}
                                            />
                                            <Route
                                                exact
                                                path="/product/dinein"
                                                component={ProductDinein}
                                            />
                                            <Route
                                                exact
                                                path="/product/delivery"
                                                component={ProductDelivery}
                                            />
                                            <Route
                                                exact
                                                path="/curbside"
                                                component={CurbsidePickup}
                                            />
                                            <Route
                                                exact
                                                path="/gklocations"
                                                component={
                                                    GhostKitchenLocationPicker
                                                }
                                            />
                                            <Route
                                                exact
                                                path="/table"
                                                component={Home}
                                            />
                                            <Route
                                                path="/receipts-old/:guest_id"
                                                component={Receipt}
                                            />
                                            <Route
                                                path="/receipts/:guest_id"
                                                component={NewReceipt}
                                            />
                                            <Route
                                                path="/order/nuvei_confirmation"
                                                component={NuveiConfirmation}
                                            />
                                            <Route
                                                path="/"
                                                component={RestaurantRoutes}
                                            />
                                        </Switch>
                                    </LocaleProvider>
                                </Router>
                            </div>
                        </div>
                    </Wrapper>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// expose store when run in Cypress
if (window.Cypress) {
    window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
