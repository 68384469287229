import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'; // persist store

// middleware
import logger from 'redux-logger';
import thunk from 'redux-thunk';

// reducer
import rootReducer from './reducers/root-reducer';

import storage from 'localforage';
const persistConfig = {
    key: 'root',
    storage,
    // blacklist: ['main'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// middleware setup
const middlewares = [thunk];
if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}

export const store = createStore(
    persistedReducer,
    applyMiddleware(...middlewares)
);
export const persistor = persistStore(store);
export default { store, persistor };
