import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleOutline } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as Types from '../../reducers/TYPES';
import { persistor } from '../../reduxStore';
import { createPath } from '../v2/utils';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        padding: '2rem',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '2rem',
        fontFamily: 'Poppins',
        paddingRight: '1rem',
    },
    text: {
        fontFamily: 'Poppins',
        color: '#838383',
    },
}));

export default (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const startNewOrder = () => {
        dispatch({ type: Types.RESET_STATE });
        dispatch({
            type: Types.CLEAR_TEMPORARY_ORDERS,
        });
        dispatch({ type: Types.CLEAR_CAMPAIGN_INSTANCES });
        persistor.purge();

        history.push(createPath('/v2/:/order', [props.endpoint]));
    }

    return (
        <div className={classes.container}>
            <div
                className={classes.columnContainer}
                style={{ alignItems: 'left', maxWidth: 500, height: '100%' }}
            >
                <div
                    className={classes.rowContainer}
                    style={{ padding: '2rem 1rem 2rem 1rem' }}
                >
                    <div className={classes.title}>{this.props.t('paymentComplete.curbside')}</div>
                    <CheckCircleOutline
                        style={{
                            width: '3rem',
                            height: '3rem',
                            fill: '#52CD19',
                            marginLeft: 'auto',
                        }}
                    />
                </div>

                <div
                    style={{
                        fontWeight: 'bold',
                        fontSize: '3rem',
                        // marginTop: "10vw",
                        textAlign: 'left',
                        alignSelf: 'left',
                        padding: '2rem 0rem 2rem 1rem',
                    }}
                >
                    {t('common.ty')}!
                </div>
                <div
                    className={classes.columnContainer}
                    style={{ marginTop: '5vw', padding: '2rem 1rem 2rem 1rem' }}
                >
                    <div className={classes.rowContainer}>
                        <div className={classes.text}>
                            {t('paymentComplete.curbsidePickupNumber')}: &nbsp;
                        </div>
                        <div
                            className={classes.text}
                            style={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                            }}
                        >
                            {' '}
                            {props.curbsideNumber}{' '}
                        </div>
                    </div>

                    <div className={classes.rowContainer}>
                        <div className={classes.text}>
                            {t('paymentComplete.yourOrderNumber')}: &nbsp;
                        </div>
                        <div
                            className={classes.text}
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {props.orderNumber}
                        </div>
                    </div>
                </div>

                {/* <div className={classes.rowContainer}>
            <CheckCircleOutline style={{ width: '1.5rem', height: '1.5rem', fill: '#202020' }} />
            <div style={{ paddingLeft: '1rem' }}>You’ll receive a text when your order is ready for pickup.</div>
          </div> */}
            </div>
            <div
                className={classes.columnContainer}
                style={{
                    position: 'absolute',
                    bottom: 0,
                    alignItems: 'center',
                }}
            >
                <div
                    className={classes.rowContainer}
                    style={{
                        paddingBottom: '2rem',
                    }}
                >
                    <CheckCircleOutline
                        style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            fill: '#202020',
                        }}
                    />
                    <div
                        className={classes.text}
                        style={{ paddingLeft: '1rem' }}
                    >
                        Our staff will drop off your order.
                    </div>
                </div>
                <Button
                    variant="contained"
                    data-cy="start-new-order-button"
                    onClick={startNewOrder}
                    style={{
                        backgroundColor: '#ECECEC',
                        textTransform: 'none',
                        color: '#444444',
                        borderRadius: 0,
                        fontWeight: '700',
                        paddingBottom: '4rem',
                        width: '100%',
                    }}
                >
                    Start New Order
                </Button>
                <div
                    style={{
                        width: '100vw',
                        height: 'env(safe-area-inset-bottom)',
                        backgroundColor: '#ECECEC',
                        zIndex: 1,
                    }}
                ></div>
            </div>
        </div>
    );
};
