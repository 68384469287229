import { Box, CircularProgress, Container } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import CampaignBlock from '../Components/CampaignBlock';
import MiniCart from '../Components/MiniCart';
import TopHeader from '../Components/TopHeader';

export default (props) => {
    const guest = useSelector((state) => state.table.selected_guest);
    const loadingGuest = !guest.guest_id;

    return (
        <>
            <TopHeader orderPage />
            <Container
                fixed
                style={{ display: 'flex', flexDirection: 'column', flex: '1' }}
            >
                {loadingGuest ? (
                    <Box m={1}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <CampaignBlock />
                )}
            </Container>
            <MiniCart />
        </>
    );
};
