import React from 'react';

interface ButtonProps {
    text: string;
    href?: string;
    showChevron?: boolean;
    onClick?: () => void;
}

export default (props: ButtonProps) => {
    return (
        <a href={props.href}>
            <div className="v3ButtonContainer">
                <div className="v3ButtonInnerConatiner">
                    {props.text}
                    {props.showChevron ? (
                        <img
                            src="/assets/landing/v3/button-chevron.svg"
                            className="v3ButtonChevron"
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </a>
    );
};
