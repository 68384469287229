import default_theme from './default';
import { cloudfrontBaseUrl } from '../endpoints';

const theme = {
    ...default_theme,

    // GUEST HOME OPTIONAL LOGO
    optionalHomeHeaderUrl: `${cloudfrontBaseUrl}}54e3ed8a-baea-11ea-b85e-02685a4295ea/35590546.jpg`,
    optionalHomeHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    optionalHomeHeaderImg: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: '50% 50%',
    },

    customDineSelect: {
        height: '80px',
        paddingBottom: '0',
        marginBottom: '30px',
    },

    floatingButton: {
        backgroundColor: '#104C98',
    },

    finalizeAddItemsButton: {
        border: '2px solid #104C98',
        color: '#104C98',
    },

    // MUILTIPLIER
    itemMultiplierContainer: {
        borderColor: '#104C98',
    },
    itemMultiplierLeft: {
        borderRightColor: '#104C98',
        color: '#104C98',
    },
    itemMultiplierRight: {
        borderLeftColor: '#104C98',
        color: '#104C98',
    },
    itemMultiplierText: {
        color: '#104C98',
    },
};

export default theme;
