import React from 'react';

// css
import './css/Text.css';
import { withTheme } from '../themes/theming';

const PageTitleWithTheme = (props) => {
    const { theme } = props;
    return (
        <div className="yourOrderContainer">
            <div className="yourOrderTitle" style={theme.orderTitle}>
                {props.text}
            </div>
        </div>
    );
};

const PageDescriptionWithTheme = (props) => {
    const { theme } = props;
    return (
        <div className="yourOrderContainer">
            <div
                className="yourOrderDescription"
                style={theme.orderDescription}
            >
                {props.text}
            </div>
        </div>
    );
};

const PageTitle = withTheme(PageTitleWithTheme);
const PageDescription = withTheme(PageDescriptionWithTheme);

export { PageTitle, PageDescription };
