import React from 'react';
import './GhostKitchenLocationPicker.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const GKGooglePlacesAutoComplete = ({ address, onChange, isMobile }) => {
    const googlePlacesAutocompleteApi =
        'AIzaSyBXP2M5if4sWkuZH92dUm3B3urlzZohKsY';
    if (isMobile) {
        return (
            <GooglePlacesAutocomplete
                apiKey={googlePlacesAutocompleteApi}
                onLoadFailed={(error) =>
                    console.error('Could not inject Google script', error)
                }
                selectProps={{
                    address,
                    onChange,
                    placeholder: 'Search by city, address, or postal code',
                    noOptionsMessage: () => 'No address found.',
                    styles: {
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                                ? '#EFFAF1'
                                : 'white',
                        }),
                        indicatorSeparator: (provided) => ({
                            ...provided,
                            display: 'none',
                        }),
                        indicatorsContainer: (provided) => ({
                            ...provided,
                            display: 'none',
                        }),
                        container: (provided) => ({
                            ...provided,
                            display: 'flex',
                            height: '45px',
                            border: 'none',
                            marginTop: 24,
                            width: '100%',
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            height: '45px',
                            border: 'none',
                            borderRadius: '0px',
                            borderBottom: '1px solid #AEAEAE',
                            '&:hover': {
                                borderColor: state.isFocused
                                    ? '#3AB649'
                                    : '#EAEAEA;',
                            },
                            boxShadow: 'none',
                            borderColor: state.isFocused
                                ? '#3AB649'
                                : '#EAEAEA;',
                            minHeight: '45px',
                            width: '100%',
                        }),
                        input: (provided) => ({
                            ...provided,
                            border: 'none',
                        }),
                    },
                }}
            />
        );
    } else {
        return (
            <GooglePlacesAutocomplete
                variant="outlined"
                apiKey={googlePlacesAutocompleteApi}
                onLoadFailed={(error) =>
                    console.error('Could not inject Google script', error)
                }
                selectProps={{
                    address,
                    onChange,
                    placeholder: 'Search by city, address, or postal code',
                    noOptionsMessage: () => 'No address found.',

                    styles: {
                        padding: '0rem 1rem 0rem 1rem',
                        indicatorSeparator: (provided) => ({
                            ...provided,
                            display: 'none',
                        }),
                        indicatorsContainer: (provided) => ({
                            ...provided,
                            display: 'none',
                        }),

                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                                ? '#EFFAF1'
                                : 'white',
                        }),
                        noOptionsMessage: (provided) => ({
                            ...provided,
                        }),
                        container: (provided) => ({
                            ...provided,
                            display: 'flex',
                            width: '85%',
                            height: '45px',
                            border: 'none',
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            height: '45px',
                            borderBottomLeftRadius: '0px',
                            borderTopLeftRadius: '0px',
                            '&:hover': {
                                borderColor: state.isFocused
                                    ? '#3AB649'
                                    : '#EAEAEA;',
                            },
                            boxShadow: 'none',
                            borderColor: state.isFocused
                                ? '#3AB649'
                                : '#EAEAEA;',
                            minHeight: '45px',
                            width: '100%',
                        }),
                        input: (provided) => ({
                            ...provided,
                            border: 'none',
                        }),
                    },
                }}
            />
        );
    }
};

export default GKGooglePlacesAutoComplete;
