const theme = {
    headerLogo: {},

    // PAYMENT COMPLETE
    paymentCompleteContainer: {},

    // paymentCompleteTitle: {
    //     color: "#383838",

    //     paddingLeft: "20px",
    //     paddingRight: "20px",
    // },
    // orderCodeBold: {
    //     fontWeight: "700",
    //     fontSize: "30px",
    //     margin: "10px",
    // },
    // paymentCompleteDescription: {
    //     marginTop: "50px",

    //     color: "#383838",
    //     fontWeight: "600",
    //     fontSize: "18px",

    //     paddingLeft: "20px",
    //     paddingRight: "20px",
    //     textAlign: "center",
    // },
    // phoneNumberPrompt: {
    //     textAlign: "center",
    //     color: "#383838",
    //     fontSize: "12px",
    //     padding: "30px 50px 10px 50px",
    // },

    // PAYMENT
    paymentContainer: {
        borderTop: '1px solid #DFDFDF',
        borderBottom: '1px solid #DFDFDF',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    paymentCardElementStyle: {},

    paymentSubmitButton: {
        marginTop: '10px',
        borderRadius: '7px',
        backgroundColor: '#3BAD4D',
    },

    // PAYMENT: APPLE PAY
    applePayContainer: {
        position: 'absolute',
        bottom: '20px',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    applePayLabel: {
        textAlign: 'center',
        margin: '10px',
        fontSize: '18px',
        fontWeight: '500',
    },

    // GUEST LIST LOGO

    guestListImage: '/assets/photos/gk-banner-2.jpg',

    guestListLogoContiner: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
    },

    guestListImageStyle: {
        width: '100%',
        height: '230px',
        objectFit: 'cover',
    },

    guestListLogo: {
        width: '100%',
        objectFit: 'contain',
    },

    // MUILTIPLIER
    itemMultiplierContainer: {
        borderColor: '#3BAD4D',
    },
    itemMultiplierLeft: {
        borderRightColor: '#3BAD4D',
        color: '#3BAD4D',
    },
    itemMultiplierRight: {
        borderLeftColor: '#3BAD4D',
        color: '#3BAD4D',
    },
    itemMultiplierText: {
        color: '#3BAD4D',
    },

    // PAY SUMMARY
    tipLabel: {
        color: '#3BAD4D',
    },
    tipButton: {
        borderColor: '#3BAD4D',
        color: '#3BAD4D',
    },
    tipButtonActive: {
        backgroundColor: '#3BAD4D',
        color: '#fff',
    },

    description: 'Countless international brands. One kitchen!',

    guestListSubtitleContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '30px',
        marginRight: '30px',
        textAlign: 'center',

        fontFamily: 'Noto Sans JP',
        fontStyle: 'italic',
        fontSize: '18px',
        fontWeight: '500',
        color: '#606060',

        marginBottom: '30px',
    },
    guestListGreeting: {
        display: 'none',
    },

    buttonFont: {
        fontFamily: 'Lato',
        fontWeight: '600',
        fontSize: '18px',
        color: '#222',
    },

    pageTitleFont: {
        color: '#373737',
        fontWeight: '700',
    },
    pageDescriptionFont: {
        color: '#373737',
        fontStyle: 'normal',
    },

    addGuestTextBox: {
        color: '#373737',
        fontFamily: 'Roboto',
        fontWeight: '400',
    },

    // FLOATING BUTTON

    floatingButton: {
        backgroundColor: '#3BAD4D',
        fontWeight: '500',
    },
    floatingButtonLabel: {
        fontFamily: 'Lato',
        fontWeight: '600',
    },

    // HEADER
    headerGuestNameLabel: {
        color: '#000',
        fontFamily: 'Roboto',
        fontWeight: '400',
    },
    headerBackButtonText: {
        fontFamily: 'Roboto',
        fontWeight: '700',
        color: '#3BAD4D',
        fontSize: '22px',
    },
    headerGuestIcon: {},

    // GUEST HOME OPTIONAL LOGO
    optionalHomeHeaderUrl:
        '/assets/photos/ghostkitchens/brands/cheesecake-header.jpg',
    optionalHomeHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    optionalHomeHeaderImg: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: '50% 50%',
    },

    optionalHomeWelcomeContainer: {
        marginTop: '20px',
        marginBottom: '30px',
    },

    // Homepage Greeting
    welcomeGreeting: {
        display: 'none',
        fontFamily: 'Noto Sans JP',
        fontStyle: 'normal',
        fontWeight: '500',
        color: '#373737',
    },
    welcomeName: {
        display: 'none',
        fontFamily: 'Noto Sans JP',
        color: '#373737',
    },
    //Custom header
    customGuestHomeHeaderContainer: {
        marginLeft: '30px',
        marginTop: '30px',
    },
    customGuestHomeTextStyle: {
        fontWeight: '400',
        fontSize: '24px',
        color: '#222222',

        maxWidth: '75%',
    },
    customGuestHomeText: 'Countless international brands. One kitchen!',

    // OPTIONAL GUEST HOME FOOTER
    optionalHomeFooter: {
        paddingTop: '50px',
        paddingBottom: '20px',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        color: '#888',
        fontFamily: 'Lato',
        fontWeight: '200',
        fontSize: '13px',
    },

    optionalHomeFooterText: 'Ghost Kitchens',

    // Finalize add item button
    finalizeAddItemsButton: {
        border: '2px solid #3BAD4D',
        color: '#3BAD4D',
    },

    // Generic titles and descriptions
    orderTitle: {
        color: '#3BAD4D',
        fontFamily: 'Roboto',
        fontSize: '36px',
    },

    orderDescription: {
        fontFamily: 'Roboto',
        color: '#333',
    },

    restaurantMenuTitle: {
        fontFamily: 'Roboto',
        color: '#3BAD4D',
        fontSize: '24px',
        fontWeight: '500',
    },

    restaurantCategoryLabel: {
        fontFamily: 'Roboto',
        fontSize: '18px',
        color: '#2F2F2F',
    },

    menuCategoryTitle: {
        fontFamily: 'Roboto',
        color: '#3BAD4D',
    },

    menuItemLabel: {
        fontFamily: 'Noto Sans JP',
        color: '#373737',
    },

    itemDetailTitle: {
        fontFamily: 'Noto Sans JP',
        color: '#373737',
    },
    itemDetailPrice: {
        fontFamily: 'Noto Sans JP',
        color: '#373737',
    },
    itemDetailDescription: {
        fontFamily: 'Noto Sans JP',
        color: '#373737',
    },
};

export default theme;
