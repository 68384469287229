// Function to compare modifiers
export function areModifiersEqual(existingModifiers, newItem) {
    const existingModifierIds = existingModifiers.modifiers.map(
        (modifier) => modifier.modifier_id
    );

    if (!existingModifierIds.length) return false;

    const newModifierIds = newItem.modifiers.map(
        (modifier) => modifier.modifier_id
    );

    // Check if the arrays have the same length and all modifier_ids in existingModifierIds are present in newModifierIds
    return (
        existingModifiers.item_id === newItem.item_id &&
        existingModifierIds.length === newModifierIds.length &&
        existingModifierIds.every((existingModifierId) =>
            newModifierIds.includes(existingModifierId)
        )
    );
}

export function isEqualSpecialInstructions(existingItem, newInstructions) {
    return existingItem.special_instructions === newInstructions;
}
