export const blacklistMenus = ['DJ'];
export const blacklistAll = ['Ghost Kitchen'];
const NOT_TO_SHOW = [
    'Egg Bird',
    "Chubb's",
    "The Ice Cream Store by Ben & Jerry's",
    'Beyond Meat Menu',
    'Taco Star',
    'Ghost Ice Cream',
    'Black Tulip',
    'Jamba',
    'IHOP',
];

export const splitBrands = (menus) => {
    if (!Array.isArray(menus)) {
        return { active: [], inactive: [] };
    }

    const nonBlacklistedMenus = menus.filter((menu) => {
        let is_blacklisted = false;

        // Remove all menus that are coming soon with this name
        blacklistMenus.forEach((blacklistedName) => {
            if (menu.name.includes(blacklistedName) && !menu.is_active) {
                is_blacklisted = true;
            }
        });

        // Remove all menus with this name
        blacklistAll.forEach((blacklistedName) => {
            if (menu.name.includes(blacklistedName)) {
                is_blacklisted = true;
            }
        });
        return !is_blacklisted;
    });

    let active = nonBlacklistedMenus.filter((m) => m.is_active);
    let inactive = nonBlacklistedMenus.filter((m) => !m.is_active);

    inactive = inactive.filter((menu) => {
        let show = true;
        NOT_TO_SHOW.forEach((brand) => {
            if (menu.name.includes(brand)) {
                show = false;
            }
        });
        return show;
    });

    return { active, inactive };
};

const isBlacklisted = (menu) => {
    return (
        blacklistMenus.some((blacklistedName) => menu.name.includes(blacklistedName) && !menu.is_active) ||
        blacklistAll.some((blacklistedName) => menu.name.includes(blacklistedName))
    );
};

const shouldShow = (menu) => {
    return NOT_TO_SHOW.every((brand) => !menu.name.includes(brand));
};

export const sortInScheduleMenus = (menus = [], inScheduleMenus = []) => {
    const inScheduleMenuIds = inScheduleMenus.map((item) => item.menu_id);

    const result = menus
        .filter((menu) => !isBlacklisted(menu))
        .sort((a, b) => a.rank - b.rank)
        .reduce(
            (acc, menu) => {
                const { is_active, menu_id } = menu;
                const in_schedule = inScheduleMenuIds.includes(menu_id);
                const updatedMenu = { ...menu, in_schedule };

                if (is_active && in_schedule) {
                    acc.activeMenus.push(updatedMenu);
                } else {
                    if (shouldShow(menu)) {
                        acc.inactiveMenus.push(updatedMenu);
                    }
                }

                return acc;
            },
            { activeMenus: [], inactiveMenus: [] }
        );

    return [...result.activeMenus, ...result.inactiveMenus];
};
