import React from 'react';
import { connect } from 'react-redux';

// action creators
import * as TableActions from '../actions/tableActions';
import { PaymentType, DineInOrTakeoutSetting } from '../reducers/TYPES';

// Route css
import './css/GuestHome.css';

// Components
import Header from '../components/Header';
import Button from '../components/Button';
import HealthAndSafetyForm from '../components/HealthAndSafetyForm';

// theme
import { withTheme } from '../themes/theming';
import { toast } from 'react-toastify';
import { isDisableSelectGuest, isTakeoutOrder } from '../utils';
import { withTranslation } from 'react-i18next';

class GuestHome extends React.Component {
    componentDidMount() {
        // Check if there exists a guest_id and a selected guest in state.
        this.props.checkValidRoute(this.props.history);

        // Load orders
        this.props.loadOrders(this.props.history);

        // TODO: REMOVE (FOR TESTING ONLY)
        // this.props.updateTheme();
    }

    componentWillUnmount() {
        toast.dismiss('orderPlacedToast');
        toast.dismiss('sendingTextNotification');
    }

    backButtonPressed() {
        this.props.goBackToGuestSelect(this.props.history);
    }

    myOrderButtonPressed() {
        this.props.history.push('/orders');
    }

    startOrderButtonPressed() {
        this.props.history.push('/logoview');
    }

    callWaiter() {
        this.props.history.push('/callWaiter');
    }

    signOut() {
        const id_arr = this.props.main.table_id;
        const id = `${id_arr[0]}${id_arr[1]}${id_arr[2]}${id_arr[3]}`;

        this.props.history.push(`/table/${id}`);
    }

    isGK(id) {
        const restaurants = [
            'abe82375-9878-11ea-90c7-00155d00c302',
            '56ff23fd-a475-11ea-b0d7-7c67a2b110fc',
            '148f29f6-a945-11ea-8a6a-069f49d95199',
            'f3a6b762-ad30-11ea-b57a-069f49d95199',
        ];
        return restaurants.includes(id);
    }

    isStandingTable() {
        const order_type = this.props.order.order_type;
        return order_type === DineInOrTakeoutSetting.TAKEOUT;
    }

    render() {
        const guest = this.props.table.selected_guest;
        const restaurant = this.props.main.restaurant_info;
        const { t } = this.props;

        const theme = this.props.theme;

        let orderCount;
        if ('items' in this.props.table.guest_orders) {
            orderCount = this.props.table.guest_orders.items.length;
        }
        const yourOrderLabel = `${this.props.t(
            'guestHome.myOrder'
        )} (${orderCount} ${this.props.t('guestHome.items')})`;
        const local_id = this.props.table.local_id;

        const start_order_text =
            orderCount > 0
                ? this.props.t('guestHome.addToOrder')
                : this.props.t('guestHome.startOrder');
        const add_to_order_disabled = orderCount > 0 && isTakeoutOrder();

        return (
            <div className="Container">
                <Header history={this.props.history} showGuestName />

                {this.props.main.show_health_and_safety ? (
                    <HealthAndSafetyForm />
                ) : (
                    <></>
                )}

                <div style={{ display: 'none', ...theme.optionalHomeHeader }}>
                    <img
                        alt="Restaurant header"
                        src={theme.optionalHomeHeaderUrl}
                        style={theme.optionalHomeHeaderImg}
                    ></img>
                </div>

                <div style={theme.customGuestHomeHeaderContainer}>
                    <div style={theme.customGuestHomeTextStyle}>
                        {theme.customGuestHomeText}
                    </div>
                </div>

                {this.isStandingTable() ? (
                    <div
                        className="welcome"
                        style={theme.optionalHomeWelcomeContainer}
                    >
                        <div
                            className="welcome-greeting"
                            style={theme.welcomeGreeting}
                        >
                            {t('common.welcomeTo')}
                        </div>
                        <div className="welcome-name" style={theme.welcomeName}>
                            {restaurant.name}
                        </div>
                    </div>
                ) : (
                    <div
                        className="welcome"
                        style={theme.optionalHomeWelcomeContainer}
                    >
                        <div
                            className="welcome-greeting"
                            style={theme.welcomeGreeting}
                        >
                            {this.props.t('guestHome.welcome')},
                        </div>
                        <div className="welcome-name" style={theme.welcomeName}>
                            {guest.name}{' '}
                            {local_id.length > 0
                                ? `| ${this.props.t(
                                      'tableSelect.table'
                                  )}: ${local_id}`
                                : ''}
                        </div>
                    </div>
                )}

                {orderCount === undefined ? (
                    <div className="cssload-container">
                        <div className="cssload-whirlpool"></div>
                    </div>
                ) : (
                    <div className="guestHomeButtonContainer">
                        <div className="buttonGroupContainer">
                            {orderCount !== 0 ? (
                                <Button
                                    width={3}
                                    text={yourOrderLabel}
                                    showArrow
                                    action={() => this.myOrderButtonPressed()}
                                />
                            ) : null}
                            <Button
                                text={start_order_text}
                                showArrow
                                action={() => this.startOrderButtonPressed()}
                                disabled={add_to_order_disabled}
                            />

                            {!this.isStandingTable() ? (
                                <Button
                                    text={this.props.t('guestHome.callWaiter')}
                                    action={() => this.callWaiter()}
                                    showArrow
                                />
                            ) : (
                                ''
                            )}
                        </div>

                        {!this.isStandingTable() && !isDisableSelectGuest ? (
                            <div className="buttonGroupContainer">
                                <div style={{ margin: '15px' }}></div>
                                <div>
                                    <Button
                                        text={this.props.t('guestHome.selectDifferentGuest')}
                                        action={() => this.signOut()}
                                    />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )}

                <div style={theme.optionalHomeFooter}>
                    {theme.optionalHomeFooterText}
                </div>

                {/* <FinalizeOrderButton history={this.props.history} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        table: state.table,
        main: state.main,
        order: state.order,
    };
};
const actions = {
    goBackToGuestSelect: TableActions.goBackToGuestSelect,
    checkValidRoute: TableActions.checkValidRoute,
    loadOrders: TableActions.loadOrders,
    updateTheme: TableActions.updateTheme,
};
export default withTranslation()(
    withTheme(connect(mapStateToProps, actions)(GuestHome))
);
