import React from 'react';
import {
    AiFillCalendar,
    AiFillCreditCard,
    AiTwotoneSecurityScan,
} from 'react-icons/ai';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as OrderActions from '../../../actions/orderActions';
import Header from '../../../components/Header';
import { i18n } from '../../../locales';
import './css/PayNMI.css';

class PayNMI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            amount: 0,
            isSubmitting: false,
            scriptLoaded: false,
        };
        this.setState = this.setState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.finishSubmit = this.finishSubmit.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }

    componentDidMount() {
        const collectScript = document.createElement('script');
        collectScript.src =
            'https://secure.networkmerchants.com/token/Collect.js';
        collectScript.id = 'collectJS';
        document.body.appendChild(collectScript);
        const token_key = this.props.state.main.restaurant_info.nmi_public_key;
        console.log('nmi-data-tokenization-key', token_key);
        document
            .getElementById('collectJS')
            .setAttribute('data-tokenization-key', token_key);

        const collectCallback = () => {
            window.CollectJS.configure({
                variant: 'inline',
                country:
                    `${this.props.state.main.restaurant_info.currency}`.toUpperCase() ===
                    'CAD'
                        ? 'CA'
                        : 'US',
                price: `${this.props.state.order.payment_totals.total_with_tip_and_tax.toFixed(
                    2
                )}`,
                currency: this.props.state.main.restaurant_info.currency
                    ? `${this.props.state.main.restaurant_info.currency}`.toUpperCase()
                    : 'USD',
                callback: (token) => {
                    console.log('callback fns: ', { token });
                    this.finishSubmit(token);
                },
                googleFont: 'Montserrat:400',
                invalidCss: {
                    color: 'red',
                    backgroundColor: 'red',
                    borderWidth: 100,
                },
                validCss: {
                    color: 'green',
                    backgroundColor: 'green',
                    borderWidth: 0,
                },
                placeholderCss: {
                    color: '#999999',
                    backgroundColor: 'black',
                },
                focusCss: {
                    color: 'black',
                    backgroundColor: 'red',
                },
                fields: {
                    ccnumber: {
                        placeholder: '0000 0000 0000 0000',
                        selector: '#ccnumber',
                        title: 'Card Number',
                    },
                    ccexp: {
                        placeholder: 'MM / YY',
                        selector: '#ccexp',
                        title: 'Card Expiration',
                    },
                    cvv: {
                        placeholder: 'CVV',
                        selector: '#cvv',
                        title: 'CVV Code',
                    },
                },
                validationCallback: this.validateFields,
            });
            this.setState({ scriptLoaded: true });
        };

        collectScript.onload = () => {
            if (window.CollectJS) collectCallback();
            else setTimeout(collectCallback, 100);
        };
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ isSubmitting: true });

        if (this.state.scriptLoaded === true) {
            window.CollectJS.startPaymentRequest();
        } else {
            toast.error(i18n.t('toast.requestProcessingError'));
        }
    }

    validateFields(fieldName, valid, message) {
        if (!valid && this.state.isSubmitting) {
            this.setState({ isSubmitting: false });
            toast.error(message);
        }
    }

    finishSubmit(response) {
        console.log('finishSubmit fns: ', { response });
        this.props.processNMIToken(this.props.history, response);
    }

    render() {
        if (this.props.isCartEmpty && this.props.endpoint) {
            this.props.history.replace(`/v2/${this.props.endpoint}/order`);
            return null;
        }

        if (this.props.isCartEmpty) {
            return null;
        }

        return (
            <div>
                <Header back=":goBack" history={this.props.history} />
                <div className="column-container center payLayout">
                    <div
                        style={{
                            textAlign: 'center',
                            borderRadius: '5px',
                            padding: '1rem',
                            marginBottom: '10px',
                            marginTop: '5%',
                            backgroundColor: '#e6e6e6',
                            color: '#333',
                            width: '66vw',
                        }}
                    >
                        <img
                            style={{ width: '20px', padding: '10px' }}
                            src="/assets/icons/lock-black.png"
                            alt=""
                        />
                        <div style={{ padding: '5px' }}>
                            Your payment is secured by NMI.
                        </div>
                    </div>
                    <div className="paymentText">
                        You Pay <br />$
                        {this.props.state.order.payment_totals.total_with_tip_and_tax.toFixed(
                            2
                        )}
                        {}
                    </div>
                    <hr />
                    <div
                        style={{
                            padding: '1rem',
                            alignItems: 'center',
                            width: '66vw',
                        }}
                    >
                        <form
                            className="column-container"
                            onSubmit={this.handleSubmit}
                        >
                            <div className="column-container">
                                <div className="row-container center">
                                    <AiFillCreditCard
                                        style={{ fontSize: '2rem' }}
                                    />
                                    <div style={{ width: '100%' }}>
                                        <div id="ccnumber" />
                                    </div>
                                </div>

                                <div className="row-container center">
                                    <AiFillCalendar
                                        style={{ fontSize: '4rem' }}
                                    />
                                    <div style={{ width: '100%' }}>
                                        <div id="ccexp" />
                                    </div>

                                    <AiTwotoneSecurityScan
                                        style={{ fontSize: '4rem' }}
                                    />
                                    <div style={{ width: '100%' }}>
                                        <div id="cvv" />
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    position: 'relative',
                                    alignSelf: 'center',
                                }}
                            >
                                <button
                                    style={{
                                        textTransform: 'none',
                                        backgroundColor: '#20B04B',
                                        color: 'white',
                                        fontWeight: '700',
                                        padding: '0.6rem 1rem 0.6rem 1rem',
                                        borderRadius: '5px',
                                        borderWidth: 0,
                                        width: '66vw',
                                        marginBottom: '0.5rem',
                                        opacity: this.state.isSubmitting
                                            ? 0.6
                                            : 1,
                                    }}
                                    type="submit"
                                    disabled={this.state.isSubmitting}
                                >
                                    Pay With Credit Card
                                </button>
                            </div>
                            <hr />
                            <div id="applepaybutton"></div>
                            <div id="googlepaybutton"></div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        state,
        isCartEmpty: !state.order.temporary_order.length,
        endpoint: state.main.restaurant_info.endpoint,
    }),
    {
        processNMIToken: OrderActions.processNMIToken,
    }
)(PayNMI);
