import React from 'react';
import { connect } from 'react-redux';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import * as TableActions from '../../../actions/tableActions';
import * as OrderActions from '../../../actions/orderActions';
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';

class PhoneNumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_number_error: '',
            number_submitted: false,
            show_keyboard: true,
        };
    }

    endOrder() {
        const endpoint = this.props.state.main.restaurant_info.endpoint;
        this.props.clearAllOrders();
        this.props.history.push(`/${endpoint}.kiosk`);
    }

    renderComplete() {
        return (
            <div
                className="to-page-container to-page-container-dark"
                onClick={(e) => this.endOrder()}
            >
                <div className="to-page-inner-container to-page-inner-container-dark">
                    <img
                        alt="GK Logo"
                        src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                        className="to-page-review-logo"
                    ></img>
                    <div className="to-page-review-heading">
                        Order complete.
                    </div>
                    <div className="to-page-order-success">
                        We've sent you a text receipt. See you again!
                    </div>

                    <div
                        className="to-page-order-success"
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            height: '300px',
                        }}
                    >
                        Tap anywhere to start a new order.
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.number_submitted) {
            return this.renderComplete();
        }

        return (
            <div className="to-page-container to-page-container-dark">
                <div className="to-page-inner-container to-page-inner-container-dark">
                    <img
                        alt="GK Logo"
                        src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                        className="to-page-review-logo"
                    ></img>
                    <div className="to-page-review-heading">
                        Enter your phone number
                    </div>
                    <div className="to-page-order-success">
                        We'll text you a receipt
                    </div>

                    <PhoneInput
                        placeholder="(647) 000-0000"
                        defaultCountry="CA"
                        value={
                            this.props.state.table.selected_guest.phone_number
                        }
                        style={{
                            width: 'calc(100% - 30px)',
                            fontSize: '40px',
                            backgroundColor: '#F7F7F7',
                            borderBottom: '5px solid #DADADA',
                        }}
                        inputClassName="tablet-phone-input"
                        onChange={(val) => {
                            this.setState({ phone_number_error: '' });
                            this.props.updatePhoneNumber(val);
                        }}
                    />

                    <div style={{ fontSize: '26px' }}>
                        {this.state.phone_number_error}
                    </div>

                    <div
                        className="to-order-save"
                        style={{ marginTop: '30px', width: '800px' }}
                    >
                        <div
                            className="to-item-save-button to-order-save-button  to-item-cancel"
                            onClick={(e) => {
                                this.props.history.push(
                                    '/kiosk/paymentComplete'
                                );
                            }}
                        >
                            Back
                        </div>
                        <div
                            className="to-item-save-button to-order-save-button"
                            onClick={(e) => {
                                if (
                                    isValidPhoneNumber(
                                        this.props.state.table.selected_guest
                                            .phone_number
                                    )
                                ) {
                                    this.props.submitPhoneNumber();
                                    this.setState({
                                        number_submitted: true,
                                    });
                                } else {
                                    this.setState({
                                        phone_number_error:
                                            'Sorry, invalid phone number. Please try again.',
                                    });
                                }
                            }}
                        >
                            Done
                        </div>
                    </div>

                    {this.state.show_keyboard ? (
                        <Keyboard
                            onChange={(value) =>
                                this.props.updatePhoneNumber(value)
                            }
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    updatePhoneNumber: TableActions.updatePhoneNumber,
    submitPhoneNumber: TableActions.submitPhoneNumber,

    clearAllOrders: OrderActions.clearAllOrders,
})(PhoneNumberInput);
