import React, { useEffect } from 'react';

const ToS = () => {
    useEffect(() => {
        window.open('/documents/ToS.html', '_self');
    }, [])
    return (
        <>Redirecting...</>
    );
};
export default ToS;
