import React from 'react';
import { useState } from 'react';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import axios from 'axios';
import { APIv1Endpoint } from '../../endpoints';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import CurbsidePickupComplete from './CurbsidePickupComplete';
import { Alert } from '@material-ui/lab';

//var success = false; //temporary variable to test things out

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
    },
    textField: {
        margin: '10px',
        textAlign: 'center',
        backgroundColor: '#B8B8B8',
    },
    header: {
        fontWeight: 'bold',
        fontSize: '1rem',
        marginTop: '7%',
        fontFamily: 'Poppins',
    },

    title: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        marginTop: 'auto',
        fontFamily: 'Poppins',
        color: '#101010',
    },
    error: {
        fontFamily: 'Poppins',
        fontSize: '0.7rem',
        fontWeight: 'bold',
        color: '#F84E4E',
        textAlign: 'right',
        marginLeft: 'auto',
        marginTop: 'auto',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
});

export default (props) => {
    const classes = useStyles();
    const Schema = Yup.object().shape({
        table_id: Yup.string()
            .min(4, '')
            .max(4, '')
            .required('Order ID required'),
        curb_num: Yup.string().min(1, '').required('Curbside Number Required'),
    });

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [axiosError, setAxiosError] = useState(false);
    const [curbError, setCurbError] = useState(false);
    const [restaurantEndpoint, setRestaurantEndpoint] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [curbNumber, setCurbNumber] = useState(-1);

    //there was an issue contacting the backend api
    function callAxiosError() {
        setAxiosError(true);
        setTimeout(() => {
            //we display an error message for 4 seconds
            setAxiosError(false);
        }, 5000);
    }

    if (success) {
        return (
            <CurbsidePickupComplete
                orderNumber={orderNumber}
                curbsideNumber={curbNumber}
                endpoint={restaurantEndpoint}
            ></CurbsidePickupComplete>
        );
    }
    return (
        <div className={classes.container}>
            <div
                className={classes.rowContainer}
                style={{
                    padding: '2rem 1rem 2rem 0rem',
                    left: 'auto',
                }}
            >
                <img
                    alt="car.png"
                    src="/assets/car.png"
                    style={{
                        width: '3.5rem',
                        height: '2.5rem',
                        marginTop: 'auto',
                        padding: '0.5rem',
                        marginRight: '0.5rem',
                    }}
                ></img>
                <div className={classes.title}> Curbside Pickup </div>
            </div>

            <Formik
                style={{
                    width: '100%',
                }}
                initialValues={{
                    restaurant_id: '',
                    table_id: '',
                    guest_name: '',
                    curb_num: -1,
                }}
                validationSchema={Schema}
                onSubmit={(values) => {
                    if (values.curb_num < 0) {
                        //handle this error
                        setCurbError(true);
                    } else {
                        setCurbError(false);
                        values.table_id = values.table_id?.toUpperCase();

                        // get table orders
                        axios
                            .get(
                                `${APIv1Endpoint}table/${values.table_id}/orders`
                            )
                            .then((res) => {
                                let r_id = '';
                                if (res.data.orders.length > 0) {
                                    r_id = res.data.orders[0].restaurant_id;

                                    axios
                                        .get(
                                            `${APIv1Endpoint}restaurant/${r_id}/details`
                                        )
                                        .then((restaurantDetailsResponse) => {
                                            if (
                                                restaurantDetailsResponse.data
                                                    .restaurant
                                            ) {
                                                setRestaurantEndpoint(
                                                    restaurantDetailsResponse
                                                        .data.restaurant
                                                        .endpoint
                                                );

                                                axios
                                                    .post(
                                                        `${APIv1Endpoint}table/callWaiter`,
                                                        {
                                                            ...values,
                                                            restaurant_id: r_id,
                                                            request: `CURBSIDE PICKUP - PARKING #${values.curb_num}`,
                                                        }
                                                    )
                                                    .then((res) => {
                                                        setCurbNumber(
                                                            values.curb_num
                                                        );
                                                        setOrderNumber(
                                                            values.table_id
                                                        );
                                                        setSuccess(true);
                                                    })
                                                    .catch((err) => {
                                                        callAxiosError();
                                                    });
                                            } else {
                                                callAxiosError();
                                                //then the restaurant failed
                                            }
                                        })
                                        .catch((err) => {
                                            callAxiosError();
                                            //then the axios .get failed
                                            ///alert(err);
                                        });

                                    setError(false);
                                } else {
                                    setError(true);
                                    return;
                                }
                            })
                            .catch((err) => {
                                callAxiosError();
                            });
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => {
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div
                                    className={classes.columnContainer}
                                    style={{ height: '100%', maxWidth: 500 }}
                                >
                                    <div>
                                        <div className={classes.rowContainer}>
                                            <div className={classes.header}>
                                                Order Number:
                                            </div>
                                            {errors.table_id &&
                                            touched.table_id ? (
                                                (setError(false),
                                                (
                                                    <div
                                                        className={
                                                            classes.error
                                                        }
                                                    >
                                                        {errors.table_id}
                                                    </div>
                                                ))
                                            ) : error ? (
                                                <div className={classes.error}>
                                                    Invalid Order Number
                                                </div>
                                            ) : null}
                                        </div>

                                        <TextField
                                            name="table_id"
                                            variant="outlined"
                                            onChange={handleChange}
                                            placeholder="ABCD"
                                            inputProps={{
                                                style: {
                                                    textTransform: 'uppercase',
                                                    textAlign: 'center',
                                                    fontSize: '2rem',
                                                    fontFamily: 'Poppins',
                                                },
                                            }}
                                            style={{
                                                backgroundColor: '#F2F2F2',
                                                marginTop: 10,
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className={classes.rowContainer}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <div className={classes.header}>
                                                    Curbside Number:
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: '0.7rem',
                                                        color: '#8A8A8A',
                                                    }}
                                                >
                                                    Where are you parked?
                                                </div>
                                            </div>
                                            {errors.curb_num &&
                                            touched.curb_num ? (
                                                <div className={classes.error}>
                                                    {errors.curb_num}
                                                </div>
                                            ) : curbError ? (
                                                <div className={classes.error}>
                                                    Curbside Number Required
                                                </div>
                                            ) : null}
                                        </div>
                                        <TextField
                                            name="curb_num"
                                            variant="outlined"
                                            onChange={handleChange}
                                            placeholder="2"
                                            style={{
                                                backgroundColor: '#F2F2F2',
                                            }}
                                            inputProps={{
                                                // disableUnderline: true,
                                                style: {
                                                    textAlign: 'center',
                                                    fontSize: '2rem',
                                                    fontFamily: 'Poppins',
                                                },
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            padding: '1rem',
                                        }}
                                    >
                                        {axiosError ? (
                                            <Alert severity="error">
                                                There was an error communicating
                                                with the server.
                                            </Alert>
                                        ) : null}
                                    </div>

                                    <div
                                        style={{
                                            float: 'right',
                                            alignSelf: 'flex-end',
                                            flexGrow: 1,
                                            flex: 1,
                                            position: 'absolute',
                                            bottom: '4rem',
                                        }}
                                    >
                                        <Button
                                            style={{
                                                width: 150,
                                                backgroundColor: '#0E79B5',
                                                textTransform: 'none',
                                                color: '#ffffff',
                                                borderRadius: '2rem',
                                                padding: '1rem',
                                                fontWeight: '700',
                                                float: 'right',
                                                position: 'relative',
                                            }}
                                            onClick={handleSubmit}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};
