import React from 'react';
import { CircularProgress } from '@material-ui/core';
import './css/LoadingScreen.css';

export default () => {
    return (
        <div className="loadingScreenTopContainer">
            <div className="loadingScreenContainer">
                <CircularProgress />
            </div>
        </div>
    );
};
