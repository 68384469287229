import React from 'react';
import { connect } from 'react-redux';

import './css/HealthAndSafetyForm.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import * as TableActions from '../actions/tableActions';
import * as yup from 'yup';
import Axios from 'axios';
import { APIv1Endpoint } from '../endpoints';
import { toast } from 'react-toastify';
import { PaymentType } from '../reducers/TYPES';
import OGRADYS_TEXT from './OGradysHealthAndSafetyForm';
import { isAbsinthe } from '../utils';
import { i18n } from '../locales';
import { withTranslation } from 'react-i18next';

class HealthAndSafetyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            f_name: '',
            l_name: '',
            phone: '',

            errors: [],
            phone_number_error: [],
            is_submitting_phone_number: false,

            page: 1,
        };
    }

    confirmName() {
        // check
        const schema = yup.object().shape({
            l_name: yup
                .string()
                .required(this.props.t('safetyForm.lastNameRequired'))
                .min(2, this.props.t('safetyForm.nameLengthMinimum')),
            f_name: yup
                .string()
                .required(this.props.t('safetyForm.firstNameRequired'))
                .min(2, this.props.t('safetyForm.nameLengthMinimum')),
        });

        let l_name = this.state.l_name;
        if (isAbsinthe() && !l_name.includes('Table #')) {
            // Prefix with table #
            l_name = `${this.props.t('tableSelect.table')} #${l_name}`;
            this.setState({ l_name });
        }

        schema
            .validate({ f_name: this.state.f_name, l_name: l_name })
            .then((res) => {
                this.setState({ page: 2, errors: [] });
                this.phone_number_input.focus();
            })
            .catch((err) => {
                console.error(err);
                this.setState({ errors: err.errors });

                // scroller.scrollTo('f_name', {
                //     duration: 1250,
                //     delay: 0,
                //     smooth: "easeInOutQuart",
                //     offset: -80,
                // });
            });
    }

    submitInformation() {
        const phone_number = this.state.phone;
        const { f_name, l_name } = this.state;

        if (!phone_number || !isValidPhoneNumber(phone_number)) {
            this.setState({ phone_number_error: ['Invalid phone number'] });
            return;
        }

        this.setState({ is_submitting_phone_number: true });

        this.setState({ phone_number_error: [] });
        Axios.post(`${APIv1Endpoint}guest/update`, {
            guest: {
                ...this.props.state.table.selected_guest,
                name: `${f_name} ${l_name}`,
                phone_number,
            },
        })
            .then((res) => {
                this.setState({ page: 3, is_submitting_phone_number: false });
            })
            .catch((err) => {
                console.error(err);
                toast.error(i18n.t('toast.addPhoneNumberError'));
            });
    }

    renderPage1() {
        const show_long_form =
            this.props.state.main.restaurant_info.restaurant_id ===
            '163c3613-ada4-11ea-927c-069f49d95199';
        const container_cls = show_long_form ? 'scrollFormContainer' : '';

        const firstNameLabel = isAbsinthe() ? 'Name' : 'First name';
        const lastNameLabel = isAbsinthe() ? 'Table #' : 'Last name';

        let health_safety_country = 'Canadian';
        if (
            (
                this.props.state.main.restaurant_info.currency || ''
            ).toLowerCase() === 'usd'
        ) {
            health_safety_country = 'American';
        }

        return (
            <div className="healthAndSafetyContainer">
                <div className="healthAndSafetyHeader">
                    {/* Show health form if dine-in or in development */}
                    {this.props.state.order.payment_type !==
                        PaymentType.PAY_IMMEDIATE ||
                    !process.env.NODE_ENV ||
                    process.env.NODE_ENV === 'development' ? (
                        <>
                            <div
                                className="has-header-button al"
                                onClick={(e) =>
                                    this.props.showHideHealthForm(false)
                                }
                            >
                                {this.props.t('common.cancel')}
                            </div>
                        </>
                    ) : (
                        <div className="has-header-button al"></div>
                    )}

                    <div className="has-left">
                        <img
                            alt="heart"
                            src="/assets/icons/heart-red.png"
                            className="has-heart"
                        ></img>
                        <div className="has-title">
                            {this.props.t('safetyForm.healthAndSafety')}
                        </div>
                    </div>
                    <div
                        className="has-header-button ar"
                        onClick={(e) => this.confirmName()}
                    >
                        {this.props.t('tableSelect.next')}
                    </div>
                </div>
                <div className={`${container_cls}`}>
                    <div className="has-description">
                        {this.props.t('safetyForm.healthSafetyRegulations', {
                            value: health_safety_country,
                        })}
                        {show_long_form ? (
                            <div className="has-description-long">
                                {OGRADYS_TEXT}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>

                    <div className="has-form-multiple">
                        <div className="has-form-input-container">
                            <div className="has-form-input-label">
                                {firstNameLabel}
                            </div>
                            <input
                                type="text"
                                className="has-form-input"
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.lastNameRef.focus();
                                    }
                                }}
                                value={this.state.f_name}
                                onChange={(e) =>
                                    this.setState({ f_name: e.target.value })
                                }
                            ></input>
                        </div>

                        <div className="has-form-input-container">
                            <div className="has-form-input-label">
                                {lastNameLabel}
                            </div>
                            <input
                                type="text"
                                className="has-form-input"
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.confirmName();
                                    }
                                }}
                                ref={(ref) => (this.lastNameRef = ref)}
                                value={this.state.l_name}
                                onChange={(e) =>
                                    this.setState({ l_name: e.target.value })
                                }
                            ></input>
                        </div>
                    </div>

                    {Array.isArray(this.state.errors) &&
                    this.state.errors.length > 0 ? (
                        <div className="errorMessage">
                            {this.state.errors[0]}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }

    renderPage2() {
        const default_country =
            this.props.state.main.restaurant_info.restaurant_id ===
            '2993daf5-c798-11ea-b545-02685a4295ea'
                ? 'US'
                : 'CA';

        return (
            <div className="healthAndSafetyContainer">
                <div className="healthAndSafetyHeader">
                    <div
                        className="has-header-button al"
                        onClick={(e) => this.setState({ page: 1 })}
                    >
                        {this.props.t('common.back')}
                    </div>
                    <div className="has-left">
                        <img
                            alt="heart"
                            src="/assets/icons/heart-red.png"
                            className="has-heart"
                        ></img>
                        <div className="has-title">Health & Safety</div>
                    </div>
                    {this.state.is_submitting_phone_number ? (
                        <div>
                            <div className="healthSpinner">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="has-header-button ar"
                            onClick={(e) => this.submitInformation()}
                        >
                            {this.props.t('common.done')}
                        </div>
                    )}
                </div>

                <div className="has-form-multiple">
                    <div className="has-form-input-container">
                        <div className="has-form-input-label">
                            {this.props.t('safetyForm.phoneNumber')}
                        </div>

                        <PhoneInput
                            placeholder="(647) 000-0000"
                            defaultCountry={default_country}
                            value={this.state.phone}
                            onChange={(e) => this.setState({ phone: e })}
                            style={{
                                width: 'calc(100% - 40px)',
                                backgroundColor: '#F7F7F7',
                                borderBottom: '5px solid #DADADA',
                            }}
                        />
                    </div>
                </div>

                {Array.isArray(this.state.phone_number_error) &&
                this.state.phone_number_error.length > 0 ? (
                    <div className="errorMessage">
                        {this.state.phone_number_error[0]}
                    </div>
                ) : (
                    ''
                )}

                <div className="privacyPolicyContainer">
                    {this.props.t('safetyForm.doneAgreement')}
                    <span
                        className="privacyLabel"
                        onClick={(e) => window.open('/privacy', '_blank')}
                    >
                        {this.props.t('safetyForm.privacyPolicy')}
                    </span>
                </div>
            </div>
        );
    }

    renderPage3() {
        return (
            <div className="healthAndSafetyContainer">
                <div className="healthAndSafetyHeader">
                    <div
                        className="has-header-button al"
                        onClick={(e) => this.props.showHideHealthForm(false)}
                    ></div>
                    <div className="has-left">
                        <img
                            alt="heart"
                            src="/assets/icons/heart-red.png"
                            className="has-heart"
                        ></img>
                        <div className="has-title">
                            {this.props.t('safetyForm.healthAndSafety')}
                        </div>
                    </div>
                    <div
                        className="has-header-button ar"
                        onClick={(e) => {
                            this.props.loadGuestFromDB();
                            this.props.showHideHealthForm(false);
                        }}
                    >
                        {this.props.t('oneMenu.close')}
                    </div>
                </div>

                <div className="has-thank-you">
                    {this.props.t('common.ty')}!
                </div>
                <div className="has-thank-you-image"></div>
            </div>
        );
    }

    render() {
        // If guest has a valid phone number, then they dont need to show the health form
        const guest = this.props.state.table.selected_guest || {}; // Possible that the guest has not been fetched yet
        if (isValidPhoneNumber(guest.phone_number || '')) {
            return <></>;
        }

        const showHealthForm =
            this.props.state.main.restaurant_info.is_display_health_form;
        if (!showHealthForm) {
            return <div></div>;
        }
        return (
            <div className="healthAndSafetyBackground">
                {this.state.page === 1 ? this.renderPage1() : ''}
                {this.state.page === 2 ? this.renderPage2() : ''}
                {this.state.page === 3 ? this.renderPage3() : ''}
            </div>
        );
    }
}

const mstp = (state) => ({ state });
const EnhancedHealthAndSafetyForm = withTranslation()(
    connect(mstp, {
        submitPhoneNumber: TableActions.submitPhoneNumber,
        showHideHealthForm: TableActions.showHideHealthForm,
        loadGuestFromDB: TableActions.loadGuestFromDB,
    })(HealthAndSafetyForm)
);

// Export the wrapped component as the default export
export default EnhancedHealthAndSafetyForm;
