import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from '../../../components/Header';
import { withTheme } from '../../../themes/theming';

import * as OrderActions from '../../../actions/orderActions';

import {
    PaymentRequestButtonElement,
    useElements,
    CardElement,
    useStripe,
    Elements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { GAEvent } from '../../../googleAnalytics';
import { loadStripe } from '@stripe/stripe-js';
import { isTakeoutOrder, isDineinOrder } from '../../../utils';
import * as Types from '../../../reducers/TYPES';
import PayNuvei from './PayNuvei';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import { i18n } from '../../../locales';
import { ENV } from '../../../endpoints';

const PaymentMethods = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const intent = props.state.order.payment_intent;
    const theme = props.theme;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const is_tablet = props.state.table.is_tablet;
    const showFields = intent && Object.keys(intent).length > 0;

    const { paymentComplete, history } = props;

    useEffect(() => {
        // ensure stripe and intent object exist
        if (stripe && Object.keys(intent).length > 0) {
            const pr = stripe.paymentRequest({
                country: 'CA',
                currency: intent.currency || 'cad',
                total: {
                    label: 'Mentum Order',
                    amount: intent.amount,
                },
                requestPayerName: true,
                requestPayerPhone: true,
            });

            pr.canMakePayment()
                .then((result) => {
                    if (result) {
                        setPaymentRequest(pr);
                        pr.on('paymentmethod', async (ev) => {
                            const { error: confirmError } =
                                await stripe.confirmCardPayment(
                                    intent.client_secret,
                                    { payment_method: ev.paymentMethod.id },
                                    { handleActions: false }
                                );

                            if (confirmError) {
                                ev.complete('fail');
                                // Show payment failed due to cancellation
                                console.error(confirmError);
                                history.push('/order');
                                toast.error(i18n.t('toast.errorNotification3'));

                                GAEvent(
                                    'PaymentError',
                                    'ApplePay: Unable to make payment (confirmError)',
                                    JSON.stringify(confirmError)
                                );
                            } else {
                                // check whether payment was processed
                                ev.complete('success');

                                const result = await stripe.confirmCardPayment(
                                    intent.client_secret
                                );
                                if (result.error) {
                                    // payment failed due to payment method
                                    console.log('payment method error');
                                    history.push('/order');
                                    toast.error(i18n.t('errorNotification2'), {
                                        autoClose: false,
                                    });
                                    GAEvent(
                                        'PaymentError',
                                        'ApplePay: Unable to make payment (confirmCardPayment)',
                                        JSON.stringify(result.error)
                                    );
                                } else {
                                    // payment complete
                                    console.log('done!');
                                    console.log(result.paymentIntent);
                                    if (
                                        result.paymentIntent.status ===
                                        'succeeded'
                                    ) {
                                        paymentComplete(
                                            result.paymentIntent,
                                            history,
                                            true
                                        );
                                        GAEvent(
                                            'Payment',
                                            'Payment complete (using Apple Pay/GPay/Microsoft Pay)',
                                            ''
                                        );
                                    }
                                }
                            }
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [stripe, intent, paymentComplete, history]);

    const renderApplePay = () => {
        return (
            <div className="paymentContainer">
                {paymentRequest ? (
                    <div className="applePayContainer">
                        <PaymentRequestButtonElement
                            options={{ paymentRequest }}
                        ></PaymentRequestButtonElement>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const onCardChange = (evt) => {
        if (evt.error) {
            toast.error(evt.error);
        }
    };

    const onCardSubmit = async (evt) => {
        evt.preventDefault();
        setIsSubmitting(true);

        const result = await stripe.confirmCardPayment(intent.client_secret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: evt.target.name.value,
                },
            },
        });
        setIsSubmitting(false);
        if (result.error) {
            console.error(result.error);
            // props.history.push('/order');
            // toast.error('Sorry, we could not complete your request. Please try again.')
            toast.dismiss('cc-error');
            toast.error(result.error.message, { toastId: 'cc-error' });
            GAEvent(
                'PaymentError',
                'CreditCard: Unable to make payment (confirmCardPayment)',
                JSON.stringify(result.error)
            );
        } else {
            console.log('done!');
            console.log(result.paymentIntent);
            if (result.paymentIntent.status === 'succeeded') {
                props.paymentComplete(
                    result.paymentIntent,
                    props.history,
                    true
                );
                GAEvent('Payment', 'Payment complete (using Credit Card)', '');
            }
        }
    };

    const cardStyle = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: 'Roboto, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#32325d',
                },
                ...theme.paymentCardElementStyle,
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    const renderCreditCardInput = () => {
        return (
            <div>
                {showFields ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {!is_tablet ? (
                            <div
                                style={{
                                    textAlign: 'center',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    margin: '20px',
                                    marginBottom: '0',
                                    backgroundColor: '#eee',
                                    color: '#333',
                                }}
                            >
                                <img
                                    style={{ width: '20px', padding: '10px' }}
                                    src="/assets/icons/lock-black.png"
                                    alt=""
                                />
                                <div style={{ padding: '5px' }}>
                                    Your payment is secured by Stripe.
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {renderTotal()}

                        <form
                            data-cy="credit-card-input"
                            className="paymentContainer"
                            style={theme.paymentContainer}
                            onSubmit={onCardSubmit}
                        >
                            <CardElement
                                id="card-element"
                                options={cardStyle}
                                onChange={onCardChange}
                            ></CardElement>
                            <button
                                data-cy="pay-with-credit-card-button"
                                className="paymentSubmitButton"
                                style={theme.paymentSubmitButton}
                                id="submit"
                                disabled={isSubmitting}
                            >
                                <span id="button-text">
                                    Pay with Credit Card
                                </span>
                            </button>
                        </form>
                        <img
                            style={{ height: '40px' }}
                            src="/assets/icons/cards.svg"
                            alt=""
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const renderTotal = () => {
        return (
            <div>
                {showFields ? (
                    <div className="youPayContainer">
                        <div className="youPayLabel" style={theme.youPayLabel}>
                            You Pay
                        </div>
                        <div className="youPayPrice" style={theme.youPayPrice}>
                            ${(intent.amount / 100).toFixed(2)}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const renderSpinner = () => {
        return (
            <div>
                {!showFields ? (
                    <div>
                        <div className="cssload-container">
                            <div className="cssload-whirlpool"></div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const renderPayAtCounter = () => {
        // check if pay at counter is disabled
        const payAtCounterSetting =
            props.state.main.restaurant_info.pay_at_counter;
        if (
            isTakeoutOrder() &&
            [
                'RESTAURANT_SETTING_DINE_IN_ONLY',
                'RESTAURANT_SETTING_NONE',
            ].includes(payAtCounterSetting)
        ) {
            return;
        }
        if (
            isDineinOrder() &&
            [
                'RESTAURANT_SETTING_TAKE_OUT_ONLY',
                'RESTAURANT_SETTING_NONE',
            ].includes(payAtCounterSetting)
        ) {
            return;
        }

        // do not show pay at counter if the order is dine-in (would not make sense, since the order has already gone through)
        if (!isTakeoutOrder()) {
            return;
        }

        let payAtCounterText = 'Pay at the front counter.';
        if (
            [
                '53fed4cc-c896-11ea-a655-02685a4295ea',
                '93cc4bee-caf7-11ea-86b4-02685a4295ea',
                'b9e956bf-caf7-11ea-86b4-02685a4295ea',
            ].includes(props.state.main.restaurant_info.restaurant_id)
        ) {
            payAtCounterText = 'Please pay at cashier first. Thank you!';
        }

        const payAtCounterLoading = props.state.order.pay_at_counter_loading;

        return (
            <div className="payAtCounterContainer">
                {!is_tablet ? (
                    <div className="payAtCounterText">{payAtCounterText}</div>
                ) : (
                    ''
                )}
                <div
                    className="payAtCounterButton"
                    data-cy="payAtCounterButton"
                    onClick={(e) => {
                        if (!payAtCounterLoading) {
                            props.payAtCounter(props.history, true, true, true);
                        }
                    }}
                >
                    {payAtCounterLoading ? 'Please wait...' : 'Pay at Counter'}
                </div>
            </div>
        );
    };

    const isPaymentEnabled =
        (isTakeoutOrder() &&
            [
                'RESTAURANT_SETTING_TAKE_OUT_ONLY',
                'RESTAURANT_SETTING_TAKE_OUT_AND_DINE_IN',
            ].includes(props.state.main.restaurant_info.stripe_payments)) ||
        (!isTakeoutOrder() && [
            'RESTAURANT_SETTING_DINE_IN_ONLY',
            'RESTAURANT_SETTING_TAKE_OUT_AND_DINE_IN',
        ]);

    const stripe_connected_acct = props.state.main.restaurant_info.stripe_acct;

    return (
        <div>
            <Header back=":goBack" history={props.history}></Header>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {isPaymentEnabled && stripe_connected_acct ? (
                    <div className="paymentContainerDesktop">
                        {renderSpinner()}
                        {renderCreditCardInput()}
                        {renderApplePay()}
                    </div>
                ) : (
                    <div>{renderTotal()}</div>
                )}

                {renderPayAtCounter()}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { state };
};

const PaymentMethodsUI = connect(mapStateToProps, {
    paymentComplete: OrderActions.paymentComplete,
    payAtCounter: OrderActions.payAtCounter,
})(withTheme(PaymentMethods));

class Pay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stripePromise: null,
            paymentProvider: Types.PaymentProcessor.NONE,
        };
    }

    componentDidMount() {
        const cart_items = this.props.state.order.temporary_order;
        const cartEmpty = !Array.isArray(cart_items) || cart_items.length === 0;
        if (cartEmpty) {
            return;
        }

        switch (this.props.state.order.payment_processor) {
            case Types.PaymentProcessor.STRIPE:
                let stripePromise;
                const stripe_connected_acct =
                    this.props.state.main.restaurant_info.stripe_acct;
                const stripe_public_key =
                    ENV === 'development'
                        ? 'pk_test_5VjAbQTuGVzCv5HvWZIZCrGf00LVhQ90BG'
                        : 'pk_live_51GLhHrCZg0xFw8bMjfdxjcX0uZQq4vF3vsKse0lYko0XtshyZDP2HZYMCfuCFhE3ZwMCAiI1NSboJo748NjDJgfJ002fPidB26';

                stripePromise = loadStripe(stripe_public_key, {
                    stripeAccount: stripe_connected_acct,
                });

                this.setState({
                    stripePromise,
                    paymentProvider: Types.PaymentProcessor.STRIPE,
                });
                break;
            case Types.PaymentProcessor.NUVEI:
                this.setState({
                    paymentProvider: Types.PaymentProcessor.NUVEI,
                });
                break;
            case Types.PaymentProcessor.NONE:
                this.props.history.push('/order');
                Sentry.setContext('state', { ...this.props.state });
                Sentry.captureException();
                toast.warning(i18n.t('toast.errorNotification1'));
                break;
            default:
                break;
        }
    }

    render() {
        const endpoint = this.props.match.params.endpoint;
        const cart_items = this.props.state.order.temporary_order;
        const cartEmpty = !Array.isArray(cart_items) || cart_items.length === 0;
        if (cartEmpty) {
            this.props.history.replace(`/v2/${endpoint}/order`);
        }

        if (
            this.state.stripePromise &&
            this.state.paymentProvider === Types.PaymentProcessor.STRIPE
        ) {
            return (
                <>
                    <Elements stripe={this.state.stripePromise}>
                        <PaymentMethodsUI
                            history={this.props.history}
                        ></PaymentMethodsUI>
                    </Elements>
                </>
            );
        }

        if (this.state.paymentProvider === Types.PaymentProcessor.NUVEI) {
            return (
                <div className="nuveiPaymentContainer">
                    <Header back="/order" history={this.props.history} />
                    <PayNuvei></PayNuvei>
                </div>
            );
        }

        return <>Error</>;
    }
}

export default connect(mapStateToProps)(Pay);
