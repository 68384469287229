import { APIv1Endpoint } from './endpoints';
import { loadStripeTerminal } from '@stripe/terminal-js';
import Axios from 'axios';
import { store } from './reduxStore';
import * as Types from './reducers/TYPES';
import { isDevEnvironment } from './utils';

class PhysicalStripeTerminal {
    constructor() {
        this.reconnectInterval = null;
    }

    onDisconnect() {
        console.error('Terminal unexpectedly disconnected');
        // this.disconnectFromReader();
        // attempt to reconnect
        store.dispatch({
            type: Types.SET_TERMINAL_CONNECTION_ERROR,
            payload:
                'Terminal unexpectedly disconnected. Attempting to reconnect...',
        });
        if (this && this.automateRetryConnection) {
            this.automateRetryConnection();
        }
    }

    automateRetryConnection() {
        if (!this.reconnectInterval) {
            this.reconnectToReader();

            this.reconnectInterval = setInterval(() => {
                console.log('Attempting to reconnect');
                store.dispatch({
                    type: Types.SET_TERMINAL_CONNECTION_ERROR,
                    payload: 'Attempting to reconnect to reader...',
                });
                this.reconnectToReader();
            }, 30000);
        }
    }

    isSetup() {
        return this.terminal ? true : false;
    }

    isReaderConnected() {
        if (!this.terminal) {
            return false;
        }
        return this.terminal.getConnectionStatus() === 'connected';
    }

    showTerminalRegistrationScreen() {
        store.dispatch({
            type: Types.TERMINAL_IS_CONNECTING,
            payload: false,
        });
        store.dispatch({
            type: Types.TERMINAL_SET_CONNECTED,
            payload: false,
        });
        store.dispatch({
            type: Types.SET_READER_DISABLED,
            payload: false,
        });
    }

    cancelPayment() {
        this.terminal.cancelCollectPaymentMethod().then((res) => {
            if (res.error) {
                console.error(res.error);
            }
            console.log('Cancelled');
        });
    }

    async setup(restaurant_id) {
        // check if already connected
        if (this.terminal && this.isReaderConnected()) {
            return;
        }

        if (this.reconnectInterval) {
            clearInterval(this.reconnectInterval);
        }

        const requestConnectionToken = (r_id) => {
            if (!r_id) {
                console.error('r_id is empty');
                store.dispatch({
                    type: Types.SET_TERMINAL_CONNECTION_ERROR,
                    payload: 'Could not retrieve connection token',
                });
                return;
            }

            return fetch(`${APIv1Endpoint}terminal/${r_id}/connectionToken`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    return data.token;
                })
                .catch((err) => {
                    console.error(err);
                    store.dispatch({
                        type: Types.SET_TERMINAL_CONNECTION_ERROR,
                        payload: 'Could not retrieve connection token',
                    });
                });
        };

        const StripeTerminal = await loadStripeTerminal();

        const terminal = StripeTerminal.create({
            onFetchConnectionToken: () => {
                return requestConnectionToken(restaurant_id);
            },
            onUnexpectedReaderDisconnect: () => {
                this.onDisconnect();
            },
        });

        this.terminal = terminal;

        // load readers
        this.loadReaders();
    }

    getTerminal() {
        if (!this.terminal) {
            console.error('Terminal is not setup!');
        }
        return this.terminal;
    }

    getConnectedReader() {
        if (!this.terminal) {
            return;
        }
        return this.terminal.getConnectedReader();
    }

    processPaymentIntent(intent) {
        if (!this.terminal) {
            console.error('Terminal is not setup!');
            return;
        }

        store.dispatch({
            type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
            payload: null,
        });

        store.dispatch({
            type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
            payload: Types.TerminalPaymentStatus.PROCESSING,
        });

        const client_secret = intent.client_secret;

        //collect payment method
        this.terminal
            .collectPaymentMethod(client_secret)
            .then((res) => {
                if (res.error) {
                    console.error(res.error);
                    store.dispatch({
                        type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                        payload: Types.TerminalPaymentStatus.FAILED,
                    });

                    store.dispatch({
                        type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
                        payload: res.error.message,
                    });
                } else {
                    console.log(`Intent success`);
                    console.log(res.paymentIntent);

                    const restaurant_id =
                        store.getState().main.restaurant_info.restaurant_id;

                    // PROCESS PAYMENT
                    this.terminal
                        .processPayment(res.paymentIntent)
                        .then((processResult) => {
                            if (processResult.error) {
                                console.error(processResult.error);
                                store.dispatch({
                                    type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                                    payload: Types.TerminalPaymentStatus.FAILED,
                                });
                                store.dispatch({
                                    type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
                                    payload: processResult.error.message,
                                });
                            } else if (processResult.paymentIntent) {
                                store.dispatch({
                                    type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                                    payload:
                                        Types.TerminalPaymentStatus.COMPLETE,
                                });

                                // check if intent requires capture
                                if (
                                    processResult.paymentIntent.status ===
                                    'requires_capture'
                                ) {
                                    console.log('Customer requies capture');
                                    Axios.post(
                                        `${APIv1Endpoint}terminal/capturePayment`,
                                        {
                                            restaurant_id: restaurant_id,
                                            payment_intent_id:
                                                res.paymentIntent.id,
                                        }
                                    )
                                        .then((res) => {
                                            console.log('Captured');
                                            store.dispatch({
                                                type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                                                payload:
                                                    Types.TerminalPaymentStatus
                                                        .COMPLETE,
                                            });
                                        })
                                        .catch((err) => {
                                            console.error(err.response);
                                            store.dispatch({
                                                type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                                                payload:
                                                    Types.TerminalPaymentStatus
                                                        .FAILED,
                                            });
                                            store.dispatch({
                                                type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
                                                payload: JSON.stringify(
                                                    err.response
                                                ),
                                            });
                                        });
                                }
                            }
                        });
                }
            })
            .catch((err) => {
                store.dispatch({
                    type: Types.TERMINAL_PAYMENT_STATUS_CHANGED,
                    payload: Types.TerminalPaymentStatus.FAILED,
                });
                store.dispatch({
                    type: Types.TERMINAL_PAYMENT_ERROR_CHANGED,
                    payload: JSON.stringify(err.error),
                });

                console.error(err);
                // attempt to reconnect to reader
                stripeTerminal.disconnectFromReader();
                if (err.code === 'reader_error') {
                    stripeTerminal.reconnectToReader();
                }
            });
    }

    disableReader() {
        store.dispatch({
            type: Types.SET_TERMINAL_CONNECTION_ERROR,
            payload: '',
        });
        store.dispatch({
            type: Types.SET_READER_DISABLED,
            payload: true,
        });
    }

    enableReader() {
        store.dispatch({
            type: Types.SET_READER_DISABLED,
            payload: false,
        });
    }

    disconnectFromReader() {
        if (!this.isSetup()) {
            return;
        }

        // prevent automatic reconnections
        clearInterval(this.reconnectInterval);

        this.enableReader();
        this.terminal.disconnectReader();

        store.dispatch({
            type: Types.TERMINAL_READER_CONNECTED,
            payload: null,
        });

        store.dispatch({
            type: Types.TERMINAL_SET_CONNECTED,
            payload: false,
        });

        store.dispatch({
            type: Types.TERMINAL_IS_CONNECTING,
            payload: false,
        });
    }

    reconnectToReader() {
        if (this.isReaderConnected()) {
            console.warn('Terminal already connected!');
            return;
        }

        // reconnect to previous reader
        const selected_reader = store.getState().kiosk.selected_reader;

        if (selected_reader && selected_reader.serial_number) {
            const serial_number = selected_reader.serial_number;

            // find reader from all readers
            let config = { simulated: isDevEnvironment() };
            this.terminal
                .discoverReaders(config)
                .then((readers) => {
                    readers = readers.discoveredReaders;

                    const search = readers.filter((r) => {
                        return r.serial_number === serial_number;
                    });

                    if (search.length === 1) {
                        const reader = search[0];
                        this.connectToReader(reader);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    getReader() {
        return store.getState().kiosk.selected_reader;
    }

    loadReaders() {
        if (!this.terminal) {
            console.error('Terminal is not setup!');
            return;
        }

        let config = { simulated: isDevEnvironment() };
        if (isDevEnvironment()) {
            this.terminal.setSimulatorConfiguration({
                testCardNumber: '4242424242424242',
            });
        }

        this.terminal.discoverReaders(config).then((discoverResult) => {
            if (discoverResult.error) {
                console.error(
                    `Could not discover readers: ${discoverResult.error}`
                );
                store.dispatch({
                    type: Types.SET_TERMINAL_CONNECTION_ERROR,
                    payload: discoverResult.error,
                });
            } else if (discoverResult.discoveredReaders.length === 0) {
                console.error(`No available readers`);
                store.dispatch({
                    type: Types.SET_TERMINAL_CONNECTION_ERROR,
                    payload: 'No available readers',
                });
            } else {
                store.dispatch({
                    type: Types.TERMINAL_READERS_LOADED,
                    payload: discoverResult.discoveredReaders,
                });

                this.reconnectToReader();
            }
        });
    }

    listAvailableReaders() {
        const readers = store.getState().kiosk.terminals;
        if (!readers) {
            return [];
        }

        return readers;
    }

    isConnectingToReader() {
        return store.getState().kiosk.is_connecting;
    }

    connectToReader(reader) {
        store.dispatch({
            type: Types.SET_TERMINAL_CONNECTION_ERROR,
            payload: '',
        });
        store.dispatch({
            type: Types.TERMINAL_IS_CONNECTING,
            payload: reader.serial_number,
        });

        if (!this.terminal) {
            console.error('Terminal not setup');
            return;
        }

        this.terminal
            .connectReader(reader)
            .then((connectionResult) => {
                store.dispatch({
                    type: Types.TERMINAL_IS_CONNECTING,
                    payload: false,
                });

                if (connectionResult.error) {
                    this.automateRetryConnection();
                    console.error(
                        `Failed to connect to reader`,
                        connectionResult.error
                    );
                    if (connectionResult.error.code === 'reader_error') {
                        // likely connection token has expired. try again
                        const restaurant_id =
                            store.getState().main.restaurant_info.restaurant_id;
                        this.setup(restaurant_id);
                    }
                    store.dispatch({
                        type: Types.SET_TERMINAL_CONNECTION_ERROR,
                        payload: connectionResult.error,
                    });
                } else {
                    console.log(
                        `Connected to reader ${connectionResult.reader.label}`
                    );
                    if (this.reconnectInterval) {
                        clearInterval(this.reconnectInterval);
                    }
                    console.log(connectionResult);
                    store.dispatch({
                        type: Types.TERMINAL_READER_CONNECTED,
                        payload: {
                            ...reader,
                            serial_number: reader.serial_number,
                        },
                    });
                    store.dispatch({
                        type: Types.TERMINAL_VENDOR_CHANGED,
                        payload: Types.TerminalVendors.STRIPE,
                    });
                    store.dispatch({
                        type: Types.TERMINAL_SET_CONNECTED,
                        payload: true,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                this.automateRetryConnection();
                store.dispatch({
                    type: Types.TERMINAL_IS_CONNECTING,
                    payload: false,
                });
            });
    }
}

let stripeTerminal = new PhysicalStripeTerminal();
export default stripeTerminal;
