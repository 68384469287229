import { Avatar, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadOrders } from '../../../actions/tableActions';
import { PaymentType } from '../../../reducers/TYPES';
import './css/DineInExistingOrder.css';
import './css/Loading.css';
import { useTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../../endpoints';

export default (props) => {
    const items = useSelector((state) => state.table.guest_orders.items);
    const isTakeoutOrder =
        useSelector((state) => state.order.payment_type) ===
        PaymentType.PAY_IMMEDIATE;
    const dispatch = useDispatch();
    const history = useHistory();
    const { restaurant_id } = useSelector(
        (state) => state.main.restaurant_info
    );
    const { t } = useTranslation();
    const isLoading = useSelector((state) => state.table.is_loading_orders);

    useEffect(() => {
        if (!isTakeoutOrder) {
            dispatch(loadOrders(history, false));
        }
    }, []);

    if (isLoading) {
        return (
            <div className="dineInExistingOrderProgressBarContainer">
                <CircularProgress size={36} />
            </div>
        );
    }

    if (isTakeoutOrder || (items || []).length === 0) {
        return <></>;
    }

    const renderItems = () => {
        const renderModifiers = (modifiers) => {
            if (!Array.isArray(modifiers)) {
                return;
            }
            return modifiers.map((modifier, modifierIndex) => {
                return (
                    <div
                        style={{ fontSize: '0.75rem', color: '#707070' }}
                        data-cy="selectedModifier"
                    >
                        {modifier.name}
                    </div>
                );
            });
        };

        return (items || []).map((itemInstance, itemIndex) => {
            const { item } = itemInstance;
            const image = `${cloudfrontBaseUrl}${restaurant_id}/items/${item.item_id}.jpeg`;
            // TODO: Calculate price with modifiers
            let priceOfModifiers = 0;
            itemInstance.modifiers.forEach((modifier) => {
                priceOfModifiers += modifier.price || 0;
            });
            const price = (
                (item.price + priceOfModifiers) *
                itemInstance.multiplier
            ).toFixed(2);
            const instructions = item.special_instructions;
            return (
                <div
                    data-cy="cartItem"
                    style={{
                        padding: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        cursor: 'pointer',
                    }}
                >
                    <Avatar
                        src={image}
                        alt={item.name}
                        style={{
                            width: '4rem',
                            height: '4rem',
                        }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                        }}
                    >
                        <div
                            style={{
                                paddingBottom: '1rem',
                            }}
                        >
                            <div>{item.name}</div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {renderModifiers(itemInstance.modifiers)}
                                <div
                                    style={{
                                        fontSize: '0.75rem',
                                        color: '#707070',
                                    }}
                                >
                                    {instructions ? instructions : ''}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ padding: '0rem 1rem 0rem 1rem' }}>
                                {item.multiplier}
                            </div>
                        </div>
                    </div>
                    <div>${price}</div>
                </div>
            );
        });
    };

    const guest_has_paid =
        items &&
        !items.filter((item) => {
            return item.has_paid === 'PAYMENT_UNPAID';
        }).length > 0;

    return (
        <>
            <div className="dineInExistingOrderHeader">
                {guest_has_paid ? t('cart.itemsPaid') : t('cart.sentToKitchen')}
            </div>
            {renderItems()}
        </>
    );
};
