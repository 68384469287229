import React from 'react';
import './GhostKitchenLocationPicker.css';

const GKAnnotationDetails = ({ openingIntervals }) => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const week = {
        0: 'Sunday:',
        1: 'Monday:',
        2: 'Tuesday:',
        3: 'Wednesday:',
        4: 'Thursday:',
        5: 'Friday:',
        6: 'Saturday:',
    };

    return (
        <div>
            <div className="row" style={{ paddingTop: 8 }}>
                <div className="dayOfTheWeekText">
                    {Object.keys(week).map((dayName, i) => {
                        return (
                            <div key={dayName}>
                                {dayOfWeek === i ? (
                                    <span className="currentTimeText">
                                        {week[dayName]}
                                    </span>
                                ) : (
                                    <span>{week[dayName]}</span>
                                )}
                                <br />
                            </div>
                        );
                    })}
                </div>

                <div className="openHoursText">
                    {Object.keys(week).map((dayName, i) => {
                        return (
                            <div key={dayName}>
                                {dayOfWeek === i ? (
                                    <span className="currentTimeText">
                                        {openingIntervals[i]}
                                    </span>
                                ) : (
                                    <span>{openingIntervals[i]}</span>
                                )}
                                <br />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default GKAnnotationDetails;
