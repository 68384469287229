import React from 'react';
import { cloudfrontBaseUrl } from '../../../endpoints';
import Header from '../../../components/Header';

class OgradysGallery extends React.Component {
    render() {
        return (
            <>
                <Header
                    back="/logoview"
                    history={this.props.history}
                    showGuestName
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ maxWidth: '700px' }}>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/107168020_575494089777985_5323757294858260560_n.jpg`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8418.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8419.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8420.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8421.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8422.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8423.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8424.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8527.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8528.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8529.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8530.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                        <img
                            alt=""
                            src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8531.JPG`}
                            style={{ width: '100%' }}
                        ></img>
                    </div>
                </div>
            </>
        );
    }
}

export default OgradysGallery;
