import { connect } from 'react-redux';
import React from 'react';

// Actions
import * as OrderActions from '../../actions/orderActions';
import './css/main.css';
import Header from '../../components/Header';
import FinalizeOrderButton from './components/FinalizeOrderButton';
import { withTheme } from '../../themes/theming';
import { scroller } from 'react-scroll';

class RestaurantMenus extends React.Component {
    componentDidMount() {
        if (this.props.location.state) {
            if (this.props.location.state.selected_menu) {
                const menu = this.props.location.state.selected_menu;
                scroller.scrollTo(menu.menu_id, {
                    duration: 1250,
                    delay: 0,
                    smooth: 'easeInOutQuart',
                    offset: -80,
                });
            }
        }
    }

    menuCategorySelected(category) {
        this.props.menuCategorySelected(category, this.props.history);
    }

    renderMenus(menus) {
        const theme = this.props.theme;
        return menus.map((menu) => {
            // Dont render the menu if there are no categories
            if (
                Array.isArray(menu.categories) &&
                menu.categories.length === 0
            ) {
                return <div key={menu.menu_id}></div>;
            }
            return (
                <div key={menu.menu_id} id={menu.menu_id}>
                    <div
                        className="menuTitle"
                        style={theme.restaurantMenuTitle}
                    >
                        {menu.name}
                    </div>
                    {this.renderCategories(menu.categories)}
                </div>
            );
        });
    }

    renderCategories(categories) {
        if (!Array.isArray(categories)) {
            return;
        }

        const theme = this.props.theme;
        return categories.map((category) => (
            <div
                className="menuButton"
                key={category.category_id}
                onClick={(e) => this.menuCategorySelected(category)}
            >
                <div
                    className="menuButtonLabel category"
                    style={theme.restaurantCategoryLabel}
                >
                    {category.name}
                </div>
                {/* <img alt="Arrow" src="/assets/modern-right-arrow.png" className="menuButtonRightArrow" /> */}
            </div>
        ));
    }

    render() {
        let { menus } = this.props.state.order.menu;
        return (
            <div style={{ paddingBottom: '200px' }}>
                <Header
                    back="/logoview"
                    showGuestName
                    history={this.props.history}
                />
                {this.renderMenus(menus)}
                <FinalizeOrderButton history={this.props.history} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { state };
};

const mapActionsToProps = {
    menuCategorySelected: OrderActions.menuCategorySelected,
};

export default withTheme(
    connect(mapStateToProps, mapActionsToProps)(RestaurantMenus)
);
