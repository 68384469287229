import { Fab } from '@material-ui/core';
import React, { useState } from 'react';
import './css/DineInFab.css';

import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import { Help, LocalCafe, Notifications, Receipt } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { callWaiter } from '../../../actions/orderActions';
import { DineInOrTakeoutSetting, PaymentType } from '../../../reducers/TYPES';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const isDineIn =
        useSelector((state) => state.order.order_type) ===
        DineInOrTakeoutSetting.DINE_IN;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    if (!isDineIn) {
        return <></>;
    }

    const actions = [
        {
            icon: <Notifications />,
            name: t('waiter.callWaiter'),
            message: t('cart.callWaiterToTable'),
        },
        {
            icon: <LocalCafe />,
            name: t('waiter.askForGlassOfWater'),
            message: t('cart.glassOfWater'),
        },
        {
            icon: <Receipt />,
            name: t('waiter.askForBill'),
            message: t('cart.askingForBill'),
        },
        {
            icon: <Help />,
            name: t('waiter.helpWithApp'),
            message: t('cart.helpWithApp'),
        },
    ];

    const callWaiterPressed = (action) => {
        dispatch(callWaiter(action.message, undefined));
        handleClose();
    };

    return (
        <>
            <div
                style={{
                    flexGrow: 1,
                    zIndex: 999999,
                }}
            >
                <Backdrop open={open}></Backdrop>
                <SpeedDial
                    ariaLabel="Call Waiter"
                    style={{
                        position: 'fixed',
                        bottom: 'calc(110px + env(safe-area-inset-bottom))',
                        right: '1rem',
                    }}
                    icon={<RoomServiceIcon style={{ color: '#fff' }} />}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                >
                    {actions.map((action) => {
                        return (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                TooltipClasses="tooltipLabelFix"
                                tooltipTitle={action.name}
                                tooltipOpen
                                onClick={() => callWaiterPressed(action)}
                            />
                        );
                    })}
                </SpeedDial>
            </div>
        </>
    );
};
