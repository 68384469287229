import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as Types from '../../reducers/TYPES';
import stripeTerminal from '../../StripeTerminal';
import * as OrderActions from '../../actions/orderActions';

class FrontOfHouseReader extends React.Component {
    render() {
        // check for terminal payment status
        const terminal_payment_status =
            this.props.state.kiosk.terminal_payment_status;

        if (terminal_payment_status === Types.TerminalPaymentStatus.COMPLETE) {
            this.props.history.push('/orderComplete');
        }

        if (terminal_payment_status === Types.TerminalPaymentStatus.FAILED) {
            this.props.history.push('/order');
            const reader_error = this.props.state.kiosk.payment_error;
            if (reader_error.error_details) {
                toast.error(
                    `${reader_error.error_details || 'Did customer cancel?'}`
                );
            } else if (reader_error.error) {
                toast.error(`Payment failed: ${reader_error.error}`);
            } else {
                toast.error(`Unknown Error: ${JSON.stringify(reader_error)}`);
            }
        }

        const cancelPayment = () => {
            const terminalVendor = this.props.state.kiosk.terminal_vendor;
            switch (terminalVendor) {
                case Types.TerminalVendors.STRIPE:
                    stripeTerminal.cancelPayment();
                    break;
                case Types.TerminalVendors.NUVEI:
                    // get cancel token
                    const source =
                        this.props.state.kiosk.nuvei_cancellation_token;
                    if (source) {
                        source.cancel('Cancelled by user');
                    }
                    break;
                default:
                    break;
            }
            this.props.history.push('/order');
        };

        const payAtCounter = () => {
            this.props.payAtCounter(this.props.history, true, true);
        };

        const renderCancelButton = () => {
            if (
                this.props.state.kiosk.terminal_vendor ===
                Types.TerminalVendors.NUVEI
            ) {
                return '';
            }

            return (
                <div>
                    <div
                        className="to-page-payment-back-button not-absolute"
                        onTouchEnd={() => cancelPayment()}
                        onMouseUp={() => cancelPayment()}
                    >
                        Cancel
                    </div>
                </div>
            );
        };

        const renderPayAtCounter = () => {
            return (
                <div
                    className="to-page-payment-back-button not-absolute"
                    onClick={() => payAtCounter()}
                >
                    Pay at Counter
                </div>
            );
        };

        return (
            <div>
                <div>
                    <div className="to-page-review-heading">
                        Please tell customer to Tap or Insert their card.
                    </div>
                </div>
                {renderPayAtCounter()}
                {renderCancelButton()}
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    payAtCounter: OrderActions.payAtCounter,
})(FrontOfHouseReader);
