const OGRADYS_TEXT = `PATIO SAFETY REGULATIONS
(MUST READ, PLEASE SCROLL DOWN)

Dear Patrons,

In accordance with Toronto Public Health and Safety Guidelines, we have implemented the following policies to ensure everyone's safety and avoid any possibility of contributing to the spreading COVID19.

Please read the following and ask for any clarification:

1)        Our menu is also available online. Please visit www.ogradyschurch.com/.

2)        In compliance with the law, at least one person from the party must provide a name and phone number for government tracking purposes. Otherwise, please sign in through the QR Code.

3)        All seating has been arranged for the safety of all patrons. Please do not move or rearrange tables or chairs.

4)        As much as you may want to, please, do not stop to talk to or approach other tables to say “hello” to friends.

5)        Please use a safe social distance in the restroom.

6)        Each party will only be offered one bill to provide the least amount of contact between server and patron.

7)        In compliance with the law, we allow parties with a maximum of 6 people.

8)        The full party must be present to be seated.

9)        There will be a 90-minute seating limit per party.

10)  Please remember to double check and make sure you have taken everything with you when you leave. We will not be enforcing a lost and found at this time. Anything and everything that is left behind will be disposed of immediately upon clearing, cleaning and sanitizing of the section before its next use.

11)  Upon entry into the building to use the bathroom, all patrons are required by law to wear face mask. If you do not have a mask on your person, you may purchase a mask surgical mask for $2.00 or a reusable cloth mask for $5.00.

If you have any questions, please do not hesitate to ask.

We thank you for your understanding, patience and support!

On behalf of everyone here at O’Grady’s, welcome back!

It is good to see you all again!

Respectfully,

O’Grady’s Management & Staff
`;

export default OGRADYS_TEXT;
