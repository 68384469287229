import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faFacebook,
    faGoogle,
    faYelp,
} from '@fortawesome/free-brands-svg-icons';
import { Rating } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getRestaurantRating,
    loadGuestOrders,
    updateGuestName,
    updateRestaurantRatingNew,
} from '../actions/orderActions';
import { combineItemsById } from '../utils';

library.add(faYelp, faGoogle, faFacebook);

const restaurantIdToSocialMedia: Record<string, Record<string, string>> = {
    '2993daf5-c798-11ea-b545-02685a4295ea': {
        yelp: 'https://yelp.to/qTKq/bvDYmMOE88',
        google: 'https://www.google.com/search?client=ms-android-verizon&output=search&q=ethos+Greek+Bistro&ludocid=17635880988830284877&ibp=gwp;0,7&lsig=AB86z5XFpAxRcosstID8xWYWLYvJ&kgs=f0636fcdcfe7b645&shndl=-1&source=sh/x/kp/local&entrypoint=sh/x/kp/local',
        facebook: 'https://www.facebook.com/EthosGreekBistro/',
    },
};

interface Modifier {
    modifier_id: string;
    modifier_group_id: string;
    name: string;
    price?: number;
    count?: number;
}

interface ModifierGroup {
    modifier_group: {
        modifier_group_id: string;
        name: string;
    };
}

interface Campaign {
    discount: number;
}

interface ItemInstance {
    item: any;
    modifiers?: Modifier[];
    multiplier?: number;
    has_paid?: string;
    special_instructions?: string;
    campaign_instance_id?: string;
}

const PaymentReceipt: React.FC = () => {
    const params: any = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const receipt_restaurant: any = useSelector(
        (state: any) => state.order.receipt_restaurant
    );
    const rawGuest: any = useSelector(
        (state: any) => state.order.receipt_raw_guest
    );
    const guest: any = useSelector((state: any) => state.order.receipt_guest);
    const rating: number = useSelector(
        (state: any) => state.order.restaurant_rating
    );
    const order: any = guest?.order;

    const guestId = params.guest_id;

    const [subtotal, setSubtotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [tip, setTip] = useState(0);
    const [tax, setTax] = useState(0);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [deliveryTip, setDeliveryTip] = useState(0);
    const [items, setItems] = useState<any[]>();
    const [hasLoaded, setHasLoaded] = useState(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const initLoad = () => {
        dispatch(getRestaurantRating(guestId));
        dispatch(loadGuestOrders(guestId));
    };

    useEffect(() => {
        initLoad();
    }, [guestId, loadGuestOrders]);

    const handleRating = (rating: number) => {
        dispatch(updateRestaurantRatingNew(rating));

        setTimeout(() => {
            initLoad();
            setIsSaving(false);
        }, 2000);
    };

    useEffect(() => {
        if (guest === undefined || !receipt_restaurant || !order) {
            return;
        }

        let {
            items = [],
            tip_percentage = 0,
            tip_fixed = 0,
            campaigns = [],
            delivery_fee = 0,
            delivery_tip = 0,
            sales_tax,
        } = order;

        let newSubtotal = 0;
        items.forEach((item: any) => {
            let itemPrice = item.item.price ?? 0;
            item.modifiers?.forEach((modifier: Modifier) => {
                itemPrice += modifier.price ?? 0;
            });
            const multiplier = item.multiplier ?? 1;
            newSubtotal += itemPrice * multiplier;
        });

        campaigns.forEach((campaign: Campaign) => {
            newSubtotal -= campaign.discount / 100;
        });

        let newDiscount = 0;
        if (guest?.restaurant_id === '2993daf5-c798-11ea-b545-02685a4295ea') {
            newDiscount = newSubtotal * 0.2;
        }
        newSubtotal -= newDiscount;

        let newTip = tip_fixed;
        if (tip_percentage) {
            newTip = newSubtotal * (tip_percentage / 100);
        }

        const newTax = sales_tax / 100;
        const newDeliveryFee = delivery_fee / 100;
        const newDeliveryTip = delivery_tip / 100;

        setSubtotal(newSubtotal);
        setDiscount(newDiscount);
        setTip(newTip);
        setTax(newTax);
        setItems(items);
        setDeliveryFee(newDeliveryFee);
        setDeliveryTip(newDeliveryTip);
        setHasLoaded(Boolean(receipt_restaurant));
    }, [guest]);

    if (!hasLoaded) {
        return <></>;
    }

    const hasPaid = guest.has_paid;
    const tableId = order.table_id;
    const logo = receipt_restaurant.logo;
    const restaurantName = receipt_restaurant.name;
    const restaurantAddress = receipt_restaurant.address;

    const handleName = (name: string) => {
        try {
            setIsSaving(true);
            dispatch(
                updateGuestName({
                    ...rawGuest,
                    name,
                })
            );
        } catch (error) {
            console.log({ error });
        } finally {
            setTimeout(() => {
                initLoad();
                setIsSaving(false);
            }, 2000);
        }
    };

    return (
        <div className="max-w-md mx-auto h-screen bg-white p-[16px] flex flex-col font-['Inter'] ">
            <Header logo={logo} />
            <div className="flex-1">
                <StatusBanner hasPaid={hasPaid} />
                <OrderName
                    name={guest.name}
                    handleName={handleName}
                    isLoading={isSaving}
                />
                <OrderInfo tableId={tableId} hasPaid={hasPaid} />
                <OrderDetails items={items} />
                <OrderSummary
                    tip={tip}
                    tax={tax}
                    subtotal={subtotal}
                    deliveryFee={deliveryFee}
                    discount={discount}
                    deliveryTip={deliveryTip}
                />
            </div>

            <FeedbackSection
                rating={rating}
                handleRating={handleRating}
                restaurantName={restaurantName}
                restaurantAddress={restaurantAddress}
            />
        </div>
    );
};

const Header = ({ logo }: { logo: string }) => {
    return (
        <div className="flex items-center justify-center mt-[6px]">
            <img
                alt="Restaurant Logo"
                src={logo}
                className="mx-auto h-[45px] w-auto max-w-full"
            />
        </div>
    );
};

const StatusBanner = ({ hasPaid }: { hasPaid: boolean }) => {
    if (!hasPaid) {
        return (
            <div className="bg-[#FEF3F2] border-2 border-[#F04437] rounded-lg py-3 px-5 my-5 flex items-center justify-center gap-3">
                {/* <img src="/assets/icons/not-allowed.svg" /> */}

                <span className="text-base font-medium text-black">
                    Payment required before we can process your order
                </span>
            </div>
        );
    }

    return (
        <div className="bg-[#DBFAE6] border-2 border-[#17B169] rounded-lg py-3 px-5 my-5 flex items-center justify-center gap-1.5">
            {/* <svg className="flex-shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22ZM12.5 20C16.9183 20 20.5 16.4183 20.5 12C20.5 7.58172 16.9183 4 12.5 4C8.08172 4 4.5 7.58172 4.5 12C4.5 16.4183 8.08172 20 12.5 20ZM11.5026 16L7.25999 11.7574L8.67421 10.3431L11.5026 13.1716L17.1595 7.51472L18.5737 8.92893L11.5026 16Z" fill="#17B26A" />
            </svg> */}

            <span className="text-base font-medium text-black">
                Payment completed
            </span>
        </div>
    );
};

type OrderNameType = {
    name?: string;
    isLoading: boolean;
    handleName: (name: string) => void;
};

const OrderName = ({ name, isLoading, handleName }: OrderNameType) => {
    const [newName, setNewName] = useState<string>(name || '');
    const [view, setView] = useState<'text' | 'form'>('text');

    const handleSaveName = async () => {
        if (!newName || newName.length < 1 || newName === name) {
            setView('text');
            return;
        }

        if (isLoading) {
            return;
        }

        await handleName(newName);
        setView('text');
    };

    return (
        <>
            {view === 'form' ? (
                <div className="w-full h-[42px] gap-3 flex mb-6">
                    <input
                        placeholder="Enter Name"
                        defaultValue={name}
                        onChange={(e) => setNewName(e.target.value)}
                        className="w-full h-[42px] text-black border border-[#98A2B3] px-[14px] py-3 rounded leading-[10px]"
                        disabled={isLoading}
                    />

                    <button
                        className="bg-[#0D785D] flex-shrink-0 w-[40px] h-[42px] rounded flex justify-center items-center"
                        disabled={isLoading}
                        onClick={handleSaveName}
                    >
                        <img src="/assets/icons/check.svg" />
                    </button>
                </div>
            ) : (
                <>
                    {name ? (
                        <h2
                            className={`text-xl text-black leading-[30px] font-semibold flex gap-3 cursor-pointer mb-6 ${
                                name?.length > 30
                                    ? 'items-start'
                                    : 'items-center'
                            }`}
                            onClick={() => setView('form')}
                        >
                            {name}

                            {!isLoading && <img src="/assets/icons/edit.svg" />}
                        </h2>
                    ) : (
                        <>
                            {view === 'text' && (
                                <button
                                    className="text-black text-xl font-semibold underline underline-offset-4 leading-[30px] cursor-pointer flex items-center gap-3 mb-6"
                                    onClick={() => setView('form')}
                                >
                                    Add Name
                                    <img src="/assets/icons/edit.svg" />
                                </button>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

const OrderInfo = ({ tableId, hasPaid }: any) => {
    return (
        <div className="w-full flex flex-col gap-[12px] pb-[12px] border-b border-[#D1D7E0]">
            <div className="flex justify-between w-full h-3">
                <p className="text-black text-sm font-medium leading-[10px]">
                    Order #
                </p>
                <p className="text-right text-black text-sm font-semibold leading-[10px]">
                    {tableId}
                </p>
            </div>

            {/* TODO: */}
            {/* {hasPaid ? (
                <>
                    <div className="flex items-center justify-between w-full">
                        <p className="text-black text-sm font-medium leading-[10px]">Transaction type</p>
                        <div className="justify-end items-center gap-0.5 flex">
                            <svg className="relative w-4 h-4" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.50011 10.6667C9.97284 10.6667 11.1668 9.47281 11.1668 8.00008C11.1668 6.52732 9.97284 5.33341 8.50011 5.33341C7.02733 5.33341 5.83342 6.52732 5.83342 8.00008C5.83342 9.47281 7.02733 10.6667 8.50011 10.6667ZM14.5031 2.6687H2.50309C2.13491 2.6687 1.83643 2.96717 1.83643 3.33537V12.6687C1.83643 13.0369 2.13491 13.3353 2.50309 13.3353H14.5031C14.8713 13.3353 15.1698 13.0369 15.1698 12.6687V3.33537C15.1698 2.96717 14.8713 2.6687 14.5031 2.6687ZM3.16976 10.4309V5.56922C3.92027 5.34475 4.51208 4.75269 4.73621 4.00203H12.264C12.4887 4.7547 13.083 5.34791 13.8364 5.57101V10.4291C13.0818 10.6526 12.4866 11.2475 12.2628 12.002H4.73737C4.51413 11.2495 3.92158 10.6558 3.16976 10.4309Z" fill="#73737F" />
                            </svg>

                            <div className="text-right text-zinc-700 text-xs font-medium leading-[10px]">Cash</div>
                        </div>
                    </div>

                    <div className="flex items-center justify-between w-full">
                        <p className="h-3 text-black text-sm font-medium leading-[10px]">Transaction type</p>
                        <div className="flex items-center gap-2">
                            <div className="w-[37px] h-3" />
                            <div className="text-right text-black text-sm font-semibold leading-[10px]">**** 1234</div>
                        </div>
                    </div>
                </>
            ) : null} */}
        </div>
    );
};

const OrderDetails = ({ items }: any) => {
    if (!Array.isArray(items)) {
        return null;
    }

    return (
        <>
            {items.map((itemInstance: ItemInstance, index: number) => {
                if (itemInstance.campaign_instance_id) {
                    return null;
                }

                let { item } = itemInstance;
                let { multiplier = 1, modifiers = [] } = itemInstance;

                let priceWithModifiers = item.price || 0;
                modifiers.forEach((modifier: Modifier) => {
                    if (modifier.price) {
                        priceWithModifiers += parseFloat(
                            modifier.price.toString()
                        );
                    }
                });

                const price = (priceWithModifiers * multiplier).toFixed(2);

                return (
                    <Item
                        key={index}
                        price={price}
                        itemName={item.name}
                        quantity={multiplier}
                        brandName={item.brand_type || 'None'}
                        modifiers={itemInstance.modifiers}
                        itemModifierGroup={item.item_modifier_groups}
                        specialInstruction={itemInstance.special_instructions}
                    />
                );
            })}
        </>
    );
};

const Item = (props: any) => {
    const {
        itemName,
        brandName,
        price,
        quantity,
        modifiers,
        itemModifierGroup,
        specialInstruction,
    } = props;
    return (
        <div className="w-full flex-col justify-start items-start flex py-[12px] border-b border-[#D1D7E0]">
            <div className="flex items-center justify-between w-full">
                <div className="text-sm font-normal text-zinc-700">
                    {brandName}
                </div>
                <div className="text-xs font-normal text-zinc-700">
                    Qty {quantity}
                </div>
            </div>

            <div className="flex items-start justify-between w-full mt-[12px]">
                <div className="">
                    <p className="text-sm font-medium leading-3 text-black">
                        {itemName}
                    </p>

                    {specialInstruction ? (
                        <p className="text-[#20B04B] text-sm mt-[2px]">
                            Instructions: {specialInstruction}
                        </p>
                    ) : null}

                    <ItemModifiers
                        modifiers={modifiers}
                        itemModifierGroup={itemModifierGroup}
                    />
                </div>
                <div className="text-zinc-700 text-xs font-normal leading-[10px]">
                    ${price}
                </div>
            </div>
        </div>
    );
};

const ItemModifiers = ({ modifiers, itemModifierGroup }: any) => {
    if (!Array.isArray(modifiers)) {
        return <></>;
    }

    const groupedModifiers: { [key: string]: Modifier[] } = {};

    modifiers.forEach((modifier) => {
        const modifierGroupItem = itemModifierGroup?.find(
            (item: ModifierGroup) =>
                item.modifier_group.modifier_group_id ===
                modifier.modifier_group_id
        );

        if (modifierGroupItem) {
            const groupName = modifierGroupItem.modifier_group.name;

            if (!groupedModifiers[groupName]) {
                groupedModifiers[groupName] = [];
            }

            groupedModifiers[groupName].push(modifier);
        }
    });

    if (!Object.keys(groupedModifiers).length) {
        return <></>;
    }

    return (
        <div className="flex flex-col items-start justify-center gap-3 mt-4">
            {Object.keys(groupedModifiers).map((groupName, groupIndex) => (
                <div key={groupIndex}>
                    {combineItemsById(groupedModifiers[groupName], 'name').map(
                        (modifier, modifierIndex) => {
                            const modifierPrice = modifier.price
                                ? `+ $${modifier.price.toFixed(2)}`
                                : '';

                            return (
                                <div
                                    className="text-black text-sm font-normal ml-[16px]"
                                    key={`${modifier.modifier_id}-${modifierIndex}`}
                                >
                                    {modifier.count} {modifier.name}{' '}
                                    {modifierPrice}
                                </div>
                            );
                        }
                    )}
                </div>
            ))}
        </div>
    );
};

const OrderSummary = ({
    tip,
    tax,
    subtotal,
    deliveryFee,
    discount,
    deliveryTip,
}: any) => {
    const total = (subtotal + tip + tax + deliveryTip).toFixed(2);

    return (
        <div className="w-full flex-col justify-start items-start gap-3 flex mt-[24px]">
            {deliveryTip !== 0 ? (
                <div className="w-full justify-end items-start gap-[35px] flex">
                    <div className="w-auto text-right text-zinc-700 text-xs font-medium leading-[10px]">
                        Delivery Tip
                    </div>
                    <div className="w-16 text-right text-zinc-700 text-xs font-semibold leading-[10px]">
                        -${deliveryTip.toFixed(2)}
                    </div>
                </div>
            ) : null}
            {discount !== 0 ? (
                <div className="w-full justify-end items-start gap-[35px] flex">
                    <div className="w-[57px] text-right text-zinc-700 text-xs font-medium leading-[10px]">
                        Discount
                    </div>
                    <div className="w-16 text-right text-zinc-700 text-xs font-semibold leading-[10px]">
                        -${discount.toFixed(2)}
                    </div>
                </div>
            ) : null}
            {deliveryFee ? (
                <div className="w-full justify-end items-start gap-[35px] flex">
                    <div className="w-auto text-right text-zinc-700 text-xs font-medium leading-[10px]">
                        Delivery Fee
                    </div>
                    <div className="w-16 text-right text-zinc-700 text-xs font-semibold leading-[10px]">
                        ${deliveryFee.toFixed(2)}
                    </div>
                </div>
            ) : null}
            <div className="w-full justify-end items-start gap-[35px] flex">
                <div className="w-[57px] text-right text-zinc-700 text-xs font-medium leading-[10px]">
                    Subtotal
                </div>
                <div className="w-16 text-right text-zinc-700 text-xs font-semibold leading-[10px]">
                    ${subtotal.toFixed(2)}
                </div>
            </div>
            <div className="w-full justify-end items-start gap-[35px] flex">
                <div className="w-[57px] text-right text-zinc-700 text-xs font-medium leading-[10px]">
                    Tip
                </div>
                <div className="w-16 text-right text-zinc-700 text-xs font-semibold leading-[10px]">
                    ${tip.toFixed(2)}
                </div>
            </div>
            <div className="w-full justify-end items-start gap-[35px] flex">
                <div className="w-[57px] text-right text-zinc-700 text-xs font-medium leading-[10px]">
                    Tax
                </div>
                <div className="w-16 text-right text-zinc-700 text-xs font-semibold leading-[10px]">
                    ${tax.toFixed(2)}
                </div>
            </div>
            <div className="w-full justify-end items-start gap-[35px] flex">
                <div className="w-[57px] text-right text-emerald-800 text-sm font-medium leading-3">
                    Total
                </div>
                <div className="w-16 text-sm font-semibold leading-3 text-right text-emerald-800">
                    ${total}
                </div>
            </div>
        </div>
    );
};

const FeedbackSection = ({
    rating,
    handleRating,
    restaurantName,
    restaurantAddress,
}: any) => {
    return (
        <div className="text-center mt-[45px]">
            <p className="text-center text-[#73737F] font-medium">
                How was your experience at {restaurantName}?
            </p>
            <div className="flex justify-center mt-[8px]">
                <Rating
                    name="size-large"
                    value={rating}
                    size="large"
                    className="text-[#D1D7E0]"
                    onChange={(_, value) => handleRating(value)}
                />
            </div>
            <div className="text-[#45454C] text-[12px] mt-[12px]">
                <p>{restaurantAddress}</p>
                <p className="mt-[4px]">POWERED BY MENTUM</p>
            </div>
        </div>
    );
};

export default PaymentReceipt;
