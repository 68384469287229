import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as OrderActions from '../../../actions/orderActions';
import '../css/KioskErrorBoundary.css';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router-dom';
import { captureException } from '@sentry/react';

export class KioskErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorType: null,
            message: null,
            resetCountdown: 10,
        };
    }

    static getDerivedStateFromError(err) {
        if (err.name)
            return {
                hasError: true,
                errorType: err.name,
                message: err.message,
            };
    }

    componentDidCatch(err) {
        console.error(err);
        captureException(err);
    }

    onIdle() {
        this.setState({ resetCountdown: 10 });
        this.countdown = setInterval(() => {
            const newValue = this.state.resetCountdown - 1;
            if (newValue <= 0) {
                this.endOrder();
            }
            this.setState({
                resetCountdown: newValue,
            });
        }, 1000);
    }

    endOrder() {
        // clear countdown interval
        if (this.countdown) {
            clearInterval(this.countdown);
        }

        const endpoint = this.props.state.main.restaurant_info.endpoint;
        this.props.clearAllOrders();
        console.log(endpoint);
        window.location.replace(`/${endpoint}.kiosk`);
    }

    endOrderPressed = () => {
        setTimeout(() => {
            this.endOrder();
        }, 350);
    };

    renderErrorRefreshPage = () => {
        return (
            <div className="KioskErrorBoundaryContainer">
                <h1 className="KioskErrorTitle">
                    Sorry, something went wrong :(
                </h1>
                <div className="KioskRestartCountDown">
                    Mentum will restart automatically in{' '}
                    {this.state.resetCountdown} seconds
                </div>
                <div
                    className="KioskRestartButton"
                    onMouseUp={() => this.endOrderPressed()}
                    onTouchEnd={() => this.endOrderPressed()}
                >
                    Click to restart
                </div>
                <p className="KioskErrorMessage">{this.state.message}</p>
            </div>
        );
    };

    render() {
        let errorPageToDisplay = <div> Sorry, something went wrong :( </div>;
        if (this.state.hasError) {
            switch (this.state.errorType) {
                default:
                    errorPageToDisplay = this.renderErrorRefreshPage();
                    break;
            }
            return (
                <div className="KioskErrorBoundaryPage">
                    <IdleTimer
                        ref={(ref) => (this.idleTimer = ref)}
                        timeout={10 * 60} // 10 second idle timeout
                        onIdle={() => this.onIdle()}
                    />
                    {errorPageToDisplay}
                </div>
            );
        }

        return this.props.children;
    }
}

const mstp = (state) => ({ state });
export default connect(mstp, {
    clearAllOrders: OrderActions.clearAllOrders,
})(withRouter(KioskErrorBoundary));
