import React, { useState, createContext, useEffect, useContext } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { APIv1Endpoint } from '../endpoints';
import * as TYPES from '../reducers/TYPES';
import Axios from 'axios';
import en from './en.json';
import fr from './fr.json';
import es from './es.json';
import pt from './pt.json';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { translateMenus } from '../actions/orderActions';

// Define an enum `Language` with 'en' and 'fr' as options.
export enum Language {
    EN = 'en',
    FR = 'fr',
    ES = 'es',
    PT = 'pt',
}

// Create a `resources` object that maps the `Language` enum to the corresponding translation JSON.
export const resources = {
    [Language.EN]: {
        translation: en,
    },
    [Language.FR]: {
        translation: fr,
    },
    [Language.ES]: {
        translation: es,
    },
    [Language.PT]: {
        translation: pt,
    },
} as const;

// Check if the resources exist for the initial language, if not, default to English
const fallbackLocale = resources[Language.EN]
    ? {
          label: 'English',
          value: Language.EN,
          emoji: '🇬🇧',
      }
    : {
          label: 'English',
          value: Language.EN,
          emoji: '🇬🇧',
      };

// Initialize i18next with the translation resources and other configuration options.
// If initialization fails, log the error to the console.
try {
    i18n.use(initReactI18next).init({
        compatibilityJSON: 'v3',
        resources,
        lng: fallbackLocale.value,
        fallbackLng: Language.EN,
        interpolation: {
            escapeValue: false,
        },
    });
} catch (error) {
    console.error('Failed to initialize i18next:', error);
}

type Locale = {
    label: string;
    value: Language;
    emoji: string;
};

// Define the type for the LocaleContext.
export type LocaleContextType = {
    locale: Locale | null;
    supportedLocales: Locale[];
    isRequestHeaderSet: boolean;
    setLocale: (locale: Locale) => void;
};

// Create the LocaleContext with default values.
export const LocaleContext = createContext<LocaleContextType>({
    locale: {
        label: 'English',
        value: Language.EN,
        emoji: '🇬🇧',
    },
    supportedLocales: [],
    isRequestHeaderSet: false,
    setLocale: () => {},
});

// Define the name of the cookie that will store the preferred language.
const COOKIE_NAME = 'preferred-language';

const supportedLocales = [
    { label: 'English', value: Language.EN, emoji: '🇬🇧' },
    { label: 'Français', value: Language.FR, emoji: '🇫🇷' },
    { label: 'Spanish', value: Language.ES, emoji: '🇪🇸' },
    { label: 'Portugese', value: Language.PT, emoji: '🇵🇹' },
];

export const useLocale = () => {
    const context = useContext(LocaleContext);
    return context;
};

// Define the LocaleProvider component. This component provides the current locale and a function to change it to its children.
export const LocaleProvider = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch();
    const restaurant = useSelector((state: any) => state.main.restaurant_info);
    const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);
    const localeFromCookie = cookies[COOKIE_NAME] as Locale;
    const locale = useSelector(
        (state: any) => state.main.language || supportedLocales[0]
    );
    const setLocale = (locale: Locale) => {
        dispatch({
            type: TYPES.SET_LANGUAGE,
            payload: locale,
        });
    };
    const [isRequestHeaderSet, setIsRequestHeaderSet] = useState(false);
    const [currentEndpoint, setCurrentEndpoint] = useState(restaurant.endpoint);

    const onSetLocale = (locale: Locale) => {
        if (resources[locale.value]) {
            setCookie(COOKIE_NAME, locale);
            setLocale(locale);
            i18n.changeLanguage(locale.value);
        } else {
            console.warn(`Tried to set unsupported locale: ${locale}`);
        }
    };

    const resetCookie = () => {
        removeCookie(COOKIE_NAME);
    };

    useEffect(() => {
        if (!restaurant.endpoint || restaurant.endpoint !== currentEndpoint)
            return;
        const defaultLanguage = restaurant.language;

        const locale = localeFromCookie || defaultLanguage || fallbackLocale;

        if (resources[locale.value]) {
            i18n.changeLanguage(locale.value);
            setIsRequestHeaderSet(true);
            setLocale(locale);
        } else {
            console.warn(`Cookie has unsupported locale: ${locale}`);
        }
    }, [restaurant.endpoint]);

    // Load menu translations
    useEffect(() => {
        const load = async () => {
            if (!restaurant.restaurant_id) {
                return;
            }

            const response = await axios.get(
                `${APIv1Endpoint}restaurants/${restaurant.restaurant_id}/translations`
            );
            const translations = response?.data?.list ?? {};

            dispatch({
                type: TYPES.SET_MENU_TRANSLATIONS,
                payload: translations,
            });
        };

        load();
    }, [restaurant.restaurant_id]);

    useEffect(() => {
        dispatch(translateMenus());
    }, [locale.value]);

    useEffect(() => {
        if (!restaurant.endpoint || restaurant.endpoint === currentEndpoint) {
            return;
        }

        setCurrentEndpoint(restaurant.endpoint);
        resetCookie();

        axios
            .get(`${APIv1Endpoint}restaurant/${restaurant.endpoint}`)
            .then((res) => {
                const restaurant = res.data.restaurant;
                const activeLanguages = restaurant.active_languages;
                const defaultLanguage = restaurant.language;
                const defaultLocale = supportedLocales.find(
                    (locale) => locale.value === defaultLanguage
                );

                const locale = defaultLocale || fallbackLocale;

                if (resources[locale.value]) {
                    const isActive = activeLanguages.includes(locale.value);
                    Axios.defaults.headers.common['X-Language-Code'] = isActive
                        ? locale.value
                        : defaultLanguage;
                    i18n.changeLanguage(locale.value);
                    setIsRequestHeaderSet(true);
                    setLocale(locale);
                } else {
                    console.warn(`Cookie has unsupported locale: ${locale}`);
                }

                dispatch({
                    type: TYPES.RESTAURANT_INFO_UPDATED,
                    payload: restaurant,
                });
            });
    }, [restaurant, isRequestHeaderSet]);

    return (
        <LocaleContext.Provider
            value={{
                locale,
                supportedLocales,
                isRequestHeaderSet,
                setLocale: onSetLocale,
            }}
        >
            {children}
        </LocaleContext.Provider>
    );
};

export { i18n };
