import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import { withTheme } from '../../themes/theming';
import * as OrderActions from '../../actions/orderActions';
import { toast } from 'react-toastify';
import { OrderDateType } from '../../reducers/TYPES';

import './TakeoutTimePicker.css';
// Date time pickers
import DateFnsUtils from '@date-io/date-fns';
import { enUS, fr } from 'date-fns/locale';
import {
    DatePicker,
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Axios from 'axios';
import { APIv1Endpoint } from '../../endpoints';
import { checkItemsAreAvailableAtSpecifiedTime } from '../../utils';
import { i18n } from '../../locales';

class TakeoutTimePicker extends React.Component {
    componentDidMount() {
        // if exists
        toast.dismiss('mealPeriodClosed');

        const order_date_type = this.props.state.order.order_date_type;

        // default date to now + 10 minutes

        // reset later time/date to now
        if (order_date_type === OrderDateType.NOW) {
            const tenMinutesInSeconds = 10 * 60 * 1000;
            const now = new Date(new Date().getTime() + tenMinutesInSeconds);
            this.props.orderTimeChanged(now);
        }
    }

    isValidTime() {
        const time = new Date(this.props.state.order.order_time);

        const currentTime = new Date().getTime();
        const is_valid = time.getTime() >= currentTime;

        return is_valid;
    }

    componentWillUnmount() {
        if (!this.isValidTime()) {
            this.orderTimeChanged(new Date());
            this.setOrderType(OrderDateType.NOW);

            const update_order_req = {
                order_id: this.props.state.table.guest_orders.order_id,
                pickup_time: new Date(),
                order_type: 1,
            };
            Axios.post(
                `${APIv1Endpoint}orders/setType`,
                update_order_req
            ).catch((err) => {
                console.error(err.response);
            });
            return;
        }
    }

    setGuestToOrderNow(show_toast) {
        this.orderTimeChanged(new Date());
        this.setOrderType(OrderDateType.NOW);

        if (!checkItemsAreAvailableAtSpecifiedTime()) {
            return;
        }

        const update_order_req = {
            order_id: this.props.state.table.guest_orders.order_id,
            pickup_time: new Date(),
            order_type: 1,
        };
        Axios.post(`${APIv1Endpoint}orders/setType`, update_order_req)
            .then((res) => {
                if (show_toast) {
                }
            })
            .catch((err) => {
                if (show_toast) {
                    toast.error(i18n.t('toast.setOrderingTimeNowError'));
                }
                console.error(err.response);
            });
    }

    saveButtonPressed() {
        const order_date_type = this.props.state.order.order_date_type;

        switch (order_date_type) {
            case OrderDateType.NOW:
                if (!checkItemsAreAvailableAtSpecifiedTime()) {
                    return;
                }
                this.props.history.goBack();
                this.setGuestToOrderNow(true);
                break;

            case OrderDateType.LATER:
                // ensure time is valid

                if (!this.isValidTime()) {
                    toast.error(i18n.t('toast.invalidOrderingTime'));
                    // Set time to now
                    this.orderTimeChanged(new Date());
                    this.setOrderType(OrderDateType.NOW);
                    return;
                }

                if (!checkItemsAreAvailableAtSpecifiedTime()) {
                    return;
                }

                const pickup_time = this.props.state.order.order_time;
                // update order
                const update_order_req = {
                    order_id: this.props.state.table.guest_orders.order_id,
                    pickup_time,
                    order_type: 2,
                };
                Axios.post(`${APIv1Endpoint}orders/setType`, update_order_req)
                    .then((res) => {
                        toast.success(i18n.t('toast.orderingTimeSet'));
                        this.props.history.goBack();
                    })
                    .catch((err) => {
                        toast.error(i18n.t('toast.setOrderingTimeLaterError'));
                        console.error(err.response);
                    });

                break;
            default:
                break;
        }
    }

    setOrderType(type) {
        this.props.orderDateTypeChanged(type);
    }

    renderNowComponent() {
        return (
            <div className="timePickerContainer">
                <div
                    className="twoFA-button"
                    style={{ marginLeft: 0, marginRight: 0 }}
                    onClick={(e) => this.saveButtonPressed()}
                >
                    Save
                </div>
            </div>
        );
    }

    orderTimeChanged(time) {
        this.props.orderTimeChanged(time);
    }

    renderLaterComponent() {
        const order_time = this.props.state.order.order_time;

        const locale = {
            en: enUS,
            fr,
        };
        return (
            <div className="timePickerContainer">
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale[i18n.language]}
                >
                    <div className="dateTimePickerRow">
                        <DatePicker
                            value={order_time}
                            minDate={new Date()}
                            label="Day"
                            minDateMessage="Date cannot be earlier than today."
                            onChange={(val) => this.orderTimeChanged(val)}
                        ></DatePicker>

                        <TimePicker
                            value={order_time}
                            minDate={new Date()}
                            label="Time"
                            minutesStep={5}
                            minDateMessage="Time cannot be in the past."
                            onChange={(val) => this.orderTimeChanged(val)}
                        ></TimePicker>
                    </div>
                </MuiPickersUtilsProvider>

                <div
                    className="twoFA-button"
                    style={{ marginLeft: 0, marginRight: 0 }}
                    onClick={(e) => this.saveButtonPressed()}
                >
                    Save
                </div>
            </div>
        );
    }

    render() {
        const theme = this.props.theme;

        const order_date_type = this.props.state.order.order_date_type;

        const now_selected =
            order_date_type === OrderDateType.NOW
                ? 'segmentedControlSelected'
                : '';
        const later_selected =
            order_date_type === OrderDateType.LATER
                ? 'segmentedControlSelected'
                : '';

        return (
            <div className="">
                <Header back=":goBack" history={this.props.history}></Header>

                <div className="itemDetailsDesktopContainer">
                    <div className="finalizeOrderContainer">
                        <div
                            className="finalizeOrderTitle"
                            style={theme.orderTitle}
                        >
                            Order for when?{' '}
                        </div>

                        <div className="segmentedControlContainer">
                            <div
                                className={`segmentedControl ${now_selected}`}
                                onClick={(e) =>
                                    this.setOrderType(OrderDateType.NOW)
                                }
                            >
                                Now
                            </div>
                            <div
                                className={`segmentedControl ${later_selected}`}
                                onClick={(e) =>
                                    this.setOrderType(OrderDateType.LATER)
                                }
                            >
                                Later
                            </div>
                        </div>

                        {order_date_type === OrderDateType.NOW
                            ? this.renderNowComponent()
                            : ''}
                        {order_date_type === OrderDateType.LATER
                            ? this.renderLaterComponent()
                            : ''}
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default withTheme(
    connect(mstp, {
        orderDateTypeChanged: OrderActions.orderDateTypeChanged,
        orderTimeChanged: OrderActions.orderTimeChanged,
    })(TakeoutTimePicker)
);
