import React from 'react';
import { connect } from 'react-redux';

import { withTheme } from '../../../themes/theming';
import { withTranslation } from 'react-i18next';

class FinalizeOrderButton extends React.Component {
    navigate(hasOrders) {
        this.props.history.push('/order');
    }

    render() {
        const theme = this.props.theme;

        const { temporary_order } = this.props.state.order;

        const hasOrders = temporary_order.length !== 0;
        const itemsLabel = hasOrders
            ? temporary_order.length === 1
                ? `1 ${this.props.t('oneMenu.items')}`
                : temporary_order.length + ` ${this.props.t('oneMenu.items')}`
            : `0 ${this.props.t('oneMenu.items')}`;

        const floatingButtonStyle = hasOrders
            ? { ...theme.floatingButton, backgroundColor: '#F15152' }
            : { ...theme.floatingButton };

        return (
            <div>
                <div
                    className="floatingButtonSafeArea"
                    style={theme.floatingButton}
                ></div>
                <div
                    className="floatingButton"
                    onClick={(e) => this.navigate(hasOrders)}
                    style={floatingButtonStyle}
                >
                    <div
                        className="floatingButtonLabel"
                        style={{
                            ...theme.floatingButtonLabel,
                        }}
                    >
                        <img
                            alt="Bag"
                            className="floatingButtonBag"
                            src="/assets/bag-white.png"
                        ></img>
                        {hasOrders
                            ? `${this.props.t('cart.placeOrderNow')}`
                            : `${this.props.t('oneMenu.myBasket')}`}
                    </div>

                    <div className="floatingButtonRight">
                        <div className="floatingButtonItemCount">
                            {itemsLabel}
                        </div>
                        <img
                            alt="Arrow"
                            src="/assets/thin-right-arrow-white.png"
                            className="floatButtonRightArrow"
                        />
                    </div>
                </div>

                {/* <div className="floatingNotificationContainer">
                <div className="floatingNotification">
                    Notification
                </div>
            </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { state };
};
const ConnectedComponent = connect(mapStateToProps)(FinalizeOrderButton);

const TranslatedComponent = withTheme(withTranslation()(ConnectedComponent));

export default TranslatedComponent;
