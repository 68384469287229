import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import amplitude from 'amplitude-js';
import Axios from 'axios';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as OrderActions from '../../../actions/orderActions';
import { APIv1Endpoint } from '../../../endpoints';
import * as Types from '../../../reducers/TYPES';
import { persistor } from '../../../reduxStore';
import { isDineinOrder } from '../../../utils';
import { createPath } from '../utils';

const useStyles = makeStyles((theme) => ({
    confirmation: {
        flex: '1',
        color: '#202020',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Poppins',
    },
}));

export default (props) => {
    const history = useHistory();
    const params = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const endpoint = params.endpoint;

    const [verificationError, setVerificationError] = useState(false);
    const [verificationErrorDetails, setVerificationErrorDetails] = useState(
        {}
    );

    const cart_items = useSelector((state) => state.order.temporary_order);
    const isDineInPayment = isDineinOrder();

    useEffect(() => {
        const search = queryString.parse(props.location.search);
        const request = {
            terminal_id: search.TERMINALID,
            order_id: search.ORDERID,
            datetime: search.DATETIME,
            approval_code: search.APPROVALCODE,
            response_code: search.RESPONSECODE,
            response_text: search.RESPONSETEXT,
            hash: search.HASH,
            total: Math.round(search.AMOUNT * 100),
            unique_ref: search.UNIQUEREF,
        };
        Axios.post(`${APIv1Endpoint}verifyNuveiPayment`, request)
            .then((res) => {
                if (res.data.success) {
                    amplitude
                        .getInstance()
                        .logEvent('Nuvei Payment Verification Succeeded');
                    dispatch(OrderActions.paymentComplete({}, history));
                } else {
                    amplitude
                        .getInstance()
                        .logEvent('Nuvei Payment Verification Failed', res);
                    setVerificationError(true);
                    setVerificationErrorDetails({
                        message:
                            'Sorry, there was an issue with your order. You will be refunded.',
                    });
                    console.log(res.data);
                }
            })
            .catch((err) => {
                amplitude
                    .getInstance()
                    .logEvent('Nuvei Payment Verification Errored', err);
                if (err.response) {
                    setVerificationError(true);
                    setVerificationErrorDetails({
                        message:
                            'Sorry, there was an error validating your payment. Did you accidentally press the Back button?',
                    });
                    console.log(err.response);
                } else {
                    console.error(err);
                }
            });
        amplitude.getInstance().logEvent('Verifying Nuvei Payment');
    }, [dispatch, history, props.location.search]);

    const startNewOrder = () => {
        dispatch({ type: Types.RESET_STATE });
        dispatch({
            type: Types.CLEAR_TEMPORARY_ORDERS,
        });
        dispatch({ type: Types.CLEAR_CAMPAIGN_INSTANCES });
        persistor.purge();
        history.push(createPath('/v2/:/order', [endpoint]));
    }

    let headerText = isDineInPayment
        ? 'Processing your payment...'
        : "We're preparing your order...";

    return (
        <div className={classes.confirmation}>
            {verificationError ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '2rem 1rem 0rem 1rem',
                        }}
                    >
                        <div
                            style={{
                                fontSize: '1.5rem',
                                fontWeight: '700',
                                flex: '1',
                                paddingRight: '1rem',
                            }}
                        >
                            An error occurred...
                        </div>
                        <ErrorOutline
                            style={{
                                width: '3rem',
                                height: '3rem',
                                fill: '#E45858',
                            }}
                        />
                    </div>
                    <div
                        style={{
                            padding: '1rem',
                            flex: '1',
                        }}
                    >
                        {verificationErrorDetails.message}
                    </div>
                    <Button
                        variant="contained"
                        onClick={startNewOrder}
                        style={{
                            width: '100vw',
                            backgroundColor: '#ECECEC',
                            textTransform: 'none',
                            color: '#444444',
                            borderRadius: 0,
                            padding: '1rem',
                            fontWeight: '700',
                        }}
                    >
                        Start New Order
                    </Button>
                </>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '2rem 1rem 0rem 1rem',
                    }}
                >
                    <div
                        style={{
                            fontSize: '1.5rem',
                            fontWeight: '700',
                            flex: '1',
                            paddingRight: '1rem',
                        }}
                    >
                        {headerText}
                    </div>
                    <CircularProgress color="#D2D2D2" />
                </div>
            )}
        </div>
    );
};
