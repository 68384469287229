import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as TableActions from '../../../actions/tableActions';
import { createPath } from '../utils';
import './css/OrderTypeSelect.css';
import Header from '../../../components/Header';
import { LanguageSwitch } from '../../../components/LanguageSwitch';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const {
        match: { params },
    } = props;
    const endpoint = params.endpoint;
    const restaurant = useSelector((state) => state.main.restaurant_info);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isDineInAvailable = [
        'RESTAURANT_SETTING_TAKE_OUT_AND_DINE_IN',
        'RESTAURANT_SETTING_DINE_IN_ONLY',
    ].includes(restaurant.order_mode);
    const isTakeoutAvailable = [
        'RESTAURANT_SETTING_TAKE_OUT_ONLY',
        'RESTAURANT_SETTING_TAKE_OUT_AND_DINE_IN',
    ].includes(restaurant.order_mode);

    const takeOutPressed = () => {
        history.push(createPath('/v2/:/order', [restaurant.endpoint]));
        dispatch(TableActions.takeAwaySelected(history, false));
        dispatch(TableActions.setVersion(2)); /*  */
    };

    const dineInPressed = () => {
        history.push(createPath('/v2/:/selectTable', [endpoint]));
        dispatch(TableActions.loadStandingTable(endpoint, history, '', false));
    };

    useEffect(() => {
        // Handle dine-in only redirection
        if (
            ['RESTAURANT_SETTING_DINE_IN_ONLY'].includes(restaurant.order_mode)
        ) {
            dineInPressed();
        }
        // Handle take-out only redirection
        if (
            ['RESTAURANT_SETTING_TAKE_OUT_ONLY'].includes(restaurant.order_mode)
        ) {
            takeOutPressed();
        }
        if (['RESTAURANT_SETTING_NONE'].includes(restaurant.order_mode)) {
            takeOutPressed();
        }
    }, [restaurant]);

    return (
        <>
            <div className="tableSelectContainer">
                <div className="tableSelectWelcomeHeader">
                    {t('orderTypePage.welcomeMessage')}
                </div>
                <div className="tableSelectWelcomeDescription">
                    {t('orderTypePage.orderTypeQuestion')}
                </div>

                <div className="dineSelectRow">
                    {isDineInAvailable ? (
                        <div
                            className="dineSelectPrimaryButton"
                            onClick={dineInPressed}
                        >
                            <img
                                className="dineSelectPrimaryImage"
                                src="/assets/icons/DineInIcon.svg"
                            ></img>
                            <div className="dineSelectPrimaryText">
                                {t('common.dineInOption')}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {isTakeoutAvailable ? (
                        <div
                            className="dineSelectPrimaryButton"
                            onClick={takeOutPressed}
                        >
                            <img
                                className="dineSelectPrimaryImage"
                                src="/assets/icons/TakeOutIcon.svg"
                            ></img>
                            <div className="dineSelectPrimaryText">
                                {t('common.takeOutOption')}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div style={{ marginTop: 20 }}>
                    <div
                        className="tableSelectNextButton"
                        onClick={takeOutPressed}
                    >
                        {t('common.browseMenuOption')}
                    </div>
                </div>
            </div>
        </>
    );
};
