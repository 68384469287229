const theme = {
    // GUEST LIST
    description: 'a sense of place & someplace else',

    guestListSubtitleContainer: {
        display: 'flex',
        marginLeft: '30px',
        marginRight: '30px',

        textAlign: 'left',

        fontFamily: 'Playfair Display',
        fontStyle: 'italic',
        fontSize: '20px',
        fontWeight: '300',
        color: '#606060',

        marginTop: '60px',
        marginBottom: '80px',
    },

    // OPTIONAL GUEST HOME FOOTER
    optionalHomeFooter: {
        paddingTop: '50px',
        paddingBottom: '20px',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        color: '#999',
        fontFamily: 'Noto Sans JP',
        fontSize: '16px',
        fontStyle: 'italic',
    },
    optionalHomeFooterText: '100 Cumberland St, Toronto, ON M5R 1A6',

    //
    guestListGreeting: {
        fontSize: '20px',
        fontFamily: 'Playfair Display',
    },

    buttonFont: {
        fonSize: '18px',
    },

    pageTitleFont: {},
    pageDescriptionFont: {
        fontStyle: 'normal',
        fontSize: '18px',
    },

    addGuestTextBox: {
        borderRadius: '5px',
    },

    // FLOATING BUTTON

    floatingButton: {
        // backgroundColor: "#edb92a",
    },
    floatingButtonLabel: {},

    // HEADER
    headerGuestNameLabel: {},
    headerBackButtonText: {
        fontSize: '22px',
    },
    headerGuestIcon: {},

    // GUEST HOME
    optionalHomeHeaderUrl: '/assets/photos/sass/sasshome.jpeg',
    optionalHomeHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    optionalHomeHeaderImg: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: '50% 0%',
    },

    // Homepage Greeting
    welcomeGreeting: {
        fontSize: '20px',
    },
    welcomeName: {
        fontSize: '20px',
    },

    // Finalize Item Label
    finalizeItemLabel: {
        fontSize: '18px',
    },
    finalizeItemPrice: {},
    finalizeItemDelete: {},
    finalizeAddItemsButton: {
        border: '2px solid #758688',
        color: '#758688',
    },

    // Generic titles and descriptions
    orderTitle: {},
    orderDescription: {
        fontStyle: 'normal',
        fontSize: '18px',
    },

    restaurantMenuTitle: {},
    restaurantCategoryLabel: {
        fontSize: '22px',
        fontWeight: '400',
    },

    menuCategoryTitle: {},
    itemDetailTitle: {
        color: '#758688',
    },
    itemDetailPrice: {
        color: '#758688',
    },

    itemDetailDescription: {
        color: '#758688',
    },

    itemDescription: {
        color: '#758688',
    },
    itemPrice: {
        color: '#758688',
    },
    menuItemLabel: {
        color: '#758688',
    },
};

export default theme;
