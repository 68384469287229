import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as OrderActions from '../../../actions/orderActions';
import { APIv1Endpoint } from '../../../endpoints';

function ApplePayButton(props) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document
            .querySelector('#apple-pay-button')
            .addEventListener('click', onButtonClick);
    }, []);

    const onButtonClick = () => {
        if (loading) {
            return;
        }
        setLoading(true);

        const amount =
            Math.round(
                props.state.order.payment_totals.total_with_tip_and_tax * 100
            ) / 100;
        const currencyCode =
            props.state.main.restaurant_info.currency.toUpperCase();

        const request = {
            countryCode: currencyCode === 'USD' ? 'US' : 'CA',
            currencyCode: currencyCode,
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            merchantCapabilities: ['supports3DS'],
            total: {
                label: props.state.main.restaurant_info.name,
                type: 'final',
                amount: amount,
            },
        };

        const newLineItems = [];

        const session = new window.ApplePaySession(3, request);

        session.onpaymentmethodselected = (event) => {
            session.completePaymentMethodSelection(request.total, newLineItems);
        };

        session.onshippingmethodselected = (event) => {
            session.completeShippingMethodSelection(
                window.ApplePaySession.STATUS_SUCCESS,
                request.total,
                newLineItems
            );
        };

        session.onshippingcontactselected = (event) => {
            var shipping = 'CA';
            session.completeShippingContactSelection(
                window.ApplePaySession.STATUS_SUCCESS,
                shipping,
                request.total,
                newLineItems
            );
        };

        session.onvalidatemerchant = (event) => {
            const url = `${APIv1Endpoint}authorizeApplePayMerchant`;

            const req = {
                validate_url: event.validationURL,
            };

            axios
                .post(url, req)
                .then((res) => {
                    let text = res.data.merchant_session;
                    text = JSON.parse(text);
                    session.completeMerchantValidation(text);
                })
                .catch((err) => {
                    console.error('Error fetching merchant session', err);
                });
        };

        session.onpaymentauthorized = (event) => {
            let token = event.payment.token.paymentData;
            let tokentext = JSON.stringify(token);
            let tokenb64 = btoa(tokentext);
            props.processBamboraToken(
                props.history,
                tokenb64,
                'PAYMENT_METHOD_APPLE'
            );
            const result = {
                status: window.ApplePaySession.STATUS_SUCCESS,
            };
            session.completePayment(result);
        };

        session.oncancel = (event) => {};

        session.begin();
    };

    return (
        <div>
            <apple-pay-button
                id="apple-pay-button"
                style={{
                    display: 'inline-block',
                }}
                onClick={onButtonClick}
                buttonstyle="black"
                type="pay"
            ></apple-pay-button>
        </div>
    );
}

const mapStateToProps = (state) => ({
    state,
});

const mapDispatchToProps = {
    processBamboraToken: OrderActions.processBamboraToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplePayButton);
