import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';
import Order from './Menu/Order';
import Item from './Menu/Item';
import ItemScreen from './Menu/Item';
import ModifierSelect from './Menu/ModifierSelect';
import SpecialInstructions from './Menu/ItemInstructions';
import { useDispatch, useSelector } from 'react-redux';

import * as TableActions from '../../actions/tableActions';
import * as OrderActions from '../../actions/orderActions';
import Cart from './Cart/Cart';
import PhoneNumber from './Cart/PhoneNumber';
import PayStripe from './Cart/PayStripe';
import PayNMI from './Cart/PayNMI';
import PayBambora from './Cart/PayBambora';
import NuveiConfirmation from './Cart/NuveiConfirmation';
import OrderComplete from './Cart/OrderComplete';
import SingleMenuPage from './Menu/SingleMenuPage';
import SingleCategoryPage from './Menu/SingleCategoryPage';
import SearchPage from './SearchPage';
import './css/fonts.css';
import { createPath, parseUrl } from './utils';
import CampaignDetails from './Menu/CampaignDetails';
import './css/V2Root.css';
import ScrollToTop from './Components/ScrollToTop';
import PoweredByMentum from './Components/PoweredByMentum';
import TableSelect from './DineIn/TableSelect';
import GuestSelect from './DineIn/GuestSelect';
import {
    AFFILIATE_CODE_CHANGED,
    CLEAR_TEMPORARY_ORDERS,
    DineInOrTakeoutSetting,
    PaymentType,
} from '../../reducers/TYPES';
import OrderTypeSelect from './DineIn/OrderTypeSelect';
import { isDineinOrder, isFrontOfHouse } from '../../utils';
import { IFrameMessage } from '../../IFrameMessage';

export default (props) => {
    const history = useHistory();
    const params = useParams();
    const endpoint = useSelector(
        (state) => state.main.restaurant_info.endpoint
    );
    const dispatch = useDispatch();
    const location = useLocation();
    const order = useSelector((state) => state.table.guest_orders);
    const [prevPath, setPrevPath] = useState('');
    const ref = useRef();
    // let oldHeight = window.innerHeight;

    const isDineInOrder = isDineinOrder();

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                // if (Math.abs(oldHeight - window.innerHeight) >= RERENDER_THRESHOLD) {
                document.documentElement.style.setProperty(
                    '--min-height',
                    `${window.innerHeight}px`
                );
                // oldHeight = window.innerHeight
                setSize([window.innerWidth, window.innerHeight]);
                // }
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    useWindowSize();

    useEffect(() => {
        // ensure restaurant data is loaded and guest is created
        const _affiliate = params.affiliate;
        const _endpoint = params.endpoint;

        dispatch(TableActions.setVersion(2));

        const matches = [
            createPath('/v2/:', [_endpoint]),
            createPath('/v2/:/', [_endpoint]),
        ];
        const fohMatches = [
            createPath('/v2/:/:', [_endpoint, '$frontOfHouse']),
            createPath('/v2/:/:/', [_endpoint, '$frontOfHouse']),
        ];

        const deliveryMatches = [
            createPath('/v2/:/:', [_endpoint, '$delivery']),
            createPath('/v2/:/:/', [_endpoint, '$delivery']),
        ];

        if (matches.includes(location.pathname)) {
            let path = createPath('/v2/:', [_endpoint]);
            history.push(`${path}/home`);
        }
        if (
            fohMatches.includes(location.pathname) ||
            deliveryMatches.includes(location.pathname)
        ) {
            let path = createPath('/v2/:', [_endpoint]);
            history.push(`${path}/order`);
        }

        const createNewOrder = () => {
            // create a guest as a take-out user
            dispatch(
                TableActions.loadStandingTable(
                    _endpoint,
                    history,
                    _affiliate || '',
                    false
                )
            );
            dispatch({
                type: CLEAR_TEMPORARY_ORDERS,
            });
            // set dine-in type to default to Takeout
            dispatch(
                OrderActions.setDineInOrTakeout(DineInOrTakeoutSetting.TAKEOUT)
            );
        };

        if (
            order.order_time &&
            _affiliate !== '$frontOfHouse' &&
            isFrontOfHouse()
        ) {
            createNewOrder();
            return;
        }

        if (order.order_time && _affiliate !== '$frontOfHouse') {
            // Check if guest can be reused
            const now = new Date();
            const time = new Date(order.order_time);

            const timeValid = now - time < 1000 * 60 * 30;

            const orderHasItems =
                Array.isArray(order.items) && order.items.length > 0;
            const isEndpointSame = _endpoint === endpoint;

            if (
                (timeValid && !orderHasItems && isEndpointSame) ||
                (isDineInOrder && isEndpointSame)
            ) {
                // reuse the same guest

                // load restaurant data
                dispatch(TableActions.loadRestaurantDetails());

                // Reload menu tho
                dispatch(OrderActions.loadMenu());

                // Load guest orders
                if (isDineInOrder) {
                    dispatch(TableActions.loadGuestFromDB());
                    dispatch(TableActions.loadOrders(history, false));
                }

                if (
                    _affiliate &&
                    (fohMatches.includes(location.pathname) ||
                        deliveryMatches.includes(location.pathname))
                ) {
                    dispatch({
                        type: AFFILIATE_CODE_CHANGED,
                        payload: _affiliate,
                    });
                }

                return;
            }
        }

        createNewOrder();

        /* eslint-disable */
    }, []);

    useEffect(() => {
        const previousLocation = usePrevious(location, ref);
        setPrevPath(previousLocation);
        let truncatedUrl = parseUrl(location.pathname);
        if (truncatedUrl.length === 117) {
            //currently at Item
            if (previousLocation) {
                let truncatedPrevUrl = parseUrl(previousLocation.pathname);
                if (
                    !(
                        truncatedPrevUrl.length === 120 ||
                        truncatedPrevUrl.length === 157 ||
                        truncatedPrevUrl === '/cart' ||
                        truncatedPrevUrl.length === 117
                    )
                ) {
                    //ensuring that we are not dispatching the modSelect, itemInstr, item or cart as the page before
                    dispatch(OrderActions.setPageBeforeItem(previousLocation));
                }
            } else {
                dispatch(OrderActions.setPageBeforeItem(previousLocation));
            }
        }
        /* eslint-disable */
    }, [location]);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#20B04B',
            },
        },
        typography: {
            fontFamily: 'Poppins',
            h5: {
                fontWeight: '700',
            },
        },
        overrides: {
            MuiBottomNavigationAction: {
                label: {
                    fontWeight: '700',
                },
            },
        },
    });

    const navIndexToRoute = {
        0: `/v2/${endpoint}/order`,
        1: `/v2/${endpoint}/account`,
    };

    return (
        <>
            <IFrameMessage />
            <ThemeProvider theme={theme}>
                <div className="v2RootBody">
                    <ScrollToTop>
                        <Route
                            exact
                            path="/v2/:endpoint/search"
                            render={(props) => (
                                <SearchPage
                                    {...props}
                                    previousPath={prevPath}
                                />
                            )}
                            previousPath={prevPath}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint"
                            component={Order}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/item"
                            component={Item}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/m/:menu_id"
                            render={(props) => (
                                <SingleMenuPage
                                    {...props}
                                    previousPath={prevPath}
                                />
                            )}
                            previousPath={prevPath}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/cart"
                            render={(props) => (
                                <Cart {...props} previousPath={prevPath} />
                            )}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/phone"
                            component={PhoneNumber}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/payStripe"
                            component={PayStripe}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/payBambora/:id"
                            render={(props) => (
                                <PayBambora
                                    {...props}
                                    previousPath={prevPath}
                                />
                            )}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/nuveiConfirmation"
                            component={NuveiConfirmation}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/payNMI/:id"
                            render={(props) => (
                                <PayNMI {...props} previousPath={prevPath} />
                            )}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/orderComplete"
                            component={OrderComplete}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/m/:menu_id/c/:category_id"
                            render={(props) => (
                                <SingleCategoryPage
                                    {...props}
                                    previousPath={prevPath}
                                />
                            )}
                            previousPath={prevPath}
                        ></Route>
                        <Route
                            exact
                            path="/v2/:endpoint/m/:menu_id/c/:category_id/i/:item_id"
                            render={(props) => (
                                <ItemScreen
                                    {...props}
                                    previousPath={prevPath}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/v2/:endpoint/m/:menu_id/c/:category_id/i/:item_id/mg/:modifier_group_id"
                            render={(props) => (
                                <ModifierSelect
                                    {...props}
                                    previousPath={prevPath}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/v2/:endpoint/m/:menu_id/c/:category_id/i/:item_id/si"
                            render={(props) => (
                                <SpecialInstructions
                                    {...props}
                                    previousPath={prevPath}
                                />
                            )}
                        />

                        <Route
                            path="/v2/:endpoint/order"
                            component={Order}
                        ></Route>
                        <Route
                            path="/v2/:endpoint/campaign/:campaign_id"
                            component={CampaignDetails}
                        ></Route>

                        {/* Dine-in Routes */}
                        <Route
                            exact
                            path="/v2/:endpoint/home"
                            component={OrderTypeSelect}
                        />
                        <Route
                            exact
                            path="/v2/:endpoint/selectTable"
                            component={TableSelect}
                        />
                        <Route
                            exact
                            path="/v2/:endpoint/table/:table_id/guests"
                            component={GuestSelect}
                        />
                    </ScrollToTop>
                    <PoweredByMentum />
                </div>
                {/* <div className="v2RootNavbar">
    <BottomNavigation
      value={route}
      onChange={(evt, navIndex) => {
      history.push(navIndexToRoute[navIndex]);
      setRoute(navIndex);
      }}
      showLabels
    >
      <BottomNavigationAction
      label="Order"
      icon={<AiFillHome size="2em" />}
      ></BottomNavigationAction>
    </BottomNavigation>
    </div> */}
            </ThemeProvider>
        </>
    );
};

// Hook
export const usePrevious = (value, ref) => {
    if (ref.current !== value) {
        let previous = ref.current;
        ref.current = value;
        return previous;
    }
};
