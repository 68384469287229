import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import './css/GuestSelect.css';
import * as TableActions from '../../../actions/tableActions';
import * as OrderActions from '../../../actions/orderActions';
import { v4 as uuidv4 } from 'uuid';
import { APIv1Endpoint } from '../../../endpoints';
import axios from 'axios';
import { createPath } from '../utils';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    DineInOrTakeoutSetting,
    LOADING_TABLE_GUESTS,
} from '../../../reducers/TYPES';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const GuestSelect = (props) => {
    const {
        match: { params },
    } = props;
    const { endpoint, table_id } = params;
    const [showNewGuestModal, setShowNewGuestModal] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const [newGuestName, setNewGuestName] = useState('');
    const [newGuestError, setNewGuestError] = useState('');
    const [isAddingGuest, setIsAddingGuest] = useState(false);

    const guests = useSelector((state) => state.table.guests);
    const is_loading_guests = useSelector(
        (state) => state.table.is_loading_guests
    );

    const { t } = useTranslation();

    useEffect(() => {
        dispatch({
            type: LOADING_TABLE_GUESTS,
            payload: true,
        });

        const { restaurant_id } = props.state.main.restaurant_info;
        axios
            .post(`${APIv1Endpoint}table/local`, {
                restaurant_id,
                local_id: table_id,
            })
            .then((res) => {
                const { table } = res.data;
                props.dineInTableFound(table, props.history, false);
                props.loadGuestList();
            })
            .catch((err) => {
                toast.error(t('toast.tableNotFound2'), {
                    autoClose: 10000,
                });
                history.push(createPath('/v2/:/selectTable', [endpoint]));
                console.error(err.response);
                dispatch({
                    type: LOADING_TABLE_GUESTS,
                    payload: false,
                });
            });

        dispatch(
            OrderActions.setDineInOrTakeout(DineInOrTakeoutSetting.DINE_IN)
        );
    }, []);

    const selectGuest = (guest) => {
        props.selectGuest(guest, null, false);
        history.push(createPath('/v2/:/order', [endpoint]));
    };

    const renderGuests = () => {
        if (is_loading_guests) {
            return (
                <div
                    className="dineInExistingOrderProgressBarContainer"
                    style={{ marginTop: 20 }}
                >
                    <CircularProgress size={36} />
                </div>
            );
        }

        let guestsRender = (guests || []).map((guest) => {
            const name = guest.name;
            const initials = name
                .split(' ')
                .map((word) => word[0])
                .join('');

            return (
                <div
                    className="guestSelectGuestContainer"
                    onClick={() => selectGuest(guest)}
                >
                    <div className="guestSelectCircle">{initials}</div>
                    <div className="guestSelectName">{name}</div>
                </div>
            );
        });

        return (
            <>
                <div className="guestSelectGroupContainer">
                    <div
                        className="guestSelectGuestContainer"
                        onClick={() => setShowNewGuestModal(true)}
                    >
                        <div className="guestSelectCircle">
                            <img
                                src="/assets/icons/new-guest-plus.png"
                                style={{ width: 30, height: 30 }}
                            />
                        </div>
                        <div className="guestSelectName">&nbsp;</div>
                    </div>
                    {guestsRender}
                </div>
            </>
        );
    };

    const renderAddGuestModal = () => {
        const addGuest = async () => {
            if (isAddingGuest) {
                return;
            }

            setIsAddingGuest(true);
            props.addGuestToDineinTable(null, false, newGuestName);
            history.push(createPath('/v2/:/order', [endpoint]));
            setIsAddingGuest(false);
        };

        const renderNextButton = () => {
            if (!newGuestName) {
                return (
                    <div className="tableSelectNextButton tableSelectNextButtonDisabled">
                        {t('common.continue')}
                    </div>
                );
            } else {
                return (
                    <div className="tableSelectNextButton" onClick={addGuest}>
                        {isAddingGuest
                            ? t('guestSelect.pleaseWait')
                            : t('common.continue')}
                    </div>
                );
            }
        };

        if (showNewGuestModal) {
            return (
                <div className="addGuestModal">
                    <div className="addGuestModalContainer">
                        <div
                            className="cancelRow"
                            onClick={() => setShowNewGuestModal(false)}
                        >
                            <img
                                src="/assets/icons/modal-close.png"
                                style={{ width: 15, height: 15 }}
                            />
                        </div>
                        <div className="tableModalContainer">
                            <div
                                className="tableSelectWelcomeHeader"
                                style={{ color: '#1C4055', marginTop: 20 }}
                            >
                                {t('guestSelect.joinTable')}
                            </div>

                            <div
                                className="tableSelectInputContainer tableSelectInputContainerSafari"
                                style={{ marginTop: -30, marginBottom: -30 }}
                            >
                                <div className="tableSelectInputHeader">
                                    {t('tableSelect.table')}
                                </div>
                                <div className="TableSelectInputFieldContainer">
                                    <input
                                        type="text"
                                        className="tableSelectInputField"
                                        value={table_id}
                                    />
                                </div>
                            </div>

                            <div
                                className="tableSelectWelcomeDescription"
                                style={{ maxWidth: '100%' }}
                            >
                                {t('guestSelect.name')}
                            </div>
                            <input
                                type="text"
                                className="addGuestInput"
                                placeholder="John Doe"
                                value={newGuestName}
                                onChange={(e) =>
                                    setNewGuestName(e.target.value)
                                }
                            ></input>

                            <div style={{ marginTop: 60 }}>
                                {renderNextButton()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            {renderAddGuestModal()}
            <div className="tableSelectContainer">
                <div
                    className="tableSelectWelcomeHeader"
                    style={{ color: '#1C4055' }}
                >
                    <div>
                        {t('tableSelect.table')} {table_id}
                    </div>
                    <div
                        className="guestSelectEditTableBtn"
                        onClick={() =>
                            history.push(
                                createPath('/v2/:/selectTable', [endpoint])
                            )
                        }
                    >
                        {t('cart.edit')}
                    </div>
                </div>

                {renderGuests()}
            </div>
        </>
    );
};

const mstp = (state) => ({ state });

export default connect(mstp, {
    loadGuestList: TableActions.loadGuestList,
    addGuestToTable: TableActions.addGuestToDineinTable,
    selectGuest: TableActions.selectGuest,
    dineInTableFound: TableActions.dineInTableFound,
    addGuestToDineinTable: TableActions.addGuestToDineinTable,
})(GuestSelect);
