export const PaymentProcessor = {
    NONE: 'NONE',
    STRIPE: 'STRIPE',
    NUVEI: 'NUVEI',
    NMI: 'NMI',
    BAMBORA: 'BAMBORA',
};

export const DineInOrTakeoutSetting = {
    NONE: 'NONE',
    DINE_IN: 'DINE_IN',
    TAKEOUT: 'TAKEOUT',
};
export const ADDING_GUEST_TO_TABLE = 'ADDING_GUEST_TO_TABLE';
export const LOADING_TABLE_GUESTS = 'LOADING_TABLE_GUESTS';
export const LOAD_GUEST_ORDERS = 'LOAD_GUEST_ORDERS';
export const LOADING_GUEST_ORDERS = 'LOADING_GUEST_ORDERS';
export const TAP_TO_CHECKOUT_NOTIF_DISPLAY = 'TAP_TO_CHECKOUT_NOTIF_DISPLAY';
export const TAP_TO_CHECKOUT_NOTIF_SHOWN = 'TAP_TO_CHECKOUT_NOTIF_SHOWN';
export const READY_TO_PAY_CHECKOUT_DISPLAY = 'READY_TO_PAY_CHECKOUT_DISPLAY';
export const TOP_TEN_LOADED = 'TOP_TEN_LOADED';
export const NUVEI_CANCELLATION_TOKEN_RECEIVED =
    'NUVEI_CANCELLATION_TOKEN_RECEIVED';
export const NUVEI_PAYMENT_REQUEST_RECEIVED = 'NUVEI_PAYMENT_REQUEST_RECEIVED';
export const PAYMENT_PROCESSOR_CHANGED = 'PAYMENT_PROCESSOR_CHANGED';

export const NUVEI_PAYMENT_TERMINALS_LOADED = 'NUVEI_PAYMENT_TERMINALS_LOADED';
export const NUVEI_PAYMENT_TERMINAL_SELECTED =
    'NUVEI_PAYMENT_TERMINAL_SELECTED';
export const NUVEI_ERROR_CHANGED = 'NUVEI_ERROR_CHANGED';

export const TERMINAL_VENDOR_CHANGED = 'TERMINAL_VENDOR_CHANGED';
export const TerminalVendors = {
    NONE: 'NONE',
    STRIPE: 'STRIPE',
    NUVEI: 'NUVEI',
};
export const RESTAURANT_ENDPOINT_RECEIVED = 'RESTAURANT_ENDPOINT_RECEIVED';
export const RESTAURANT_AFFILIATE_RECEIVED = 'RESTAURANT_AFFILIATE_RECIEVED';
export const IS_EDIT_ITEM_CHANGED = 'IS_EDIT_ITEM_CHANGED';
export const PAGE_BEFORE_ITEM_CHANGED = 'PAGE_BEFORE_ITEM_CHANGED';

export const CAMPAIGN_CODE_METADATA_UPDATED = 'CAMPAIGN_CODE_METADATA_UPDATED';

export const AFFILIATE_CODE_CHANGED = 'AFFILIATE_CODE_CHANGED';

export const DISCOUNT_CODE_FIELD_CHANGED = 'DISCOUNT_CODE_FIELD_CHANGED';
export const PAY_AT_COUNTER_LOADING = 'PAY_AT_COUNTER_LOADING';
export const CAMPAIGN_INSTANCE_RECEIVED = 'CAMPAIGN_INSTANCE_RECEIVED';

export const ITEM_EDIT_MODE_CHANGED = 'ITEM_UPDATE_MODE_CHANGED';
export const CATEGORIES_TO_ITEMS_MAP_UPDATED =
    'CATEGORIES_TO_ITEMS_MAP_UPDATED';
export const LOCAL_ID_CHANGED = 'LOCAL_ID_CHANGED';

export const TERMINAL_READERS_LOADED = 'TERMINAL_READERS_LOADED';
export const TERMINAL_READER_CONNECTED = 'TERMINAL_READER_CONNECTED';
export const TERMINAL_IS_CONNECTING = 'TERMINAL_IS_CONNECTING';
export const TERMINAL_SET_CONNECTED = 'TERMINAL_SET_CONNECTED';
export const SET_TERMINAL_CONNECTION_ERROR = 'SET_TERMINAL_CONNECTION_ERROR';
export const TERMINAL_PAYMENT_ERROR_CHANGED = 'TERMINAL_PAYMENT_ERROR_CHANGED';
export const SET_READER_DISABLED = 'SET_READER_DISABLED';

export const DELIVERY_OPTION_UPDATED = 'DELIVERY_OPTION_UPDATED';
export const DELIVERY_FEE = 'DELIVERY_FEE';
export const QUOTE_ID = 'QUOTE_ID';
export const PAYMENT_LOADING = 'PAYMENT_LOADING';
export const DROPOFF_INSTRUCTIONS = 'DROPOFF_INSTRUCTIONS';
export const DROPOFF_SUBMIT = 'DROPOFF_SUBMIT';
export const ADDRESS = 'ADDRESS';
export const DROPOFF_ETA = 'DROPOFF_ETA';

export const TerminalPaymentStatus = {
    DEFAULT: 'DEFAULT',
    READY: 'READY',
    PROCESSING: 'PROCESSING',
    COMPLETE: 'COMPLETE',
    FAILED: 'FAILED',
};

export const TERMINAL_PAYMENT_STATUS_CHANGED =
    'TERMINAL_PAYMENT_STATUS_CHANGED';

export const OrderDateType = {
    NOW: 0,
    LATER: 1,
};

export const DeviceTypes = {
    KIOSK: 0,
    TABLET: 1,
    CONSUMER_DEVICE: 2, // computer or smartphone
};

export const DEVICE_TYPE_CHANGED = 'DEVICE_TYPE_CHANGED';

export const FINALIZE_ITEM_QUANTITY_UPDATED = 'FINALIZE_ITEM_QUANTITY_UPDATED';
export const MENU_SCHEDULES_LOADED = 'MENU_SCHEDULES_LOADED';

export const ORDER_DATE_TYPE_CHANGED = 'ORDER_DATE_TYPE_CHANGED';
export const ORDER_TIME_CHANGED = 'ORDER_TIME_CHANGED';

export const ORDER_SPECIAL_INSTRUCTIONS_CHANGED =
    'ORDER_SPECIAL_INSTRUCTIONS_CHANGED';
export const SELECTED_ITEM_SPECIAL_INSTRUCTIONS_CHANGED =
    'SELECTED_ITEM_SPECIAL_INSTRUCTIONS_CHANGED';
export const TWOFA_CODE_TIME_REQUESTED = 'TWO_CODE_TIME_REQUESTED';
export const TWOFA_COMPLETION_CHANGED = 'TWOFA_COMPLETION_CHANGED';
export const TOGGLE_HEALTH_AND_SAFETY = 'TOGGLE_HEALTH_AND_SAFETY';
export const DINE_IN_TABLE_FOUND = 'DINE_IN_TABLE_FOUND';
export const SET_TABLET_ENDPOINT = 'SET_TABLET_ENDPOINT';
export const SET_IS_TABLET = 'SET_IS_TABLET';
// payment type describes whether or not payment is required before
// the order is sent to the kitchen.
export const PaymentType = {
    DISABLED: 'NoPayment',
    PAY_IMMEDIATE: 'PaymentRequired',
    PAY_LATER: 'PayAfterOrder',
    PAY_COUNTER_ONLY: 'PayCounterOnly',
};
export const MENU_ONLY_MODE_CHANGED = 'MENU_ONLY_MODE_CHANGED';
export const PAYMENT_TYPE_CHANGED = 'PAYMENT_TYPE_CHANGED';
export const TIP_CHANGED = 'TIP_CHANGED';
export const DELIVERY_TIP_PERCENTAGE_CHANGED =
    'DELIVERY_TIP_PERCENTAGE_CHANGED';
export const SET_PAYMENT_REQUEST = 'SET_PAYMENT_REQUEST';
export const PAYMENT_INTENT_RECEIVED = 'PAYMENT_INTENT_RECEIVED';
export const PAYMENT_INTENT_COMPLETED = 'PAYMENT_INTENT_COMPLETED';
export const PAYMENT_AMOUNT_UPDATED = 'PAYMENT_AMOUNT_UPDATED';
export const PAYMENT_TOTAL_UPDATED = 'PAYMENT_TOTAL_UPDATED';
export const CART_UPDATING = 'CART_UPDATING';
export const CART_UPDATED = 'CART_UPDATED';

export const table_id_UPDATED = 'table_id_UPDATED';
export const GUEST_LIST_UPDATED = 'GUEST_LIST_UPDATED';
export const ADD_GUEST_NAME_UPDATED = 'ADD_GUEST_NAME_UPDATED';
export const table_id_ERROR_OCCURED = 'table_id_ERROR_OCCURED';
export const table_id_SUCCEEDED = 'table_id_SUCCEEDED';

export const RESET_STATE = 'RESET_STATE';

export const THEME_UPDATED = 'THEME_UPDATED';

export const ADD_GUEST_BUTTON_STATE_CHANGED = 'ADD_GUEST_BUTTON_STATE_CHANGED';
export const GUEST_NAME_IN_USE = 'GUEST_NAME_IN_USE';
export const ADD_GUEST_ERROR_RECEIVED = 'ADD_GUEST_ERR_RECEIVED';
export const GUEST_SELECTED = 'GUEST_SELECTED';

export const ORDERS_UPDATED = 'ORDERS_UPDATED';

export const LOADING_STATUS_UPDATED = 'LOADING_STATUS_UPDATED';
export const RESTAURANT_INFO_UPDATED = 'RESTAURANT_INFO_UPDATED';
export const RESTAURANT_STATE_UPDATED = 'RESTAURANT_STATE_UPDATED';

// MENU
export const MENU_CATEGORIES_LOADED = 'MENU_CATEGORIES_LOADED';
export const MENU_ITEMS_LOADED = 'MENU_ITEMS_LOADED';
export const MENU_CATEGORY_SELECTED = 'MENU_CATEGORY_SELECTED';
export const MENU_ITEM_SELECTED = 'MENU_ITEM_SELECTED';
export const LOADING_MENUS_STATUS_UPDATED = 'LOADING_MENUS_STATUS_UPDATED';
export const RESTAURANT_MENUS_LOADED = 'RESTAURANT_MENUS_LOADED';
export const RESTAURANT_IN_SCHEDULE_MENUS_LOADED =
    'RESTAURANT_IN_SCHEDULE_MENUS_LOADED';
export const MENU_SELECTED = 'MENU_SELECTED';

export const MENU_MODIFIERS_LOADED = 'MENU_MODIFIERS_LOADED';
export const MENU_MODIFIER_GROUPS_LOADED = 'MENU_MODIFIER_GROUPS_LOADED';

export const ITEM_MODIFIER_SELECTED = 'ITEM_MODIFIERS_SELECTED';
export const ITEM_MODIFIERS_UPDATED = 'ITEM_MODIFIERS_UPDATED';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';

export const ITEM_ADDED_TO_ORDER = 'ITEM_ADDED_TO_ORDER';
export const ITEM_QUANTITY_CHANGED = 'ITEM_QUANTITY_CHANGED';
export const CLEAR_TEMPORARY_ORDERS = 'CLEAR_TEMPORARY_ORDERS';
export const CLEAR_ORDER_TIME = 'CLEAR_ORDER_TIME';

export const SET_MENU_TRANSLATIONS = 'SET_MENU_TRANSLATIONS';

export const SET_LANGUAGE = 'SET_LANGUAGE';

//CAMPAIGN
export const RESTAURANT_CAMPAIGNS_LOADED = 'RESTAURANT_CAMPAIGNS_LOADED';
export const RESTAURANT_FEATURED_CAMPAIGNS_LOADED =
    'RESTAURANT_FEATURED_CAMPAIGNS_LOADED';
export const CAMPAIGN_SELECTED = 'CAMPAIGN_SELECTED';
export const CAMPAIGN_ITEM_SELECTED = 'CAMPAIGN_ITEM_SELECTED';
export const CAMPAIGN_ADDED_TO_ORDER = 'CAMPAIGN_ADDED_TO_ORDER';
export const CAMPAIGN_INSTANCE_DELETED = 'CAMPAIGN_INSTANCE_DELETED';
export const CLEAR_CAMPAIGN_INSTANCES = 'CLEAR_CAMPAIGN_INSTANCES';
export const REWARD_ITEMS_ADDED_TO_ORDER = 'REWARD_ITEMS_ADDED_TO_ORDER';

//
export const DELETE_ITEM_STEP_1 = 'DELETE_ITEM_STEP_1';

// RECEIPT
export const RECEIPT_ORDER_UPDATED = 'RECEIPT_ORDER_UPDATED';
export const RECEIPT_RESTAURANT_UPDATED = 'RECEIPT_RESTAURANT_UPDATED';
export const RESTAURANT_RATING_UPDATED = 'RESTAURANT_RATING_UPDATED';
export const PHONE_NUMBER_UPDATED = 'PHONE_NUMBER_UPDATED';

// v2
export const VERSION_UPDATED = 'VERSION_UPDATED';

// Dine-in
export const DINE_IN_OR_TAKE_OUT_CHANGED = 'DINE_IN_OR_TAKEOUT_CHANGED';
