import React from 'react';
import { connect } from 'react-redux';

import '../css/TabletOrderHome.css';
import * as OrderActions from '../../../actions/orderActions';
import { scroller } from 'react-scroll';
import Minicart from '../Minicart';
import { getImageUrl, isDevEnvironment } from '../../../utils';
import { splitBrands } from '../logos';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { cloudfrontBaseUrl } from '../../../endpoints';

class KioskBrand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localMenuIdToImageMap: {},
            selectedMenuRef: null,

            show_left_chevron: false,
            show_right_chevron: false,
            show_top_chevron: false,
            show_bottom_chevron: true,

            brands: {},
            coming_soon: {},
        };

        this.ref = {};
        this.containerRef = null;
        this.categoryRef = null;
    }

    selectMenu(menu) {
        // this.props.menuCategorySelected({}, null, false);
        this.props.menuSelected(menu, this.props.history, false);
        this.scrollToMenu(menu);
        setTimeout(() => {
            this.props.menuCategorySelected(
                menu.categories[0] || {},
                null,
                false
            );
        }, 500);
    }

    selectItem(item) {
        this.props.menuItemSelected(item, this.props.history, false);
        this.props.history.push('/kiosk/item');
    }

    scrollToMenu(menu, immediate = false) {
        if (!menu) return;
        const center = window.innerWidth / 2 - 100;

        scroller.scrollTo(menu.menu_id, {
            duration: immediate ? 0 : 1250,
            delay: immediate ? 0 : 1000,
            smooth: 'easeInOutQuart',
            offset: -center,
            containerId: 'kiosk-menus',
            horizontal: true,
        });
    }

    componentDidMount() {
        const selected_menu = this.props.state.order.selected_menu;
        this.scrollToMenu(selected_menu, true);
    }

    renderMenus(menus) {
        if (!menus) {
            return;
        }

        const { active, inactive } = splitBrands(menus);

        const menuClicked = (menu) => {
            this.selectMenu(menu);
        };

        const renderedMenus = active.map((menu) => {
            const is_menu_selected =
                menu.menu_id === this.props.state.order.selected_menu.menu_id;
            const is_selected = is_menu_selected ? 'to-menu-selected' : '';
            return (
                <div
                    key={menu.menu_id}
                    id={menu.menu_id}
                    ref={(r) => {
                        this.ref[menu.menu_id] = r;
                    }}
                    className={`to-container-left-menu`}
                    onClick={() => menuClicked(menu)}
                >
                    <LazyLoadImage
                        src={getImageUrl('menu', menu.menu_id)}
                        className={`to-container-left-menu-img ${is_selected}`}
                    ></LazyLoadImage>
                </div>
            );
        });

        const renderedComingSoonMenus = inactive.map((menu, index) => {
            return (
                <div
                    className="to-container-left-menu"
                    key={`comingSoon-${index}`}
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: '25px',
                            top: '135px',

                            padding: '10px',
                            margin: '5px',
                            width: 'auto',
                            textAlign: 'center',
                            backgroundColor: '#A0C5A0',
                            color: '#fff',
                            fontSize: '16px',
                            fontWeight: '800',
                            borderRadius: '25px',
                        }}
                    >
                        Coming Soon!
                    </div>
                    <LazyLoadImage
                        effect="opacity"
                        src={getImageUrl('menu', menu.menu_id)}
                        className={`to-container-left-menu-img to-container-left-menu-img-coming-soon`}
                    />
                </div>
            );
        });

        return (
            <div
                id="kiosk-menus"
                className="kiosk-brand-select-mini"
                ref={(r) => (this.containerRef = r)}
                onScroll={(e) => {
                    // const scrollLeft = this.containerRef.scrollLeft;
                    // this.setState({
                    //   show_left_chevron: scrollLeft > 60,
                    //   show_right_chevron:
                    //     scrollLeft + this.containerRef.clientWidth <
                    //     this.containerRef.scrollWidth - 60,
                    // });
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        className="brand-left-chevron"
                        onTouchEnd={(e) => {
                            this.containerRef.scrollBy({
                                left: -628,
                                behavior: 'smooth',
                            });
                        }}
                        onMouseUp={() => {
                            this.containerRef.scrollBy({
                                left: -628,
                                behavior: 'smooth',
                            });
                        }}
                        style={{
                            opacity: this.state.show_left_chevron ? '1' : '0',
                        }}
                    >
                        <img
                            alt=""
                            src="https://mentumqr.s3.amazonaws.com/gk-stuff/chevron-left-gray.png"
                            className="brand-left-chevron-icon"
                        ></img>
                    </div>

                    <div style={{ paddingLeft: '50px' }}></div>
                    {renderedMenus}
                    {renderedComingSoonMenus}
                    <div style={{ paddingLeft: '50px' }}></div>

                    <div
                        className="brand-right-chevron"
                        style={{
                            opacity: this.state.show_right_chevron ? '1' : '0',
                        }}
                        onTouchEnd={(e) => {
                            this.containerRef.scrollBy({
                                left: 628,
                                behavior: 'smooth',
                            });
                        }}
                        onMouseUp={() => {
                            this.containerRef.scrollBy({
                                left: 628,
                                behavior: 'smooth',
                            });
                        }}
                    >
                        <img
                            alt=""
                            src="https://mentumqr.s3.amazonaws.com/gk-stuff/chevron-left-gray.png"
                            className="brand-left-chevron-icon"
                            style={{
                                transform: 'rotate(180deg)',
                            }}
                        ></img>
                    </div>
                </div>
            </div>
        );
    }

    _renderItems_helper(items) {
        items = items || [];
        const in_stock = items.filter((i) => i.item.is_in_stock);
        const not_in_stock = items.filter((i) => !i.item.is_in_stock);
        const sortedItems = [...in_stock, ...not_in_stock];

        return sortedItems.map((itemData) => {
            const item = itemData.item;
            const restaurant_id =
                this.props.state.main.restaurant_info.restaurant_id;
            //?v=${new Date().getTime()}
            const imgUrl = `${cloudfrontBaseUrl}${restaurant_id}/items/${item.item_id}.jpeg`;
            const price = item.price
                ? `$${parseFloat(item.price).toFixed(2)}`
                : '';

            const itemSelected = () => {
                setTimeout((e) => {
                    this.selectItem(item);
                }, 1);
            };

            const inStock = item.is_in_stock;

            if (inStock) {
                return (
                    <>
                        <div
                            className="to-container-menu-item"
                            onClick={() => itemSelected()}
                        >
                            <LazyLoadImage
                                className="to-container-menu-item-photo"
                                src={imgUrl}
                                effect="opacity"
                            ></LazyLoadImage>
                            <div className="to-container-menu-item-label">
                                <div>{item.name}</div>
                                <div className="to-container-menu-item-price">
                                    {price}
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <div className="to-container-menu-item item-container-sold-out">
                            <div
                                className="to-container-menu-item-photo kiosk-out-of-stock"
                                style={{
                                    backgroundImage: `url('${imgUrl}'), url('')`,
                                }}
                            ></div>
                            <div className="to-container-menu-item-label kiosk-out-of-stock">
                                <div>{item.name}</div>
                                <div className="to-container-menu-item-price">
                                    SOLD OUT
                                </div>
                            </div>
                        </div>
                    </>
                );
            }
        });
    }

    renderItems(selected_category) {
        const items = this._renderItems_helper(selected_category.items);
        const backButtonPressed = () => {
            setTimeout((e) => {
                this.props.history.push('/kiosk/brands');
            }, 1);
        };

        return (
            <>
                <div
                    className="to-container-back-button-row noselect"
                    onTouchEnd={() => backButtonPressed}
                    onMouseUp={() => backButtonPressed()}
                >
                    <div className="to-container-back-button">Back</div>
                </div>
                {items}
            </>
        );
    }

    _renderCategories_helper(categories) {
        const restaurant_id =
            this.props.state.main.restaurant_info.restaurant_id;
        const selected_category = this.props.state.order.selected_category;

        return (categories || []).map((categoryData, category_index) => {
            const category = categoryData.category;
            if (!category || !selected_category.category) {
                return null;
            }

            const imgUrl = `${cloudfrontBaseUrl}${restaurant_id}/categories/${category.category_id}.jpeg`;
            const is_selected =
                selected_category.category.category_id === category.category_id
                    ? 'kiosk-category-selected'
                    : '';

            const kioskCategorySelected = () => {
                setTimeout((e) => {
                    this.props.kioskCategorySelected(categoryData);
                }, 1);
            };

            return (
                <div
                    key={`category-${category_index}`}
                    className={`kiosk-category-sidebar ${is_selected}`}
                    onClick={() => kioskCategorySelected()}
                >
                    <div className="kiosk-category">
                        <LazyLoadImage
                            className="kiosk-category-sidebar-image"
                            src={imgUrl}
                            effect="opacity"
                        ></LazyLoadImage>
                        <div className="kiosk-category-sidebar-text">
                            {category.name}
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderCategories() {
        const selected_menu = this.props.state.order.selected_menu;
        const categories = selected_menu.categories;

        const catRender = this._renderCategories_helper(categories);

        return (
            <>
                <div
                    className="category-chevron"
                    style={{
                        opacity: this.state.show_top_chevron ? '1' : '0',
                    }}
                    onTouchEnd={(e) => {
                        this.categoryRef.scrollBy({
                            top: -1024,
                            behavior: 'smooth',
                        });
                    }}
                    onMouseUp={(e) => {
                        this.categoryRef.scrollBy({
                            top: -1024,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <img
                        alt=""
                        src="https://mentumqr.s3.amazonaws.com/gk-stuff/chevron-left-gray.png"
                        className="category-chevron-icon"
                    ></img>
                </div>

                <div className="category-container">{catRender}</div>

                <div
                    className="category-chevron category-chevron-down"
                    style={{
                        opacity: this.state.show_bottom_chevron ? '1' : '0',
                    }}
                    onTouchEnd={(e) => {
                        this.categoryRef.scrollBy({
                            top: 1024,
                            behavior: 'smooth',
                        });
                    }}
                    onMouseUp={() => {
                        this.categoryRef.scrollBy({
                            top: 1024,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <img
                        alt=""
                        src="https://mentumqr.s3.amazonaws.com/gk-stuff/chevron-left-gray.png"
                        className="category-chevron-icon category-chevron-icon-down"
                    ></img>
                </div>
            </>
        );
    }

    requestFullscreen() {
        if (!isDevEnvironment) {
            document.documentElement.webkitRequestFullScreen();
        }
    }

    render() {
        const menus = this.props.state.order.menu.menus;
        const selected_category = this.props.state.order.selected_category;

        return (
            <div>
                <div className="topHeaderBar">
                    <div className="brand-select-header-combined">
                        <div className="kiosk-header">
                            <img
                                alt=""
                                onClick={(e) =>
                                    this.props.history.push(
                                        '/kiosk/selectReader'
                                    )
                                }
                                className="kiosk-header-logo"
                                src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                            ></img>
                            <div className="kiosk-header-right"></div>
                        </div>
                        {this.renderMenus(menus)}
                    </div>

                    <div className="to-container">
                        <div
                            className={`to-container-left`}
                            ref={(r) => {
                                this.categoryRef = r;
                            }}
                            onScroll={(e) => {
                                const scrollTop = this.categoryRef.scrollTop;
                                this.setState({
                                    show_top_chevron: scrollTop > 80,
                                    show_bottom_chevron:
                                        scrollTop +
                                            this.categoryRef.clientHeight <
                                        this.categoryRef.scrollHeight - 80,
                                });
                            }}
                        >
                            {this.renderCategories()}
                        </div>

                        <div id="tablet-right" className={`to-container-right`}>
                            <div className="to-container-menu-container">
                                {this.renderItems(selected_category)}
                            </div>
                        </div>
                    </div>

                    <Minicart
                        history={this.props.history}
                        scrollToMenu={() => this.scrollToMenu()}
                    ></Minicart>
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    menuCategorySelected: OrderActions.menuCategorySelected,
    menuSelected: OrderActions.menuSelected,
    menuItemSelected: OrderActions.menuItemSelected,
    kioskCategorySelected: OrderActions.kioskCategorySelected,

    clearAllOrders: OrderActions.clearAllOrders,

    resetPaymentErrors: OrderActions.resetPaymentErrors,
})(KioskBrand);
