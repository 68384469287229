import axios from 'axios';
import React from 'react';
import { BsTypeStrikethrough } from 'react-icons/bs';
import { connect, useDispatch } from 'react-redux';

import * as TableActions from '../actions/tableActions';
import { APIv1Endpoint } from '../endpoints';
import * as Types from '../reducers/TYPES';

class GetStandingTable extends React.Component {
    componentDidMount() {
        const {
            match: { params },
        } = this.props;
        const restaurantName = params.restaurantName;

        if (!restaurantName) {
            return;
        }

        const affiliate = params.affiliate;

        const isFrontOfHouse = affiliate === '$frontOfHouse';
        const isKiosk = restaurantName.includes('.kiosk');

        if (!isFrontOfHouse && !isKiosk) {
            axios
                .get(`${APIv1Endpoint}restaurant/${restaurantName}`)
                .then((res) => {
                    if (res.data.restaurant) {
                        // Sassafraz V1 Rollback
                        if (res.data.restaurant.name.includes('sassafraz')) {
                            this.props.loadStandingTable(
                                restaurantName,
                                this.props.history,
                                affiliate || ''
                            );
                            return;
                        }
                        this.props.setRestaurant(res.data.restaurant);
                        this.props.history.push(`/v2/${restaurantName}/home`);
                        return;
                    }
                })
                .catch((err) => {
                    this.props.loadStandingTable(
                        restaurantName,
                        this.props.history,
                        affiliate || ''
                    );
                });
        } else {
            // front of house
            this.props.loadStandingTable(
                restaurantName,
                this.props.history,
                affiliate || ''
            );
        }
    }

    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'stretch',
                    justifyContent: 'center',
                }}
            >
                <div className="cssload-container">
                    <div className="cssload-whirlpool"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { state };
};

export default connect(mapStateToProps, {
    loadStandingTable: TableActions.loadStandingTable,
    setRestaurant: TableActions.setRestaurant,
})(GetStandingTable);
