import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import GuestList from './GuestList';
import AddGuest from './AddGuest';
import GuestHome from './GuestHome';
import { YourOrder } from './YourOrder';
import PaymentComplete from './Payment/PaymentComplete';
import FinalizeOrder from './Menu/FinalizeOrder';
import Complete from './Menu/Complete';
import CallWaiter from './CallWaiter';
import CallWaiterComplete from './CallWaiterComplete';
import Menu from './Menu';
import Pay from './Payment/Pay';
import { ThemeProvider } from '../themes/theming';
import GetStandingTable from './GetStandingTable';
import PayNuvei from './Payment/PayNuvei';

import * as TableActions from '../actions/tableActions';
import * as OrderActions from '../actions/orderActions';

import { ToastContainer } from 'react-toastify';
import LogoView from './Menu/LogoView';
import OneMenu from './Menu/OneMenu';
import Campaign from './Campaign/Campaign';
import OneCampaign from './Campaign/OneCampaign';
import DineSelect from './DineSelect';
import Concierge from './Hilton/Concierge';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TakeoutTwoFA from './Payment/TakeoutTwoFA';

import TakeoutTimePicker from './Order/TakeoutTimePicker';
import OgradysGallery from './Menu/custom/ogradygallery';
import KioskHome from './Tablet/KioskHome';
import Kiosk from './Tablet/Kiosk';
import VideoWall from './Other/VideoWall';
import HiltonCustomerForm from './Hilton/HotelCustomerForm';
import TVWall from './Other/TVWall';

import V2Root from './v2/V2Root';
import FourOFour from './Other/FourOFour';
import FrontOfHouseTerminalSelect from '../components/FrontOfHouseTerminalSelect';
import FrontOfHouseReader from './Payment/FrontOfHouseReader';
import PayNMI from './v2/Cart/PayNMI';
import ToS from '../components/ToS';

class RestaurantRoutes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkNuveiTerminalStatusInverval: null,
            isLegacyRestaurant: false,
        };
    }

    componentDidMount() {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            this.props.updateTheme();
        }
        this.state.isLegacyRestaurant =
            !window.location.pathname.includes('/v2/');
    }

    render() {
        const theme = this.props.state.main.theme;
        const isFrontofHouseV1 =
            this.props.state.order.affiliate_code === `$frontOfHouse` &&
            this.state.isLegacyRestaurant;

        if (isFrontofHouseV1 && !this.state.checkNuveiTerminalStatusInverval) {
            // handle terminals
            this.props.loadNuveiTerminals();
            let checkNuveiInterval = setInterval(() => {
                this.props.loadNuveiTerminals();
            }, 10000);
            this.setState({
                checkNuveiTerminalStatusInverval: checkNuveiInterval,
            });
        }

        return (
            <ThemeProvider theme={theme} style={{ padding: '10px' }}>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                />
                {isFrontofHouseV1 ? <FrontOfHouseTerminalSelect /> : null}

                <Switch>
                    <Route
                        exact
                        path="/v2/:endpoint/:affiliate"
                        component={V2Root}
                    ></Route>
                    <Route path="/v2/:endpoint" component={V2Root}></Route>

                    <Route path="/404" component={FourOFour}></Route>
                    <Route path="/privacy" component={PrivacyPolicy}></Route>
                    <Route path="/tos" component={ToS}></Route>

                    <Route path="/dineSelect" component={DineSelect}></Route>

                    <Route
                        path="/table/:tableCode"
                        component={GuestList}
                    ></Route>
                    <Route path="/addguest" component={AddGuest}></Route>
                    <Route path="/guesthome" component={GuestHome}></Route>
                    <Route path="/logoview" component={LogoView}></Route>

                    {/* Kiosk */}
                    <Route
                        exact
                        path="/kioskhome"
                        component={KioskHome}
                    ></Route>
                    <Route path="/kiosk" component={Kiosk}></Route>

                    <Route path="/orders" component={YourOrder}></Route>
                    <Route
                        path="/orderComplete"
                        component={PaymentComplete}
                    ></Route>
                    <Route path="/menu" component={Menu}></Route>
                    <Route path="/oneMenu" component={OneMenu}></Route>
                    <Route path="/campaigns" component={Campaign}></Route>
                    <Route path="/oneCampaign" component={OneCampaign}></Route>
                    <Route
                        exact
                        path="/callWaiter"
                        component={CallWaiter}
                    ></Route>
                    <Route
                        path="/callWaiter/complete"
                        component={CallWaiterComplete}
                    ></Route>

                    <Route
                        exact
                        path="/order/setPickupTime"
                        component={TakeoutTimePicker}
                    >
                        
                    </Route>

                    <Route
                        exact
                        path="/order"
                        component={FinalizeOrder}
                    ></Route>
                    <Route exact path="/order/pay" component={Pay}></Route>
                    <Route
                        exact
                        path="/order/payNuvei"
                        component={PayNuvei}
                    ></Route>
                    <Route
                        exact
                        path="/order/frontOfHouseReader"
                        component={FrontOfHouseReader}
                    ></Route>
                    <Route exact path="/order/payNMI/:id" component={PayNMI} />
                    <Route
                        exact
                        path="/order/2fa"
                        component={TakeoutTwoFA}
                    ></Route>

                    <Route path="/order/complete" component={Complete}></Route>

                    {/* Custom */}
                    <Route
                        exact
                        path="/hilton/concierge"
                        component={Concierge}
                    ></Route>
                    <Route
                        exact
                        path="/hotel/customerform"
                        component={HiltonCustomerForm}
                    ></Route>
                    <Route
                        exact
                        path="/ogradysgallery"
                        component={OgradysGallery}
                    ></Route>
                    <Route exact path="/wall" component={VideoWall}></Route>
                    <Route exact path="/tv" component={TVWall}></Route>

                    {/* Restaurant Name */}
                    <Route
                        exact
                        path="/:restaurantName"
                        component={GetStandingTable}
                    ></Route>
                    <Route
                        exact
                        path="/:restaurantName/:affiliate"
                        component={GetStandingTable}
                    ></Route>
                </Switch>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return { state };
};

export default connect(mapStateToProps, {
    updateTheme: TableActions.updateTheme,
    loadNuveiTerminals: OrderActions.loadNuveiTerminals,
})(RestaurantRoutes);
