import React from 'react';
import { Link } from 'react-router-dom';
import Spacing, { SpacingAmount } from '../components/Spacing';

const LOGO_URL = '/assets/logos/mentum_logo_2021.svg';

interface LinkProps {
    text: string;
    path: string;
}

const FooterLink = (props: LinkProps) => {
    return (
        <Link to={props.path} style={{ color: 'inherit' }}>
            <div className="v3FooterLink">{props.text}</div>
        </Link>
    );
};

export default () => {
    return (
        <>
            <Spacing amount={SpacingAmount.Spacing38} />
            <div className="v3Footer">
                <div className="v3FooterContainer v3FooterBorder">
                    <img src={LOGO_URL} className="v3HeaderLogo" />
                    <Spacing amount={SpacingAmount.Spacing38} />
                    {/*
                    <div className="v3FooterGroup">
                        <div className="v3FooterGroupHeader">PRICING</div>
                        <div className="v3FooterLink">Quotes</div>
                        <div className="v3FooterLink">Features</div>
                    </div>
                    <div className="v3FooterGroup">
                        <div className="v3FooterGroupHeader">COMPANY</div>
                        <div className="v3FooterLink">Our Story</div>
                        <div className="v3FooterLink">Problems We Solve</div>
                        <div className="v3FooterLink">Partnerships</div>
                        <div className="v3FooterLink">Careers</div>
                    </div>
                    
                */}
                    <div className="v3FooterRow">
                        <div className="v3FooterGroup">
                            <div className="v3FooterGroupHeader">PRODUCTS</div>
                            <FooterLink
                                text="Self-serve Kiosk"
                                path="/product/kiosk"
                            />
                            <FooterLink
                                text="QR-Code Ordering"
                                path="/product/qr"
                            />
                            <FooterLink
                                text="Mobile App"
                                path="/product/dinein"
                            />
                            <FooterLink
                                text="Delivery Integrations"
                                path="/product/delivery"
                            />
                        </div>
                        <div className="v3FooterGroup">
                            <div className="v3FooterGroupHeader">COMPANY</div>
                            <div className="v3FooterLink">
                                <a
                                    href="https://www.notion.so/mentumqr/Careers-at-Mentum-5a111016a09b4d97a2e146f9c9e3cefe"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    style={{ color: 'inherit' }}
                                >
                                    Careers
                                </a>
                            </div>
                            <div className="v3FooterLink">
                                <a
                                    href={
                                        process.env.PUBLIC_URL +
                                        '/documents/Terms_of_Service2021.pdf'
                                    }
                                    target="_blank"
                                    style={{ color: 'inherit' }}
                                >
                                    Terms of Service
                                </a>
                            </div>
                            <div className="v3FooterLink">
                                <a
                                    href={
                                        process.env.PUBLIC_URL +
                                        '/documents/Privacy_Policy2021.pdf'
                                    }
                                    target="_blank"
                                    style={{ color: 'inherit' }}
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>

                        <div className="v3FooterGroup">
                            <div className="v3FooterGroupHeader">CONTACT</div>
                            <div className="v3FooterLink">
                                <a href="mailto:sales@mentumqr.com">sales@mentumqr.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="v3Footer">
                <div className="v3FooterContainer">
                    <div className="v3CopyrightFooter">© Mentum</div>
                </div>
            </div>
            <Spacing amount={SpacingAmount.Spacing60} />
        </>
    );
};
