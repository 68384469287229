import React from 'react';
import { connect } from 'react-redux';

import '../css/BrandSelect.css';
import { splitBrands } from '../logos';
import Minicart from '../Minicart';
import * as OrderActions from '../../../actions/orderActions';
import { getImageUrl, isDevEnvironment } from '../../../utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class BrandSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAllergyNotice: false,
        };
    }

    componentDidMount() {
        if (!isDevEnvironment()) {
            document.documentElement.webkitRequestFullScreen();
        }
    }

    endOrder() {
        // clear countdown interval
        if (this.countdown) {
            clearInterval(this.countdown);
        }

        const endpoint = this.props.state.main.restaurant_info.endpoint;
        this.props.clearAllOrders();
        this.props.history.push(`/${endpoint}.kiosk`);
    }

    renderMenus(menus) {
        const { active, inactive } = splitBrands(menus);
        const brands = active.map((menu) => {
            const menuTapped = () => {
                this.props.history.push('/kiosk');
                this.props.menuSelected(menu, null, false, true);
                if (
                    !Array.isArray(menu.categories) &&
                    menu.categories.length === 0
                ) {
                    // menu was not properly loaded -- reset
                    this.endOrder();
                }
                return null;
            };

            return (
                <div onClick={() => menuTapped()}>
                    <LazyLoadImage
                        effect="opacity"
                        className="brand-select-logo"
                        src={getImageUrl('menu', menu.menu_id)}
                    ></LazyLoadImage>
                </div>
            );
        });

        const brands_soon = inactive.map((menu) => {
            return (
                <div>
                    {/* <div className="brand-coming-soon">Coming Soon!</div> */}
                    <LazyLoadImage
                        className="brand-select-logo"
                        style={{ opacity: '0.7', filter: 'saturate(0%)' }}
                        effect="opacity"
                        src={getImageUrl('menu', menu.menu_id)}
                    ></LazyLoadImage>
                </div>
            );
        });

        return (
            <div className="brand-select-container">
                {brands} {brands_soon}
            </div>
        );
    }

    requestFullscreen() {
        if (!isDevEnvironment) {
            document.documentElement.webkitRequestFullScreen();
        }
    }

    renderAllergyNotice() {
        return (
            <div
                className="allergy-notice-modal-container"
                onClick={() => this.setState({ showAllergyNotice: false })}
            >
                <div className="allergy-notice-modal">
                    <div className="allergy-notice-modal-header">
                        <div className="allergy-notice-modal-title">
                            Allergy Notice
                        </div>
                        <div className="allergy-notice-modal-close">Close</div>
                    </div>
                    <div className="allergy-notice-modal-body">
                        Attention customers with food allergies. Please be aware
                        that our food may contain or come into contact with
                        common allergens, such as dairy, eggs, wheat, soybeans,
                        tree nuts, peanuts, fish, shellfish or wheat. While we
                        take steps to minimize risk and safely handle the foods
                        that contain potential allergens, please be advised that
                        cross contamination may occur, as factors beyond our
                        reasonable control may alter the formulations of the
                        food we serve, or manufacturers may change their
                        formulations without our knowledge.
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const menus = this.props.state.order.menu.menus;
        return (
            <>
                {this.state.showAllergyNotice ? (
                    this.renderAllergyNotice()
                ) : (
                    <></>
                )}

                <div className="topHeaderBar">
                    <div
                        className="kiosk-header"
                        onTouchEnd={() => this.requestFullscreen()}
                        onMouseUp={() => this.requestFullscreen()}
                    >
                        <img
                            alt="Kiosk header"
                            onClick={(e) =>
                                this.props.history.push('/kiosk/selectReader')
                            }
                            className="kiosk-header-logo"
                            src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                        ></img>
                        <div className="kiosk-header-right"></div>
                    </div>

                    {/* <div className="brand-select-header">
                    SELECT A KITCHEN
                </div> */}

                    <div className="allergies-msg-container">
                        <div
                            className="allergies-msg"
                            onClick={() =>
                                this.setState({
                                    showAllergyNotice:
                                        !this.state.showAllergyNotice,
                                })
                            }
                        >
                            Allergy Notice
                        </div>
                    </div>

                    {this.renderMenus(menus)}
                </div>

                <Minicart history={this.props.history}></Minicart>
            </>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    menuSelected: OrderActions.menuSelected,
    kioskCategorySelected: OrderActions.kioskCategorySelected,
})(BrandSelect);
