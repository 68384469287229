import React from 'react';
import { Link } from 'react-router-dom';
import { SpacingAmount, Spacing, Header, Footer, Button } from '../components';
import './LandingV3.css';
import './Contact.css';

export default () => {
    return (
        <>
            <div className="v3ContactUsContainer">
                <Header />
                <Spacing amount={SpacingAmount.Spacing38} />
                <Spacing amount={SpacingAmount.Spacing38} />
                <div className="v3ContactHomeContainer">
                    <div className="v3ContactUsSide mobileHidden">
                        <div className="v3ContactUsTag">Contact Us</div>
                        <div className="v3ContactUsText">
                            Send us a message, and we'll reply back within 24
                            hours!
                        </div>
                        <Spacing amount={SpacingAmount.Spacing60} />
                        <img
                            className="v3ContactImage"
                            src="/assets/landing/v3/contact-img.png"
                        />
                    </div>
                    <div className="v3ContactUsForm">
                        <div className="v3CompleteContainer">
                            <img
                                className="v3CompleteIcon mobileHidden"
                                src="/assets/landing/v3/complete-icon.png"
                            />
                            <img
                                className="v3CompleteIcon desktopHidden"
                                src="/assets/landing/v3/complete-icon-white.svg"
                            />
                            <div className="v3CompleteText">
                                Thank you for your submission.
                            </div>
                            <Link to="/">
                                <Button text="Back to Home" />
                            </Link>
                        </div>
                    </div>
                </div>
                <Spacing amount={SpacingAmount.Spacing128} />
                <Spacing amount={SpacingAmount.Spacing128} />
            </div>
            <Spacing amount={SpacingAmount.Spacing128} />
            <div className="v3BrandsContainer">
                <div className="v3CenteredTagline">Trusted By</div>
                <div className="v3BrandsList">
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo1.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo2.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo3.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo4.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo5.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo6.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo7.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo8.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo9.png"
                    />
                    <img
                        className="v3BrandLogo"
                        src="/assets/landing/v3/partners/logo10.png"
                    />
                </div>
                <Spacing amount={SpacingAmount.Spacing128} />
            </div>
            <Footer />
        </>
    );
};
