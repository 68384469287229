import React from 'react';

import './css/Buttons.css';
import { withTheme } from '../themes/theming';

const Button = (props) => {
    const theme = props.theme;

    let {
        // width, // DEPRECATED: Width: 1, 2 or 3 //
        text, // button text
        action, // onPress action
        showArrow, // show arrow
        showBackArrow,
        arrow,
        price, // Show a price text
        plus, // plus icon
        disabled,
    } = props;

    showArrow = showArrow | arrow; // both "arrow" and "showArrow" prop does the same thing.

    // Generate button class name from properties
    let buttonClassName = 'button3 button';

    let rightArrow = (
        <img
            alt="Arrow"
            className="rightArrow"
            src="/assets/thin-right-arrow.png"
        ></img>
    );

    let backArrow = (
        <img
            alt="Back"
            className="rightArrow"
            src="/assets/thin-left-arrow.svg"
        ></img> // todo: make a gray plus button
    );

    let plusGlyph = (
        <img
            alt="Plus"
            className="rightArrow"
            src="/assets/thin-right-arrow.png"
        ></img> // todo: make a gray plus button
    );

    const formatted_price = price != null ? '$' + price : '';

    if (disabled) {
        return (
            <div className={`${buttonClassName} disabled`}>
                <div
                    className="buttonText"
                    style={{ ...theme.buttonFont, color: '#999' }}
                >
                    {text}
                </div>
                <div className="rightAlign">
                    <div className="priceText">{formatted_price}</div>
                    <div>{plus ? plusGlyph : null}</div>
                    <div>{showArrow ? rightArrow : null}</div>
                    <div>{showBackArrow ? backArrow : null}</div>
                </div>
            </div>
        );
    }

    return (
        <div onClick={action} className={buttonClassName}>
            <div className="buttonText" style={theme.buttonFont}>
                {text}
            </div>
            <div className="rightAlign">
                <div className="priceText">{formatted_price}</div>
                <div>{plus ? plusGlyph : null}</div>
                <div>{showArrow ? rightArrow : null}</div>
                <div>{showBackArrow ? backArrow : null}</div>
            </div>
        </div>
    );
};

export default withTheme(Button);
