import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PaymentType } from '../../../reducers/TYPES';
import { isTakeoutOrder } from '../../../utils';
import { createPath } from '../utils';
import './css/DineSelectHeader.css';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { LanguageSwitch } from '../../../components/LanguageSwitch';

export default (props) => {
    const current_guest = useSelector((state) => state.table.selected_guest);
    const restaurant = useSelector((state) => state.main.restaurant_info);
    const table = useSelector((state) => state.table.table);
    const history = useHistory();
    const isLoadingDineInOrder = useSelector(
        (state) => state.table.is_loading_orders
    );
    const isAddingGuestToTable = useSelector(
        (state) => state.table.is_adding_guest_to_table
    );
    const { t } = useTranslation();

    const isMenuOnly =
        useSelector((state) => state.main.restaurant_info.order_mode) ===
        'RESTAURANT_SETTING_NONE';

    if (isMenuOnly) {
        return (
            <div className="dineSelectHeader">
                <div className="dineSelectHeaderInnerContainer">
                    <div className="dineSelectLeft">
                        <div className="dineSelectType">
                            {t('common.menuBrowsingOption')}
                        </div>
                    </div>
                    <LanguageSwitch />
                </div>
            </div>
        );
    }

    if (isTakeoutOrder()) {
        return (
            <div className="dineSelectHeader">
                <div className="dineSelectHeaderInnerContainer">
                    <div className="dineSelectLeft">
                        <div className="dineSelectType">
                            {t('common.takeOutOption')}
                        </div>
                    </div>
                <LanguageSwitch />
                </div>
            </div>
        );
    } else {
        const renderLoadingGuest = (
            <div>
                <CircularProgress size={21} />
            </div>
        );

        return (
            <div className="dineSelectHeader">
                <div className="dineSelectHeaderInnerContainer">
                    <div className="dineSelectLeft">
                        {isLoadingDineInOrder || isAddingGuestToTable ? (
                            <>{renderLoadingGuest}</>
                        ) : (
                            <>
                                <div className="dineSelectUserName">
                                    {current_guest.name
                                        ? current_guest.name.toUpperCase()
                                        : ''}
                                </div>
                                <div className="dineSelectType">
                                    {t('common.dineInOption')}
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        className="dineSelectEditButton"
                        onClick={() =>
                            history.push(
                                createPath('/v2/:/table/:/guests', [
                                    restaurant.endpoint,
                                    table.local_id,
                                ])
                            )
                        }
                    >
                        {t('common.switchUserOption')}
                    </div>
                    <LanguageSwitch />
                </div>
            </div>
        );
    }
};
