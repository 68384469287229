import React from 'react';

import './css/TextBox.css';
import { withTheme } from '../themes/theming';

const TextBox = (props) => {
    let { value, onChange } = props;
    return (
        <input
            className="textBox"
            type="text"
            value={value}
            onChange={onChange}
            style={props.theme.addGuestTextBox}
        />
    );
};

export default withTheme(TextBox);
