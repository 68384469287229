import React from 'react';
import { connect } from 'react-redux';

// Actions
import * as OrderActions from '../../actions/orderActions';
import './css/main.css';
import Header from '../../components/Header';
import FinalizeOrderButton from './components/FinalizeOrderButton';
import { withTheme } from '../../themes/theming';
import { toast } from 'react-toastify';
import HealthAndSafetyForm from '../../components/HealthAndSafetyForm';
import { PaymentType } from '../../reducers/TYPES';
import { isTakeout, checkIntervalAvailability } from '../../utils';
import { i18n } from '../../locales';
import { withTranslation } from 'react-i18next';

class OneMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenuHours: false,
            menu_schedules: [],
        };
    }

    componentWillUnmount() {
        toast.dismiss('mealPeriodClosed');
    }

    componentDidMount() {
        const selected_menu = this.props.state.order.selected_menu;
        const schedule = this.getMenuSchedules();
        this.setState({
            menu_schedules: schedule,
        });

        if (schedule.length > 0) {
            // check current time is within schedule
            const current_time = this.props.state.order.order_time
                ? new Date(this.props.state.order.order_time)
                : new Date();
            let is_available = false;

            schedule.forEach((schedule) => {
                // todo: optimize
                (schedule.intervals || []).forEach((i) => {
                    // check day of week
                    if (i.day_of_week - 1 === current_time.getDay()) {
                        if (checkIntervalAvailability(i, current_time)) {
                            is_available = true;
                        }
                    }
                });
            });

            if (!is_available) {
                const message = isTakeout()
                    ? i18n.t('toast.closedMealPeriod')
                    : i18n.t('toast.menuNotServed', {
                          selectedMenuName: selected_menu.name,
                      });

                toast.info(message, {
                    toastId: 'mealPeriodClosed',
                });
            }
        }
    }

    menuCategorySelected(category) {
        this.props.menuCategorySelected(category, this.props.history);
    }

    getMenuSchedules() {
        if (this.state.menu_schedules.length > 0) {
            return this.state.menu_schedules;
        } else {
            const schedules = this.props.state.order.menu.schedule;
            const selected_menu = this.props.state.order.selected_menu;

            if (!schedules) {
                return [];
            }

            // TODO: OPTIMIZE
            const schedule = schedules.filter((s) => {
                if (s.menus) {
                    return (
                        s.menus.filter((m) => {
                            return m.menu_id === selected_menu.menu_id;
                        }).length > 0
                    );
                } else {
                    return false;
                }
            });

            return schedule;
        }
    }

    renderMenuHours() {
        const selected_menu = this.props.state.order.selected_menu;
        const schedules = this.getMenuSchedules();

        if (schedules.length > 0) {
            // combine schedules
            let combined_schedule = {};
            schedules.forEach((schedule) => {
                if (!schedule.intervals) {
                    return;
                }
                schedule.intervals.forEach((interval) => {
                    const day_of_week = interval.day_of_week;
                    if (!(day_of_week in combined_schedule)) {
                        combined_schedule[day_of_week] = [interval];
                    } else {
                        combined_schedule[day_of_week].push(interval);
                    }
                });
            });

            return (
                <div className="menuHoursContainer">
                    <div className="menuHours">
                        <div className="menuHoursTitle">
                            {selected_menu.name}{' '}
                            {this.props.t('menuHours.hours')}
                        </div>

                        <div>
                            {Object.keys(combined_schedule).map(
                                (day_of_week) => {
                                    const intervals =
                                        combined_schedule[day_of_week];
                                    return intervals.map(
                                        (i, interval_index) => {
                                            const start_time = new Date(
                                                i.start_time
                                            );
                                            const end_time = new Date(
                                                i.end_time
                                            );
                                            const start_time_str =
                                                start_time.toLocaleString(
                                                    i18n.language,
                                                    {
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true,
                                                        timeZone: 'UTC',
                                                    }
                                                );
                                            const end_time_str =
                                                end_time.toLocaleString(
                                                    i18n.language,
                                                    {
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true,
                                                        timeZone: 'UTC',
                                                    }
                                                );
                                            const day_of_week = i.day_of_week;
                                            const day_of_week_map = {
                                                1: this.props.t(
                                                    'menuHours.daysOfWeek.1'
                                                ),
                                                2: this.props.t(
                                                    'menuHours.daysOfWeek.2'
                                                ),
                                                3: this.props.t(
                                                    'menuHours.daysOfWeek.3'
                                                ),
                                                4: this.props.t(
                                                    'menuHours.daysOfWeek.4'
                                                ),
                                                5: this.props.t(
                                                    'menuHours.daysOfWeek.5'
                                                ),
                                                6: this.props.t(
                                                    'menuHours.daysOfWeek.6'
                                                ),
                                                7: this.props.t(
                                                    'menuHours.daysOfWeek.7'
                                                ),
                                            };
                                            const today_day_of_week =
                                                new Date().getDay() + 1;
                                            return (
                                                <div
                                                    className={`menuHourRow ${
                                                        today_day_of_week ===
                                                        i.day_of_week
                                                            ? 'menuHourRow-bold'
                                                            : ''
                                                    }`}
                                                >
                                                    <div className="menuHourDayOfWeek">
                                                        {interval_index === 0
                                                            ? day_of_week_map[
                                                                  day_of_week
                                                              ]
                                                            : ''}
                                                    </div>
                                                    <div className="menuHourInterval">
                                                        {start_time_str}{' '}
                                                        {this.props.t(
                                                            'common.to'
                                                        )}{' '}
                                                        {end_time_str}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    );
                                }
                            )}
                        </div>

                        <div
                            onClick={(e) => this.toggleMenuHours()}
                            className="categoryHeaderMenuHours"
                        >
                            {this.props.t('oneMenu.close')}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="menuHoursContainer">
                    <div className="menuHours">
                        <div className="menuHoursTitle">
                            Sorry, no menu hours found
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                onClick={(e) => this.toggleMenuHours()}
                                className="categoryHeaderMenuHours"
                            >
                                {this.props.t('oneMenu.close')}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    toggleMenuHours() {
        const toggle = !this.state.showMenuHours;
        this.setState({
            showMenuHours: toggle,
        });
    }

    renderCategories(categories) {
        if (!Array.isArray(categories)) {
            return;
        }

        const theme = this.props.theme;
        return categories.map((categoryData) => {
            const category = categoryData.category;
            if (!category) {
                return <></>;
            }
            return (
                <div
                    className="menuButton"
                    key={category.category_id}
                    onClick={(e) => this.menuCategorySelected(categoryData)}
                >
                    <div
                        className="menuButtonLabel category"
                        style={theme.restaurantCategoryLabel}
                    >
                        {category.name}
                    </div>
                    {category.description ? (
                        <div
                            className="menuButtonLabel categoryDescription"
                            style={theme.restaurantCategoryDescription}
                        >
                            {category.description}
                        </div>
                    ) : (
                        ''
                    )}
                    {/* <img alt="Arrow" src="/assets/modern-right-arrow.png" className="menuButtonRightArrow" /> */}
                </div>
            );
        });
    }

    render() {
        const selected_menu = this.props.state.order.selected_menu;
        const theme = this.props.theme;

        const showHoursButton = true; //this.props.state.main.restaurant_info.restaurant_id === '65fcf46b-c0bb-11ea-abee-02685a4295ea';
        const menuSchedules = this.state.menu_schedules;

        return (
            <div>
                {this.props.state.main.show_health_and_safety ? (
                    <HealthAndSafetyForm />
                ) : (
                    ''
                )}
                <Header
                    back="/logoview"
                    showGuestName
                    history={this.props.history}
                />

                {this.state.showMenuHours ? this.renderMenuHours() : ''}

                <div className="categoryHeader" style={theme.menuCategoryTitle}>
                    {selected_menu.name}
                    {selected_menu.description ? (
                        <div className="categoryHeaderDescriptionContainer">
                            {selected_menu.description}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="categoryHeaderMenuHoursContainer">
                        {showHoursButton && menuSchedules.length > 0 ? (
                            <div
                                onClick={(e) => this.toggleMenuHours()}
                                className="categoryHeaderMenuHours categoryHeaderMenuHours-light"
                            >
                                {this.props.t('oneMenu.showMenuHours')}
                            </div>
                        ) : (
                            ''
                        )}
                        {showHoursButton &&
                        menuSchedules.length > 0 &&
                        this.props.state.order.payment_type ===
                            PaymentType.PAY_IMMEDIATE ? (
                            <div
                                onClick={(e) => {
                                    this.props.history.push(
                                        '/order/setPickupTime'
                                    );
                                }}
                                className="categoryHeaderMenuHours"
                            >
                                {this.props.t('common.setPickupTime')}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="menuButtonContainer">
                    {this.renderCategories(selected_menu.categories)}
                </div>

                <FinalizeOrderButton history={this.props.history} />
                <div style={{ width: '100%', height: '100px' }}></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ state });

export default withTranslation()(
    withTheme(
        connect(mapStateToProps, {
            menuCategorySelected: OrderActions.menuCategorySelected,
        })(OneMenu)
    )
);
