import React from 'react';

const VerticalSpace = (props) => {
    var { size } = props;

    // Maps size prop to height in pixels.
    const size_map = { 1: 50, 2: 100, 3: 150 };
    const css = { height: size_map[size] };

    return <div style={css}></div>;
};

export { VerticalSpace };
