import React from 'react';
import { connect } from 'react-redux';
import stripeTerminal from '../../../StripeTerminal';
import { convertTempItemToItemInstance } from '../../../utils';
import Axios from 'axios';
import { APIv1Endpoint } from '../../../endpoints';
import * as OrderActions from '../../../actions/orderActions';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import * as TableActions from '../../../actions/tableActions';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './css/OrderComplete.css';

class OrderComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_number_error: '',
            number_submitted: false,
            show_keyboard: true,
        };
    }

    endOrder() {
        const endpoint = this.props.state.main.restaurant_info.endpoint;
        this.props.clearAllOrders();
        this.props.history.push(`/${endpoint}.kiosk`);
    }

    componentDidMount() {
        this.props.updatePhoneNumber('');
    }

    renderTextComplete() {
        return (
            <div onClick={(e) => this.endOrder()}>
                <div className="to-page-order-success">
                    We've sent you a receipt. See you again!
                </div>

                <div
                    className="to-page-order-success"
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        height: '300px',
                    }}
                >
                    Tap to start a new order.
                </div>
            </div>
        );
    }

    renderPhoneNumberInput() {
        if (this.state.number_submitted) {
            return this.renderTextComplete();
        }

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div className="to-page-order-success">
                    Thank you! We'll text you a receipt.
                </div>

                <div className="kiosk-pn-row">
                    <div>
                        <PhoneInput
                            placeholder="(000) 000-0000"
                            defaultCountry="CA"
                            value={
                                this.props.state.table.selected_guest
                                    .phone_number
                            }
                            style={{
                                width: '300px',
                                fontSize: '28px',
                                backgroundColor: '#F7F7F7',
                                borderBottom: '5px solid #DADADA',
                            }}
                            readOnly
                            inputClassName="tablet-phone-input"
                            onChange={(val) => {
                                this.setState({ phone_number_error: '' });
                                this.props.updatePhoneNumber(val);
                            }}
                        />
                    </div>

                    {this.state.show_keyboard ? (
                        <div className="kiosk-keyboard">
                            <Keyboard
                                onChange={(val) => {
                                    this.setState({ phone_number_error: '' });
                                    this.props.updatePhoneNumber(`+1${val}`);
                                }}
                                layout={{
                                    default: [
                                        '1 2 3',
                                        '4 5 6',
                                        '7 8 9',
                                        '{bksp} 0 {bksp}',
                                        '{bksp}',
                                    ],
                                }}
                                theme="hg-theme-default hg-layout-numeric numeric-theme"
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                <div style={{ fontSize: '26px', margin: '10px' }}>
                    {this.state.phone_number_error}
                </div>

                <div
                    className="to-order-save"
                    style={{ marginTop: '30px', width: '800px' }}
                >
                    <div
                        className="to-item-save-button to-order-save-button  to-item-cancel"
                        onClick={(e) => {
                            this.endOrder();
                        }}
                    >
                        Cancel
                    </div>
                    <div
                        className="to-item-save-button to-order-save-button"
                        onClick={(e) => {
                            if (
                                isValidPhoneNumber(
                                    this.props.state.table.selected_guest
                                        .phone_number
                                )
                            ) {
                                this.props.submitPhoneNumber();
                                this.setState({
                                    number_submitted: true,
                                });
                            } else {
                                this.setState({
                                    phone_number_error:
                                        'Sorry, invalid phone number. Please try again.',
                                });
                            }
                        }}
                    >
                        Done
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const table_id_arr = this.props.state.main.table_id;
        const table_id = table_id_arr.map((i) => i);

        return (
            <div
                className="to-page-container to-page-container-dark"
                onClick={(e) => {
                    if (this.state.number_submitted) {
                        this.endOrder();
                    }
                }}
            >
                <div className="to-page-inner-container to-page-inner-container-dark">
                    <img
                        alt="GK Logo"
                        src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                        className="to-page-review-logo"
                    ></img>

                    {/* <div className="to-page-review-heading">
                    <img src="https://mentumqr.s3.amazonaws.com/misc/correct+1.svg" style={{
                        width: '100px',
                        height: '100px'
                    }}/>
                </div> */}

                    <div
                        className="to-page-order-number"
                        style={{ marginTop: '50px' }}
                    >
                        <div className="to-page-table-number">
                            YOUR ORDER NUMBER
                        </div>
                        {table_id}
                    </div>

                    {this.renderPhoneNumberInput()}
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    clearAllOrders: OrderActions.clearAllOrders,
    updatePhoneNumber: TableActions.updatePhoneNumber,
    submitPhoneNumber: TableActions.submitPhoneNumber,
})(OrderComplete);
