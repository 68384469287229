import default_theme from './default';

const theme = {
    ...default_theme,

    // custom header
    customHeader: {},

    // GUEST HOME OPTIONAL LOGO
    optionalHomeHeaderUrl: '/assets/photos/utsav/19_large.jpg',
    optionalHomeHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    optionalHomeHeaderImg: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: '50% 50%',
    },

    customDineSelect: {
        height: '80px',
        paddingBottom: '0',
        marginBottom: '30px',
    },

    floatingButton: {
        backgroundColor: '#000',
    },

    finalizeAddItemsButton: {
        border: '2px solid #000',
        color: '#000',
    },

    // MUILTIPLIER
    itemMultiplierContainer: {
        borderColor: '#000',
    },
    itemMultiplierLeft: {
        borderRightColor: '#000',
        color: '#000',
    },
    itemMultiplierRight: {
        borderLeftColor: '#000',
        color: '#000',
    },
    itemMultiplierText: {
        color: '#000',
    },
};

export default theme;
