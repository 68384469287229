import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Control the browser to show from the top instead of last scroll position
 */
export default () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
};
