import React from 'react';
import { connect } from 'react-redux';
import { withTheme } from '../themes/theming';
import { withTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../endpoints';
class CallWaiterComplete extends React.Component {
    goHome() {
        this.props.history.push('/guesthome');
    }

    render() {
        const theme = this.props.theme;
        const restaurant_id =
            this.props.state.main.restaurant_info.restaurant_id;
        const logo = `${cloudfrontBaseUrl}${restaurant_id}/misc/logo.png`;

        return (
            <div>
                <div className="completeContainer">
                    <img
                        src={logo}
                        className="completeLogo"
                        alt="Restaurant Logo"
                    />

                    <div className="completeThankYouContainer">
                        <div className="completeThankYouText">
                            {this.props.t('waiter.requestSent')}
                        </div>
                        <div className="completeThankYouText">
                            {this.props.t('waiter.someoneWillBeWithYouShortly')}
                        </div>
                    </div>
                </div>

                <div
                    className="floatingButtonAlt floatingButtonAltLow"
                    onClick={(e) => this.goHome()}
                >
                    <div
                        className="floatingButtonLabelAlt"
                        style={theme.floatingButtonLabel}
                    >
                        {this.props.t('oneMenu.close')}
                    </div>
                </div>
            </div>
        );
    }
}

const MSTP = (state) => {
    return { state };
};

export default withTranslation()(withTheme(connect(MSTP)(CallWaiterComplete)));
