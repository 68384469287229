import React from 'react';
import { connect } from 'react-redux';
import { withTheme } from '../../themes/theming';

import * as OrderActions from './../../actions/orderActions';
import { isValidPhoneNumber } from 'react-phone-number-input';
import './css/LogoView.css';
import FinalizeOrderButton from './components/FinalizeOrderButton';
import Header from '../../components/Header';
import { toast } from 'react-toastify';
import HealthAndSafetyForm from '../../components/HealthAndSafetyForm';
import { PaymentType } from '../../reducers/TYPES';
import CampaignBlock from '../../components/CampaignBlock';
import { cloneDeep } from 'lodash';
import { splitBrands } from '../Tablet/logos';
import { BASE_URL, cloudfrontBaseUrl } from '../../endpoints';
import { i18n } from '../../locales';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

function getMenuWithScehdule(menus, inScheduleMenus) {
    const inScheduleMenuIds = inScheduleMenus.map((item) => item.menu_id);

    const menusWithSchedule = [...menus]
        .map((menu) => ({
            ...menu,
            in_schedule: inScheduleMenuIds.includes(menu.menu_id),
        }))
        .sort((a, b) => {
            if (a.in_schedule && !b.in_schedule) {
                return -1; // a comes before b
            } else if (!a.in_schedule && b.in_schedule) {
                return 1; // b comes before a
            } else {
                return 0; // no change in order
            }
        });
    return menusWithSchedule;
}

class LogoView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brands: {},
            coming_soon: {},
        };
    }

    componentDidMount() {
        this.props.loadInScheduleMenus();
        if (!this.props.state.main.is_active) {
            toast.dismiss('closedToast');
            toast.warning(i18n.t('toast.currentlyClosed'), {
                toastId: 'closedToast',
            });
        }
    }

    menuClicked(menu) {
        this.props.menuSelected(menu, this.props.history);
    }

    isRestaurantOpen() {
        let isOpen = false;

        // check if they're closed
        const date = new Date();
        let hours = date.getHours();
        const dayOfWeek = date.getDay();
        // if closed
        const businessHours = {
            weekday: [10, 22],
            weekend: [9, 22],
        };

        // hours = 23;

        if (dayOfWeek in [1, 2, 3, 4, 5]) {
            // weekday
            if (
                businessHours.weekday[0] <= hours &&
                hours < businessHours.weekday[1]
            ) {
                isOpen = true;
            }
        } else {
            // weekend
            if (
                businessHours.weekend[0] <= hours &&
                hours < businessHours.weekend[1]
            ) {
                isOpen = true;
            }
        }
        return isOpen;
    }

    renderFeaturedCampaign() {
        let campaigns = this.props.state.order.menu.campaigns.filter(
            ({ is_featured }) => is_featured
        );
        if ((campaigns || []).length > 0) {
            const randomIndex = Math.floor(Math.random() * campaigns.length);
            let selectedCampaign_copy = cloneDeep(campaigns[randomIndex]);
            selectedCampaign_copy.goBack = true;

            return (
                <div className="featuredCampaigns">
                    <CampaignBlock
                        campaign={campaigns[randomIndex]}
                        onClick={(e) =>
                            this.props.campaignSelected(
                                selectedCampaign_copy,
                                this.props.history
                            )
                        }
                    />
                </div>
            );
        }
    }

    renderLargeMenuBlocks() {
        let menus = this.props.state.order.menu.menus;
        const { restaurant_id } = this.props.state.main.restaurant_info;

        const menuIdToImageMap = {
            // sassafraz demo
            '79bbeb96-b57b-11ea-acd6-069f49d95199':
                'https://14t7vz2358e44amevl4f4yrm-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/Salmon-Dinner-0301.jpg', // lunch
            'c28f8dc8-b57c-11ea-acd6-069f49d95199':
                'https://images.unsplash.com/photo-1553682544-4ccf2778c9a8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80', // wine
            'f6fd0a4d-b57c-11ea-acd6-069f49d95199':
                'https://images.unsplash.com/photo-1577996947118-d820138a697e?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
            'a0fb2790-b57c-11ea-acd6-069f49d95199':
                'https://images.unsplash.com/photo-1556881261-e41e8db21055?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80', //cocktail
            '9233a9c2-b57c-11ea-acd6-069f49d95199':
                'https://14t7vz2358e44amevl4f4yrm-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/Striploin.jpg', //steak
            'b984c056-b57c-11ea-acd6-069f49d95199':
                'https://14t7vz2358e44amevl4f4yrm-wpengine.netdna-ssl.com/wp-content/uploads/2018/10/Olive-and-Feta-037-2000x1335.jpg', //wekend brunch
        };

        let menuBlocks = menus.map((menu) => {
            if (!menu) {
                return null;
            }

            if (!menu.is_active) {
                return null;
            }

            const is_takeout =
                this.props.state.order.payment_type ===
                PaymentType.PAY_IMMEDIATE;

            // takeout and restaurant is set to dine-in only
            if (is_takeout && menu.menu_status === 'DINE_IN_ONLY') {
                return null;
            }
            // dine in and restaurant is set to takeout-only
            if (!is_takeout && menu.menu_status === 'TAKE_OUT_ONLY') {
                return null;
            }

            // check if menu has items
            if (Array.isArray(menu.categories)) {
                if (menu.categories.length === 0) {
                    return null;
                }
            }

            // DEFAULT MENU BLOCK IMAGE (pasta lol)
            let imageUrl = '/assets/photos/DEFAULT_MENU.jpg';
            if (menu.menu_id in menuIdToImageMap) {
                imageUrl = menuIdToImageMap[menu.menu_id];
            }

            let s3ImageUrl = `${cloudfrontBaseUrl}${restaurant_id}/menus/${menu.menu_id}.jpeg`;

            return (
                <div
                    className="dataBlockItemContainer"
                    key={menu.menu_id}
                    onClick={(e) => this.menuClicked(menu)}
                >
                    <img
                        alt=""
                        className="dataBlockImage"
                        style={{
                            backgroundImage: `url(${s3ImageUrl}), url(${imageUrl})`,
                        }}
                    ></img>
                    <div className="dataBlockGradient"></div>
                    <div className="dataBlockMenuText">{menu.name}</div>
                </div>
            );
        });

        // sassafraz wine list
        if (
            this.props.state.main.restaurant_info.restaurant_id ===
            'c1216aa5-8fc7-11ea-b79c-0aea01d90f1c'
        ) {
            menuBlocks.push(
                <div
                    className="dataBlockItemContainer"
                    onClick={() => {
                        window.open(
                            `https://mentumqr.s3.amazonaws.com/misc/WINE_LIST_OCT_2021.pdf?v=${new Date().getTime()}`,
                            '_self'
                        );
                    }}
                >
                    <img
                        alt=""
                        className="dataBlockImage"
                        src="https://14t7vz2358e44amevl4f4yrm-wpengine.netdna-ssl.com/wp-content/uploads/2018/11/SON_3442-2000x800.jpg"
                    ></img>
                    <div className="dataBlockGradient"></div>
                    <div className="dataBlockMenuText">Wine List</div>
                </div>
            );
        }

        // ogrady's gallery
        if (
            this.props.state.main.restaurant_info.restaurant_id ===
            '163c3613-ada4-11ea-927c-069f49d95199'
        ) {
            menuBlocks.push(
                <div
                    className="dataBlockItemContainer"
                    onClick={() => {
                        this.props.history.push('/ogradysgallery');
                    }}
                >
                    <img
                        alt=""
                        className="dataBlockImage"
                        src={`${cloudfrontBaseUrl}}163c3613-ada4-11ea-927c-069f49d95199/misc/IMG_8423.JPG`}
                    ></img>
                    <div className="dataBlockGradient"></div>
                    <div className="dataBlockMenuText">Our Staff</div>
                </div>
            );
        }

        // hilton demo concierge
        if (
            this.props.state.main.restaurant_info.restaurant_id ===
            '54e3ed8a-baea-11ea-b85e-02685a4295ea'
        ) {
            menuBlocks.push(
                <div
                    className="dataBlockItemContainer"
                    onClick={() => {
                        this.props.history.push('/hilton/concierge');
                    }}
                >
                    <img
                        alt=""
                        className="dataBlockImage"
                        src="/assets/photos/concierge.jpg"
                    ></img>
                    <div className="dataBlockGradient"></div>
                    <div className="dataBlockMenuText">Concierge</div>
                </div>
            );
        }

        if ((this.props.state.order.menu.campaigns.length || []) > 0) {
            menuBlocks.splice(
                0,
                0,
                <div
                    className="dataBlockItemContainer"
                    key="campaign_menu"
                    onClick={(e) => {
                        this.props.history.push('/campaigns');
                    }}
                >
                    <img
                        alt=""
                        className="dataBlockImage"
                        src="/assets/photos/DEFAULT_MENU.jpg"
                        style={{ backgroundImage: ``, backgroundColor: 'pink' }}
                    ></img>
                    <div className="dataBlockGradient"></div>
                    <div className="dataBlockMenuText">Promotions</div>
                </div>
            );
        }

        return <div className="dataBlockMany">{menuBlocks}</div>;
    }

    isGK() {
        return (
            this.props.state.main.restaurant_info.name.search(
                'Ghost Kitchen'
            ) !== -1
        );
    }

    renderRoosterQuote() {
        return (
            <>
                {this.props.state.main.show_health_and_safety ? (
                    <HealthAndSafetyForm />
                ) : (
                    ''
                )}
                <div
                    style={{
                        fontFamily: 'Cormorant Garamond',
                        fontSize: '26px',
                        fontWeight: '600',
                        padding: '30px',
                        color: '#555',
                    }}
                >
                    <img
                        alt=""
                        src={`${cloudfrontBaseUrl}}56ff23fd-a475-11ea-b0d7-7c67a2b110fc/misc/landing-page.jpeg?ver=1595535504215`}
                        style={{ width: '100%', marginBottom: '20px' }}
                    ></img>
                    "Close your eyes, fall in love, stay there."<br></br>- Rumi{' '}
                    <br></br>
                    <br></br>
                    {this.props.t('logoView.thankYou')}
                    <br></br>
                    <br></br>- Roosters
                    {this.props.state.main.restaurant_info.restaurant_id ===
                    '59d04477-c859-11ea-a655-02685a4295ea' ? (
                        <img
                            alt=""
                            src="https://mentumqr.s3.amazonaws.com/misc/unnamed.png"
                            style={{ width: '100%', marginTop: '20px' }}
                        ></img>
                    ) : (
                        ''
                    )}
                    {this.props.state.main.restaurant_info.restaurant_id ===
                    '63742e22-cabd-11ea-ab4f-02685a4295ea' ? (
                        <img
                            alt=""
                            src="https://mentumqr.s3.amazonaws.com/misc/unnamed.jpg"
                            style={{ width: '100%', marginTop: '20px' }}
                        ></img>
                    ) : (
                        ''
                    )}
                </div>

                <div
                    style={{
                        textAlign: 'center',
                        margin: '30px',
                    }}
                >
                    <a href={`${BASE_URL}/`}>
                        {this.props.t('logoView.poweredBy')}&nbsp;MENTUM ~{' '}
                        {this.props.t('logoView.staySafe')}
                    </a>
                </div>
            </>
        );
    }

    renderCovidFormThankYou() {
        const restaurant_id =
            this.props.state.main.restaurant_info.restaurant_id;
        const logoViewHeaderUrl = `${cloudfrontBaseUrl}${restaurant_id}/misc/landing-page.jpeg`;
        return (
            <>
                <div>
                    {this.props.state.main.show_health_and_safety ? (
                        <HealthAndSafetyForm />
                    ) : (
                        <></>
                    )}
                    <img
                        alt=""
                        src={logoViewHeaderUrl}
                        style={{
                            marginLeft: '10vw',
                            width: '80vw',
                        }}
                    ></img>
                </div>

                <div
                    style={{
                        textAlign: 'center',
                        fontFamily: 'Lato',
                        fontSize: '23px',
                        padding: '20px',
                    }}
                >
                    {this.props.t('logoView.infoReceived')}
                </div>
            </>
        );
    }

    render() {
        const inScheduleMenus = this.props.state.order.menu.inScheduleMenus;

        const restaurant_id =
            this.props.state.main.restaurant_info.restaurant_id;
        const restaurant = this.props.state.main.restaurant_info;

        // check if rooster, temp disable store
        const rooster_locations = [
            '56ff23fd-a475-11ea-b0d7-7c67a2b110fc',
            '59d04477-c859-11ea-a655-02685a4295ea',
            '63742e22-cabd-11ea-ab4f-02685a4295ea',
        ];
        if (rooster_locations && rooster_locations.includes(restaurant_id)) {
            return this.renderRoosterQuote();
        }
        const covid_form_only_restuarant_ids = [
            '03af3e8c-dea6-11ea-b14e-02685a4295ea',
        ];
        if (covid_form_only_restuarant_ids.includes(restaurant_id)) {
            return this.renderCovidFormThankYou();
        }

        const is_tablet = this.props.state.table.is_tablet;
        const headerBackLink = is_tablet ? '' : '/guesthome';
        const menus = this.props.state.order.menu.menus;

        // GK
        const logoViewHeaderUrl = `${cloudfrontBaseUrl}${restaurant_id}/misc/landing-page.jpeg`;
        let strippedPhoneNumber = restaurant.phone_number
            ?.replace(/-/g, '')
            ?.replace(/ /g, '');

        const mainMenus = splitBrands(menus);
        return (
            <div
                className="logoViewDesktopContainer"
                style={{ paddingBottom: '100px' }}
            >
                <Header
                    back={headerBackLink}
                    history={this.props.history}
                    showGuestName
                />

                {!this.isGK() ? (
                    <div>
                        <div
                            className="logoViewHeaderImage"
                            style={{
                                backgroundImage: `url(${logoViewHeaderUrl})`,
                            }}
                        ></div>
                        <div className="logoViewHeaderText">
                            <div className="textGlow noselect"></div>
                        </div>
                        <img
                            alt=""
                            src={logoViewHeaderUrl}
                            className="logoViewHeaderExpansion"
                        ></img>
                    </div>
                ) : (
                    ''
                )}

                {this.props.state.main.show_health_and_safety && !is_tablet ? (
                    <HealthAndSafetyForm />
                ) : (
                    <></>
                )}

                {this.isGK() ? (
                    <div>
                        <div className="gk-restaurant-location-container">
                            <div className="gk-restaurant-header">
                                {this.props.t('common.welcomeTo')}
                            </div>
                            <div className="gk-restaurant-name">
                                {restaurant.name}
                            </div>
                            <div className="gk-restaurant-info">
                                {restaurant.address !== '0' ? (
                                    <div
                                        className="gk-restaurant-info-item"
                                        onClick={(e) => {
                                            window.open(
                                                `http://maps.apple.com/?q=${restaurant.address}`,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        {restaurant.address}
                                    </div>
                                ) : (
                                    ''
                                )}

                                {isValidPhoneNumber(strippedPhoneNumber) ? (
                                    <div
                                        className="gk-restaurant-info-item"
                                        onClick={(e) => {
                                            window.open(
                                                `tel:${restaurant.phone_number}`,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        {restaurant.phone_number}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        {this.renderFeaturedCampaign()}

                        {(this.props.state.order.menu.campaigns.length || []) >
                        0 ? (
                            <>
                                <div className="featuredCampaigns">
                                    <div
                                        className="campaignBlockContainer"
                                        onClick={(e) => {
                                            this.props.history.push(
                                                '/campaigns'
                                            );
                                        }}
                                    >
                                        <div className="campaignBlockText">
                                            <p className="campaignBlockSeeAll">
                                                {this.props.t(
                                                    'logoView.seePromotions'
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}

                        <div
                            style={{
                                padding: '20px',
                                fontSize: '24px',
                                fontWeight: '300',
                            }}
                        >
                            {this.props.t('logoView.chooseBrand')}
                        </div>
                        <div className="gkLogoContainer">
                            {getMenuWithScehdule(
                                mainMenus.active,
                                inScheduleMenus
                            ).map((menu) => {
                                const s3ImageUrl = `${cloudfrontBaseUrl}${restaurant_id}/menus/${menu.menu_id}.jpeg`;
                                return (
                                    <div
                                        key={menu.menu_id}
                                        className={`ghostKitchenLogoButton ${
                                            !menu.in_schedule
                                                ? 'gkImageComingSoon'
                                                : ''
                                        }`}
                                        onClick={(e) => {
                                            if (menu.in_schedule) {
                                                this.menuClicked(menu);
                                            }
                                        }}
                                    >
                                        <img
                                            alt=""
                                            src={s3ImageUrl}
                                            className="gkImage"
                                        ></img>
                                    </div>
                                );
                            })}
                            {/* Coming soon */}

                            <div className="ghostKitchenLogoButtonComingSoonText">
                                {this.props.t('logoView.comingSoon')}
                            </div>

                            {mainMenus.inactive.map((menu) => {
                                const s3ImageUrl = `${cloudfrontBaseUrl}${restaurant_id}/menus/${menu.menu_id}.jpeg`;
                                return (
                                    <div
                                        key={menu.menu_id}
                                        className="ghostKitchenLogoButton"
                                    >
                                        <img
                                            alt=""
                                            src={s3ImageUrl}
                                            className="gkImage gkImageComingSoon"
                                        ></img>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div>
                        {this.renderFeaturedCampaign()}
                        <div className="dataBlock">
                            {this.renderLargeMenuBlocks()}
                        </div>
                    </div>
                )}
                <FinalizeOrderButton
                    history={this.props.history}
                ></FinalizeOrderButton>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ state });
const ConnectedLogoView = withTheme(
    connect(mapStateToProps, {
        menuSelected: OrderActions.menuSelected,
        campaignSelected: OrderActions.campaignSelected,
        resetPaymentErrors: OrderActions.resetPaymentErrors,
        loadNuveiTerminals: OrderActions.loadNuveiTerminals,
        loadInScheduleMenus: OrderActions.loadInScheduleMenus,
    })(LogoView)
);

const LogoViewWithRouter = withRouter(ConnectedLogoView);

export default withTranslation()(LogoViewWithRouter);
