import React, { useState } from 'react';

export interface dropdownProps {
    label: string;
    children: JSX.Element;
    classname: string;
    open?: boolean;

    showChevron?: boolean;
    setShowParent?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default (props: dropdownProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const { innerWidth } = window;
    const isDesktop = innerWidth > 850;

    const onMouseEnter = () => {
        if (isDesktop) {
            setShowDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (isDesktop) {
            setShowDropdown(false);
            if (showDropdown && props.setShowParent) {
                props.setShowParent(false);
            }
        }
    };

    const onLabelClick = () => {
        setShowDropdown(!showDropdown);
    };

    const onBodyClick = () => {
        // in the case where dropdown is visible, close the parent as well.
        if (showDropdown && props.setShowParent) {
            props.setShowParent(false);
            if (isDesktop) {
                setShowDropdown(false);
            }
        }
    };

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className={props.classname} onClick={onLabelClick}>
                {props.label}
                <div className="arrow" />
            </div>
            <div onClick={onBodyClick}>
                {
                    <div
                        className={
                            showDropdown
                                ? 'v3HeaderDropdownShow'
                                : ' v3HeaderDropdownHide'
                        }
                    >
                        {props.children}
                    </div>
                }
            </div>
        </div>
    );
};
