import React from 'react';
import './VideoWall.css';
import Axios from 'axios';
import ReactPlayer from 'react-player';
import { isDevEnvironment } from '../../utils';
import localforage from 'localforage';

const PlayerState = {
    Playing: 0,
    Ended: 1,
};

const TVWallNotification = (text, important = false) => {
    return {
        date: new Date(),
        text,
        important,
    };
};

class TVWall extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            videos: [],
            locations: {},
            selectedLocationId: '',
            show_location_selector: false,

            video_progress: {},
            video_index: 0,
            player_state: PlayerState.Ended,
            show_curtains: false,
            download_progress: {},
            current_video: null,

            notifications: [],
            show_notifications: false,

            online: false,
        };
    }

    objectHasSameContents(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    pushNotification(text, important = false) {
        const notif = TVWallNotification(text, important);
        this.setState({
            ...this.state,
            notifications: [notif, ...this.state.notifications],
        });
    }

    playVideo() {
        if (this.state.player_state === PlayerState.Playing) {
            return;
        }
        if (this.state.videos.length === 0) {
            console.error('Play video called when no video was available.');
            return;
        }
        if (this.state.video_index > this.state.videos.length - 1) {
            // reset
            this.setState({
                video_index: 0,
            });
            this.playVideo();
            return;
        }

        const video_url = this.state.videos[this.state.video_index];
        localforage.getItem(video_url).then((blob) => {
            if (!blob || !(blob instanceof Blob)) {
                this.setState({
                    video_index: 0,
                });
                this.playVideo();
                return;
            }
            this.setState({
                current_video: window.URL.createObjectURL(blob),
                player_state: PlayerState.Playing,
            });
        });
    }

    downloadVideos() {
        const videos = this.state.videos;
        const dl_progress = {};

        if (Array.isArray(videos)) {
            // get localforage keys
            localforage.keys().then((keys) => {
                videos.forEach((video_url, video_index) => {
                    if (keys.includes(video_url)) {
                        // video has already been downloaded

                        if (video_index === videos.length - 1) {
                            this.setState({
                                videos: videos,
                            });
                            this.playVideo();
                        }
                    } else {
                        /// else, download the video as a blob
                        Axios({
                            url: video_url,
                            onDownloadProgress: (progress) => {
                                dl_progress[video_url] =
                                    progress.loaded / progress.total;
                                this.setState({
                                    download_progress: dl_progress,
                                });
                            },
                            responseType: 'blob',
                        }).then((res) => {
                            // add video to local forage
                            const blob = res.data;
                            localforage.setItem(video_url, blob);

                            if (video_index === videos.length - 1) {
                                this.setState({
                                    videos: videos,
                                });
                                this.playVideo();
                            }
                        });
                    }
                });
            });
        }

        this.playVideo();
    }

    playOffline() {
        localforage.getItem('videos').then((videos) => {
            if (!videos) {
                return;
            }

            // find all downloaded videos
            localforage.keys().then((keys) => {
                let offline_videos = [];

                keys.forEach((video_url) => {
                    if (videos.includes(video_url)) {
                        offline_videos.push(video_url);
                    }
                });

                if (offline_videos.length > 0) {
                    this.setState({
                        videos: offline_videos,
                        video_index: 0,
                    });
                    this.playVideo();
                }
            });
        });
    }

    loadVideoList() {
        Axios.get(
            `https://mentumqr.s3.amazonaws.com/video-wall/wall-videos.json?timestamp=${new Date().getTime()}`
        )
            .then((res) => {
                let locations = {};
                if (res.data.locations) {
                    locations = res.data.locations;
                    this.setState({
                        locations,
                    });
                }

                if (res.data.videos) {
                    if (!this.state.online) {
                        this.pushNotification('Back online');
                    }

                    this.setState({
                        online: true,
                    });

                    let videos = res.data.videos;

                    // find location if selected
                    const selectedLocationId = this.state.selectedLocationId;
                    if (
                        Object.keys(locations).length > 0 &&
                        selectedLocationId &&
                        selectedLocationId !== ''
                    ) {
                        // find location videos
                        let selected_location =
                            locations[this.state.selectedLocationId];
                        if (selected_location) {
                            videos = selected_location.videoUrls;
                        }
                    }

                    // check if data is the same
                    const greater =
                        videos.length >= this.state.videos.length
                            ? videos
                            : this.state.videos;
                    const less =
                        videos.length < this.state.videos.length
                            ? videos
                            : this.state.videos;
                    const diff = greater.filter((x) => !less.includes(x));
                    if (diff.length === 0) {
                        return;
                    }

                    this.pushNotification(
                        `New video list received. Diff: ${JSON.stringify(diff)}`
                    );

                    // set localforage video list (offline fallback)
                    localforage.setItem('videos', videos);

                    this.setState({
                        videos,
                        video_index: 0,
                        player_state: PlayerState.Ended,
                    });

                    this.downloadVideos();
                } else {
                    if (this.state.player_state === PlayerState.Ended) {
                        this.playOffline();
                        this.pushNotification(
                            'Loaded /api/videowall but had an unexpected result.',
                            true
                        );
                        this.setState({
                            online: false,
                        });
                    }
                }
            })
            .catch((err) => {
                // axios error (network or other error)::: fallback to offline mode
                console.error(err);
                this.pushNotification(JSON.stringify(err), true);
                this.setState({
                    online: false,
                });
                if (this.state.player_state === PlayerState.Ended) {
                    this.playOffline();
                }
            });
    }

    componentDidMount() {
        localforage.setDriver(localforage.INDEXEDDB);
        // localforage.clear();

        // set selectedLocationId
        localforage.getItem('locationId').then((id) => {
            if (id) {
                this.setState({
                    selectedLocationId: id,
                });
            }
        });

        // start playback
        this.playOffline();

        if (!isDevEnvironment()) {
            document.documentElement.webkitRequestFullScreen();
        }

        this.loadVideoList();
        // load every 1 minute
        const refreshInterval = setInterval((e) => {
            this.loadVideoList();
        }, 1000 * 60 * 10);

        this.refreshInterval = refreshInterval;
    }

    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }

    toggleNotifications() {
        this.setState({
            show_notifications: !this.state.show_notifications,
        });
    }

    selectLocation(locationId) {
        this.setState({
            selectedLocationId: locationId,
            show_location_selector: false,
        });
        this.loadVideoList();
        localforage.setItem('locationId', locationId);
    }

    render() {
        const renderDownloadProgress = () => {
            let total_progress = 0; // out of 100
            const num_videos = Object.keys(this.state.download_progress).length;
            let num_downloading = 0;

            const individualDownloadProgress = Object.keys(
                this.state.download_progress
            ).map((key) => {
                const progress = Math.round(
                    this.state.download_progress[key] * 100
                );
                if (progress === 100) {
                    total_progress += 100 / num_videos;
                    return <></>;
                }
                num_downloading += 1;
                total_progress += progress / num_videos;
                /*eslint no-useless-escape: "off"*/
                return (
                    <div className="downloadProgress-item" key={key}>
                        {progress}% | {key.replace(/^.*[\\\/]/, '')}
                    </div>
                );
            });

            const notifications = this.state.notifications;
            // only show important notifications (notif with flag important)
            const important_notifs = notifications.filter((n) => n.important);
            const hasNotifications =
                Array.isArray(important_notifs) &&
                important_notifs.length !== 0;
            if (
                (total_progress === 100 ||
                    num_downloading === 0 ||
                    num_videos === 0) &&
                !hasNotifications
            ) {
                return;
            }

            if (!this.state.show_notifications) {
                const is_online = this.state.online;
                let sm_notification =
                    num_downloading > 0
                        ? `${Math.round(total_progress)}%`
                        : important_notifs.length;
                if (!is_online) {
                    sm_notification = 'Offline';
                }
                return (
                    <div
                        className="tv-wall-notifications"
                        onClick={() => this.toggleNotifications()}
                    >
                        {sm_notification}
                    </div>
                );
            }

            const renderNotifications = notifications.map(
                (notif, notif_index) => {
                    return (
                        <div
                            className="downloadProgress-item"
                            key={`notif_${notif_index}`}
                        >
                            {notif.date.toLocaleString('en-US', {
                                dateStyle: 'medium',
                                timeStyle: 'medium',
                            })}{' '}
                            {notif.text}
                        </div>
                    );
                }
            );

            const totalProgress = (
                <div className="progressBar">
                    <div
                        className="innerProgressBar"
                        style={{
                            width: `${total_progress}%`,
                        }}
                    ></div>
                </div>
            );

            return (
                <div
                    className="downloadProgress"
                    onClick={() => this.toggleNotifications()}
                >
                    {totalProgress}
                    {renderNotifications}
                    {individualDownloadProgress}
                </div>
            );
        };

        const renderLocationSelector = () => {
            if (Object.keys(this.state.locations).length > 0) {
                if (this.state.show_location_selector) {
                    return (
                        <div className="locationSelectorContainer">
                            <h1>Select a location</h1>
                            {Object.keys(this.state.locations).map((key) => {
                                const location = this.state.locations[key];
                                return (
                                    <div className="location">
                                        {location.name} ({location.locationId})
                                        <span
                                            className="selectLocationButton"
                                            onClick={() =>
                                                this.selectLocation(
                                                    location.locationId
                                                )
                                            }
                                        >
                                            Select
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                } else {
                    return (
                        <div
                            onClick={() => {
                                this.setState({ show_location_selector: true });
                            }}
                            className="locationSelectorButton"
                        ></div>
                    );
                }
            }
        };

        if (this.state.videos.length === 0) {
            return (
                <div className="videoContainerEmpty">
                    {renderDownloadProgress()}
                    {renderLocationSelector()}
                </div>
            );
        }
        if (this.state.player_state === PlayerState.Ended) {
            return (
                <div className="videoContainerEmpty">
                    {renderDownloadProgress()}
                    {renderLocationSelector()}
                </div>
            );
        }

        const { innerWidth, innerHeight } = window;
        const current_video = this.state.current_video;

        return (
            <div className="videoContainer">
                {renderDownloadProgress()}

                {renderLocationSelector()}

                <div
                    className={
                        this.state.show_curtains ? 'curtains' : 'hide-curtains'
                    }
                    onClick={() => {
                        document.documentElement.webkitRequestFullScreen();
                        this.setState({
                            player_state: PlayerState.Ended,
                        });
                        setTimeout(() => {
                            this.setState({
                                player_state: PlayerState.Playing,
                            });
                        }, 100);
                    }}
                ></div>

                <ReactPlayer
                    muted
                    playing
                    url={current_video}
                    controls={false}
                    progressInterval={100}
                    width={innerWidth}
                    height={innerHeight}
                    onEnded={() => {
                        this.setState({
                            player_state: PlayerState.Ended,
                        });
                        let next_video = this.state.video_index + 1;
                        if (next_video === this.state.videos.length) {
                            next_video = 0;
                        }
                        this.setState({
                            video_index: next_video,
                        });
                        this.playVideo();
                    }}
                    ref={(ref) => (this.player = ref)}
                    onStart={() => {
                        this.setState({
                            show_curtains: false,
                        });
                    }}
                    onProgress={(p) => {
                        const timeUntilVideoIsOver =
                            this.player.getDuration() - p.playedSeconds;
                        if (timeUntilVideoIsOver <= 0.5) {
                            // fade out
                            this.setState({
                                show_curtains: true,
                            });
                        }
                    }}
                />
            </div>
        );
    }
}

export default TVWall;
