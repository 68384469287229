import React from 'react';
import { Route } from 'react-router-dom';
import Item from './Item';
import MenusPage from './MenusPage';
import Featured from './Featured';
import { IFrameMessage } from '../../../IFrameMessage';

export default () => {
    return (
        <>
            <IFrameMessage />
            <Route path="item" component={Item}></Route>
            <Route
                exact
                path="/v2/:endpoint/order"
                component={MenusPage}
            ></Route>
            <Route
                exact
                path="/v2/:endpoint/order/featured"
                component={Featured}
            ></Route>
        </>
    );
};
