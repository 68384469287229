import { GAEvent } from '../googleAnalytics';
import * as TYPES from '../reducers/TYPES';

/* Validates the table code
Params:
    code (str): The table code
Returns:
    (Dictionary)
        success (bool): Whether or not table code is valid,
        error (str): Error code if success is false
*/
const validateTableCode = (code) => {
    if (code.length !== 4) {
        return { success: false, error: 'Invalid length' };
    }
    return { success: true };
};

export const resetReduxState = () => (dispatch) => {
    dispatch({ type: TYPES.RESET_STATE });
    dispatch({
        type: TYPES.CLEAR_TEMPORARY_ORDERS,
    });
    dispatch({ type: TYPES.CLEAR_CAMPAIGN_INSTANCES });
};

/* Checks table code (from state)
Params:
    history (obj): react-router-dom history object (this.props.history)
*/
export const checkTableCode = (history) => (dispatch, getState) => {
    // Get table code from state
    const table_id = tableCodeArrayToString(getState().main.table_id);

    // Validate table_id
    const validation = validateTableCode(table_id);

    // If table code is invalid, we dispatch an error message
    if (!validation.success) {
        dispatch({
            type: TYPES.table_id_ERROR_OCCURED,
            payload: { error: validation.error },
        });

        // Return to homepage
        history.push('/');
        return;
    } else {
        // Dispatch successful validation
        dispatch({
            type: TYPES.table_id_SUCCEEDED,
        });
    }
};

/* Grabs code from URL and sets the app state
Params:
    code (str): QPay code
*/
export const directLinkLoaded = (code) => (dispatch) => {
    GAEvent('User', 'Table code loaded', code);

    // TODO: Handle invalid table codes
    code = code.split('');

    // Uppercase each letter
    code.forEach((val, index) => {
        code[index] = val.toUpperCase();
    });

    dispatch({
        type: TYPES.table_id_UPDATED,
        payload: {
            table_id: code,
        },
    });
};

/* Handles entering table code in Home.js
Params:
    code (str): A single digit code
    index (int): Index of the code
*/
export const enterTableCode = (code, index) => (dispatch, getState) => {
    // Copy table code array
    var new_table_id = [...getState().main.table_id];

    // Set new table code at the specified index
    new_table_id[index] = code;

    dispatch({
        type: TYPES.table_id_UPDATED,
        payload: {
            table_id: new_table_id,
        },
    });
};

/* Converts table array to a string */
function tableCodeArrayToString(code_array) {
    // itearte through each item in the array and append it to a string
    let table_id = '';
    for (let i = 0; i < code_array.length; i++) {
        table_id += code_array[i];
    }

    return table_id;
}

/* QPay code submit button handler
Params:
    history (obj): react-router-dom history object (this.props.history)
*/
export const tableCodeSubmit = (history) => (dispatch, getState) => {
    // Get table code from state
    const tableCodeArr = getState().main.table_id;
    const tableCode = tableCodeArrayToString(tableCodeArr);

    history.push('/table/' + tableCode);
};
