import * as Types from './TYPES';

const INITIAL_STATE = {
    terminal_vendor: Types.TerminalVendors.NONE,

    is_reader_disabled: false,

    terminals: [],
    selected_reader: {},
    is_connected: false,
    is_connecting: false,

    terminal_payment_status: Types.TerminalPaymentStatus.DEFAULT,

    connection_error: {},
    payment_error: '',

    kiosk_id: '',
    terminal_id: '',

    nuvei_payment_terminals: [],
    nuvei_selected_terminal: {},
    nuvei_error: {},

    nuvei_cancellation_token: null,
};

const guestReducer = (state = INITIAL_STATE, action) => {
    let copy = { ...state };

    switch (action.type) {
        case Types.NUVEI_CANCELLATION_TOKEN_RECEIVED:
            copy.nuvei_cancellation_token = action.payload;
            return copy;
        case Types.TERMINAL_VENDOR_CHANGED:
            copy.terminal_vendor = action.payload;
            return copy;
        case Types.NUVEI_ERROR_CHANGED:
            copy.nuvei_error = action.payload;
            return copy;
        case Types.NUVEI_PAYMENT_TERMINALS_LOADED:
            copy.nuvei_payment_terminals = action.payload;
            return copy;
        case Types.NUVEI_PAYMENT_TERMINAL_SELECTED:
            copy.nuvei_selected_terminal = action.payload;
            return copy;
        case Types.SET_READER_DISABLED:
            copy.is_reader_disabled = action.payload;
            return copy;
        case Types.TERMINAL_PAYMENT_STATUS_CHANGED:
            copy.terminal_payment_status = action.payload;
            return copy;
        case Types.TERMINAL_PAYMENT_ERROR_CHANGED:
            copy.payment_error = action.payload;
            return copy;
        case Types.SET_TERMINAL_CONNECTION_ERROR:
            copy.connection_error = action.payload;
            return copy;
        case Types.TERMINAL_SET_CONNECTED:
            copy.is_connected = action.payload;
            return copy;
        case Types.TERMINAL_READER_CONNECTED:
            copy.selected_reader = action.payload;
            return copy;
        case Types.TERMINAL_IS_CONNECTING:
            copy.is_connecting = action.payload;
            return copy;
        case Types.TERMINAL_READERS_LOADED:
            copy.terminals = action.payload;
            return copy;
        default:
            return state;
    }
};

export default guestReducer;
