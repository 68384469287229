import React from 'react';

// css
import './css/Default.css';
import './css/AddGuest.css';

// action creators
import * as TableActions from '../actions/tableActions';

// modules
import { connect } from 'react-redux';

// Components
import Button from '../components/Button'; // Button
import TextBox from '../components/TextBox'; // Textbox
import { PageTitle, PageDescription } from '../components/Titles'; // Page titles and description modules
import Header from '../components/Header'; // Header
import { VerticalSpace } from '../components/Common'; // Common Modules
import { withTranslation } from 'react-i18next';

/*Converts a code array to a string */
const tableCodeArrayToString = (table_id_arr) => {
    let table_id = '';
    table_id_arr.forEach((code) => {
        table_id += code;
    });
    return table_id;
};

class AddGuest extends React.Component {
    componentDidMount() {
        // Handle when no code has been entered
        // i.e. should redirect back to the homepage
        const table_id = tableCodeArrayToString(this.props.mainState.table_id);
        if (table_id === '') {
            // redirect to homepage if table code is empty
            this.props.history.push('/');
        } else {
            // set the back button URL
            this.goBackUrl = '/table/' + table_id;
        }
    }

    /*
  handle Cancel button pressed
  */
    cancelButtonPressed() {
        this.props.history.push(this.goBackUrl);
    }

    /* Handle add button presssed */
    addButtonPressed() {
        const history = this.props.history;
        this.props.addGuestToTable(history);
    }

    /* Handle changing text field */
    guestNameChanged(event) {
        const name = event.target.value;
        this.props.addGuestNameUpdated(name);
    }

    /* Show error messages */
    renderErrorMessage() {
        const errorMsg = this.props.tableState.err_guest_name;
        return <div className="errorMessage">{errorMsg}</div>;
    }

    render() {
        const nameFieldValue = this.props.tableState.add_guest_name; // get name from state
        const addButtonDisabled = this.props.tableState.addButtonDisabled;

        return (
            <div className="Container">
                <Header history={this.props.history} back={this.goBackUrl} />
                <VerticalSpace size={1} />
                <PageTitle text={this.props.t('guest.newGuest')} />
                <PageDescription text={this.props.t('guest.enterYourName')} />

                <div style={{ display: 'flex', marginBottom: '30px' }}>
                    <TextBox
                        value={nameFieldValue}
                        onChange={(evt) => this.guestNameChanged(evt)}
                    />
                </div>
                {this.renderErrorMessage()}
                <Button
                    text={this.props.t('guest.add')}
                    width={3}
                    action={() => this.addButtonPressed()}
                    showArrow
                    disabled={addButtonDisabled}
                />
                <Button
                    text={this.props.t('common.cancel')}
                    width={3}
                    action={() => this.cancelButtonPressed()}
                    showBackArrow
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mainState: state.main,
        tableState: state.table,
    };
};

const actionCreators = {
    addGuestToTable: TableActions.addGuestToTable,
    addGuestNameUpdated: TableActions.addGuestNameUpdated,
};

// Connect the component to Redux
const ConnectedComponent = connect(mapStateToProps, actionCreators)(AddGuest);

// Wrap the connected component with withTranslation
export default withTranslation()(ConnectedComponent);
