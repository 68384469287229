import React from 'react';

interface StoreHighlightProps {}
export default (props: StoreHighlightProps) => {
    return (
        <img
            src="/assets/landing/v3/highlight/store-highlight.png"
            className="v3HighlightImage"
        />
    );
};
