import React from 'react';
import { Avatar, CircularProgress } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import { Language, useLocale } from '../locales';
import './css/LanguageSwitch.css';

export function LanguageSwitch() {
    const isLoading = useSelector((state: any) => state.order.menu.is_loading);
    const { locale, setLocale, supportedLocales } = useLocale();

    const handleChange = (e: any) => {
        const newLocale = supportedLocales.find(
            (el) => el.value === (e.target.value as Language)
        );
        setLocale(newLocale!);
    };

    if (!locale) {
        return null;
    }

    return (
        <div className="languageSwitchContainer">
            {isLoading && <CircularProgress size={16} />}
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={locale.value}
                label={locale.label}
                onChange={handleChange}
            >
                {supportedLocales.map((el) => (
                    <MenuItem key={el.value} value={el.value}>
                        {el.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
