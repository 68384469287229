import DateFnsUtils from '@date-io/date-fns';
import {
    Avatar,
    Button,
    CircularProgress,
    Fab,
    IconButton,
    InputLabel,
    Modal,
    Table,
    TableCell,
    TableRow,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    AddCircleOutline,
    Close,
    RemoveCircleOutline,
} from '@material-ui/icons';
import {
    DatePicker,
    MuiPickersUtilsProvider,
    TimePicker,
} from '@material-ui/pickers';
import amplitude from 'amplitude-js';
import Axios from 'axios';
import { enUS, fr } from 'date-fns/locale';
import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { BsFillClockFill } from 'react-icons/bs';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import * as OrderActions from '../../../actions/orderActions';
import * as TableActions from '../../../actions/tableActions';
import { APIv1Endpoint, cloudfrontBaseUrl } from '../../../endpoints';
import { i18n, useLocale } from '../../../locales';
import * as Types from '../../../reducers/TYPES';
import {
    checkItemsAreAvailableAtSpecifiedTime,
    combineItemsById,
    isDineInPaymentsEnabled,
    isDineinOrder,
    isRestaurantClosed,
} from '../../../utils';
import DineInExistingOrder from '../Components/DineInExistingOrder';
import RoundComponent from '../Components/RoundComponent';
import { createPath, sendUserBack } from '../utils';
import { TipOption } from './Tip/TipOption';
import './css/Cart.css';

const useStyles = makeStyles((theme) => ({
    cart: {
        flex: '1',
        backgroundColor: 'white',
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        color: '#fff',
        backgroundColor: '#20B04B',
        margin: '10px',
    },
    button_unselected: {
        margin: '10px',
    },
    modal: {
        position: 'absolute',
        bottom: '0',
        backgroundColor: '#F5F5F7',
        width: '100%',
        height: '80%',
        fontFamily: 'Poppins',
        overflow: 'hidden',
        borderRadius: '1rem 1rem 0rem 0rem',
    },
    fab: {
        position: 'fixed',
        bottom: 'calc(60px + env(safe-area-inset-bottom))',
        right: '1rem',
    },
    indicator: {
        backgroundColor: '#103C54',
    },
    tableCell: {
        padding: '0.5rem 0rem 0.5rem 0rem',
        fontSize: '1rem',
    },
}));

const ORDER_OPTION = {
    PICKUP_NOW: 'pickup-now',
    PICKUP_LATER: 'pickup-later',
    DELIVERY: 'delivery',
};

const dummyTips = [
    { percent: '15', other: '1' },
    { percent: '18', other: '1' },
    { percent: '20', other: '1' },
    { percent: '25', other: '1' },
];

const Cart = (props) => {
    // if (!useSelector((state) => state.table.selected_guest)) {
    //     return;
    // }

    const selected_guest = useSelector((state) => state.table.selected_guest);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const endpoint = params.endpoint;
    const restaurant_id = useSelector(
        (state) => state.main.restaurant_info.restaurant_id
    );

    console.log('CART');
    const { t } = useTranslation();
    const { locale } = useLocale();
    const address = useSelector((state) => state.main.restaurant_info.address);
    const name = useSelector((state) => state.main.restaurant_info.name);
    const order = useSelector((state) => state.order);
    const cart_items = useSelector((state) => state.order.temporary_order);
    const top_ten_items = useSelector((state) => state.order.menu.top_10);
    const order_id = useSelector((state) => state.table.guest_orders.order_id);
    const payment_processor = useSelector(
        (state) => state.order.payment_processor
    );
    const {
        net_total,
        tax_amount,
        tip_amount,
        delivery_tip_amount,
        discount,
        total_with_tip_and_tax,
    } = useSelector((state) => state.order.payment_totals);
    const delivery_fee = useSelector((state) => state.order.delivery_fee);
    const tip = useSelector((state) => state.order.payment_tip);
    const currency = useSelector(
        (state) => state.main.restaurant_info.currency
    );
    const delivery_tip = useSelector(
        (state) => state.order.delivery_tip_percentage
    );
    const tip_options =
        useSelector((state) => state.main.restaurant_info.tip_options) || [];
    const delivery_tip_options = useSelector(
        (state) => state.order.delivery_tip_options
    );
    const guest_id = useSelector(
        (state) => state.table.selected_guest.guest_id
    );
    const delivery_option = useSelector(
        (state) => state.main.restaurant_info.delivery_type
    );
    const delivery_min_spend = useSelector(
        (state) => state.main.restaurant_info.delivery_min_spend
    );
    const delivery_enabled = delivery_option !== 'DELIVERY_OPTION_NO_DELIVERY';
    const delivery_option_updated = useSelector(
        (state) => state.order.delivery_option_updated
    );
    const affiliateCode = useSelector((state) => state.order.affiliate_code);
    const dropoff_instructions = useSelector(
        (state) => state.order.dropoff_instructions
    );
    const dropoff_submit = useSelector((state) => state.order.dropoff_submit);
    const payment_loading = useSelector((state) => state.order.payment_loading);
    const dropoff_eta = useSelector((state) => state.order.dropoff_eta);
    const quote_time = useSelector((state) => state.table.quote_time);
    const [openPickup, setOpenPickup] = React.useState(false);
    const [openDiscount, setOpenDiscount] = React.useState(false);
    const [openTipModal, setOpenTipModal] = React.useState(false);
    const [openDeliveryTipModal, setOpenDeliveryTipModal] =
        React.useState(false);
    const [discountCode, setDiscountCode] = React.useState('');
    const [localTip, setLocalTip] = React.useState(tip);
    const [localDeliveryTip, setLocalDeliveryTip] =
        React.useState(delivery_tip);
    const [discountCodeIsSubmitting, setDiscountCodeIsSubmitting] =
        React.useState(false);
    const [pickupText, setPickupText] = React.useState('Pickup Later');
    const [deliveryAddress, setDeliveryAddress] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [dropOffInstructions, setDropOffInstructions] = useState('');
    const [orderOption, setOrderOption] = useState(null);
    const [totalsCalculated, setTotalsCalculated] = React.useState(false);
    const isLoadingDineInOrder = useSelector(
        (state) => state.table.is_loading_orders
    );
    const isCartUpdating = useSelector((state) => state.order.cart_updating);

    const state = useSelector((state) => state.order.selected_item);
    const is_restuarant_closed = isRestaurantClosed();

    const [amount, setAmount] = useState();

    // Dine-In
    const [showDineInPaymentOptions, setShowDineInPaymentOptions] =
        useState(false);
    const isMenuOnlyMode = useSelector(
        (state) =>
            state.main.restaurant_info.order_mode === 'RESTAURANT_SETTING_NONE'
    );
    const isTakeoutOrder =
        useSelector((state) => state.order.order_type) ===
        Types.DineInOrTakeoutSetting.TAKEOUT;
    const current_guest = useSelector((state) => state.table.selected_guest);
    const current_guest_order = useSelector(
        (state) => state.table.guest_orders.items
    );
    const isTipEnabled = useSelector((state) => {
        const restaurant = state.main.restaurant_info;
        return (
            restaurant.tip_enabled &&
            restaurant.tip_platform.includes('TIP_PLATFORM_WEB')
        );
    });
    const [phoneNumber, setPhoneNumber] = useState('');
    const disable_tip = !isTipEnabled;
    // &&
    // isGhostKitchens() &&
    // !showDineInPaymentOptions &&
    // !isTakeoutOrder;

    // Logic for rendering
    const guest_has_existing_order =
        current_guest_order && current_guest_order.length > 0;
    const cart_empty = cart_items.length === 0;
    const guest_has_unpaid_items =
        current_guest_order &&
        current_guest_order.filter((item) => {
            return item.has_paid === 'PAYMENT_UNPAID';
        }).length > 0;
    const show_dine_in_checkout_buttons =
        cart_empty &&
        guest_has_existing_order &&
        guest_has_unpaid_items &&
        !isTakeoutOrder &&
        !showDineInPaymentOptions;
    const guest_has_paid =
        current_guest_order &&
        current_guest_order.length > 0 &&
        !current_guest_order.filter((item) => {
            return item.has_paid === 'PAYMENT_UNPAID';
        }).length > 0;

    let total_price = 0;
    cart_items.forEach(
        (item) =>
            (total_price += Number(
                item.price_with_modifiers * item.multiplier?.toFixed(2)
            ))
    );
    const showDelivery =
        delivery_enabled &&
        !(
            delivery_option === 'DELIVERY_OPTION_IN_HOUSE' &&
            total_price < delivery_min_spend / 100
        ) &&
        !isDineinOrder() &&
        !(
            delivery_option === 'DELIVERY_OPTION_NASH' &&
            affiliateCode !== '$delivery'
        );

    React.useEffect(() => {
        if (showDelivery) {
            handleDeliveryOpen();
        } else {
            dispatch({ type: Types.DELIVERY_OPTION_UPDATED, payload: true });
        }
    }, []);

    React.useEffect(() => {
        dispatch(OrderActions.loadRecommendations());
        dispatch(TableActions.setVersion(2));
    }, [dispatch]);

    React.useEffect(() => {
        if (disable_tip) {
            dispatch(OrderActions.changeTip(0));
        }

        // calculate dine-in payment
        if (showDineInPaymentOptions) {
            // re-enable tip
            // dispatch(OrderActions.changeTip(0.15));
            dispatch(OrderActions.calculateTotals(current_guest_order, true));
        } else {
            dispatch(OrderActions.calculateTotals(cart_items, false));
        }

        setTotalsCalculated(true);
    }, [
        dispatch,
        disable_tip,
        cart_items,
        current_guest_order,
        showDineInPaymentOptions,
    ]);

    const handlePickupLaterOpen = () => {
        setOpenPickup(true);
        dispatch({ type: Types.CLEAR_ORDER_TIME });
        setOrderOption(ORDER_OPTION.PICKUP_LATER);
        dispatch({ type: Types.DELIVERY_OPTION_UPDATED, payload: true });
        dispatch({ type: Types.QUOTE_ID, payload: 'pickupDeliveryOption' });
        dispatch({ type: Types.PAYMENT_LOADING, payload: true });
        dispatch(OrderActions.calculateTotals(cart_items, false));
        setTotalsCalculated(true);
    };

    const handlePickupClose = () => {
        setOpenPickup(false);
    };

    const handleDiscountOpen = () => {
        setOpenDiscount(true);
    };

    const handleDiscountClose = () => {
        setOpenDiscount(false);
    };

    const handleTipOpen = () => {
        setOpenTipModal(true);
        setLocalTip(tip);
    };

    const handleTipClose = () => {
        setLocalTip(tip);
        setOpenTipModal(false);
    };

    const handleDeliveryTipOpen = () => {
        setOpenDeliveryTipModal(true);
        setLocalDeliveryTip(delivery_tip);
    };

    const handleDeliveryTipClose = () => {
        setOpenDeliveryTipModal(false);
    };

    const handleDeliveryOpen = () => {
        setOrderOption('delivery');
        dispatch({ type: Types.DELIVERY_OPTION_UPDATED, payload: false });
        dispatch({ type: Types.PAYMENT_LOADING, payload: true });
        dispatch({ type: Types.DROPOFF_SUBMIT, payload: false });
    };

    const callQuoteEndpoint = () => {
        dispatch({ type: Types.PAYMENT_LOADING, payload: true });
        Axios.post(`${APIv1Endpoint}delivery/quote`, {
            guest_id: guest_id,
            restaurant_id: restaurant_id,
            dropoff_address: deliveryAddress.label,
            dropoff_instructions: dropoff_instructions,
            dropoff_phone_number: phoneNumber,
            guest_first_name: firstName,
            guest_last_name: lastName,
            delivery_tip: delivery_tip_amount,
        }).then(
            (response) => {
                dispatch({ type: Types.QUOTE_ID, payload: response.data.id });
                dispatch({
                    type: Types.DELIVERY_FEE,
                    payload: response.data.fee / 100,
                });
                dispatch({
                    type: Types.DROPOFF_ETA,
                    payload: response.data.dropoff_eta,
                });
                dispatch(
                    TableActions.addGuestNameUpdated(`${firstName} ${lastName}`)
                );
                dispatch(OrderActions.calculateTotals(cart_items, false));
                dispatch({ type: Types.PAYMENT_LOADING, payload: false });
                setTotalsCalculated(true);
            },
            (error) => {
                dispatch({ type: Types.DROPOFF_SUBMIT, payload: false });
                console.log(error);
                if (error.response.status === 503) {
                    toast.error(i18n.t('toast.addressNotDeliverable'));
                } else {
                    toast.error(i18n.t('toast.errorNotification2'));
                }
                dispatch({ type: Types.PAYMENT_LOADING, payload: false });
            }
        );
    };

    const handleAddressChange = (value) => {
        setDeliveryAddress(value);
        dispatch({ type: Types.ADDRESS, payload: value });
    };

    const handleDropoffChange = (event) => {
        let instructions = event.target.value;
        if (instructions.length > 250) {
            event.target.value = instructions.substring(0, 250);
            return;
        }
        dispatch({
            type: Types.DROPOFF_INSTRUCTIONS,
            payload: event.target.value,
        });
        setDropOffInstructions(event.target.value);
    };

    const handleDropoffSubmit = (event) => {
        event.preventDefault();
        if (!isValidPhoneNumber(phoneNumber)) {
            toast.error(i18n.t('toast.invalidPhoneNumber2'));
            return;
        }
        if (deliveryAddress === '') {
            toast.error(i18n.t('toast.provideDeliveryAddress'));
            return;
        }
        dispatch({ type: Types.PHONE_NUMBER_UPDATED, payload: phoneNumber });
        dispatch({ type: Types.DROPOFF_SUBMIT, payload: true });
        dispatch({ type: Types.ADDRESS, payload: deliveryAddress.label });
        callQuoteEndpoint();
    };

    const renderModifiers = (modifiers, itemModifierGroup) => {
        if (!Array.isArray(modifiers)) {
            return;
        }

        const groupedModifiers = {};

        // Group modifiers by modifier group name
        modifiers.forEach((modifier) => {
            const modifierGroup = itemModifierGroup.find(
                (item) =>
                    item.modifier_group.modifier_group_id ===
                    modifier.modifier_group_id
            );

            const groupName = modifierGroup.modifier_group.name;

            if (!groupedModifiers[groupName]) {
                groupedModifiers[groupName] = [];
            }

            groupedModifiers[groupName].push(modifier);
        });

        return Object.keys(groupedModifiers).map((groupName, groupIndex) => (
            <div key={groupIndex}>
                <div
                    style={{
                        fontWeight: 'bold',
                        margin: '10px 0',
                        margin: '10px 0 5px 0',
                        fontSize: '16px',
                        color: '#707070',
                    }}
                >
                    {groupName}
                </div>
                {/* {combineItemsById(groupedModifiers[groupName], 'name').map(
                    (modifier, modifierIndex) => (
                        <div
                            key={modifierIndex}
                            style={{ fontSize: '0.75rem', color: '#707070' }}
                            data-cy="selectedModifier"
                        >
                            {console.log(
                                'modifiers',
                                groupedModifiers[groupNameƒ]
                            )}
                            {modifier.count} {modifier.name}
                        </div>
                    )
                )} */}
                {combineItemsById(groupedModifiers[groupName], 'name').map(
                    (modifier, modifierIndex) => (
                        <div
                            key={modifierIndex}
                            style={{ fontSize: '0.75rem', color: '#707070' }}
                            data-cy="selectedModifier"
                        >
                            {modifier.count} {modifier.name}
                        </div>
                    )
                )}
            </div>
        ));
    };

    const renderOrder = (items) => {
        if (
            showDineInPaymentOptions &&
            current_guest_order &&
            current_guest_order.length > 0
        ) {
            return <></>;
        }
        if (!Array.isArray(items) || items.length === 0) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '1rem',
                        color: '#333',
                    }}
                >
                    {t('cart.emptyCartMessage')}.
                </div>
            );
        }
        return items.map((item, itemIndex) => {
            const image = `${cloudfrontBaseUrl}${restaurant_id}/items/${item.item_id}.jpeg`;
            const price = (
                item.price_with_modifiers * item.multiplier
            )?.toFixed(2);
            const instructions = item.special_instructions;
            return (
                <div
                    key={item.item_id}
                    data-cy="cartItem"
                    style={{
                        padding: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        dispatch({
                            type: Types.ITEM_EDIT_MODE_CHANGED,
                            payload: true,
                        });
                        const path = createPath('/v2/:/m/:/c/:/i/:', [
                            endpoint,
                            item.menu_id,
                            item.category_id,
                            item.item_id,
                        ]);
                        const location = {
                            pathname: path,
                            state: {
                                cart_item: item,
                            },
                        };
                        history.push(location);
                    }}
                >
                    <Avatar
                        src={image}
                        alt={item.name}
                        style={{
                            width: '4rem',
                            height: '4rem',
                        }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    fontWeight: '700',
                                }}
                            >
                                {item.name}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {renderModifiers(
                                    item.modifiers,
                                    item.item_modifier_groups
                                )}
                                <div
                                    style={{
                                        marginTop: '10px',
                                        fontSize: '0.75rem',
                                        color: '#707070',
                                    }}
                                >
                                    {instructions ? instructions : ''}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (item.multiplier === 1) {
                                        dispatch(OrderActions.deleteItem(item));
                                    } else {
                                        dispatch(
                                            OrderActions.finalizeOrderQuantityUpdated(
                                                item.multiplier - 1,
                                                itemIndex
                                            )
                                        );
                                    }
                                }}
                                style={{
                                    padding: 0,
                                }}
                            >
                                <RemoveCircleOutline fontSize="large" />
                            </IconButton>
                            <div style={{ padding: '0rem 1rem 0rem 1rem' }}>
                                {item.multiplier}
                            </div>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        OrderActions.finalizeOrderQuantityUpdated(
                                            item.multiplier + 1,
                                            itemIndex
                                        )
                                    );
                                }}
                                style={{
                                    padding: 0,
                                }}
                            >
                                <AddCircleOutline fontSize="large" />
                            </IconButton>
                        </div>
                    </div>
                    <div>${price}</div>
                </div>
            );
        });
    };

    const renderAddons = (items) => {
        if (!Array.isArray(items)) {
            return;
        }
        if (cart_items.length === 0) {
            // Do not render addons if cart is empty
            return <></>;
        }
        const cartItemIds = cart_items.map((item) => item.item_id);
        items = items.filter((item) => !cartItemIds.includes(item.item_id));
        if (items.length === 0) {
            return;
        }
        return (
            <div
                style={{
                    backgroundColor: '#F5F5F7',
                    padding: '1rem',
                }}
            >
                <div
                    style={{ color: 'black', fontSize: 12, fontWeight: '500' }}
                >
                    {t('cart.completeYourMeal')}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        overflowX: 'auto',
                    }}
                >
                    {items.map((item, index) => {
                        const image = `${cloudfrontBaseUrl}${restaurant_id}/items/${item.item_id}.jpeg`;
                        return (
                            <RoundComponent
                                medium
                                key={index}
                                image={image}
                                label={item.name}
                                onPress={() => {
                                    amplitude
                                        .getInstance()
                                        .logEvent(
                                            'Recommended Item Selected',
                                            item
                                        );
                                    dispatch({
                                        type: Types.ITEM_EDIT_MODE_CHANGED,
                                        payload: false,
                                    });
                                    const path = createPath(
                                        '/v2/:/m/:/c/:/i/:',
                                        [
                                            endpoint,
                                            item.menu_id,
                                            item.category_id,
                                            item.item_id,
                                        ]
                                    );
                                    history.push(path);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        );
    };

    const isValidTime = () => {
        const time = new Date(order.order_time);
        const currentTime = new Date().getTime();
        const is_valid = time.getTime() >= currentTime;
        return is_valid;
    };

    const discountCodeCallback = (success) => {
        if (success) {
            setOpenDiscount(false);
            setDiscountCode('');
        }
        setDiscountCodeIsSubmitting(false);
    };

    const handlePickupNow = () => {
        setPickupText('Pickup Later');
        setOrderOption(ORDER_OPTION.PICKUP_NOW);
        if (!checkItemsAreAvailableAtSpecifiedTime()) {
            return;
        }
        dispatch(OrderActions.orderTimeChanged(new Date()));

        const update_order_req = {
            order_id,
            pickup_time: new Date(),
            order_type: 1,
        };
        Axios.post(`${APIv1Endpoint}orders/setType`, update_order_req)
            .then((res) => {})
            .catch((err) => {
                toast.error(i18n.t('toast.setOrderingTimeNowError'));
                console.error(err.response);
            });
        handlePickupClose();
    };

    const handleSavePickupLater = () => {
        if (orderOption === ORDER_OPTION.PICKUP_LATER) {
            // LATER
            if (!isValidTime()) {
                toast.error(t('toast.setOrderingTimeLaterError'));
                // Set time to now
                dispatch(OrderActions.orderTimeChanged(new Date()));
                return;
            }
            if (!checkItemsAreAvailableAtSpecifiedTime()) {
                return;
            }
            // update order
            const update_order_req = {
                order_id,
                pickup_time: order.order_time,
                order_type: 2,
            };
            Axios.post(`${APIv1Endpoint}orders/setType`, update_order_req)
                .then((res) => {
                    toast.success(t('toast.orderingTimeSet'));
                    handlePickupClose();
                })
                .catch((err) => {
                    toast.error(t('toast.setOrderingTimeLaterError'));
                    console.error(err.response);
                });
            setPickupText(`${t('cart.pickupLateOn')} ` + order_time_str);
        }
    };

    const discountSaveButtonPressed = () => {
        setDiscountCodeIsSubmitting(true);
        dispatch(
            OrderActions.updateDiscountCodeField(discountCode.toUpperCase())
        );
        dispatch(OrderActions.validateCode(history, discountCodeCallback));
    };

    const tipSaveButtonPressed = () => {
        dispatch(OrderActions.changeTip(localTip));
        if (!isTakeoutOrder) {
            dispatch(OrderActions.calculateTotals(current_guest_order, true));
        } else {
            dispatch(OrderActions.calculateTotals(cart_items, false));
        }
        setOpenTipModal(false);
        setTotalsCalculated(true);
    };

    const deliveryTipSaveButtonPressed = () => {
        dispatch(OrderActions.changeDeliveryTipPercentage(localDeliveryTip));
        setOpenDeliveryTipModal(false);
    };

    const renderPickupModal = () => {
        const languagesLocale = {
            en: enUS,
            fr,
        };

        return (
            <Modal open={openPickup} onClose={handlePickupClose}>
                <div className={classes.modal}>
                    <div
                        style={{
                            backgroundColor: 'white',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '1rem 0rem 1rem 0rem',
                            }}
                        >
                            <IconButton onClick={handlePickupClose}>
                                <Close />
                            </IconButton>
                            <div
                                style={{
                                    fontWeight: '700',
                                    fontSize: '1.25rem',
                                    flex: '1',
                                }}
                            >
                                {t('cart.orderTime')}
                            </div>
                        </div>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={languagesLocale[locale.value]}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '1rem',
                                }}
                            >
                                <DatePicker
                                    lang={locale}
                                    fullWidth
                                    // value={null}
                                    value={order.order_time}
                                    minDate={new Date()}
                                    label={
                                        <div
                                            style={{
                                                textTransform: 'none',
                                                color: 'black',
                                            }}
                                        >
                                            {t('cart.day')}
                                        </div>
                                    }
                                    minDateMessage="Date cannot be earlier than today."
                                    onChange={(value) =>
                                        dispatch(
                                            OrderActions.orderTimeChanged(value)
                                        )
                                    }
                                    style={{
                                        paddingBottom: '1rem',
                                    }}
                                />

                                <TimePicker
                                    locale={languagesLocale[locale.value]}
                                    lang={languagesLocale[locale.value]}
                                    fullWidth
                                    // value={null}
                                    value={order.order_time}
                                    minDate={new Date()}
                                    label={
                                        <div
                                            style={{
                                                textTransform: 'none',
                                                color: 'black',
                                            }}
                                        >
                                            {t('cart.time')}
                                        </div>
                                    }
                                    minutesStep={5}
                                    minDateMessage="Time cannot be in the past."
                                    onChange={(value) =>
                                        dispatch(
                                            OrderActions.orderTimeChanged(value)
                                        )
                                    }
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                    </div>
                    {renderSaveButton(handleSavePickupLater)}
                </div>
            </Modal>
        );
    };

    const renderDiscountModal = () => {
        if (isTakeoutOrder || showDineInPaymentOptions) {
            return (
                <Modal open={openDiscount} onClose={handleDiscountClose}>
                    <div className={classes.modal}>
                        <div
                            style={{
                                backgroundColor: 'white',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: '1rem 0rem 1rem 0rem',
                                }}
                            >
                                <IconButton onClick={handleDiscountClose}>
                                    <Close />
                                </IconButton>
                                <div
                                    style={{
                                        fontWeight: '700',
                                        fontSize: '1.25rem',
                                        flex: '1',
                                    }}
                                >
                                    {t('cart.discountCode')}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '1rem',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    value={discountCode}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={
                                        <div
                                            style={{
                                                textTransform: 'none',
                                                color: 'black',
                                            }}
                                        >
                                            {t('cart.enterDiscountCode')}
                                        </div>
                                    }
                                    onChange={(e) => {
                                        setDiscountCode(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        {renderSaveButton(
                            discountSaveButtonPressed,
                            discountCodeIsSubmitting
                        )}
                    </div>
                </Modal>
            );
        }
    };

    const renderTipModal = () => {
        const _tipOptions =
            Array.isArray(tip_options) && tip_options.length > 0
                ? tip_options
                : [15, 0, 10, 18, 20, 25];

        return (
            <Modal open={openTipModal} onClose={handleTipClose}>
                <div className={classes.modal}>
                    <div
                        style={{
                            backgroundColor: 'white',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '1rem 0rem 1rem 0rem',
                            }}
                        >
                            <IconButton onClick={handleTipClose}>
                                <Close />
                            </IconButton>
                            <div
                                style={{
                                    fontWeight: '700',
                                    fontSize: '1.25rem',
                                    flex: '1',
                                }}
                            >
                                {t('cart.editTip')}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '1rem',
                            }}
                        ></div>
                    </div>

                    <div
                        style={{
                            padding: '10px',
                            backgroundColor: '#fff',
                        }}
                    >
                        {_tipOptions.map((option, index) => {
                            option /= 100;
                            const label = `${parseFloat(option * 100)}%`;
                            const selected = option === localTip;
                            return (
                                <span key={index}>
                                    <Button
                                        variant={
                                            selected ? 'contained' : 'outlined'
                                        }
                                        color="primary"
                                        className={
                                            selected
                                                ? classes.button
                                                : classes.button_unselected
                                        }
                                        onClick={() => {
                                            setLocalTip(option);
                                        }}
                                    >
                                        {label}
                                    </Button>
                                </span>
                            );
                        })}
                    </div>

                    {renderSaveButton(tipSaveButtonPressed)}
                </div>
            </Modal>
        );
    };

    const renderDeliveryTipModal = () => {
        return (
            <Modal open={openDeliveryTipModal} onClose={handleDeliveryTipClose}>
                <div className={classes.modal}>
                    <div class="whiteBackground">
                        <div class="modalTop">
                            <IconButton onClick={handleDeliveryTipClose}>
                                <Close />
                            </IconButton>
                            <div
                                style={{
                                    fontWeight: '700',
                                    fontSize: '1.25rem',
                                    flex: '1',
                                }}
                            >
                                {t('cart.editDeliveryTip')}
                            </div>
                        </div>
                    </div>

                    <div class="modalContent">
                        {delivery_tip_options.map((option, index) => {
                            const label = `${parseFloat(option * 100)}%`;
                            const selected = option === localDeliveryTip;
                            return (
                                <span key={index}>
                                    <Button
                                        variant={
                                            selected ? 'contained' : 'outlined'
                                        }
                                        color="primary"
                                        className={
                                            selected
                                                ? classes.button
                                                : classes.button_unselected
                                        }
                                        onClick={() => {
                                            setLocalDeliveryTip(option);
                                        }}
                                    >
                                        {label}
                                    </Button>
                                </span>
                            );
                        })}
                    </div>

                    {renderSaveButton(deliveryTipSaveButtonPressed)}
                </div>
            </Modal>
        );
    };

    const renderSaveButton = (onClick, disabled) => {
        return (
            <>
                <Button
                    variant="contained"
                    onClick={onClick}
                    disabled={disabled}
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 'env(safe-area-inset-bottom)',
                        width: '100vw',
                        backgroundColor: '#20B04B',
                        textTransform: 'none',
                        color: 'white',
                        borderRadius: 0,
                        padding: '1rem',
                    }}
                >
                    {t('cart.save')}
                </Button>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100vw',
                        height: 'env(safe-area-inset-bottom)',
                        backgroundColor: '#20B04B',
                    }}
                ></div>
            </>
        );
    };

    const renderNuveiWarning = () => {
        if (cartEmpty || payment_processor !== 'NUVEI') {
            return;
        }

        return (
            <div
                style={{
                    backgroundColor: '#F5F5F7',
                }}
            >
                <div
                    style={{
                        padding: '1rem',
                        fontSize: 13,
                        fontWeight: '600',
                        color: '#666',
                    }}
                >
                    {t('order.amexNotSupportedNotice')}
                </div>
            </div>
        );
    };

    const renderGuestAlreadyPaid = () => {
        if (guest_has_paid && !cartEmpty) {
            return (
                <div
                    style={{
                        backgroundColor: '#20B04B',
                    }}
                >
                    <div
                        style={{
                            padding: '1rem',
                            fontSize: 16,
                            fontWeight: '600',
                            color: '#fff',
                        }}
                    >
                        You've already paid. Please start a new order.
                    </div>
                </div>
            );
        }
    };

    const cartEmpty = !Array.isArray(cart_items) || cart_items.length === 0;
    const order_time = order.order_time;
    const order_time_str =
        order_time && order_time.toLocaleString
            ? order_time.toLocaleString('en-US', {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
              })
            : t('cart.pickupLater');
    const renderAddressWebview = () => {
        return (
            <div className="dropoffInputContainer">
                <GooglePlacesAutocomplete
                    variant="outlined"
                    apiKey="AIzaSyBXP2M5if4sWkuZH92dUm3B3urlzZohKsY"
                    onLoadFailed={(error) =>
                        console.error('Could not inject Google script', error)
                    }
                    selectProps={{
                        value: deliveryAddress,
                        onChange: handleAddressChange,
                        styles: {
                            padding: '0rem 1rem 0rem 1rem',
                        },
                        isClearable: true,
                    }}
                />
            </div>
        );
    };

    const renderDropoffEta = () => {
        var dropoffEta = new Date(dropoff_eta);
        var currentDate = new Date();
        var timeDiff = dropoffEta - currentDate;
        timeDiff /= 1000;
        timeDiff /= 60;
        var minutes = Math.round(timeDiff);
        return (
            <div>
                <br />
                <BsFillClockFill></BsFillClockFill>
                <p style={{ display: 'inline', padding: '10px' }}>
                    {t('cart.deliveryArrivalTime', { minutes })}
                </p>
            </div>
        );
    };

    const { tip_platform } = useSelector((state) => state.main.restaurant_info);
    const tipPlatformEnabled =
        tip_platform?.length === 0 ||
        tip_platform?.includes('TIP_PLATFORM_WEB');

    const renderPageBody = () => {
        if (!isTakeoutOrder && !showDineInPaymentOptions) {
            return;
        }
        if (
            isTakeoutOrder &&
            !delivery_option_updated &&
            !dropoff_submit &&
            showDelivery
        ) {
            return (
                <div
                    style={{
                        padding: '1rem',
                    }}
                >
                    <form
                        onSubmit={handleDropoffSubmit}
                        className="deliveryForm"
                    >
                        <label>
                            {t('cart.deliveryAddress')}

                            <br />
                            {renderAddressWebview()}
                        </label>
                        <br />
                        <div className="guestName">
                            <label>
                                {t('cart.firstName')}
                                <br />
                                <div className="firstName">
                                    <div class="box1">
                                        <div class=" box2">
                                            <div class="box3">
                                                <input
                                                    type="text"
                                                    value={firstName}
                                                    onChange={(e) =>
                                                        setFirstName(
                                                            e.target.value
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <br />
                                    </div>
                                </div>
                            </label>
                            <label>
                                {t('cart.lastName')}
                                <br />
                                <div className="lastName">
                                    <div class="box1">
                                        <div class=" box2">
                                            <div class="box3">
                                                <input
                                                    type="text"
                                                    value={lastName}
                                                    onChange={(e) =>
                                                        setLastName(
                                                            e.target.value
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <label>
                            {t('cart.contactNumber')}

                            <div style={{ padding: '0rem' }}>
                                <PhoneInput
                                    style={{
                                        padding: '0 0 0 10px',
                                        margin: '0',
                                        borderColor: 'hsl(0, 0%, 80%)',
                                        borderRadius: '4px',
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                    }}
                                    inputStyle={{ textAlign: 'left' }}
                                    placeholder="(123) 456-7890"
                                    defaultCountry={
                                        currency?.toUpperCase() === 'USD'
                                            ? 'US'
                                            : 'CA'
                                    }
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    data-cy="phoneNumberInput"
                                />
                            </div>
                            <br />
                        </label>
                        <label>
                            {t('cart.dropoffInstructions')}
                            <br />
                            <small> {t('cart.maxCharacters')}</small>
                            <br />
                            <div className="dropoffInputContainer">
                                <div class="box1">
                                    <div class="box2">
                                        <div class="box3">
                                            <input
                                                class="dropoffInput"
                                                type="text"
                                                placeholder={t(
                                                    'common.optional'
                                                )}
                                                onChange={handleDropoffChange}
                                                value={dropOffInstructions}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <br />
                            </div>
                        </label>
                        <label>
                            <div
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <div className={classes.tableCell}>
                                    {t('cart.deliveryTip')}
                                </div>
                                <div
                                    className={classes.tableCell}
                                    style={{
                                        textAlign: 'right',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: 'fit-content',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button
                                        style={{
                                            textTransform: 'none',
                                            color: '#20B04B',
                                        }}
                                        onClick={handleDeliveryTipOpen}
                                    >
                                        {t('cart.edit')}
                                    </Button>{' '}
                                    ${(delivery_tip_amount / 100)?.toFixed(2)}
                                </div>
                            </div>
                        </label>
                        <br />
                        <input
                            class="inputSubmit"
                            type="submit"
                            value="Save"
                        ></input>
                    </form>
                </div>
            );
        } else if (payment_loading && showDelivery) {
            return (
                <div
                    style={{
                        padding: '1rem',
                    }}
                >
                    <TableRow>
                        <TableCell className={classes.tableCell}>
                            {t('cart.calculatingPayment')}
                        </TableCell>
                    </TableRow>
                </div>
            );
        } else {
            if (!totalsCalculated) {
                return <></>;
            }
            return (
                <div
                    style={{
                        padding: '1rem',
                    }}
                >
                    <Button
                        style={{
                            float: 'right',
                            textTransform: 'none',
                            color: '#20B04B',
                        }}
                        onClick={handleDiscountOpen}
                    >
                        {t('cart.enterDiscountCode')}
                    </Button>
                    <Table>
                        <tbody>
                            {delivery_fee != null &&
                            !delivery_option_updated &&
                            showDelivery ? (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        {t('cart.deliveryFee')}
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{ textAlign: 'right' }}
                                    >
                                        ${delivery_fee?.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <></>
                            )}
                            {discount ? (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        Discount
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{ textAlign: 'right' }}
                                    >
                                        -${discount?.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <></>
                            )}
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    {t('cart.subtotal')}
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell}
                                    style={{ textAlign: 'right' }}
                                >
                                    ${(net_total + (discount || 0))?.toFixed(2)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    {t('cart.taxes')}
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell}
                                    style={{ textAlign: 'right' }}
                                >
                                    ${tax_amount?.toFixed(2)}
                                </TableCell>
                            </TableRow>
                            {isTipEnabled && (
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <div className="!flex gap-6 items-center">
                                            {t('addATip')}

                                            <TipOption />
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{ textAlign: 'right' }}
                                    >
                                        {/* <Button
                                            style={{
                                                textTransform: 'none',
                                                color: '#20B04B',
                                            }}
                                            onClick={handleTipOpen}
                                        >
                                            {t('cart.edit')}
                                        </Button>{' '} */}
                                        ${tip_amount?.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    {t('cart.deliveryTip')}
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell}
                                    style={{ textAlign: 'right' }}
                                >
                                    ${(delivery_tip_amount / 100)?.toFixed(2)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    className={classes.tableCell}
                                    style={{
                                        fontSize: '1.25rem',
                                        borderBottom: 'none',
                                    }}
                                >
                                    {t('cart.total')}
                                </TableCell>
                                <TableCell
                                    className={classes.tableCell}
                                    style={{
                                        fontSize: '1.25rem',
                                        borderBottom: 'none',
                                        textAlign: 'right',
                                    }}
                                >
                                    ${(total_with_tip_and_tax || 0).toFixed(2)}
                                </TableCell>
                            </TableRow>
                            {dropoff_eta &&
                            !delivery_option_updated &&
                            delivery_option !== 'DELIVERY_OPTION_IN_HOUSE' ? (
                                renderDropoffEta()
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </Table>
                </div>
            );
        }
    };

    const renderDeliveryOptions = () => {
        if (isTakeoutOrder) {
            return (
                <div
                    style={{
                        padding: '0 1rem 0 1rem',
                    }}
                >
                    <div
                        style={{
                            paddingBottom: '1rem',
                        }}
                    >
                        <InputLabel
                            style={{
                                color: 'rgba(255, 255, 255, 0.8)',
                                fontSize: '0.75rem',
                                textTransform: 'uppercase',
                            }}
                        >
                            {t('common.order')}
                        </InputLabel>
                        <div style={{ fontWeight: '700' }}>
                            {address ? address : name ? name : 'Loading...'}
                        </div>
                    </div>

                    <div
                        style={{
                            paddingBottom: '1rem',
                        }}
                    >
                        <InputLabel
                            style={{
                                color: 'rgba(255, 255, 255, 0.8)',
                                fontSize: '0.75rem',
                                margin: '0 0 5px 0',
                            }}
                        >
                            {t('cart.chooseDeliveryOption')}
                        </InputLabel>
                        <div className="radioButtons">
                            <input
                                id={ORDER_OPTION.PICKUP_NOW}
                                checked={
                                    orderOption === ORDER_OPTION.PICKUP_NOW
                                }
                                type="radio"
                                value={ORDER_OPTION.PICKUP_NOW}
                                style={{ margin: '5px' }}
                                onClick={() => {
                                    handlePickupNow();
                                }}
                            />
                            <label
                                htmlFor={ORDER_OPTION.PICKUP_NOW}
                                onClick={() => handlePickupNow()}
                            >
                                {t('cart.pickupNow')}
                            </label>
                            <br />
                            <input
                                id={ORDER_OPTION.PICKUP_LATER}
                                checked={
                                    orderOption === ORDER_OPTION.PICKUP_LATER
                                }
                                type="radio"
                                value={ORDER_OPTION.PICKUP_LATER}
                                style={{ margin: '5px' }}
                                onClick={() => {
                                    handlePickupLaterOpen();
                                }}
                            />
                            <label
                                htmlFor={ORDER_OPTION.PICKUP_LATER}
                                onClick={() => handlePickupLaterOpen()}
                            >
                                {pickupText === 'Pickup Later'
                                    ? t('cart.pickupLater')
                                    : pickupText}
                            </label>
                            <br />
                            {showDelivery ? (
                                <>
                                    <input
                                        id={ORDER_OPTION.DELIVERY}
                                        checked={
                                            orderOption ===
                                            ORDER_OPTION.DELIVERY
                                        }
                                        type="radio"
                                        value={ORDER_OPTION.DELIVERY}
                                        style={{ margin: '5px' }}
                                        onClick={() => {
                                            handleDeliveryOpen();
                                        }}
                                    ></input>
                                    <label
                                        htmlFor={ORDER_OPTION.DELIVERY}
                                        onClick={() => handleDeliveryOpen()}
                                    >
                                        {t('cart.delivery')}
                                    </label>
                                    <br />
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            if (!isMenuOnlyMode) {
                return (
                    <div
                        style={{
                            padding: '0 1rem 0 1rem',
                        }}
                    >
                        <div
                            style={{
                                paddingBottom: '1rem',
                            }}
                        >
                            <InputLabel
                                style={{
                                    color: 'rgba(255, 255, 255, 0.8)',
                                    fontSize: '0.75rem',
                                }}
                            >
                                {t('cart.name')}
                            </InputLabel>
                            <div style={{ fontWeight: '700' }}>
                                {current_guest.name}
                            </div>
                        </div>
                    </div>
                );
            }
        }
    };

    const renderDineInCheckoutButton = () => {
        // temp cart must be empty

        const paymentsEnabled = isDineInPaymentsEnabled();

        if (show_dine_in_checkout_buttons && !guest_has_paid) {
            if (paymentsEnabled) {
                return (
                    <div className="dineInCartButtons">
                        <button
                            className="paymentSubmitButton paymentSubmitButtonGreen"
                            onClick={() => setShowDineInPaymentOptions(true)}
                        >
                            <span id="button-text">
                                {' '}
                                {t('cart.goToCheckout')}
                            </span>
                        </button>
                        <button
                            className="paymentSubmitButton paymentSubmitButtonAddMore"
                            onClick={() => {
                                sendUserBack(
                                    history,
                                    props.previousPath,
                                    params
                                );
                            }}
                        >
                            <span id="button-text"> {t('cart.orderMore')}</span>
                        </button>
                    </div>
                );
            } else {
                return (
                    <div className="dineInCartButtons">
                        <button className="paymentSubmitButton paymentSubmitButtonDisabled">
                            <span id="button-text">
                                {t('cart.pleasePayAtCounter')}
                            </span>
                        </button>
                        <button
                            className="paymentSubmitButton paymentSubmitButtonGreen"
                            onClick={() => {
                                sendUserBack(
                                    history,
                                    props.previousPath,
                                    params
                                );
                            }}
                        >
                            <span id="button-text"> {t('cart.orderMore')}</span>
                        </button>
                    </div>
                );
            }
        }
    };
    const renderMenuOnlyModeMessage = () => {
        if (isMenuOnlyMode) {
            return (
                <div className="finalizeOrderMessage">
                    {t('cart.contactStaffMember')}
                </div>
            );
        }
    };

    const renderFab = () => {
        if (is_restuarant_closed) {
            return (
                <Fab
                    className={classes.fab}
                    style={{
                        backgroundColor: '#AAA',
                        color: '#666',
                        textTransform: 'none',
                        fontWeight: '700',
                        padding: '2rem',
                        borderRadius: '10000px',
                    }}
                    variant="extended"
                >
                    {t('cart.closed')}
                </Fab>
            );
        }

        if (
            (!showDineInPaymentOptions && cartEmpty) ||
            (isTakeoutOrder &&
                !delivery_option_updated &&
                (!dropoff_submit || payment_loading)) ||
            guest_has_paid ||
            isMenuOnlyMode ||
            isLoadingDineInOrder
        ) {
            return <></>;
        } else {
            return (
                <Fab
                    className={classes.fab}
                    variant="extended"
                    style={{
                        textTransform: 'none',
                        backgroundColor: '#20B04B',
                        color: 'white',
                        fontWeight: '700',
                        padding: '2rem',
                        borderRadius: '10000px',
                    }}
                    disabled={isCartUpdating}
                    onClick={() => {
                        if (isTakeoutOrder) {
                            var curTime = new Date();
                            var timeDiff = curTime - quote_time;
                            timeDiff /= 1000;
                            if (timeDiff > 240 && !delivery_option_updated) {
                                toast.error(t('toast.orderTimeout'));
                                dispatch({
                                    type: Types.DROPOFF_SUBMIT,
                                    payload: false,
                                });
                            } else {
                                amplitude.getInstance().logEvent('Pay Button');
                                if (!checkItemsAreAvailableAtSpecifiedTime())
                                    return;
                                history.push(
                                    createPath('/v2/:/phone', [endpoint])
                                );
                            }
                        } else {
                            if (showDineInPaymentOptions) {
                                history.push(
                                    createPath('/v2/:/phone', [endpoint])
                                );
                            } else {
                                // Send order to kitchen
                                dispatch(
                                    OrderActions.sendToKitchen(
                                        undefined,
                                        undefined,
                                        false
                                    )
                                );
                                if (isDineInPaymentsEnabled()) {
                                    dispatch(
                                        OrderActions.v2ShowReadyToPay(true)
                                    );
                                }
                                history.push(
                                    createPath('/v2/:/order', [endpoint])
                                );
                            }
                        }
                    }}
                    data-cy="payNowButton"
                >
                    {isCartUpdating && (
                        <CircularProgress
                            color="white"
                            size={18}
                            style={{ marginRight: 8 }}
                        />
                    )}
                    {total_with_tip_and_tax &&
                    (isTakeoutOrder || showDineInPaymentOptions)
                        ? `${t('cart.pay')} $${total_with_tip_and_tax?.toFixed(
                              2
                          )}`
                        : t('cart.placeOrder')}
                </Fab>
            );
        }
    };

    if (!selected_guest) return <Redirect to="/" />;

    if (!locale) {
        return null;
    }

    return (
        <div className={classes.cart}>
            <div
                style={{
                    backgroundColor: '#20B04B',
                    color: 'white',
                    position: 'sticky',
                    top: '0',
                    zIndex: '1',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '1rem 0rem 1rem 0rem',
                    }}
                >
                    <IconButton
                        data-cy="cart_close"
                        onClick={() => {
                            sendUserBack(history, props.previousPath, params);
                        }}
                    >
                        <Close style={{ fill: 'white' }} />
                    </IconButton>
                    <div style={{ fontWeight: '700', fontSize: '1.25rem' }}>
                        {t('cart.cart')}
                    </div>
                </div>

                {renderDeliveryOptions()}
            </div>
            <div
                style={{
                    backgroundColor: 'white',
                    color: '#333333',
                    overflowY: 'auto',
                    flex: '1',
                }}
            >
                {renderNuveiWarning()}
                {renderOrder(cart_items)}

                {renderGuestAlreadyPaid()}
                <DineInExistingOrder />

                {renderDineInCheckoutButton()}
                {renderAddons(top_ten_items)}
                {renderPageBody()}
                {renderMenuOnlyModeMessage()}
                {!cartEmpty ? <div style={{ height: '5rem' }}></div> : <></>}
            </div>
            {renderFab()}
            <div style={{ height: 160 }}></div>
            {renderPickupModal()}
            {renderDiscountModal()}
            {renderTipModal()}
            {renderDeliveryTipModal()}
        </div>
    );
};

export default Cart;
