import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import '../../css/ItemScreen.css';
import TextField from '@material-ui/core/TextField';
import { Container } from '@material-ui/core';
import * as OrderActions from '../../../actions/orderActions';
import { sendUserBack } from '../utils';
import {
    SelectPageHeader,
    SelectPageSubHeader,
} from '../Components/ItemComponents';
import MiniCart from '../Components/MiniCart';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { previousPath } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    let initialInstructions = history.location.state
        ? history.location.state.special_instructions
        : '';
    const [specialInstructions, setSpecialInstructions] =
        useState(initialInstructions);
    const reduxState = useSelector((state) => state);
    const urlParams = useParams();

    const resetSpecialInstructions = useCallback(() => {
        if (!previousPath) {
            dispatch(OrderActions.menuItemSpecialInstructionsChanged(''));
        }
    }, [previousPath, dispatch]);

    useEffect(resetSpecialInstructions, []);

    return (
        <Container
            fixed
            style={{
                padding: 0,
                margin: 0,
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
            }}
        >
            <div
                class="content"
                style={{ display: 'flex', flexDirection: 'column', flex: '1' }}
            >
                <SelectPageHeader
                    condition={
                        specialInstructions !==
                        reduxState.order.selected_item_special_instructions
                    }
                    previousPath={previousPath}
                    urlParams={urlParams}
                    history={history}
                    text={t('order.specialInstructions')}
                    clickedSaveCallback={() => {
                        dispatch(
                            OrderActions.menuItemSpecialInstructionsChanged(
                                specialInstructions
                            )
                        );
                        sendUserBack(history, previousPath, urlParams);
                    }}
                />
                <SelectPageSubHeader sub_header={'Enter your instructions'} />
                <div className="SpecialInstructionsContainer">
                    <TextField
                        data-cy="specialInstructionsField"
                        fullWidth
                        defaultValue={specialInstructions}
                        onChange={(e) => {
                            setSpecialInstructions(e.target.value);
                        }}
                        id="outlined-basic"
                        variant="outlined"
                    />
                </div>
            </div>
            <MiniCart />
        </Container>
    );
};
