import React from 'react';
import { connect } from 'react-redux';

import { Rating } from '@material-ui/lab';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faYelp,
    faGoogle,
    faFacebook,
} from '@fortawesome/free-brands-svg-icons';

import './css/Receipt.css';
import * as OrderActions from '../actions/orderActions';
import { YourOrderComponent } from './YourOrder';
import { withTranslation } from 'react-i18next';

library.add(faYelp, faGoogle, faFacebook);

const restuarant_id_to_social_media = {
    '2993daf5-c798-11ea-b545-02685a4295ea': {
        yelp: 'https://yelp.to/qTKq/bvDYmMOE88',
        google: 'https://www.google.com/search?client=ms-android-verizon&output=search&q=ethos+Greek+Bistro&ludocid=17635880988830284877&ibp=gwp;0,7&lsig=AB86z5XFpAxRcosstID8xWYWLYvJ&kgs=f0636fcdcfe7b645&shndl=-1&source=sh/x/kp/local&entrypoint=sh/x/kp/local',
        facebook: 'https://www.facebook.com/EthosGreekBistro/',
    },
};

class Receipt extends YourOrderComponent {
    componentDidMount() {
        this.props.loadReceipt(this.props.match.params.guest_id);
    }

    renderRow(label, value) {
        return (
            <div className="yourOrderItemRow">
                <div className="yourOrderItemLabel">{label}</div>
                <div className="yourOrderItemPrice">{value}</div>
            </div>
        );
    }

    renderIcon(icon, link) {
        return (
            <div className="rating-icon" key={link}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', icon]} size={'2x'} />
                </a>
            </div>
        );
    }

    render() {
        let receipt_order, receipt_restaurant;
        if (this.props.order.receipt_order === undefined) {
            return;
        }

        receipt_order = this.props.order.receipt_order;
        receipt_restaurant = this.props.order.receipt_restaurant;

        let {
            items,
            tip_percentage,
            tip_fixed,
            campaigns,
            delivery_fee,
            delivery_tip,
            sales_tax,
        } = receipt_order;
        if (!tip_percentage) tip_percentage = 0;
        if (!tip_fixed) tip_fixed = 0;
        if (!items) items = [];
        if (!campaigns) campaigns = [];
        if (!delivery_fee) delivery_fee = 0;
        if (!delivery_tip) delivery_tip = 0;

        const has_loaded = Boolean(receipt_restaurant);

        let subtotal = 0;
        items.forEach((item) => {
            let item_price = item.item.price ? item.item.price : 0;
            let { modifiers } = item;
            if (!modifiers) modifiers = [];

            modifiers.forEach((modifier) => {
                item_price += modifier.price ? modifier.price : 0;
            });

            const multiplier = item.multiplier ? item.multiplier : 1;
            subtotal += item_price * multiplier;
        });

        campaigns.forEach((campaign) => {
            subtotal -= campaign.discount / 100;
        });

        let discount = 0;
        if (
            receipt_restaurant.restaurant_id ===
            '2993daf5-c798-11ea-b545-02685a4295ea'
        ) {
            discount = subtotal * 0.2;
        }
        subtotal -= discount;

        //subtotal /= 100;
        //discount /= 100;
        let tip = 0;
        if (tip_percentage) {
            tip = subtotal * (tip_percentage / 100);
        }
        if (tip_fixed) {
            tip = tip_fixed;
        }

        const tax = sales_tax / 100;
        subtotal += delivery_fee / 100;
        delivery_tip = delivery_tip / 100;
        console.log(discount);
        //TODO: replace with actual yelp/google link
        return (
            <div className="receipt-container">
                <div className="receiptLogoContainer">
                    <img
                        alt="Restuarant Logo"
                        className="receiptLogo"
                        src={receipt_restaurant.logo}
                    />
                </div>
                {this.renderRow('Order ID', receipt_order.table_id)}
                {receipt_order.label &&
                    this.renderRow(
                        this.props.t('receipt.guestName'),
                        receipt_order.label
                    )}

                <div style={{ marginTop: '20px' }}>
                    {this.renderItems(items)}
                </div>

                {has_loaded && discount ? (
                    this.renderRow(
                        `20% ${this.props.t('receipt.offDiscount')}`,
                        -discount.toFixed(2)
                    )
                ) : (
                    <></>
                )}
                {has_loaded ? (
                    campaigns.map((campaign) => {
                        return this.renderRow(
                            [
                                this.props.t('common.discount'),
                                campaign.campaign.name,
                            ].join(': '),
                            '-' + (campaign.discount / 100).toFixed(2)
                        );
                    })
                ) : (
                    <></>
                )}
                {has_loaded &&
                    this.renderRow(
                        this.props.t('cart.deliveryFee'),
                        (delivery_fee / 100).toFixed(2)
                    )}
                {has_loaded &&
                    this.renderRow(
                        this.props.t('cart.subtotal'),
                        subtotal.toFixed(2)
                    )}
                {has_loaded &&
                    this.renderRow(
                        `${this.props.t('cart.tip')}${
                            tip_percentage ? ` (${tip_percentage}%)` : ''
                        }`,
                        tip.toFixed(2)
                    )}
                {has_loaded &&
                    this.renderRow(
                        this.props.t('cart.deliveryTip'),
                        delivery_tip.toFixed(2)
                    )}
                {has_loaded &&
                    this.renderRow(this.props.t('common.tax'), tax.toFixed(2))}
                {has_loaded &&
                    this.renderRow(
                        this.props.t('cart.total'),
                        (subtotal + tip + tax + delivery_tip).toFixed(2)
                    )}
                <div className="rating-container">
                    <div className="rating-control">
                        <div className="">
                            {this.props.t('receipt.experienceQuestion')}&nbsp;
                            {receipt_restaurant.name}?
                        </div>
                        <Rating
                            name="size-large"
                            defaultValue={this.props.order.restaurant_rating}
                            precision={1}
                            size="large"
                            onChange={(event, value) => {
                                this.props.updateRestaurantRating(value);
                            }}
                        />
                    </div>

                    {this.props.order.restaurant_rating >= 4 && (
                        <div>
                            Thanks! Feel free to leave a review.
                            <div className="rating-icons">
                                {Object.keys(
                                    restuarant_id_to_social_media[
                                        receipt_restaurant.restaurant_id
                                    ] || {}
                                ).map((social_media) => {
                                    return this.renderIcon(
                                        social_media,
                                        restuarant_id_to_social_media[
                                            receipt_restaurant.restaurant_id
                                        ][social_media]
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
                <div className="address-container">
                    {this.props.order.receipt_restaurant.address}
                </div>
                <div className="receipt-powered-by">
                    {this.props.t('logoView.poweredBy')}&nbsp;MENTUM
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.order,
    };
};

const actions = {
    loadReceipt: OrderActions.loadReceipt,
    updateRestaurantRating: OrderActions.updateRestaurantRating,
};

export default connect(mapStateToProps, actions)(withTranslation()(Receipt));
