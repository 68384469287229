import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { tipBtnClass } from './TipOption';
import { OptionChangeHandler, TipType } from './types';

export interface OtherTipOptionProps {
    isActive: boolean;
    onOptionChange: OptionChangeHandler;
}

export const OtherTipOption = ({
    isActive,
    onOptionChange,
}: OtherTipOptionProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setIsModalOpen(true)}
                className={clsx(
                    tipBtnClass,
                    isActive
                        ? 'bg-[#0D785D] text-white'
                        : 'bg-[#F0F0F2] text-black'
                )}
            >
                Other
            </button>

            {isModalOpen && (
                <OtherTipOptionModal
                    onOptionChange={onOptionChange}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </>
    );
};

interface OtherTipOptionModalProps {
    onOptionChange: OptionChangeHandler;
    onClose: () => void;
}

const OtherTipOptionModal = ({
    onClose,
    onOptionChange,
}: OtherTipOptionModalProps) => {
    const { t: translate } = useTranslation();
    const [value, setValue] = useState<number | undefined>();
    const selectedTipType = useSelector(
        (state: any) => state.order.payment_tip_type
    );
    console.log('value', value);
    const [currentTipType, setCurrentTipType] = useState(selectedTipType);

    const tip = useSelector((state: any) => {
        const tip = state.order.payment_tip;

        if (currentTipType === 'fixed') {
            return tip;
        }

        return Math.round(tip * 100);
    });

    const { net_total, tax_amount } = useSelector(
        (state: any) => state.order.payment_totals
    );

    const [isSelectedOptionVisible, setIsSelectedOptionVisible] =
        useState(false);

    useEffect(() => {
        setValue(tip);
    }, []);

    useEffect(() => {
        setValue(currentTipType !== selectedTipType ? undefined : tip);
    }, [currentTipType]);

    const handleCloseModal = () => onClose();

    const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (
        e
    ) => {
        setIsSelectedOptionVisible((x) => !x);
        const type = e.target.value as TipType;
        setCurrentTipType(type);
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        const value = event.target.value;

        if (currentTipType === 'fixed') {
            const valueWithTwoDecimalPlaces = Number(Number(value).toFixed(2));
            setValue(valueWithTwoDecimalPlaces);
            return;
        }

        if (!value) {
            setValue(undefined);
            return;
        }

        if (isNaN(Number(value))) {
            return;
        }

        if (Number(value) < 0) {
            return;
        }

        const parsedValue = parseInt(value, 10);

        if (!parsedValue) {
            return;
        }

        setValue(parsedValue);
    };

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        if (!value) {
            return;
        }

        onOptionChange(value, currentTipType);

        onClose();
    };

    const tipValue = useMemo(() => {
        let amt = Number(value);

        if (!amt || amt <= 0) {
            return 0;
        }

        if (currentTipType === 'percentage') {
            amt /= 100;

            return net_total * amt;
        }

        return amt;

    }, [value, currentTipType]);

    const getTipAmount = () => {
        let amt = tipValue;

        return `$${(net_total + amt + tax_amount).toFixed(2)}`;
    };

    const isDisabled = !value;

    return (
        <ReactModal
            isOpen
            ariaHideApp={false}
            className="w-[600px] min-h-[300px] bg-white p-8 pt-9 rounded-lg focus-visible:outline-none relative"
            overlayClassName="fixed inset-0 bg-black/25 opacity-100 flex items-center justify-center"
            onRequestClose={handleCloseModal}
        >
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-10">
                    <h1 className="flex items-center justify-between my-0 text-2xl font-semibold leading-7 text-black">
                        {translate('enterOtherAmount')}

                        <button
                            className="cursor-pointer"
                            onClick={handleCloseModal}
                        >
                            <img
                                src="/assets/icons/modal-close.svg"
                                alt="close modal"
                            />
                        </button>
                    </h1>
                    <div className="flex flex-col items-start w-full gap-1">
                        <div
                            className="self-stretch px-3.5 py-3 bg-white rounded border border-gray-400 items-center gap-2 flex">
                            <div className="flex items-center gap-1">
                                <select
                                    className="w-auto h-auto focus:border-none focus:outline-none"
                                    defaultValue={currentTipType}
                                    onChange={handleSelectChange}
                                >
                                    <option value="percentage">
                                        {isSelectedOptionVisible
                                            ? 'Percent (%)'
                                            : '%'}
                                    </option>
                                    <option value="fixed">
                                        {isSelectedOptionVisible
                                            ? 'Amount ($)'
                                            : '$'}
                                    </option>
                                </select>
                            </div>

                            <input
                                className="w-full text-xl font-medium leading-normal text-black"
                                value={value || ''}
                                type={
                                    currentTipType === 'fixed'
                                        ? 'number'
                                        : 'text'
                                }
                                onChange={handleChange}
                            />
                        </div>

                        {!!value && (
                            <div className="w-full text-sm font-normal leading-tight text-zinc-700">
                                {translate('otherTipInfo')} {getTipAmount()}
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col items-center justify-between gap-4">
                        <button
                            className={clsx(
                                '!rounded h-11 w-full flex items-center justify-center text-base font-semibold leading-3',
                                isDisabled
                                    ? '!bg-[#AFD9CE] text-[#7DC1AF] bg-opacity-30 !cursor-not-allowed !pointer-events-auto'
                                    : 'bg-[#0D785D] text-white'
                            )}
                            type="submit"
                            disabled={isDisabled}
                        >
                            {translate('save').toUpperCase()}
                        </button>

                        {tipValue > net_total && (
                            <p className="w-full text-sm font-medium leading-tight text-red-500 text-center">
                                {translate('tipError')} {value}
                            </p>
                        )}
                    </div>
                </div>
            </form>
        </ReactModal>
    );
};
