import React from 'react';

import './css/Header.css';
import { connect } from 'react-redux';
import * as Types from '../reducers/TYPES';

import { withTheme } from '../themes/theming';
import { isHotel } from '../utils';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { LocaleContext } from '../locales';
import { withTranslation } from 'react-i18next';

class Header extends React.Component {
    callButtonPressed() {
        this.props.history.push('/callWaiter');
    }
    static contextType = LocaleContext;

    render() {
        const {
            back, // back button url
            // guestName, // show back button with guest name
            handleBackButton,
            tableCode, // table code
            // showMenu, // show menu
            history,
            showGuestName,
            backButtonText,
        } = this.props;

        const { locale, supportedLocales, setLocale } = this.context;

        const theme = this.props.theme; // withTheme

        const logo = this.props.state.main.restaurant_info.logo;
        const guestName = this.props.state.table.selected_guest
            ? this.props.state.table.selected_guest.name
            : '';

        // if type of restuarant is standing, dont show guest name
        const hideGuestName =
            this.props.state.order.payment_type !== Types.PaymentType.DISABLED;

        const backButton = () => {
            const theme = this.props.theme;
            //const backArrowAssetURL = theme==="dark"?"/assets/backArrow-white.svg":"/assets/backArrow.svg"
            const backArrowAssetURL = '/assets/thin-back-btn.png';

            const is_hilton =
                this.props.state.main.restaurant_info.restaurant_id ===
                '65fcf46b-c0bb-11ea-abee-02685a4295ea';

            const backButtonClicked = () => {
                if (back === '') return;
                if (back === ':goBack') {
                    history.goBack();
                } else {
                    history.push(back);
                }
            };

            return (
                <div
                    className={`backButtonContainer`}
                    onClick={(e) => backButtonClicked()}
                >
                    {back ? (
                        <span className="backBtn">
                            <img
                                className="thinBackButton"
                                alt="Back"
                                src={backArrowAssetURL}
                            ></img>
                        </span>
                    ) : null}

                    {backButtonText ? (
                        <div
                            className="backButtonLabel"
                            style={theme.headerBackButtonText}
                        >
                            {backButtonText}
                        </div>
                    ) : (
                        <img
                            alt="Logo"
                            style={theme.headerLogo}
                            className={`imgLogo ${
                                is_hilton ? 'imgLogoLg' : ''
                            }`}
                            src={`${logo}?v=2`}
                        />
                    )}
                </div>
            );
        };
        // Render back button with table code
        const tableCodeBackButton = () => (
            <div
                onClick={handleBackButton}
                className={
                    'tableCodeBackBtn ' +
                    (theme === 'dark' ? 'tableCodeBackBtnDark' : '')
                }
            >
                <div style={{ display: 'flex' }}>
                    <img
                        alt="Back"
                        src={
                            theme === 'dark'
                                ? '/assets/tableCodeBackArrow-white.svg'
                                : '/assets/tableCodeBackArrow.svg'
                        }
                    />
                    <div className="tableCodeText">{tableCode}</div>
                </div>
            </div>
        );

        // Render menu sandwich button
        // TODO: Wire the sandwich button
        // const menuSandwich = () => (
        //     <div className="menuSandwichBtn">
        //         <img src={theme==="dark" ? "/assets/menuSandwich-white.svg" : "/assets/menuSandwich.svg"}></img>
        //     </div>
        // );

        // const guestNameBackButton = () => (
        //     <div onClick={handleBackButton} className = {"tableCodeBackBtn "+ (theme=="dark"?"tableCodeBackBtnDark":"")}>
        //         <div style={{display: 'flex'}}>
        //             <img src={ (theme=="dark"?"/assets/tableCodeBackArrow-white.svg":"/assets/tableCodeBackArrow.svg")} />
        //             <div className={(theme==="dark"? "guestNameText guestNameTextDark": "guestNameText")}>{guestName}</div>
        //         </div>
        //     </div>
        // );

        const navigateToGuestList = (table_id) => {
            // this.props.history.push(`/table/${table_id}`);
            this.props.history.push('/guesthome');
        };

        const renderGuestName = (name) => {
            if (hideGuestName | !name | this.props.state.table.is_tablet) {
                return;
            }

            // table id
            const id_arr = this.props.state.main.table_id;
            const id = `${id_arr[0]}${id_arr[1]}${id_arr[2]}${id_arr[3]}`;

            return (
                <div
                    className="guestNameRow"
                    onClick={(e) => navigateToGuestList(id)}
                >
                    <div
                        className="guestNameLabel"
                        style={theme.headerGuestNameLabel}
                    >
                        {/* {name} */}
                    </div>
                    <img
                        alt="Guest Icon"
                        className="guestNameIcon"
                        style={theme.headerGuestIcon}
                        src="/assets/guest-icon.png"
                    />
                </div>
            );
        };

        const showRestartOrder = () => {
            if (this.props.state.table.is_tablet) {
                return (
                    <div
                        className="restartOrderButton"
                        onClick={(e) => {
                            this.props.history.push(
                                `/${this.props.state.table.tablet_endpoint}`
                            );
                        }}
                    >
                        {this.props.t('common.startOver')}
                        Start Over
                    </div>
                );
            } else {
                return <></>;
            }
        };

        const handleChange = (event) => {
            const newLocale = supportedLocales.find(
                (el) => el.value === event.target.value
            );
            setLocale(newLocale);
        };

        return (
            <div>
                <div
                    className="fixedHeader"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="header">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                            className={`${
                                isHotel() ? 'backButtonLogoHilton' : ''
                            }`}
                        >
                            {backButton()}
                            {tableCode ? tableCodeBackButton() : null}
                        </div>

                        {showGuestName ? renderGuestName(guestName) : null}
                        {showRestartOrder()}
                    </div>
                    {/* TO HIDE IN CASE  OF TRANS */}
                    {/* <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={locale.value}
                        label={locale.label}
                        onChange={handleChange}
                    >
                        {supportedLocales.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                                {el.label}
                            </MenuItem>
                        ))}
                    </Select> */}
                </div>

                {/* Compensate for fixed header */}
                <div style={{ visibility: 'hidden' }}>
                    <div className="header">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            {backButton()}
                            {tableCode ? tableCodeBackButton() : null}
                        </div>
                        {showGuestName ? renderGuestName(guestName) : null}
                        {showRestartOrder()}
                    </div>
                </div>
            </div>
        );
    }
}

const MSTP = (state) => {
    return { state };
};

const EnhancedHeader = withTranslation()(withTheme(connect(MSTP)(Header)));

export default EnhancedHeader;