import * as TYPES from './TYPES';
import { Themes } from '../themes/theming';

const INITIAL_STATE = {
    table_id: ['', '', '', ''],
    // flow: table code entered
    table_id_error: '',

    // Restaurant details
    restaurant_info: {
        name: '', //"Uncle Joe's Family Restaurant",
        logo: '/assets/mentumqrlogo.png', //"http://www.unclejoesfamilyrestaurant.com/logo.png",
        address: '', //"124 Markham Road",
        restaurant_id: '',
    },
    is_active: false,
    theme: Themes.Default,

    menu_only_mode: false,

    // flow: user has entered a valid table
    guests_list: [],
    is_guest_selected: false,

    order_details: [],

    is_loading: false,

    show_health_and_safety: true,

    version: 1,

    language: {
        label: 'English',
        value: 'en',
        emoji: '🇬🇧',
    },

    menuTranslations: {},
};

const MainReducer = (state = INITIAL_STATE, action) => {
    let copy = { ...state }; // copy state

    switch (action.type) {
        case TYPES.VERSION_UPDATED:
            copy.version = action.payload;
            return copy;
        case TYPES.TOGGLE_HEALTH_AND_SAFETY:
            copy.show_health_and_safety = action.payload;
            return copy;
        case TYPES.MENU_ONLY_MODE_CHANGED:
            copy.menu_only_mode = action.payload;
            return copy;
        case TYPES.THEME_UPDATED:
            copy.theme = action.payload;
            return copy;
        case TYPES.RESTAURANT_STATE_UPDATED:
            copy.is_active = action.payload;
            return copy;
        case TYPES.RESTAURANT_INFO_UPDATED:
            copy.restaurant_info = action.payload;
            return copy;
        case TYPES.LOADING_STATUS_UPDATED:
            copy.is_loading = action.payload;
            return copy;
        case TYPES.RESET_STATE:
            return INITIAL_STATE;
        case TYPES.table_id_ERROR_OCCURED:
            copy.table_id = INITIAL_STATE.table_id; // reset table code
            copy.table_id_error = action.payload.error; // show error
            return copy;

        case TYPES.table_id_UPDATED:
            // Table Code was changed
            copy.table_id = action.payload.table_id;
            return copy;

        case TYPES.table_id_SUCCEEDED:
            copy.table_id_error = '';
            return copy;

        case TYPES.SET_MENU_TRANSLATIONS:
            copy.menuTranslations = action.payload;
            return copy;

        case TYPES.SET_LANGUAGE:
            copy.language = action.payload;
            return copy;

        default:
            return state;
    }
};

export default MainReducer;
