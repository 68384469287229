import React from 'react';

import Header from '../components/Header';
import { connect } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faThumbsUp as farThumbsUp,
    faThumbsDown as farThumbsDown,
} from '@fortawesome/free-regular-svg-icons';
import {
    faThumbsUp as fasThumbsUp,
    faThumbsDown as fasThumbsDown,
} from '@fortawesome/free-solid-svg-icons';

import './css/YourOrder.css';
import { withTheme } from '../themes/theming';
import PaySummary from './Payment/PaySummary';

import * as OrderActions from '../actions/orderActions';
import { withTranslation } from 'react-i18next';
import { combineItemsById } from '../utils';

library.add(farThumbsUp, farThumbsDown, fasThumbsUp, fasThumbsDown);

export class YourOrderComponent extends React.Component {
    renderModifiers(modifiers, itemModifierGroup) {
        if (!Array.isArray(modifiers)) {
            return;
        }

        const groupedModifiers = {};

        // Group modifiers by modifier group name
        modifiers.forEach((modifier) => {
            const modifierGroupItem = itemModifierGroup.find(
                (item) =>
                    item.modifier_group.modifier_group_id ===
                    modifier.modifier_group_id
            );

            const groupName = modifierGroupItem.modifier_group.name;

            if (!groupedModifiers[groupName]) {
                groupedModifiers[groupName] = [];
            }

            groupedModifiers[groupName].push(modifier);
        });

        return Object.keys(groupedModifiers).map((groupName, groupIndex) => (
            <div key={groupIndex}>
                {combineItemsById(groupedModifiers[groupName], 'name').map(
                    (modifier, modifierIndex) => {
                        const modifierPrice = modifier.price
                            ? `+${modifier.price.toFixed(2)}`
                            : '';

                        return (
                            <div
                                className="yourOrderItemModifier"
                                key={`${modifier.modifier_id}-${modifierIndex}`}
                            >
                                +{modifier.count} {modifier.name}{' '}
                                {modifierPrice}
                            </div>
                        );
                    }
                )}
            </div>
        ));
    }

    renderItems(items, showRatings = false) {
        if (!Array.isArray(items)) {
            return;
        }

        return items.map((itemInstance) => {
            if (itemInstance.campaign_instance_id) {
                return null;
            }
            let { item } = itemInstance;

            // check if multiplier exists
            if (!itemInstance.multiplier) {
                itemInstance.multiplier = 1;
            }

            let priceWithModifiers = 0;
            if (item.price) {
                priceWithModifiers = item.price;
            }
            if (itemInstance.modifiers) {
                itemInstance.modifiers.forEach((modifier) => {
                    if (modifier.price) {
                        priceWithModifiers += parseFloat(modifier.price);
                    }
                });
            }

            const hasPaid = itemInstance.has_paid === 'PAYMENT_PAID_ONLINE';

            const renderMultiplier =
                itemInstance.multiplier !== 1
                    ? `(${itemInstance.multiplier})`
                    : '';
            let price;
            price = (priceWithModifiers * itemInstance.multiplier).toFixed(2);
            const thumbsUpStyle =
                this.state && this.state[item.item_id] === 1 ? 'fas' : 'far';
            const thumbsDownStyle =
                this.state && this.state[item.item_id] === -1 ? 'fas' : 'far';

            return (
                <div
                    key={item.item_instance_id}
                    className="yourOrderItemContainer"
                >
                    <div className="yourOrderItemRow">
                        <div className="yourOrderItemLabel">
                            {item.name} {renderMultiplier}{' '}
                            {hasPaid ? <b>(Paid)</b> : ''}
                            {showRatings ? (
                                <>
                                    <div
                                        className="thumbIcon thumbsUp"
                                        onClick={(e) => {
                                            this.props.updateRestaurantRating(
                                                1,
                                                item.item_id
                                            );
                                            this.setState({
                                                [item.item_id]: 1,
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={[thumbsUpStyle, 'thumbs-up']}
                                        />
                                    </div>
                                    <div
                                        className="thumbIcon thumbsDown"
                                        onClick={(e) => {
                                            this.props.updateRestaurantRating(
                                                -1,
                                                item.item_id
                                            );

                                            this.setState({
                                                [item.item_id]: -1,
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={[
                                                thumbsDownStyle,
                                                'thumbs-down',
                                            ]}
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="yourOrderItemPrice">{price}</div>
                    </div>
                    <div className="yourOrderItemModifier">
                        {itemInstance.special_instructions
                            ? `${this.props.t('yourOrder.notes')}: ${
                                  itemInstance.special_instructions
                              }`
                            : ''}
                    </div>
                    {this.renderModifiers(
                        itemInstance.modifiers,
                        itemInstance.item.item_modifier_groups
                    )}
                </div>
            );
        });
    }

    renderPAGCampaigns = () => {
        const items = this.props.state.table.guest_orders.items;
        if (!this.props.state.table.guest_orders.campaigns) {
            return <></>;
        }
        const PAGCampaignInstances =
            this.props.state.table.guest_orders.campaigns.filter(
                (instance) =>
                    instance.campaign.campaign_type ===
                    'CAMPAIGN_PURCHASE_AND_GET'
            );
        return PAGCampaignInstances.map((campaign_instance) => {
            //get all items in this PAG campaign
            const PAGItems = [];
            items.forEach((item) => {
                if (
                    item.campaign_instance_id ===
                    campaign_instance.campaign_instance_id
                ) {
                    PAGItems.push(item);
                }
            });
            //calculate total price of this PAG campaign
            let totalPrice = 0;
            PAGItems.forEach((item) => (totalPrice += item.amount));
            const discountedPrice =
                totalPrice - (campaign_instance.discount || 0);

            const PAGItemComponents = PAGItems.map((item, index) => (
                <div
                    key={`${campaign_instance.campaign_instance_id}_item_${index}`}
                    className="PAGItem"
                >
                    {item.item.name}
                </div>
            ));

            return (
                <div
                    key={campaign_instance.campaign_instance_id}
                    className="yourOrderItemContainer"
                >
                    <div className="yourOrderItemRow">
                        <div className="yourOrderItemLabel">
                            {campaign_instance.campaign.name}
                            {PAGItemComponents}
                        </div>
                        <div className="yourOrderItemPriceContainer">
                            <div className="yourOrderItemPrice originalPrice">
                                {(totalPrice / 100).toFixed(2)}
                            </div>
                            <div className="yourOrderItemPrice">
                                {(discountedPrice / 100).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    renderSAGRewardItems = () => {
        const itemInstances = this.props.state.table.guest_orders.items;
        const SAGCampaignInstances = (
            this.props.state.table.guest_orders.campaigns || []
        ).filter(
            (instance) =>
                instance.campaign.campaign_type === 'CAMPAIGN_SPEND_AND_GET'
        );
        let rewardItemInstances = [];
        SAGCampaignInstances.forEach((instance) => {
            itemInstances.forEach((item) => {
                if (
                    item.campaign_instance_id === instance.campaign_instance_id
                ) {
                    rewardItemInstances.push(item);
                }
            });
        });

        return rewardItemInstances.map((itemInstance) => {
            let discountedPrice = 'Free';
            if (itemInstance.amount / 100 !== itemInstance.item.price) {
                discountedPrice =
                    itemInstance.amount / 100 - itemInstance.item.price;
            }

            return (
                <div
                    key={itemInstance.item_instance_id}
                    className="yourOrderItemContainer"
                >
                    <div className="yourOrderItemRow">
                        <div className="yourOrderItemLabel">
                            {itemInstance.item.name}
                        </div>
                        <div className="yourOrderItemPriceContainer">
                            <div className="yourOrderItemPrice originalPrice">
                                {(itemInstance.amount / 100).toFixed(2)}
                            </div>
                            <div className="yourOrderItemPrice">
                                {discountedPrice}
                            </div>
                        </div>
                    </div>
                    <div className="yourOrderItemModifier">
                        {itemInstance.item.special_instructions
                            ? `${this.props.t('yourOrder.notes')}: ${
                                  itemInstance.special_instructions
                              }`
                            : ''}
                    </div>
                    {this.renderModifiers(itemInstance.modifiers)}
                </div>
            );
        });
    };

    pay() {
        const order = this.props.state.order.temporary_order;
        const tip = this.props.state.order.payment_tip;
        const delivery_tip =
            this.props.state.order.payment_totals.delivery_tip_amount;
        this.props.getPaymentIntent_PayLater(this.props.history);
    }

    render() {
        const theme = this.props.theme;
        const items = this.props.state.table.guest_orders.items;
        const restaurant = this.props.state.main.restaurant_info;

        // for displaying payment options
        const displayPayment = [
            'RESTAURANT_SETTING_DINE_IN_ONLY',
            'RESTAURANT_SETTING_TAKE_OUT_AND_DINE_IN',
        ].includes(restaurant.stripe_payments);

        // For displaying price in pay button
        const paymentTotal =
            this.props.state.order.payment_totals.total_with_tip_and_tax;

        return (
            <div>
                <Header
                    history={this.props.history}
                    showGuestName
                    back="/guesthome"
                />
                <div className="yourOrderContainer">
                    <div className="yourOrderTitle" style={theme.orderTitle}>
                        {this.props.t('yourOrder.yourOrder')}
                    </div>
                    <div
                        className="yourOrderDescription"
                        style={theme.orderDescription}
                    >
                        {this.props.t('yourOrder.makeChangesNote')}
                    </div>

                    {this.renderItems(items)}
                    {this.renderPAGCampaigns()}
                    {this.renderSAGRewardItems()}

                    {displayPayment && paymentTotal > 0 ? (
                        <PaySummary order={items} isItemInstance></PaySummary>
                    ) : (
                        ''
                    )}
                </div>

                <div style={{ height: '100px' }}></div>

                {displayPayment && paymentTotal > 0 ? (
                    <div>
                        <div
                            className="floatingButton"
                            onClick={(e) => this.pay()}
                            style={theme.floatingButton}
                        >
                            <div
                                className="floatingButtonLabel"
                                style={theme.floatingButtonLabel}
                            >
                                {this.props.t('yourOrder.pay')}{' '}
                                {paymentTotal.toFixed(2)}
                            </div>
                            <div className="floatingButtonRight">
                                <img
                                    alt="Arrow"
                                    src="/assets/thin-right-arrow-white.png"
                                    className="floatButtonRightArrow"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className="floatingButtonAlt floatingButtonAltLow"
                        onClick={(e) => this.props.history.goBack()}
                    >
                        <div
                            className="floatingButtonLabelAlt"
                            style={theme.floatingButtonLabel}
                        >
                            {this.props.t('yourOrder.goBack')}
                        </div>
                        <div className="floatingButtonRight">
                            <img
                                alt="Arrow"
                                src="/assets/thin-left-arrow.svg"
                                className="floatButtonRightArrow"
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { state };
};

export const YourOrder = withTranslation()(
    withTheme(
        connect(mapStateToProps, {
            getPaymentIntent_PayLater: OrderActions.getPaymentIntent_PayLater,
            updateRestaurantRating: OrderActions.updateRestaurantRating,
        })(YourOrderComponent)
    )
);
