import React from 'react';
import { connect } from 'react-redux';

import './css/TabletOrderHome.css';
import * as OrderActions from '../../actions/orderActions';
import './css/Kiosk.css';
import { isDevEnvironment } from '../../utils';
import SelectReaderTerminal from './tablet-routes/SelectReaderTerminal';
import { Route, Switch } from 'react-router-dom';
import PaymentReader from './tablet-routes/PaymentReader';
import KioskItemDetail from './tablet-routes/KioskItemDetail';
import OrderSummary from './tablet-routes/OrderSummary';
import PaymentSelect from './tablet-routes/PaymentSelect';
import OrderComplete from './tablet-routes/OrderComplete';
import PhoneNumberInput from './tablet-routes/PhoneNumberInput';
import BrandSelect from './tablet-routes/BrandSelect';
import KioskBrand from './tablet-routes/KioskBrand';
import IdleTimer from 'react-idle-timer';
import { checkPaymentTerminalConnected } from '../../utils';
import './css/Kiosk.css';
import KioskErrorBoundary from './components/KioskErrorBoundary';
import KioskMaintenanceScreen from './tablet-routes/KioskMaintenanceScreen';
import * as TableActions from '../../actions/tableActions';

class TabletOrderHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showIdlePrompt: false,
            resetCountdown: 11,
            checkNuveiTerminalStatusInverval: null,
        };
    }

    componentDidMount() {
        if (!isDevEnvironment()) {
            document.documentElement.webkitRequestFullScreen();
        }

        if (!isDevEnvironment()) {
            window.oncontextmenu = function (event) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            };
        }

        // periodically check nuvei terminal status
        this.props.loadNuveiTerminals();
        let checkNuveiInterval = setInterval(() => {
            this.props.loadNuveiTerminals();
        }, 10000);
        this.setState({
            checkNuveiTerminalStatusInverval: checkNuveiInterval,
        });

        // Check that guest is not invalidated (due to timeout, cron, etc)
        const guestExists = this.props.state.table.selected_guest;
        const endpoint = this.props.state.main.restaurant_info.endpoint;
        if (!guestExists) {
            this.props.loadStandingTable(
                endpoint,
                this.props.history,
                null,
                false
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.checkNuveiTerminalStatusInverval);
    }

    restartResetCountdown() {
        this.setState({ resetCountdown: 10 });
    }

    onIdle() {
        // do not show idle message while in development
        if (isDevEnvironment()) {
            return;
        }

        this.setState({
            showIdlePrompt: true,
        });
        this.restartResetCountdown();

        this.countdown = setInterval(() => {
            const newValue = this.state.resetCountdown - 1;
            if (newValue <= 0) {
                this.endOrder();
            }
            this.setState({
                resetCountdown: newValue,
            });
        }, 1000);
    }

    continueWithOrder() {
        if (this.countdown) {
            clearInterval(this.countdown);
        }
        this.setState({ showIdlePrompt: false });
    }

    endOrder() {
        // clear countdown interval
        if (this.countdown) {
            clearInterval(this.countdown);
        }

        const endpoint = this.props.state.main.restaurant_info.endpoint;
        this.props.clearAllOrders();
        this.props.history.push(`/${endpoint}.kiosk`);
    }

    renderIdlePrompt() {
        const endOrderPressed = () => {
            setTimeout(() => {
                this.endOrder();
            }, 350);
        };
        const continueWithOrderPressed = () => {
            setTimeout(() => {
                this.continueWithOrder();
            }, 350);
        };

        return (
            <div
                className={`idlePromptContainer ${
                    !this.state.showIdlePrompt ? 'hide' : ''
                }`}
            >
                <div className="idlePrompt">
                    <p>It looks like you're away. Continue?</p>

                    <section>{this.state.resetCountdown}</section>

                    <div className="idlePromptButtonContainer">
                        <div
                            className="idlePromptButton"
                            onMouseUp={() => endOrderPressed()}
                            onTouchEnd={() => endOrderPressed()}
                        >
                            Start Over
                        </div>
                        <div
                            className="idlePromptButton"
                            onMouseUp={() => continueWithOrderPressed()}
                            onTouchEnd={() => continueWithOrderPressed()}
                        >
                            Continue
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const paymentMethodExists = checkPaymentTerminalConnected(
            this.props.state
        );

        const renderPaymentMethodError = () => {
            if (!paymentMethodExists) {
                return (
                    <div className="paymentMethodError">
                        <div className="paymentMethodErrorCircle"></div>
                        Warning: Payment machine disconnected!
                    </div>
                );
            }
        };

        const noselect = !isDevEnvironment() ? 'noselect' : '';

        return (
            <KioskErrorBoundary>
                <img
                    alt="Mentum Logo"
                    src="https://mentumqr.s3.amazonaws.com/kiosk/mentum-branding-blue.png"
                    className="kiosk-logo"
                />
                <div className={noselect}>
                    <IdleTimer
                        ref={(ref) => (this.idleTimer = ref)}
                        timeout={1000 * 60} // 1 minute idle timeout
                        onIdle={() => this.onIdle()}
                    ></IdleTimer>

                    {this.renderIdlePrompt()}

                    {renderPaymentMethodError()}

                    <Switch>
                        <Route
                            exact
                            path="/kiosk"
                            component={KioskBrand}
                        ></Route>
                        <Route
                            path="/kiosk/brands"
                            component={BrandSelect}
                        ></Route>
                        <Route
                            path="/kiosk/selectReader"
                            component={SelectReaderTerminal}
                        ></Route>
                        <Route
                            path="/kiosk/paymentTerminal"
                            component={PaymentReader}
                        ></Route>
                        <Route
                            path="/kiosk/item"
                            component={KioskItemDetail}
                        ></Route>
                        <Route
                            path="/kiosk/cart"
                            component={OrderSummary}
                        ></Route>
                        <Route
                            path="/kiosk/paymentSelect"
                            component={PaymentSelect}
                        ></Route>
                        <Route
                            path="/kiosk/paymentComplete"
                            component={OrderComplete}
                        ></Route>
                        <Route
                            path="/kiosk/submitPhoneNumber"
                            component={PhoneNumberInput}
                        ></Route>
                        <Route
                            path="/kiosk/maintenance"
                            component={KioskMaintenanceScreen}
                        ></Route>
                    </Switch>
                </div>
            </KioskErrorBoundary>
        );
    }
}

const mstp = (state) => ({ state });
export default connect(mstp, {
    menuCategorySelected: OrderActions.menuCategorySelected,
    menuSelected: OrderActions.menuSelected,
    menuItemSelected: OrderActions.menuItemSelected,

    loadMenu: OrderActions.loadMenu,
    menuItemModifierSelected: OrderActions.menuItemModifierSelected,
    menuItemSpecialInstructionsChanged:
        OrderActions.menuItemSpecialInstructionsChanged,
    addToOrder: OrderActions.addToOrder,

    increaseItemQuantity: OrderActions.increaseItemQuantity,
    decreaseItemQuantity: OrderActions.decreaseItemQuantity,
    deleteItem: OrderActions.deleteItem,

    payAtCounter: OrderActions.payAtCounter,

    loadStandingTable: TableActions.loadStandingTable,

    getPaymentIntent_PayBefore: OrderActions.getPaymentIntent_PayBefore,

    clearAllOrders: OrderActions.clearAllOrders,

    resetPaymentErrors: OrderActions.resetPaymentErrors,
    loadNuveiTerminals: OrderActions.loadNuveiTerminals,
})(TabletOrderHome);
