/**
 * @enum {string}
 */
export const LocationPickerAction = {
    SELECT_RESTAURANT: 'SELECT_RESTAURANT',
    SELECT_PICKUP: 'SELECT_PICKUP',
    SELECT_DELIVERY: 'SELECT_DELIVERY',
    GET_CURRENT_LOCATION: 'GET_CURRENT_LOCATION',
    SELECT_RADIUS: 'SELECT_RADIUS',
    SET_ADDRESS: 'SET_ADDRESS',
};
