import React from 'react';
import { Link } from 'react-router-dom';
import { SpacingAmount } from '../components/Spacing';
import { Header, Spacing, Integrations, Button, Footer } from '../components';
import './ProductPage.css';
import './LandingV3.css';

export default () => {
    return (
        <div>
            <Header />
            <div className="pdSectionContainer">
                <div className="ProductBackground">
                    <div className="pdHeroSplit">
                        <div className="pdHeroSplitSide">
                            <Spacing amount={SpacingAmount.Spacing60} />
                            <div className="pdHeroTagline">
                                SELF-SERVE KIOSK
                            </div>
                            <div className="pdHeroText">
                                Higher Revenue, Lower Costs
                            </div>
                            <div className="pdHeroDescr">
                                With our highest quality customer support and
                                most advanced ordering systems, your modern QSR
                                brand demands a modern QSR partner.
                            </div>
                            <Button
                                href="/contact"
                                text="Get started with us"
                                showChevron
                            />
                        </div>
                        <div className="pdHeroSplitSide">
                            <Spacing amount={SpacingAmount.Spacing60} />
                            <img
                                src="/assets/landing/v3/productPages/pd-kiosk.png"
                                className="pdHeroImg"
                            />
                        </div>
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="ProductSplitViewRWM">
                        <div className="ProductSplitViewSide ProductSplitViewRight">
                            <img
                                src="/assets/landing/v3/productPages/higher-revenue-kiosk.png"
                                className="ProductImage"
                            />
                        </div>
                        <div className="ProductSplitViewSide">
                            <div className="v3LandingProductName">Higher revenue</div>
                            <div className="v3Landingv3LandingProductDescription">
                                Our kiosks increase overall revenues by 30% with
                                built-in recommendation engines and upselling
                                algorithms while being the most affordable
                                option on the market.
                            </div>
                            <Link to="/contact">
                                <div className="v3LandingProductLink">
                                    Book a Demo
                                    <img
                                        src="/assets/landing/v3/link-chevron.svg"
                                        className="v3LandingProductChevron"
                                    />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="ProductSplitView">
                        <div className="ProductSplitViewSide">
                            <div className="v3LandingProductName">
                                Reliable and affordable
                            </div>
                            <div className="v3Landingv3LandingProductDescription">
                                {' '}
                                With our network of national support partners,
                                we service or replace in record times to make
                                sure there are no interuptions in business.
                            </div>
                            <Link to="/contact">
                                <div className="v3LandingProductLink">
                                    Book a Demo
                                    <img
                                        src="/assets/landing/v3/link-chevron.svg"
                                        className="v3LandingProductChevron"
                                    />
                                </div>
                            </Link>
                        </div>
                        <Spacing amount={SpacingAmount.Spacing38} />
                        <div className="ProductSplitViewSide ProductSplitViewRight">
                            <img
                                src="/assets/landing/v3/productPages/reliable-affordable-kiosk.png"
                                className="ProductImage"
                            />
                        </div>
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="ProductSplitViewRWM">
                        <div className="ProductSplitViewSide ProductSplitViewRight">
                            <img
                                src="/assets/landing/v3/productPages/efficient-labour-kiosk.png"
                                className="ProductImage"
                            />
                        </div>
                        <div className="ProductSplitViewSide">
                            <div className="v3LandingProductName">
                                More efficient labour
                            </div>
                            <div className="v3Landingv3LandingProductDescription">
                                {' '}
                                Handle higher volumes of orders with more
                                efficient labor management in your operation to
                                push a higher overall quality of service.
                            </div>
                            <Link to="/contact">
                                <div className="v3LandingProductLink">
                                    Book a Demo
                                    <img
                                        src="/assets/landing/v3/link-chevron.svg"
                                        className="v3LandingProductChevron"
                                    />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="v3ContentContainer">
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="v3CenteredHeader">POS Integrations</div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="v3CenteredDescription">
                        Handle higher volumes of orders with more efficient
                        labor management in your operation to push a higher
                        overall quality of service.
                    </div>
                    <Integrations />
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="v3CenteredHeader">
                        Check out our other products
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="CheckoutOthersContainer">
                        <div className="CheckoutContent">
                            <Link to="/product/qr" style={{ color: 'inherit' }}>
                                <img
                                    src="/assets/landing/v3/productPages/checkout-qr.png"
                                    className="CheckoutImg"
                                />
                                <div className="CheckoutTag">
                                    QR Dine-in & Pay
                                </div>
                                <div className="CheckoutDescrp">
                                    Future proof your store with the industries
                                    most seamless digital ordering tool for your
                                    QSR, Hotel, Bar, Club, or Food Truck with
                                    take away ordering.
                                </div>
                            </Link>
                        </div>
                        <div className="CheckoutContent">
                            <Link
                                to="/product/delivery"
                                style={{ color: 'inherit' }}
                            >
                                <img
                                    src="/assets/landing/v3/productPages/checkout-d.png"
                                    className="CheckoutImg"
                                />
                                <div className="CheckoutTag">
                                    Delivery Aggregation
                                </div>
                                <div className="CheckoutDescrp">
                                    The most customer and waiter centric table
                                    and hotel ordering system in the industry.
                                    Order, call for the waiter, and securely pay
                                    digitally.{' '}
                                </div>
                            </Link>
                        </div>
                        <div className="CheckoutContent">
                            <Link
                                to="/product/dinein"
                                style={{ color: 'inherit' }}
                            >
                                <img
                                    src="/assets/landing/v3/productPages/checkout-dinein.png"
                                    className="CheckoutImg"
                                />
                                <div className="CheckoutTag">
                                    Dine-in Ordering
                                </div>
                                <div className="CheckoutDescrp">
                                    Instantly edit menus on all your delivery
                                    platforms, change stock status, and get your
                                    analytics all from a single dashboard or
                                    integrate directly into your POS.
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <Spacing amount={SpacingAmount.Spacing60} />

                <div className="v3ReadyToGetStartedContainer ReadySectionBackground">
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="v3ReadyHeader">Ready to get started?</div>
                    <Spacing amount={SpacingAmount.Spacing38} />

                    <div className="v3CenteredDescription">
                        Get in touch and set up a free demo of how our products
                        can future proof your operations.
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Link to="/contact">
                        <Button text="Book a Demo" />
                    </Link>
                    <Spacing amount={SpacingAmount.Spacing128} />
                    <Spacing amount={SpacingAmount.Spacing38} />
                </div>
            </div>
            <Footer />
        </div>
    );
};
