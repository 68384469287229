import React from 'react';
import { connect } from 'react-redux';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import Header from '../../components/Header';
import * as TableActions from '../../actions/tableActions';

import { withTheme } from '../../themes/theming';
import { PaymentType } from '../../reducers/TYPES';

import { isValidPhoneNumber } from 'react-phone-number-input';
import { isHotel } from '../../utils';
import { toast } from 'react-toastify';
import { i18n } from '../../locales';
import { withTranslation } from 'react-i18next';

class PaymentComplete extends React.Component {
    goHome() {
        this.props.history.push('/guesthome');
    }

    constructor(props) {
        super(props);
        this.state = {
            showPhoneInput: false,
            phoneNumberAdded: true, // default to automatically send a text (due to 2fa, or through dine-in)
        };
    }

    componentDidMount() {
        // check if phone number is already submitted
        const phone_num =
            this.props.state.table.selected_guest.phone_number || '';
        if (!isValidPhoneNumber(phone_num)) {
            this.setState({
                showPhoneInput: true,
            });
        }

        // tablet endpoint show the phone # sms input field
        if (this.props.state.table.is_tablet) {
            this.setState({
                phoneNumberAdded: false,
                showPhoneInput: true,
            });
        }
    }

    componentWillUnmount() {
        toast.dismiss('orderPlacedToast');
    }

    submitPhoneNumber() {
        this.props.submitPhoneNumber();
        this.setState({
            phoneNumberAdded: true,
        });
    }

    startNewOrder() {
        if (this.props.state.table.is_tablet) {
            const endpoint = this.props.state.table.tablet_endpoint;
            this.props.resetState();
            this.props.history.push(`/${endpoint}`);
        }
    }

    renderPhoneNumber() {
        if (!this.state.showPhoneInput) {
            return;
        }

        let textReceiptTitle = this.props.t(
                'paymentComplete.wantReceipt'
            );
        if (this.props.state.order.payment_type === PaymentType.PAY_IMMEDIATE) {
            textReceiptTitle = this.props.t(
                'paymentComplete.wantReceipt'
            );
        }
        let textReceiptDescription = '';
        if (this.props.state.order.payment_type === PaymentType.PAY_IMMEDIATE) {
            textReceiptDescription = this.props.t(
                'paymentComplete.enterPhoneNumber'
            );
        }

        const default_country =
            this.props.state.main.restaurant_info.restaurant_id ===
            '2993daf5-c798-11ea-b545-02685a4295ea'
                ? 'US'
                : 'CA';

        return (
            <div className="textReceiptContainer">
                {/* <div className="textReceiptNewLabel">New!</div> */}
                <div className="textReceiptTitle">{textReceiptTitle}</div>
                <div className="textReceiptDescription">
                    {textReceiptDescription}
                </div>

                {this.state.phoneNumberAdded ? (
                    <div>
                        <PhoneInput
                            placeholder="(647) 000-0000"
                            defaultCountry={default_country}
                            value={this.props.table.selected_guest.phone_number}
                            onChange={this.props.updatePhoneNumber}
                            disabled={true}
                        />

                        <div
                            className={`textReceiptSubmit textReceiptSubmitDisabled`}
                        >
                            <div className="textReceiptLabel">
                                {this.props.t('paymentComplete.sent')}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <PhoneInput
                            placeholder="(647) 000-0000"
                            defaultCountry="CA"
                            value={this.props.table.selected_guest.phone_number}
                            onChange={this.props.updatePhoneNumber}
                        />

                        <div
                            className={`textReceiptSubmit`}
                            onClick={(e) => {
                                this.submitPhoneNumber();
                            }}
                        >
                            <div className="textReceiptLabel">
                                {this.props.t('common.submit')}
                                {}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderThankYou() {
        const theme = this.props.theme;
        const table_id_arr = this.props.state.main.table_id;
        const table_id = table_id_arr.map((i) => i);
        const is_tablet = this.props.state.table.is_tablet;
        const order_time_str = this.props.state.table.selected_guest.order_time;

        let order_time = '';
        if (order_time_str) {
            let date = new Date(order_time_str);
            order_time = `${date.toLocaleString(i18n.language, {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })}`;
        }

        const has_paid =
            this.props.state.order.payment_type === PaymentType.PAY_IMMEDIATE;

        return (
            <div
                className="paymentCompleteContainer"
                style={theme.paymentCompleteContainer}
            >
                <div
                    className="paymentCompleteThankYou"
                    style={theme.paymentCompleteThankYou}
                >
                    {this.props.t('paymentComplete.thankYouForOrder')}
                </div>

                {has_paid && order_time_str ? (
                    <>
                        <div className="paymentCompleteOrderReadyTime">
                            {this.props.t('paymentComplete.orderReadyAt')}&nbsp;
                            {order_time}
                        </div>
                    </>
                ) : (
                    <></>
                )}

                {isHotel() ? (
                    <div className="hiltonCompleteMsg">
                        {this.props.t(
                            'paymentComplete.orderPlacedNotification'
                        )}{' '}
                        <span className="hilton-complete-msg-bold">
                            {this.props.t('paymentComplete.restaurantName')}
                        </span>{' '}
                        {this.props.t(
                            'paymentComplete.pickupLocationNotification'
                        )}
                    </div>
                ) : (
                    ''
                )}

                {has_paid ? (
                    <>
                        <div
                            className="paymentCompleteDescription"
                            style={theme.paymentCompleteDescription}
                        >
                            {this.props.t('paymentComplete.yourOrderNumber')}
                        </div>
                        <div
                            className="paymentCompleteCodeBold"
                            style={theme.orderCodeBold}
                        >
                            {table_id}
                        </div>
                    </>
                ) : (
                    ''
                )}

                {is_tablet ? (
                    <div>
                        <div
                            className="startNewOrderButton"
                            onClick={(e) => {
                                this.startNewOrder();
                            }}
                        >
                            {this.props.t('paymentComplete.startNewOrder')}
                        </div>
                    </div>
                ) : (
                    <>
                        <div
                            className="twoFA-button"
                            onClick={(e) =>
                                this.props.history.push('/guesthome')
                            }
                        >
                            {this.props.t('paymentComplete.viewMyOrder')}
                        </div>
                        {/* <LinkedButton text="View My Order" onClick={e => {
                        this.props.history.push('/guesthome')
                    }} style={{ marginTop: "40px", marginBottom: "10px" }} /> */}
                    </>
                )}
            </div>
        );
    }

    render() {
        const is_tablet = this.props.state.table.is_tablet;

        return (
            <div>
                <Header history={this.props.history} />

                <div className="textReceiptAndPaymentComplete">
                    {is_tablet ? ( // reverse thank you direction for tablet
                        <>
                            {this.renderThankYou()}
                            {this.renderPhoneNumber()}
                        </>
                    ) : (
                        <>
                            {this.renderPhoneNumber()}
                            {this.renderThankYou()}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    main: state.main,
    table: state.table,
    state,
});

const actions = {
    updatePhoneNumber: TableActions.updatePhoneNumber,
    submitPhoneNumber: TableActions.submitPhoneNumber,
    resetState: TableActions.resetState,
};

export default withTranslation()(
    withTheme(connect(mapStateToProps, actions)(PaymentComplete))
);
