import {
    AppBar,
    Box,
    CircularProgress,
    Container,
    IconButton,
    Toolbar,
    Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createPath } from '../utils';
import './css/CampaignDetails.css';
import { i18n } from '../../../locales';
import { useTranslation } from 'react-i18next';
import { loadCampaigns } from '../../../actions/orderActions';

const SpendAndGet = (props) => {
    const { campaign } = props;
    const { discount_type } = campaign.discount;
    let message = '';
    if (
        discount_type === 'DISCOUNT_ITEM' ||
        discount_type === 'DISCOUNT_SPECIAL_PRICE'
    ) {
        message = i18n.t('campaign.promoItemsAutoAdded');
    } else if (
        discount_type === 'DISCOUNT_CENT' ||
        discount_type === 'DISCOUNT_PERCENT'
    ) {
        message = i18n.t('campaign.autoAddToCart');
    }
    return <div className="v2CampaignDetailText">{message}</div>;
};

export default (props) => {
    const history = useHistory();
    const params = useParams();
    const { t } = useTranslation();
    const goBack = () => {
        history.push(createPath('/v2/:/order/featured', [params.endpoint]));
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadCampaigns());
    }, [dispatch]);

    // check params
    if (!params.campaign_id) {
        history.push('/404');
    }

    let campaigns = useSelector((state) => state.order.menu.campaigns);
    let campaign = {};
    if (campaigns) {
        const result = campaigns.filter(
            (c) => c.campaign_id === params.campaign_id
        );
        if (result.length === 1) {
            campaign = result[0];
        }
    } else {
        return <CircularProgress />;
    }

    let renderedCampaign = null;
    switch (campaign.campaign_type) {
        case 'CAMPAIGN_SPEND_AND_GET':
            renderedCampaign = <SpendAndGet campaign={campaign} />;
            break;
        case 'CAMPAIGN_PURCHASE_AND_GET':
            renderedCampaign = <SpendAndGet campaign={campaign} />;
            break;
        case 'CAMPAIGN_DISCOUNTED_ENTITY':
            renderedCampaign = <SpendAndGet campaign={campaign} />;
            break;
        default:
            break;
    }

    return (
        <>
            <AppBar position="sticky" color="transparent">
                <Toolbar>
                    <IconButton edge="start" onClick={() => goBack()}>
                        <FiChevronLeft color="#121212" />
                    </IconButton>
                    <Typography variant="h6">{t('common.promo')}</Typography>
                </Toolbar>
            </AppBar>
            <Container fixed>
                <Box m={2}>
                    <div className="v2CampaignDetailsName">{campaign.name}</div>
                    {renderedCampaign}
                </Box>
            </Container>
        </>
    );
};
