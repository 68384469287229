import React from 'react';
import { connect } from 'react-redux';
import * as OrderActions from '../../../actions/orderActions';
import stripeTerminal from '../../../StripeTerminal';
import * as Types from '../../../reducers/TYPES';
import { withTranslation } from 'react-i18next';

class PaymentSelect extends React.Component {
    render() {
        const payAtCounter = () => {
            this.props.payAtCounter(null, false, false);
            this.props.history.push({
                pathname: '/kiosk/paymentComplete',
                state: { isPayAtCounter: true },
            });
        };

        const isPayAtCounterEnabled = [
            'RESTAURANT_SETTING_TAKE_OUT_ONLY',
            'RESTAURANT_SETTING_TAKE_OUT_AND_DINE_IN',
        ].includes(this.props.state.main.restaurant_info.pay_at_counter);

        const isNuveiConnected =
            this.props.state.kiosk.nuvei_selected_terminal !== {};
        const isReaderConnected =
            stripeTerminal.isReaderConnected() || isNuveiConnected;

        const isNuvei =
            this.props.state.kiosk.terminal_vendor ===
            Types.TerminalVendors.NUVEI;

        const payWithTerminal = () => {
            // check if nuvei or stripe
            const terminal_vendor = this.props.state.kiosk.terminal_vendor;
            switch (terminal_vendor) {
                case Types.TerminalVendors.STRIPE:
                    this.props.getPaymentIntent_PayBefore(null, true);
                    this.props.history.push('/kiosk/paymentTerminal');
                    break;
                case Types.TerminalVendors.NUVEI:
                    this.props.getPaymentIntent_PayBefore(null, true);
                    this.props.history.push('/kiosk/paymentTerminal');
                    break;
                default:
                    console.error('Invalid terminal vendor');
            }
        };

        return (
            <div className="to-page-container to-page-container-dark">
                <div className="to-page-inner-container to-page-inner-container-dark">
                    <img
                        alt=""
                        src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                        className="to-page-review-logo"
                    ></img>
                    <div className="to-page-review-heading">
                        {this.props.t('common.howToPay')}
                    </div>

                    <div className="to-page-payment-options-container">
                        {isReaderConnected ? (
                            <div
                                className="to-page-payment-option"
                                onClick={() => payWithTerminal()}
                            >
                                <img
                                    alt=""
                                    src="https://mentumqr.s3.amazonaws.com/misc/payment-types.png"
                                    className="to-page-payment-option-image"
                                />
                                <div className="to-page-payment-option-label">
                                    {this.props.t('common.payHere')}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        {isPayAtCounterEnabled ? (
                            <>
                                <div
                                    className="to-page-payment-option"
                                    onClick={() => payAtCounter()}
                                >
                                    <img
                                        alt=""
                                        src="https://mentumqr.s3.amazonaws.com/misc/Group+2+(2).png"
                                        className="to-page-payment-option-image"
                                    />
                                    <div className="to-page-payment-option-label">
                                        {this.props.t(
                                            'order.payAtCounterOption'
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>

                    {isNuvei ? (
                        <div className="to-page-review-nuvei-warning">
                            {this.props.t('order.amexNotSupportedNotice')}
                        </div>
                    ) : (
                        <></>
                    )}

                    <div
                        className="to-page-payment-back-button"
                        onTouchEnd={(e) => {
                            this.props.history.push('/kiosk/cart');
                        }}
                        onMouseUp={(e) => {
                            this.props.history.push('/kiosk/cart');
                        }}
                    >
                        {this.props.t('common.cancel')}
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({ state });

export default withTranslation()(
    // Wrap the component with withTranslation
    connect(mstp, {
        updateOrder: OrderActions.updateOrder,
        payAtCounter: OrderActions.payAtCounter,
        getPaymentIntent_PayBefore: OrderActions.getPaymentIntent_PayBefore,
        loadNuveiTerminals: OrderActions.loadNuveiTerminals,
    })(PaymentSelect)
);
