import default_theme from './default';

const theme = {
    ...default_theme,
    // GUEST HOME OPTIONAL LOGO
    optionalHomeHeaderUrl: '/assets/photos/utsav/03.jpg',
    optionalHomeHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    optionalHomeHeaderImg: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: '50% 50%',
    },

    customDineSelect: {
        height: '40px',
        paddingBottom: '0',
        marginBottom: '30px',
    },

    floatingButton: {
        backgroundColor: '#F1592A',
    },

    finalizeAddItemsButton: {
        border: '2px solid #F1592A',
        color: '#F1592A',
    },

    // MUILTIPLIER
    itemMultiplierContainer: {
        borderColor: '#F1592A',
    },
    itemMultiplierLeft: {
        borderRightColor: '#F1592A',
        color: '#F1592A',
    },
    itemMultiplierRight: {
        borderLeftColor: '#F1592A',
        color: '#F1592A',
    },
    itemMultiplierText: {
        color: '#F1592A',
    },
};

export default theme;
