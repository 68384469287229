const ZohoForm = `
<!-- Note :
   - You can modify the font style and form style to suit your website. 
   - Code lines with comments Do not remove this code are required for the form to work properly, make sure that you do not remove these lines of code. 
   - The Mandatory check script can modified as to suit your business needs. 
   - It is important that you test the modified form before going live.-->
<div id='crmWebToEntityForm' class='zcwf_lblLeft crmWebToEntityForm' style='color: black;max-width: 600px;'>
  <meta name='viewport' content='width=device-width, initial-scale=1.0'>
   <META HTTP-EQUIV ='content-type' CONTENT='text/html;charset=UTF-8'>
   <form action='https://crm.zoho.com/crm/WebToContactForm' name=WebToContacts4952413000000570003 method='POST' onSubmit='javascript:document.charset="UTF-8"; return checkMandatory4952413000000570003()' accept-charset='UTF-8'>
 <input type='text' style='display:none;' name='xnQsjsdp' value='06bf10da4c8f8d7fd4f6622174d91619a480597b206a5437f137573ad6f35f72'></input> 
 <input type='hidden' name='zc_gad' id='zc_gad' value=''></input> 
 <input type='text' style='display:none;' name='xmIwtLD' value='c65fafe946011d77bd2ce8fef8261f02c938ed16868638fd35760dbfeb2e26ba'></input> 
 <input type='text'  style='display:none;' name='actionType' value='Q29udGFjdHM='></input>
 <input type='text' style='display:none;' name='returnURL' value='https&#x3a;&#x2f;&#x2f;mentumqr.com&#x2f;contact&#x2f;complete' > </input>
	 <!-- Do not remove this code. -->
	 <input type='text' style='display:none;' id='ldeskuid' name='ldeskuid'></input>
	 <input type='text' style='display:none;' id='LDTuvid' name='LDTuvid'></input>
	 <!-- Do not remove this code. -->
<style>
html,body{
	margin: 0px;
}
#crmWebToEntityForm.zcwf_lblLeft {
	width:100%;
	padding: 48px 60px;
	margin: 0 auto;
	box-sizing: border-box;
}
#crmWebToEntityForm.zcwf_lblLeft * {
	box-sizing: border-box;
}
#crmWebToEntityForm{text-align: left;}
#crmWebToEntityForm * {
	direction: ltr;
}
.zcwf_lblLeft .zcwf_title {
	word-wrap: break-word;
	padding: 0px 6px 10px;
	font-weight: bold;
}
.zcwf_lblLeft .zcwf_col_fld input[type=text], .zcwf_lblLeft .zcwf_col_fld textarea {
    width: 100%;
    height: 38px;
    border: 1px solid #fff !important;
    overflow-wrap: break-word; 
    resize: vertical;
    border-radius: 2px;
    padding-left: 12px;
    float: left;
    box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
    border-radius: 4px;
}
.zcwf_lblLeft .zcwf_col_lab {
	color: rgba(17, 17, 17, 0.48);
    width: auto;
    font-size: 16px;
}
.zcwf_lblLeft .zcwf_col_fld {
	float: left;
    width: 100%;
    padding-top: 6px;
}
.zcwf_lblLeft .zcwf_privacy{padding: 6px;}
.zcwf_lblLeft .wfrm_fld_dpNn{display: none;}
.dIB{display: inline-block;}
.zcwf_lblLeft .zcwf_col_fld_slt {
	width: auto;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    font-size: 12px;
    float: left;
    resize: vertical;
}
.zcwf_lblLeft .zcwf_row:after, .zcwf_lblLeft .zcwf_col_fld:after {
	content: '';
    display: table;
    clear: both;
    padding-left: 10px;
}
.zcwf_lblLeft .zcwf_col_help {
	float: left;
	margin-left: 7px;
	font-size: 12px;
	max-width: 35%;
	word-break: break-word;
}
.zcwf_lblLeft .zcwf_help_icon {
	cursor: pointer;
	width: 16px;
	height: 16px;
	display: inline-block;
	background: #fff;
	border: 1px solid #ccc;
	color: #ccc;
	text-align: center;
	font-size: 11px;
	line-height: 16px;
	font-weight: bold;
	border-radius: 50%;
}
.zcwf_lblLeft .zcwf_row {
    padding-bottom: 14px;
}
.zcwf_lblLeft .zcwf_row {margin: 15px 0px;}
.zcwf_lblLeft .formsubmit {
	margin-right: 5px;
	cursor: pointer;
	color: #333;
	font-size: 12px;
}
.zcwf_lblLeft .zcwf_privacy_txt {
	color: rgb(0, 0, 0);
	font-size: 12px;
	font-family: Arial;
	display: inline-block;
	vertical-align: top;
	color: #333;
	padding-top: 2px;
	margin-left: 6px;
}
.zcwf_lblLeft .zcwf_button {
	font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 3px 9px;
    cursor: pointer;
    width: 120px;
    height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: linear-gradient(119.65deg, #6BBECB 6.31%, #4770DB 35.28%, #4151CC 63.28%, #1B42A7 95.14%);
    border-radius: 5px;
}
.zcwf_lblLeft .zcwf_tooltip_over{
	position: relative;
}
.zcwf_lblLeft .zcwf_tooltip_ctn{
	position: absolute;
	background: #dedede;
	padding: 3px 6px;
	top: 3px;
	border-radius: 4px;word-break: break-all;
	min-width: 50px;
	max-width: 150px;
	color: #333;
}
.zcwf_lblLeft .zcwf_ckbox{
	float: left;
}
.zcwf_lblLeft .zcwf_file{
	width: 55%;
	box-sizing: border-box;
	float: left;
}
.clearB:after{
	content:'';
	display: block;
	clear: both;
}
@media all and (max-width: 850px) {
	.zcwf_lblLeft .zcwf_col_lab {
        width: auto;
        float: none !important;
        color: #fff;
        font-size: 14px;
    }
    .zcwf_lblLeft .zcwf_col_fld {
        width: auto;
        float: none !important;
    }
    .zcwf_lblLeft .zcwf_col_help {width: 40%;}
    #crmWebToEntityForm.zcwf_lblLeft {
        width:100%;
        padding: 8px 10px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .zcwf_lblLeft .zcwf_col_fld input[type=text], .zcwf_lblLeft .zcwf_col_fld textarea {
        width: auto;
        height: 36px;
        margin: auto;
        min-width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
}

</style>
<div class='zcwf_row'><div class='zcwf_col_lab'><label for='Email'>Email<span>*</span></label></div><div class='zcwf_col_fld'><input type='text' ftype='email' id='Email' name='Email' maxlength='100'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab'><label for='Last_Name'>Name<span>*</span></label></div><div class='zcwf_col_fld'><input type='text' id='Last_Name' name='Last Name' maxlength='80'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab'><label for='Title'>Title<span>*</span></label></div><div class='zcwf_col_fld'><input type='text' id='Title' name='Title' maxlength='100'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab'><label for='Phone'>Phone<span>*</span></label></div><div class='zcwf_col_fld'><input type='text' id='Phone' name='Phone' maxlength='50'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab'><label for='Mailing_Zip'>Zip Code<span>*</span></label></div><div class='zcwf_col_fld'><input type='text' id='Mailing_Zip' name='Mailing Zip' maxlength='30'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab'><label for='Mailing_Country'>Country<span>*</span></label></div><div class='zcwf_col_fld'><input type='text' id='Mailing_Country' name='Mailing Country' maxlength='100'></input><div class='zcwf_col_help'></div></div></div>
<div class='zcwf_row'><div class='zcwf_col_lab'><label for='Description'>Message<span>*</span></label></div><div class='zcwf_col_fld'><textarea id='Description' name='Description' style='height: 100px; scroll-padding-top: 12px;'></textarea><div class='zcwf_col_help'></div></div></div><div class='zcwf_row'><div class='zcwf_col_lab'></div><div class='zcwf_col_fld'><input type='submit' id='formsubmit' class='formsubmit zcwf_button' style="margin-right: 10px" value='Submit' title='Submit'></div></div>
	<script>
	function validateEmail4952413000000570003()
	{
		var form = document.forms['WebToContacts4952413000000570003'];
		var emailFld = form.querySelectorAll('[ftype=email]');
		var i;
		for (i = 0; i < emailFld.length; i++)
		{
			var emailVal = emailFld[i].value;
			if((emailVal.replace(/^\s+|\s+$/g, '')).length!=0 )
			{
				var atpos=emailVal.indexOf('@');
				var dotpos=emailVal.lastIndexOf('.');
				if (atpos<1 || dotpos<atpos+2 || dotpos+2>=emailVal.length)
				{
					alert('Please enter a valid email address. ');
					emailFld[i].focus();
					return false;
				}
			}
		}
		return true;
	}

 	  function checkMandatory4952413000000570003() {
		var mndFileds = new Array('Last Name','Email','Title','Phone','Mailing Zip','Mailing Country','Description');
		var fldLangVal = new Array('Name','Email','Title','Phone','Brand','Number\x20of\x20Locations','Message');
		for(i=0;i<mndFileds.length;i++) {
		  var fieldObj=document.forms['WebToContacts4952413000000570003'][mndFileds[i]];
		  if(fieldObj) {
			if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length==0) {
			 if(fieldObj.type =='file')
				{ 
				 alert('Please select a file to upload.'); 
				 fieldObj.focus(); 
				 return false;
				} 
			alert(fldLangVal[i] +' cannot be empty.'); 
   	   	  	  fieldObj.focus();
   	   	  	  return false;
			}  else if(fieldObj.nodeName=='SELECT') {
  	   	   	 if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
				alert(fldLangVal[i] +' cannot be none.'); 
				fieldObj.focus();
				return false;
			   }
			} else if(fieldObj.type =='checkbox'){
 	 	 	 if(fieldObj.checked == false){
				alert('Please accept  '+fldLangVal[i]);
				fieldObj.focus();
				return false;
			   } 
			 } 
			 try {
			     if(fieldObj.name == 'Last Name') {
				name = fieldObj.value;
 	 	 	    }
			} catch (e) {}
		    }
		}
		trackVisitor();
		if(!validateEmail4952413000000570003()){return false;}
		document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
	}

function tooltipShow4952413000000570003(el){
	var tooltip = el.nextElementSibling;
	var tooltipDisplay = tooltip.style.display;
	if(tooltipDisplay == 'none'){
		var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
		for(i=0; i<allTooltip.length; i++){
			allTooltip[i].style.display='none';
		}
		tooltip.style.display = 'block';
	}else{
		tooltip.style.display='none';
	}
}
</script><script type='text/javascript' id='VisitorTracking'>var $zoho= $zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:'c3576549ac0c59376a24b6f21e06327283dc3ba830f1f7a5b30bb7cf3bb783fc1a2010ab7b6727677d37b27582c0e9c4', values:{},ready:function(){}};var d=document;s=d.createElement('script');s.type='text/javascript';s.id='zsiqscript';s.defer=true;s.src='https://salesiq.zoho.com/widget';t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);function trackVisitor(){try{if($zoho){var LDTuvidObj = document.forms['WebToContacts4952413000000570003']['LDTuvid'];if(LDTuvidObj){LDTuvidObj.value = $zoho.salesiq.visitor.uniqueid();}var firstnameObj = document.forms['WebToContacts4952413000000570003']['First Name'];if(firstnameObj){name = firstnameObj.value +' '+name;}$zoho.salesiq.visitor.name(name);var emailObj = document.forms['WebToContacts4952413000000570003']['Email'];if(emailObj){email = emailObj.value;$zoho.salesiq.visitor.email(email);}}} catch(e){}}</script>
	<!-- Do not remove this --- Analytics Tracking code starts --><script id='wf_anal' src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=c65fafe946011d77bd2ce8fef8261f02c938ed16868638fd35760dbfeb2e26bagid06bf10da4c8f8d7fd4f6622174d91619a480597b206a5437f137573ad6f35f72gid5cf99212d7958dbcd176c761c6ce0a4d7e43e277bdc5d8b9c12010015418942cgid14f4ec16431e0686150daa43f3210513'></script><!-- Do not remove this --- Analytics Tracking code ends. --></form>
</div>`;

export default ZohoForm;
