import React from 'react';
import { connect } from 'react-redux';
import * as OrderActions from '../../actions/orderActions';
import { withTheme } from '../../themes/theming';
import { toast } from 'react-toastify';
import './css/CampaignItemDetail.css';
import { scroller } from 'react-scroll';
import { isEthos } from '../../utils';
import { Button } from '@material-ui/core';
import { i18n } from '../../locales';
import { withTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../endpoints';

class CampaignItemDetail extends React.Component {
    modifierSelected(modifier, is_multiple_selection_allowed) {
        toast.dismiss(modifier.modifier_group_id);
        this.props.menuItemModifierSelected(
            modifier,
            is_multiple_selection_allowed
        );
    }

    isModifierChecked(modifier) {
        const modifiers_enabled =
            this.props.state.order.selected_item_modifiers;
        return (
            modifiers_enabled.filter(
                (m) => modifier.modifier_id === m.modifier_id
            ).length === 1
        );
    }

    renderModifierGroups(item) {
        const modifiers = this.props.state.order.menu.modifiers;

        const modifierGroups = item.item_modifier_groups;
        if (!Array.isArray(modifierGroups)) {
            return;
        }

        return (
            <div
                className="campaignItemModifierGroupContainer"
                id="campaignItemModifierGroupContainer"
            >
                {modifierGroups.map((modGroupObject, index) => {
                    let modGroupRequired = modGroupObject.is_required;
                    if (!modGroupRequired) {
                        modGroupRequired = false;
                    }
                    const modGroup = modGroupObject.modifier_group;
                    const group_modifiers = modifiers.filter((mod) => {
                        return (
                            mod.modifier_group_id === modGroup.modifier_group_id
                        );
                    });

                    let modifier_group_name = modGroup.name;
                    const dot_index = modifier_group_name.indexOf('.');
                    if (dot_index !== -1) {
                        // check if index before dot is a number
                        if (
                            !isNaN(
                                parseInt(
                                    modifier_group_name.slice(
                                        dot_index - 1,
                                        dot_index
                                    )
                                )
                            ) &&
                            modifier_group_name.slice(
                                dot_index + 1,
                                dot_index + 2
                            ) === ' '
                        ) {
                            modifier_group_name = modifier_group_name.slice(
                                dot_index + 2,
                                modifier_group_name.length
                            );
                        }
                    }

                    return (
                        <div
                            key={modGroup.modifier_group_id}
                            id={modGroup.modifier_group_id}
                            className="ModifierGroupsContainer"
                        >
                            <div className="campaignItemModifierGroupName">
                                {modifier_group_name}
                                <span className="campaignItemModifierRequiredAccent">
                                    {modGroupRequired ? ' *' : ''}
                                </span>
                            </div>
                            {group_modifiers.map((modifier) => {
                                let render;
                                let modifier_name = modifier.name;
                                const mod_dot_index =
                                    modifier_name.indexOf('.');
                                if (mod_dot_index !== -1) {
                                    if (
                                        !isNaN(
                                            parseInt(
                                                modifier_name.slice(
                                                    mod_dot_index - 1,
                                                    mod_dot_index
                                                )
                                            )
                                        ) &&
                                        modifier_name.slice(
                                            mod_dot_index + 1,
                                            mod_dot_index + 2
                                        ) === ' '
                                    ) {
                                        modifier_name = modifier_name.slice(
                                            mod_dot_index + 2,
                                            modifier_name.length
                                        );
                                    }
                                }

                                const is_checked = this.isModifierChecked(
                                    modifier
                                )
                                    ? 'itemModifierRadioSelected'
                                    : '';

                                if (modGroup.is_multiple_selection_allowed) {
                                    render = (
                                        <div
                                            className={`campaignItemModifierRadioRow ${is_checked}`}
                                            key={modifier.modifier_id}
                                            onClick={(e) => {
                                                this.modifierSelected(
                                                    modifier,
                                                    true
                                                );
                                            }}
                                        >
                                            <label className="campaignItemModifierLabel">
                                                {modifier_name}
                                                <div className="campaignItemModifierDescriptionLabel">
                                                    {modifier.description}{' '}
                                                    {modifier.price
                                                        ? ' +$' + modifier.price
                                                        : ''}
                                                </div>
                                            </label>
                                        </div>
                                    );
                                } else {
                                    render = (
                                        <div
                                            className={`campaignItemModifierRadioRow ${is_checked}`}
                                            key={modifier.modifier_id}
                                            onClick={(e) => {
                                                this.modifierSelected(
                                                    modifier,
                                                    false
                                                );
                                            }}
                                        >
                                            <label className="campaignItemModifierLabel">
                                                {modifier_name}
                                                <div className="campaignItemModifierDescriptionLabel">
                                                    {modifier.description}{' '}
                                                    {modifier.price
                                                        ? ' +$' + modifier.price
                                                        : ''}
                                                </div>
                                            </label>
                                        </div>
                                    );
                                }
                                return render;
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }

    checkModifiersAreSatisfied() {
        // validate that all required modifiers are selected
        const item = this.props.state.order.selected_item;
        const modifierGroups = item.item_modifier_groups;
        const modifiers_enabled =
            this.props.state.order.selected_item_modifiers;

        if (Array.isArray(modifierGroups)) {
            for (let i = 0; i < modifierGroups.length; i++) {
                const modifierGroup = modifierGroups[i];

                if (!modifierGroup.is_required) {
                    continue;
                }

                // check if at least one of them exists in the selected modifiers
                const modifierSatisfied =
                    modifiers_enabled.filter((m) => {
                        return (
                            modifierGroup.modifier_group.modifier_group_id ===
                            m.modifier_group_id
                        );
                    }).length >= 1;

                // if modifier is not satisfied, user cannot add to cart, show toast
                if (!modifierSatisfied) {
                    toast.error(
                        i18n.t('toast.requiredModifier', {
                            modiferName: modifierGroup.modifier_group.name,
                        }),
                        {
                            toastId:
                                modifierGroup.modifier_group.modifier_group_id,
                        }
                    );

                    scroller.scrollTo(
                        modifierGroup.modifier_group.modifier_group_id,
                        {
                            duration: 1250,
                            delay: 0,
                            smooth: 'easeInOutQuart',
                            offset: -80,
                        }
                    );
                    return false;
                }
            }
        }

        return true;
    }

    getImage(item_id) {
        const restaurant_id =
            this.props.state.main.restaurant_info.restaurant_id;
        // try to get image
        const urlCandidate = `${cloudfrontBaseUrl}${restaurant_id}/items/${item_id}.jpeg`;
        return urlCandidate;
    }

    renderSpecialRequests() {
        const theme = this.props.theme;

        if (isEthos()) {
            return;
        }

        return (
            <div className="campaignItemSpecialInstructionsContainer">
                <div style={theme.modifierGroupName}>
                    {' '}
                    {this.props.t('common.specialRequests')}
                </div>
                <input
                    className="campaignItemSpecialInstructionsTextField"
                    value={
                        this.props.state.order
                            .selected_item_special_instructions
                    }
                    onChange={(e) =>
                        this.props.menuItemSpecialInstructionsChanged(
                            e.target.value
                        )
                    }
                />
            </div>
        );
    }

    campaignItemSelected = () => {
        if (this.checkModifiersAreSatisfied()) {
            const {
                selected_campaign_items,
                selected_item,
                selected_item_modifiers,
                selected_item_special_instructions,
            } = this.props.state.order;
            const { itemGroupNumber } = this.props;
            let campaignItem = selected_item;
            campaignItem.modifiers = selected_item_modifiers;
            campaignItem.special_instruciton =
                selected_item_special_instructions;

            //insert the chosen item into the array
            selected_campaign_items.splice(itemGroupNumber, 1, campaignItem);
            this.props.campaignItemSelected(selected_campaign_items);
            this.props.campaignItemSelectedHandler();
            toast.success(i18n.t('toast.campaignItemSelected'));
        }
    };

    render() {
        const theme = this.props.theme;
        let item = this.props.state.order.selected_item;
        let price = item.price === 0 ? '' : (item.price / 100).toFixed(2);
        let image = this.getImage(item.item_id);

        const priceTag = this.props.showPrice ? (
            <div
                className="campaignItemDetailPrice"
                style={theme.itemDetailPrice}
            >
                {price}
            </div>
        ) : (
            ''
        );

        return (
            <div className="campaignItemDetailsContainer">
                <div
                    className="campaignItemPhoto"
                    style={{ backgroundImage: `url(${image})` }}
                >
                    <img
                        alt="Campaign"
                        src={image}
                        className="campaignItemPhoto"
                        style={{ visibility: 'hidden' }}
                    ></img>
                </div>
                <div className="campaignHeaderRow">
                    <div
                        className="campaignItemDetailName"
                        style={theme.itemDetailTitle}
                    >
                        {item.name}{' '}
                        {!item.is_in_stock
                            ? this.props.t('itemDetail.soldOut')
                            : ''}
                    </div>
                    {priceTag}
                </div>
                <div
                    className="campaignItemDetailDescription"
                    style={theme.itemDetailDescription}
                >
                    {item.description}
                </div>
                {this.renderModifierGroups(item)}
                {this.renderSpecialRequests()}
                <div className="campaignModalButtonContainer">
                    <Button
                        variant="contained"
                        color="default"
                        className="campaignItemModalButton"
                        style={{ marginRight: '15px' }}
                        onClick={(e) => {
                            this.props.closeItemModal();
                        }}
                    >
                        {this.props.t('common.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="campaignItemModalButton"
                        onClick={(e) => {
                            this.campaignItemSelected();
                        }}
                    >
                        {this.props.t('common.select')}
                    </Button>
                </div>
            </div>
        );
    }
}

const MSTP = (state) => {
    return { state };
};

const EnhancedCampaignItemDetail = withTranslation()(
    withTheme(
        connect(MSTP, {
            loadMenu: OrderActions.loadMenu,
            menuItemModifierSelected: OrderActions.menuItemModifierSelected,
            menuItemSpecialInstructionsChanged:
                OrderActions.menuItemSpecialInstructionsChanged,
            addToOrder: OrderActions.addToOrder,
            campaignItemSelected: OrderActions.campaignItemSelected,
        })(CampaignItemDetail)
    )
);

export default EnhancedCampaignItemDetail;
