import React from 'react';
import '../css/KioskMaintenanceScreen.css';

const KioskMaintenanceScreen = () => {
    return (
        <div className="to-page-maintenance">
            <img alt="Maintenance" src="/assets/icons/barricade.svg"></img>
            <div
                className="to-page-review-heading mt-2"
                style={{ color: '#333' }}
            >
                Sorry, this kiosk is out of order.
            </div>
        </div>
    );
};

export default KioskMaintenanceScreen;
