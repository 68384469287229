import * as TYPES from '../reducers/TYPES';
import { APIv1Endpoint } from '../endpoints';
// endpoint
import { v4 as uuidv4 } from 'uuid';

// Themes
import { getTheme } from '../themes/theming';
import {
    loadMenu,
    clearAllOrders,
    loadCampaigns,
    loadNuveiTerminals,
} from './orderActions';
import { PaymentType } from '../reducers/TYPES';
import { toast } from 'react-toastify';
import { GAEvent } from '../googleAnalytics';
import { changeTip, selectNuveiTerminal } from './orderActions';
import stripeTerminal from '../StripeTerminal';
import { isFrontOfHouse } from '../utils';
import amplitude from 'amplitude-js';
import { resetReduxState } from './tableCodeActions';
import { isDineinOrder } from '../utils';
import { i18n } from '../locales';

const axios = require('axios').default;

export const resetState = () => (dispatch, getState) => {
    dispatch(resetReduxState());
};

export const setVersion = (value) => (dispatch, getState) => {
    dispatch({
        type: TYPES.VERSION_UPDATED,
        payload: value,
    });
};

export const setRestaurant = (restaurant) => (dispatch, getState) => {
    dispatch({
        type: TYPES.RESTAURANT_INFO_UPDATED,
        payload: restaurant,
    });
};

export const showHideHealthForm = (value) => (dispatch, getState) => {
    dispatch({
        type: TYPES.TOGGLE_HEALTH_AND_SAFETY,
        payload: value,
    });
};

export const setIsTablet = (value) => (dispatch, getState) => {
    dispatch({
        type: TYPES.SET_IS_TABLET,
        payload: value,
    });
};

export const updateLocalId = (value) => (dispatch) => {
    dispatch({
        type: TYPES.LOCAL_ID_CHANGED,
        payload: value,
    });
};

/* Converts table code array to a string */
export const tableCodeArrayToString = (table_id_arr) => {
    let table_id = '';
    table_id_arr.forEach((code) => {
        table_id += code;
    });
    return table_id;
};

export const updateTheme = () => (dispatch, getState) => {
    const restaurant = getState().main.restaurant_info;
    // set theme
    const theme = getTheme(restaurant);
    dispatch({
        type: TYPES.THEME_UPDATED,
        payload: theme,
    });
};

export const setDineInPaymentFlow = () => (dispatch, getState) => {
    // set payment type to pay later
    dispatch({
        type: TYPES.PAYMENT_TYPE_CHANGED,
        payload: PaymentType.PAY_LATER,
    });
};

export const dineInTableFound =
    (table, history, redirect = true) =>
    (dispatch, getState) => {
        // set dine-in table

        dispatch({ type: TYPES.DINE_IN_TABLE_FOUND, payload: table });
        // set table id

        dispatch({
            type: TYPES.DINE_IN_OR_TAKE_OUT_CHANGED,
            payload: TYPES.DineInOrTakeoutSetting.DINE_IN,
        });

        let t_id_arr = table.table_id.split('');
        t_id_arr.forEach((val, index) => {
            t_id_arr[index] = val.toUpperCase();
        });
        // create user and log them in
        // update table code
        dispatch({
            type: TYPES.table_id_UPDATED,
            payload: {
                table_id: t_id_arr,
            },
        });

        // set payment type to pay later
        dispatch({
            type: TYPES.PAYMENT_TYPE_CHANGED,
            payload: PaymentType.PAY_LATER,
        });

        if (redirect) {
            history.push(`/table/${table.table_id}`);
        }
    };

export const verifyRestaurantPaymentType = () => (dispatch, getState) => {
    const restaurant_id = getState().main.restaurant_info.restaurant_id;
    const table_id = getState().table.selected_guest.table_id;

    if (!table_id || !restaurant_id) {
        console.warn(
            'table_id and restaurant_id are required to restore a closed table.'
        );
        return;
    }

    axios.get(`${APIv1Endpoint}table/${table_id}/flat`).then((res) => {});

    // load all sections
    axios
        .get(`${APIv1Endpoint}restaurant/${restaurant_id}/sections`)
        .then((res) => {
            const sections = res.data.sections;

            // find table section this table belongs to
            axios
                .post(`${APIv1Endpoint}table/${table_id}/flat`)
                .then((res) => {
                    const section_id = res.data.table.section_id;

                    const section = sections.filter(
                        (s) => s.section_id === section_id
                    )[0];

                    const section_type_to_payment_type_map = {
                        SEATED: PaymentType.PAY_LATER,
                        STANDING: PaymentType.PAY_IMMEDIATE,
                        WAITING: PaymentType.PAY_LATER,
                    };

                    const paymentType =
                        section_type_to_payment_type_map[section.section_type];

                    dispatch({
                        type: TYPES.PAYMENT_TYPE_CHANGED,
                        payload: paymentType,
                    });
                })
                .catch((err) => {
                    console.error(err.response);
                });
        })
        .catch((err) => {
            console.error(err.response);
        });
};

// const isOneMenuAvailable = (menus) => {
//   const menus_to_show = [];
//   menus.forEach((menu) => {
//     if (
//       menu &&
//       Array.isArray(menu.categories) &&
//       menu.categories.length > 0 &&
//       menu.is_active
//     ) {
//       menus_to_show.push(menu);
//     }
//   });
//   // if only one menu, go directly to categories
//   return menus_to_show.length > 0 ? menus_to_show[0] : null;
// };

export const takeAwaySelected =
    (history, redirect = true) =>
    (dispatch, getState) => {
        const restaurant_info = getState().main.restaurant_info;
        const { restaurant_id } = restaurant_info;
        GAEvent('User', 'Take away selected', restaurant_id);

        dispatch({
            type: TYPES.DINE_IN_OR_TAKE_OUT_CHANGED,
            payload: TYPES.DineInOrTakeoutSetting.TAKEOUT,
        });

        if (
            [
                'RESTAURANT_SETTING_TAKE_OUT_ONLY',
                'RESTAURANT_SETTING_TAKE_OUT_AND_DINE_IN',
            ].includes(restaurant_info.pay_at_counter)
        ) {
            dispatch({
                type: TYPES.PAYMENT_TYPE_CHANGED,
                payload: PaymentType.PAY_LATER,
            });
        } else {
            // take-out pay immediately
            dispatch({
                type: TYPES.PAYMENT_TYPE_CHANGED,
                payload: PaymentType.PAY_IMMEDIATE,
            });
        }

        // if is a tablet, fallback to original implementation
        const endpoint = `${APIv1Endpoint}section/addGuest`;
        const req = {
            restaurant_id,
            section_type: 2,
        };
        // Get table id
        axios
            .post(endpoint, req)
            .then((res) => {
                const table_id = res.data.table_id;

                let t_id_arr = table_id.split('');
                t_id_arr.forEach((val, index) => {
                    t_id_arr[index] = val.toUpperCase();
                });
                // create user and log them in
                // update table code
                dispatch({
                    type: TYPES.table_id_UPDATED,
                    payload: {
                        table_id: t_id_arr,
                    },
                });

                // Set name to table id
                const name = table_id;

                const guest_req = {
                    table_id,
                    guest: {
                        table_id,
                        name,
                        has_paid: false,
                        order: {
                            table_id,
                            amount: 0,
                            delivery_platform: isFrontOfHouse()
                                ? 'FRONT_COUNTER'
                                : 'MENTUM',
                        },
                        affiliate_code: getState().order.affiliate_code || '',
                    },
                    restaurant_id,
                };

                // add guest
                const addGuestEndpoint = APIv1Endpoint + 'table/addGuest';
                axios
                    .post(addGuestEndpoint, guest_req)
                    .then((res) => {
                        const { guest_id } = res.data;

                        amplitude.getInstance().setUserId(guest_id);
                        amplitude.getInstance().setUserProperties({
                            restaurant_id: restaurant_id,
                            restaurant_name: restaurant_info.name,
                            stripe_enabled: Boolean(
                                restaurant_info.stripe_acct
                            ),
                            nuvei_enabled: Boolean(restaurant_info.nuvei_acct),
                            nmi_enabled: Boolean(
                                restaurant_info.nmi_public_key
                            ),
                            bambora_enabled: Boolean(
                                restaurant_info.bambora_mid
                            ),
                        });

                        const guest_obj = {
                            guest_id,
                            table_id,
                            name,
                            order: {},
                        };

                        dispatch({
                            type: TYPES.GUEST_SELECTED,
                            payload: {
                                selected_guest: guest_obj,
                            },
                        });

                        if (redirect) {
                            history.push('/logoview');
                        }

                        if (!isFrontOfHouse()) {
                            dispatch(selectNuveiTerminal({}));
                            if (stripeTerminal.isSetup()) {
                                stripeTerminal.disconnectFromReader();
                            }
                        }
                        // LOAD ORDERS FOR THIS GUEST
                        dispatch(loadOrders());
                    })
                    .catch((err) => {
                        console.error(err.response);
                    });
            })
            .catch((err) => console.error(err.response));
    };

export const dispatchPaymentProcessor =
    (restaurant) => (dispatch, getState) => {
        if (restaurant.nuvei_acct) {
            dispatch({
                type: TYPES.PAYMENT_PROCESSOR_CHANGED,
                payload: TYPES.PaymentProcessor.NUVEI,
            });
        }
        if (restaurant.stripe_acct) {
            dispatch({
                type: TYPES.PAYMENT_PROCESSOR_CHANGED,
                payload: TYPES.PaymentProcessor.STRIPE,
            });
        }

        if (restaurant.nmi_public_key) {
            dispatch({
                type: TYPES.PAYMENT_PROCESSOR_CHANGED,
                payload: TYPES.PaymentProcessor.NMI,
            });
        }

        if (restaurant.bambora_mid) {
            dispatch({
                type: TYPES.PAYMENT_PROCESSOR_CHANGED,
                payload: TYPES.PaymentProcessor.BAMBORA,
            });
        }
    };

export const clearMenuAndLoadResturantDetails =
    (endpoint) => async (dispatch, getState) => {
        if (!endpoint) {
            throw new Error(
                'Could not reload data for this restaurant. Invalid state'
            );
        }

        try {
            // Clear menu
            dispatch({
                type: TYPES.RESTAURANT_MENUS_LOADED,
                payload: [],
            });

            const res = await axios.get(
                `${APIv1Endpoint}restaurant/${endpoint}`
            );
            const restaurant = res.data.restaurant;

            dispatch({
                type: TYPES.RESTAURANT_INFO_UPDATED,
                payload: restaurant,
            });

            const state_map = {
                ACTIVE: true,
                INACTIVE: false,
                DELAYED: true,
            };
            dispatch({
                type: TYPES.RESTAURANT_STATE_UPDATED,
                payload: state_map[restaurant.state],
            });

            // Reload menu
            dispatch(loadMenu());
        } catch (error) {
            // Handle errors here
            console.error('Error loading restaurant details:', error);
        }
    };

export const loadRestaurantDetails = () => (dispatch, getState) => {
    const restaurantName = getState().main.restaurant_info.endpoint;
    if (!restaurantName) {
        throw new Error(
            'Could re-load data for this restaurant. Invalid state'
        );
    }
    axios.get(`${APIv1Endpoint}restaurant/${restaurantName}`).then((res) => {
        const restaurant = res.data.restaurant;
        dispatch({
            type: TYPES.RESTAURANT_INFO_UPDATED,
            payload: restaurant,
        });
    });
};

export const loadStandingTable =
    (restaurantName, history, affiliate = null, redirect = true) =>
    (dispatch, getState) => {
        GAEvent('User', 'Load standing table', restaurantName);

        dispatch({
            type: TYPES.RESTAURANT_ENDPOINT_RECEIVED,
            payload: restaurantName,
        });

        dispatch({
            type: TYPES.RESTAURANT_AFFILIATE_RECEIVED,
            payload: affiliate,
        });

        dispatch({
            type: TYPES.RESET_STATE,
        });

        if (affiliate) {
            dispatch({
                type: TYPES.AFFILIATE_CODE_CHANGED,
                payload: affiliate,
            });
        }

        // check if endpoint includes "*_tab"
        const is_tablet_endpoint = restaurantName.includes('_tab');
        const is_kiosk_endpoint = restaurantName.includes('.kiosk');

        if (is_kiosk_endpoint) {
            // set is tablet to true
            dispatch(setIsTablet(true));
            dispatch({
                type: TYPES.SET_TABLET_ENDPOINT,
                payload: restaurantName,
            });
            dispatch({
                type: TYPES.DEVICE_TYPE_CHANGED,
                payload: TYPES.DeviceTypes.KIOSK,
            });
            dispatch({
                type: TYPES.PAYMENT_TYPE_CHANGED,
                payload: PaymentType.PAY_IMMEDIATE,
            });
            // set tip percentage to 0
            dispatch(changeTip(0));

            // legacy endpoint support: mentumqr.com/g2_tab points to mentumqr.com/edmonton_tab
            if (restaurantName === 'g2_kiosk') {
                restaurantName = 'edmonton';
            } else {
                restaurantName = restaurantName.replace('.kiosk', '');
            }

            if (redirect) {
                history.push('/kioskhome');
            }
        }

        // if tablet endpoint, enable tablet mode
        if (is_tablet_endpoint) {
            // set is tablet to true
            dispatch(setIsTablet(true));
            dispatch({
                type: TYPES.SET_TABLET_ENDPOINT,
                payload: restaurantName,
            });
            dispatch({
                type: TYPES.DEVICE_TYPE_CHANGED,
                payload: TYPES.DeviceTypes.TABLET,
            });
            dispatch({
                type: TYPES.PAYMENT_TYPE_CHANGED,
                payload: PaymentType.PAY_IMMEDIATE,
            });
            // set tip percentage to 0
            dispatch(changeTip(0.0));

            // legacy endpoint support: mentumqr.com/g2_tab points to mentumqr.com/edmonton_tab
            if (restaurantName === 'g2_tab') {
                restaurantName = 'edmonton';
            } else {
                restaurantName = restaurantName.replace('_tab', '');
            }
        }

        axios
            .get(`${APIv1Endpoint}restaurant/${restaurantName}`)
            .then((res) => {
                const restaurant = res.data.restaurant;
                const { restaurant_id } = restaurant;

                // dispatch restaurant data
                dispatch({
                    type: TYPES.RESTAURANT_INFO_UPDATED,
                    payload: restaurant,
                });

                if (is_kiosk_endpoint) {
                    dispatch(loadNuveiTerminals());
                }
                if (
                    (is_kiosk_endpoint || affiliate === '$frontOfHouse') &&
                    restaurant.stripe_acct
                ) {
                    stripeTerminal.setup(restaurant_id);
                }

                // set payment processor
                dispatch(dispatchPaymentProcessor(restaurant));

                // set theme for restaurant
                const theme = getTheme(restaurant);
                dispatch({
                    type: TYPES.THEME_UPDATED,
                    payload: theme,
                });
                // select theme
                dispatch(dispatchTheme(res.data.restaurant));

                // get store status
                const state_map = {
                    ACTIVE: true,
                    INACTIVE: false,
                    DELAYED: true,
                };
                dispatch({
                    type: TYPES.RESTAURANT_STATE_UPDATED,
                    payload: state_map[restaurant.state],
                });

                // PRELOAD RESTAURANT MENU
                dispatch(loadMenu());

                // PRELOAD RESTAURANT CAMPAIGNS
                dispatch(loadCampaigns());

                // select take out or dine in later
                if (!is_tablet_endpoint && !is_kiosk_endpoint) {
                    // MENU ONLY MODE
                    if (restaurant.order_mode === 'RESTAURANT_SETTING_NONE') {
                        dispatch({
                            type: TYPES.MENU_ONLY_MODE_CHANGED,
                            payload: true,
                        });
                        dispatch(takeAwaySelected(history, redirect));
                        return;
                    }

                    dispatch(takeAwaySelected(history, redirect));

                    // SET MENU ONLY MODE TO FALSE IF THE PREVIOUS CHECKS HAVE PASSED
                    dispatch({
                        type: TYPES.MENU_ONLY_MODE_CHANGED,
                        payload: false,
                    });

                    return;
                }

                // if is a tablet, fallback to original implementation
                const endpoint = `${APIv1Endpoint}section/addGuest`;
                const req = {
                    restaurant_id,
                    section_type: 2,
                };
                // Get table id
                axios
                    .post(endpoint, req)
                    .then((res) => {
                        const table_id = res.data.table_id;

                        let t_id_arr = table_id.split('');
                        t_id_arr.forEach((val, index) => {
                            t_id_arr[index] = val.toUpperCase();
                        });
                        // create user and log them in
                        // update table code
                        dispatch({
                            type: TYPES.table_id_UPDATED,
                            payload: {
                                table_id: t_id_arr,
                            },
                        });

                        // Set name to table id
                        const name = table_id;
                        const guest_req = {
                            table_id,
                            guest: {
                                table_id,
                                name,
                                has_paid: false,
                                order: {
                                    table_id,
                                    amount: 0,
                                    delivery_platform: isFrontOfHouse()
                                        ? 'FRONT_COUNTER'
                                        : is_kiosk_endpoint
                                        ? 'KIOSK'
                                        : 'MENTUM',
                                },
                                affiliate_code: affiliate || '',
                            },
                            restaurant_id,
                        };
                        // add guest
                        const addGuestEndpoint =
                            APIv1Endpoint + 'table/addGuest';
                        axios
                            .post(addGuestEndpoint, guest_req)
                            .then((res) => {
                                const { guest_id } = res.data;

                                const guest_obj = {
                                    guest_id,
                                    table_id,
                                    name,
                                    order: {
                                        delivery_platform: isFrontOfHouse()
                                            ? 'FRONT_COUNTER'
                                            : is_kiosk_endpoint
                                            ? 'KIOSK'
                                            : 'MENTUM',
                                    },
                                    ...guest_req.guest,
                                };

                                dispatch({
                                    type: TYPES.GUEST_SELECTED,
                                    payload: {
                                        selected_guest: guest_obj,
                                    },
                                });

                                // check if tablet
                                if (is_tablet_endpoint && redirect) {
                                    history.push('/tablethome');
                                } else if (is_kiosk_endpoint) {
                                    // do nothing
                                } else {
                                    if (redirect) {
                                        history.push('/logoview');
                                    }
                                }

                                // LOAD ORDERS FOR THIS GUEST
                                dispatch(loadOrders());
                            })
                            .catch((err) => {
                                console.error(err.response);
                            });
                    })
                    .catch((err) => console.error(err.response));
            })
            .catch((err) => {
                // restaurant not found
                GAEvent('Error', 'Standing table not found!!!', restaurantName);

                if (is_kiosk_endpoint && redirect) {
                    history.push('/kiosk/maintenance');
                } else if (redirect) {
                    history.push('/404');
                }
            });
    };

/* ensures table code is valid and guest is selected */
export const checkValidRoute = (history) => (dispatch, getState) => {
    const table_id = tableCodeArrayToString(getState().main.table_id);
    const selected_guest = getState().table.selected_guest;

    if (table_id === '') {
        // push back to home
        history.push('/');
    } else if (selected_guest === {}) {
        // push to select guest page
        history.push('/table/' + table_id);
    }
};

/* Load orders */
export const loadOrders =
    (history, redirect = true) =>
    (dispatch, getState) => {
        const tableCode = tableCodeArrayToString(getState().main.table_id);
        const requestUrl = APIv1Endpoint + 'table/' + tableCode + '/orders';

        // Dipatching this will show the loading animation
        dispatch({
            type: TYPES.ORDERS_UPDATED,
            payload: {
                all_orders: {},
                guest_orders: {},
            },
        });

        dispatch({
            type: TYPES.LOADING_GUEST_ORDERS,
            payload: true,
        });

        axios
            .get(requestUrl)
            .then((res) => {
                const { data } = res;
                dispatch({
                    type: TYPES.LOADING_GUEST_ORDERS,
                    payload: false,
                });
                if (data.orders) {
                    const orders = data.orders;

                    // Look for the user's order from the table's orders.
                    const selected_guest_id =
                        getState().table.selected_guest.guest_id;
                    var guest_orders = [];

                    orders.forEach((order) => {
                        if (order.guest_id === selected_guest_id) {
                            guest_orders = order;
                            if (!Array.isArray(guest_orders.items)) {
                                guest_orders.items = [];
                            }
                            guest_orders.items.forEach((itemInstance) => {
                                const item = itemInstance.item;
                                // set price to dollars
                                if (item.price) {
                                    item.price /= 100;
                                }
                                // set modifier price to dollars
                                if (itemInstance.modifiers) {
                                    itemInstance.modifiers.forEach(
                                        (modifier) => {
                                            if (modifier.price) {
                                                modifier.price /= 100;
                                            }
                                        }
                                    );
                                }
                            });
                            guest_orders.amount /= 100;
                        }
                    });

                    dispatch({
                        type: TYPES.ORDERS_UPDATED,
                        payload: {
                            all_orders: orders,
                            guest_orders,
                        },
                    });
                } else {
                    // if no orders, table was likely closed
                    // redirect them to guest list
                    if (redirect) {
                        history.push(`/table/${tableCode}`);
                    }
                    toast.error(i18n.t('toast.tableSessionClosed'));
                    console.warn('Unexpected response in loadOrders');
                    console.warn(data);
                }
            })
            .catch((err) => {
                dispatch({
                    type: TYPES.LOADING_GUEST_ORDERS,
                    payload: false,
                });
                if (err.response) {
                    console.error(err.response);
                }
            });

        // // Look for the user's order from the table's orders.
        // const selected_guest_id = getState().table.selected_guest.guest_id;
        // var guest_orders = [];
        // orders.forEach(order => {
        //     if (order.guest_id === selected_guest_id) {
        //         guest_orders = order;
        //     }
        // });

        // // Send dispatch
        // dispatch({
        //     type: TYPES.ORDERS_UPDATED,
        //     payload: {
        //         all_orders: orders,
        //         guest_orders,
        //     }
        // })
    };

export const addGuestToDineinTable =
    (history, redirect = true, name = '') =>
    (dispatch, getState) => {
        // set loading to true
        dispatch({
            type: TYPES.ADDING_GUEST_TO_TABLE,
            payload: true,
        });

        // get guest list
        let guests = getState().table.guests;
        if (!Array.isArray(guests)) {
            guests = [];
        }
        const new_guest_num = guests.length + 1;

        // POST request to add guest to a table.
        const table_id = tableCodeArrayToString(getState().main.table_id);
        const endpoint = APIv1Endpoint + 'table/addGuest';
        const restaurant_id = getState().main.restaurant_info.restaurant_id;

        let guest = {
            table_id,
            name: name ? name : `Guest ${new_guest_num}`,
            has_paid: false,
            order: {
                table_id,
                amount: 0,
                delivery_platform: isFrontOfHouse()
                    ? 'FRONT_COUNTER'
                    : 'MENTUM',
            },
            affiliate_code: getState().order.affiliate_code,
        };

        const request = {
            table_id: table_id,
            guest,
            restaurant_id,
        };

        axios
            .post(endpoint, request)
            .then((res) => {
                // select the guest
                const guest_id = res.data.guest_id;
                guest.guest_id = guest_id;
                dispatch({
                    type: TYPES.GUEST_SELECTED,
                    payload: {
                        selected_guest: guest,
                    },
                });
                if (redirect) {
                    history.push('/logoview');
                }
                // LOAD ORDERS FOR THIS GUEST
                dispatch(loadOrders());

                // set loading to false
                dispatch({
                    type: TYPES.ADDING_GUEST_TO_TABLE,
                    payload: false,
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.error(err.response);
                    dispatch({
                        type: TYPES.ADD_GUEST_ERROR_RECEIVED,
                        payload: {
                            errorMessage:
                                'Error sending request: ' +
                                err.response.data.error,
                        },
                    });
                }

                // set loading to false
                dispatch({
                    type: TYPES.ADDING_GUEST_TO_TABLE,
                    payload: false,
                });
            });
    };

export const loadGuestFromDB = () => (dispatch, getState) => {
    const table_id = tableCodeArrayToString(getState().main.table_id);
    const guest = getState().table.selected_guest;
    const endpoint = APIv1Endpoint + 'table/' + table_id + '/guests';
    axios
        .get(endpoint)
        .then((res) => {
            // find guest here
            const guests = res.data.guests;
            const db_guest = guests.filter((g) => {
                return g.guest_id === guest.guest_id;
            });

            if (db_guest.length === 1) {
                dispatch({
                    type: TYPES.GUEST_SELECTED,
                    payload: {
                        selected_guest: db_guest[0],
                    },
                });
            } else if (db_guest.length === 0) {
                // reset state - guest has been removed from the table (i.e. table session closed)
                dispatch({ type: TYPES.RESET_STATE });
                setTimeout(() => {
                    if (window && window.location) {
                        window.location.reload();
                    }
                }, 1000);
            }
        })
        .catch((err) => console.error(err.response));
};

/* Select a guest */
export const selectGuest =
    (guest_obj, history, redirect = true) =>
    (dispatch, getState) => {
        dispatch({
            type: TYPES.GUEST_SELECTED,
            payload: {
                selected_guest: guest_obj,
            },
        });

        // reset orders
        dispatch(clearAllOrders());

        if (redirect) {
            // navigate to guesthome
            history.push('/guesthome');
        }

        // LOAD ORDERS FOR THIS GUEST
        dispatch(loadOrders());
    };

export const goBackToGuestSelect = (history) => (dispatch, getState) => {
    const table_id = tableCodeArrayToString(getState().main.table_id);
    history.push('/table/' + table_id);
};

export const dispatchTheme = (restaurant) => (dispatch, getState) => {
    const theme = getTheme(restaurant);

    dispatch({
        type: TYPES.THEME_UPDATED,
        payload: theme,
    });
};

/* Loads guest list */
export const loadGuestList = () => (dispatch, getState) => {
    // show loading
    // dispatch({
    //     type: TYPES.LOADING_STATUS_UPDATED,
    //     payload: true
    // })
    dispatch({
        type: TYPES.LOADING_TABLE_GUESTS,
        payload: true,
    });

    // do post request
    const table_id = tableCodeArrayToString(getState().main.table_id);
    const endpoint = APIv1Endpoint + 'table/' + table_id + '/guests';
    axios
        .get(endpoint, { params: { exclude_empty_orders: false } })
        .then((res) => {
            let { guests, restaurant_id } = res.data;
            if (!guests) {
                guests = [];
            }
            // reverse guest list (so newest guests appear first)
            guests.reverse();
            dispatch({
                type: TYPES.GUEST_LIST_UPDATED,
                payload: { guests: guests },
            });

            if (res.data.table) {
                dispatch({
                    type: TYPES.LOCAL_ID_CHANGED,
                    payload: res.data.table.local_id || res.data.table.table_id,
                });
            }

            // get restaurant info
            const rest_info_endpoint =
                APIv1Endpoint + 'restaurant/' + restaurant_id + '/details';
            axios
                .get(rest_info_endpoint)
                .then((res) => {
                    // check if the store is offline
                    const state_map = {
                        ACTIVE: true,
                        INACTIVE: false,
                        DELAYED: true,
                    };
                    // dispatch state data
                    dispatch({
                        type: TYPES.RESTAURANT_STATE_UPDATED,
                        payload: state_map[res.data.restaurant.state],
                    });
                    dispatch({
                        type: TYPES.RESTAURANT_INFO_UPDATED,
                        payload: res.data.restaurant,
                    });

                    // select theme
                    dispatch(dispatchTheme(res.data.restaurant));

                    // PRELOAD RESTAURANT MENU
                    dispatch(loadMenu());

                    // PRELOAD RESTAURANT CAMPAIGNS
                    dispatch(loadCampaigns());

                    // Complete Loading
                    dispatch({
                        type: TYPES.LOADING_TABLE_GUESTS,
                        payload: false,
                    });
                })
                .catch((err) => {
                    // Complete Loading
                    dispatch({
                        type: TYPES.LOADING_TABLE_GUESTS,
                        payload: false,
                    });
                    console.error(err.response);
                });
        })
        .catch((err) => {
            // Complete Loading
            dispatch({
                type: TYPES.LOADING_TABLE_GUESTS,
                payload: false,
            });
            if (err.response) {
                console.warn(err.response);
            }
        });
};

/* Updates state when the Add Guest text field is changed */
export const addGuestNameUpdated = (value) => (dispatch, getState) => {
    dispatch({
        type: TYPES.ADD_GUEST_NAME_UPDATED,
        payload: {
            name: value,
        },
    });
};

/* "Add" button is pressed */
export const addGuestToTable =
    (history, name = null, redirect = true) =>
    (dispatch, getState) => {
        dispatch({
            type: TYPES.ADD_GUEST_BUTTON_STATE_CHANGED,
            payload: true,
        });

        // new guest name
        const newGuest = name || getState().table.add_guest_name;

        if (newGuest === '') {
            dispatch({
                type: TYPES.GUEST_NAME_IN_USE,
                payload: {
                    errorMessage: 'Sorry, your name cannot be blank.',
                },
            });
            dispatch({
                type: TYPES.ADD_GUEST_BUTTON_STATE_CHANGED,
                payload: false,
            });
            return;
        }

        // Check if the name is already in use
        const guests = getState().table.guests;
        let guestNameInUse = false;

        guests.forEach((guest) => {
            if (guest.name === newGuest) {
                guestNameInUse = true;
            }
        });
        if (guestNameInUse) {
            // dispatch error message
            dispatch({
                type: TYPES.GUEST_NAME_IN_USE,
                payload: {
                    errorMessage: 'Guest name in use',
                },
            });
            dispatch({
                type: TYPES.ADD_GUEST_BUTTON_STATE_CHANGED,
                payload: false,
            });
            return;
        }

        // POST request to add guest to a table.
        const table_id = tableCodeArrayToString(getState().main.table_id);
        const endpoint = APIv1Endpoint + 'table/addGuest';
        const restaurant_id = getState().main.restaurant_info.restaurant_id;

        const guest_id = uuidv4();
        const order_id = uuidv4();

        const request = {
            table_id: table_id,
            guest: {
                guest_id,
                table_id,
                name: newGuest,
                has_paid: false,
                order: {
                    order_id,
                    table_id,
                    guest_id,
                    amount: 0,
                },
            },
            restaurant_id,
        };

        axios
            .post(endpoint, request)
            .then((res) => {
                // Push them back to guest list once response is OK
                const tableCode = tableCodeArrayToString(
                    getState().main.table_id
                );
                if (redirect) {
                    history.push('/table/' + tableCode);
                }

                dispatch(loadGuestList());

                // Clear text field name
                dispatch({
                    type: TYPES.ADD_GUEST_NAME_UPDATED,
                    payload: {
                        name: '',
                    },
                });

                dispatch({
                    type: TYPES.ADD_GUEST_BUTTON_STATE_CHANGED,
                    payload: false,
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.error(err.response);
                    dispatch({
                        type: TYPES.ADD_GUEST_ERROR_RECEIVED,
                        payload: {
                            errorMessage:
                                'Error sending request: ' +
                                err.response.data.error,
                        },
                    });
                }
            });
    };

export const updatePhoneNumber =
    (number, submit = false) =>
    (dispatch, getState) => {
        dispatch({
            type: TYPES.PHONE_NUMBER_UPDATED,
            payload: number,
        });
        if (submit) dispatch(submitPhoneNumber(false, number));
    };

export const submitPhoneNumber =
    (sendText = true, number = '') =>
    (dispatch, getState) => {
        const v2 = getState().main.version === 2;
        const isDineIn = isDineinOrder();

        let toast_success_msg = '';
        if (isDineIn) {
            toast_success_msg = i18n.t('toast.receiptSent');
        } else {
            toast_success_msg = i18n.t('toast.orderPlaced');
            if (sendText) {
                toast_success_msg += i18n.t('toast.textReceipt');
                toast_success_msg += i18n.t('toast.tapToDismiss');
                if (!v2) {
                    toast.info(i18n.t('toast.sendingTextMessage'), {
                        toastId: 'sendingTextNotification',
                    });
                }
            }
        }

        GAEvent('User', 'Submitted phone number', '');

        const guest = getState().table.selected_guest;
        if (number) guest.phone_number = number;
        const endpoint = `${APIv1Endpoint}guest/update`;
        axios
            .post(endpoint, {
                guest: {
                    ...guest,
                    restaurant_id:
                        getState().main.restaurant_info.restaurant_id,
                },
                send_text: sendText,
            })
            .then((res) => {
                // show success notification
                if (!v2) {
                    if (sendText) {
                        toast.update('sendingTextNotification', {
                            render: `${toast_success_msg}`,
                            type: toast.TYPE.SUCCESS,
                            autoClose: 5000,
                        });
                    } else {
                        toast.success(`${toast_success_msg}`);
                    }
                }
                if (isDineIn && sendText) {
                    toast.success(`${toast_success_msg}`);
                }
            })
            .catch((err) => {
                console.error(err.response);
                GAEvent(
                    'Error',
                    'Unable to submit text message via phone',
                    JSON.stringify(err.response)
                );
                toast.error(i18n.t('toast.textSendingError'));
            });
    };

export const submitGuestName =
    (name = '') =>
    (dispatch, getState) => {
        GAEvent('User', 'Submitted guest name', '');

        const guest = getState().table.selected_guest;
        if (name) {
            guest.name = name;
        }
        const endpoint = `${APIv1Endpoint}guest/update`;
        axios
            .post(endpoint, {
                guest: {
                    ...guest,
                    restaurant_id:
                        getState().main.restaurant_info.restaurant_id,
                },
            })
            .then((res) => {
                // show success notification
                toast.success(i18n.t('toast.nameSaved'));
            })
            .catch((err) => {
                console.error(err.response);
                GAEvent(
                    'Error',
                    'Unable to save name',
                    JSON.stringify(err.response)
                );
                toast.error(i18n.t('toast.nameSavedError'));
            });
    };
