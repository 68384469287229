import React, { useState } from 'react';
import { Button, Hamburger, Dropdown, ProductsDropdown } from '.';
import { Link } from 'react-router-dom';

const LOGO_URL = '/assets/logos/mentum_logo_2021.svg';

interface LinkProps {
    text: string;
    href?: string;
    hover?: () => void;
}

const HeaderLink = (props: LinkProps) => {
    return (
        <a href={props.href} target="_blank" rel="noreferrer noopener">
            <div className="v3HeaderLink">{props.text}</div>
        </a>
    );
};

export default () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const mobileHamburgerContents = (): JSX.Element => (
        <div>
            <Dropdown
                label="Products"
                setShowParent={setIsMenuOpen}
                classname="v3HeaderLink"
                open
            >
                {ProductsDropdown}
            </Dropdown>
            {/* TODO: add our story page later
            <Link to="/ourstory" setShowParent={setHamburgerOpen}>
                <div className="v3HeaderLink">Our Story</div>
            </Link>
            */}
            <Link to="/contact">
                <div className="v3HeaderLink">Contact</div>
            </Link>
            <HeaderLink
                text="Careers"
                href="https://www.notion.so/mentumqr/Careers-at-Mentum-5a111016a09b4d97a2e146f9c9e3cefe"
            />
        </div>
    );
    return (
        <div className="v3Header">
            <div className="v3HeaderInnerContainer">
                <Link to="/">
                    <img src={LOGO_URL} className="v3HeaderLogo" />
                </Link>
                <div className="v3HeaderSplitRow">
                    <div className="v3HeaderRow mobileHidden">
                        <Dropdown label="Products" classname="v3HeaderLink">
                            {ProductsDropdown}
                        </Dropdown>
                        {/* TODO: build ourstory page and Contact page later on
                        <Link to='/ourstory'><div className="v3HeaderLink">Our Story</div> </Link>
                        */}

                        <Link to="/contact">
                            <div className="v3HeaderLink">Contact</div>{' '}
                        </Link>
                        <HeaderLink
                            text="Careers"
                            href="https://www.notion.so/mentumqr/Careers-at-Mentum-5a111016a09b4d97a2e146f9c9e3cefe"
                        />
                    </div>
                    <Link to="/contact" className="mobileHidden">
                        <Button text="Book a Demo" />
                    </Link>
                </div>
                <div className="websiteHidden">
                    <Hamburger
                        open={isMenuOpen}
                        setOpen={setIsMenuOpen}
                        menuContent={mobileHamburgerContents()}
                    />
                </div>
            </div>
        </div>
    );
};
