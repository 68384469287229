import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { TextField } from '@material-ui/core';
import * as yup from 'yup';

import './css/HotelCustomerForm.css';
import Axios from 'axios';
import { APIv1Endpoint } from '../../endpoints';
import { toast } from 'react-toastify';

import * as OrderActions from '../../actions/orderActions';
import * as TableActions from '../../actions/tableActions';
import Header from '../../components/Header';
import { isV2 } from '../../utils';
import { i18n } from '../../locales';

class HotelCustomerForm extends React.Component {
    render() {
        const guest = this.props.state.table.selected_guest;

        const schema = yup.object().shape({
            name: yup
                .string()
                .required('Last name is required')
                .min(2, 'Your name must be at least 2 letters long'),
            l_name: yup
                .string()
                .required('First name is required')
                .min(2, 'Your name must be at least 2 letters long'),
        });

        const names =
            guest.name.indexOf(' ') !== -1 ? guest.name.split(' ') : ['', ''];

        return (
            <>
                <div className="hcf-container">
                    <Header
                        back=":goBack"
                        history={this.props.history}
                    ></Header>
                    <div className="hcf-title">
                        Please confirm your information
                    </div>
                    <Formik
                        initialValues={{
                            name: names[0],
                            l_name: names[1],
                            phone_number: guest.phone_number,
                            room_number: '',
                        }}
                        validationSchema={schema}
                        onSubmit={(values) => {
                            const phone_number = guest.phone_number;
                            let { name, l_name, room_number } = values;

                            // append room number to guest last name
                            if (room_number && room_number.length > 0) {
                                l_name += ` (Room # ${room_number})`;
                            }

                            Axios.post(`${APIv1Endpoint}guest/update`, {
                                guest: {
                                    ...this.props.state.table.selected_guest,
                                    name: `${name} ${l_name}`,
                                    phone_number,
                                },
                            })
                                .then((res) => {
                                    this.props.getPaymentIntent_PayBefore(
                                        this.props.history
                                    );
                                    this.props.loadGuestFromDB();
                                })
                                .catch((err) => {
                                    console.error(err);
                                    toast.error(
                                        i18n.t('toast.errorNotification3')
                                    );
                                });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <div>
                                        <TextField
                                            variant="outlined"
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                            fullWidth
                                            value={values.name}
                                            name="name"
                                            required={true}
                                            label="Name"
                                            onChange={handleChange}
                                        ></TextField>
                                    </div>
                                    <div className="hcf-error">
                                        {errors.name}
                                    </div>
                                    <div>
                                        <TextField
                                            variant="outlined"
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                            fullWidth
                                            value={values.l_name}
                                            name="l_name"
                                            required={true}
                                            label="Last Name"
                                            onChange={handleChange}
                                        ></TextField>
                                    </div>
                                    <div className="hcf-error">
                                        {errors.l_name}
                                    </div>
                                    <div>
                                        <TextField
                                            variant="outlined"
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                            fullWidth
                                            value={values.phone_number}
                                            disabled={true}
                                            name="phone_number"
                                            label="Phone Number"
                                            onChange={handleChange}
                                        ></TextField>
                                    </div>
                                    <div>
                                        <TextField
                                            variant="outlined"
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                            fullWidth
                                            value={values.room_number}
                                            name="room_number"
                                            label="Room Number"
                                            onChange={handleChange}
                                        ></TextField>
                                    </div>
                                </div>
                                {isSubmitting ? (
                                    <div className="twoFA-button">
                                        Submitting
                                    </div>
                                ) : (
                                    <div
                                        className="twoFA-button"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </div>
                                )}
                            </form>
                        )}
                    </Formik>
                </div>
            </>
        );
    }
}

const mstp = (state) => ({ state });

export default connect(mstp, {
    getPaymentIntent_PayBefore: OrderActions.getPaymentIntent_PayBefore,
    loadGuestFromDB: TableActions.loadGuestFromDB,
})(HotelCustomerForm);
