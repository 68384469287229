import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './css/FrontOfHouseTerminalSelect.css';
import stripeTerminal from '../StripeTerminal';
import * as OrderActions from '../actions/orderActions';
import { useTranslation } from 'react-i18next';

export default () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const restaurant_id = state.main.restaurant_info.restaurant_id;
    const { t } = useTranslation();

    useEffect(() => {
        if (!stripeTerminal.isSetup()) {
            stripeTerminal.setup(restaurant_id);
        }
    }, [restaurant_id]);

    const availableReaders = stripeTerminal.listAvailableReaders();
    const availableNuveiReaders = state.kiosk.nuvei_payment_terminals;

    const connected_stripe_reader = stripeTerminal.getConnectedReader() || {};
    const connected_nuvei_reader = state.kiosk.nuvei_selected_terminal;

    const connectedReaderName =
        connected_stripe_reader.serial_number ||
        connected_nuvei_reader.terminal_id;

    const is_reader_connecting = state.kiosk.is_connecting;

    const _connError = state.kiosk.connection_error;
    const connection_error =
        Object.keys(_connError).length > 0 ? _connError : '';

    if (isExpanded) {
        return (
            <div className="foh-container-expanded">
                <div className="kioskTerminalSetup">
                    <div className="kioskTerminalSetupInnerContainer">
                        <h1>{t('oneMenu.terminalReaderRegistration')}</h1>

                        <div
                            className="kiskoTerminalConnectButton"
                            onClick={(e) => {
                                setIsExpanded(false);
                            }}
                            style={{
                                marginTop: '30px',
                                marginBottom: '30px',
                            }}
                        >
                            {t('oneMenu.close')}
                        </div>

                        {availableReaders.map((reader, reader_index) => {
                            const isTerminalConnected =
                                connected_stripe_reader.serial_number ===
                                    reader.serial_number &&
                                stripeTerminal.isReaderConnected();
                            const connectionLabel =
                                is_reader_connecting &&
                                is_reader_connecting === reader.serial_number
                                    ? t('oneMenu.connecting')
                                    : t('oneMenu.connect');
                            return (
                                <div
                                    className="kioskTerminalReaderRow"
                                    key={`reader-${reader_index}`}
                                >
                                    <div>
                                        <h3>
                                            {t('oneMenu.reader')}
                                            {reader_index + 1}
                                        </h3>
                                    </div>
                                    <div>{reader.label}</div>
                                    <div>
                                        {t('oneMenu.device')}:{' '}
                                        {reader.device_type}
                                        <br></br>
                                        {t('oneMenu.deviceID')}: {reader.id}
                                        <br></br>
                                        {t('oneMenu.location')}:{' '}
                                        {reader.location}
                                        <br></br>
                                        {t('oneMenu.sn')}:{' '}
                                        {reader.serial_number}
                                        <br></br>
                                        {t('oneMenu.ipAddress')}:{' '}
                                        {reader.ip_address}
                                        <br></br>
                                        {t('oneMenu.object')}: {reader.object}
                                    </div>
                                    <div>{reader.status}</div>
                                    <div
                                        className="kiskoTerminalConnectButton"
                                        style={{
                                            backgroundColor: isTerminalConnected
                                                ? 'rgb(0, 114, 19)'
                                                : '#222',
                                        }}
                                        onClick={(e) => {
                                            if (!isTerminalConnected) {
                                                stripeTerminal.connectToReader(
                                                    reader
                                                );
                                                dispatch(
                                                    OrderActions.selectNuveiTerminal(
                                                        {}
                                                    )
                                                );
                                            }
                                        }}
                                    >
                                        {isTerminalConnected
                                            ? t('oneMenu.connectedMessage')
                                            : connectionLabel}
                                    </div>
                                </div>
                            );
                        })}

                        {availableNuveiReaders.map((reader, reader_index) => {
                            let isTerminalSelected = false;
                            if (connected_nuvei_reader) {
                                isTerminalSelected =
                                    reader.terminal_id ===
                                    connected_nuvei_reader.terminal_id;
                            }
                            const connectionLabel =
                                is_reader_connecting &&
                                is_reader_connecting === reader.serial_number
                                    ? t('oneMenu.connecting')
                                    : t('oneMenu.connect');
                            const isOffline = !reader.is_online;
                            return (
                                <div
                                    className="kioskTerminalReaderRow"
                                    key={`reader-nuvei-${reader_index}`}
                                >
                                    <div>
                                        <h3>Nuvei: {reader.kiosk_id}</h3>
                                    </div>
                                    <div>{reader.label}</div>
                                    <div>
                                        {t('oneMenu.terminalId')}:{' '}
                                        {reader.terminal_id}
                                    </div>
                                    <div>
                                        {t('oneMenu.online')}:{' '}
                                        {reader.is_online
                                            ? t('common.yes')
                                            : t('common.no')}
                                    </div>
                                    <div
                                        className="kiskoTerminalConnectButton"
                                        style={{
                                            backgroundColor: isTerminalSelected
                                                ? 'rgb(0, 114, 19)'
                                                : '#222',
                                        }}
                                        onClick={(e) => {
                                            // select nuvei terminal, disconect from stripe terminal
                                            dispatch(
                                                OrderActions.selectNuveiTerminal(
                                                    reader
                                                )
                                            );
                                            stripeTerminal.disconnectFromReader();
                                        }}
                                    >
                                        {isTerminalSelected
                                            ? `${t('oneMenu.connected')}${
                                                  isOffline
                                                      ? ` ${t(
                                                            'oneMenu.offline'
                                                        )}`
                                                      : ''
                                              }`
                                            : connectionLabel}
                                    </div>
                                </div>
                            );
                        })}

                        <div
                            className="kiskoTerminalConnectButton"
                            onClick={(e) => {
                                stripeTerminal.disconnectFromReader();
                                dispatch(OrderActions.selectNuveiTerminal({}));
                            }}
                            style={{
                                marginTop: '30px',
                                backgroundColor: '#222',
                            }}
                        >
                            {t('oneMenu.disconnect')}
                        </div>

                        <div
                            className="kiskoTerminalConnectButton"
                            onClick={(e) => {
                                stripeTerminal.loadReaders();
                                dispatch(OrderActions.loadNuveiTerminals());
                            }}
                            style={{
                                marginTop: '30px',
                                backgroundColor: 'rgba(0,0, 155 ,0.8)',
                            }}
                        >
                            {t('oneMenu.refreshReaders')}
                        </div>

                        {connection_error ? (
                            <div
                                style={{
                                    margin: '20px',
                                    fontSize: '40px',
                                    color: 'red',
                                }}
                            >
                                {connection_error.message}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        );
    }

    const isConnecting = is_reader_connecting
        ? t('oneMenu.connecting')
        : t('oneMenu.ccMachineDisconnected');
    return (
        <div
            className="foh-container"
            style={{ backgroundColor: connectedReaderName ? 'green' : 'blue' }}
            onClick={() => setIsExpanded(true)}
        >
            {!connectedReaderName
                ? isConnecting
                : `${t('cart.creditCardMachine')} ${connectedReaderName}`}
        </div>
    );
};
