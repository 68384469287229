import React, { useState, useMemo } from 'react';
import './GhostKitchenLocationPicker.css';
import {
    FaLocationArrow,
    FaCrosshairs,
    FaSearch,
    FaChevronLeft,
} from 'react-icons/fa';
import { HiShoppingCart } from 'react-icons/hi';
import { ImPhone } from 'react-icons/im';
import GKAnnotationDetails from './GKAnnotationDetails';
import GKGooglePlacesAutoComplete from './GKGooglePlacesAutoComplete';
import { LocationPickerAction } from './constants';
import GKGoogleMap, { buildRestaurantMarker } from './GKGoogleMap';
import { BASE_URL } from '../../../endpoints';

const GKLocationPickerMobile = ({
    isLoading,
    isDelivery,
    restaurants,
    scheduleByRestaurantId,
    restaurantsDistances,
    selectedRestaurant,
    isDeliveryEnabled,
    dUnit,
    address,
    onRequestCallback,
}) => {
    const [showMobileMap, setShowMobileMap] = useState(false);
    const [showSearchbar, setShowSearchBar] = useState(false);



    const showSearch = () => {
        setShowMobileMap(false);
        setShowSearchBar(true);
    };

    const hideMap = () => {
        setShowMobileMap(false);
        setShowSearchBar(false);
    };

    const selectedCoordinates = selectedRestaurant
        ? {
              lat: selectedRestaurant.latitude,
              lng: selectedRestaurant.longitude,
          }
        : null;

    const mapMarkers = useMemo(() => {
        if (
            restaurants.length === 0 ||
            Object.keys(scheduleByRestaurantId).length === 0
        ) {
            return [];
        }
        return restaurants.map(
            (el) => ({
                position: {
                    lat: el.latitude,
                    lng: el.longitude,
                },
                title: el.name,
                content: buildRestaurantMarker(
                    el,
                    scheduleByRestaurantId[el.restaurant_id]
                ),
                onClick: () =>
                    onRequestCallback({
                        type: 'SELECT_RESTAURANT',
                        restaurant: el,
                    }),
            }),
            [restaurants, scheduleByRestaurantId]
        );
    }, [restaurants, scheduleByRestaurantId]);

    const MobileListView = () => (
        <div className="restaurantLocationPickerContainer">
            <div>
                <div className="logoBar" style={{ paddingBottom: 18 }}>
                    <FaSearch
                        className="searchIcon"
                        size="1.2em"
                        onClick={() => showSearch()}
                    />
                    <img
                        className="mobileListLogo"
                        alt="Ghost Kitchens Logo"
                        src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                    />
                    <div className="useLocationMobileList">
                        <FaCrosshairs
                            size="1.2em"
                            style={{ margin: 'auto' }}
                            onClick={() => {
                                onRequestCallback({
                                    type: LocationPickerAction.GET_CURRENT_LOCATION,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="row" style={{ display: 'flex' }}>
                    {isDeliveryEnabled ? (
                        <div
                            className="restaurantDropdownButtonClosed"
                            onClick={() =>
                                onRequestCallback({
                                    type: LocationPickerAction.SELECT_DELIVERY,
                                })
                            }
                            style={{
                                color: isDelivery ? '#207E2B' : '#606060',
                                borderBottom: isDelivery
                                    ? '3px solid #207E2B'
                                    : '1px solid rgb(200,200,200)',
                            }}
                        >
                            {'Delivery'}
                        </div>
                    ) : (
                        <div
                            className="restaurantDropdownButtonClosed"
                            style={{
                                color: ' rgba(85, 85, 85, 0.2)',
                                borderBottom: '1px solid rgb(200,200,200)',
                            }}
                        >
                            {'Delivery'}
                        </div>
                    )}
                    <div
                        className="restaurantDropdownButtonClosed"
                        onClick={() =>
                            onRequestCallback({
                                type: LocationPickerAction.SELECT_PICKUP,
                            })
                        }
                        style={{
                            color: isDelivery ? '#606060' : '#207E2B',
                            borderBottom: isDelivery
                                ? '1px solid rgb(200,200,200)'
                                : '3px solid #207E2B',
                            paddingLeft: 2,
                        }}
                    >
                        {'Pick Up'}
                    </div>
                </div>
            </div>
            {!isLoading && restaurants.length === 0 && (
                <div className="noResultsContainer">
                    <div className="noResultsTag">
                        Sorry, no restaurant found
                    </div>
                    <div className="noResultsText">
                        Try different location or checkout different distance
                        please.
                    </div>
                </div>
            )}
            <div
                className="restaurantList"
                style={{
                    paddingBottom: '85px',
                }}
            >
                {isLoading ? (
                    <div style={{ margin: 10 }}>Loading...</div>
                ) : (
                    <></>
                )}
                {!isLoading &&
                    restaurants.map((restaurant) => {
                        const { openingToday } =
                            scheduleByRestaurantId[restaurant.restaurant_id];
                        const distance =
                            restaurantsDistances[restaurant.restaurant_id];
                        return (
                            <div
                                key={restaurant.endpoint}
                                className="restaurantItem"
                                onClick={() => {
                                    onRequestCallback({
                                        type: LocationPickerAction.SELECT_RESTAURANT,
                                        restaurant,
                                    });
                                    setShowMobileMap(true);
                                }}
                                style={{
                                    borderBottom: '1px solid rgb(200,200,200)',
                                }}
                            >
                                <h1 style={{ display: 'inline' }}>
                                    {restaurant.name}
                                </h1>
                                {distance ? (
                                    <span className="restaurantDistanceText">
                                        {(distance.distance / 1000).toFixed(2)}{' '}
                                        {dUnit} away
                                    </span>
                                ) : null}
                                <div>
                                    <section className="restautantsAddressText">
                                        {restaurant.address}
                                    </section>
                                </div>
                                <div>
                                    <span style={{ fontWeight: 500 }}>
                                        {'Phone: '}
                                    </span>
                                    {restaurant.phone_number}
                                </div>
                                <div>
                                    <section
                                        style={{
                                            fontWeight: 500,
                                            color: 'black',
                                        }}
                                    >
                                        {openingToday}
                                    </section>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );

    const showRestaurantMapView = () => {
        if (selectedRestaurant !== null) {
            var { openingIntervals, openingToday, preOrder } =
                scheduleByRestaurantId[selectedRestaurant.restaurant_id];
        }

        return (
            <div
                className="restaurantLocationPickerContainer"
                style={{
                    display: showMobileMap ? 'flex' : 'none',
                }}
            >
                <div className="headerAndMapContainer">
                    <div className="logoBarMapOpen">
                        <FaSearch
                            className="searchIcon"
                            size="1.2em"
                            onClick={() => showSearch()}
                        />

                        <div
                            className="gkLogoContainer"
                            style={{ padding: '0px 30px 0px 0px' }}
                        >
                            <img
                                className="gkLogo"
                                alt="Ghost Kitchens Logo"
                                src="https://mentumqr.s3.amazonaws.com/gk-stuff/new_gk_logo.svg"
                            />
                        </div>
                    </div>
                    <div className="mapContainer">
                        <div className="useLocationRoundButton">
                            <div
                                className="useLocation"
                                onClick={() =>
                                    onRequestCallback({
                                        type: LocationPickerAction.GET_CURRENT_LOCATION,
                                    })
                                }
                            >
                                <FaCrosshairs
                                    className="useLocationIcon"
                                    size="1.2em"
                                />
                            </div>
                        </div>
                        <GKGoogleMap
                            selectedCoordinates={selectedCoordinates}
                            markers={mapMarkers}
                        />
                    </div>
                </div>
                <div className="backIconContainer">
                    <FaChevronLeft
                        size="18px"
                        style={{
                            color: '#606060',
                        }}
                        onClick={() => hideMap()}
                    />
                </div>
                {selectedRestaurant != null ? (
                    <div
                        className="restaurantItem"
                        style={{
                            overflowY: 'auto',
                            display: 'block',
                            justifyContent: 'space between',
                            maxHeight: '40%',
                            height: '100vh',
                            margin: 'auto',
                            flexGrow: '1',
                            flex: '0 0 auto',
                        }}
                    >
                        <h1>{selectedRestaurant.name}</h1>
                        {!['0', ''].includes(selectedRestaurant.address) ? (
                            <section>{selectedRestaurant.address}</section>
                        ) : (
                            ''
                        )}
                        <div style={{ paddingBottom: '10px' }}>
                            <span style={{ fontWeight: 500 }}>Phone:</span>{' '}
                            {selectedRestaurant.phone_number}
                        </div>
                        <div className="restaurantLocationActionContainer">
                            <div
                                className="restaurantLocationActionButton"
                                target="_blank"
                                onClick={() => {
                                    window.open(
                                        `tel:${selectedRestaurant.phone_number}`,
                                        '_blank'
                                    );
                                }}
                            >
                                <ImPhone
                                    size="0.8em"
                                    style={{ marginRight: '5px' }}
                                />{' '}
                                Call
                            </div>
                            <div
                                className="restaurantLocationActionButton"
                                onClick={() => {
                                    window.open(
                                        `http://maps.apple.com/?q=${selectedRestaurant.address}`,
                                        '_blank'
                                    );
                                }}
                            >
                                <FaLocationArrow
                                    size="0.8em"
                                    style={{ marginRight: '5px' }}
                                />{' '}
                                Directions
                            </div>
                        </div>
                        <div className="restaurantOrderButtonContainer">
                            <div
                                className="restaurantLocationOrderButton2"
                                onClick={() => {
                                    window.open(
                                        `${BASE_URL}/${selectedRestaurant.endpoint}`
                                    );
                                }}
                            >
                                <HiShoppingCart
                                    size="0.8em"
                                    style={{ marginRight: '5px' }}
                                />{' '}
                                {preOrder ? (
                                    <span>Preorder</span>
                                ) : (
                                    <span>Order</span>
                                )}
                            </div>
                        </div>

                        <div className="restaurantAnimationContainer">
                            <div className="restaurantAnntationToday">
                                {openingToday}
                            </div>
                            <GKAnnotationDetails
                                openingIntervals={openingIntervals}
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    };

    // Render the entire location search modal for mobile devices
    const SearchbarModal = () => (
        <div
            className="mobileSearchContent"
            style={{
                display: showSearchbar ? 'block' : 'none',
            }}
        >
            <FaChevronLeft
                size="1.2em"
                style={{
                    color: '#606060',
                }}
                onClick={() => setShowSearchBar(false)}
            />
            <GKGooglePlacesAutoComplete
                isMobile
                address={address}
                onChange={(address) => {
                    onRequestCallback({
                        type: LocationPickerAction.SET_ADDRESS,
                        address,
                    });
                }}
            />
        </div>
    );

    return (
        <>
            <SearchbarModal />
            {showRestaurantMapView()}
            {!showMobileMap ? <MobileListView /> : <></>}
        </>
    );
};

export default GKLocationPickerMobile;
