import React from 'react';
import './VideoWall.css';
import Axios from 'axios';
import ReactPlayer from 'react-player/vimeo';
import { BASE_URL } from '../../endpoints';

const PlayerState = {
    Playing: 0,
    Ended: 1,
};

class VideoWall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            video_index: 0,
            player_state: PlayerState.Playing,
            show_curtains: false,
        };
    }

    objectHasSameContents(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    loadVideoList() {
        Axios.get(`${BASE_URL}/api/videowall`).then((res) => {
            if (res.data.urls) {
                const videos = res.data.urls;
                // check if data is the same

                const is_same = this.objectHasSameContents(
                    this.state.videos,
                    videos
                );
                if (is_same) {
                    return;
                }

                this.setState({
                    videos,
                    video_index: 0,
                    player_state: PlayerState.Ended,
                });

                this.setState({
                    player_state: PlayerState.Playing,
                });
            }
        });
    }

    componentDidMount() {
        this.props.history.push('/tv');
        return;
    }

    render() {
        if (this.state.videos.length === 0) {
            return '';
        }

        if (this.state.player_state === PlayerState.Ended) {
            return '';
        }

        const { innerWidth, innerHeight } = window;
        return (
            <div className="videoContainer">
                <div
                    className={
                        this.state.show_curtains ? 'curtains' : 'hide-curtains'
                    }
                    onClick={() => {
                        document.documentElement.webkitRequestFullScreen();
                        this.setState({
                            player_state: PlayerState.Ended,
                        });
                        setTimeout(() => {
                            this.setState({
                                player_state: PlayerState.Playing,
                            });
                        }, 100);
                    }}
                ></div>
                <ReactPlayer
                    muted
                    playing
                    url={this.state.videos[this.state.video_index]}
                    controls={false}
                    progressInterval={100}
                    width={innerWidth}
                    height={innerHeight}
                    onEnded={() => {
                        this.setState({
                            player_state: PlayerState.Ended,
                        });
                        let next_video = this.state.video_index + 1;
                        if (next_video === this.state.videos.length) {
                            next_video = 0;
                        }
                        this.setState({
                            video_index: next_video,
                            player_state: PlayerState.Playing,
                        });
                    }}
                    ref={(ref) => (this.player = ref)}
                    onStart={() => {
                        this.setState({
                            show_curtains: false,
                        });
                    }}
                    onProgress={(p) => {
                        const timeUntilVideoIsOver =
                            this.player.getDuration() - p.playedSeconds;
                        if (timeUntilVideoIsOver <= 0.5) {
                            // fade out
                            this.setState({
                                show_curtains: true,
                            });
                        }
                    }}
                />
            </div>
        );
    }
}
export default VideoWall;
