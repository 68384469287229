import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createPath } from '../utils';
import './css/MenuCategory.css';
import RoundComponent from './RoundComponent';
import { useTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../../endpoints';

export default (props) => {
    const history = useHistory();
    let category = props.category;
    let menu = props.menu;
    const { t } = useTranslation();
    let menuID = menu.menu_id;
    let categoryName = category.category.name;
    let categoryID = category.category.category_id;
    let restaurantID = useSelector(
        (state) => state.main.restaurant_info.restaurant_id
    );

    const params = useParams();
    const { endpoint } = params;

    const sortedItems = [...category.items].sort((a, b) => {
        return b.item.is_in_stock === a.item.is_in_stock
            ? 0
            : b.item.is_in_stock
            ? 1
            : -1;
    });

    const isListGreaterThanMax = sortedItems.length >= 6;

    return (
        <div className="menuCategory">
            <div className="categoryTitleContainer">
                <div className="categoryItemLabel" data-cy="categoryName">
                    {categoryName}
                </div>

                {sortedItems.length >= 6 && (
                    <Button
                        onClick={() => {
                            history.push(
                                createPath('/v2/:/m/:/c/:/', [
                                    endpoint,
                                    menuID,
                                    categoryID,
                                ])
                            );
                        }}
                        color="primary"
                        data-cy="seeMoreButton"
                        style={{ flex: '0 0 auto' }}
                    >
                        {t('cart.more')}
                    </Button>
                )}
            </div>

            <div
                className="categoryItemContainer"
                style={{
                    overflowX: 'auto',
                }}
            >
                {sortedItems.map((item) => {
                    let itemID = item.item.item_id;
                    let itemName = item.item.name;
                    let soldOut = !item.item.is_in_stock;
                    let imageHref = `${cloudfrontBaseUrl}${restaurantID}/items/${itemID}.jpeg`;
                    return (
                        <RoundComponent
                            onPress={() => {
                                history.push(
                                    createPath('/v2/:/m/:/c/:/i/:', [
                                        endpoint,
                                        menu.menu_id,
                                        category.category.category_id,
                                        itemID,
                                    ])
                                );
                            }}
                            image={imageHref}
                            label={itemName}
                            medium
                            key={itemID}
                            soldOut={soldOut}
                        />
                    );
                })}
            </div>
        </div>
    );
};
