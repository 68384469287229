import React from 'react';
import './css/PoweredByMentum.css';
import { BASE_URL } from '../../../endpoints';

export default () => {
    return (
        <>
            <div className="pom-container">
                <a
                    href={`${BASE_URL}/`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Logo"
                        src="/assets/logos/poweredbymentum.svg"
                        className="pom-logo"
                    />
                </a>
            </div>
            <div className="pom-inset-bottom"></div>
        </>
    );
};
