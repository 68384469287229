import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from '../../themes/theming';
import './css/Campaign.css';
import Header from '../../components/Header';
import * as OrderActions from '../../actions/orderActions';
import FinalizeOrderButton from '../Menu/components/FinalizeOrderButton';
import CampaignBlock from '../../components/CampaignBlock';
import { withTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../endpoints';

export class Campaign extends Component {
    renderFeaturedCampaigns = () => {
        if (!Array.isArray(this.props.state.order.menu.campaigns)) {
            return;
        }
        let featuredCampaigns = this.props.state.order.menu.campaigns.filter(
            ({ is_featured }) => is_featured
        );
        featuredCampaigns = featuredCampaigns.map((campaign) => (
            <CampaignBlock
                key={campaign.campaign_id}
                campaign={campaign}
                onClick={(e) => this.campaignSelected(campaign)}
            />
        ));

        if (!featuredCampaigns || (featuredCampaigns || []).length === 0) {
            return;
        }

        return (
            <div className="campaignListContainer campaignListContainer_first">
                <div className="campaignListTitle">
                    <h1>{this.props.t('campaign.featured')}</h1>
                    <p>{this.props.t('campaign.limitedTimePromotions')}</p>
                </div>
                {featuredCampaigns}
            </div>
        );
    };

    renderCampaigns = () => {
        if (!Array.isArray(this.props.state.order.menu.campaigns)) {
            return;
        }
        let campaigns = this.props.state.order.menu.campaigns.filter(
            ({ is_featured }) => !is_featured
        );
        campaigns = campaigns.map((campaign) => (
            <CampaignBlock
                key={campaign.campaign_id}
                campaign={campaign}
                onClick={(e) => this.campaignSelected(campaign)}
            />
        ));

        if (!campaigns || (campaigns || []).length === 0) {
            return;
        }

        return (
            <div className="campaignListContainer campaignListContainer_not_first">
                <div className="campaignListTitle">
                    <h1>{this.props.t('campaign.otherPromotions')}</h1>
                </div>
                {campaigns}
            </div>
        );
    };

    campaignSelected = (campaign = null) => {
        this.props.campaignSelected(campaign, this.props.history);
    };

    render() {
        const { restaurant_id } = this.props.state.main.restaurant_info;
        const is_tablet = this.props.state.table.is_tablet;
        const headerBackLink = is_tablet ? '' : '/logoview';
        const logoViewHeaderUrl = `${cloudfrontBaseUrl}${restaurant_id}/misc/landing-page.jpeg`;

        return (
            <div>
                <Header
                    back={headerBackLink}
                    history={this.props.history}
                    showGuestName
                />
                <div className="campaignPage">
                    <div
                        className="logoViewHeaderImage"
                        style={{ backgroundImage: `url(${logoViewHeaderUrl})` }}
                    />
                    <div className="logoViewHeaderText">
                        <div className="textGlow noselect">
                            {this.props.t('cart.promotion')}
                        </div>
                    </div>
                    <img
                        alt="Logo"
                        src={logoViewHeaderUrl}
                        className="logoViewHeaderExpansion"
                    />
                    {this.renderFeaturedCampaigns()}
                    {this.renderCampaigns()}
                </div>
                <FinalizeOrderButton
                    history={this.props.history}
                ></FinalizeOrderButton>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ state });

export default withTheme(
    connect(mapStateToProps, {
        campaignSelected: OrderActions.campaignSelected,
    })(withTranslation()(Campaign))
);
