import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './css/RoundComponent.css';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';

const Sizes = {
    MinSizeSmall: 128,
    MinSizeMedium: 196,
};

const RoundComponent = (props) => {
    let {
        onPress,
        image,
        label,
        small,
        medium,
        autoMarginRight,
        isComingSoon,
        isInSchedule = true,
        soldOut,
    } = props;
    let attribute =
        (small ? ' small' : '') +
        (medium ? ' medium' : '') +
        (autoMarginRight ? ' auto-margin-right' : '');
    let [roundImageSrc, setRoundImageSrc] = useState(image);
    const [isPhotoMissing, setIsPhotoMissing] = useState(false);
    const { t } = useTranslation();

    const { innerWidth: width } = window;
    const ImageSizes = {
        small: {
            width: Math.min(0.2 * width, Sizes.MinSizeSmall),
            height: Math.min(0.2 * width, Sizes.MinSizeSmall),
        },
        medium: {
            width: Math.min(0.25 * width, Sizes.MinSizeMedium),
            height: Math.min(0.25 * width, Sizes.MinSizeMedium),
        },
    };
    const size = medium ? 'medium' : small ? 'small' : '';
    const comingSoonClass = isComingSoon || !isInSchedule ? ' coming-soon' : '';
    const imageSize = ImageSizes[size];

    attribute += isComingSoon || !isInSchedule ? ' round-img-coming-soon' : '';

    if (soldOut) {
        attribute += ' round-comp-not-in-stock';
    }

    const twoLetterLabel = label.slice(0, 1);

    const renderImage = isPhotoMissing ? (
        <>
            <Avatar
                alt={label}
                style={{
                    width: imageSize.width,
                    height: imageSize.height,
                    fontSize: 36,
                    opacity: soldOut ? 0.5 : 1,
                }}
            >
                {twoLetterLabel}
            </Avatar>
        </>
    ) : (
        <>
            <LazyLoadImage
                src={roundImageSrc}
                width={imageSize.width}
                height={imageSize.height}
                className={`round-image ${attribute}`}
                effect="opacity"
                alt={label}
                onError={() => {
                    setIsPhotoMissing(true);
                    setRoundImageSrc(
                        'https://mentumqr.s3.amazonaws.com/misc/fallback.png'
                    );
                }}
                data-cy="lazyImage"
                style={{
                    width: imageSize.width,
                    height: imageSize.height,
                    overflow: 'hidden',
                }}
            />
        </>
    );

    // using LazyLoadImage's placeholderSrc would not work since that sets the background of the image, and we'll still see the browser broken image icon
    // it also gets rid of the circle css that we had, so the fallback image would become a square
    // for now, we can first use imageSrc as a state, then update it when we detect the image failed to load
    return (
        <div
            className={'round-container' + attribute + comingSoonClass}
            onClick={onPress}
            style={{ position: 'relative' }}
        >
            {renderImage}
            {label && (
                <div
                    className={
                        'round-label' +
                        (soldOut ? ' round-comp-not-in-stock' : '')
                    }
                    data-cy="roundLabel"
                >
                    {label}
                </div>
            )}
            {soldOut && (
                <div
                    style={{
                        width: imageSize.width,
                        top: imageSize.height / 2,
                    }}
                    className="out-of-stock-text"
                >
                    {t('itemDetail.outOfStock')}
                </div>
            )}
        </div>
    );
};

export default RoundComponent;
