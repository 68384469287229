import React from 'react';
import { Link } from 'react-router-dom';
import { SpacingAmount } from '../components/Spacing';
import { Header, Spacing, Integrations, Button, Footer } from '../components';
import './ProductPage.css';
import './LandingV3.css';

export default () => {
    return (
        <div>
            <Header />
            <div className="pdSectionContainer">
                <div className="ProductBackground">
                    <div className="pdHeroSplit">
                        <div className="pdHeroSplitSide">
                            <Spacing amount={SpacingAmount.Spacing60} />
                            <div className="pdHeroTagline">
                                QR-CODE DINE-IN & PAY
                            </div>
                            <div
                                className="pdHeroText"
                                style={{ width: '90%' }}
                            >
                                The future of ordering
                            </div>
                            <div
                                className="pdHeroDescr"
                                style={{ width: '80%' }}
                            >
                                Future proof your store with the industries most
                                seamless digital ordering tool for your QSR,
                                Hotel, Bar, Club, or Food Truck with take away
                                ordering.
                            </div>
                            <Link to="/contact">
                                <Button
                                    text="Get started with us"
                                    showChevron
                                />
                            </Link>
                        </div>
                        <div className="pdHeroSplitSide">
                            <Spacing amount={SpacingAmount.Spacing60} />
                            <img
                                src="/assets/landing/v3/productPages/pd-qr.png"
                                className="pdHeroImg"
                                style={{ width: 241, padding: 0 }}
                            />
                        </div>
                    </div>

                    <div className="ProductSplitViewRWM">
                        <div className="ProductSplitViewSide ProductSplitViewRight">
                            <img
                                src="/assets/landing/v3/productPages/pd-qr-data.png"
                                className="ProductImage"
                            />
                        </div>
                        <div className="ProductSplitViewSide">
                            <div className="v3LandingProductName">
                                Capture customer data
                            </div>
                            <div
                                className="v3LandingProductDescription"
                                style={{ width: '85%' }}
                            >
                                {' '}
                                Digital ordering makes it possible so that every
                                customer that walks into your store is captured
                                and rewarded to come back.
                            </div>
                            <Link to="/contact">
                                <div className="ProductLink">
                                    Book a Demo
                                    <img
                                        src="/assets/landing/v3/link-chevron.svg"
                                        className="v3LandingProductChevron"
                                    />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="ProductSplitView">
                        <div className="ProductSplitViewSide">
                            <div
                                className="v3LandingProductName"
                                style={{ maxWidth: '80%' }}
                            >
                                Digital-first experience
                            </div>
                            <div className="v3LandingProductDescription">
                                {' '}
                                Handle higher order quantities even during rush
                                hours with effortless and design centric digital
                                ordering and payments.{' '}
                            </div>
                            <Link to="/contact">
                                <div className="ProductLink">
                                    Book a Demo
                                    <img
                                        src="/assets/landing/v3/link-chevron.svg"
                                        className="v3LandingProductChevron"
                                    />
                                </div>
                            </Link>
                        </div>
                        <div className="ProductSplitViewSide ProductSplitViewRight">
                            <img
                                src="/assets/landing/v3/productPages/pd-qr-experience.png"
                                className="ProductImage"
                            />
                        </div>
                    </div>

                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing60} />

                    <div className="ProductSplitViewRWM">
                        <div className="ProductSplitViewSide ProductSplitViewRight">
                            <img
                                src="/assets/landing/v3/productPages/pd-qr-revenue.png"
                                className="ProductImage"
                            />
                        </div>
                        <div className="ProductSplitViewSide">
                            <div className="v3LandingProductName">Higher revenue</div>
                            <div className="v3LandingProductDescription">
                                {' '}
                                Increase average basket size by 30% and overall
                                revenues with built-in recommendation engines
                                and upselling algorithms.
                            </div>
                            <Link to="/contact">
                                <div className="ProductLink">
                                    Book a Demo
                                    <img
                                        src="/assets/landing/v3/link-chevron.svg"
                                        className="v3LandingProductChevron"
                                    />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="v3ContentContainer">
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <div className="v3CenteredHeader">POS Integrations</div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="v3CenteredDescription">
                        Handle higher volumes of orders with more efficient
                        labor management in your operation to push a higher
                        overall quality of service.
                    </div>
                    <Integrations />
                    <Spacing amount={SpacingAmount.Spacing60} />
                    <Spacing amount={SpacingAmount.Spacing128} />
                    <div className="v3CenteredHeader">
                        Check out our other products
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="CheckoutOthersContainer">
                        <div className="CheckoutContent">
                            <Link
                                to="/product/kiosk"
                                style={{ color: 'inherit' }}
                            >
                                <img
                                    src="/assets/landing/v3/productPages/checkout-kiosk.png"
                                    className="CheckoutImg"
                                />
                                <div className="CheckoutTag">
                                    Expressive Kiosk
                                </div>
                                <div className="CheckoutDescrp">
                                    Save on labour costs and customer wait times
                                    by giving patrons control of their own
                                    dining experience.
                                </div>
                            </Link>
                        </div>
                        <div className="CheckoutContent">
                            <Link
                                to="/product/delivery"
                                style={{ color: 'inherit' }}
                            >
                                <img
                                    src="/assets/landing/v3/productPages/checkout-d.png"
                                    className="CheckoutImg"
                                />
                                <div className="CheckoutTag">
                                    Delivery Aggregation
                                </div>
                                <div className="CheckoutDescrp">
                                    The most customer and waiter centric table
                                    and hotel ordering system in the industry.
                                    Order, call for the waiter, and securely pay
                                    digitally.{' '}
                                </div>
                            </Link>
                        </div>
                        <div className="CheckoutContent">
                            <Link
                                to="/product/dinein"
                                style={{ color: 'inherit' }}
                            >
                                <img
                                    src="/assets/landing/v3/productPages/checkout-dinein.png"
                                    className="CheckoutImg"
                                />
                                <div className="CheckoutTag">
                                    Dine-in Ordering
                                </div>
                                <div className="CheckoutDescrp">
                                    Instantly edit menus on all your delivery
                                    platforms, change stock status, and get your
                                    analytics all from a single dashboard or
                                    integrate directly into your POS.
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <Spacing amount={SpacingAmount.Spacing60} />
                <div className="v3ReadyToGetStartedContainer ReadySectionBackground">
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <div className="v3ReadyHeader">Ready to get started?</div>
                    <Spacing amount={SpacingAmount.Spacing38} />

                    <div className="v3CenteredDescription">
                        Get in touch and set up a free demo of how our products
                        can future proof your operations.
                    </div>
                    <Spacing amount={SpacingAmount.Spacing38} />
                    <Link to="/contact">
                        <Button text="Book a Demo" />
                    </Link>
                    <Spacing amount={SpacingAmount.Spacing128} />
                </div>
                <Footer />
            </div>
        </div>
    );
};
