import React from 'react';
import { connect } from 'react-redux';

import { withTheme } from '../../themes/theming';
import { withTranslation } from 'react-i18next';
import { cloudfrontBaseUrl } from '../../endpoints';

class Complete extends React.Component {
    goHome() {
        this.props.history.push('/guesthome');
    }

    render() {
        const restaurant_id =
            this.props.state.main.restaurant_info.restaurant_id;
        const logo = `${cloudfrontBaseUrl}${restaurant_id}/misc/logo.png`;
        const orderPlaced =
            Object.keys(this.props.state.order.temporary_order).length === 0;

        const theme = this.props.theme;

        return (
            <div>
                <div className="completeContainer">
                    <img
                        src={logo}
                        className="completeLogo"
                        alt="Restaurant Logo"
                    />

                    {orderPlaced ? (
                        <div>
                            <div className="completeThankYouContainer">
                                <div className="completeThankYouText">
                                    {this.props.t('common.ty')}!
                                </div>
                                <div className="completeThankYouText">
                                    {this.props.t(
                                        'paymentComplete.orderPlaced'
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="cssload-container">
                            <div className="cssload-whirlpool"></div>
                        </div>
                    )}
                </div>

                {orderPlaced ? (
                    <div
                        className="floatingButton"
                        style={theme.floatingButton}
                        onClick={(e) => this.goHome()}
                    >
                        <div
                            className="floatingButtonLabel center"
                            style={theme.floatingButtonLabel}
                        >
                            {this.props.t('common.close')}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

const MSTP = (state) => {
    return { state };
};

export default withTranslation()(withTheme(connect(MSTP)(Complete)));
